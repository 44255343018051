import React from "react";
import { Grid, Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  EventsOffersAsset,
  EventsOffersAssetMobile
} from "GlobalProcess/GlobalFunction";

const MediaBlock = ({ widget, color_theme = [], ui_properties }) => {
  const { i18n } = useTranslation();
  const widthScreen = useMediaQuery("(max-width:900px)");
  const { color_definition } = color_theme || {};
  const backgroundColor = color_definition?.background_color;
  const descriptionColor = color_definition?.description;
  const { background, description_color } = ui_properties || {};
  return (
    <Grid
      container
      style={{
        backgroundColor: background || backgroundColor || ""
      }}
      sx={{
        paddingLeft: { xs: "20px", lg: "11.11%" },
        paddingRight: { xs: "20px", lg: "11.11%" },
        position: "relative",
        top: { xs: "0px" },
        display: "flex",
        justifyContent: "center",
        direction: i18n.language === "arabic" ? "rtl" : "ltr"
      }}
      className="editorial-detail"
    >
      <Grid item xs={12} sx={{ maxWidth: { xs: "100%" } }}>
        <Grid container sx={{ marginTop: { xs: "56px", md: "80px" } }}>
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: i18n.language === "arabic" ? "left" : "right"
              }}
            >
              {!widthScreen && widget?.asset && EventsOffersAsset(widget)}
              {widthScreen &&
                widget?.asset_mobile &&
                EventsOffersAssetMobile(widget)}
            </Box>
            {widget?.label && (
              <Box
                style={{
                  color: description_color || descriptionColor || ""
                }}
                className={
                  i18n.language === "arabic" ? "textStyleArabic" : "textStyle"
                }
              >
                {widget?.label}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MediaBlock;
