import React from "react";
import { FormHelperText } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const CustomFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: "0px !important",
  marginRight: "0px !important",
  color: "var(--ui-colours-error-red, #F66464)",
  fontFamily:
    theme?.i18n?.language === "arabic" ? "TajawalRegular" : "MontserratRegular",
  fontSize: theme?.i18n?.language === "arabic" ? "17px" : "14px",
  lineHeight: "130%",
  marginTop: "16px"
}));

const CustomFormHelperTextWithTranslation = (props) => {
  const { i18n } = useTranslation();

  return <CustomFormHelperText {...props} theme={{ i18n }} />;
};

export default CustomFormHelperTextWithTranslation;
