import React from "react";
import MediaBlock from "Components/EditorialDetail/MediaBlock";

export const EditorialMediaBlockContainer = ({ widget, ui_properties }) => {
  return (
    <MediaBlock
      widget={widget}
      color_theme={widget.color_theme}
      ui_properties={ui_properties}
    />
  );
};
