import React, { useEffect, useState } from "react";
import RewardCarousel from "./RewardCarousel";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ViewAllButton from "Assets/ViewAllButton";
import CustomLink from "../SupportPages/components/CustomLink";
import RewardCarouselWithDetail from "./RewardCarouselWithDetail";
import {
  useGetAccount,
  useGetArchivedCoupons,
  useGetPromotions,
  useGetRewardCards
} from "services/account/account.query";
import { RewardTypeEnums } from "utils/enums";

const RewardList = () => {
  const { t, i18n } = useTranslation();
  const {
    data: rewardCardsData,
    isLoading: rewardsCardLoading,
    isSuccess: rewardsCardSuccess,
    isError: rewardsCardError
  } = useGetRewardCards();
  const {
    data: promotionsData,
    isLoading: promotionLoading,
    isSuccess: promotionSuccess,
    isError: promotionError
  } = useGetPromotions();
  const {
    data: userInfoData,
    isLoading: userInfoLoading,
    isSuccess: userInfoSuccess
  } = useGetAccount();
  const {
    data: archivedCoupons,
    isLoading: archivedLoading,
    isSuccess: archivedSuccess
  } = useGetArchivedCoupons();
  const [rewardCardsList, setRewardCardsList] = useState([]);

  const getDefaultRewardCard = (data, type) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    if (type == "front") {
      return defaultRewardCard?.web?.assets?.front;
    } else {
      return defaultRewardCard?.web?.assets?.back;
    }
  };
  const getDefaultRewardCardBrandLogo = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.brand_logo;
  };
  const getDefaultRewardCardBrandName = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.brand_name;
  };
  const getDefaultRewardCardBrandDescription = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.card_back_description;
  };
  const getDefaultRewardCardTheme = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.web?.color_theme;
  };

  useEffect(() => {
    if (
      (promotionSuccess && rewardsCardSuccess && userInfoSuccess) ||
      archivedSuccess
    ) {
      let tempRewards = [];
      if (
        rewardCardsData?.items?.length > 0 &&
        promotionsData?.customer_promotions?.length > 0
      ) {
        const { fields } = rewardCardsData?.items[0];
        if (fields?.configuration?.reward_card_types?.length > 0) {
          promotionsData?.customer_promotions?.map(
            (promotion, indexPromotion) => {
              const rewardCard = fields?.configuration?.reward_card_types?.find(
                (rewardCard) => rewardCard?.id === promotion?.applicable_stores
              );
              if (
                rewardCard &&
                rewardCard?.id === promotion?.applicable_stores
              ) {
                tempRewards.push({
                  id: indexPromotion,
                  image: {
                    couponBarcode:
                      userInfoData?.Customer[0]?.LoyaltyInformaton?.Card
                        ?.CardID || "NA",
                    discount: promotion?.discount,
                    discountType: promotion?.discount_type,
                    cardTitleDescription: promotion?.offer_name,
                    offerStatus: promotion?.offer_status?.split(" ")[0]
                      ? promotion?.offer_status?.split(" ")[0].toUpperCase()
                      : "",
                    offerValidity: promotion?.offer_validity,
                    cardBackgroundImage: rewardCard?.web?.assets?.front,
                    cardBackgroundImageHalf: rewardCard?.web?.assets?.back,
                    BrandLogo: rewardCard?.brand_logo,
                    brandName: rewardCard?.brand_name,
                    ctaText: rewardCard?.cta_text,
                    cardDetailDescription: rewardCard?.card_back_description,
                    cardTheme: rewardCard?.web?.color_theme,
                    expiredBrandLogo: rewardCard?.expired_brand_logo,
                    expiredCardBackgroundImage:
                      fields?.configuration?.expired?.bg_asset,
                    expiredCardTheme:
                      fields?.configuration?.expired?.text_color,
                    rewardType: RewardTypeEnums.PROMOTIONS
                  }
                });
              } else {
                tempRewards.push({
                  id: indexPromotion,
                  image: {
                    couponBarcode:
                      userInfoData?.Customer[0]?.LoyaltyInformaton?.Card
                        ?.CardID || "NA",
                    discount: promotion?.discount,
                    discountType: promotion?.discount_type,
                    cardTitleDescription: promotion?.offer_name,
                    offerStatus: promotion?.offer_status?.split(" ")[0]
                      ? promotion?.offer_status?.split(" ")[0].toUpperCase()
                      : "",
                    offerValidity: promotion?.offer_validity,
                    cardBackgroundImage:
                      fields?.configuration?.reward_card_types[0]?.web?.assets
                        ?.front ||
                      getDefaultRewardCard(rewardCardsData, "front"),
                    cardBackgroundImageHalf:
                      fields?.configuration?.reward_card_types[0]?.web?.assets
                        ?.back || getDefaultRewardCard(rewardCardsData, "back"),
                    BrandLogo:
                      fields?.configuration?.reward_card_types[0]?.brand_logo ||
                      getDefaultRewardCardBrandLogo(rewardCardsData),
                    brandName:
                      fields?.configuration?.reward_card_types[0]?.brand_name ||
                      getDefaultRewardCardBrandName(rewardCardsData),
                    ctaText:
                      fields?.configuration?.reward_card_types[0]?.cta_text,
                    cardDetailDescription:
                      fields?.configuration?.reward_card_types[0]
                        ?.card_back_description ||
                      getDefaultRewardCardBrandDescription(rewardCardsData),
                    cardTheme:
                      fields?.configuration?.reward_card_types[0]?.web
                        ?.color_theme ||
                      getDefaultRewardCardTheme(rewardCardsData),
                    expiredBrandLogo:
                      fields?.configuration?.reward_card_types[0]
                        ?.expired_brand_logo,
                    expiredCardBackgroundImage:
                      fields?.configuration?.expired?.bg_asset,
                    expiredCardTheme:
                      fields?.configuration?.expired?.text_color,
                    rewardType: RewardTypeEnums.PROMOTIONS
                  }
                });
              }
            }
          );
        }
      }
      if (
        rewardCardsData?.items?.length > 0 &&
        promotionsData?.customer_coupons?.length > 0
      ) {
        const { fields } = rewardCardsData?.items[0];
        promotionsData?.customer_coupons?.map((coupon, indexCoupon) => {
          const check = archivedCoupons.find(
            (item) => item?.Barcode === coupon?.coupon_barcode
          );
          if (check) {
            return true;
          }
          const rewardCard = fields?.configuration?.reward_card_types?.find(
            (rewardCard) => rewardCard?.id === coupon?.applicable_stores
          );
          if (rewardCard && rewardCard?.id === coupon?.applicable_stores) {
            tempRewards.push({
              id: indexCoupon + 1,
              image: {
                couponBarcode: coupon?.coupon_barcode,
                discount: coupon?.discount,
                discountType: coupon?.discount_type,
                cardTitleDescription: coupon?.coupon_name,
                offerStatus: coupon?.coupon_status?.split(" ")[0]
                  ? coupon?.coupon_status?.split(" ")[0].toUpperCase()
                  : "",
                offerValidity: coupon?.coupon_validity,
                cardBackgroundImage: rewardCard?.web?.assets?.front,
                cardBackgroundImageHalf: rewardCard?.web?.assets?.back,
                BrandLogo: rewardCard?.brand_logo,
                brandName: rewardCard?.brand_name || "",
                ctaText: rewardCard?.cta_text,
                cardDetailDescription: rewardCard?.card_back_description,
                cardTheme: rewardCard?.web?.color_theme,
                expiredBrandLogo: rewardCard?.expired_brand_logo,
                expiredCardBackgroundImage:
                  fields?.configuration?.expired?.bg_asset,
                expiredCardTheme: fields?.configuration?.expired?.text_color,
                rewardType: RewardTypeEnums.CUPONS
              }
            });
          } else {
            tempRewards.push({
              id: indexCoupon + 1,
              image: {
                couponBarcode: coupon?.coupon_barcode,
                discount: coupon?.discount,
                discountType: coupon?.discount_type,
                cardTitleDescription: coupon?.coupon_name,
                offerStatus: coupon?.coupon_status?.split(" ")[0]
                  ? coupon?.coupon_status?.split(" ")[0].toUpperCase()
                  : "",
                offerValidity: coupon?.coupon_validity,
                cardBackgroundImage:
                  fields?.configuration?.reward_card_types[0]?.web?.assets
                    ?.front || getDefaultRewardCard(rewardCardsData, "front"),
                cardBackgroundImageHalf:
                  fields?.configuration?.reward_card_types[0]?.web?.assets
                    ?.back || getDefaultRewardCard(rewardCardsData, "back"),
                BrandLogo:
                  fields?.configuration?.reward_card_types[0]?.brand_logo ||
                  getDefaultRewardCardBrandLogo(rewardCardsData),
                brandName:
                  fields?.configuration?.reward_card_types[0]?.brand_name ||
                  getDefaultRewardCardBrandName(rewardCardsData),
                ctaText: fields?.configuration?.reward_card_types[0]?.cta_text,
                cardDetailDescription:
                  fields?.configuration?.reward_card_types[0]
                    ?.card_back_description ||
                  getDefaultRewardCardBrandDescription(rewardCardsData),
                cardTheme:
                  fields?.configuration?.reward_card_types[0]?.web
                    ?.color_theme || getDefaultRewardCardTheme(rewardCardsData),
                expiredBrandLogo:
                  fields?.configuration?.reward_card_types[0]
                    ?.expired_brand_logo,
                expiredCardBackgroundImage:
                  fields?.configuration?.expired?.bg_asset,
                expiredCardTheme: fields?.configuration?.expired?.text_color,
                rewardType: RewardTypeEnums.CUPONS
              }
            });
          }
        });
      }
      setRewardCardsList(tempRewards);
    }
  }, [
    promotionSuccess,
    promotionsData?.customer_coupons,
    promotionsData?.customer_promotions,
    rewardCardsData?.items,
    rewardsCardSuccess,
    userInfoData?.Customer,
    userInfoSuccess
  ]);

  return (
    <Box
      className="rewards"
      sx={{
        paddingLeft: { xs: "20px", md: "11.11%" },
        paddingRight: { xs: "20px", md: "11.11%" },
        marginTop: { xs: "35px", md: "51px" },
        marginBottom: { xs: "32px", md: "118px" }
      }}
      data-lang={i18n.language}
    >
      {rewardsCardLoading ||
      promotionLoading ||
      userInfoLoading ||
      archivedLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <CircularProgress
            size={"25px"}
            style={{
              color: "black"
            }}
          />
        </Box>
      ) : (
        !rewardsCardError &&
        !promotionError &&
        rewardCardsList.length !== 0 && (
          <>
            {" "}
            <div
              style={{
                position: "relative"
              }}
            >
              <Box className="your-rewards-header" data-lang={i18n.language}>
                {t("Your Rewards")}
              </Box>
            </div>
            <RewardCarouselWithDetail rewards={rewardCardsList}>
              {(onClickItem) => (
                <RewardCarousel
                  rewards={rewardCardsList}
                  onClickItem={(id) => {
                    onClickItem(id);
                  }}
                />
              )}
            </RewardCarouselWithDetail>
            <Box
              sx={{
                marginTop: "24px",
                direction: i18n.language === "arabic" ? "rtl" : "ltr"
              }}
            >
              <CustomLink to={"/rewards/profile"}>
                <ViewAllButton
                  sx={{
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalMedium"
                        : "MontserratMedium",
                    fontSize: i18n.language === "arabic" ? "15px" : "12px"
                  }}
                >
                  {t("View all rewards")}
                </ViewAllButton>
              </CustomLink>
            </Box>
          </>
        )
      )}
    </Box>
  );
};

export default RewardList;
