import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { NavBar } from "Components/NavBar";
import { selectPath } from "store/pathSlice";
import { scrollInfFunc } from "store/publicSlice";
import { PRIVATE_TOKEN_PERSIST_KEY } from "constants";
import { useGetAccount } from "services/account/account.query";
import { getToken } from "utils/authStorage";
import { getUserProperties } from "utils/getUserProperties";
import { useDataLayer } from "hooks/useDataLayer";

const propsDark = {
  navClass: "Navmenu",
  navButton: "NavButton",
  createAccount: "NavButton-crate-account"
};
const propsLight = {
  navClass: "Navmenu-light",
  navButton: "NavButton-light",
  createAccount: "NavButton-crate-account-light"
};
const propsOtherLight = {
  navClass: "Navmenu-light-other",
  navButton: "NavButton-light",
  createAccount: "NavButton-crate-account-light"
};
const propsOtherLight2 = {
  navClass: "Navmenu-light-other2",
  navButton: "NavButton-light",
  createAccount: "NavButton-crate-account-light"
};

const mainstyle = {
  opacity: "1",
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: -20,
  display: "none"
};
const mainstyleHover = {
  opacity: "0.5",
  background: "#000000",
  position: "absolute",
  width: "100%",
  height: "-webkit-fill-available",
  zIndex: 20,
  display: "block"
};

export const NavBarContainer = ({ navbar, widget = {} }) => {
  const { isMenuOpen } = useSelector((state) => state.public);
  const [NavScroll, SetNavScroll] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const { data: userData } = useGetAccount();
  const [token, setToken] = useState(
    getToken(PRIVATE_TOKEN_PERSIST_KEY) || false
  );
  const [scrollInfo, setScrollInfo] = useState(0);
  const twice = useMediaQuery("(min-width:1200px)");
  const [isHover, setIsHover] = useState(false);
  const { setUserData } = useDataLayer();
  const userAgent = window?.navigator?.userAgent;

  const { color_theme, logo, links = [] } = widget;
  const { color_definition } = color_theme || {};

  //  It first checks if isHover and twice are both true.
  //  Then, it checks if the currentPath includes specific route segments like "how," "amber-app," or if the currentPath is the root path ("/").
  //  If all these conditions are met, it returns mainstyleHover.
  //  If the conditions are not met, it returns mainstyle.

  const changeBackground = () => {
    if (window.scrollY > 66) {
      SetNavScroll(true);
    } else {
      SetNavScroll(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(selectPath(currentPath));
    changeBackground();
  }, []);

  useEffect(() => {
    if (!isMenuOpen) {
      setIsHover(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    async function handleData() {
      setUserData(await getUserProperties(userData));
    }

    handleData();
  }, [userData, setUserData]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = Math.round(window.scrollY);
      setScrollInfo(scrollPercentage);
      changeBackground();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(scrollInfFunc(scrollInfo));
  }, [dispatch, scrollInfo]);

  const styleCntrl = () =>
    isHover &&
    twice &&
    (currentPath?.includes("how") ||
      currentPath?.includes("amber-app") ||
      currentPath === "/")
      ? mainstyleHover
      : mainstyle;

  return (
    <>
     
      {!userAgent?.includes("amber") && (
        <>
          {navbar === "default" ? (
            <NavBar
              propsclass={
                !NavScroll && !isHover && !isMenuOpen ? propsDark : propsLight
              }
              color_definition={color_definition}
              setIsHover={setIsHover}
              isHover={isHover}
              NavScroll={NavScroll}
              userData={userData}
              token={token}
              showDefaultLogo={!NavScroll && !isHover}
              setToken={setToken}
              SetNavScroll={SetNavScroll}
              navbar="default"
              isMenuOpen={isMenuOpen}
              sxAppBar={{
                paddingLeft: { xs: "4px", xxl: "16px" },
                paddingRight: { xs: "4px", xxl: "16px" }
              }}
              sxDrawer={{
                display: { xs: "flex", lg: "none" }
              }}
              sxXsLogoLinkBox={{
                maxHeight: "28px"
              }}
              sxLgLogoLinkBox={{
                alignItems: "center"
              }}
              navItems={links}
              logo={logo}
            />
          ) : navbar === "brands" ? (
            <NavBar
              isHover={isHover}
              setIsHover={setIsHover}
              NavScroll={NavScroll}
              propsclass={
                twice && NavScroll ? propsOtherLight2 : propsOtherLight
              }
              color_definition={color_definition}
              userData={userData}
              token={token}
              setToken={setToken}
              defaultTool={true}
              SetNavScroll={SetNavScroll}
              navbar="brands"
              sxAppBar={{
                paddingLeft: { xs: "4px", xl: "16px" },
                paddingRight: { xs: "4px", xl: "16px" },
                boxShadow:
                  isHover || NavScroll
                    ? "0px 2px 16px rgba(0, 0, 0, 0.1) !important"
                    : "initial",
                direction: "ltr"
              }}
              sxDrawer={{
                display: { xs: "block", lg: "none" }
              }}
              sxXsLogoLinkBox={{
                maxHeight: "28px"
              }}
              sxLgLogoLinkBox={{
                alignItems: "center"
              }}
              navItems={links}
              logo={logo}
            />
          ) : navbar === "white" ? (
            <NavBar
              isHover={isHover}
              setIsHover={setIsHover}
              NavScroll={NavScroll}
              propsclass={propsOtherLight2}
              showDefaultLogo={false}
              userData={userData}
              token={token}
              color_definition={color_definition}
              setToken={setToken}
              defaultTool={true}
              SetNavScroll={SetNavScroll}
              navbar="white"
              sxAppBar={{
                paddingLeft: { xs: "4px", xl: "16px" },
                paddingRight: { xs: "4px", xl: "16px" },
                boxShadow:
                  isHover || NavScroll
                    ? "0px 2px 16px rgba(0, 0, 0, 0.1) !important"
                    : "initial"
              }}
              sxDrawer={{
                display: { xs: "block", lg: "none" }
              }}
              sxXsLogoLinkBox={{
                height: "28px"
              }}
              navItems={links}
              logo={logo}
            />
          ) : (
            <NavBar
              propsclass={!NavScroll && !isHover ? propsOtherLight : propsLight}
              logged={navbar === "logged" ? true : false}
              setIsHover={setIsHover}
              isHover={isHover}
              NavScroll={NavScroll}
              userData={userData}
              color_definition={color_definition}
              token={token}
              setToken={setToken}
              defaultTool={true}
              SetNavScroll={SetNavScroll}
              navbar=""
              sxAppBar={{
                paddingLeft: { xs: "4px", xl: "16px" },
                paddingRight: { xs: "4px", xl: "16px" },
                boxShadow:
                  isHover || NavScroll
                    ? "0px 2px 16px rgba(0, 0, 0, 0.1) !important"
                    : "initial"
              }}
              sxLgLogoLinkBox={{
                alignItems: "center"
              }}
              sxDrawer={{
                display: { xs: "block", lg: "none" }
              }}
              navItems={links}
              logo={logo}
              showDefaultLogo={false}
            />
          )}
          <Box style={styleCntrl()}></Box>
        </>
      )}
    </>
  );
};
