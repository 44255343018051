import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import Close from "Assets/images/close.svg";
import { useCountryNotAvailable } from "hooks/onBoardingHooks";
import LazyImage from "Components/LazyLoad/LazyImage";

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  //This function controls the opening of the modal
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const mobilestyle = {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    margin: "0px",
    maxHeight: "100%"
  };
  const dekstopstyle = {
    width: "100%",
    maxWidth: "520px",
    height: "fit-content",
    margin: "0px",
    maxHeight: "506px",
    paddingBottom: "48px"
  };

  //This function controls the closing of the modal
  const handleClose = () => {
    setOpen(false);
  };
  const { t, i18n } = useTranslation();
  const mobile = useMediaQuery("(max-width:600px)");
  const CountryNotAvailable = useCountryNotAvailable();
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //It checks the browser's screen size and if the size is mobile it sets it to center to align the text, otherwise it sets it left or right
  const NoCountrySyle = () => {
    if (mobile) {
      return "center";
    } else {
      return i18n.language === "arabic" ? "right" : "left";
    }
  };
  return (
    <Box
      style={{
        margin: "10px",
        position: "relative",
        top: "9px",
        textAlign: NoCountrySyle()
      }}
    >
      <Link
        onClick={handleClickOpen("paper")}
        className={
          i18n.language === "arabic"
            ? "SCcountry-text-arabic"
            : "SCcountry-text"
        }
        style={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
      >
        {t("My country is not on this list")}
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          backgroundColor: { xs: "#FFFFFF", md: "initial" },
          direction: i18n.language === "arabic" ? "rtl" : "ltr"
        }}
        scroll={scroll}
        PaperProps={{ sx: mobile ? mobilestyle : dekstopstyle }}
      >
        <DialogActions>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            component="label"
            style={{ color: "#000000" }}
          >
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={Close}
              alt="ReturnImage"
            />
          </IconButton>
        </DialogActions>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ padding: { xs: "68px 20px 0px 20px", md: "0px 48px" } }}
        >
          <div className="scroll-dialog-title">
            {i18n.language === "arabic"
              ? CountryNotAvailable?.[0]?.label?.ar
              : CountryNotAvailable?.[0]?.label?.en}
          </div>
        </DialogTitle>

        <DialogContent sx={{ padding: { xs: "0px 20px", md: "0px 48px" } }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <p
              className={
                i18n.language === "arabic"
                  ? "scroll-dialog-text-arabic"
                  : "scroll-dialog-text"
              }
            >
              {i18n.language === "arabic"
                ? CountryNotAvailable?.[0]?.description?.ar
                : CountryNotAvailable?.[0]?.description?.en}
            </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
