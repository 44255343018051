import React from "react";
import AppstoreBTN from "Assets/images/AppstoreBTN.svg";
import AppstoreBTNarabic from "Assets/images/AppstoreBTNarabic.svg";
import google from "Assets/images/google.svg";
import googlearabic from "Assets/images/googlearabic.svg";

const googleImageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "fill"
};
const appleImageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "fill"
};

const imageStyleMap = {
  apple: appleImageStyle,
  google: googleImageStyle
};

const appStoreImageImageMap = {
  "apple-en": AppstoreBTN,
  "apple-arabic": AppstoreBTNarabic,
  "google-en": google,
  "google-arabic": googlearabic
};

export const getAppStoreName = () =>
  navigator.userAgent.indexOf("Mac") !== -1 ? "apple" : "google";

export const getOSStyle = () => imageStyleMap[getAppStoreName()];

export const getAppStoreImage = (lang) =>
  appStoreImageImageMap[getAppStoreName() + "-" + lang];

const AppStore = ({ image, data_description }) => {
  return (
    <img src={image} data-description={data_description} style={getOSStyle()} />
  );
};

export default AppStore;
