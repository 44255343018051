import React, { useMemo } from "react";
import CarouselSubList from "Components/Homecomponents/CarouselSubList";
import { useParams } from "react-router-dom";

//The transform function takes an item and an index and transforms the data into a new object with specific properties,
// which is typically used for data mapping and processing.

const transform = (item, index) => ({
  id: item.id || index,
  text: item.brand,
  maintext: item.title,
  badge: item.badge,
  category: item.categories || item.category,
  image: item.image,
  link_type: item.link_type,
  link: item.link,
  badge_behaviour: item.badge_behaviour,
  badge_template: item.badge_template,
  start_date: item.start_date,
  end_date: item.end_date,
  reading_time: item.reading_time
});

export const CarousalSublistContainer = ({
  widget = {},
  hidden,
  carosel_description_style,
  swiperClass,
  ui_properties
}) => {
  let {
    show_category,
    component_type,
    show_header_text,
    color_theme,
    data_source,
    header_text,
    max_items,
    item_limit,
    show_brand_title,
    ids,
    title,
    cta_button = {},
    items = [],
    is_shop_the_look_carousel
  } = widget || {};

  //The filteredItems variable is generated by filtering the items array to exclude items that have no properties or are empty objects

  const filteredItems = items.filter((item) => Object.keys(item).length > 0);
  const { id } = useParams();

  show_category = id ? show_category : true;
  const { link_type } = cta_button || {};

  //The mappedItems variable is computed using the useMemo hook, processing a list of filteredItems and generating a new list of transformed items
  //with specific properties. It optimizes performance by recalculating only when the filteredItems, ids, or max_items change.

  const mappedItems = useMemo(() => {
    const idArray = ids?.split(",");
    if (max_items > 3 && max_items <= filteredItems.length) {
      return filteredItems
        .slice(0, max_items)
        .map((item, index) => ({ ...item, id: idArray?.[index] }))
        .map(transform);
    } else {
      return filteredItems
        .map((item, index) => ({ ...item, id: idArray?.[index] }))
        .map(transform);
    }
  }, [filteredItems, ids, max_items]);
  return (
    <>
      {max_items >= 3 && (
        <CarouselSubList
          items={mappedItems}
          size={item_limit || 4}
          head={title || header_text}
          buttontext={cta_button.title}
          order_cross={false}
          navigate={true}
          paginate={false}
          minh={false}
          margintp={true}
          marginValue="0px"
          marginSwiperHead="0px"
          spacebetween={20}
          pathto={cta_button.link}
          color_theme={color_theme}
          link_type={link_type}
          backGround="transparent"
          show_brand_title={show_brand_title}
          data_source={data_source}
          hidden={hidden}
          show_category={show_category}
          show_header_text={show_header_text}
          carosel_description_style={carosel_description_style}
          swiperClass={swiperClass}
          component_type={component_type}
          is_shop_the_look_carousel={is_shop_the_look_carousel}
          ui_properties={ui_properties}
        />
      )}
    </>
  );
};
