import jwtDecode from "jwt-decode";

import { AUTH_TOKEN_PERSIST_KEY, PRIVATE_TOKEN_PERSIST_KEY } from "constants";
import {
  removePrivateBearerToken,
  setPrivateBearerToken
} from "services/privateApiClient";
import { removeBearerToken } from "services/apiClient";

// getInfoFromToken(token): Extracts user information from the given token and returns this information. Since JWT tokens are used, this function uses the jwt-decode library to parse the token's content.

// saveToken(token, key = AUTH_TOKEN_PERSIST_KEY): Stores the given token in local storage with a specific key (default as AUTH_TOKEN_PERSIST_KEY). This is used to store the user authentication token after a user logs in.

// getToken(key = AUTH_TOKEN_PERSIST_KEY): Retrieves the authentication token stored in local storage with a specific key (default as AUTH_TOKEN_PERSIST_KEY) and returns it.

// getPrivateToken(key = PRIVATE_TOKEN_PERSIST_KEY): Retrieves the private authentication token stored in local storage with a specific key (default as PRIVATE_TOKEN_PERSIST_KEY) and returns it.

// getSecondToken(key = AUTH_TOKEN_PERSIST_KEY): Similar to the getToken function, it retrieves the authentication token stored in local storage with a specific key (default as AUTH_TOKEN_PERSIST_KEY) and returns it.

// removeToken(key = AUTH_TOKEN_PERSIST_KEY): Removes the authentication token stored in local storage with a specific key (default as AUTH_TOKEN_PERSIST_KEY).

// onSetPrivateToken(token): Sets the private authentication token and automatically redirects the user. This function sets the private authentication token using setPrivateBearerToken, saves the token in local storage using saveToken, removes the general authentication token using removeBearerToken, and finally removes the old general authentication token using removeToken. It then reloads the page to update the user's session.

// onRemovePrivateToken(): Removes the private authentication token and automatically redirects the user. This function removes the private authentication token using removePrivateBearerToken, removes the token from local storage using removeToken, and then reloads the page.

export const getInfoFromToken = (token) => {
  const userInfo = jwtDecode(token);
  return userInfo;
};

export const saveToken = (token, key = AUTH_TOKEN_PERSIST_KEY) =>
  localStorage.setItem(key, token);
export const getToken = (key = AUTH_TOKEN_PERSIST_KEY) =>
  localStorage.getItem(key);
export const getPrivateToken = (key = PRIVATE_TOKEN_PERSIST_KEY) =>
  localStorage.getItem(key);
export const getSecondToken = (key = AUTH_TOKEN_PERSIST_KEY) =>
  localStorage.getItem(key);
export const removeToken = (key = AUTH_TOKEN_PERSIST_KEY) =>
  localStorage.removeItem(key);

export const onSetPrivateToken = (token) => {
  setPrivateBearerToken(token);
  saveToken(token, PRIVATE_TOKEN_PERSIST_KEY);

  removeBearerToken();
  removeToken(AUTH_TOKEN_PERSIST_KEY);
};

export const onRemovePrivateToken = () => {
  removePrivateBearerToken();
  removeToken(PRIVATE_TOKEN_PERSIST_KEY);
  window.location.reload();
};
