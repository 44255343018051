import React, { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomizedDialog from "Pages/Account/components/Modal";
import { useIsMobileView } from "hooks";
import logo from "Assets/images/logo-dark.svg";
import { useTranslation } from "react-i18next";
import { Box, Grid, Menu } from "@mui/material";
import CustomLink from "Pages/SupportPages/components/CustomLink";
import { useDispatch } from "react-redux";
import { countries } from "Assets/helper/helper";
import { clearRouteCntrl, resetState } from "store/authanticateSlice";
import { useUserStore } from "hooks/useUser";
import { TIER_MAP } from "constants";
import { isMenuOpenReducer } from "store/publicSlice";
import { useCountryList } from "hooks/onBoardingHooks";
import { setSelectedCountryCodeToStorage } from "utils/setSelectedCountryCodeToStorage";
import { EVENT_TYPE, useDataLayer } from "hooks/useDataLayer";
import LazyImage from "Components/LazyLoad/LazyImage";

const subMenu = {
  fontFamily: "MontserratRegular",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "130%",
  color: "#000000",
  cursor: "pointer"
};
const subMenuArabic = {
  fontFamily: "TajawalRegular",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "17px",
  lineHeight: "130%",
  color: "#000000",
  cursor: "pointer"
};

export const UserMenu = (props) => {
  const { userData, setToken, setAnchorEl, anchorEl, SetNavScroll } = props;

  const { removeUser } = useUserStore();
  const navigate = useNavigate();
  const { setSettingsEvent } = useDataLayer();
  const region = userData?.Customer?.[0]?.PersonalInformation?.Country;
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  useEffect(() => {
    dispatch(isMenuOpenReducer(open));
  }, [dispatch, open]);

  useEffect(() => {
    setSelectedCountryCodeToStorage(
      userData?.Customer?.[0]?.PersonalInformation?.Country
    );
  }, []);
  const isMobileView = useIsMobileView();
  const { t, i18n } = useTranslation();

  //Updates to be made when token is removed

  const removeToken = () => {
    setSettingsEvent(EVENT_TYPE.SIGN_OUT);
    setToken("");
    removeUser();
    dispatch(clearRouteCntrl());
    dispatch(resetState());
    setAnchorEl(null);
    navigate("/");
  };
  const countryList = useCountryList();

  // The filterRegion function appears to filter a list of countries based on a specified region or country code. It uses the countries array and checks each country's countryCode to match the specified region.
  // The userCountry variable, which is memoized using useMemo, looks for a specific country in the countryList based on the maxxing_iso_code property, which is obtained from the region. If found, the country is assigned to the userCountry variable; otherwise, it defaults to an empty object.

  const filterRegion = () => {
    return countries?.filter((country) => country?.countryCode === region);
  };

  const userCountry = useMemo(() => {
    return (
      countryList?.find((country) => country?.maxxing_iso_code === region) || {}
    );
  }, [countryList, region]);

  let lastname = userData?.Customer?.[0]?.PersonalInformation?.LastName;
  let firstname = userData?.Customer?.[0]?.PersonalInformation?.FirstName;

  //The nameControl function is used to format a person's name. If either the firstname or lastname is longer than 90 characters,
  // it shortens the name by taking the first character of the lastname and the first ten characters of the firstname.
  //It then constructs a modified name with ellipses ("...") placed around the shortened parts of the name.
  // The position of the ellipses is adjusted based on the language, either before or after the first name and last name components. This function helps ensure that the person's name is displayed in a user-friendly manner within the specified character limits.

  const nameControl = () => {
    if (lastname?.length > 90 || firstname?.length > 90) {
      const firstCharLastNme = lastname.charAt(0);
      const firstCharName = firstname.slice(0, 10);
      let modifiedlastname;
      if (i18n.language === "arabic") {
        modifiedlastname =
          ".".repeat(3) +
          " " +
          firstCharName +
          ".".repeat(3) +
          firstCharLastNme;
      } else {
        modifiedlastname =
          firstCharName +
          ".".repeat(3) +
          " " +
          firstCharLastNme +
          ".".repeat(3);
      }

      return modifiedlastname;
    } else return firstname + " " + lastname;
  };

  const contactInfo = (
    <div
      className="nav-select-menu"
      style={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
    >
      <>
        <Box
          sx={{
            fontFamily:
              i18n.language === "arabic" ? "TajawalMedium" : "MontserratMedium",
            fontstyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "130%",
            color: "#000000",
            width: "100%"
          }}
        >
          {nameControl()}
        </Box>
        <Grid container>
          <Grid
            xs={12}
            sx={{
              marginTop: "16px",
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "82px"
              }}
            >
              <Box
                className={
                  i18n.language === "arabic"
                    ? "nav-select-menu-light-text-arabic"
                    : "nav-select-menu-light-text"
                }
              >
                {t("Points")}
              </Box>
              <Box
                className={
                  i18n.language === "arabic"
                    ? "nav-select-menu-dark-text-arabic"
                    : "nav-select-menu-dark-text"
                }
              >
                {userData?.Customer?.[0]?.LoyaltyInformaton?.Card
                  ?.CurrentPointBalance || ""}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "150px"
              }}
            >
              <Box
                className={
                  i18n.language === "arabic"
                    ? "nav-select-menu-light-text-arabic"
                    : "nav-select-menu-light-text"
                }
              >
                {t("Tier")}
              </Box>
              <Box
                className={
                  i18n.language === "arabic"
                    ? "nav-select-menu-dark-text-arabic"
                    : "nav-select-menu-dark-text"
                }
              >
                {t(
                  TIER_MAP[
                    userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.Tier
                  ] || ""
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sx={{
              marginTop: "20px",
              marginBottom: "24px",
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "16px",
                background: "#F9F9FD",
                marginTop: "10px",
                marginBottom: "10px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start"
                }}
              >
                <Box
                  className={
                    i18n.language === "arabic"
                      ? "nav-select-menu-dark-text-arabic"
                      : "nav-select-menu-dark-text"
                  }
                  sx={{ marginBottom: "20px", fontSize: "13px" }}
                >
                  {t("Currently in")}
                  {" " + t(filterRegion()[0]?.label)}
                </Box>
                <Box>
                  <LazyImage
                    placeholderSrc={
                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    }
                    lazyclass="lazy"
                    style={{
                      width: "20px",
                      height: "20px"
                    }}
                    src={userCountry?.flag}
                    alt="UAE"
                  />
                </Box>
              </Box>
              <Box
                className={
                  i18n.language === "arabic"
                    ? "nav-select-menu-light-text-arabic"
                    : "nav-select-menu-light-text"
                }
                sx={{ fontSize: "13px" }}
              >
                {t(`Events, offers and stores`)}
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              gap: "16px",
              paddingTop: "20px",
              paddingBottom: "20px",
              position: "relative"
            }}
          >
            <div
              style={{
                borderTop: "1px solid #F3F3FA",
                width: "calc(100% + 40px)",
                position: "absolute",
                top: "0",
                left: "-20px"
              }}
            />
            <CustomLink to={"/dashboard"}>
              <Box sx={i18n.language === "arabic" ? subMenuArabic : subMenu}>
                {t("Dashboard")}
              </Box>
            </CustomLink>
            <CustomLink to={"/purchase-history"}>
              <Box sx={i18n.language === "arabic" ? subMenuArabic : subMenu}>
                {t("Purchase history")}
              </Box>
            </CustomLink>
            <CustomLink to={"/account/personal-details"}>
              <Box sx={i18n.language === "arabic" ? subMenuArabic : subMenu}>
                {t("Account settings")}
              </Box>
            </CustomLink>
          </Grid>
          <Grid
            xs={12}
            sx={{
              backgroundColor: "#FFFFFF",
              // borderTop: "1px solid #F3F3FA",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              gap: "16px",
              paddingTop: "20px",
              position: "relative"
            }}
          >
            <div
              style={{
                borderTop: "1px solid #F3F3FA",
                width: "calc(100% + 40px)",
                position: "absolute",
                top: "0",
                left: "-20px"
              }}
            />
            <CustomLink to={"/faq#faq"}>
              <Box sx={i18n.language === "arabic" ? subMenuArabic : subMenu}>
                {t("Help")}
              </Box>
            </CustomLink>
            <Box
              sx={i18n.language === "arabic" ? subMenuArabic : subMenu}
              onClick={removeToken}
            >
              {t("Sign out")}
            </Box>
          </Grid>
        </Grid>
      </>
    </div>
  );

  //The handleClose function is used to close a menu or dropdown by setting the anchorEl state to null and the NavScroll state to false.

  const handleClose = () => {
    setAnchorEl(null);
    // SetNavScroll(false);
  };

  return (
    <>
      {!isMobileView ? (
        <>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              style: {
                padding: 0
              }
            }}
            sx={{
              top: "40px",
              right: "40px",
              position: "fixed",
              boxShadow: "none",
              padding: "20px",
              width: "100%"
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            {contactInfo}
          </Menu>
        </>
      ) : (
        <CustomizedDialog open={open} hasActions={false} onCancel={handleClose}>
          <Box
            style={{
              maxWidth: "88.1px",
              maxHeight: "40px",
              marginRight: "24px",
              marginLeft: "20px",
              marginTop: "32px"
            }}
          >
            <Link to="/">
              <LazyImage
                placeholderSrc={
                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                }
                lazyclass="lazy"
                src={logo}
                alt="logo"
                width={"88.1px"}
                height={"40px"}
              />
            </Link>
          </Box>
          {contactInfo}
        </CustomizedDialog>
      )}
    </>
  );
};
