import React from "react";
import ViewAllButton from "Assets/ViewAllButton";
import LinkType from "Components/LinkType";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const InfoButtonBox = ({ item, color_theme }) => {
  const { bullets_cta_button } = item || {};
  const { cta_button_fill, cta_button_text, cta_button_border } =
    color_theme?.color_definition || {};

  const { i18n } = useTranslation();

  const englishText = {
    width: { xs: "123px", md: "119px" },
    fontFamily: "MontserratMedium",
    fontSize: "12px",
    lineHeight: "130%",
    backgroundColor: cta_button_fill || "#F3F3FA",
    color: cta_button_text || "#000000",
    border: `1px solid ${cta_button_border || "#000000"}`
  };
  const arabicText = {
    width: { xs: "123px", md: "119px" },
    fontFamily: "TajawalMedium",
    fontStyle: "normal",
    fontweight: "500",
    fontSize: "15px",
    lineHeight: "130%",
    backgroundColor: cta_button_fill || "#F3F3FA",
    color: cta_button_text || "#000000",
    border: `1px solid ${cta_button_border || "#000000"}`
  };
  return (
    <Box
      sx={{
        width: "100%",
        direction: i18n.language === "arabic" ? "rtl" : "ltr"
      }}
    >
      {bullets_cta_button?.title && (
        <LinkType
          link_type={bullets_cta_button?.link_type}
          link={bullets_cta_button?.link}
        >
          <ViewAllButton
            style={i18n.language === "arabic" ? arabicText : englishText}
          >
            {bullets_cta_button?.title}
          </ViewAllButton>
        </LinkType>
      )}
    </Box>
  );
};

export default InfoButtonBox;
