import React, { useMemo } from "react";
import CarousalSliderSwiper from "Components/CarousalSlider";

//The transform function takes an item and an index and transforms the data into a new object with specific properties,
// which is typically used for data mapping and processing.

const transform = (item, index) => ({
  id: index,
  arabicimage: item.image_web,
  image: item.image_web,
  mobileimage: item.image_mobile,
  headText: item.title,
  subText: item.description,
  subImage: item.sub_image,
  subImageMobile: item.sub_image_mobile,
  subImageLink: item.sub_image_link,
  link: item.link
});

export const CarousalSliderSwiperContainer = ({ widget }) => {
  const { auto_slide, color_theme, items = [] } = widget;

  //The mappedItems variable is generated using the useMemo hook, which processes a list of items, assigns IDs,
  // and applies a transformation function to create a new list of transformed items,
  // optimizing performance by recalculating only when the items array changes.

  const mappedItems = useMemo(() => items.map(transform), [items]);

  return (
    <CarousalSliderSwiper
      helperArray={mappedItems}
      color_theme={color_theme}
      auto_slide={auto_slide}
    />
  );
};
