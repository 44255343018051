import { Box, CircularProgress, Grid, MenuItem, Select } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicTable from "Assets/ResponsiveTable/Responsive";
import LightBackground from "Components/LightBackground";
import { useIsLanguageArabic, useIsMobileView } from "hooks";
import Layout from "../Layout";
import BrowseButton from "Pages/common/Promotions/BrowseButton";
import { EmptyPurchase } from "Pages/common/Promotions/Promotions";
import { useGetPurchaseHistory } from "services/account/account.query";
import { selectPathPrevious } from "store/pathPreviousSlice";
import { useDispatch } from "react-redux";
import {
  menuTextStyle,
  menuTextStylearabic,
  selectText,
  selectTextArabic
} from "Components/LocationComponent/styles";
import CustomSelectIcon from "Components/CustomSelectIcon";
import PurchaseContainer from "./PurchaseContainer";

const webPaginationSize = 20;
const mobileViewPaginationSize = 10;

// Sorting constants
const SORT_CONFIG = {
  TransactionDate: {
    displayName: "Date",
    defaultType: "newest",
    types: {
      newest: {
        displayName: "Newest First",
        compare: (a, b) =>
          new Date(b.TransactionDate) - new Date(a.TransactionDate)
      },
      leastNew: {
        displayName: "Oldest First",
        compare: (a, b) =>
          new Date(a.TransactionDate) - new Date(b.TransactionDate)
      }
    }
  },
  Points: {
    displayName: "Points",
    defaultType: "higherFirst",
    types: {
      higherFirst: {
        displayName: "Higher First",
        compare: (a, b) => parseInt(b.Points, 10) - parseInt(a.Points, 10)
      },
      lowerFirst: {
        displayName: "Lower First",
        compare: (a, b) => parseInt(a.Points, 10) - parseInt(b.Points, 10)
      }
    }
  },
  BrandName: {
    displayName: "Merchant",
    defaultType: "AtoZ",
    types: {
      AtoZ: {
        displayName: "A-Z",
        compare: (a, b) => a.BrandName.localeCompare(b.BrandName)
      },
      ZtoA: {
        displayName: "Z-A",
        compare: (a, b) => b.BrandName.localeCompare(a.BrandName)
      }
    }
  },
  Amount: {
    displayName: "Amount",
    defaultType: "higherFirst",
    types: {
      higherFirst: {
        displayName: "Higher First",
        compare: (a, b) => {
          const parsedAAmount = Number(a.PurchaseValue.split(" ")[0]);
          const parsedBAmount = Number(b.PurchaseValue.split(" ")[0]);

          return parseInt(parsedBAmount, 10) - parseInt(parsedAAmount, 10);
        }
      },
      lowerFirst: {
        displayName: "Lower First",
        compare: (a, b) => {
          const parsedAAmount = a.PurchaseValue.split(" ")[0];
          const parsedBAmount = b.PurchaseValue.split(" ")[0];
          return parseInt(parsedAAmount, 10) - parseInt(parsedBAmount, 10);
        }
      }
    }
  },
  Status: {
    displayName: "Status",
    defaultType: "AtoZ",
    types: {
      AtoZ: {
        displayName: "A-Z",
        compare: (a, b) => a.TransactionType.localeCompare(b.TransactionType)
      },
      ZtoA: {
        displayName: "Z-A",
        compare: (a, b) => b.TransactionType.localeCompare(a.TransactionType)
      }
    }
  }
};

const PurchaseHistory = ({ isMock = false }) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const isMobileView = useIsMobileView();
  const [pageSize, setPageSize] = useState(() =>
    isMobileView ? mobileViewPaginationSize : webPaginationSize
  );
  const isLanguageArabic = useIsLanguageArabic();
  const {
    data: purchaseHistoryData,
    isSuccess,
    isLoading
  } = useGetPurchaseHistory();
  const dispatch = useDispatch();
  // Sorting
  const [sortBy, setSortBy] = useState("TransactionDate");
  const [sortType, setSortType] = useState("newest");

  // sortData:  It creates a copy of the original data array to avoid mutating the original data.
  // It uses conditional statements to determine the sorting logic based on the sortBy and sortType values. The sorting logic varies depending on the selected sorting criteria.
  // If sortBy is "Amount" or "Points," it extracts and parses the numeric values from the "PurchaseValue" or "Points" properties of the data objects and sorts them accordingly.
  // If sortBy is "TransactionDate," it converts the "TransactionDate" property into Date objects and sorts them by date.
  // If none of the predefined criteria match, it falls back to a custom sorting logic defined in the SORT_CONFIG object, which seems to be an object containing various sorting criteria and comparison functions.
  // Finally, it returns the sorted sortedData array.

  const sortData = useCallback(
    (data) => {
      let sortedData = [...data];

      if (sortBy === "Amount") {
        sortedData.sort((a, b) => {
          const parsedAAmount = Number(a.PurchaseValue.split(" ")[0]);
          const parsedBAmount = Number(b.PurchaseValue.split(" ")[0]);

          if (sortType === "higherFirst") {
            return parsedBAmount - parsedAAmount;
          } else {
            return parsedAAmount - parsedBAmount;
          }
        });
      } else if (sortBy === "Points") {
        sortedData.sort((a, b) => {
          const parsedAAmount = Number(a.Points);
          const parsedBAmount = Number(b.Points);

          if (sortType === "higherFirst") {
            return parsedBAmount - parsedAAmount;
          } else {
            return parsedAAmount - parsedBAmount;
          }
        });
      } else if (sortBy === "TransactionDate") {
        sortedData.sort((a, b) => {
          const parsedAAmount = new Date(a.TransactionDate);
          const parsedBAmount = new Date(b.TransactionDate);

          if (sortType === "newest") {
            return parsedBAmount - parsedAAmount;
          } else {
            return parsedAAmount - parsedBAmount;
          }
        });
      } else {
        sortedData.sort((a, b) => {
          return SORT_CONFIG[sortBy].types[sortType].compare(a, b);
        });
      }

      return sortedData;
    },
    [sortBy, sortType]
  );

  const paginateData = useCallback(
    (data) => {
      return data.slice(0, currentPage * pageSize);
    },
    [currentPage, pageSize]
  );

  useEffect(() => {
    setPageSize(isMobileView ? mobileViewPaginationSize : webPaginationSize);
  }, [isMobileView]);

  useEffect(() => {
    dispatch(selectPathPrevious("/purchase-history"));
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess && purchaseHistoryData?.Customer && !isLoading) {
      if (
        purchaseHistoryData?.Customer[0]?.LoyaltyTransactions?.Transactions
          ?.length > 0
      ) {
        setTotalSize(
          purchaseHistoryData?.Customer[0]?.LoyaltyTransactions?.Transactions
            ?.length
        );

        const ogPuchaseHistory =
          purchaseHistoryData?.Customer[0]?.LoyaltyTransactions?.Transactions;
        const tempTableData = ogPuchaseHistory.map((data, index) => ({
          ...data,
          stableIndex: index
        }));

        const sortedData = sortData(tempTableData);

        setTableData(paginateData(sortedData));
      } else {
        setTotalSize(0);
        setTableData([]);
      }
    }
  }, [
    isLoading,
    isSuccess,
    purchaseHistoryData,
    setTotalSize,
    sortBy,
    sortData,
    sortType,
    paginateData
  ]);

  useEffect(() => {
    if (isSuccess && purchaseHistoryData?.Customer && !isLoading) {
      let transactions =
        purchaseHistoryData?.Customer[0]?.LoyaltyTransactions?.Transactions ||
        [];
      if (transactions.length > 0) {
        setTotalSize(transactions.length);
        const ogPuchaseHistory =
          purchaseHistoryData?.Customer[0]?.LoyaltyTransactions?.Transactions;
        const tempTableData = ogPuchaseHistory.map((data, index) => ({
          ...data,
          stableIndex: index
        }));

        setTableData(paginateData(sortData(tempTableData)));
      } else {
        setTotalSize(0);
        setTableData([]);
      }
    }
  }, [
    isLoading,
    isSuccess,
    purchaseHistoryData,
    setTotalSize,
    sortBy,
    sortData,
    sortType,
    paginateData
  ]);

  if (isLoading && !isSuccess) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isLoading ? "100vh" : "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  }

  return (
    <Layout>
      <PurchaseContainer>
        <LightBackground
          text={t("Purchase history")}
          paddingTp="0px"
          xsMinHeight="200px"
          minheight="320px"
          containerStyle={{
            justifyContent: "flex-start"
          }}
        />
        {totalSize ? (
          <>
            <Grid
              container
              sx={{
                paddingLeft: isMobileView ? "20px" : "11.11%",
                paddingRight: isMobileView ? "20px" : "11.11%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "59px",
                alignItems: "center",
                marginBottom: isMobileView ? "0" : "74px",
                flexDirection: isMobileView ? "column" : "row",
                direction: isLanguageArabic ? "rtl" : "initial"
              }}
            >
              <Grid item sm={12} md={6} style={{ width: "100%" }}>
                {!isMobileView ? (
                  <span
                    style={
                      i18n.language === "arabic"
                        ? {
                            fontFamily: "TajawalRegular",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "19.7px"
                          }
                        : {
                            fontFamily: "MontserratRegular",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "18px"
                          }
                    }
                  >
                    {t(
                      "You will see the transactions that belong to the last 2 years"
                    )}
                  </span>
                ) : null}

                {isMobileView ? (
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      marginBottom: "16px",
                      fontFamily: "MontserratRegular",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "18.2px"
                    }}
                    className={
                      i18n.language === "arabic"
                        ? "transaction-message-arabic"
                        : "transaction-message"
                    }
                  >
                    {t(
                      "You will see the transactions that belong to the last 2 years"
                    )}
                  </p>
                ) : null}
              </Grid>
              {isMobileView ? (
                <div className="stor-filters-main">
                  <label>{t("Sort By")} :</label>
                  <div className="sort-filters-wrapper">
                    <Select
                      sx={
                        i18n.language === "arabic"
                          ? selectTextArabic
                          : selectText
                      }
                      className="sort-filter"
                      IconComponent={CustomSelectIcon}
                      value={sortBy}
                      onChange={(e) => {
                        const newSortBy = e.target.value;
                        setSortBy(newSortBy);
                        setSortType(SORT_CONFIG[newSortBy].defaultType);
                      }}
                    >
                      {Object.entries(SORT_CONFIG).map(
                        ([key, { displayName }]) => (
                          <MenuItem
                            key={key}
                            value={key}
                            sx={
                              i18n.language === "arabic"
                                ? menuTextStylearabic
                                : menuTextStyle
                            }
                          >
                            {t(displayName)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <Select
                      sx={
                        i18n.language === "arabic"
                          ? selectTextArabic
                          : selectText
                      }
                      className="sort-filter"
                      value={sortType}
                      onChange={(e) => setSortType(e.target.value)}
                      IconComponent={CustomSelectIcon}
                    >
                      {Object.entries(SORT_CONFIG[sortBy].types).map(
                        ([type, { displayName }]) => (
                          <MenuItem
                            key={type}
                            value={type}
                            sx={
                              i18n.language === "arabic"
                                ? menuTextStylearabic
                                : menuTextStyle
                            }
                          >
                            {t(displayName)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                </div>
              ) : null}
            </Grid>

            <Grid>
              <BasicTable
                showMoreButton={currentPage * pageSize < totalSize}
                data={tableData}
                EmptyComponent={<EmptyPurchase />}
                CustomViewAllButton={
                  <BrowseButton
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    style={{
                      marginTop: isMobileView ? "24px" : "0px"
                    }}
                  >
                    {t("Show more transactions")}
                  </BrowseButton>
                }
                loadMoreJustifyContent={true}
              />
            </Grid>
          </>
        ) : (
          <Box sx={{ marginTop: "56px", marginBottom: "76px" }}>
            <EmptyPurchase />
          </Box>
        )}{" "}
      </PurchaseContainer>
    </Layout>
  );
};

export default PurchaseHistory;
