import "./_serviceMessages/serviceMessages";
import {
  getApiClient,
  getAxiosInstance,
  getBearerTokenFunc,
  getRemoveBearerTokenFunc
} from "./commonApiClient";

export const privateApiAxiosInstance = getAxiosInstance();
export const privateApiClient = getApiClient(privateApiAxiosInstance);
export const setPrivateBearerToken = getBearerTokenFunc(
  privateApiAxiosInstance
);
export const removePrivateBearerToken = getRemoveBearerTokenFunc(
  privateApiAxiosInstance
);
