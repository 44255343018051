import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsLanguageArabic } from "hooks";
import logoUnion from "Assets/images/logo-white.svg";
import AuthanticateButtons from "Components/AuthanticateButtons";
import MobileNavMenu from "Components/MobileNavMenu";
import { UserMenu } from "Components/UserMenu";
import LinkType from "Components/LinkType";
import LazyImage from "Components/LazyLoad/LazyImage";

const drawerWidth = "100%";

export const NavBar = (props) => {
  const {
    window,
    propsclass,
    isHover,
    NavScroll,
    userData,
    setToken,
    token,
    SetNavScroll,
    navbar,
    defaultTool,
    showDefaultLogo,
    sxAppBar = {},
    sxDrawer = {},
    sxXsLogoLinkBox = {},
    sxLgLogoLinkBox = {},
    navItems = [],
    isMenuOpen,
    color_definition,
    logo,
    setIsHover
  } = props;
  const { i18n } = useTranslation();
  const tabletMax = useMediaQuery("(max-width:720px)");
  const desktopMin = useMediaQuery("(max-width:1280px)");
  const isLanguageArabic = useIsLanguageArabic();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { normal_link, hover_link, background_color, main_nav_hover } =
  color_definition || {};

  //  The handleClick function is designed to open a menu or popover by setting the anchorEl state based on the provided event object. This function is typically used in a user interface when a user clicks on a button or element, triggering the opening of a related menu or popover at the clicked location.
  // The handleDrawerToggle function is used to toggle the state of mobileOpen. It is commonly employed in responsive web applications to control the visibility of a mobile navigation drawer or sidebar. When this function is called, it reverses the current state, making the drawer open if it's closed, and vice versa. This allows users to show or hide the mobile navigation menu as needed.

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const authanticatebuttons = {
    propsclass,
    isHover,
    NavScroll,
    userData,
    token,
    handleClick,
    handleDrawerToggle,
    navbar,
    defaultTool,
    color_definition
  };
  const authanticateStates = {
    userData,
    setToken,
    token,
    setAnchorEl,
    anchorEl,
    SetNavScroll,
    color_definition
  };
  const authstates = {
    handleClick,
    token,
    setToken,
    userData,
    setMobileOpen,
    color_definition
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const LogoWithLink = (
    <Link to="/">
      <LazyImage
        placeholderSrc={
          "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        }
        lazyclass="lazy"
        width={"100%"}
        height={"100%"}
        src={showDefaultLogo ? logoUnion : logo}
        alt="logo"
      />
    </Link>
  );

  // The linkButton function appears to generate a button element based on the properties of an item object. It sets various styles for the button, including padding, font family, and font size. The button content is wrapped in a LinkType component, which handles different link types and styles for the link based on its properties. The button is created using the Button component from some UI library (not specified in the provided code).
  // The handleMouseEnter and handleMouseLeave functions are used to track the mouse's hover state over an element. When the mouse enters the element (hovering), setIsHover is set to true, and when the mouse leaves (no longer hovering), setIsHover is set to false. These functions are likely used to apply styles or behaviors when the user hovers over or moves away from the button or element.

  const linkButton = (item) => {
    if (item) {
      return (
        <Button
          key={item.title}
          sx={{
            paddingLeft: desktopMin ? "8px" : "16px",
            paddingRight: desktopMin ? "8px" : "16px",
            fontFamily:
              i18n.language === "arabic"
                ? "TajawalRegular"
                : "MontserratRegular",
            fontSize: i18n.language === "arabic" ? "17px" : "14px"
          }}
          className={propsclass.navButton}
        >
          <LinkType
            link={item.link}
            link_type={item.link_type}
            stylesLink={
              !NavScroll && !isHover && !isMenuOpen ? normal_link : hover_link
            }
          >
            {" "}
            {item.title}
          </LinkType>
        </Button>
      );
    }
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Box
      sx={{ display: "flex", position: "absolute", width: "100%", top: 0 }}
      className="Nav"
    >
      <AppBar
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        component="nav"
        className={propsclass.navClass}
        sx={sxAppBar}
        style={{
          backgroundColor:
            !NavScroll && !isHover && !isMenuOpen
              ? background_color
              : main_nav_hover
        }}
      >
        <Toolbar
          sx={{
            justifyContent: { xs: "space-between" },
            flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
            alignItems: "center",
            minHeight: tabletMax ? "48px !important" : "80px !important",
            paddingLeft: { xs: "16px !important", lg: "36px !important" },
            paddingRight: { xs: "16px !important", lg: "36px !important" }
          }}
        >
          <Box
            sx={{
              display: { xs: "block", lg: "none" },
              maxWidth: "61.67px",
              maxHeight: "28px",
              ...sxXsLogoLinkBox
            }}
          >
            {LogoWithLink}
          </Box>

          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
              ...sxLgLogoLinkBox
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                mr: 7,
                display: { xs: "none", lg: "flex" },
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                marginLeft: i18n.language === "arabic" ? "56px" : ""
              }}
            >
              <Box
                sx={{
                  marginRight:
                    i18n.language === "arabic"
                      ? { xs: "20px", xl: "40px" }
                      : "",
                  maxWidth: "74.89px",
                  maxHeight: "34px"
                }}
              >
                {LogoWithLink}
              </Box>
            </Typography>
            {navItems.map((item) => linkButton(item))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
              alignItems: "center"
            }}
          >
            <AuthanticateButtons {...authanticatebuttons} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          SlideProps={{
            direction: isLanguageArabic ? "left" : "right"
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: main_nav_hover
            },
            ...sxDrawer
          }}
        >
          <MobileNavMenu navItems={navItems} {...authstates} logo={logo} />
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
      <UserMenu {...authanticateStates} />
    </Box>
  );
};
