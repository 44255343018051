import React from "react";
import { NavBarContainer } from "../NavBarContainer";
import { useGetBrands } from "services/brands/brands.query";

const ErrorContainer = ({ children }) => {
  const { data: brands = [] } = useGetBrands();
  const { widgets = [] } = brands;
  const userAgent = window?.navigator?.userAgent;

  const navbar = widgets?.find(
    (widget) => widget?.component_type === "web-homepage-main-navigation"
  );

  return (
    <>
      {!userAgent?.includes("amber") && <NavBarContainer widget={navbar} />}
      {children}
    </>
  );
};

export default ErrorContainer;
