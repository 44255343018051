import { useState } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Vector from "Assets/images/Vectorwhite.svg";

const CustomSelectIcon = (props) => {
  return (
    <img src={Vector} alt="Custom Icon" {...props} sx={{ color: "red" }} />
  );
};

const FaqQuestions = ({ id, widget, color_theme }) => {
  const { i18n } = useTranslation();
  const [expand, setExpand] = useState(false);
  const { background_color, header_text, description, title } =
    color_theme?.color_definition || {};
   // This code takes an array of objects called "widget.items" and creates a new array of objects, "mappedObj,"
  // where each object consists of a "name" property derived from the "title" in the original objects, a "description"
  //property extracted from the "description" field, and a "panel" property that follows the format "Panel-N," with N being the index of the original object plus one.

  const mappedObj = widget?.configurations?.[0]?.items?.map((item, index) => {
    return {
      name: item?.title,
      description: item?.description,
      panel: `Panel-${index + 1}`
    };
  });

  const text = {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "130%",
    color: description || "#FFFFFF"
  };
  const textArabic = {
    fontFamily: "TajawalRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "17px",
    lineHeight: "130%",
    color: description || "#FFFFFF",
    textAlign: "right",
    direction: "rtl"
  };
  const headText = {
    textAlign: "left",
    height: "100%",
    textDecoration: "none",
    fontFamily: "MontserratMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: " 130%",
    color: title || "#FFFFFF",
    direction: "ltr"
  };
  const headTextArabic = {
    fontFamily: "TajawalMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "19px",
    lineHeight: "130%",
    textAlign: "right",
    color: title || "#FFFFFF"
  };

  //handleChange: This function takes a panel as a parameter and returns a function. When this returned function is called with an event and an isExpanded boolean, it sets the state variable expand to the panel value if isExpanded is true; otherwise, it sets expand to false.
  //borderExpandedStyle: This function takes an index as a parameter. It is used to determine the border style for a specific panel. If the index is equal to the length of the mappedObj array minus one, it returns a border style with "0px" width.

  const handleChange = (panel) => (event, isExpanded) => {
    setExpand(isExpanded ? panel : false);
  };
  const borderExpandedStyle = (index) => {
    return index === mappedObj.length - 1
      ? "0px"
      : "1px solid" + title || "1px solid white";
  };
  return (
    <>
      <Box
        className="your-questions-main"
        id={id}
        style={{ backgroundColor: background_color || "" }}
      >
        <Box
          sx={{ maxWidth: "800px", paddingLeft: "20px", paddingRight: "20px" }}
        >
          <div
            className={
              i18n.language === "arabic"
                ? "your-questions-main-head-arabic"
                : "your-questions-main-head"
            }
            style={{ color: header_text || "" }}
          >
            {widget?.configurations?.[0]?.title}
          </div>
          {mappedObj.map((label, index) => (
            <Accordion
              expanded={expand === label.panel}
              onChange={handleChange(label.panel)}
              sx={{
                backgroundColor: "transparent",
                color: "#90969e",
                fontFamily: "MontserratRegular",
                boxShadow: "none",
                "&.MuiAccordion-root:before": {
                  height: "0px"
                },
                "&.MuiAccordion-root": {
                  borderBottom: borderExpandedStyle(index)
                }
              }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<CustomSelectIcon color="red" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  padding: " 0px",
                  minHeight: "72px",
                  display: "flex",
                  flexDirection:
                    i18n.language === "arabic" ? "row-reverse" : "row",
                  "& .MuiAccordionSummary-content": {
                    display: "flex",
                    flexDirection:
                      i18n.language === "arabic" ? "row-reverse" : "row"
                  }
                }}
              >
                <Box
                  style={i18n.language === "arabic" ? headTextArabic : headText}
                  sx={{
                    display: "flex",
                    flexDirection:
                      i18n.language === "arabic" ? "row-reverse" : "row",
                    paddingRight: "0px"
                  }}
                >
                  {label.name}
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Typography>
                  <List>
                    <ListItem disablePadding>
                      <ListItemText
                        primaryTypographyProps={{
                          style: i18n.language === "arabic" ? textArabic : text
                        }}
                        primary={label.description}
                        sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                      />
                    </ListItem>
                  </List>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default FaqQuestions;
