import React from "react";
import Return from "Assets/images/Return.svg";
import ReturnArabic from "Assets/images/ReturnArabic.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyImage from "Components/LazyLoad/LazyImage";

const BackButton = ({ path }) => {
  const { i18n } = useTranslation();

  // The imageStyle object defines the styling for an image element. Depending on the current language (determined by i18n.language), it adjusts the image's position within a container.
  //  If the language is "arabic," the image is placed on the right side, and if it's not "arabic," the image is on the left. The top position is 20 pixels in both cases, and the zIndex
  //   is set to 3, ensuring the image's stacking order. The code allows the image's positioning to adapt to the container's width based on the language setting.

  const imageStyle = {
    position: "absolute",
    left: i18n.language === "arabic" ? "auto" : 20,
    top: 20,
    right: i18n.language === "arabic" ? 20 : "auto",
    zIndex: 3
  };

  return (
    <>
      <Link to={path || "/"}>
        <LazyImage
          placeholderSrc={
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          }
          lazyclass="lazy"
          src={i18n.language === "arabic" ? ReturnArabic : Return}
          style={imageStyle}
          alt="Return"
        />
      </Link>
    </>
  );
};

export default BackButton;
