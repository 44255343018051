import React, { useMemo } from "react";

import AmberStatus from "Components/HowItWorks/AmberStatus";
import { useGetOnAppConfigContent } from "services/cms/cms.query";
import { AnchorLinkEnums } from "utils/enums";

export const AmberStatusContainer = ({ widget }) => {
  const { color_theme, widgets } = widget;
  const { data: appConfigContent = {} } = useGetOnAppConfigContent();

  //The mappedItems variable is generated using the useMemo hook, which processes a list of items, assigns IDs,
  // and applies a transformation function to create a new list of transformed items,
  // optimizing performance by recalculating only when the items array changes.

  const amberTierMapping = useMemo(() => {
    return appConfigContent?.items?.[0]?.fields?.configuration?.Configurations?.find(
      (item) => item?.configuration_name === "amber_tier_mapping"
    );
  }, [appConfigContent]);

  return (
    <AmberStatus
      id={AnchorLinkEnums.POINTS_CALCULATOR}
      widget={widget}
      color_theme={color_theme}
      widgets={widgets}
      amberTierMapping={amberTierMapping}
    />
  );
};
