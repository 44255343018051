import { useQuery } from "@tanstack/react-query";
import * as Requests from "./offers.requests";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetOffers = () => {
  const tierValue = useTierValue();
  return useQuery(
    ["fetch-events-and-offers-content", tierValue],
    () => {
      return Requests.getOffers({ segment: tierValue });
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};
export const useGetOfferCategories = () => {
  return useQuery(["offer-categories"], () => {
    return Requests.getOfferCategories();
  });
};

export const useGetOfferDetailsByID = ({ id }) => {
  return useQuery(
    ["fetch-offers-content", id],
    () => {
      return Requests.getOfferDetailsByID({ id });
    },
    {
      enabled: !!id
    }
  );
};

export const useGetOffersLanding = ({ content_type }) => {
  return useQuery(["fetch-offers-content"], () => {
    return Requests.getOffersLanding({ content_type });
  });
};
