import React from "react";
import ReactPlayer from "react-player";

// isColor: It checks if a given string is a valid color.

export const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

// LengthControl: Truncates a text string to a specified size if it exceeds the size.

export const LengthControl = (text, size) => {
  if (text?.length > size) {
    const textFirst = text.slice(0, size);
    const modifiedtext = textFirst + ".".repeat(3);
    return modifiedtext;
  } else return text;
};

// MapCategories: Accepts category data and converts it into a string format with a maximum length of 40 characters.

export const MapCategories = (category) => {
  if (typeof category !== "string") {
    return LengthControl(
      category?.filter?.((item) => item !== "" || item !== " ").join(", "),
      40
    );
  } else return LengthControl(category, 40);
};

// LineControl: Limits the number of visible lines in a text string based on the maxLines parameter.

export const LineControl = (text, maxLines) => {
  const textLines = text.split("\n");
  const visibleText = textLines.slice(0, maxLines).join("\n");
  return visibleText;
};

// LightBackgroundControl: Checks if a list of widgets contains a specific component type ("web-homepage-slider").

export const LightBackgroundControl = (widgets) => {
  return !!widgets?.find?.(
    (widget) => widget?.component_type === "web-homepage-slider"
  );
};

// CategoriesRule: Combines primary and secondary categories into a single string.

export const CategoriesRule = (primary_category, secondary_category) => {
  if (primary_category && secondary_category) {
    return primary_category + ", " + secondary_category;
  } else if (primary_category && !secondary_category) {
    return primary_category;
  } else if (!primary_category && secondary_category) {
    return secondary_category;
  } else return null;
};

// EventsOffersAsset: Renders an asset, which can be either a video or an image, based on the asset type.

export const EventsOffersAsset = (item) => {
  if (item?.asset_type === "VIDEO") {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={item?.asset}
          width="100%"
          height="100%"
          muted={true}
          controls={false}
          playing={true}
          loop={true}
        />
      </div>
    );
  } else
    return (
      <img
        className="brand-carousel-image"
        width="100%"
        src={item?.asset}
        alt={item?.name}
      />
    );
};

// EventsOffersAssetMobile: Similar to EventsOffersAsset, but for mobile devices.

export const EventsOffersAssetMobile = (item) => {
  if (item?.asset_type === "VIDEO") {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={item?.asset}
          width="100%"
          height="100%"
          muted={true}
          controls={false}
          playing={true}
          loop={true}
        />
      </div>
    );
  } else
    return (
      <img
        className="brand-carousel-image brandCarouselMobileImg"
        width="100%"
        src={item?.asset_mobile}
        alt={item?.name}
      />
    );
};

// DisplayOptionFunc: Checks if a display option and a path meet specific conditions for display.

export const DisplayOptionFunc = (display_option, path) => {
  return !!(
    (display_option?.toLowerCase().includes("web") ||
      display_option?.toLowerCase().includes("both")) &&
    path
  );
};


export const categoryListFilteredHeightControl = (Length, loading) => {
  if (loading) {
    return Length <= 4 ? "20vh" : "50vh";
  }
  return "100%";
}