import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Grid } from "@mui/material";

import LightBackground from "Components/LightBackground";
import { useIsLanguageArabic, useIsMobileView } from "hooks";
import Layout from "../Layout";
import BackNavigator from "Components/BackNavigator";
import { useGetPurchaseHistory } from "services/account/account.query";
import { formatDate } from "dateUtil";
import i18n from "i18n";
import PurchaseContainer from "./PurchaseContainer";

const PurchaseCard = ({ id, singleData }) => {
  const { t } = useTranslation();
  const isLanguageArabic = useIsLanguageArabic();
  const isMobileView = useIsMobileView();
  return (
    <Box
      sx={{
        width: "100%",
        height: isMobileView ? "initial" : "214px",
        border: "1px solid #DFE1EA",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
          borderBottom: "1px solid #DFE1EA",
          height: "62px"
        }}
      >
        <span
          style={{
            fontFamily: isLanguageArabic ? "TajawalMedium" : "MontserratMedium",
            fontSize: isLanguageArabic ? "19px" : "16px",
            lineHeight: isLanguageArabic ? "24.7px" : "20.8px"
          }}
        >
          {id === "1" ? t("Total Amber Points") : t("Transaction amount")}
        </span>
        <div
          style={{
            padding: "4px 12px",
            background: id === "1" ? "#E7F0DF" : "transparent",
            borderRadius: "18px"
          }}
        >
          <span
            style={{
              fontFamily: isLanguageArabic
                ? "TajawalMedium"
                : "MontserratMedium",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "18.2px"
            }}
          >
            {id === "1"
              ? (singleData.Points * 1 === 0
                  ? " "
                  : singleData.Points * 1 > 0
                  ? "+ "
                  : "- ") +
                Math.abs(singleData.Points) +
                (i18n.language === "arabic" ? " نقطة" : " pts")
              : `${singleData.PurchaseValue.split(" ")[1]} ${
                  singleData.PurchaseValue.split(" ")[0]
                }`}
          </span>
        </div>
      </Box>
      <Box
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: "20px",
          gap: "16px",
          height: "204px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span
            style={
              isLanguageArabic
                ? {
                    height: "18px",
                    fontFamily: "TajawalRegular",
                    fontSize: "17px",
                    lineHeight: "130%",
                    fontWeight: 400
                  }
                : {
                    fontFamily: "MontserratRegular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "21px",
                    color: "#5A5F64"
                  }
            }
          >
            {id === "2" ? t("Date") : t("Standard Points")}
          </span>
          <span
            style={
              isLanguageArabic
                ? {
                    height: "18px",
                    fontFamily: "TajawalRegular",
                    fontSize: "17px",
                    lineHeight: "130%",
                    fontWeight: 400
                  }
                : {
                    fontFamily: "MontserratRegular",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "130%"
                  }
            }
          >
            {id === "2"
              ? formatDate({
                  dateText: singleData.TransactionDate,
                  language: i18n.language
                })
              : Math.abs(singleData.Points) +
                (i18n.language === "arabic" ? " نقطة" : " pts")}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span
            style={
              isLanguageArabic
                ? {
                    height: "18px",
                    fontFamily: "TajawalRegular",
                    fontSize: "17px",
                    lineHeight: "130%",
                    fontWeight: 400
                  }
                : {
                    fontFamily: "MontserratRegular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "21px",
                    color: "#5A5F64"
                  }
            }
          >
            {id === "1" ? t("Bonus Points") : null}
          </span>
          <span
            style={
              isLanguageArabic
                ? {
                    height: "18px",
                    fontFamily: "TajawalRegular",
                    fontSize: "17px",
                    lineHeight: "130%",
                    fontWeight: 400
                  }
                : {
                    fontFamily: "MontserratRegular",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "130%"
                  }
            }
          >
            {id === "1"
              ? 0 + (i18n.language === "arabic" ? " نقطة" : " pts")
              : null}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span
            style={
              isLanguageArabic
                ? {
                    height: "18px",
                    fontFamily: "TajawalRegular",
                    fontSize: "17px",
                    lineHeight: "130%",
                    fontWeight: 400,
                    marginTop: "-32px"
                  }
                : {
                    fontFamily: "MontserratRegular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "21px",
                    color: "#5A5F64",
                    marginTop: "-16px"
                  }
            }
          >
            {id === "2" ? t("Type") : null}
          </span>
          <span
            style={
              isLanguageArabic
                ? {
                    height: "18px",
                    fontFamily: "TajawalRegular",
                    fontSize: "17px",
                    lineHeight: "130%",
                    fontWeight: 400,
                    marginTop: "-32px"
                  }
                : {
                    fontFamily: "MontserratRegular",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "130%",
                    marginTop: "-16px"
                  }
            }
          >
            {id === "2" ? t(singleData.TransactionType) : null}
          </span>
        </div>
      </Box>
    </Box>
  );
};

const PurchaseDetail = () => {
  const { id } = useParams();
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();
  const isLanguageArabic = useIsLanguageArabic();
  const pathPrevious = useSelector((state) => state.pathPrevious);
  const [singleData, setSingleData] = useState(null);
  const {
    data: purchaseHistoryData,
    isSuccess,
    isLoading
  } = useGetPurchaseHistory();

  // const downloadReceipt = (
  //     <div style={{ marginTop: isMobileView ? "32px" : "0" }}>
  //         <BrowseButton
  //             onClick={() => {
  //                 // onDownloadReceipt();
  //             }}
  //         >
  //             {t("Download Receipt")}
  //         </BrowseButton>
  //     </div>
  // );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      isSuccess &&
      purchaseHistoryData?.Customer[0]?.LoyaltyTransactions?.Transactions &&
      !isLoading &&
      id
    ) {
      const tempData =
        purchaseHistoryData?.Customer[0]?.LoyaltyTransactions?.Transactions?.find(
          (item, index) => index === parseInt(id)
        );
      setSingleData(tempData);
    }
  }, [purchaseHistoryData, isSuccess, isLoading, id]);
  if (isLoading && !isSuccess) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isLoading ? "100vh" : "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  }

  return (
    <Layout>
      <PurchaseContainer>
        <Grid
          style={{
            width: "inherit",
            height: "inherit",
            direction: isLanguageArabic ? "rtl" : "ltr"
          }}
        >
          <LightBackground
            text={t("Purchase Details")}
            textContainerStyle={{
              fontSize: isMobileView ? "38px !important" : "initial"
            }}
            containerStyle={{
              ...(isMobileView ? { justifyContent: "flex-start" } : {}),
              flexDirection: "initial"
            }}
            xsMinHeight="200px"
            paddingTp="0px"
            minheight="320px"
          >
            <BackNavigator
              path={pathPrevious ? pathPrevious : "/purchase-history"}
              title={
                pathPrevious === "/dashboard"
                  ? t("Back to Dashboard")
                  : t("Back to Purchase History")
              }
            />
          </LightBackground>
          {singleData && (
            <Box
              sx={{
                flexDirection: "column",
                paddingLeft: isMobileView ? "20px" : "11.11%",
                paddingRight: isMobileView ? "20px" : "11.11%",
                display: "flex",
                paddingBottom: "48px",
                paddingTop: "48px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "32px"
                }}
              >
                {/* <img src={""} width="248px" height="50px" alt="blooming" /> */}
                <span
                  style={{
                    fontFamily: isLanguageArabic
                      ? "TajawalMedium"
                      : "MontserratMedium",
                    fontSize: isLanguageArabic ? "27px" : "25px",
                    lineHeight: isLanguageArabic ? "24.7px" : "20.8px"
                  }}
                >
                  {singleData.BrandName.split("-")[0]}
                </span>
                {/* {!isMobileView && downloadReceipt} */}
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: isMobileView ? "16px" : "24px",
                  flexDirection: isMobileView ? "column" : "row"
                }}
              >
                <PurchaseCard singleData={singleData} id={"1"} />
                <PurchaseCard singleData={singleData} id={"2"} />
              </Box>
              {/* {isMobileView && downloadReceipt} */}
            </Box>
          )}
        </Grid>
      </PurchaseContainer>
    </Layout>
  );
};

export default PurchaseDetail;
