import * as React from "react";
import { useTranslation } from "react-i18next";
import { Backdrop, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import CloseImage from "Assets/images/close.png";
import { useIsLanguageArabic, useIsMobileView } from "hooks";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import PrimaryButton from "../components/PrimaryButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  },
  "& .MuiDialog-paper": {
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      maxHeight: "initial"
    },
    margin: 0
  }
}));

const BackdropComponent = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  }
})({ zIndex: -1, backgroundColor: "rgba(0,0,0,0.8)" });

function BootstrapDialogTitle(props) {
  const { onClose } = props;
  const isLanguageArabic = useIsLanguageArabic();

  return (
    <IconButton
      disableRipple
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        left: isLanguageArabic ? "25px" : "initial",
        right: !isLanguageArabic ? "25px" : "initial",
        // justifyContent: isLanguageArabic ? "flex-start": "flex-end",
        justifyContent: isLanguageArabic ? "flex-start" : "flex-end",
        top: 25,
        color: (theme) => theme.palette.grey[500]
      }}
    >
      <img src={CloseImage} width={14} alt="close" />
    </IconButton>
  );
}

export const defaultModalTitleStyle = {
  margin: 0,
  padding: 0,
  fontFamily: "PlayfairDisplay",
  fontSize: "28px",
  fontWeight: 400,
  lineHeight: "34px",
  marginBottom: "12px"
};
export const defaultModalTitleArabicStyle = {
  margin: 0,
  padding: 0,
  fontFamily: "NotoNaskhArabicRegular",
  fontSize: "28px",
  fontWeight: 400,
  lineHeight: "34px",
  marginBottom: "12px"
};
export default function CustomizedDialog({
  children,
  open,
  onOk,
  onCancel,
  title,
  okButtonLabel,
  dialogClasses = {},
  titleClassName = "",
  titleStyle = {},
  dialogClassName = "",
  dialogContentClassName = "",
  dialogStyle = {},
  okButtonProps = {},
  hasActions = true,
  isLoading
}) {
  const isMobileView = useIsMobileView();
  const isLanguageArabic = useIsLanguageArabic();
  const { i18n, t } = useTranslation();
  return (
    <BootstrapDialog
      className={dialogClassName}
      onClose={onCancel}
      open={open}
      keepMounted={false}
      classes={{ ...dialogClasses }}
      BackdropComponent={BackdropComponent}
      style={{
        direction: isLanguageArabic ? "rtl" : "ltr"
      }}
    >
      <Box sx={dialogStyle} className={dialogContentClassName}>
        <BootstrapDialogTitle onClose={onCancel} />
        <DialogContent
          sx={{
            padding: "0px !important",
            margin: "0px !important",
            overflow: "hidden"
          }}
        >
          <h3 style={titleStyle} className={titleClassName}>
            {t(title)}
          </h3>
          {children}
        </DialogContent>
        {hasActions && (
          <DialogActions
            sx={{
              justifyContent: "flex-start",
              padding: "0px !important",
              margin: "0px !important"
            }}
          >
            {isLoading ? (
              <ColorLoadingButton
                size="small"
                loading={true}
                variant="outlined"
                disabled
                type="submit"
                className="button-submit-loading"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalMedium"
                      : "MontserratMedium",
                  maxWidth: "100%",
                  marginTop: "24px",
                  padding: "6px 16px",
                  height: "37px",
                  minWidth: isMobileView ? "100%" : "84px"
                }}
              />
            ) : (
              <PrimaryButton
                text={okButtonLabel}
                autoFocus
                onClick={onOk}
                style={{
                  minWidth: isMobileView ? "100%" : "84px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalMedium"
                      : "MontserratMedium"
                }}
                {...okButtonProps}
              />
            )}
          </DialogActions>
        )}
      </Box>
    </BootstrapDialog>
  );
}
