import { useState, useEffect, useMemo } from "react";
import Layout from "../Layout";
import LightBackground from "Components/LightBackground";
import { ContentTypesEnums, DataSourceEnums } from "utils/enums";
import { EVENTS_RESULTS_CMS_COMPONENT_MAP } from "CmsComponents";
import { Box } from "@mui/material";
import { useGetEventsLanding } from "services/events/events.query";
import { LightBackgroundControl } from "GlobalProcess/GlobalFunction";
import { CategoryListFilteredContainer } from "CmsComponents/CategoryListFilteredContainer";
import { useNavigate } from "react-router-dom";
import { useGetRecordIdsAndTypes } from "services/brands/brands.query";
const ResultEvents = () => {
  const [activeButton, setActiveButton] = useState("1");
  const [currentMonthId, setCurrentMonthId] = useState(null);
  const { data: events = [] } = useGetEventsLanding({
    content_type: "event_landing_page"
  });
  const { widgets = [],seo_data } = events;
  const navigate = useNavigate();
  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  const [monthFilterCount, SetMonthFilterCount] = useState();

  //   countMonths function counts the number of events for each month in the monthFilterCount array and returns an object where keys represent months and values are the corresponding event counts.

  // An array called monthsRecord initializes 12 months with zero RecordCount.

  // monthCounts is an object that stores the counts of events for each month.

  // A loop iterates through the monthCounts and updates the corresponding RecordCount for each month in the monthsRecord array.

  // The componentDetails variable extracts the details of a component with the type "mini-navigation" from the events object.

  // emptyDataMessaging uses the useMemo hook to find a component with the type "empty-data-messaging" within the events object.

  // carouselCount checks if there is a component with the type "CAROUSEL" in the widgets object.

  // EventsLoadContentColorTheme extracts the color theme from componentDetails.

  // An useEffect hook scrolls to the top of the page.

  // The useGetRecordIdsAndTypes hook is used to fetch data related to record IDs and types from a data source defined by DataSourceEnums.EVENTS.

  // monthRecordCount retrieves the record count for the "all" category from the fetched data.

  const countMonths = () => {
    return monthFilterCount?.reduce((counts, event) => {
      const { month } = event;
      counts[month] = (counts[month] || 0) + 1;
      return counts;
    }, {});
  };

  let monthsRecord = [
    {
      id: "1",
      RecordCount: 0
    },
    {
      id: "2",
      RecordCount: 0
    },
    {
      id: "3",
      RecordCount: 0
    },
    {
      id: "4",
      RecordCount: 0
    },
    {
      id: "5",
      RecordCount: 0
    },
    {
      id: "6",
      RecordCount: 0
    },
    {
      id: "7",
      RecordCount: 0
    },
    {
      id: "8",
      RecordCount: 0
    },
    {
      id: "9",
      RecordCount: 0
    },
    {
      id: "10",
      RecordCount: 0
    },
    {
      id: "11",
      RecordCount: 0
    },
    {
      id: "12",
      RecordCount: 0
    }
  ];

  const monthCounts = countMonths();
  for (const month in monthCounts) {
    const id = month;
    const count = monthCounts[month];

    const foundItem = monthsRecord.find((item) => item.id === id);
    if (foundItem) {
      foundItem.RecordCount = count;
    }
  }

  const componentDetails = events?.widgets?.find(
    (item) => item?.component_type === "mini-navigation"
  );
  const emptyDataMessaging = useMemo(() => {
    return events?.widgets?.find(
      (item) => item?.component_type === "empty-data-messaging"
    );
  }, [widgets]);

  const carouselCount = useMemo(() => {
    return (
      widgets?.find(
        (item) => item?.component_type === ContentTypesEnums.CAROUSEL
      )?.items?.length > 0
    );
  }, [widgets]);

  const EventsLoadContentColorTheme = componentDetails?.color_theme || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: IdsAndCountsData } = useGetRecordIdsAndTypes({
    data_source: DataSourceEnums.EVENTS
  });

  const monthRecordCount = IdsAndCountsData?.find(
    (item) => item?.CategoryID === "all"
  )?.RecordCount;
  return (
    <Layout seo_data={seo_data} className="events">
      {!LightBackgroundControl(widgets) && (
        <LightBackground
          text={events?.title}
          xsMinHeight="200px"
          paddingTp="0px"
          minheight="320px"
          containerStyle={{
            justifyContent: "initial"
          }}
        />
      )}

      <Box sx={{ minHeight: "100vh" }}>
        {widgets?.map((widget) => {
          const CmsComponent =
            EVENTS_RESULTS_CMS_COMPONENT_MAP[widget["component_type"]];
          if (CmsComponent) {
            return (
              <CmsComponent
                key={widget?.component_type}
                widget={widget}
                page="events"
                CategoryType={DataSourceEnums.EVENTS}
                activeButton={activeButton}
                ButtonText={componentDetails?.cta_button?.title}
                color_theme={EventsLoadContentColorTheme}
                setActiveButton={setActiveButton}
                currentMonthId={currentMonthId}
                setCurrentMonthId={setCurrentMonthId}
                months={true}
                monthsRecord={monthsRecord}
              />
            );
          }
          return null;
        })}
        <CategoryListFilteredContainer
          CategoryType={DataSourceEnums.EVENTS}
          activeButton={activeButton}
          ButtonText={componentDetails?.cta_button.title}
          color_theme={EventsLoadContentColorTheme}
          web_message={!carouselCount && emptyDataMessaging?.web_message}
          web_message_title={!carouselCount && emptyDataMessaging?.title}
          months={true}
          monthRecordCount={monthRecordCount}
          SetMonthFilterCount={SetMonthFilterCount}
          currentMonthId={currentMonthId}
          setCurrentMonthId={setCurrentMonthId}
         />
      </Box>
    </Layout>
  );
};

export default ResultEvents;
