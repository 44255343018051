import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { grey } from "@mui/material/colors";
import { useIsFetching } from "@tanstack/react-query";

import { useCountryTopList } from "hooks/onBoardingHooks";
import { CMS_LANGUAGE_MAP } from "CmsComponents";
import Stroke from "Assets/images/Stroke.svg";
import { getSelectedCountryCodeFromStorage } from "utils/getSelectedCountryCodeFromStorage";
import { getSelectedCountryFromStorage } from "utils/getSelectedCountryFromStorage";
import { setSelectedCountryCodeToStorage } from "utils/setSelectedCountryCodeToStorage";
import { setSelectedCountryToStorage } from "utils/setSelectedCountryToStorage";
import { countries, countryMap } from "Assets/helper/helper";
import { getSelectedLanguageCodeFromStorage } from "utils/getSelectedLanguageCodeFromStorage";
import { usePostSetCountry } from "services/account/account.query";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import { useUserIsLoggedIn } from "hooks/useUser";
import LazyImage from "Components/LazyLoad/LazyImage";
import { LangMap } from "i18n";
import { useSearchParams } from "react-router-dom";

export default function PositionedMenu({
  languageSelector = {},
  socialMedias = {},
  onLanguageChanged,
  userData,
  filterContents,
  hover_link,
  main_nav_hover
}) {
  const tabletMax = useMediaQuery("(min-width:1200px)");
  const isLoading = useIsFetching(["account"]);
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [searchParams] = useSearchParams();
  const [userCountryCode, setUserCountryCode] = useState(
    getSelectedCountryCodeFromStorage()
  );
  const [selectedCountry, setSelectedCountry] = useState(
    getSelectedCountryFromStorage()
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    getSelectedLanguageCodeFromStorage()
  );
  const {
    mutateAsync: setCountry,
    error,
    isLoading: setCountryLoading
  } = usePostSetCountry({ onSuccess: () => {}, onError: () => {} });
  const isLoggedIn = useUserIsLoggedIn();
  const countryFromQueryParams = searchParams.get("country");

  let defaultCountryISOCode = "ae";
  // If userCuntry is not null, countryCode in the elements of the countries object is filtered equal to userCountryCode
  if (
    userCountryCode != null ||
    (countryFromQueryParams && countryFromQueryParams !== "")
  ) {
    if (countryFromQueryParams && countryFromQueryParams !== "") {
      const userCountryMatch = countries.filter(
        (country) => country?.code === countryFromQueryParams?.toString()
      );
      defaultCountryISOCode = userCountryMatch?.[0]?.code?.toLowerCase();
    } else {
      const userCountryMatch = countries.filter(
        (country) => country?.countryCode === userCountryCode?.toString()
      );
      defaultCountryISOCode = userCountryMatch?.[0]?.code?.toLowerCase();
    }
  }

  //  The code you provided is a JavaScript function that filters an array of objects to find the object with a matching id property.
  //  The id property is split into two parts at the - character, and the second part is compared to the defaultCountryISOCode variable.
  //  If the two values match, the object is returned.
  let defaultCountryDataFromService = filterContents?.items?.find((item) => {
    let splitID = item.id.split("-");
    if (splitID[1] == defaultCountryISOCode) {
      item.name = item.title;
      item.iso_code = splitID[1].toUpperCase();
      return item;
    }
  });

  //if the selectedCountry is not null sets iso_code of selectedCountry to defaultCountryISOCode otherwise it starts setSelectedCountryToStorage in utils
  if (selectedCountry != null) {
    defaultCountryISOCode = selectedCountry.iso_code.toLowerCase();
  } else {
    setSelectedCountryToStorage(defaultCountryDataFromService);
  }

  const topCoutry = useCountryTopList();

  //This JavaScript code creates a countryMapdata array by reorganizing the items within the filterContents object.
  //It transforms the array by adding iso_code and name for each item, and it performs this operation if the filterContents object is defined.
  //If the filterContents object is not defined or its items are undefined, it creates an empty array.

  const countryMapdata =
    filterContents.items !== undefined
      ? filterContents?.items.map((item) => {
          return {
            ...item,
            iso_code: item?.id.split("-")[1].toUpperCase(),
            name: item.title
          };
        })
      : [];

  //This code defines a locationChange function that, based on the userCountryCode parameter,
  //maps and retrieves data from various sources and returns an object containing specific values, while using memoization for the useCallback hook.
  const locationChange = useCallback(
    (userCountryCode = "101") => {
      const code = countryMap.get(userCountryCode) || "101";
      const countryMapdata =
        filterContents.items !== undefined
          ? filterContents?.items.map((item) => {
              return {
                ...item,
                iso_code: item?.id.split("-")[1].toUpperCase(),
                name: item.title
              };
            })
          : [];
      const isoCodeMapped = topCoutry?.find(
        (country) => country?.maxxing_iso_code === code
      )?.iso_code;
      const isoCodeMappedNum = topCoutry?.find(
        (country) => country?.maxxing_iso_code === code
      )?.maxxing_iso_code;
      const userCountryMapped = countryMapdata?.find((country) => {
        return country?.iso_code === (code ? isoCodeMapped : "AE");
      });
      return { isoCodeMappedNum, userCountryMapped };
    },
    [filterContents.items, topCoutry]
  );

  //This function, handleClick, sets the anchor element for a component based on the provided event's current target.

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //This function, handleClose, clears or resets the anchor element by setting it to null.
  const handleClose = () => {
    setAnchorEl(null);
  };
  //This function, handleChange, responds to a change event by updating various states and,
  //if the user is logged in, setting the selected country and storing it in local storage.
  const handleChange = (e) => {
    const { isoCodeMappedNum, userCountryMapped } = locationChange(
      e.target.value
    );

    setUserCountryCode(isoCodeMappedNum);
    setSelectedCountry(userCountryMapped);

    if (isLoggedIn) {
      setCountry({ country: isoCodeMappedNum }).then(() => {
        setSeletedCountryToStorage(isoCodeMappedNum, userCountryMapped);
      });
    } else {
      setSeletedCountryToStorage(isoCodeMappedNum, userCountryMapped);
    }
  };

  //This function, setSeletedCountryToStorage, stores the isoCodeMappedNum and userCountryMapped values in local storage,
  // then reloads the window to apply the changes immediately.
  const setSeletedCountryToStorage = (isoCodeMappedNum, userCountryMapped) => {
    setSelectedCountryCodeToStorage(isoCodeMappedNum);
    setSelectedCountryToStorage(userCountryMapped);
    window.location.reload();

    return;
  };

  useEffect(() => {
    if (countryFromQueryParams && countryFromQueryParams !== "") {
      const { isoCodeMappedNum, userCountryMapped } = locationChange(
        countryFromQueryParams
      );
      setUserCountryCode(isoCodeMappedNum);
      setSelectedCountryCodeToStorage(isoCodeMappedNum);
      setSelectedCountry(userCountryMapped);
      setSelectedCountryToStorage(userCountryMapped);
    } else if (!userCountryCode) {
      const { isoCodeMappedNum, userCountryMapped } = locationChange();
      setUserCountryCode(isoCodeMappedNum);
      setSelectedCountryCodeToStorage(isoCodeMappedNum);
      setSelectedCountry(userCountryMapped);
      setSelectedCountryToStorage(userCountryMapped);
    }
  }, [locationChange, userCountryCode, countryFromQueryParams]);

  return (
    <div
      className="box-select"
      style={{
        display: "flex",
        alignItems: tabletMax
          ? i18n.language === "arabic"
            ? "flex-start"
            : "flex-end"
          : "center"
      }}
    >
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          padding: "0px",
          marginRight: "0px",
          "&:active": {
            color: grey[700]
          },
          marginTop: { xs: "112px", lg: "0px" },
          width: "inherit"
        }}
      >
        {isLoading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress
              size={"25px"}
              style={{
                color: "white"
              }}
            />
          </Box>
        ) : (
          <Box className="selectbox-country">
            <Box
              className="selectbox-country-text"
              sx={{
                flexDirection:
                  i18n.language === "arabic" ? "row-reverse" : "row"
              }}
            >
              <LazyImage
                placeholderSrc={
                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                }
                src={"/icons/" + defaultCountryDataFromService?.flag}
                alt="flag"
                className="selectbox-country-image"
                lazyclass="lazy"
              />
              <div
                className={
                  i18n.language === "arabic"
                    ? "selectbox-inline-text-arabic"
                    : "selectbox-inline-text"
                }
              >
                {defaultCountryDataFromService?.title}
              </div>
              <LazyImage
                placeholderSrc={
                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                }
                src={Stroke}
                alt="flag"
                lazyclass="lazy"
              />
            </Box>
          </Box>
        )}
      </Button>
      <div
        className={
          i18n.language === "arabic"
            ? "select-box-right-text-arabic"
            : "select-box-right-text"
        }
        style={{ marginRight: "0px" }}
      >
        <Box
          className={
            i18n.language === "arabic"
              ? "footer-language-menu-arabic"
              : "footer-language-menu"
          }
        >
          {languageSelector?.items?.map((item, index) => (
            <Fragment key={item.id}>
              <button
                className="right-text-span"
                style={{
                  color: i18n.language === "arabic" ? "#90969E" : "#FFFFFF"
                }}
                value={CMS_LANGUAGE_MAP[item.id]}
                onClick={onLanguageChanged}
              >
                <span>{item.title}</span>
              </button>
              {index < languageSelector?.items.length - 1 && (
                <span className="right-text-divider">|</span>
              )}
            </Fragment>
          ))}
        </Box>
      </div>
      <div
        className={
          i18n.language === "arabic"
            ? "select-box-right-text-arabic"
            : "select-box-right-text"
        }
        style={{ marginRight: "0px" }}
      >
        <div className="social-box" style={{ marginBottom: "10px" }}>
          {socialMedias?.items?.map((item) => (
            <a
              key={item.id}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyImage
                placeholderSrc={
                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                }
                lazyclass="lazy"
                src={item.icon}
                alt={item.id}
              />
            </a>
          ))}
        </div>
      </div>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          left: "0px",
          "& .MuiPopover-paper": { borderRadius: "10px !important" },
          "& .MuiMenu-list": { padding: "0px !important" }
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
          left: 10
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
          left: 10
        }}
      >
        <div
          className="footer-select-country"
          style={{ backgroundColor: main_nav_hover || "" }}
        >
          <>
            {setCountryLoading && (
              <div className="footer-select-country__loading">
                {error ? (
                  <AlertWarning
                    marginbottom="24px"
                    margintop="-16px"
                    text={t(error)}
                  />
                ) : (
                  <CircularProgress size={"25px"} style={{ color: "black" }} />
                )}
              </div>
            )}
            <div
              className={
                i18n.language === "arabic"
                  ? "select-country-head-arabic"
                  : "select-country-head"
              }
            >
              {filterContents.title}
            </div>
            <div
              className={
                i18n.language === "arabic"
                  ? "select-country-text-arabic"
                  : "select-country-text"
              }
            >
              {filterContents.description}
            </div>
            <div className="custom-radios" style={{ marginTop: "0px" }}>
              {countryMapdata?.map((country) => (
                <div key={country.iso_code}>
                  <div
                    className={
                      i18n.language === "arabic"
                        ? "SC-radio-box-arabic"
                        : "SC-radio-box"
                    }
                  >
                    <div
                      className={
                        i18n.language === "arabic"
                          ? "SC-box-1-arabic"
                          : "SC-box-1"
                      }
                    >
                      <div>
                        <LazyImage
                          placeholderSrc={
                            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                          }
                          lazyclass="lazy"
                          width="20"
                          height="20"
                          style={{
                            borderRadius: "50%",
                            marginRight:
                              i18n.language === "arabic" ? "0px" : "12px",
                            marginLeft:
                              i18n.language === "arabic" ? "12px" : "0px"
                          }}
                          src={"/icons/" + country.flag}
                          alt={
                            selectedLanguage === LangMap.ar
                              ? t(country?.name)
                              : country?.name
                          }
                        />
                      </div>
                      <div
                        style={{
                          textAlign:
                            i18n.language === "arabic" ? "right" : "left",
                          color: hover_link || ""
                        }}
                      >
                        {selectedLanguage === LangMap.ar
                          ? t(country?.name)
                          : country?.name}
                      </div>
                    </div>
                    <div
                      className={
                        i18n.language === "arabic"
                          ? "SC-box-2-arabic"
                          : "SC-box-2"
                      }
                    >
                      <input
                        type="radio"
                        id={country.iso_code}
                        name="color"
                        value={country.iso_code}
                        onChange={handleChange}
                      />

                      <label htmlFor={country.iso_code}>
                        <span
                          style={{
                            backgroundColor:
                              country.iso_code ===
                              defaultCountryDataFromService?.iso_code
                                ? "black"
                                : "white"
                          }}
                        >
                          <LazyImage
                            placeholderSrc={
                              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            }
                            lazyclass="lazy"
                            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                            alt="Checked Icon"
                          />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        </div>
      </Menu>
    </div>
  );
}
