import React from "react";

const TableHeadItem = ({ item }) => {
  return (
    <td className="table-head-col" title={item}>
      {item}
    </td>
  );
};

const TableRow = ({ data = [] }) => {
  return (
    <tr>
      {data.map((item) => {
        return (
          <td className="table-body-col" key={item}>
            {item}
          </td>
        );
      })}
    </tr>
  );
};

const defaultClassNames = {
  table: "table",
  tableHead: "table-head",
  tableBody: "table-body",
  tableBodyRow: "table-body-row"
};
const CustomTable = ({
  classNames = defaultClassNames,
  columns = [],
  data = []
}) => {
  return (
    <table className={classNames.table}>
      <thead className={classNames.tableHead}>
        <tr>
          {columns.map((item) => (
            <TableHeadItem key={item} scope="col" item={item}></TableHeadItem>
          ))}
        </tr>
      </thead>
      <tbody className={classNames.tableBody}>
        {data.map((item) => {
          return <TableRow key={item.id} data={item.items} />;
        })}
      </tbody>
    </table>
  );
};

export default CustomTable;
