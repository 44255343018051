import { Box, Grid, CircularProgress } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsLanguageArabic, useIsMobileView } from "hooks";
import { useGetAccount } from "services/account/account.query";
import { useIsFetching } from "@tanstack/react-query";
import { TIER_MAP, TIER_CODE } from "constants";
import {
  useGetOnAppConfigContent,
  useGetTierCards
} from "services/cms/cms.query";
import { formatDate } from "dateUtil";
import LazyImage from "Components/LazyLoad/LazyImage";

const InfoLabel = ({ label, value }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box>
      <div className="Box-head" data-lang={i18n.language}>
        {t(label)}
      </div>
      <div className="Box-text" data-lang={i18n.language}>
        {t(value)}
      </div>
    </Box>
  );
};

const InfoLabels = ({ userData }) => {
  let PointsEarned =
    userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.CurrentPointBalance.toLocaleString(
      "en-US"
    );
  let Tier = userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.Tier;
  let CustomerID = userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.CardID;

  return (
    <>
      <Box className="info-labels-container">
        {[
          {
            label: "Points",
            value: PointsEarned?.length > 0 ? PointsEarned : 0
          },
          { label: "Tier", value: TIER_MAP[Tier] },
          { label: "Member Number", value: CustomerID }
        ].map(({ label, value }) => (
          <InfoLabel label={label} key={label} value={value} />
        ))}
      </Box>
    </>
  );
};

const Title = ({ userData }) => {
  const { t, i18n } = useTranslation();
  const isLoading = useIsFetching(["account"]);

  let lastname = userData?.Customer?.[0]?.PersonalInformation?.LastName;
  let firstname = userData?.Customer?.[0]?.PersonalInformation?.FirstName;

  const timeOfNow = new Date().getHours();

  let greeting;

  if (timeOfNow >= 5 && timeOfNow < 12) {
    greeting = t("Good Morning");
  } else if (timeOfNow >= 12 && timeOfNow < 17) {
    greeting = t("Good Afternoon");
  } else {
    greeting = t("Good Evening");
  }

  return (
    <>
      {!isLoading && (
        <div>
          <div className="hello-message" data-lang={i18n.language}>
            {greeting}
            <span style={{ direction: "rtl" }}>
              {i18n.language === "arabic" ? <>&#x60C;</> : ","}
            </span>
          </div>
          <div
            className="user-name promotion-header-user-name"
            data-lang={i18n.language}
          >
            {firstname && lastname && firstname + " " + lastname}
          </div>
        </div>
      )}
    </>
  );
};

const DashboardCard = ({ tierId }) => {
  const { data, isLoading, isSuccess } = useGetTierCards();
  const [background, setBackground] = useState(null);
  const [cardLogo, setCardLogo] = useState(null);
  const [labelTextColor, setLabelTextColor] = useState(null);
  const { i18n } = useTranslation();

  const widget = data?.items?.[0]?.fields?.configuration?.widgets?.find(
    (widget) => widget?.tier_code === TIER_CODE[tierId]
  );
  const { card_name: label, tier_code } = widget || {};

  useEffect(() => {
    if (!isLoading && isSuccess && label) {
      const { items } = data;
      if (items.length > 0) {
        const { fields } = items[0];
        const { configuration } = fields;
        const { widgets } = configuration;
        const cardInfo = widgets.find(
          (widget) => widget.tier_code === tier_code
        );
        setBackground(cardInfo?.front?.assets_normal?.background_image_web);
        setCardLogo(cardInfo?.front?.assets_normal?.card_logo);
        setLabelTextColor(cardInfo?.color_theme?.color_definition?.card_name);
      }
    }
  }, [data, isLoading, isSuccess, label, tier_code]);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center center",
        borderRadius: "14px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "11.77px",
        overflow: "hidden",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat"
      }}
      className="reward-box"
    >
      {cardLogo && (
        <LazyImage
          placeholderSrc={
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          }
          lazyclass="lazy"
          className="brandmark-image"
          src={cardLogo}
          alt="brandmark-image"
        />
      )}
      <span
        className="brandmark-text"
        data-lang={i18n.language}
        style={{ textTransform: "uppercase", color: labelTextColor }}
      >
        {label}
      </span>
    </div>
  );
};

const PromotionHeaderDesktop = ({
  totalnumber,
  userData,
  myTierId,
  usePreviousLabel
}) => {
  const { i18n } = useTranslation();
  const isLoading = useIsFetching(["account"]);
  const { data, isSuccess, isLoading: cardLoading } = useGetTierCards();
  const [cardDescription, setCardDescription] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  useEffect(() => {
    if (userData) {
      setTargetDate(
        userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.TierNextChange
      );
    }
  }, [userData]);

  const label = TIER_MAP[myTierId];
  const keys = Object.keys(TIER_MAP);
  const index = keys.indexOf(myTierId);
  const labelPrevious = TIER_MAP[keys[index - 1]];
  const labelControl = usePreviousLabel ? labelPrevious : label;

  useEffect(() => {
    if (isSuccess && !cardLoading) {
      const { items } = data;
      if (items.length > 0) {
        const { fields } = items[0];
        const { configuration } = fields;
        const { widgets } = configuration;
        const cardInfo = widgets.find(
          (widget) => widget.tier_code === labelControl?.toLowerCase()
        );

        setCardDescription(cardInfo?.front?.card_description || "");
      }
    }
  }, [data, isSuccess, isLoading, cardLoading, labelControl]);

  return (
    <Grid
      flexDirection={"row"}
      width={"100%"}
      container
      justifyContent={"space-between"}
      marginTop={"84.5px"}
    >
      <Grid item width={"100%"} maxWidth={"520px"}>
        {isLoading && cardLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%"
            }}
          >
            <CircularProgress
              size={"25px"}
              style={{
                color: "black"
              }}
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "grid",
                maxWidth: "520px",
                gridTemplateColumns: "1fr",
                rowGap: "32px"
              }}
            >
              <Title userData={userData} />
              <InfoLabels userData={userData} />
              <Box className="Box-text-spend" data-lang={i18n.language}>
                {cardDescription &&
                  cardDescription
                    .replaceAll(
                      "{{max_value}}",
                      totalnumber.toLocaleString("en-US")
                    )
                    .replaceAll(
                      "{{target_date}}",
                      formatDate({
                        dateText: targetDate,
                        language: i18n.language,
                        fromFormat: "YYYY-MM-DD",
                        toFormat: "DD MMM YYYY"
                      })
                    )}
              </Box>
            </Box>
          </>
        )}
      </Grid>
      <Grid item>
        <DashboardCard tierId={myTierId} />
      </Grid>
    </Grid>
  );
};

const PromotionHeaderMobile = ({
  totalnumber,
  userData,
  myTierId,
  usePreviousLabel
}) => {
  const { i18n } = useTranslation();
  const isLoading = useIsFetching(["account"]);
  const { data, isSuccess, isLoading: cardLoading } = useGetTierCards();
  const [cardDescription, setCardDescription] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  useEffect(() => {
    if (userData) {
      setTargetDate(
        userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.TierNextChange
      );
    }
  }, [userData]);

  const label = TIER_MAP[myTierId];
  const keys = Object.keys(TIER_MAP);
  const index = keys.indexOf(myTierId);
  const labelPrevious = TIER_MAP[keys[index - 1]];

  const labelControl = usePreviousLabel ? labelPrevious : label;

  useEffect(() => {
    if (isSuccess && !cardLoading) {
      const { items } = data;
      if (items.length > 0) {
        const { fields } = items[0];
        const { configuration } = fields;
        const { widgets } = configuration;
        const cardInfo = widgets.find(
          (widget) => widget.tier_code === labelControl?.toLowerCase()
        );

        setCardDescription(cardInfo?.front?.card_description || "");
      }
    }
  }, [data, isSuccess, isLoading, cardLoading, labelControl]);

  return (
    <Grid
      flexDirection={"column"}
      width={"100%"}
      container
      justifyContent={"space-between"}
      gap={"24px"}
      marginTop={"48px"}
    >
      {!isLoading && !cardLoading && (
        <>
          <Title userData={userData} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <InfoLabels userData={userData} />
            </Grid>
            <Grid item alignItems={"flex-start"}>
              <DashboardCard tierId={myTierId} />
            </Grid>
          </Grid>
          <Box className="Box-text-spend" data-lang={i18n.language}>
            {cardDescription &&
              cardDescription
                .replaceAll(
                  "{{max_value}}",
                  totalnumber.toLocaleString("en-US")
                )
                .replaceAll(
                  "{{target_date}}",
                  formatDate({
                    dateText: targetDate,
                    language: i18n.language,
                    fromFormat: "YYYY-MM-DD",
                    toFormat: "DD MMM YYYY"
                  })
                )}
          </Box>
        </>
      )}
    </Grid>
  );
};

const PromotionHeader = ({ isTableDataEmpty = false }) => {
  const isLanguageArabic = useIsLanguageArabic();
  const isMobileView = useIsMobileView();
  const { data: userData } = useGetAccount();
  const [pointsNeeded, setPointsNeeded] = useState(0);
  const { data: appConfigContent = {} } = useGetOnAppConfigContent();
  const { isLoading: cardLoading } = useGetTierCards();
  const [usePreviousLabel, SetPreviousLabel] = useState(false);
  const isLoading = useIsFetching(["account"]);

  const amberTierMapping = useMemo(() => {
    return appConfigContent?.items?.[0]?.fields?.configuration?.Configurations?.find(
      (item) => item?.configuration_name === "amber_tier_mapping"
    );
  }, [appConfigContent]);

  const myTierId = useMemo(() => {
    return userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.Tier;
  }, [userData]);

  const pointsEarned =
    useMemo(() => {
      return userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.PointsEarned;
    }, [userData]) || 0;

  const totalnumber =
    myTierId === "009" && Number(pointsEarned) < Number(pointsNeeded)
      ? Number(pointsNeeded) - Number(pointsEarned)
      : pointsNeeded;

  useEffect(() => {
    if (myTierId && amberTierMapping) {
      let point = 0;
      switch (myTierId) {
        case "001":
          point =
            parseInt(
              amberTierMapping?.tiers?.find((tier) => tier.tier_id === myTierId)
                ?.max_points || 0
            ) +
            1 -
            parseInt(pointsEarned);
          setPointsNeeded(point);
          break;
        case "002":
          point =
            parseInt(
              amberTierMapping?.tiers?.find((tier) => tier.tier_id === myTierId)
                ?.max_points || 0
            ) +
            1 -
            parseInt(pointsEarned);

          setPointsNeeded(point);
          break;
        case "008":
          point =
            parseInt(
              amberTierMapping?.tiers?.find((tier) => tier.tier_id === myTierId)
                ?.max_points || 0
            ) +
            1 -
            parseInt(pointsEarned);

          setPointsNeeded(point);
          break;
        case "009":
          point =
            parseInt(
              amberTierMapping?.tiers?.find((tier) => tier.tier_id === myTierId)
                ?.min_points || 0
            ) +
            1 -
            parseInt(pointsEarned);
          if (point <= 0) {
            point = parseInt(
              amberTierMapping?.tiers?.find((tier) => tier.tier_id === "009")
                ?.min_points || 0
            );
          } else SetPreviousLabel(true);

          setPointsNeeded(point);
          break;
        case "010":
          setPointsNeeded(0);
          break;
        default:
          break;
      }
    }
  }, [myTierId, amberTierMapping]);
  return (
    <Grid
      className="reward-system"
      container
      rowSpacing={0}
      columnSpacing={{ xs: 0 }}
      sx={{
        paddingLeft: { xs: "20px", md: "11.11%" },
        paddingRight: { xs: "20px", md: "11.11%" },
        paddingTop: isMobileView ? "56px" : "80px",
        paddingBottom: isMobileView ? "40px" : "80px",
        height: "100%",
        maxHeight: { xs: "100%" },
        background: "#F3F3FA",
        display: "flex",
        justifyContent: { xs: "center", md: "space-between" },
        alignItems: "flex-start",
        direction: isLanguageArabic ? "rtl" : "ltr"
      }}
    >
      {!isLoading && !cardLoading && (
        <>
          {" "}
          {isMobileView ? (
            <PromotionHeaderMobile
              isTableDataEmpty={isTableDataEmpty}
              userData={userData}
              pointsNeeded={pointsNeeded}
              setPointsNeeded={setPointsNeeded}
              myTierId={myTierId}
              amberTierMapping={amberTierMapping}
              pointsEarned={pointsEarned}
              totalnumber={totalnumber}
              usePreviousLabel={usePreviousLabel}
            />
          ) : (
            <PromotionHeaderDesktop
              isTableDataEmpty={isTableDataEmpty}
              userData={userData}
              pointsNeeded={pointsNeeded}
              setPointsNeeded={setPointsNeeded}
              myTierId={myTierId}
              amberTierMapping={amberTierMapping}
              pointsEarned={pointsEarned}
              totalnumber={totalnumber}
              usePreviousLabel={usePreviousLabel}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default PromotionHeader;
