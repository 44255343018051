import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LinkButton from "../Account/components/LinkButton";
import BrowseButton from "Pages/common/Promotions/BrowseButton";

const CookiesPrivacy = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box className="container">
      <Grid container className="grid">
        <Grid item className="title-container" xs={12}>
          <h3 className="title-text" data-lang={i18n.language}>
            {t("Cookies & privacy")}
          </h3>
        </Grid>
        <Grid item className="description-container" xs={12}>
          <p className="description-text" data-lang={i18n.language}>
            {t("cookiesAndPrivacyMessage")}
          </p>
        </Grid>
        <div className="manage-details-container">
          <div className="manage-details-link">
            <LinkButton>{t("Manage Details")}</LinkButton>
          </div>
          <div className="action-button-container">
            <BrowseButton className="browse-button-arabic action-button browse-button">
              {t("Deny all")}
            </BrowseButton>
            <BrowseButton className="browse-button-arabic action-button browse-button">
              {t("Allow all")}
            </BrowseButton>
          </div>
        </div>
      </Grid>
    </Box>
  );
};

export default CookiesPrivacy;
