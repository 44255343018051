import React from "react";
import styled from "styled-components";

export const ScoopedCard = ({ children, background }) => {
  const ScoopedCardStyle = styled.div`
    .scooped-card {
      max-width: 640px;
      position: relative;
      width: calc(100% - 80px);
      padding: 40px;
      display: block;
    }

    .scooped-card:before,
    .scooped-card:after,
    p:before,
    p:after {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background-color: ${background || "white"};
      position: absolute;
    }
    .scooped-card:before {
      top: 0;
      left: 0;
      border-bottom-right-radius: 100%;
    }
    .scooped-card:after {
      bottom: 0;
      right: 0;
      border-top-left-radius: 100%;
    }
    p:before {
      top: 0;
      right: 0;
      border-bottom-left-radius: 100%;
    }
    p:after {
      bottom: 0;
      left: 0;
      border-top-right-radius: 100%;
    }
  `;
  return <ScoopedCardStyle className="event-card">{children}</ScoopedCardStyle>;
};

export default ScoopedCard;
