import React from "react";
import { useGetBrands } from "services/brands/brands.query";
import { NavBarContainer } from "../NavBarContainer";

const PurchaseContainer = ({ children }) => {
  const { data: brands = [] } = useGetBrands();
  const { widgets } = brands || {};
  const navbarWidget = widgets?.find(
    (widget) => widget?.component_type === "web-homepage-main-navigation"
  );
  const userAgent = window?.navigator?.userAgent;

  return (
    <>
      {!userAgent?.includes("amber") && (
        <NavBarContainer widget={navbarWidget} />
      )}
      {children}
    </>
  );
};

export default PurchaseContainer;
