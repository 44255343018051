import { useEffect } from "react";
import _ from "lodash";

// This code defines a custom hook useDataLayer for managing and handling data in the Google Tag Manager (GTM) dataLayer. Here's a summary of the key functionality in this code:
// DataLayer Initialization: The useDataLayer hook initializes the dataLayer object as a global variable if it doesn't already exist. The dataLayer is a JavaScript array that GTM uses to communicate with the tracking code on a web page.
// Event Types and Item Type Maps: This code defines two objects, EVENT_TYPE and ITEMTYPE_MAP, which are used to create and structure events to be pushed into the dataLayer. These objects provide a predefined set of event types and item types, making it easier to create and handle events consistently.
// Event Handling Functions: Several functions are provided to push specific types of events into the dataLayer. These functions include:
// setEvent: A generic function to set an event with custom data.
// setSignUpEvent and setSignInEvent: Functions for pushing user sign-up and sign-in events into the dataLayer with specific steps.
// setSettingsEvent: A function to push settings-related events into the dataLayer.
// setUserData: Sets user properties data in the dataLayer.
// setImpressionEvent, setClickEvent, setFilterEvent, and setInteractionEvent: Functions to push events of these specific types into the dataLayer with relevant data.
// Duplicate Data Handling: Comments and conditional statements are provided for preventing duplicate data from being pushed into the dataLayer. This ensures that events are only pushed when needed.
// The useDataLayer hook is designed to be used within a React component to facilitate the management and dispatch of various GTM events and data. It provides a structured and consistent way to interact with the dataLayer, which is commonly used for analytics and tracking purposes in web applications.

export const EVENT_TYPE = Object.freeze({
  SIGN_UP: "sign_up",
  SIGN_IN: "sign_in",
  CHANGE_SETTING: "change_setting",
  DELETE_ACCOUNT: "delete_account",
  RECEIPT_ACTIVITY: "receipt_activity",
  SEARCH: "search",
  FAMILY_MEMBERS: "family_members",
  SIGN_OUT: "sign_out",
  CONTACT_US: "contact_us",
  IMPRESSION: "impression",
  CLICK: "click",
  FILTER: "filter",
  INTERACTION: "interaction"
});

export const ITEMTYPE_MAP = Object.freeze({
  EDITORIAL: "editorial",
  BRAND: "brand",
  OFFERS: "offer",
  EVENTS: "event",
  REWARD: "reward"
});

export const useDataLayer = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  const setData = (data) => {
    window.dataLayer.push(data);
  };

  const setEvent = (event, data) => {
    window.dataLayer.push({ event, ...data });
  };

  const setSignUpEvent = (step) => {
    window.dataLayer.push({
      event: EVENT_TYPE.SIGN_UP,
      step
    });
  };
  const setSignInEvent = (step) => {
    window.dataLayer.push({
      event: EVENT_TYPE.SIGN_IN,
      step
    });
  };
  const setSettingsEvent = (event, data) => {
    window.dataLayer.push({ event, ...data });
  };

  /**
   * @typedef {Object} UserPropertiesData
   * @property {String} userID - The amber ID of the user
   * @property {String} amber_tier - The amber tier of the user
   * @property {String} hashed_email - The MD5 hashed email of the user
   * @property {String} hashed_phone - The MD5 hashed phone number of the user
   * @property {String} country - The 2 letter country code of the country in the user’s account NOT the country where they are currently in as defined by IP address
   * @property {String} language - The app language as set by the user
   * @property {String} user_gender - The gender set by the user
   * @property {String} user_age - The age set by the user
   * @property {String} no_of_kids - The number of connected children in the user’s account
   * @property {String} location_permission - Whether or not the user has allowed location permissions in the app
   * @property {String} email_opt_in - Whether or not the user has opted in to email communications
   * @property {String} sms_opt_in - Whether or not the user has opted in to sms communications
   * @property {String} push_opt_in - Whether or not the user has opted in to push communications
   */

  /**
   * Sets an user properties with specified data.
   * @param {UserPropertiesData} data - The data for the user properties.
   */
  const setUserData = (data) => {
    // The following if prevents duplicate data. If duplicate data is wanted, the control should be removed.
    if (!_.find(window.dataLayer, ["userID", data.userID])) {
      window.dataLayer.push(data);
    }
  };

  /**
   * @typedef {Object} ImpressionEventData
   * @property {String} page - The module where the content block is being shown
   * @property {String} itemtype - The type of content card/item being viewed
   * @property {String} brand - The associated brand of the event/offer/card/etc. being viewed
   * @property {String} category - The associated category of the itemtype being viewed
   * @property {String} title - The name/title of the content block being shown
   */

  /**
   * Sets an impression event with specified data.
   * @param {ImpressionEventData} data - The data for the impression event.
   */
  const setImpressionEvent = (data) => {
    // TODO: If you want prevent duplicate data. While push action use this the if.
    /* if (
      !_.find(
        window.dataLayer,
        i => i.page === data.page && i.event === EVENT_TYPE.IMPRESSION
      )
    ) {
    } */

    window.dataLayer.push({
      event: EVENT_TYPE.IMPRESSION,
      ...data
    });
  };

  /**
   * @typedef {Object} ClickEventData
   * @property {String} page - The module where the content block is being clicked on
   * @property {String} itemtype - The type of content card/item being clicked on
   * @property {String} brand - The associated brand of the event/offer/card/etc. being clicked
   * @property {String} category - The associated category of the itemtype being clicked
   * @property {String} title - The name/title of the content block being clicked
   */

  /**
   * Sets an click event with specified data.
   * @param {ClickEventData} data - The data for the click event.
   */
  const setClickEvent = (data) => {
    // TODO: If you want prevent duplicate data. While push action use this the if.
    /* if (
      !_.find(
        window.dataLayer,
        i => i.page === data.page && i.event === EVENT_TYPE.CLICK
      )
    ) {
    } */

    window.dataLayer.push({
      event: EVENT_TYPE.CLICK,
      ...data
    });
  };

  /**
   * @typedef {Object} FilterEventData
   * @property {String} page - The module where the content is being filtered
   * @property {String} type - The type of content being filtered (e.g events/offers/)
   * @property {String} name - The value of the filter
   */

  /**
   * Sets an filter event with specified data.
   * @param {FilterEventData} data - The data for the filter event.
   */
  const setFilterEvent = (data) => {
    // TODO: If you want prevent duplicate data. While push action use this the if.
    /* if (
      !_.find(
        window.dataLayer,
        i => i.page === data.page && i.event === EVENT_TYPE.FILTER
      )
    ) {
    } */

    window.dataLayer.push({
      event: EVENT_TYPE.FILTER,
      ...data
    });
  };

  /**
   * @typedef {Object} InteractionEventData
   * @property {String} page - Page/module where the interaction is happening
   * @property {String} brand - Associated brand (if any)
   * @property {String} title - Title (if any)
   * @property {String} category - Category (if any)
   * @property {String} command_activated - Name of the interaction being triggered
   */

  /**
   * Sets an interaction event with specified data.
   * These events should be triggered when a user interacts with certain UI items. These are:
      1. Event interactions: e.g. when a user RSVPs to an event
      2. Offer interactions: e.g. when a user reveals an offer code
      3. Brands details interactions: e.g when a user uses the call store button
      4. Cards interaction: e.g when a user views tier benefits
      5. Rewards Interaction: e.g. when a user removes a reward
      6. Popover interaction: when a user clicks on a CTA in a popover
   * @param {InteractionEventData} data - The data for the interaction event.
   */
  const setInteractionEvent = (data) => {
    // TODO: If you want prevent duplicate data. While push action use this the if.
    /* if (
      !_.find(
        window.dataLayer,
        i => i.page === data.page && i.event === EVENT_TYPE.INTERACTION
      )
    ) {
    } */

    window.dataLayer.push({
      event: EVENT_TYPE.FILTER,
      ...data
    });
  };

  return {
    setData,
    setEvent,
    setSignUpEvent,
    setSignInEvent,
    setSettingsEvent,
    setUserData,
    setImpressionEvent,
    setClickEvent,
    setFilterEvent,
    setInteractionEvent
  };
};
