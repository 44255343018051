import React, { useEffect, useState } from "react";

import AxiosInterceptor from "hooks/AxiosInterceptor";
import VerifyAuth from "hooks/VerifyAuth";
import { useUserStore } from "hooks/useUser";
import RouteListener from "routes/RouteListener";
import { PRIVATE_TOKEN_PERSIST_KEY, TIER_LOCAL_STORAGE_KEY } from "constants";
import { axiosInstance } from "services/apiClient";
import { prefetchQueries } from "services/prefetchQueries";
import { setPrivateBearerToken } from "services/privateApiClient";
import { getToken } from "utils/authStorage";

const Bootstrap = ({ children }) => {
  const [isBootstrapped, setIsBootstrapped] = useState(false);

  const { setUser, setTier } = useUserStore();

  useEffect(() => {
    if (!axiosInstance.defaults.headers.Authorization) {
      const token = getToken(PRIVATE_TOKEN_PERSIST_KEY);
      const savedTier = localStorage.getItem(TIER_LOCAL_STORAGE_KEY); 

      if (token) {
        setPrivateBearerToken(token);
        setUser(token);
        if (savedTier) {
          setTier(savedTier); // If tier exists in localStorage, set it to the global state
        }
      } else {
        localStorage.removeItem(TIER_LOCAL_STORAGE_KEY)
      }
    }
    setIsBootstrapped(true);
    prefetchQueries();
  }, [setUser, setTier]);

  return (
    <>
      <RouteListener />
      <VerifyAuth />
      <AxiosInterceptor />
      {isBootstrapped ? children : null}
    </>
  );
};

export default Bootstrap;
