// The formatPhoneNumber function in the provided JavaScript code is used to format a given string as a phone number. It removes non-digit characters from the input string and then formats it into a more readable phone number format. The function handles different string lengths by inserting spaces and hyphens in the appropriate places. This ensures that phone numbers are consistently formatted for display.
// Takes a single parameter, value, which is expected to be a string (e.g., a phone number).
// Removes all non-digit characters from the input string.
// Formats the string based on its length:
// If the length is less than 4, it returns the original string.
// If the length is between 4 and 7, it formats the string as "XXX XXXX."
// If the length is between 7 and 10, it formats the string as "XX XXX-XXXX."
// If the length is 10 or greater, it formats the string as "XXX XXX-XXXX."

export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
  }
  if (phoneNumberLength < 10) {
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      5
    )}-${phoneNumber.slice(5, 9)}`;
  }
  return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};
