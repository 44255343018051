import { Box, FormControl, Grid, RadioGroup } from "@mui/material";
import React from "react";
import { InputLabel, SettingsDescription, SettingsSubTitle } from "../common";
import RadioButton from "../components/RadioButton";
import PrimaryButton from "../components/PrimaryButton";
import { useIsMobileView } from "hooks";
import { useDispatch } from "react-redux";
import { selectLanguage } from "store/languageSlice";
import { withTranslation, useTranslation } from "react-i18next";
import LazyImage from "Components/LazyLoad/LazyImage";
import { useUserIsLoggedIn } from "hooks/useUser";
import { usePatchPreferredLanguage } from "services/updatePreferredLanguage/updatePreferredLanguage.query";
const RadioButtonItem = ({ imageUrl, ...props }) => {
  const { t, i18n } = useTranslation();
 
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px"
      }}
    >
      <LazyImage
        placeholderSrc={
          "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        }
        lazyclass="lazy"
        src={imageUrl}
        alt="flag"
        width={20}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "300px",
          height: "52px",
          borderBottom: "1px solid #CED0D7",
          fontSize: i18n.language === "arabic" ? "16px" : "14px",
          fontFamily:
            i18n.language === "arabic" ? "TajawalRegular" : "MontserratRegular"
        }}
      >
        <span>{t(props.label)}</span>
        {props.children}
      </Box>
    </Box>
  );
};

const LanguageSettings = (props) => {
   const { t, i18n } = useTranslation();
  const [lang, setLang] = React.useState(
    localStorage.getItem("language") || "en"
  );
  const isMobileView = useIsMobileView();
  const dispatch = useDispatch();

  const isLoggedIn = useUserIsLoggedIn();
  const languageMapped = {
    en: "en",
    arabic: "ar"
  };
  const onChangeLang = (event) => {
    setLang(event.target.value);
  };

  const { mutateAsync: setPreferred } = usePatchPreferredLanguage({
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {}
  });

  const onLanguageChanged = () => {
    dispatch(selectLanguage(lang));
    props.i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
 if (isLoggedIn) {
      setPreferred({ preferred_language: languageMapped[lang] });
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }  };
  return (
       <Grid>
        <SettingsSubTitle text={t("Language")} />
        <SettingsDescription
          className="settings-description-header"
          text={t("langSettingsDescription")}
        />
        <Grid container rowSpacing={4} columnSpacing={1}>
          <Grid item sm={12} md={12} style={{ width: "100%" }}>
            <InputLabel text={t("Language")} />
            <FormControl>
              <RadioGroup value={lang} onChange={onChangeLang}>
                <RadioButtonItem
                  imageUrl={`${window.origin}/images/CountryCodes/united_kingdom.png`}
                  label={t("English")}
                >
                  <RadioButton value="en" />
                </RadioButtonItem>
                <RadioButtonItem
                  imageUrl={`${window.origin}/images/CountryCodes/united_arab_emirates.png`}
                  label={t("Arabic")}
                >
                  <RadioButton value="arabic" />
                </RadioButtonItem>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6} style={{ width: "100%" }}>
            <PrimaryButton
              text={t("Save changes")}
              onClick={onLanguageChanged}
              style={{
                width: isMobileView ? "100%" : "initial",
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium"
              }}
            />
          </Grid>
        </Grid>
      </Grid>
   );
};

export default withTranslation()(LanguageSettings);
