import { apiClient } from "services/apiClient";

export const patchPreferredLanguage = async ({ preferred_language } = {}) => {
  return apiClient({
    url: "/account/update-preferred-language",
    method: "PATCH",
    data: {
      preferred_language
    },

    headers: {
      Authorization: `Bearer ${localStorage.getItem("private-token")}`
    }
  }).then((res) => res.data);
};
