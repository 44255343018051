import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

// useIsMobileView Function:
// This function is used to determine whether the current view is in mobile mode or not.
// It takes an optional breakPointValue parameter, which specifies the breakpoint value (default is "md" for medium screens).
// It utilizes the useTheme hook from the Emotion library to access the current theme.
// It employs the useMediaQuery hook from the Material-UI library to check whether the screen size is greater than or equal to the specified breakpoint.
// The result is a boolean value (true if it's a mobile view, false otherwise).

// useIsLanguageArabic Function:
// This function is used to determine if the current language is Arabic.
// It uses the useTranslation hook to access the i18n object, which contains information about the current language.
// It checks if the i18n.language is set to "arabic" and returns a boolean value (true if the language is Arabic, false otherwise).

export const useIsMobileView = (breakPointValue = "md") => {
  const theme = useTheme();

  const moreThanSm = useMediaQuery(theme.breakpoints.up(breakPointValue));

  return !moreThanSm;
};

export const useIsLanguageArabic = () => {
  const { i18n } = useTranslation();

  return i18n.language === "arabic";
};
