import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

export const pathSlice = createSlice({
  name: "path",
  initialState,
  reducers: {
    selectPath: (state, action) => {
      state = action.payload;
      return state;
    }
  }
});

export const { selectPath } = pathSlice.actions;
export default pathSlice.reducer;
