import { apiClient } from "../apiClient";

export const getEvents = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-events-and-offers-content",
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};

export const getEventsById = async ({ id, segment = "Default" }) => {
  return apiClient({
    url: `/cms/fetch-events-content/${id}`,
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};

export const getEventsLanding = async ({
  segment = "Default",
  content_type
}) => {
  return apiClient({
    url: `/cms/fetch-events-content?content_type=${content_type}`,
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};
