import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Grid, Box } from "@mui/material";
import ViewAllButton from "Assets/ViewAllButton";
import Item from "./item";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinkType from "Components/LinkType";

const CategoryList = ({
  items,
  ButtonText,
  home,
  data_source,
  pdngtop,
  pathto,
  title,
  color_theme,
  link_type,
  configuration_events,
  configuration_offers,
  show_brand_title
}) => {
  const { t, i18n } = useTranslation();
  const mobile = useMediaQuery("(max-width:550px)");
  const { color_definition } = color_theme || {};
  const [configurationItems, setConfigurationItems] = useState([]);

  // sortingDesc function: Sorts the data array in descending order based on the "publish_date" property, with more recent dates appearing first.
  // sortingAsc function: Sorts the data array in ascending order based on the "publish_date" property, with older dates appearing first.

  const sortingDesc = useCallback((data) => {
    return data?.sort((a, b) => (a?.publish_date > b?.publish_date ? -1 : 1));
  }, []);

  const sortingAsc = useCallback((data) => {
    return data?.sort((a, b) => (a?.publish_date > b?.publish_date ? 1 : -1));
  }, []);

  useEffect(() => {
    let tempList = [];
    if (
      configuration_events?.component_type === data_source.toLowerCase() ||
      "MANUAL"
    ) {
      tempList =
        configuration_events?.sorting === "DESCENDING"
          ? sortingDesc(items)?.slice(0, configuration_events?.max_items || 12)
          : sortingAsc(items)?.slice(0, configuration_events?.max_items || 12);
      setConfigurationItems(tempList);
    }
    if (
      configuration_offers?.component_type === data_source.toLowerCase() ||
      "MANUAL"
    ) {
      tempList =
        configuration_offers?.sorting === "DESCENDING"
          ? sortingDesc(items)?.slice(0, configuration_offers?.max_items || 12)
          : sortingAsc(items)?.slice(0, configuration_offers?.max_items || 12);
      setConfigurationItems(tempList);
    } else setConfigurationItems(items);
  }, [
    configuration_events,
    configuration_offers,
    data_source,
    items,
    sortingAsc,
    sortingDesc
  ]);

  return (
    <>
      <Grid
        container
        spacing={"42.67px"}
        style={{
          backgroundColor: color_definition
            ? color_definition?.background_color
            : "#FFFFFF",
          direction: i18n.language === "arabic" ? "rtl" : "ltr",
          marginTop: "0px"
        }}
        sx={{
          paddingTop: home ? { xs: "32px", md: "48px" } : "0px",
          paddingBottom: home ? { xs: "32px", md: "48px" } : "0px",
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" }
        }}
      >
        <Grid item xs={12} sx={{ paddingTop: pdngtop || "" }}>
          <Box
            className="home-info-card-head-category"
            sx={{
              display: "flex",
              fontFamily:
                i18n.language === "arabic" ? "Tajawalbold " : "ArchivoBlack",
              letterSpacing: i18n.language === "arabic" ? "0.00em" : "0.2em",
              fontSize:
                i18n.language === "arabic" ? { xs: "18px" } : { xs: "14px" }
            }}
            style={{
              color: color_definition ? color_definition?.title : "#000000"
            }}
          >
            {title}
          </Box>
        </Grid>
        {configurationItems &&
          configurationItems?.map((category, index) => (
            <Grid
              item
              xs={mobile ? 12 : 6}
              sm={6}
              md={6}
              lg={3}
              key={category.id}
              sx={{
                paddingTop: {
                  xs: index < 1 ? "24px !important" : "32px !important",
                  sm: index < 2 ? "24px !important" : "32px !important",
                  md: index < 3 ? "24px !important" : "32px !important",
                  lg: index < 4 ? "24px !important" : "32px !important"
                }
              }}
            >
              <Item
                card={category}
                color_definition={color_definition}
                show_brand_title={show_brand_title}
                data_source={data_source}
              />
            </Grid>
          ))}
        <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
          {" "}
          {ButtonText ? (
            <Box
              sx={{
                width: "100%",
                display: "flex"
              }}
            >
              <LinkType link_type={link_type} link={pathto}>
                <ViewAllButton
                  sx={{
                    marginTop: "24px",
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalMedium"
                        : "MontserratMedium",
                    fontSize: i18n.language === "arabic" ? "15px" : "12px"
                  }}
                  style={{
                    backgroundColor: color_definition
                      ? color_definition?.cta_button_fill
                      : "#F3F3FA",
                    color: color_definition?.cta_button_text
                      ? color_definition?.cta_button_text
                      : "#000000",
                    border: `1px solid ${
                      color_definition?.cta_button_border
                        ? color_definition?.cta_button_border
                        : "#000000"
                    }`
                  }}
                >
                  {ButtonText || t("View all brands")}
                </ViewAllButton>
              </LinkType>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
export default CategoryList;
