import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  linkName: localStorage.getItem("linkName") || ""
};

export const linkSlice = createSlice({
  name: "linkName",
  initialState,
  reducers: {
    selectlink: (state, action) => {
      state.linkName = action.payload;
    }
  }
});

export const { selectlink } = linkSlice.actions;
export default linkSlice.reducer;
