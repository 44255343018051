import React from "react";
import { Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const LightBackground = ({
  text,
  textalign,
  paddingTp,
  minheight,
  xsMinHeight,
  textContainerStyle = {},
  containerStyle = {},
  children,
  paddingleft,
  paddingright
}) => {
  const { t, i18n } = useTranslation();

  //The minheightControl function calculates the minimum height for different screen sizes (xs and sm) based on certain conditions and input values. Here's a summary of its functionality:
  // If the xsMinHeight variable is defined:
  // If minheight is defined, it returns an object with specific minimum heights for xs and sm screen sizes.
  // If minheight is not defined, it returns an object with xsMinHeight for xs and a default value of "260px" for sm.
  // If the xsMinHeight variable is not defined:
  // If minheight is defined, it returns an object with a default value of "122px" for xs and the specified minheight for sm.
  // If minheight is not defined, it returns an object with "122px" for xs and "260px" for sm as default values.

  const minheightControl = () => {
    if (xsMinHeight) {
      if (minheight) {
        return { xs: xsMinHeight, sm: minheight };
      } else {
        return { xs: xsMinHeight, sm: "260px" };
      }
    } else {
      if (minheight) {
        return { xs: "122px", sm: minheight };
      } else {
        return { xs: "122px", sm: "260px" };
      }
    }
  };
  return (
    <Grid
      className="reward-system"
      container
      rowSpacing={0}
      columnSpacing={{ xs: 0 }}
      sx={{
        paddingLeft: { xs: "20px", md: paddingleft || "11.11%" },
        paddingRight: {
          xs: "20px",
          md: paddingright || "11.11%"
        },
        paddingTop: paddingTp || "5%",
        height: "100%",
        minHeight: minheightControl,
        maxHeight: "340px",
        background: "#F3F3FA",
        justifyContent: { xs: "center", md: "space-between" },
        alignItems: "flex-end",
        textAlign: textalign || "left",
        flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
        ...containerStyle
      }}
    >
      <Box
        sx={{
          paddingBottom: { xs: "32px", md: "64px" },
          ...textContainerStyle
        }}
        className={
          i18n.language === "arabic" ? "user-name-arabic" : "user-name"
        }
      >
        {children}
        {text}
      </Box>
    </Grid>
  );
};

export default LightBackground;
