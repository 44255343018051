import Brightness1Icon from "@mui/icons-material/Brightness1";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { Checkbox } from "formik-mui";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  clearRouteCntrl,
  setPersonelInfo,
  setRouteCntrl
} from "store/authanticateSlice";
import { useAuthRegister, useAuthSetNumber } from "services/auth/auth.query";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import IconCheckboxes from "Assets/CustomComponents/IconCheckboxes";
import { setBearerToken } from "services/apiClient";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import MaterialUIPickers from "Assets/Shared/Datepicker/MaterialUIPickers";
import { countries_radius } from "Assets/helper/helper";
import { useUserStore } from "hooks/useUser";

const styleArabic = {
  fontSize: 18,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular",
  textAlign: "right"
};
const styleEnglish = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  textAlign: "left"
};

const styleMenuArabic = {
  display: "flex",
  justifyContent: "flex-end",
  fontFamily: "TajawalRegular",
  fontSize: 18
};
const styleMenu = {
  display: "flex",
  justifyContent: "flex-start",
  fontFamily: "MontserratRegular",
  fontSize: 16
};

const muihelper = {
  color: "#f66464 !important",
  marginLeft: " 0px !important",
  fontFamily: "MontserratRegular",
  fontSize: "14px !important",
  lineHeight: "130% !important",
  marginTop: "8px !important",
  textAlign: "left"
};
const muihelperarabic = {
  color: "#f66464 !important",
  marginRight: " 0px !important",
  fontFamily: "TajawalRegular",
  fontSize: "14px !important",
  lineHeight: "130% !important",
  marginTop: "8px !important",
  textAlign: "right"
};

const styleArabicSelect = {
  fontSize: 16,
  padding: "15px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular",
  backgroundColor: "white !important"
};

const styleEnglishSelect = {
  fontSize: 16,
  padding: "15px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  backgroundColor: "white !important"
};
const BasicSelectAmberAlliance = ({ setDynamic, initialPersonalDetails }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { setUser } = useUserStore();
  const { alliance_id } = useSelector((state) => state.public);

  //The selectCntrl function is designed to select and return a specific style for a select element based on the current language specified by i18n.language.
  //If the language is "arabic," it returns the styleArabicSelect, and for any other language, it returns the styleEnglishSelect.
  // This function enables different styling for the select element depending on the language setting.

  const selectCntrl = () => {
    if (i18n.language === "arabic") {
      return styleArabicSelect;
    } else {
      return styleEnglishSelect;
    }
  };

  //The code generates a new array called oneofcountry by iterating through the countries_radius array. For each item (country object) in the countries_radius array,
  //it utilizes the t function to fetch the translated name of the country by invoking t(country.name). The outcome is an array containing the translated names of the countries,
  // effectively providing a list of localized country names.

  const oneofcountry = countries_radius.map((country) => {
    return t(country.name);
  });

  const {
    mutateAsync: register,
    isLoading: isAuthRegisterLoading,
    error: authRegisterError
  } = useAuthRegister({ onSuccess: () => {}, onError: () => {} });
  const {
    mutateAsync: setNumber,
    isLoading: isSetNumberLoading,
    error: setNumberRegisterError
  } = useAuthSetNumber({ onSuccess: () => {}, onError: () => {} });

  const isNewUser = !initialPersonalDetails;
  const initialValues = initialPersonalDetails || {
    firstName: "",
    lastName: "",
    gender: t("Please select"),
    nationality: t("Select"),
    date: "",
    email: "",
    terms: !isNewUser,
    data_sharing: true
  };
  const validationSchema = yup.object({
    firstName: yup.string().required(t("First Name cannot be blank")),
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    lastName: yup.string().required(t("Last Name cannot be blank")),
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    email: yup
      .string()
      .email(t("Please check your email address"))
      .required(t("Please check your email address")),
    date: yup
      .string()
      .nullable()
      .required(t("Date of birth should be selected"))
      .test(
        "minimumAge",
        t("You must be at least 15 years old to enroll in Amber."),
        (value) => {
          if (!value) return false; // Return false if the value is empty
          const currentDate = new Date();
          const selectedDate = new Date(value);
          const age = currentDate.getFullYear() - selectedDate.getFullYear();
          const monthDiff = currentDate.getMonth() - selectedDate.getMonth();
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && currentDate.getDate() < selectedDate.getDate())
          ) {
            // Decrease age by 1 if the current date is earlier than the selected date in the same birth month
            return age - 1 >= 15;
          }
          return age >= 15;
        }
      ),
    nationality: yup
      .string()
      .oneOf(oneofcountry, t("Please select your nationality")),
    termsAndConditions: yup.bool().oneOf([true], t("In order to continue")),
    gender: yup
      .string()
      .oneOf(["0", "1", "99"], t("Gender cannot be blank"))
      .required("Gender cannot be blank"),
    terms: yup
      .bool()
      .oneOf([isNewUser], t("Please confirm you accept our Terms & Conditions"))
  });
  const genderCategory = [
    {
      value: "0",
      label: t("Male")
    },
    {
      value: "1",
      label: t("Female")
    },
    {
      value: "99",
      label: t("Not specified")
    }
  ];

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFirstsubmit(values);
    }
  });

  //The handleMainFunction function is designed to process and sanitize the values of the "firstName" and "lastName" fields in a form. It performs the following actions:
  //Removes leading spaces from the "firstName" and "lastName" values.Normalizes multiple consecutive spaces to a single space.
  //Removes non-alphabetic characters from the "firstName" and "lastName" values.
  //This function is used to clean and format user input, ensuring that the names contain only valid characters and are properly formatted.

  const handleMainFunction = () => {
    formik.values.firstName = formik.values.firstName
      .replace(/^\s+/, "")
      .replace(/\s{2,}/g, " ")
      .replace(/[^a-zA-Z\u0600-\u06FF\s]/g, "");
    formik.values.lastName = formik.values.lastName
      .replace(/^\s+/, "")
      .replace(/\s{2,}/g, " ")
      .replace(/[^a-zA-Z\u0600-\u06FF\s]/g, "");
  };

  //The formatDate function takes a date object and converts it into a string with the "YYYY-MM-DD" format.
  //It ensures that the month and day components have two digits each and that the year is represented in full.
  //This formatting is commonly used to represent dates in a standardized and sortable format.

  const formatDate = (date) => {
    const dateObject = new Date(date.date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  // The handleFirstsubmit function is used for user registration or updating user information. It takes user input data and performs the following steps:
  // If the user is a new user (determined by the isNewUser flag), it calls the register function with user details, including first name, last name, email, gender
  // , birthday (formatted using the formatDate function), nationality, terms, data sharing preferences, and an alliance ID.
  // If the user is not a new user, it calls the setNumber function with user details, including first name, last name, gender, birthday, and alliance ID.
  // Upon successful registration or update, it sets the user token, updates the application's route control, and navigates the user to the "/alliance-code" page.
  // In summary, the function manages user registration or updates and handles the user's navigation within the application, depending on whether they are a new user or not.

  const handleFirstsubmit = (values) => {
    const onSuccess = (response) => {
      setUser(response.token);
      setBearerToken(response.token);
      const newRoute = { personal: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));
      dispatch(setPersonelInfo(values));
      navigate("/alliance-code");
    };
    if (isNewUser) {
      register({
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        gender: Number(values.gender),
        birthday: formatDate(values),
        nationality: values.nationality,
        advertisement: false,
        terms: values.terms,
        data_sharing: true,
        alliance_id: alliance_id
      }).then(onSuccess);
    } else {
      setNumber({
        first_name: values.firstName,
        last_name: values.lastName,
        gender: Number(values.gender),
        birthday: formatDate(values),
        alliance_id: alliance_id
      }).then(onSuccess);
    }
  };

  useEffect(() => {
    handleMainFunction();
  }, [formik.values.firstName, formik.values.lastName]);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setDynamic(true);
    } else setDynamic(false);
  }, [formik.errors]);
  const inputCntrl = () => {
    if (i18n.language === "arabic") {
      return styleArabic;
    } else {
      return styleEnglish;
    }
  };

  const serverErrorMessage = (setNumberRegisterError || authRegisterError)
    ?.response?.data?.message;
    
   const isLoading = isSetNumberLoading || isAuthRegisterLoading;

  return (
    <FormikProvider value={formik} style={{ marginBottom: "20px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={0}
          className="textfont"
          sx={{
            "& .MuiFormHelperText-root": {
              textAlign:
                i18n.language === "arabic" ? muihelperarabic : muihelper
            }
          }}
        >
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("First name")}
            </div>

            <TextField
              id="firstName"
              name="firstName"
              placeholder={t("First name")}
              autoComplete="off"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              helperText={
                formik.touched.firstName ? formik.errors.firstName : ""
              }
              variant="outlined"
              fullWidth
              inputProps={{
                style: inputCntrl(),
                maxLength: 20
              }}
              sx={{
                input: {
                  "&::placeholder": {
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
            />
          </Grid>{" "}
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Last name")}
            </div>

            <TextField
              id="lastName"
              name="lastName"
              autoComplete="off"
              placeholder={t("Last name")}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              helperText={formik.touched.lastName ? formik.errors.lastName : ""}
              variant="outlined"
              fullWidth
              inputProps={{
                style: inputCntrl(),
                maxLength: 20
              }}
              sx={{
                input: {
                  "&::placeholder": {
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
            />
          </Grid>{" "}
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Email")}{" "}
            </div>

            <TextField
              id="email"
              name="email"
              autoComplete="off"
              placeholder={t("Email")}
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.touched.email ? formik.errors.email : ""}
              variant="outlined"
              fullWidth
              inputProps={{
                style: inputCntrl(),
                maxLength: 60
              }}
              sx={{
                input: {
                  "&::placeholder": {
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
            />
          </Grid>{" "}
          <Grid item xs={12} style={{ marginBottom: "12px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Date of birth")}{" "}
            </div>

            <MaterialUIPickers
              value={formik.values.date}
              onChange={(date) => formik.setFieldValue("date", date)}
              helperText={formik.touched.date ? formik.errors.date : ""}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Gender")}{" "}
            </div>

            <TextField
              select
              id="Gender"
              placeholder="Please Select"
              value={formik.values.gender}
              onChange={formik.handleChange("gender")}
              helperText={formik.touched.gender ? formik.errors.gender : ""}
              margin="dense"
              variant="outlined"
              fullWidth
              sx={{
                marginTop: "0px",
                ".MuiInputBase-input": {
                  padding: "12px 10px !important",
                  fontSize: "14px",
                  marginTop: "0px"
                },
                ".MuiSelect-select": {
                  overflow: "auto",
                  color:
                    formik.values.gender === t("Please select")
                      ? "#90969E"
                      : "#000000",
                  fontSize: "16px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular"
                      : "MontserratRegular"
                },
                ".MuiSvgIcon-root": {
                  left: i18n.language === "arabic" ? "7px !important" : "auto",
                  right: i18n.language === "arabic" ? "auto" : "7px !important",
                  "&.MuiSvgIcon-root path": {
                    d: 'path("M 7.41 8.59 L 12 13.17 l 4.59 -4.58 L 18 10 l -6 6 l -6 -6 l 1.41 -1.41 Z")'
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
              PaperProps={{
                style: {
                  left: "50%",
                  transform: "translateX(-77%) translateY(32%)"
                }
              }}
            >
              <MenuItem
                style={i18n.language === "arabic" ? styleMenuArabic : styleMenu}
                value={t("Please select")}
                disabled
              >
                {t("Please select")}
              </MenuItem>
              {genderCategory.map((option) => (
                <MenuItem
                  style={
                    i18n.language === "arabic" ? styleMenuArabic : styleMenu
                  }
                  key={option.value}
                  value={option.value}
                >
                  {t(option.label)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Nationality")}{" "}
            </div>

            <TextField
              select
              id="nationality"
              placeholder="Please Select"
              value={formik.values.nationality}
              onChange={formik.handleChange("nationality")}
              helperText={
                formik.touched.nationality ? formik.errors.nationality : ""
              }
              margin="dense"
              variant="outlined"
              fullWidth
              sx={{
                marginTop: "0px",

                ".Mui-selected": {
                  backgroundColor: " white !important"
                },
                ".MuiInputBase-input": {
                  padding: "10px !important",
                  fontSize: "14px",
                  marginTop: "0px"
                },
                ".MuiOutlinedInput-root": {
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular"
                      : "MontserratRegular"
                },
                ".MuiSelect-select": {
                  overflow: "auto",
                  color:
                    formik.values.nationality === t("Select")
                      ? "#90969E"
                      : "#000000",
                  fontSize: "16px"
                },
                ".MuiSvgIcon-root": {
                  left: i18n.language === "arabic" ? "7px !important" : "auto",
                  right: i18n.language === "arabic" ? "auto" : "7px !important",
                  "&.MuiSvgIcon-root path": {
                    d: 'path("M 7.41 8.59 L 12 13.17 l 4.59 -4.58 L 18 10 l -6 6 l -6 -6 l 1.41 -1.41 Z")'
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
            >
              <MenuItem
                value={t("Select")}
                sx={{
                  justifyContent:
                    i18n.language === "arabic" ? "flex-end" : "flex-start"
                }}
                style={selectCntrl()}
                disabled
              >
                {t("Select")}
              </MenuItem>
              {oneofcountry.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  style={{
                    justifyContent:
                      i18n.language === "arabic" ? "flex-end" : "flex-start"
                  }}
                  sx={selectCntrl()}
                >
                  {t(option)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {isNewUser && (
          <>
            <div className="textdisplay">
              <div
                className={
                  i18n.language === "arabic"
                    ? "datetext-sub-arabic"
                    : "datetext-sub"
                }
              >
                <IconCheckboxes />

                <div>
                  {t(
                    "Yes, keep me updated on news, events, offers and promotions."
                  )}
                </div>
              </div>

              <div
                className={
                  i18n.language === "arabic"
                    ? "datetext-sub-arabic"
                    : "datetext-sub"
                }
              >
                <Field
                  name="terms"
                  type="checkbox"
                  component={Checkbox}
                  style={{ borderRadius: "50%" }}
                  icon={
                    <Brightness1Icon
                      style={{
                        color: "#DFE1EA",
                        backgroundColor: "#DFE1EA",
                        borderRadius: "50%",
                        fontSize: "1rem",
                        padding: "3px"
                      }}
                    />
                  }
                  checkedIcon={
                    <DoneIcon
                      style={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        fontSize: "1rem",
                        padding: "3px"
                      }}
                    />
                  }
                />

                <div>
                  {t("By signing up you agree to our")}{" "}
                  <Link
                    style={{ color: "#5A5F64" }}
                    to="/legal/terms-and-conditions"
                    target="_blank"
                  >
                    {t("Terms & Conditions")}
                  </Link>
                  {t(". Please visit our")}{" "}
                  <Link
                    style={{ color: "#5A5F64" }}
                    to="/legal/privacy-policy"
                    target="_blank"
                  >
                    {t("Privacy Policy")}
                  </Link>{" "}
                  {t("to understand how we handle your personal data.")}
                </div>
              </div>
              {formik.errors.terms && formik.touched.terms && (
                <div
                  className={
                    i18n.language === "arabic"
                      ? "datetext-sub-arabic"
                      : "datetext-sub"
                  }
                  style={{
                    paddingLeft: "34px",
                    color: "#F66464",
                    fontSize: "14px",
                    marginBottom: "8px",
                    marginTop: "8px"
                  }}
                >
                  {formik.errors.terms}
                </div>
              )}
            </div>
          </>
        )}

        {serverErrorMessage && (
          <AlertWarning marginbottom="24px" text={t(serverErrorMessage)} />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            marginTop: { xs: "16px", md: "24px" }
          }}
        >
          {isLoading ? (
            <ColorLoadingButton
              size="small"
              loading={true}
              variant="outlined"
              disabled
              type="submit"
              className="button-submit-loading-create-account"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                maxWidth: "100%",
                width: "100%",
                padding: "18px 16px"
              }}
            />
          ) : (
            <ColorButton
              type="submit"
              variant="contained"
              className="button-submit-pd"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                width: "100%"
              }}
            >
              {t("Create account")}
            </ColorButton>
          )}
        </Box>
      </form>
    </FormikProvider>
  );
};

export default BasicSelectAmberAlliance;
