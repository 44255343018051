import React from "react";
import BasicTable from "Assets/CustomComponents/BasicTable";

export const AmberTierMatrixContainer = ({ widget }) => {
  return (
    <>
      <BasicTable widget={widget} />
    </>
  );
};
