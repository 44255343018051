import { Box } from "@mui/material";
import React from "react";
import { AboutAmberMenu, AboutAmberMenuMobile } from "./AboutAmberMenu";
import { useIsLanguageArabic, useIsMobileView } from "hooks";
import { Outlet } from "react-router-dom";
import Layout from "../../Layout";
import LightBackground from "Components/LightBackground";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();
  const isLanguageArabic = useIsLanguageArabic();

  return (
    <Layout className="logged">
      <Box className="about">
        <LightBackground
          xsMinHeight="200px"
          paddingTp="0px"
          minheight="320px"
          text={t("About Amber")}
        />
        <Box
          sx={{
            padding: { md: "80px 160px", xs: "20px" },
            height: "100%",
            background: "white",
            display: "flex",
            alignItems: "flex-start",
            direction: isLanguageArabic ? "rtl" : "ltr",
            width: { md: "80px 160px", xs: "calc(100%-20px)" },
            flexDirection: isMobileView ? "column" : "row"
          }}
        >
          <Box className="menu-container">
            {!isMobileView ? <AboutAmberMenu /> : <AboutAmberMenuMobile />}
          </Box>
          <Box className="outlet-container">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default AboutPage;
