import { useState, useEffect, useMemo } from "react";
import Layout from "../Layout";
import LightBackground from "Components/LightBackground";
import { ContentTypesEnums, DataSourceEnums } from "utils/enums";
import { useGetOffersLanding } from "services/offers/offers.query";
import { OFFERS_RESULTS_CMS_COMPONENT_MAP } from "CmsComponents";
import { Box } from "@mui/material";
import { LightBackgroundControl } from "GlobalProcess/GlobalFunction";
import { CategoryListFilteredContainer } from "CmsComponents/CategoryListFilteredContainer";
import { useNavigate } from "react-router-dom";
import { useGetRecordIdsAndTypes } from "services/brands/brands.query";
const EventsOffers = () => {
  const [activeButton, setActiveButton] = useState("all");
  const { data: Offers = [] } = useGetOffersLanding({
    content_type: "offer_landing_page"
  });
  const { widgets = [],seo_data } = Offers;
  const navigate = useNavigate();

  const componentDetails = Offers?.widgets?.find(
    (item) => item?.component_type === "mini-navigation"
  );
  const OffersLoadContentColorTheme = componentDetails?.color_theme || {};

  // emptyDataMessaging:
  // The useMemo hook is used to compute and memoize a value based on the Offers object (or variable).
  // It uses the find method to search for an item within the widgets array that has a specific component_type equal to "empty-data-messaging."
  // The result is memoized, and this component or information is likely used for displaying a message or component when there is no data to show.

  // carouselCount:
  // Another useMemo hook is used to compute and memoize a value based on the widgets array.
  // It searches for an item within the widgets array that has a component_type equal to ContentTypesEnums.CAROUSEL.
  // It then checks if the found item has a non-empty items array, which likely indicates the presence of carousel content.
  // The result is memoized, and this information might be used to determine whether to render a carousel component.

  const emptyDataMessaging = useMemo(() => {
    return Offers?.widgets?.find(
      (item) => item?.component_type === "empty-data-messaging"
    );
  }, [widgets]);

  const carouselCount = useMemo(() => {
    return (
      widgets?.find(
        (item) => item?.component_type === ContentTypesEnums.CAROUSEL
      )?.items?.length > 0
    );
  }, [widgets]);

  const { data: IdsAndCountsData } = useGetRecordIdsAndTypes({
    data_source: DataSourceEnums.OFFERS
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  return (
    <Layout seo_data={seo_data} className="events">
      {!LightBackgroundControl(widgets) && (
        <LightBackground
          text={Offers?.title}
          xsMinHeight="200px"
          paddingTp="0px"
          minheight="320px"
          containerStyle={{
            justifyContent: "initial"
          }}
        />
      )}
      <Box sx={{ minHeight: "100vh" }}>
        {widgets?.map((widget) => {
          const CmsComponent =
            OFFERS_RESULTS_CMS_COMPONENT_MAP[widget["component_type"]];
          if (CmsComponent) {
            return (
              <CmsComponent
                key={widget?.component_type}
                widget={widget}
                page={"offers"}
                activeButton={activeButton}
                ButtonText={`${componentDetails?.cta_button.title}`}
                color_theme={OffersLoadContentColorTheme}
                setActiveButton={setActiveButton}
                CategoryType={DataSourceEnums.OFFERS}
                IdsAndCountsData={IdsAndCountsData}
              />
            );
          }
          return null;
        })}
        <CategoryListFilteredContainer
          CategoryType={DataSourceEnums.OFFERS}
          activeButton={activeButton}
          ButtonText={componentDetails?.cta_button.title}
          color_theme={OffersLoadContentColorTheme}
          web_message={!carouselCount && emptyDataMessaging?.web_message}
          web_message_title={!carouselCount && emptyDataMessaging?.title}
        />
      </Box>{" "}
    </Layout>
  );
};

export default EventsOffers;
