import { serviceMessages } from "./serviceMessages";

export const mapResponseMessage = ({ error, url, method }) => {
  const errorCode = error.response?.data?.error_code;
  const messageKey =
    (errorCode ? errorCode + "-" : "") +
    (url + "/" + method).replace("/", "").split("/").join("-").toLowerCase();

  if (process.env.NODE_ENV === "development") {
    console.log(messageKey);
  }
  const mappedServiceMessage = serviceMessages[messageKey];
  if (mappedServiceMessage) {
    if (error?.response?.data && !error?.response?.data?.message) {
      error.response.data = {};
    }
    error.response.data.message = mappedServiceMessage;
  }
};
