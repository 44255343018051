import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import BrandsLightBackground from "Assets/CustomComponents/BrandsLightBackground";
import SwiperCarousal from "Assets/CustomComponents/SwiperCarousal";
import BrandDetail from "Assets/images/BrandDetail.png";
import MapComponent from "Components/GoogleMap/MapComponent";
import LocationComponent from "Components/LocationComponent";
import { CarousalSublistContainer } from "CmsComponents/CarousalSublistContainer";
import { BRAND_DETAIL_COMPONENT_MAP } from "CmsComponents";
import { useGetBrandById, useGetBrands } from "services/brands/brands.query";
import { useDataLayer } from "hooks/useDataLayer";
import Layout from "../Layout";
import { NavBarContainer } from "../NavBarContainer";

const BrandStoreDetails = ({ scrollCntrl, setScrollCntrl }) => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { setImpressionEvent } = useDataLayer();
  const userAgent = window?.navigator?.userAgent;

  const {
    data: {
      brand,
      brand_logo,
      primary_category,
      secondary_category,
      ui_properties,
      widgets = [],
      slug_url,
      seo_data
    } = {},
    isLoading
  } = useGetBrandById({ id });
  const { data: brands = [] } = useGetBrands();
  const brandsswidgets = brands?.widgets;

  const [selectedValue, setSelectedValue] = useState();
  const [widgetOptions, setWidgetOptions] = useState({
    items: [],
    storeLocations: {},
    animation_speed: 98,
    auto_slide: false,
    contentDescription: {},
    background_color_carousel: null,
    relatedBrandArticles: null
  });

  /* 
    The provided code segment utilizes the useMemo hook to create a mapping of widget data by component type. 
    It iterates through the widgets array, which presumably contains information about various components, and organizes this data into a componentMap object.
    This object serves as a way to associate component types with their corresponding widget data. 
    The useMemo hook ensures that this mapping is computed efficiently and recalculated only when the widgets array changes.
    This organized data can be beneficial for rendering and managing different components within the application.
  */

  useEffect(() => {
    const brandDetailMapData = _.mapKeys(widgets, "component_type");

    if (!isLoading && brandDetailMapData) {
      setWidgetOptions(() => ({
        items:
          brandDetailMapData[BRAND_DETAIL_COMPONENT_MAP["brand-item-carousel"]]
            ?.items,
        storeLocations:
          brandDetailMapData[BRAND_DETAIL_COMPONENT_MAP["store-locations"]],
        animation_speed:
          brandDetailMapData[BRAND_DETAIL_COMPONENT_MAP["brand-item-carousel"]]
            ?.animation_speed,
        auto_slide:
          brandDetailMapData[BRAND_DETAIL_COMPONENT_MAP["brand-item-carousel"]]
            ?.auto_slide,
        contentDescription:
          brandDetailMapData[BRAND_DETAIL_COMPONENT_MAP["content-description"]],
        background_color_carousel:
          brandDetailMapData[BRAND_DETAIL_COMPONENT_MAP["brand-item-carousel"]]
            ?.color_theme?.color_definition?.background_color,
        relatedBrandArticles:
          brandDetailMapData[
            BRAND_DETAIL_COMPONENT_MAP["related-brand-articles"]
          ]
      }));
    }
  }, [widgets, isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollCntrl(false);
  }, [scrollCntrl, setScrollCntrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (slug_url) {
      setImpressionEvent({
        page: slug_url,
        itemtype: "brand",
        brand: brand
      });
    }
  }, [brand, slug_url, setImpressionEvent]);

  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  const amberImage = widgets?.filter(
    (widget) => widget?.component_type === "amber-image"
  );
  const ImagePlaceholder4 = amberImage?.[0]?.image;
  const ImagePlaceholder3 = amberImage?.[1]?.image;
  const lines = widgetOptions.contentDescription?.description
    ?.split("\n")
    .filter((line) => line.trim() !== "");
  const navbar = brandsswidgets?.find(
    (widget) => widget?.component_type === "web-homepage-main-navigation"
  );

  // It initializes an empty locations array.
  // It iterates through the storeLocations?.stores array using the forEach method to access each store.
  // For each store, it further iterates through the store.country array (assuming it's an array) using forEach to access each country object.
  // If the store_country.store_iso_code matches the selectedValue, it iterates through the storeCountryItem.items array (again, assuming it's an array) using forEach.
  // For each storeCountryItem, it extracts the lat and long properties and pushes an object containing these values into the locations array.

  const locations = useMemo(() => {
    const locations = [];
    widgetOptions.storeLocations?.stores?.forEach?.((store) => {
      store.country?.forEach?.((storeCountry) => {
        if (storeCountry?.store_iso_code === selectedValue) {
          storeCountry.items?.forEach?.((storeCountryItem) => {
            const { lat, long } = storeCountryItem;
            locations.push({ lat, long });
          });
        }
      });
    });
    return locations;
  }, [widgetOptions.storeLocations?.stores, selectedValue]);
  return (
    <Layout seo_data={seo_data} className="brands">
      {userAgent !== "amber-mobile-app" && <NavBarContainer widget={navbar} />}
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0 }}
        sx={{
          paddingLeft: { xs: "0%", md: "11.11%" },
          paddingRight: { xs: "0%", md: "11.11%" },
          minHeight: "100%",
          alignContent: "center",
          justifyContent: "flex-start",
          backgroundColor: ui_properties?.background || ""
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: { xs: "20px !important", md: "0% !important" },
            paddingRight: { xs: "20px !important", md: "0% !important" }
          }}
          order={{ xs: 2, lg: 1 }}
        >
          {brand_logo && (
            <BrandsLightBackground
              logo={brand_logo}
              brand={brand}
              primaryCategory={primary_category}
              ui_properties={ui_properties}
              secondary_category={secondary_category}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: { xs: "0px " },
            paddingLeft: { xs: "0px !important", md: "0% !important" },
            paddingRight: { xs: "0px !important", md: "0% !important" },
            paddingTop: { xs: "0px  ", md: "26px ", lg: "0px" }
          }}
          order={{ xs: 1, lg: 2 }}
          className="brandsDetailCarouselContainer"
        >
          <SwiperCarousal
            items={widgetOptions.items}
            marginless={"nomargin"}
            photo={BrandDetail}
            background_color_carousel={widgetOptions.background_color_carousel}
            animation_speed={widgetOptions.animation_speed}
            auto_slide={widgetOptions.auto_slide}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: { xs: "24px", lg: "58px" },
            marginBottom: { xs: "48px", lg: "60px" },
            paddingLeft: { xs: "20px !important", md: "0% !important" },
            paddingRight: { xs: "20px !important", md: "0% !important" }
          }}
          order={{ xs: 3 }}
        >
          <Grid
            container
            order={{ xs: 5 }}
            sx={{
              paddingLeft: { xs: "0px" },
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              color: ui_properties?.background || "#FFFFFF"
            }}
          >
            <Grid
              item
              xs={12}
              lg={3.84}
              sx={{ marginTop: { xs: "0px", lg: "15px" } }}
            >
              <div
                className={
                  i18n.language === "arabic"
                    ? "brands-left-text-arabic"
                    : "brands-left-text"
                }
                style={{
                  color: ui_properties?.header_text_color || "#000000"
                }}
              >
                {widgetOptions.contentDescription?.header_description}
              </div>
            </Grid>
            <Grid item xs={12} lg={1.2}></Grid>
            <Grid item xs={12} lg={6.96}>
              {lines?.map((line) => (
                <p
                  className={
                    i18n.language === "arabic"
                      ? "brands-right-text-arabic"
                      : "brands-right-text"
                  }
                  style={{
                    color: ui_properties?.description_color || "#000000",
                    paddingLeft: { xs: "0px" }
                  }}
                >
                  {line}
                </p>
              ))}
              <Box sx={{ width: "100%", display: { xs: "none", lg: "flex" } }}>
                <LocationComponent
                  title={widgetOptions.storeLocations?.title}
                  storeLocations={widgetOptions.storeLocations}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                />
              </Box>
            </Grid>
            <Grid
              container
              sx={{ width: "100%", display: { xs: "flex", lg: "none" } }}
              order={{ xs: 1, lg: 2 }}
            >
              <Grid item xs={12} lg={6}></Grid>
              <Grid item xs={12} lg={6}>
                <LocationComponent
                  title={widgetOptions.storeLocations?.title}
                  storeLocations={widgetOptions.storeLocations}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: "48px",
            paddingLeft: { xs: "20px !important", md: "0% !important" },
            paddingRight: { xs: "20px !important", md: "0% !important" }
          }}
          order={{ xs: 4 }}
        >
          <MapComponent locations={locations} />
        </Grid>
        <Grid
          container
          order={{ xs: 5 }}
          sx={{ marginBottom: { xs: "48px", lg: "40px" } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              marginBottom: "8px",
              paddingLeft: { xs: "20px !important", md: "0% !important" },
              paddingRight: { xs: "20px !important", md: "0% !important" }
            }}
          >
            {ImagePlaceholder4 && (
              <Box
                component="img"
                src={ImagePlaceholder4}
                alt="ImagePlaceholder4"
                sx={{
                  width: "-webkit-fill-available",
                  paddingRight: { xs: "0px ", md: "12px" }
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              marginBottom: "8px",
              paddingLeft: { xs: "20px !important", md: "0% !important" },
              paddingRight: { xs: "20px !important", md: "0% !important" },
              textAlign: "end"
            }}
          >
            {ImagePlaceholder3 && (
              <Box
                component="img"
                src={ImagePlaceholder3}
                alt="ImagePlaceholder3"
                sx={{
                  width: "-webkit-fill-available",
                  paddingLeft: { xs: "0px ", md: "12px" }
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <CarousalSublistContainer widget={widgetOptions.relatedBrandArticles} />
    </Layout>
  );
};

export default BrandStoreDetails;
