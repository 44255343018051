import React, { useMemo } from "react";
import Infolist from "Components/Homecomponents/InfoList";
import useMediaQuery from "@mui/material/useMediaQuery";

export const CmsInfoList = ({ widget }) => {
  const { color_theme, small_bullet_image, items = [] } = widget;
  const tabletMax = useMediaQuery("(max-width:1199px)");

  //The transform function takes an item and an index and transforms the data into a new object with specific properties,
  // which is typically used for data mapping and processing.

  const transform = (item, index) => ({
    id: index,
    text: item.title,
    subtext: item.description,
    image: tabletMax ? item.image_mobile : item.image_web,
    link: item.link,
    link_type: item.link_type,
    bullets_cta_button: item.bullets_cta_button
  });

  //The mappedItems variable is generated using the useMemo hook, which processes a list of items, assigns IDs,
  // and applies a transformation function to create a new list of transformed items,
  // optimizing performance by recalculating only when the items array changes.

  const mappedItems = useMemo(() => items.map(transform), [items]);

  return (
    <Infolist
      helperArray={mappedItems}
      marginHome={true}
      color_theme={color_theme}
      small_bullet_image={small_bullet_image}
    />
  );
};
