import { useEffect, useState } from "react";
import { FormControl, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./TextFieldIconStyled.scss";
import CustomFormHelperTextWithTranslation from "../CustomerHelperText";
export function getCurrencyByCountry(country) {
  const Currency = {
    AE: "AED",
    KW: "KWD",
    BH: "BHD",
    QA: "QAR",
    SA: "SAR",
    OM: "OMR"
  };
  return Currency[country] || "";
}

export default function InputAdornments({
  setInputvalue,
  text,
  error,
  title,
  selected,
  errorMessage,
  currencyMappingConfigFilter,
  firstWidget,
  label_redeem
}) {
  const { i18n } = useTranslation();
  const [values, setValues] = useState({
    amount: ""
  });
  useEffect(() => {
    setValues({ amount: "" });
  }, [text]);

  // This code defines a function named "handleChange" used as an event handler for input elements. It takes a "prop" as an argument and updates state objects "values" and "inputvalue."
  //It removes non-numeric characters from the input value, limits the resulting value to a maximum of six characters, and updates the state objects accordingly. This function is typically used for input validation and data sanitization, ensuring that the input only contains numeric characters.

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value.replace(/[^\d]/g, "").slice(0, 6)
    });
    setInputvalue({
      ...values,
      [prop]: event.target.value.replace(/[^\d]/g, "")
    });
  };
  return (
    <FormControl
      fullWidth
      sx={{ mb: 1, p: 0 }}
      variant="filled"
      className="select-how"
    >
      <div
        className={
          i18n.language === "arabic" ? "select-label-arabic" : "select-label"
        }
        style={{ color: title || "" }}
      >
        {text}
      </div>

      <OutlinedInput
        id="filled-adornment-amount"
        autoComplete="off"
        placeholder=""
        error={error}
        classes={{ notchedOutline: error ? "error" : "" }}
        sx={{
          fontFamily: "MontserratMedium",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHight: "130%",
          display: "flex",
          alignItems: "center",
          color: "#90969E",
          width: { xs: "100%", lg: "480px" },
          height: "41px",
          paddingLeft: "0",
          paddingRight: "0",
          backgroundColor: "#FFFFFF",
          "& .MuiOutlinedInput-input": {
            direction: i18n.language === "arabic" ? "rtl" : "lrt",
            color: "#000"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: error
              ? "0px solid rgba(0, 0, 0, 0.23) !important"
              : "1px solid rgba(0, 0, 0, 0.23) !important"
          }
        }}
        inputProps={{
          maxLength: 6,
          style: {
            boxSizing: "border-box",
            borderTopRightRadius: i18n.language !== "arabic" ? "4px" : "0",
            borderBottomRightRadius: i18n.language !== "arabic" ? "4px" : "0",
            borderTopLeftRadius: i18n.language === "arabic" ? "4px" : "0",
            borderBottomLeftRadius: i18n.language === "arabic" ? "4px" : "0",
            paddingLeft: "15px",
            paddingRight: "15px",
            height: "41px",
            border: error ? "1px solid red" : "none"
          }
        }}
        value={values.amount}
        onChange={handleChange("amount")}
        startAdornment={
          i18n.language !== "arabic" && (
            <div
              position="start"
              className="AED"
              style={{
                borderRight: error ? "0px solid #ced0d7" : "1px solid #ced0d7",
                borderLeft: error ? "1px solid #ced0d7" : "0px solid #ced0d7",
                borderTop: error ? "1px solid #ced0d7" : "0px solid #ced0d7",
                borderBottom: error ? "1px solid #ced0d7" : "0px solid #ced0d7"
              }}
            >
              {selected === firstWidget?.[1]?.title
                ? label_redeem
                : currencyMappingConfigFilter?.currency_code}
            </div>
          )
        }
        endAdornment={
          i18n.language === "arabic" && (
            <div
              position="end"
              className="AED-arabic"
              style={{
                borderLeft: error ? "0px solid #ced0d7" : "1px solid #ced0d7",
                borderRight: error ? "1px solid #ced0d7" : "0px solid #ced0d7",
                borderTop: error ? "1px solid #ced0d7" : "0px solid #ced0d7",
                borderBottom: error ? "1px solid #ced0d7" : "0px solid #ced0d7"
              }}
            >
              {selected === firstWidget?.[1]?.title
                ? label_redeem
                : currencyMappingConfigFilter?.currency_code}
            </div>
          )
        }
      />
      {error && (
        <CustomFormHelperTextWithTranslation
          style={{
            textAlign: "initial",
            direction: i18n.language === "arabic" ? "rtl" : "lrt"
          }}
        >
          {errorMessage}
        </CustomFormHelperTextWithTranslation>
      )}
    </FormControl>
  );
}
