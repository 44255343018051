import { Snackbar, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import InfCard from "Components/EventOffers/InfCard";
import Layout from "../Layout";
import { useTranslation } from "react-i18next";
import {
  useGetOfferDetailsByID,
  useGetOffers
} from "services/offers/offers.query";
import { EVENT_DETAIL_COMPONENT_MAP } from "CmsComponents";
import { CarousalSublistContainer } from "CmsComponents/CarousalSublistContainer";
import DetailsText from "Components/DetailsText";
import { useNavigate, useParams } from "react-router-dom";
import { NavBarContainer } from "../NavBarContainer";
import { EventsOffersAsset } from "GlobalProcess/GlobalFunction";
import { useDataLayer } from "hooks/useDataLayer";
const Promotion = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { setImpressionEvent } = useDataLayer();
  const {
    data: {
      brand,
      properties,
      secondary_category,
      thumbnail,
      ui_properties,
      brand_logo,
      primary_category,
      widgets = [],
      title,
      slug_url,
      seo_data
    } = {}
  } = useGetOfferDetailsByID({ id });
  const userAgent = window?.navigator?.userAgent;

  const { data: offers = [] } = useGetOffers();
  const offersswidgets = offers?.widgets;

  // This code snippet uses the useMemo hook to create a memoized object called eventDetailMapData. It iterates through an array called widgets, mapping each item to its component_type and storing it in the componentMap object. The resulting eventDetailMapData object provides an efficient way to look up widget objects based on their component type. Memoization ensures that this object is only recomputed when the widgets array changes, which can help improve performance.

  const eventDetailMapData = useMemo(() => {
    const componentMap = {};
    widgets?.forEach((item) => {
      componentMap[item.component_type] = item;
    });
    return componentMap;
  }, [widgets]);

  const socialMediaHandles =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["social-media-handles"]];
  const schedule = eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["schedule"]];
  const eventlocation =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["event-location"]];
  const locationMap =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["location-map"]];
  const carousel =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["related-brand-articles"]];
  const content_main_banner =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["content-main-banner"]];

  const amberImages = widgets?.filter(
    (widget) => widget?.component_type === "amber-image"
  );
  const actionButtonsMapped = widgets?.filter(
    (widget) => widget?.component_type === "action-button"
  );
  const contentDescriptionMapped = widgets?.filter(
    (widget) => widget?.component_type === "content-description"
  );
  const contentDescriptionMappedText = contentDescriptionMapped?.find(
    (item) => item?.terms_conditions_content === false
  );
  const contentDescriptionMappedTermCondition = contentDescriptionMapped?.find(
    (item) => item?.terms_conditions_content === true
  );
  const navbar = offersswidgets?.find(
    (widget) => widget?.component_type === "web-homepage-main-navigation"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (slug_url) {
      setImpressionEvent({
        page: slug_url,
        itemtype: "offer",
        brand: brand,
        category: primary_category,
        title: title
      });
    }
  }, [primary_category, title, brand, slug_url, setImpressionEvent]);

  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { background } = ui_properties || {};
  return (
    <Layout seo_data={seo_data} className="brands">
      <>
        {!userAgent?.includes("amber") && <NavBarContainer widget={navbar} />}

        <InfCard
          eventFromTitle={"Offer from"}
          termsAndConditions={t("Terms & Conditions")}
          buttonPosition="center"
          brand={brand}
          brand_logo={brand_logo}
          title={title}
          dateText={schedule?.description}
          location={locationMap?.label}
          eventlocation={eventlocation}
          onClickButton={() => false}
          socialMediaHandles={socialMediaHandles}
          actionButtonsMapped={actionButtonsMapped}
          primary_category={primary_category}
          ui_properties={ui_properties}
          secondary_category={secondary_category}
          buttonStyle={{ fontSize: "12px" }}
          properties={properties}
          image={thumbnail}
          hasFooter={false}
          open={open}
          setOpen={setOpen}
          contentDescriptionMappedTermCondition={
            contentDescriptionMappedTermCondition
          }
        />
        <CarousalSublistContainer
          widget={carousel}
          ui_properties={ui_properties}
        />
        {content_main_banner?.asset && (
          <Grid
            item
            xs={12}
            sx={{
              paddingBottom: { xs: "0px ", md: "40px " },
              paddingTop: { xs: "0px !important" },
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              padding: { xs: "24px 20px ", md: " 48px 11.11%" },
              backgroundColor: background || ""
            }}
          >
            {EventsOffersAsset(content_main_banner)}
          </Grid>
        )}
        <DetailsText
          contentDescription={contentDescriptionMappedText}
          amberImages={amberImages}
          ui_properties={ui_properties}
        />
        <Snackbar
          open={open}
          sx={{
            direction: i18n.language === "arabic" ? "rtl" : "ltr"
          }}
          autoHideDuration={2000}
          onClose={handleClose}
          message={
            <div
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium"
              }}
            >
              {t("Copied to Clipboard")}
            </div>
          }
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
      </>
    </Layout>
  );
};

export default Promotion;
