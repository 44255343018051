import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Grid, Box } from "@mui/material";
import ActionAreaCard from "./item";
import useMediaQuery from "@mui/material/useMediaQuery";
import ViewAllButton from "Assets/ViewAllButton";
import { useTranslation } from "react-i18next";
import { isColor } from "GlobalProcess/GlobalFunction";
import LinkType from "Components/LinkType";
import { useDataLayer, ITEMTYPE_MAP } from "hooks/useDataLayer";
import { useLocation } from "react-router-dom";

const CarouselSubList = ({
  size,
  items,
  head,
  buttontext,
  navigate,
  paginate,
  order_cross,
  margintp,
  singleMin,
  navigateMin,
  padngtp,
  pathto,
  home,
  marginValue,
  margintpValue,
  spacebetween,
  backGround,
  marginSwiperHead,
  enabled = true,
  color_theme,
  link_type,
  show_brand_title,
  data_source,
  defaultClass,
  hidden,
  show_category,
  carosel_description_style,
  swiperClass,
  show_header_text,
  component_type,
  ui_properties
}) => {
  const { setClickEvent } = useDataLayer();
  const { i18n } = useTranslation();
  const twice = useMediaQuery("(min-width:1400px)");
  const medium = useMediaQuery("(min-width:900px) and (max-width:1399px)");
  const single = useMediaQuery("(min-width:600px)");
  const unvisiblemedium = useMediaQuery("(max-width:900px)");
  const unvisible = useMediaQuery("(max-width:599px)");
  const { background } = ui_properties || {};
  const { pathname } = useLocation();
  function setHeight(newHeight) {
    document.documentElement.style.setProperty(
      "--carousalSubHeight",
      newHeight
    );
  }
  function setHeightMobile(newHeight) {
    document.documentElement.style.setProperty(
      "--carousalSubHeightmobile",
      newHeight
    );
  }
  function setHeightMobile2(newHeight) {
    document.documentElement.style.setProperty(
      "--carousalSubHeightmobileSM",
      newHeight
    );
  }

  useEffect(() => {
    if (paginate) {
      if (size === 3) {
        setHeight("55vh");
        setHeightMobile("400px");
        setHeightMobile2("35vh");
      }
    }
  }, [paginate, size]);

  //The "paddingright" function in JavaScript calculates and returns padding values for different screen sizes based on language and a condition related to the "margintp" variable. If the language is Arabic and "margintp" is true, it returns specific padding values; otherwise, it provides different values.

  const paddingright = () => {
    if (i18n.language === "arabic") {
      if (margintp) {
        return { xs: "20px", sm: "20px", md: "11.11%" };
      } else return { xs: "20px", sm: "20px", md: "11.11%" };
    } else {
      if (margintp) {
        return { xs: "0px", sm: "20px", md: "11.11%" };
      } else return { xs: "20px", sm: "20px", md: "11.11%" };
    }
  };
  const { color_definition } = color_theme || {};

  let slidesPerView = twice
    ? size
    : medium
    ? 4
    : single
    ? 2
    : singleMin
    ? 1
    : 1.5;

  const additionalPadding = {
    paddingLeft: {
      xs: i18n.language === "arabic" ? "0px" : "20px",
      sm: "20px",
      md: "11.11%"
    },
    paddingRight: paddingright
  };

  //The "headerControl" function in JavaScript determines whether to display header text based on the "component_type." If the "component_type" includes "related," it returns the value of "show_header_text"; otherwise, it returns true.

  const headerControl = () => {
    if (component_type?.includes("related")) {
      return show_header_text;
    } else return true;
  };

  // The "onDataLayerClickEvent" function in JavaScript is used to generate a click event for tracking purposes.
  // It takes an "item" as its parameter, which contains information about the clicked item, such as "link," "link_type," "text," "category," and "maintext." The function then constructs an object with properties like "page" (set to "link"), "itemtype" (determined by mapping "link_type" to the appropriate type using the "ITEMTYPE_MAP" object), "brand" (set to "text"), "category" (joining the elements of the "category" array with commas), and "title" (set to "maintext"). This object represents the data associated with the click event.

  const onDataLayerClickEvent = (item) => {
    const { link, link_type, text, category, maintext } = item;

    setClickEvent({
      page: link,
      itemtype: ITEMTYPE_MAP[link_type],
      brand: text,
      category: category.join(","),
      title: maintext
    });
  };

  return (
    <>
      {!hidden && (
        <Box
          className={"swiper" + " " + defaultClass}
          sx={{
            marginTop: margintpValue ? { xs: "0px", md: margintpValue } : "0px"
          }}
          style={{
            backgroundColor:
              background || color_definition?.background_color || "#FFFFFF"
          }}
        >
          {items?.length >= size && (
            <Grid
              container
              style={{ width: "100%", marginLeft: "0px" }}
              rowSpacing={0}
              columnSpacing={{ xs: 0 }}
              sx={{
                paddingTop:
                  margintp && !pathname.includes("events-and-offers")
                    ? { xs: "32px", md: "48px" }
                    : "20px",
                paddingBottom:
                  margintp && !pathname.includes("events-and-offers")
                    ? { xs: "32px", md: "48px" }
                    : "0px",
                ...additionalPadding,
                marginTop: home
                  ? margintp
                    ? { xs: "57px", md: "5px" }
                    : padngtp
                    ? "9px"
                    : "5%"
                  : margintp
                  ? "0px"
                  : padngtp
                  ? "9px"
                  : "5%",
                marginBottom: marginValue || "5%",
                minHeight: "100%",
                alignContent: "center",
                justifyContent: "flex-start",
                ...(carosel_description_style || "")
              }}
              className={
                pathname.includes("events-and-offers")
                  ? "events-offers-swiper"
                  : ""
              }
            >
              <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                enabled={enabled}
                dir={i18n.language === "arabic" ? "rtl" : ""}
                slidesPerView={parseFloat(slidesPerView)}
                slidesPerGroup={parseInt(slidesPerView)}
                spaceBetween={spacebetween || 20}
                loop={false}
                loopFillGroupWithBlank={true}
                scrollbar={{ draggable: true }}
                navigation={
                  !unvisible && navigate
                    ? { clickable: true }
                    : navigateMin
                    ? { clickable: true }
                    : false
                }
                pagination={
                  order_cross
                    ? unvisiblemedium && paginate
                      ? { clickable: true }
                      : false
                    : !unvisiblemedium && paginate
                    ? { clickable: true }
                    : false
                }
                className={
                  i18n.language === "arabic"
                    ? "mySwiper language-swiper "
                    : "mySwiper " + swiperClass
                }
              >
                <Box
                  sx={{
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalRegular"
                        : "ArchivoBlack",
                    fontSize:
                      i18n.language === "arabic"
                        ? { xs: "18px" }
                        : { xs: "14px" },
                    right: i18n.language === "arabic" ? "0px" : "",
                    left: i18n.language === "arabic" ? "" : "0px",
                    letterSpacing:
                      i18n.language === "arabic" ? "0.00em" : "0.2em"
                  }}
                  className={
                    i18n.language === "arabic"
                      ? "home-info-card-head noUppercase lineHeightNormal font600"
                      : "home-info-card-head"
                  }
                  style={{
                    top: marginSwiperHead || "",
                    color: color_definition
                      ? color_definition?.header_text
                      : "#000000"
                  }}
                >
                  {headerControl() && head}
                </Box>
                {items.map((category) => (
                  <SwiperSlide
                    key={category.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    onClick={() => onDataLayerClickEvent(category)}
                  >
                    {typeof category.image !== "string" ? (
                      category.image
                    ) : (
                      <ActionAreaCard
                        card={category}
                        size={size}
                        order_cross={order_cross}
                        backGround={backGround}
                        home={home}
                        color_theme={color_theme}
                        show_brand_title={show_brand_title}
                        data_source={data_source}
                        show_category={show_category}
                        component_type={component_type}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
              {buttontext && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection:
                      i18n.language === "arabic" ? "row-reverse" : "row"
                  }}
                >
                  <LinkType link={pathto} link_type={link_type}>
                    {" "}
                    <ViewAllButton
                      sx={{
                        marginTop: marginSwiperHead
                          ? padngtp
                            ? "0px"
                            : { xs: "18px" }
                          : padngtp
                          ? "0px"
                          : { xs: "20px" },
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        fontSize: i18n.language === "arabic" ? "15px" : "12px"
                      }}
                      style={{
                        backgroundColor: color_definition
                          ? color_definition?.cta_button_fill
                          : "#F3F3FA",
                        color: isColor(color_definition?.cta_button_text)
                          ? color_definition?.cta_button_text
                          : "#000000",
                        border: `1px solid ${
                          isColor(color_definition?.cta_button_border)
                            ? color_definition?.cta_button_border
                            : "#000000"
                        }`
                      }}
                    >
                      {buttontext}
                    </ViewAllButton>
                  </LinkType>
                </Box>
              )}
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};
export default CarouselSubList;
