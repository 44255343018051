import { Grid, TextField } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import emailvalidate from "Assets/images/emailvalidate.png";
import WprightPage from "Components/Authanticate/WprightPage";
import HistoryButton from "Components/HistoryButton";
import { setEmail, setRouteCntrl } from "store/authanticateSlice";
import {
  usePostExistEmail,
  usePostExistEmailOtp
} from "services/auth/auth.query";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";
import { useDataLayer } from "hooks/useDataLayer";

const styleArabic = {
  fontSize: 18,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular"
};
const styleEnglish = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  textAlign: "right"
};
const styleEnglishLength = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr"
};

const Email = () => {
  const referalCode = useLocation()?.state?.referalCode;

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const path = useSelector((state) => state.path);
  const isExternal = useSelector((state) => state.authanticate.isExternal);
  const { setSignUpEvent } = useDataLayer();

  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);

  const hasPhoneTrue = useMemo(() => {
    return routeCntrl.some(
      (item) =>
        item.otp === true || item.multiple === true || item.multipleOTP === true
    );
  }, [routeCntrl]);

  const [showAlert, setShowalert] = useState(false);
  const [Mail, SetMail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMatched, setEmailErrorMatched] = useState(false);
  const [showAlertMultiAccount, setShowalertMultiAccount] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};
  const {
    mutate: postExistEmailOtp,
    error: existEmailOtpError,
    isLoading: isPostExistEmailOtpLoading
  } = usePostExistEmailOtp({
    onSuccess: (data) => {
      dispatch(setEmail(Mail));
      navigate("/sign-up/email-registration-otp", {
        state: {
          email: Mail,
          validation_key: data.validation_key,
          referalCode
        }
      });
    },
    onError: () => {
      resetExistEmailError();
    }
  });

  const {
    mutateAsync: emailExist,
    error: existEmailError,
    isLoading: isExistEmailLoading,
    reset: resetExistEmailError
  } = usePostExistEmail({
    onSuccess: () => {},
    onError: () => {}
  });

  useEffect(() => {
    navigateProtect();
  }, []);

  // navigateProtect():
  // It navigates to a phone validation page if the user does not have a phone (if hasPhoneTrue is false).
  // If the user has a phone, it sets showComponent to true and defines a new route with multiple steps (multiple forms) for OTP (One-Time Password) verification.

  // validateEmail(e):
  // This function is typically called when the user interacts with an email input field, such as during registration.
  // It removes any characters from the email that are not alphanumeric, periods, question marks, hyphens, underscores, slashes, plus signs, or spaces.
  // It sets the email state (Mail) with the cleaned email.
  // It checks if the cleaned email is valid using a validation library (likely validator.isEmail(email)).
  // If the email is valid, it sets emailError to true and hides any alert message (showAlert is set to false).
  // If the email is not valid, it sets emailError to false.

  // inputCntrl():
  // It returns a style (likely for rendering purposes) based on the current language and the length of the email.
  // If the language is "arabic" and the email length is greater than 0, it returns styleEnglish. Otherwise, it returns styleArabic.
  // In other cases (when the language is not "arabic"), it returns styleEnglishLength.

  // handleSubmit():
  // This function handles the submission of user registration data.
  // It resets error states and alerts.
  // It checks if the email exists in the system using the emailExist function. Based on the response:
  // If the email doesn't exist (email_exist is false), it sets the email in the state and navigates to the personal details page.
  // If multiple customer accounts with the same email exist, it sets showAlertMultiAccount to true.
  // In other cases (the email exists but not multiple accounts), it sends an OTP for email verification and navigates to the OTP verification page.

  // handleRoute():
  // This function is typically called when the user clicks a button or presses Enter to submit the form.
  // It checks if emailError is true (indicating a valid email) and if the Mail state is not empty.
  // If both conditions are met, it calls the handleSubmit() function to proceed with registration.
  // If the conditions are not met, it sets showAlert to true and emailErrorMatched to false.

  // handleKeyDown(event):
  // This function is called when a key event occurs in an input field.
  // If the key event is an Enter key press, it invokes the handleRoute() function.

  const navigateProtect = () => {
    if (!hasPhoneTrue) {
      navigate(`/sign-up/phone-validation`);
    } else {
      setShowComponent(true);
      const newRoute = { multiple: true, otp: true };
      dispatch(setRouteCntrl(newRoute));
    }
  };
  const validateEmail = (e) => {
    let email = e.target.value.replace(/([^a-zA-Z0-9.?-_/#+ ])/g, "");
    SetMail(email);
    if (validator.isEmail(email)) {
      setEmailError(true);
      setShowalert(false);
    } else {
      setEmailError(false);
    }
  };

  const inputCntrl = () => {
    if (i18n.language === "arabic") {
      if (Mail > 0) {
        return styleEnglish;
      } else return styleArabic;
    } else {
      return styleEnglishLength;
    }
  };
  const handleSubmit = () => {
    setEmailErrorMatched(false);
    setShowalertMultiAccount(false);
    setShowalert(false);
    emailExist({ email: Mail }).then((response) => {
      setSignUpEvent("enter_email");

      if (!response?.email_exist) {
        dispatch(setEmail(Mail));
        const newRoute = { email: true };
        dispatch(setRouteCntrl(newRoute));
        navigate(`/sign-up/personal-details`, {
          state: { email: Mail, referalCode }
        });
      } else if (response?.customer_count > 1) {
        setShowalertMultiAccount(true);
      } else {
        postExistEmailOtp({ email: Mail });
        const newRoute = { multipleOTP: true };
        dispatch(setRouteCntrl(newRoute));
      }
    });
  };

  const handleRoute = () => {
    if (emailError && Mail) {
      if (Mail.length > 0) {
        handleSubmit();
      }
    } else {
      setShowalert(true);
      setEmailErrorMatched(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRoute();
    }
  };

  const isLoading = isExistEmailLoading || isPostExistEmailOtpLoading;
  const error = (existEmailOtpError || existEmailError)?.response?.data
    ?.message;

  return (
    <>
      {showComponent ? (
        <div className="WPmain">
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: background ? background : "#FFFFFF"
            }}
          >
            <HistoryButton
              referalCode={referalCode}
              image="return"
              Left={i18n.language === "arabic" ? "auto" : 25}
              Top={25}
              Right={i18n.language === "arabic" ? 25 : "auto"}
              path={`/sign-up/phone-verification`}
            />
            {!isExternal && (
              <HistoryButton
                referalCode={referalCode}
                Right={i18n.language === "arabic" ? "auto" : 25}
                Top={25}
                Left={i18n.language === "arabic" ? 25 : "auto"}
                path={path}
              />
            )}
            <div className="left-page-form-pn">
              <div className="left-page-form-subdiv">
                <div>
                  <div
                    className={
                      i18n.language === "arabic"
                        ? "login-head-pn-arabic"
                        : "login-head-pn"
                    }
                  >
                    <span>{t("Add your email address")}</span>
                  </div>
                  <div
                    className={
                      i18n.language === "arabic"
                        ? "subtext-pn-arabic"
                        : "subtext-pn"
                    }
                    style={{
                      lineHeight: "25px",
                      maxWidth: "100%",
                      paddingLeft: "0px"
                    }}
                  >
                    <div>
                      {t("We use your email address to secure your account")}
                    </div>
                  </div>

                  <div>
                    {showAlert &&
                      (Mail ? (
                        <AlertWarning
                          marginbottom="24px"
                          margintop="-16px"
                          text={t("Your email address is not valid")}
                        />
                      ) : (
                        <AlertWarning
                          marginbottom="24px"
                          margintop="-16px"
                          text={t("Your email cannot be blank")}
                        />
                      ))}
                    {emailErrorMatched && (
                      <AlertWarning
                        marginbottom="24px"
                        margintop="-16px"
                        text={t(
                          "This email is registered. Please enter another an email"
                        )}
                      />
                    )}
                    {error && (
                      <AlertWarning
                        marginbottom="24px"
                        margintop="-16px"
                        text={t(error)}
                      />
                    )}
                    {showAlertMultiAccount && (
                      <AlertWarning
                        marginbottom="24px"
                        margintop="-16px"
                        text={t(
                          "Your email is associated with more than one account. Please contact Amber Assist"
                        )}
                      />
                    )}
                    <div
                      className={
                        i18n.language === "arabic"
                          ? "phone-text-arabic"
                          : "phone-text"
                      }
                      style={{ marginBottom: "8px" }}
                    >
                      {t("Email address")}
                    </div>
                    <Grid container spacing={0} className="textfont">
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <TextField
                          autoComplete="off"
                          onChange={validateEmail}
                          value={Mail}
                          placeholder={t("Your email address")}
                          onKeyUp={handleKeyDown}
                          sx={{
                            width: "100% !important",
                            input: {
                              "&::placeholder": {
                                color: "#90969E",
                                opacity: "1"
                              }
                            }
                          }}
                          inputProps={{
                            style: inputCntrl(),
                            autoCapitalize: "none"
                          }}
                        />
                        {emailError && Mail ? (
                          <img
                            style={{
                              position: "absolute",
                              right:
                                i18n.language === "arabic" ? "auto" : "34.85px",
                              left:
                                i18n.language === "arabic" ? "34.85px" : "auto"
                            }}
                            src={emailvalidate}
                            alt="emailvalidate"
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="btnbox-pn">
                  {" "}
                  {isLoading ? (
                    <ColorLoadingButton
                      size="small"
                      loading={true}
                      variant="outlined"
                      disabled
                      type="submit"
                      className="button-submit-loading"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        maxWidth: "100%"
                      }}
                    />
                  ) : (
                    <ColorButton
                      variant="contained"
                      onClick={handleRoute}
                      className="button-submit-pn"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        marginTop: "36px"
                      }}
                    >
                      {t("Continue")}
                    </ColorButton>
                  )}
                </div>
              </div>
            </div>
          </div>
          <WprightPage />
        </div>
      ) : null}
    </>
  );
};

export default Email;
