import * as Yup from "yup";

export const countries = [
  {
    code: "AE",
    label: "UAE",
    phone: "971",
    color: "color-5",
    countryCode: "101",
    image:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABLSURBVHgB7ZKxDQAgDMNSxD9MvMEN5Qb+F/ABlmBgqNXR8pLalPadsVGIpqTHRPCeTEWvDXlG30aTabHyh0G8cncnGg+aGLHyh8EFV7MMxQqKD/0AAAAASUVORK5CYII="
  },
  {
    code: "KW",
    label: "Kuwait",
    phone: "965",
    color: "color-2",
    countryCode: "106",
    image:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEGSURBVHgB7ZS/SsNAHIC/S07Ti0VLWkVF20EQpFVwFYsBBUc3FXUR7SK4+AJ2Ejv7UI6+hm+hF9L/fy/JdesHIUN+fPlI7k5wd9SksopNhL7+qJehXsEWsTSikIPrKpR8stKTdjjZhTNdLQRpGZVGBApua3G9NWmEox+dluNyJ1n1ZGmHNV17f5ioera0Tfh6Q3h1aTKKxJCweMD7+aPRrMMcWEjtY/T3c3rLbnz/8Nv6MhmfvU53nCU+81tUXfPFL6e97UWVePAKujTZVxor3Xc9PlY22dP3NMjhuoYq0vACZIajrys9loo3f52am+64G5BK3fesAp4y1vUjWv5282I5j03+AcSRHIJ8EXPKAAAAAElFTkSuQmCC"
  },
  {
    code: "BH",
    label: "Bahrain",
    phone: "973",
    color: "color-1",
    countryCode: "104",
    image:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFfSURBVHgBpZRNSwJRGIXPlAgtWrkN+hXt+hlFK/sDbYO2QZugTZBCLhQMQookUzFLEkMLdNmilaH5kabmOM0HOunYTIuQaO59xWd95mHmnLlXGJuAiKFq+AhdorazB9g85qCItKdn9KI36PhPMZJkZpYplLMFNA88UHJ5UGEKF1dXMGxtQHDMQ848goJA7VB/raHtO0E3co1hs2WbmwMR5/ISXJvrcLnXmDn+KOYHSLcZtDwBKA8Fbpw9ijlGyb3FXXYSbod6pY53bwBS4g5f9QZmFk4inscgRhKQkmnbDHmUsWFAWHBipKrMHHeUfrEEMRxH+zhI6pIptHqrbO8y/7u/kDrshiLohmOQ0zledLpRBi9lNA996F0lYSjavxnSbWPRL5YhXkQhxVO2Mq7Quv/kXME8JX7SKeEKlWweneAZWWZB6nBQrv685WfqHnr1bXbhL2a0sX9kXrpe28g3s8rACILjRV4AAAAASUVORK5CYII="
  },
  {
    code: "QA",
    label: "Qatar",
    phone: "974",
    color: "color-3",
    countryCode: "105",
    image:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGCSURBVHgBrZPLSwJhFMWPvbNNEO6D2tQ/atuooFxEJGJEWZBupAeRDzRyRE1T8W2KI+roOE9ffQ60c2waPevznfnde88YRkT4QzLHoxb+QtzhRv0zjWoorupdggatbBhh2t/FdqsDDEdgChUIDUZ/oNTuInzhQP41iJKPmuo1aBn5V2y5hvi9G4FDK/kIC92EInnsObAgduVCjxMwN8K+ICJid8JjtsxO+H5iQ+LWjXapirkRlnwhFL0hfJxdQ+rMQMiTimTcHmQffSh5KdUwzYHGrU2Y9nYwGgwhNNuoRZKQWW6idwEatbi6ArnLYXl9DQaD+jNNgVy9CTqaIvujUE9kpo6s+SgDuQc6lkLuOYDgsRU9Xpzo07RDmeXxZj4lR/GDyZenev9VG4kd/9N3iNqcaGWL0E3YypURs7tAnd+Q/XUxN0Km8K2M7SHjSx1OP2FfkBA4ukT+Jagcpi/JmJmQrdAkLI3kw5MSzNGNiT5NPRTJ3sb9K/sp0se00ks1/QCTkfaE5M+IFgAAAABJRU5ErkJggg=="
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
    color: "color-4",
    countryCode: "216",
    image:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFmSURBVHgBrZI7T8JQFMf/LaWUthRseVhSCIlRSVzUxDiwODi5+REc3Px6DsZEF2PUOKjxQRgQYlRQLA+LIAHrbSeKrTGxv+TmJuee87/nRWFn5RA+wpCzBh9h3IxL2hwS4hTOqrdQJQWRsIC4EIXECSjWqqBpGgFytFgS5cYTLh9L3oI5RcV2YRP3xFEMhdHudZGVp5GOJrB7fYT+cID5VBb1jo6ZuIbhaOQQpCcF9W4Hx+UrKGIU6/lVpGMJImrYGSqCBIWXoEbieOu27Q+5IOuI/yHIBoJ4NZpoGC3s3Z3YNkv8plZGhBNJqQFy89hYKGC/eAo+yDniKTJlc9zAsxySpH8Wrd47ljN5HJTOsajNovVhgKIomKaJiv6MnKwSH8P28xT8LzR8hvF6sMrJyWnXt4ycxIP+ggsy3fFyLX4tOcSwds8msXYwE0uhN+ij2qz9LUOLT7JzXhTrFVc7g6/RFnzkGw11b2VuA/0XAAAAAElFTkSuQmCC"
  },
  {
    label: "Oman",
    phone: "968",
    code: "OM",
    countryCode: "103",
    color: "color-6",
    image: `${window.origin}/images/CountryCodes/oman.png`
  }
];

export const countryMap = new Map();

countries.forEach((country) => {
  countryMap.set(country.code, country.countryCode);
});

export const countriesSvg = [
  {
    code: "AE",
    label: "UAE",
    phone: "971",
    color: "color-5",
    countryCode: "101",
    image: `${window.origin}/images/countrysvg/UAE.svg`
  },
  {
    code: "KW",
    label: "Kuwait",
    phone: "965",
    color: "color-2",
    countryCode: "106",
    image: `${window.origin}/images/countrysvg/Kuwait.svg`
  },
  {
    code: "BH",
    label: "Bahrain",
    phone: "973",
    color: "color-1",
    countryCode: "104",
    image: `${window.origin}/images/countrysvg/Bahrain.svg`
  },
  {
    code: "QA",
    label: "Qatar",
    phone: "974",
    color: "color-3",
    countryCode: "105",
    image: `${window.origin}/images/countrysvg/Qatar.svg`
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
    color: "color-4",
    countryCode: "216",
    image: `${window.origin}/images/countrysvg/Saudi.svg`
  },
  {
    label: "Oman",
    phone: "968",
    code: "OM",
    color: "color-6",
    countryCode: "103",
    image: `${window.origin}/images/CountryCodes/oman.png`
  }
];
export const countries_radius = [
  {
    name: "United Arab Emirates",
    country_code: "971",
    iso_code: "AE",
    flag: `${window.origin}/images/CountryCodes/united_arab_emirates.png`
  },
  {
    name: "Kuwait",
    country_code: "965",
    iso_code: "KW",
    flag: `${window.origin}/images/CountryCodes/kuwait.png`
  },
  {
    name: "Saudi Arabia",
    country_code: "966",
    iso_code: "SA",
    flag: `${window.origin}/images/CountryCodes/saudi_arabia.png`
  },
  {
    name: "Qatar",
    country_code: "974",
    iso_code: "QA",
    flag: `${window.origin}/images/CountryCodes/qatar.png`
  },
  {
    name: "Bahrain",
    country_code: "973",
    iso_code: "BH",
    flag: `${window.origin}/images/CountryCodes/bahrain.png`
  },
  {
    name: "Afghanistan",
    country_code: "93",
    iso_code: "AF",
    flag: `${window.origin}/images/CountryCodes/afghanistan.png`
  },
  {
    name: "Aland Islands",
    country_code: "358",
    iso_code: "AX",
    flag: `${window.origin}/images/CountryCodes/aland_islands.png`
  },
  {
    name: "Albania",
    country_code: "355",
    iso_code: "AL",
    flag: `${window.origin}/images/CountryCodes/albania.png`
  },
  {
    name: "Algeria",
    country_code: "213",
    iso_code: "DZ",
    flag: `${window.origin}/images/CountryCodes/algeria.png`
  },
  {
    name: "American Samoa",
    country_code: "1684",
    iso_code: "AS",
    flag: `${window.origin}/images/CountryCodes/american_samoa.png`
  },
  {
    name: "Andorra",
    country_code: "376",
    iso_code: "AD",
    flag: `${window.origin}/images/CountryCodes/andorra.png`
  },
  {
    name: "Angola",
    country_code: "244",
    iso_code: "AO",
    flag: `${window.origin}/images/CountryCodes/angola.png`
  },
  {
    name: "Anguilla",
    country_code: "1264",
    iso_code: "AI",
    flag: `${window.origin}/images/CountryCodes/anguilla.png`
  },
  {
    name: "Antarctica",
    country_code: "672",
    iso_code: "AQ",
    flag: `${window.origin}/images/CountryCodes/antarctica.png`
  },
  {
    name: "Antigua and Barbuda",
    country_code: "1268",
    iso_code: "AG",
    flag: `${window.origin}/images/CountryCodes/antigua_and_barbuda.png`
  },
  {
    name: "Argentina",
    country_code: "54",
    iso_code: "AR",
    flag: `${window.origin}/images/CountryCodes/argentina.png`
  },
  {
    name: "Armenia",
    country_code: "374",
    iso_code: "AM",
    flag: `${window.origin}/images/CountryCodes/armenia.png`
  },
  {
    name: "Aruba",
    country_code: "297",
    iso_code: "AW",
    flag: `${window.origin}/images/CountryCodes/aruba.png`
  },
  {
    name: "Ascension Island",
    country_code: "247",
    iso_code: "AC",
    flag: `${window.origin}/images/CountryCodes/ascension_island.png`
  },
  {
    name: "Australia",
    country_code: "61",
    iso_code: "AU",
    flag: `${window.origin}/images/CountryCodes/australia.png`
  },
  {
    name: "Austria",
    country_code: "43",
    iso_code: "AT",
    flag: `${window.origin}/images/CountryCodes/austria.png`
  },
  {
    name: "Azerbaijan",
    country_code: "994",
    iso_code: "AZ",
    flag: `${window.origin}/images/CountryCodes/azerbaijan.png`
  },
  {
    name: "Bahamas",
    country_code: "1242",
    iso_code: "BS",
    flag: `${window.origin}/images/CountryCodes/bahamas.png`
  },
  {
    name: "Bangladesh",
    country_code: "880",
    iso_code: "BD",
    flag: `${window.origin}/images/CountryCodes/bangladesh.png`
  },
  {
    name: "Barbados",
    country_code: "1246",
    iso_code: "BB",
    flag: `${window.origin}/images/CountryCodes/barbados.png`
  },
  {
    name: "Belarus",
    country_code: "375",
    iso_code: "BY",
    flag: `${window.origin}/images/CountryCodes/belarus.png`
  },
  {
    name: "Belgium",
    country_code: "32",
    iso_code: "BE",
    flag: `${window.origin}/images/CountryCodes/belgium.png`
  },
  {
    name: "Belize",
    country_code: "501",
    iso_code: "BZ",
    flag: `${window.origin}/images/CountryCodes/belize.png`
  },
  {
    name: "Benin",
    country_code: "229",
    iso_code: "BJ",
    flag: `${window.origin}/images/CountryCodes/benin.png`
  },
  {
    name: "Bermuda",
    country_code: "1441",
    iso_code: "BM",
    flag: `${window.origin}/images/CountryCodes/bermuda.png`
  },
  {
    name: "Bhutan",
    country_code: "975",
    iso_code: "BT",
    flag: `${window.origin}/images/CountryCodes/bhutan.png`
  },
  {
    name: "Bolivia",
    country_code: "591",
    iso_code: "BO",
    flag: `${window.origin}/images/CountryCodes/bolivia.png`
  },
  {
    name: "Bosnia and Herzegovina",
    country_code: "387",
    iso_code: "BA",
    flag: `${window.origin}/images/CountryCodes/bosnia_and_herzegovina.png`
  },
  {
    name: "Botswana",
    country_code: "267",
    iso_code: "BW",
    flag: `${window.origin}/images/CountryCodes/botswana.png`
  },
  {
    name: "Brazil",
    country_code: "55",
    iso_code: "BR",
    flag: `${window.origin}/images/CountryCodes/brazil.png`
  },
  {
    name: "British Indian Ocean Territory",
    country_code: "246",
    iso_code: "IO",
    flag: `${window.origin}/images/CountryCodes/british_indian_ocean_territory.png`
  },
  {
    name: "Brunei Darussalam",
    country_code: "673",
    iso_code: "BN",
    flag: `${window.origin}/images/CountryCodes/brunei.png`
  },
  {
    name: "Bulgaria",
    country_code: "359",
    iso_code: "BG",
    flag: `${window.origin}/images/CountryCodes/bulgaria.png`
  },
  {
    name: "Burkina Faso",
    country_code: "226",
    iso_code: "BF",
    flag: `${window.origin}/images/CountryCodes/burkina_faso.png`
  },
  {
    name: "Burundi",
    country_code: "257",
    iso_code: "BI",
    flag: `${window.origin}/images/CountryCodes/burundi.png`
  },
  {
    name: "Cambodia",
    country_code: "855",
    iso_code: "KH",
    flag: `${window.origin}/images/CountryCodes/cambodia.png`
  },
  {
    name: "Cameroon",
    country_code: "237",
    iso_code: "CM",
    flag: `${window.origin}/images/CountryCodes/cameroon.png`
  },
  {
    name: "Canada",
    country_code: "1",
    iso_code: "CA",
    flag: `${window.origin}/images/CountryCodes/canada.png`
  },
  {
    name: "Cape Verde",
    country_code: "238",
    iso_code: "CV",
    flag: `${window.origin}/images/CountryCodes/cape_verde.png`
  },
  {
    name: "Cayman Islands",
    country_code: "1345",
    iso_code: "KY",
    flag: `${window.origin}/images/CountryCodes/cayman_islands.png`
  },
  {
    name: "Central African Republic",
    country_code: "236",
    iso_code: "CF",
    flag: `${window.origin}/images/CountryCodes/central_african_republic.png`
  },
  {
    name: "Chad",
    country_code: "235",
    iso_code: "TD",
    flag: `${window.origin}/images/CountryCodes/chad.png`
  },
  {
    name: "Chile",
    country_code: "56",
    iso_code: "CL",
    flag: `${window.origin}/images/CountryCodes/chile.png`
  },
  {
    name: "China",
    country_code: "86",
    iso_code: "CN",
    flag: `${window.origin}/images/CountryCodes/china.png`
  },
  {
    name: "Christmas Island",
    country_code: "61",
    iso_code: "CX",
    flag: `${window.origin}/images/CountryCodes/christmas_island.png`
  },
  {
    name: "Cocos (Keeling) Islands",
    country_code: "61",
    iso_code: "CC",
    flag: `${window.origin}/images/CountryCodes/cocos_keeling_islands.png`
  },
  {
    name: "Colombia",
    country_code: "57",
    iso_code: "CO",
    flag: `${window.origin}/images/CountryCodes/colombia.png`
  },
  {
    name: "Comoros",
    country_code: "269",
    iso_code: "KM",
    flag: `${window.origin}/images/CountryCodes/comoros.png`
  },
  {
    name: "Congo",
    country_code: "242",
    iso_code: "CG",
    flag: `${window.origin}/images/CountryCodes/congo.png`
  },
  {
    name: "Cook Islands",
    country_code: "682",
    iso_code: "CK",
    flag: `${window.origin}/images/CountryCodes/cook_islands.png`
  },
  {
    name: "Costa Rica",
    country_code: "506",
    iso_code: "CR",
    flag: `${window.origin}/images/CountryCodes/costa_rica.png`
  },
  {
    name: "Croatia",
    country_code: "385",
    iso_code: "HR",
    flag: `${window.origin}/images/CountryCodes/croatia.png`
  },
  {
    name: "Cuba",
    country_code: "53",
    iso_code: "CU",
    flag: `${window.origin}/images/CountryCodes/cuba.png`
  },
  {
    name: "Cyprus",
    country_code: "357",
    iso_code: "CY",
    flag: `${window.origin}/images/CountryCodes/cyprus.png`
  },
  {
    name: "Czech Republic",
    country_code: "420",
    iso_code: "CZ",
    flag: `${window.origin}/images/CountryCodes/czech_republic.png`
  },
  {
    name: "Democratic Republic of the Congo",
    country_code: "243",
    iso_code: "CD",
    flag: `${window.origin}/images/CountryCodes/democratic_republic_of_the_congo.png`
  },
  {
    name: "Denmark",
    country_code: "45",
    iso_code: "DK",
    flag: `${window.origin}/images/CountryCodes/denmark.png`
  },
  {
    name: "Djibouti",
    country_code: "253",
    iso_code: "DJ",
    flag: `${window.origin}/images/CountryCodes/djibouti.png`
  },
  {
    name: "Dominica",
    country_code: "1767",
    iso_code: "DM",
    flag: `${window.origin}/images/CountryCodes/dominica.png`
  },
  {
    name: "Dominican Republic",
    country_code: "1849",
    iso_code: "DO",
    flag: `${window.origin}/images/CountryCodes/dominican_republic.png`
  },
  {
    name: "Ecuador",
    country_code: "593",
    iso_code: "EC",
    flag: `${window.origin}/images/CountryCodes/ecuador.png`
  },
  {
    name: "Egypt",
    country_code: "20",
    iso_code: "EG",
    flag: `${window.origin}/images/CountryCodes/egypt.png`
  },
  {
    name: "El Salvador",
    country_code: "503",
    iso_code: "SV",
    flag: `${window.origin}/images/CountryCodes/el_salvador.png`
  },
  {
    name: "Equatorial Guinea",
    country_code: "240",
    iso_code: "GQ",
    flag: `${window.origin}/images/CountryCodes/equatorial_guinea.png`
  },
  {
    name: "Eritrea",
    country_code: "291",
    iso_code: "ER",
    flag: `${window.origin}/images/CountryCodes/eritrea.png`
  },
  {
    name: "Estonia",
    country_code: "372",
    iso_code: "EE",
    flag: `${window.origin}/images/CountryCodes/estonia.png`
  },
  {
    name: "Eswatini",
    country_code: "268",
    iso_code: "SZ",
    flag: `${window.origin}/images/CountryCodes/eswatini.png`
  },
  {
    name: "Ethiopia",
    country_code: "251",
    iso_code: "ET",
    flag: `${window.origin}/images/CountryCodes/ethiopia.png`
  },
  {
    name: "Falkland Islands (Malvinas)",
    country_code: "500",
    iso_code: "FK",
    flag: `${window.origin}/images/CountryCodes/falkland_islands_malvinas.png`
  },
  {
    name: "Faroe Islands",
    country_code: "298",
    iso_code: "FO",
    flag: `${window.origin}/images/CountryCodes/faroe_islands.png`
  },
  {
    name: "Fiji",
    country_code: "679",
    iso_code: "FJ",
    flag: `${window.origin}/images/CountryCodes/fiji.png`
  },
  {
    name: "Finland",
    country_code: "358",
    iso_code: "FI",
    flag: `${window.origin}/images/CountryCodes/finland.png`
  },
  {
    name: "France",
    country_code: "33",
    iso_code: "FR",
    flag: `${window.origin}/images/CountryCodes/france.png`
  },
  {
    name: "French Guiana",
    country_code: "594",
    iso_code: "GF",
    flag: `${window.origin}/images/CountryCodes/french_guiana.png`
  },
  {
    name: "French Polynesia",
    country_code: "689",
    iso_code: "PF",
    flag: `${window.origin}/images/CountryCodes/french_polynesia.png`
  },
  {
    name: "Gabon",
    country_code: "241",
    iso_code: "GA",
    flag: `${window.origin}/images/CountryCodes/gabon.png`
  },
  {
    name: "Gambia",
    country_code: "220",
    iso_code: "GM",
    flag: `${window.origin}/images/CountryCodes/gambia.png`
  },
  {
    name: "Georgia",
    country_code: "995",
    iso_code: "GE",
    flag: `${window.origin}/images/CountryCodes/georgia.png`
  },
  {
    name: "Germany",
    country_code: "49",
    iso_code: "DE",
    flag: `${window.origin}/images/CountryCodes/germany.png`
  },
  {
    name: "Ghana",
    country_code: "233",
    iso_code: "GH",
    flag: `${window.origin}/images/CountryCodes/ghana.png`
  },
  {
    name: "Greece",
    country_code: "30",
    iso_code: "GR",
    flag: `${window.origin}/images/CountryCodes/greece.png`
  },
  {
    name: "Greenland",
    country_code: "299",
    iso_code: "GL",
    flag: `${window.origin}/images/CountryCodes/greenland.png`
  },
  {
    name: "Grenada",
    country_code: "1473",
    iso_code: "GD",
    flag: `${window.origin}/images/CountryCodes/grenada.png`
  },
  {
    name: "Guadeloupe",
    country_code: "590",
    iso_code: "GP",
    flag: `${window.origin}/images/CountryCodes/guadeloupe.png`
  },
  {
    name: "Guam",
    country_code: "1671",
    iso_code: "GU",
    flag: `${window.origin}/images/CountryCodes/guam.png`
  },
  {
    name: "Guatemala",
    country_code: "502",
    iso_code: "GT",
    flag: `${window.origin}/images/CountryCodes/guatemala.png`
  },
  {
    name: "Guinea",
    country_code: "224",
    iso_code: "GN",
    flag: `${window.origin}/images/CountryCodes/guinea.png`
  },
  {
    name: "Guinea-Bissau",
    country_code: "245",
    iso_code: "GW",
    flag: `${window.origin}/images/CountryCodes/guinea_bissau.png`
  },
  {
    name: "Guyana",
    country_code: "592",
    iso_code: "GY",
    flag: `${window.origin}/images/CountryCodes/guyana.png`
  },
  {
    name: "Haiti",
    country_code: "509",
    iso_code: "HT",
    flag: `${window.origin}/images/CountryCodes/haiti.png`
  },
  {
    name: "Honduras",
    country_code: "504",
    iso_code: "HN",
    flag: `${window.origin}/images/CountryCodes/honduras.png`
  },
  {
    name: "Hong Kong",
    country_code: "852",
    iso_code: "HK",
    flag: `${window.origin}/images/CountryCodes/hong_kong.png`
  },
  {
    name: "Hungary",
    country_code: "36",
    iso_code: "HU",
    flag: `${window.origin}/images/CountryCodes/hungary.png`
  },
  {
    name: "Iceland",
    country_code: "354",
    iso_code: "IS",
    flag: `${window.origin}/images/CountryCodes/iceland.png`
  },
  {
    name: "India",
    country_code: "91",
    iso_code: "IN",
    flag: `${window.origin}/images/CountryCodes/india.png`
  },
  {
    name: "Indonesia",
    country_code: "62",
    iso_code: "ID",
    flag: `${window.origin}/images/CountryCodes/indonesia.png`
  },
  {
    name: "Kazakhstan",
    country_code: "7",
    iso_code: "KZ",
    flag: `${window.origin}/images/CountryCodes/kazakhstan.png`
  },
  {
    name: "Kenya",
    country_code: "254",
    iso_code: "KE",
    flag: `${window.origin}/images/CountryCodes/kenya.png`
  },
  {
    name: "Kiribati",
    country_code: "686",
    iso_code: "KI",
    flag: `${window.origin}/images/CountryCodes/kiribati.png`
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    country_code: "850",
    iso_code: "KP",
    flag: `${window.origin}/images/CountryCodes/north_korea.png`
  },
  {
    name: "Korea, Republic of South Korea",
    country_code: "82",
    iso_code: "KR",
    flag: `${window.origin}/images/CountryCodes/south_korea.png`
  },
  {
    name: "Kosovo",
    country_code: "383",
    iso_code: "XK",
    flag: `${window.origin}/images/CountryCodes/kosovo.png`
  },
  {
    name: "Kyrgyzstan",
    country_code: "996",
    iso_code: "KG",
    flag: `${window.origin}/images/CountryCodes/kyrgyzstan.png`
  },
  {
    name: "Laos",
    country_code: "856",
    iso_code: "LA",
    flag: `${window.origin}/images/CountryCodes/laos.png`
  },
  {
    name: "Latvia",
    country_code: "371",
    iso_code: "LV",
    flag: `${window.origin}/images/CountryCodes/latvia.png`
  },
  {
    name: "Lebanon",
    country_code: "961",
    iso_code: "LB",
    flag: `${window.origin}/images/CountryCodes/lebanon.png`
  },
  {
    name: "Lesotho",
    country_code: "266",
    iso_code: "LS",
    flag: `${window.origin}/images/CountryCodes/lesotho.png`
  },
  {
    name: "Liberia",
    country_code: "231",
    iso_code: "LR",
    flag: `${window.origin}/images/CountryCodes/liberia.png`
  },
  {
    name: "Libya",
    country_code: "218",
    iso_code: "LY",
    flag: `${window.origin}/images/CountryCodes/libya.png`
  },
  {
    name: "Liechtenstein",
    country_code: "423",
    iso_code: "LI",
    flag: `${window.origin}/images/CountryCodes/liechtenstein.png`
  },
  {
    name: "Lithuania",
    country_code: "370",
    iso_code: "LT",
    flag: `${window.origin}/images/CountryCodes/lithuania.png`
  },
  {
    name: "Luxembourg",
    country_code: "352",
    iso_code: "LU",
    flag: `${window.origin}/images/CountryCodes/luxembourg.png`
  },
  {
    name: "Macau",
    country_code: "853",
    iso_code: "MO",
    flag: `${window.origin}/images/CountryCodes/macau.png`
  },
  {
    name: "Madagascar",
    country_code: "261",
    iso_code: "MG",
    flag: `${window.origin}/images/CountryCodes/madagascar.png`
  },
  {
    name: "Malawi",
    country_code: "265",
    iso_code: "MW",
    flag: `${window.origin}/images/CountryCodes/malawi.png`
  },
  {
    name: "Malaysia",
    country_code: "60",
    iso_code: "MY",
    flag: `${window.origin}/images/CountryCodes/malaysia.png`
  },
  {
    name: "Maldives",
    country_code: "960",
    iso_code: "MV",
    flag: `${window.origin}/images/CountryCodes/maldives.png`
  },
  {
    name: "Mali",
    country_code: "223",
    iso_code: "ML",
    flag: `${window.origin}/images/CountryCodes/mali.png`
  },
  {
    name: "Malta",
    country_code: "356",
    iso_code: "MT",
    flag: `${window.origin}/images/CountryCodes/malta.png`
  },
  {
    name: "Marshall Islands",
    country_code: "692",
    iso_code: "MH",
    flag: `${window.origin}/images/CountryCodes/marshall_island.png`
  },
  {
    name: "Martinique",
    country_code: "596",
    iso_code: "MQ",
    flag: `${window.origin}/images/CountryCodes/martinique.png`
  },
  {
    name: "Mauritania",
    country_code: "222",
    iso_code: "MR",
    flag: `${window.origin}/images/CountryCodes/mauritania.png`
  },
  {
    name: "Mauritius",
    country_code: "230",
    iso_code: "MU",
    flag: `${window.origin}/images/CountryCodes/mauritius.png`
  },
  {
    name: "Mayotte",
    country_code: "262",
    iso_code: "YT",
    flag: `${window.origin}/images/CountryCodes/mayotte.png`
  },
  {
    name: "Mexico",
    country_code: "52",
    iso_code: "MX",
    flag: `${window.origin}/images/CountryCodes/mexico.png`
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    country_code: "691",
    iso_code: "FM",
    flag: `${window.origin}/images/CountryCodes/micronesia.png`
  },
  {
    name: "Moldova",
    country_code: "373",
    iso_code: "MD",
    flag: `${window.origin}/images/CountryCodes/moldova.png`
  },
  {
    name: "Monaco",
    country_code: "377",
    iso_code: "MC",
    flag: `${window.origin}/images/CountryCodes/monaco.png`
  },
  {
    name: "Mongolia",
    country_code: "976",
    iso_code: "MN",
    flag: `${window.origin}/images/CountryCodes/mongolia.png`
  },
  {
    name: "Montenegro",
    country_code: "382",
    iso_code: "ME",
    flag: `${window.origin}/images/CountryCodes/montenegro.png`
  },
  {
    name: "Montserrat",
    country_code: "1664",
    iso_code: "MS",
    flag: `${window.origin}/images/CountryCodes/montserrat.png`
  },
  {
    name: "Morocco",
    country_code: "212",
    iso_code: "MA",
    flag: `${window.origin}/images/CountryCodes/morocco.png`
  },
  {
    name: "Mozambique",
    country_code: "258",
    iso_code: "MZ",
    flag: `${window.origin}/images/CountryCodes/mozambique.png`
  },
  {
    name: "Myanmar",
    country_code: "95",
    iso_code: "MM",
    flag: `${window.origin}/images/CountryCodes/myanmar.png`
  },
  {
    name: "Namibia",
    country_code: "264",
    iso_code: "NA",
    flag: `${window.origin}/images/CountryCodes/namibia.png`
  },
  {
    name: "Nauru",
    country_code: "674",
    iso_code: "NR",
    flag: `${window.origin}/images/CountryCodes/nauru.png`
  },
  {
    name: "Nepal",
    country_code: "977",
    iso_code: "NP",
    flag: `${window.origin}/images/CountryCodes/nepal.png`
  },
  {
    name: "Netherlands",
    country_code: "31",
    iso_code: "NL",
    flag: `${window.origin}/images/CountryCodes/netherlands.png`
  },
  {
    name: "Netherlands Antilles",
    country_code: "599",
    iso_code: "AN",
    flag: `${window.origin}/images/CountryCodes/netherlands_antilles.png`
  },
  {
    name: "New Caledonia",
    country_code: "687",
    iso_code: "NC",
    flag: `${window.origin}/images/CountryCodes/new_caledonia.png`
  },
  {
    name: "New Zealand",
    country_code: "64",
    iso_code: "NZ",
    flag: `${window.origin}/images/CountryCodes/new_zealand.png`
  },
  {
    name: "Nicaragua",
    country_code: "505",
    iso_code: "NI",
    flag: `${window.origin}/images/CountryCodes/nicaragua.png`
  },
  {
    name: "Niger",
    country_code: "227",
    iso_code: "NE",
    flag: `${window.origin}/images/CountryCodes/niger.png`
  },
  {
    name: "Nigeria",
    country_code: "234",
    iso_code: "NG",
    flag: `${window.origin}/images/CountryCodes/nigeria.png`
  },
  {
    name: "Niue",
    country_code: "683",
    iso_code: "NU",
    flag: `${window.origin}/images/CountryCodes/niue.png`
  },
  {
    name: "Norfolk Island",
    country_code: "672",
    iso_code: "NF",
    flag: `${window.origin}/images/CountryCodes/norfolk_island.png`
  },
  {
    name: "North Macedonia",
    country_code: "389",
    iso_code: "MK",
    flag: `${window.origin}/images/CountryCodes/north_macedonia.png`
  },
  {
    name: "Northern Mariana Islands",
    country_code: "1670",
    iso_code: "MP",
    flag: `${window.origin}/images/CountryCodes/northern_mariana_islands.png`
  },
  {
    name: "Norway",
    country_code: "47",
    iso_code: "NO",
    flag: `${window.origin}/images/CountryCodes/norway.png`
  },
  {
    name: "Oman",
    country_code: "968",
    iso_code: "OM",
    flag: `${window.origin}/images/CountryCodes/oman.png`
  },
  {
    name: "Pakistan",
    country_code: "92",
    iso_code: "PK",
    flag: `${window.origin}/images/CountryCodes/pakistan.png`
  },
  {
    name: "Palau",
    country_code: "680",
    iso_code: "PW",
    flag: `${window.origin}/images/CountryCodes/palau.png`
  },
  {
    name: "Palestine",
    country_code: "970",
    iso_code: "PS",
    flag: `${window.origin}/images/CountryCodes/palestine.png`
  },
  {
    name: "Panama",
    country_code: "507",
    iso_code: "PA",
    flag: `${window.origin}/images/CountryCodes/panama.png`
  },
  {
    name: "Papua New Guinea",
    country_code: "675",
    iso_code: "PG",
    flag: `${window.origin}/images/CountryCodes/papua_new_guinea.png`
  },
  {
    name: "Paraguay",
    country_code: "595",
    iso_code: "PY",
    flag: `${window.origin}/images/CountryCodes/paraguay.png`
  },
  {
    name: "Peru",
    country_code: "51",
    iso_code: "PE",
    flag: `${window.origin}/images/CountryCodes/peru.png`
  },
  {
    name: "Philippines",
    country_code: "63",
    iso_code: "PH",
    flag: `${window.origin}/images/CountryCodes/philippines.png`
  },
  {
    name: "Pitcairn",
    country_code: "872",
    iso_code: "PN",
    flag: `${window.origin}/images/CountryCodes/pitcairn.png`
  },
  {
    name: "Poland",
    country_code: "48",
    iso_code: "PL",
    flag: `${window.origin}/images/CountryCodes/poland.png`
  },
  {
    name: "Portugal",
    country_code: "351",
    iso_code: "PT",
    flag: `${window.origin}/images/CountryCodes/portugal.png`
  },
  {
    name: "Puerto Rico",
    country_code: "1939",
    iso_code: "PR",
    flag: `${window.origin}/images/CountryCodes/puerto_rico.png`
  },
  {
    name: "Reunion",
    country_code: "262",
    iso_code: "RE",
    flag: `${window.origin}/images/CountryCodes/reunion.png`
  },
  {
    name: "Romania",
    country_code: "40",
    iso_code: "RO",
    flag: `${window.origin}/images/CountryCodes/romania.png`
  },
  {
    name: "Russia",
    country_code: "7",
    iso_code: "RU",
    flag: `${window.origin}/images/CountryCodes/russia.png`
  },
  {
    name: "Rwanda",
    country_code: "250",
    iso_code: "RW",
    flag: `${window.origin}/images/CountryCodes/rwanda.png`
  },
  {
    name: "Saint Barthelemy",
    country_code: "590",
    iso_code: "BL",
    flag: `${window.origin}/images/CountryCodes/saint_barthelemy.png`
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    country_code: "290",
    iso_code: "SH",
    flag: `${window.origin}/images/CountryCodes/saint_helena_ascension_and_tristan_da_cunha.png`
  },
  {
    name: "Saint Kitts and Nevis",
    country_code: "1869",
    iso_code: "KN",
    flag: `${window.origin}/images/CountryCodes/saint_kitts_and_nevis.png`
  },
  {
    name: "Saint Lucia",
    country_code: "1758",
    iso_code: "LC",
    flag: `${window.origin}/images/CountryCodes/saint_lucia.png`
  },
  {
    name: "Saint Martin",
    country_code: "590",
    iso_code: "MF",
    flag: `${window.origin}/images/CountryCodes/saint_martin.png`
  },
  {
    name: "Saint Pierre and Miquelon",
    country_code: "508",
    iso_code: "PM",
    flag: `${window.origin}/images/CountryCodes/saint_pierre_and_miquelon.png`
  },
  {
    name: "Saint Vincent and the Grenadines",
    country_code: "1784",
    iso_code: "VC",
    flag: `${window.origin}/images/CountryCodes/saint_vincent_and_the_grenadines.png`
  },
  {
    name: "Samoa",
    country_code: "685",
    iso_code: "WS",
    flag: `${window.origin}/images/CountryCodes/samoa.png`
  },
  {
    name: "San Marino",
    country_code: "378",
    iso_code: "SM",
    flag: `${window.origin}/images/CountryCodes/san_marino.png`
  },
  {
    name: "Sao Tome and Principe",
    country_code: "239",
    iso_code: "ST",
    flag: `${window.origin}/images/CountryCodes/sao_tome_and_prince.png`
  },
  {
    name: "Senegal",
    country_code: "221",
    iso_code: "SN",
    flag: `${window.origin}/images/CountryCodes/senegal.png`
  },
  {
    name: "Serbia",
    country_code: "381",
    iso_code: "RS",
    flag: `${window.origin}/images/CountryCodes/serbia.png`
  },
  {
    name: "Seychelles",
    country_code: "248",
    iso_code: "SC",
    flag: `${window.origin}/images/CountryCodes/seychelles.png`
  },
  {
    name: "Sierra Leone",
    country_code: "232",
    iso_code: "SL",
    flag: `${window.origin}/images/CountryCodes/sierra_leone.png`
  },
  {
    name: "Singapore",
    country_code: "65",
    iso_code: "SG",
    flag: `${window.origin}/images/CountryCodes/singapore.png`
  },
  {
    name: "Sint Maarten",
    country_code: "1721",
    iso_code: "SX",
    flag: `${window.origin}/images/CountryCodes/sint_maarten.png`
  },
  {
    name: "Slovakia",
    country_code: "421",
    iso_code: "SK",
    flag: `${window.origin}/images/CountryCodes/slovakia.png`
  },
  {
    name: "Slovenia",
    country_code: "386",
    iso_code: "SI",
    flag: `${window.origin}/images/CountryCodes/slovenia.png`
  },
  {
    name: "Solomon Islands",
    country_code: "677",
    iso_code: "SB",
    flag: `${window.origin}/images/CountryCodes/solomon_islands.png`
  },
  {
    name: "Somalia",
    country_code: "252",
    iso_code: "SO",
    flag: `${window.origin}/images/CountryCodes/somalia.png`
  },
  {
    name: "South Africa",
    country_code: "27",
    iso_code: "ZA",
    flag: `${window.origin}/images/CountryCodes/south_africa.png`
  },
  {
    name: "South Sudan",
    country_code: "211",
    iso_code: "SS",
    flag: `${window.origin}/images/CountryCodes/south_sudan.png`
  },
  {
    name: "Spain",
    country_code: "34",
    iso_code: "ES",
    flag: `${window.origin}/images/CountryCodes/spain.png`
  },
  {
    name: "Sri Lanka",
    country_code: "94",
    iso_code: "LK",
    flag: `${window.origin}/images/CountryCodes/sri_lanka.png`
  },
  {
    name: "Sudan",
    country_code: "249",
    iso_code: "SD",
    flag: `${window.origin}/images/CountryCodes/sudan.png`
  },
  {
    name: "Sweden",
    country_code: "46",
    iso_code: "SE",
    flag: `${window.origin}/images/CountryCodes/sweden.png`
  },
  {
    name: "Switzerland",
    country_code: "41",
    iso_code: "CH",
    flag: `${window.origin}/images/CountryCodes/switzerland.png`
  },
  {
    name: "Taiwan",
    country_code: "886",
    iso_code: "TW",
    flag: `${window.origin}/images/CountryCodes/taiwan.png`
  },
  {
    name: "Tajikistan",
    country_code: "992",
    iso_code: "TJ",
    flag: `${window.origin}/images/CountryCodes/tajikistan.png`
  },
  {
    name: "Thailand",
    country_code: "66",
    iso_code: "TH",
    flag: `${window.origin}/images/CountryCodes/thailand.png`
  },
  {
    name: "Turkey",
    country_code: "90",
    iso_code: "TR",
    flag: `${window.origin}/images/CountryCodes/turkey.png`
  },
  {
    name: "Ukraine",
    country_code: "380",
    iso_code: "UA",
    flag: `${window.origin}/images/CountryCodes/ukraine.png`
  },
  {
    name: "United Kingdom",
    country_code: "44",
    iso_code: "GB",
    flag: `${window.origin}/images/CountryCodes/united_kingdom.png`
  },
  {
    name: "United States",
    country_code: "1",
    iso_code: "US",
    flag: `${window.origin}/images/CountryCodes/united_states.png`
  },
  {
    name: "Uzbekistan",
    country_code: "998",
    iso_code: "UZ",
    flag: `${window.origin}/images/CountryCodes/uzbekistan.png`
  },
  {
    name: "Vietnam",
    country_code: "84",
    iso_code: "VN",
    flag: `${window.origin}/images/CountryCodes/vietnam.png`
  },
  {
    name: "Virgin Islands, British",
    country_code: "1284",
    iso_code: "VG",
    flag: `${window.origin}/images/CountryCodes/virgin_islands_british.png`
  },
  {
    name: "Virgin Islands, U.S.",
    country_code: "1340",
    iso_code: "VI",
    flag: `${window.origin}/images/CountryCodes/virgin_islands_us.png`
  },
  {
    name: "Wallis and Futuna",
    country_code: "681",
    iso_code: "WF",
    flag: `${window.origin}/images/CountryCodes/wallis_and_futuna.png`
  },
  {
    name: "Yemen",
    country_code: "967",
    iso_code: "YE",
    flag: `${window.origin}/images/CountryCodes/yemen.png`
  },
  {
    name: "Zambia",
    country_code: "260",
    iso_code: "ZM",
    flag: `${window.origin}/images/CountryCodes/zambia.png`
  },
  {
    name: "Zimbabwe",
    country_code: "263",
    iso_code: "ZW",
    flag: `${window.origin}/images/CountryCodes/zimbabwe.png`
  }
];

export const multipleEmails = [
  { id: 1, mail: "m*****s@gmail.com", color: "color-1" },
  { id: 2, mail: "m*****s@altayer.com", color: "color-2" },
  { id: 3, mail: "t*********9@hotmail.com", color: "color-3" }
];
export const SignupSchemaPhoneNumber = Yup.object().shape({
  code: Yup.number().required("Required"),
  phoneNumber: Yup.number()
    .min(8, "Too Short!")
    .max(12, "Too Long!")
    .required("Required")
});
export const sliderItems = [
  {
    id: 1,
    image: `${window.origin}/images/noname.png`,
    mobileimage: `${window.origin}/images/Hero.png`,
    title: "slider",
    headText: "Shop wherever. Whenever. Pay in 4.",
    subText:
      "Pay in 4 everywhere, get price drop notifications, track your deliveries and more. The all-in-one shopping app.",
    tinyText: "Get the free Amber app on your phone"
  },
  {
    id: 2,
    image: `${window.origin}/images/noname.png`,
    mobileimage: `${window.origin}/images/Hero.png`,
    title: "slider",
    headText: "Shop wherever. Whenever. Pay in 4.",
    subText:
      "Pay in 4 everywhere, get price drop notifications, track your deliveries and more. The all-in-one shopping app.",
    tinyText: "Get the free Amber app on your phone"
  },
  {
    id: 3,
    image: `${window.origin}/images/noname.png`,
    mobileimage: `${window.origin}/images/Hero.png`,
    title: "slider",
    headText: "Shop wherever. Whenever. Pay in 4.",
    subText:
      "Pay in 4 everywhere, get price drop notifications, track your deliveries and more. The all-in-one shopping app.",
    tinyText: "Get the free Amber app on your phone"
  }
];

export const sliderEditorial = [
  {
    id: 1,
    image: `${window.origin}/images/editorialCarousal.svg`,
    mobileimage: `${window.origin}/images/EditorialHeroMobile.svg`,
    arabicimage: `${window.origin}/images/editorialDesktopArabic.svg`,
    arabicmobileimage: `${window.origin}/images/editorialMobileArabic.svg`
  },
  {
    id: 2,
    image: `${window.origin}/images/editorialCarousal.svg`,
    mobileimage: `${window.origin}/images/EditorialHeroMobile.svg`,
    arabicimage: `${window.origin}/images/editorialDesktopArabic.svg`,
    arabicmobileimage: `${window.origin}/images/editorialMobileArabic.svg`
  },
  {
    id: 3,
    image: `${window.origin}/images/editorialCarousal.svg`,
    mobileimage: `${window.origin}/images/EditorialHeroMobile.svg`,
    arabicimage: `${window.origin}/images/editorialDesktopArabic.svg`,
    arabicmobileimage: `${window.origin}/images/editorialMobileArabic.svg`
  }
];
export const sliderEditorial2 = [
  {
    id: 1,
    image: `${window.origin}/images/Frame885Max.svg`,
    mobileimage: `${window.origin}/images/Frame885Mobile.svg`
  },
  {
    id: 2,
    image: `${window.origin}/images/Frame885Max.svg`,
    mobileimage: `${window.origin}/images/Frame885Mobile.svg`
  },
  {
    id: 3,
    image: `${window.origin}/images/Frame885Max.svg`,
    mobileimage: `${window.origin}/images/Frame885Mobile.svg`
  }
];
export const sliderEditorial3 = [
  {
    id: 1,
    image: `${window.origin}/images/FrameEditorial.svg`,
    mobileimage: `${window.origin}/images/FrameEditorialMobile.svg`
  },
  {
    id: 2,
    image: `${window.origin}/images/FrameEditorial.svg`,
    mobileimage: `${window.origin}/images/FrameEditorialMobile.svg`
  },
  {
    id: 3,
    image: `${window.origin}/images/FrameEditorial.svg`,
    mobileimage: `${window.origin}/images/FrameEditorialMobile.svg`
  }
];
export const sliderAmberApp = [
  {
    id: 1,
    image: `${window.origin}/images/noname.png`,
    mobileimage: `${window.origin}/images/Hero.png`,
    title: "slider",
    headText: "The all-in-one shopping app.",
    tinyText: "Get the free Amber app on your phone"
  },
  {
    id: 2,
    image: `${window.origin}/images/noname.png`,
    mobileimage: `${window.origin}/images/Hero.png`,
    title: "slider",
    headText: "The all-in-one shopping app.",
    tinyText: "Get the free Amber app on your phone"
  },
  {
    id: 3,
    image: `${window.origin}/images/noname.png`,
    mobileimage: `${window.origin}/images/Hero.png`,
    title: "slider",
    headText: "The all-in-one shopping app.",
    tinyText: "Get the free Amber app on your phone"
  }
];
export const products = [
  {
    id: 1,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",
    subText: "Fashion",
    image: `${window.origin}/products/Kids.png`
  },
  {
    id: 2,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/products/Rectangle 258.png`,
    subText: "Fashion"
  },
  {
    id: 3,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/products/Rectangle 259.png`,
    subText: "Fashion"
  },
  {
    id: 4,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    subText: "Fashion",
    image: `${window.origin}/products/Kids.png`
  },
  {
    id: 5,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/products/Rectangle 258.png`,
    subText: "Fashion"
  },
  {
    id: 6,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/products/Rectangle 259.png`,
    subText: "Fashion"
  },
  {
    id: 7,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    subText: "Fashion",
    image: `${window.origin}/products/Kids.png`
  },
  {
    id: 8,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/products/Rectangle 258.png`,
    subText: "Fashion"
  },
  {
    id: 9,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/products/Rectangle 259.png`,
    subText: "Fashion"
  }
];
export const keeps = [
  {
    id: 1,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",
    subText: "Celebrity",
    image: `${window.origin}/images/carousalsub.svg`
  },
  {
    id: 2,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/images/carousalsub.svg`,
    subText: "Celebrity"
  },
  {
    id: 3,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read",

    image: `${window.origin}/images/carousalsub.svg`,
    subText: "Celebrity"
  }
];
export const products_brands = [
  {
    id: 1,
    maintext: "ARMANI EXCHANGE",
    subText: "Fashion",
    image: `${window.origin}/products/Kids.png`
  },
  {
    id: 2,
    maintext: "GAP",
    image: `${window.origin}/products/Rectangle 258.png`,
    subText: "Fashion"
  },
  {
    id: 3,
    maintext: "MAMAS & PAPAS",
    image: `${window.origin}/products/Rectangle 259.png`,
    subText: "Fashion"
  }
];
export const events = [
  {
    id: 1,
    maintext: "CAFFE NERO",
    text: "Caffe Nero hosts a barista masterclass",
    subText: "Cafe & restaurants ",
    image: `${window.origin}/products/image94.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 2,
    maintext: "JIMMY CHOOS",
    text: "The Jimmy Choo collection event",
    image: `${window.origin}/products/image103.png`,
    subText: "Fashion",
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 3,
    maintext: "BOUCHERON",
    text: "Boucheron new store launch event",
    image: `${window.origin}/products/image101.png`,
    subText: "Jewellery",
    badge: "May 10 - Dubai Mall"
  }
];
export const offers = [
  {
    id: 1,
    maintext: "HERMES",
    text: "20% off all Hermes cosmetics",
    subText: "Beauty",
    image: `${window.origin}/products/image 103.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 2,
    maintext: "GAP",
    text: "Billie Eilish unveils her exclusive new scent",
    image: `${window.origin}/products/Rectangle 259.png`,
    subText: "Fashion",
    badge: "Ends in 3 days"
  },
  {
    id: 3,
    maintext: "MAMAS & PAPAS",
    text: "Billie Eilish unveils her exclusive new scent",
    image: `${window.origin}/products/Kids.png`,
    subText: "Home",
    badge: "Ends in 3 days"
  }
];
export const categories = [
  {
    id: 1,
    text: "ARMANI EXCHANGE",
    subText: "Fashion",
    image: `${window.origin}/images/category1.png`
  },
  {
    id: 2,
    text: "MAMAS & PAPAS",
    subText: "Fashion",
    image: `${window.origin}/images/category2.png`
  },
  {
    id: 3,
    text: "GAP",
    subText: "Fashion",
    image: `${window.origin}/images/category3.png`
  },
  {
    id: 4,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/images/category4.png`
  },
  {
    id: 5,
    text: "ARMANI EXCHANGE",
    subText: "Fashion",
    image: `${window.origin}/images/category1.png`
  },
  {
    id: 6,
    text: "MAMAS & PAPAS",
    subText: "Fashion",
    image: `${window.origin}/images/category2.png`
  },
  {
    id: 7,
    text: "GAP",
    subText: "Fashion",
    image: `${window.origin}/images/category3.png`
  },
  {
    id: 8,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/images/category4.png`
  },
  {
    id: 9,
    text: "ARMANI EXCHANGE",
    subText: "Fashion",
    image: `${window.origin}/images/category1.png`
  },
  {
    id: 10,
    text: "MAMAS & PAPAS",
    subText: "Fashion",
    image: `${window.origin}/images/category2.png`
  },
  {
    id: 11,
    text: "GAP",
    subText: "Fashion",
    image: `${window.origin}/images/category3.png`
  },
  {
    id: 12,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/images/category4.png`
  }
];
export const categoriesBrands = [
  {
    id: 1,
    text: "ARMANI EXCHANGE",
    subText: "Fashion",
    image: `${window.origin}/images/category1.png`
  },
  {
    id: 2,
    text: "MAMAS & PAPAS",
    subText: "Fashion",
    image: `${window.origin}/images/category2.png`
  },
  {
    id: 3,
    text: "GAP",
    subText: "Fashion",
    image: `${window.origin}/images/category3.png`
  },
  {
    id: 4,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/images/category4.png`
  },
  {
    id: 5,
    text: "ARMANI EXCHANGE",
    subText: "Fashion",
    image: `${window.origin}/images/category1.png`
  },
  {
    id: 6,
    text: "MAMAS & PAPAS",
    subText: "Fashion",
    image: `${window.origin}/images/category2.png`
  },
  {
    id: 7,
    text: "GAP",
    subText: "Fashion",
    image: `${window.origin}/images/category3.png`
  },
  {
    id: 8,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/images/category4.png`
  },
  {
    id: 9,
    text: "ARMANI EXCHANGE",
    subText: "Fashion",
    image: `${window.origin}/images/category1.png`
  },
  {
    id: 10,
    text: "MAMAS & PAPAS",
    subText: "Fashion",
    image: `${window.origin}/images/category2.png`
  },
  {
    id: 11,
    text: "GAP",
    subText: "Fashion",
    image: `${window.origin}/images/category3.png`
  },
  {
    id: 12,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/images/category4.png`
  },
  {
    id: 13,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 14,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 15,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 16,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 17,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 18,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 19,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 20,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 21,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 22,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 23,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 24,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 25,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 26,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 27,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 28,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 29,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 30,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 31,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 32,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 33,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 34,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 35,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  },
  {
    id: 36,
    text: "Kiehls",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`
  }
];
export const categoriesEditorial = [
  {
    id: 1,
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    maintext: "Billie Eilish unveils her exclusive new scent",
    badge: "May 10 - 1 min read"
  },
  {
    id: 2,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 3,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 4,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 5,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 6,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 7,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 8,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 9,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 10,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 11,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 12,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Fashion",
    image: `${window.origin}/images/Frame 885.svg`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 13,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 14,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 15,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 16,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 17,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 18,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 19,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 20,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 21,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 22,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 23,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 24,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 25,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 26,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 27,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 28,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 29,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 30,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 31,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 32,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 33,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 34,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 35,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  },
  {
    id: 36,
    maintext: "Billie Eilish unveils her exclusive new scent",
    subText: "Beauty",
    image: `${window.origin}/images/category5.png`,
    badge: "May 10 - 1 min read"
  }
];
export const categoriesEvents = [
  {
    id: 1,
    text: "JIMMY CHOO",
    subText: "Fashion",
    maintext: "Jimmy Choo presents The Rare Earth Collection",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 2,
    text: "KURT GEIGER",
    subText: "Fashion",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 3,
    text: "BOUCHERON",
    subText: "Fashion",
    maintext: "Boucheron looks back on 50 years with an event in Dubai",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 4,
    text: "DIPTYQUE",
    subText: "Fashion",
    maintext: "Diptyque sample fair: try our global top 10 fragrances",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 5,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 6,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 7,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 8,
    text: "DIPTYQUE",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 9,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 10,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 11,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 12,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 13,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 14,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 15,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 16,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 17,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 18,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 19,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 20,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 21,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 22,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 23,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 24,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 25,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 26,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 27,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 28,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 29,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 30,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 31,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 32,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 33,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 34,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 35,
    text: "JIMMY CHOO",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 36,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 37,
    text: "JIMMY CHOO",
    subText: "Fashion",
    maintext: "Jimmy Choo presents The Rare Earth Collection",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 38,
    text: "KURT GEIGER",
    subText: "Fashion",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 39,
    text: "BOUCHERON",
    subText: "Fashion",
    maintext: "Boucheron looks back on 50 years with an event in Dubai",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 40,
    text: "DIPTYQUE",
    subText: "Fashion",
    maintext: "Diptyque sample fair: try our global top 10 fragrances",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall"
  },
  {
    id: 41,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 42,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 43,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 44,
    text: "DIPTYQUE",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 45,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 46,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 47,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 48,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/258.png`,
    badge: "May 10 - Dubai Mall",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  }
];
export const categoriesOffers = [
  {
    id: 1,
    text: "JIMMY CHOO",
    subText: "Fashion",
    maintext: "Jimmy Choo presents The Rare Earth Collection",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 2,
    text: "KURT GEIGER",
    subText: "Fashion",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 3,
    text: "BOUCHERON",
    subText: "Fashion",
    maintext: "Boucheron looks back on 50 years with an event in Dubai",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 4,
    text: "DIPTYQUE",
    subText: "Fashion",
    maintext: "Diptyque sample fair: try our global top 10 fragrances",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 5,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 6,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 7,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 8,
    text: "DIPTYQUE",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 9,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 10,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 11,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 12,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 13,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 14,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 15,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 16,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 17,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 18,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 19,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 20,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 21,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 22,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 23,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 24,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 25,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 26,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 27,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 28,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 29,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 30,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 31,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 32,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 33,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 34,
    text: "KURT GEIGER",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 35,
    text: "JIMMY CHOO",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 36,
    text: "BOUCHERON",
    subText: "Beauty",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 37,
    text: "JIMMY CHOO",
    subText: "Fashion",
    maintext: "Jimmy Choo presents The Rare Earth Collection",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 38,
    text: "KURT GEIGER",
    subText: "Fashion",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 39,
    text: "BOUCHERON",
    subText: "Fashion",
    maintext: "Boucheron looks back on 50 years with an event in Dubai",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 40,
    text: "DIPTYQUE",
    subText: "Fashion",
    maintext: "Diptyque sample fair: try our global top 10 fragrances",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 41,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 42,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 43,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 44,
    text: "DIPTYQUE",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Diptyque sample fair: try our global top 10 fragrances"
  },
  {
    id: 45,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  },
  {
    id: 46,
    text: "KURT GEIGER",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Kurt Geiger celebrates a new store opening in Dubai Mall"
  },
  {
    id: 47,
    text: "BOUCHERON",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Boucheron looks back on 50 years with an event in Dubai"
  },
  {
    id: 48,
    text: "JIMMY CHOO",
    subText: "Fashion",
    image: `${window.origin}/products/260.png`,
    badge: "Ends in 3 days",
    maintext: "Jimmy Choo presents The Rare Earth Collection"
  }
];

export const sublist = [
  {
    id: 1,
    text: "AREEJ",
    maintext: "Areej 30% off on all natural skincare products",
    subText: "Beauty",
    image: `${window.origin}/images/sublist1.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 2,
    text: "KIEHL’S",
    maintext: "The biggest Kiehl’s beauty sale in Dubai only at ATG",
    subText: "Beauty",
    image: `${window.origin}/images/sublist2.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 3,
    text: "DIPTYQUE",
    maintext: "Exclusive clearance offer for all Amber members",
    subText: "Beauty",
    image: `${window.origin}/images/sublist3.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 4,
    text: "THE FRAGRANCE KITCHEN",
    maintext: "The best for you with 2 for 1 offers across all fragrances",
    subText: "Beauty",
    image: `${window.origin}/images/sublist4.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 5,
    text: "AREEJ",
    maintext: "Areej 30% off on all natural skincare products",
    subText: "Beauty",
    image: `${window.origin}/images/sublist1.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 6,
    text: "KIEHL’S",
    maintext: "The biggest Kiehl’s beauty sale in Dubai only at ATG",
    subText: "Beauty",
    image: `${window.origin}/images/sublist2.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 7,
    text: "DIPTYQUE",
    maintext: "Exclusive clearance offer for all Amber members ",
    subText: "Beauty",
    image: `${window.origin}/images/sublist3.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 8,
    text: "THE FRAGRANCE KITCHEN",
    maintext: "The best for you with 2 for 1 offers across all fragrances",
    subText: "Beauty",
    image: `${window.origin}/images/sublist4.png`,
    badge: "Ends in 3 days"
  }
];
export const sublistEditorials = [
  {
    id: 1,
    text: "Balenciaga",
    maintext: "Ville Small Handbag in black fine grain calfskin",
    image: `${window.origin}/images/editorial/image1.svg`,
    badge: "AED 5,000"
  },
  {
    id: 2,
    text: "CHANEL",
    maintext: "Coco Nior Eau De Parfum",
    image: `${window.origin}/images/editorial/image2.svg`,
    badge: "AED 5,000"
  },
  {
    id: 3,
    text: "DIPTYQUE",
    maintext: "Rouge Matte Lipstick Orange Boite",
    image: `${window.origin}/images/editorial/image3.svg`,
    badge: "AED 5,000"
  },
  {
    id: 4,
    text: "ARMANI EXCHANGE",
    maintext: "Renee Square Sunglasses",
    image: `${window.origin}/images/editorial/image4.svg`,
    badge: "AED 5,000"
  },
  {
    id: 5,
    text: "Balenciaga",
    maintext: "Ville Small Handbag in black fine grain calfskin",
    image: `${window.origin}/images/editorial/image5.svg`,
    badge: "AED 5,000"
  },
  {
    id: 6,
    text: "Balenciaga",
    maintext: "Ville Small Handbag in black fine grain calfskin",
    image: `${window.origin}/images/editorial/image1.svg`,
    badge: "AED 5,000"
  },
  {
    id: 7,
    text: "CHANEL",
    maintext: "Coco Nior Eau De Parfum",
    image: `${window.origin}/images/editorial/image2.svg`,
    badge: "AED 5,000"
  },
  {
    id: 8,
    text: "DIPTYQUE",
    maintext: "Rouge Matte Lipstick Orange Boite",
    image: `${window.origin}/images/editorial/image3.svg`,
    badge: "AED 5,000"
  },
  {
    id: 9,
    text: "ARMANI EXCHANGE",
    maintext: "Renee Square Sunglasses",
    image: `${window.origin}/images/editorial/image4.svg`,
    badge: "AED 5,000"
  },
  {
    id: 10,
    text: "Balenciaga",
    maintext: "Ville Small Handbag in black fine grain calfskin",
    image: `${window.origin}/images/editorial/image5.svg`,
    badge: "AED 5,000"
  }
];
export const sublistbrands = [
  {
    id: 1,
    text: "armani Exchange",
    maintext: "Areej 30% off on all natural skincare products",
    subText: "Beauty",
    image: `${window.origin}/products/Rectangle 2581.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 2,
    text: "armani Exchange",
    maintext: "The biggest Kiehl’s beauty sale in Dubai only at ATG",
    subText: "Beauty",
    image: `${window.origin}/products/image 943.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 3,
    text: "armani Exchange",
    maintext: "Exclusive clearance offer for all Amber members ",
    subText: "Beauty",
    image: `${window.origin}/products/image 94.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 4,
    text: "armani Exchange",
    maintext: "The best for you with 2 for 1 offers across all fragrances",
    subText: "Beauty",
    image: `${window.origin}/products/image 941.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 5,
    text: "armani Exchange",
    maintext: "Areej 30% off on all natural skincare products",
    subText: "Beauty",
    image: `${window.origin}/products/Rectangle 2581.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 6,
    text: "armani Exchange",
    maintext: "The biggest Kiehl’s beauty sale in Dubai only at ATG",
    subText: "Beauty",
    image: `${window.origin}/products/image 943.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 7,
    text: "armani Exchange",
    maintext: "Exclusive clearance offer for all Amber members ",
    subText: "Beauty",
    image: `${window.origin}/products/image 94.png`,
    badge: "Ends in 3 days"
  },
  {
    id: 8,
    text: "armani Exchange",
    maintext: "The best for you with 2 for 1 offers across all fragrances",
    subText: "Beauty",
    image: `${window.origin}/products/image 941.png`,
    badge: "Ends in 3 days"
  }
];

export const subrewards = [
  {
    id: 1,
    image: `${window.origin}/images/RewardSystem/Default.svg`
  },
  {
    id: 2,
    image: `${window.origin}/images/RewardSystem/Expired.svg`
  },
  {
    id: 3,
    image: `${window.origin}/images/RewardSystem/Variant2.svg`
  }
  // {
  //   id: 4,
  //   image: `${window.origin}/images/RewardSystem/Variant3.svg`,
  // },
  // {
  //   id: 5,
  //   image: `${window.origin}/images/RewardSystem/Variant4.svg`,
  // },
  // {
  //   id: 6,
  //   image: `${window.origin}/images/RewardSystem/Variant5.svg`,
  // },
  // {
  //   id: 7,
  //   image: `${window.origin}/images/RewardSystem/Variant6.svg`,
  // },
  // {
  //   id: 8,
  //   image: `${window.origin}/images/RewardSystem/Variant7.svg`,
  // },
  // {
  //   id: 9,
  //   image: `${window.origin}/images/RewardSystem/Variant8.svg`,
  // },
  // {
  //   id: 10,
  //   image: `${window.origin}/images/RewardSystem/Variant10.svg`,
  // },
];
export const sublistHow = [
  {
    id: 1,
    text: "ARMANI EXCHANGE",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images1.png`
  },
  {
    id: 2,
    text: "KIEHL’S",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images2.png`
  },
  {
    id: 3,
    text: "DIPTYQUE",
    subText: "Fashion",
    image: `${window.origin}/images/howiworks/Images3.png`
  },
  {
    id: 4,
    text: "Jimmy choo",
    subText: "Fashion",
    image: `${window.origin}/images/howiworks/Images4.png`
  },
  {
    id: 5,
    text: "ARMANI EXCHANGE",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images1.png`
  },
  {
    id: 6,
    text: "KIEHL’S",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images2.png`
  },
  {
    id: 7,
    text: "DIPTYQUE",
    subText: "Fashion",
    image: `${window.origin}/images/howiworks/Images3.png`
  },
  {
    id: 8,
    text: "Jimmy choo",
    subText: "Fashion",
    image: `${window.origin}/images/howiworks/Images4.png`
  }
];
export const sublistHow2 = [
  {
    id: 1,
    text: "ARMANI EXCHANGE",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images1.png`
  },
  {
    id: 2,
    text: "KIEHL’S",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images3.png`
  },
  {
    id: 3,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images2.png`
  },
  {
    id: 4,
    text: "Jimmy choo",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images4.png`
  },
  {
    id: 5,
    text: "ARMANI EXCHANGE",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images1.png`
  },
  {
    id: 6,
    text: "KIEHL’S",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images3.png`
  },
  {
    id: 7,
    text: "DIPTYQUE",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images2.png`
  },
  {
    id: 8,
    text: "Jimmy choo",
    subText: "Beauty",
    image: `${window.origin}/images/howiworks/Images4.png`
  }
];
export const sublistamber = [
  {
    id: 1,
    text: "Get the app",
    subText: "It is available for all devaices. And it’s free.",
    image: `${window.origin}/images/Frame5.png`,
    buttontext: "View all events"
  },
  {
    id: 2,
    text: "Verify your identity",
    subText: "Safety first. We have to verify that you’re you.",
    image: `${window.origin}/images/Frame4.png`,
    buttontext: "View all events"
  },
  {
    id: 3,
    text: "Shop, shop & away",
    subText: "Kick back and enjoy the best shopping app around.",
    image: `${window.origin}/images/Frame2.png`,
    buttontext: "Find out more"
  }
];
export const sublistamberBrands = [
  {
    id: 1,
    image: `${window.origin}/images/PartnerLogo1.png`
  },
  {
    id: 2,
    image: `${window.origin}/images/PartnerLogo2.png`
  },
  {
    id: 3,
    image: `${window.origin}/images/PartnerLogo3.png`
  },
  {
    id: 4,
    image: `${window.origin}/images/PartnerLogo4.png`
  },
  {
    id: 5,
    image: `${window.origin}/images/PartnerLogo5.png`
  },
  {
    id: 6,
    image: `${window.origin}/images/PartnerLogo1.png`
  },
  {
    id: 7,
    image: `${window.origin}/images/PartnerLogo2.png`
  },
  {
    id: 8,
    image: `${window.origin}/images/PartnerLogo3.png`
  },
  {
    id: 9,
    image: `${window.origin}/images/PartnerLogo4.png`
  },
  {
    id: 10,
    image: `${window.origin}/images/PartnerLogo5.png`
  },
  {
    id: 11,
    image: `${window.origin}/images/PartnerLogo1.png`
  },
  {
    id: 12,
    image: `${window.origin}/images/PartnerLogo2.png`
  },
  {
    id: 13,
    image: `${window.origin}/images/PartnerLogo3.png`
  },
  {
    id: 14,
    image: `${window.origin}/images/PartnerLogo4.png`
  },
  {
    id: 15,
    image: `${window.origin}/images/PartnerLogo5.png`
  }
];

export const InfoListAmber = [
  {
    id: 1,
    text: "Never pay full price again.",
    subtext: `Get exclusive deals and drops from brands all over the world. We’ll add new ones every day, so you never pay more than you have to. You can also get price drop notifications for items in your Collections.`
  },
  {
    id: 2,
    text: "Pay in 4 everywhere.",
    subtext: `In the Amber app you can pay in 4 with zero interest at any store. Payments are collected automatically every 2 weeks, so you never have to lift a finger.`
  },
  {
    id: 3,
    text: "Delivery tracking",
    subtext: `Get exclusive deals and drops from brands all over the world. We’ll add new ones every day, so you never pay more than you have to. You can also get price drop notifications for items in your Collections.`
  }
];
export const Users = [
  {
    id: 1,
    phone: "97155 855 7271",
    phoneCode: "123456",
    mail: `user@gmail.com`,
    mailCode: "123123"
  },
  {
    id: 2,
    phone: "97155 179 3105",
    phoneCode: "224473",
    mail: `user1@gmail.com`,
    mailCode: "456456",
    country: "null"
  },
  {
    id: 3,
    phone: "97155 215 7808",
    phoneCode: "228742",
    mail: `usermail@gmail.com`,
    mailCode: "123456",
    mail2: `usermail2@gmail.com`,
    mailCode2: "123456",
    mail3: `usermail3@gmail.com`,
    mailCode3: "123456"
  },
  {
    id: 4,
    phone: "97155 855 8282",
    phoneCode: "123456",
    userRecord: true
  }
];

export const financialServicesInfoList = [
  {
    id: 1,
    text: "More reach than the rest.",
    subtext: "Nobody can match our coverage across Europe."
  },
  {
    id: 2,
    text: "More experience.",
    subtext: "We’ve been connecting banks since 2005, long before PSD2."
  },
  {
    id: 3,
    text: "More transactions.",
    subtext:
      "With more than 200M PIS transactions a year, nobody else comes close."
  },
  {
    id: 4,
    text: "More delight.",
    subtext: "Our front end design gives you superior conversion, fast."
  },
  {
    id: 5,
    text: "More brand flexibility.",
    subtext:
      "Already got your bank licence? With our white label solution, you can build on top of our API and customise it to fit your brand."
  },
  {
    id: 6,
    text: "More easy.",
    subtext:
      "Simple integration, maintenance and documentation in a full-specced development and testing environment."
  }
];
export const parthners = [
  {
    id: "11",
    name: "EMIRATES PLATINUM",
    name_ar: "EMIRATES PLATINUM",
    image: `${window.origin}/images/EmiratesPlatinumProgrammelogo.png`,
    path: `/alliance-phone-number`
  },
  {
    id: "14",
    name: "Etihad Airways",
    name_ar: "الاتحاد للطيران",
    image: `${window.origin}/images/Etihad Airway Logo.PNG`,
    path: `/alliance-phone-number`
  },
  {
    id: "12",
    name: "Fazaa",
    name_ar: "فزعه",
    image: `${window.origin}/images/Fazaa.png`,
    path: `/alliance-phone-number`
  },
  {
    id: "13",
    name: "Al Saada",
    name_ar: "السعادة",
    image: `${window.origin}/images/Al_Saad_Logo.png`,
    path: `/alliance-phone-number`
  },

  {
    id: "18",
    name: "Esaad",
    name_ar: "إسعاد",
    image: `${window.origin}/images/esaad partner-logo-export.png`,
    path: `/alliance-phone-number`
  }
];
