import { useEffect } from "react";
import Item from "./item";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import google from "Assets/images/google.svg";
import googlearabic from "Assets/images/googlearabic.svg";
import AppstoreBTN from "Assets/images/AppstoreBTN.svg";
import AppstoreBTNarabic from "Assets/images/AppstoreBTNarabic.svg";

const AUTO_PLAY_OPTIONS = {
  delay: 5000,
  disableOnInteraction: false
};

export default function CarousalSliderSwiper({
  helperArray,
  editorialText,
  color_theme
}) {
  const { i18n } = useTranslation();

  //The OSName function checks the user's operating system (OS) based on their user agent and returns specific button images for downloading an app from either the
  //App Store or Google Play. It provides different images for Mac users and non-Mac users, with language-specific versions for both.

  const OSName = () => {
    if (navigator.userAgent.indexOf("Mac") !== -1) {
      return i18n.language === "arabic" ? AppstoreBTNarabic : AppstoreBTN;
    } else {
      return i18n.language === "arabic" ? googlearabic : google;
    }
  };

  useEffect(() => {
    OSName();
  }, [window.navigator.userAgent]);

  //The autoplayOption object is a configuration setting used for implementing automatic slideshow functionality in a carousel or slider.
  //It defines a 5-second delay between transitioning to the next item and ensures that user interactions do not disable this automatic advancement.

  return (
    <div
      className="carousal-swiper"
      style={{
        marginTop: "-1px",
        direction: i18n.language === "arabic" ? "rtl" : "ltr"
      }}
    >
      <Swiper
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        autoplay={helperArray.length > 1 ? AUTO_PLAY_OPTIONS : false}
        speed={1000}
        loop={false}
        className="mySwiper Carousel-main"
        indicatorContainerProps={{
          style: {
            position: "absolute",
            zIndex: 2
          }
        }}
      >
        {helperArray.map((item) => (
          <SwiperSlide
            key={item.id}
            style={{ direction: "ltr", backgroundColor: "red" }}
            data-lang={i18n.language}
          >
            <Item
              key={item.id}
              item={item}
              style={{ top: "0px" }}
              editorialText={editorialText}
              color_theme={color_theme}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
