import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LoginEmail from "Pages/Login/LoginEmail";
import LoginOTP from "Pages/Login/LoginOTP";
import LoginPhoneNumber from "Pages/Login/LoginPhoneNumber";
import LoginSelectCountry from "Pages/Login/LoginSelectCountry";
import MultipleEmail from "Pages/Login/MultipleEmail";

export const LoginRoutes = (props) => {
  const [phoneNumber, SetPhoneNumber] = useState("");
  const [OTPcode, SetOTPCode] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");
  const [selectedLoginAccount, setSelectedLoginAccount] = useState();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <LoginPhoneNumber
            SetPhoneNumber={SetPhoneNumber}
            phoneNumber={phoneNumber}
            SetPhoneCode={SetPhoneCode}
            SetOTPCode={SetOTPCode}
            phoneCode={phoneCode}
          />
        }
      />
      <Route
        path="/:loginKey"
        exact
        element={
          <LoginPhoneNumber
            SetPhoneNumber={SetPhoneNumber}
            phoneNumber={phoneNumber}
            SetPhoneCode={SetPhoneCode}
            SetOTPCode={SetOTPCode}
            phoneCode={phoneCode}
          />
        }
      />
      <Route
        path="phone-verification"
        element={
          <LoginOTP
            phoneNumber={phoneNumber}
            SetPhoneNumber={SetPhoneNumber}
            SetOTPCode={SetOTPCode}
            phoneCode={phoneCode}
          />
        }
      />
      <Route
        path="country-selection"
        element={
          <LoginSelectCountry OTPcode={OTPcode} SetOTPCode={SetOTPCode} />
        }
      />
      <Route
        path="email-validation"
        element={
          <LoginEmail
            selectedLoginAccount={selectedLoginAccount}
            setSelectedLoginAccount={setSelectedLoginAccount}
            SetOTPCode={SetOTPCode}
          />
        }
      />
      <Route
        path="email-selection"
        element={
          <MultipleEmail
            setSelectedLoginAccount={setSelectedLoginAccount}
            OTPcode={OTPcode}
            SetOTPCode={SetOTPCode}
            selectedLoginAccount={selectedLoginAccount}
          />
        }
      />
      <Route path="*" element={<Navigate to="PhoneNumber" />} replace />
    </Routes>
  );
};
