import { FormControl, Grid, CircularProgress, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useIsLanguageArabic, useIsMobileView } from "hooks";
import {
  useGetAccount,
  usePostUpdateMobileNumber,
  usePostUpdateOTP
} from "services/account/account.query";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  FormSettingsWrapper,
  InputLabel,
  SettingsDescription,
  SettingsSubTitle
} from "../common";
import MobileNumberInput from "../components/MobileNumberInput";
import NotificationSnack from "../components/NotificationSnack";
import OTPConfirmation from "../components/OTPConfirmation";
import PrimaryButton from "../components/PrimaryButton";
import { phone } from "phone";
import { formatPhoneNumber } from "Components/FormatPhoneNumber/FormatPhoneNumber";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import { SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS } from "../constants";
import { useCountryList } from "hooks/onBoardingHooks";
import { EVENT_TYPE, useDataLayer } from "hooks/useDataLayer";

const realCode = "123456";

const MobileNumberSettings = () => {
  const { t, i18n } = useTranslation();
  const isMobileView = useIsMobileView();
  const [showSnack, setShowSnack] = useState(false);
  const { setSettingsEvent } = useDataLayer();
  const [isChangePhoneModalOpen, setIsChangePhoneModalOpen] = useState(false);
  const isLanguageArabic = useIsLanguageArabic();
  const {
    data: userData,
    isLoading: accountIsLoading,
    refetch,
    isFetching
  } = useGetAccount();

  const [validation_key, SetValidationKey] = useState("");
  const [errorMessageControl, SetErrorMessageControl] = useState(false);
  const [serverErrorMessage, SetserverErrorMessage] = useState("");
  const [successQuery, SetSuccessQuery] = useState(false);
  const [fetchingAccount, SetFetchingAccount] = useState(false);

  const countryList = useCountryList();
  useEffect(() => {
    if (fetchingAccount) {
      refetch();
    }
  }, [successQuery, fetchingAccount]);

  useEffect(() => {
    if (fetchingAccount && !isFetching) {
      SetFetchingAccount(false);
    }
  }, [isFetching]);

  const PrimaryPhoneNumber =
    userData?.Customer?.[0]?.PersonalInformation?.PrimaryPhoneNumber;

  const { mutate: mutateUpdateMobileNumber, isLoading } =
    usePostUpdateMobileNumber({
      onSuccess: (response) => {
        SetValidationKey(response);
        setIsChangePhoneModalOpen(true);
        SetErrorMessageControl(false);
      },
      onError: (error) => {
        const { error_code, message, status } = error?.response?.data || {};
        if (error_code === 5000 && message === "Number already exist") {
          SetErrorMessageControl(true);
          SetserverErrorMessage(t("alreadyNumberPhone"));
        } else if (error_code === 4002 && status === 429) {
          SetErrorMessageControl(true);
          SetserverErrorMessage(t("1minotp"));
        } else if (error_code === 4001 && status === 429) {
          SetErrorMessageControl(true);
          SetserverErrorMessage(t("15minotp"));
        } else if (message === "Invalid phone number" && status === 400) {
          SetErrorMessageControl(true);
          SetserverErrorMessage(
            t("Your phone number is not valid. Please try again")
          );
        }
      }
    });
  const validationSchema = yup.object({
    code: yup
      .object({
        country_code: yup.string().required(),
        iso_code: yup.string().required("Phone code cannot be blank")
      })
      .required(),
    phoneNumber: yup
      .string()
      .required(t("Phone number cannot be blank"))
      .test(
        "len",
        t("Phone number should be 7 digits minimum"),
        (val) => val?.length > 7
      )
  });

  // The filterRegion function takes a country code as input and searches for a matching country in a countryList. If a match is found, it returns the corresponding country object with details such as name and ISO code. This function is useful for retrieving information about a country based on its country code.

  const filterRegion = (code) => {
    return countryList?.find((country) => country?.country_code === code);
  };

  //generateInitialsValues: It uses the phone utility function with the phone_number to parse and obtain information about the phone number, including the country code.
  // It uses the obtained country code to look up the corresponding ISO code by calling the filterRegion function.
  // It then extracts the remaining phone number after the country code.
  // Finally, it returns an object with two properties:
  // code: An object containing country_code and iso_code.
  // phoneNumber: The remaining phone number.

  const generateInitialsValues = (phone_number) => {
    let country_code = phone(`+${phone_number}`)?.countryCode || "";
    let iso_code = filterRegion(country_code)?.iso_code || "TR";
    let phoneNumber = phone(`+${phone_number}`)?.phoneNumber?.slice(
      country_code?.length
    );

    return {
      code: {
        country_code: country_code || "",
        iso_code: iso_code || ""
      },
      phoneNumber: phoneNumber || ""
    };
  };
  const [sendToCode, setSendToCode] = useState(
    generateInitialsValues(PrimaryPhoneNumber)?.code?.country_code
  );

  const [sendToPhone, setSendToPhone] = useState(
    generateInitialsValues(PrimaryPhoneNumber)?.phoneNumber
  );
  useEffect(() => {
    setSendToCode(
      generateInitialsValues(PrimaryPhoneNumber)?.code?.country_code
    );
  }, [userData]);

  const changeMobileNumberForm = useFormik({
    initialValues: generateInitialsValues(PrimaryPhoneNumber),
    validationSchema,
    enableReinitialize: true,

    onSubmit: async ({ phoneNumber, iso_code, country_code }) => {
      const defaultValue = generateInitialsValues(PrimaryPhoneNumber);

      if (defaultValue.phoneNumber !== phoneNumber) {
        setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, { step: "enter_phone" });
      }

      await mutateUpdateMobileNumber({
        phoneNumber:
          changeMobileNumberForm?.values.code?.country_code +
          changeMobileNumberForm?.values?.phoneNumber,
        iso_code: changeMobileNumberForm?.values.code?.iso_code
      });
    }
  });

  const errorMessage =
    (changeMobileNumberForm.touched.code
      ? t(changeMobileNumberForm.errors.code?.iso_code)
      : "") ||
    (changeMobileNumberForm.touched.phoneNumber
      ? t(changeMobileNumberForm.errors.phoneNumber)
      : "");

  useEffect(() => {
    SetErrorMessageControl(false);
  }, [isChangePhoneModalOpen]);

  if (isLoading || accountIsLoading || fetchingAccount)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  return (
    <>
      {!isLoading && !accountIsLoading && !fetchingAccount && (
        <Grid>
          {isChangePhoneModalOpen && (
            <OTPConfirmation
              sendTo={
                (isLanguageArabic ? sendToCode + "" : sendToCode) +
                " " +
                sendToPhone
              }
              setPhoneNumber={changeMobileNumberForm.handleChange}
              phoneNumber={
                changeMobileNumberForm?.values.code?.country_code +
                changeMobileNumberForm?.values?.phoneNumber
              }
              iso_code={changeMobileNumberForm?.values.code?.iso_code}
              realCode={realCode}
              onPhoneChangeSuccess={(newPhoneNumber) => {
                changeMobileNumberForm.setValues(
                  generateInitialsValues(newPhoneNumber)
                );
                setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, {
                  step: "enter_phone_success"
                });
                setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, {
                  step: "complete"
                });
                setShowSnack(true);
              }}
              isChangePhoneModalOpen={isChangePhoneModalOpen}
              setIsChangePhoneModalOpen={setIsChangePhoneModalOpen}
              validation_key={validation_key}
              Query={usePostUpdateOTP}
              SetserverErrorMessage={SetserverErrorMessage}
              SetErrorMessageControl={SetErrorMessageControl}
              errorMessageControl={errorMessageControl}
              serverErrorMessage={serverErrorMessage}
              time={SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS}
              parent={"mobile"}
              SetValidationKey={SetValidationKey}
              SetSuccessQuery={SetSuccessQuery}
              isFetching={isFetching}
              accountIsLoading={accountIsLoading}
              SetFetchingAccount={SetFetchingAccount}
            />
          )}
          <NotificationSnack
            message={t("Your phone number has been updated")}
            open={showSnack}
            handleClose={() => {
              setShowSnack(false);
            }}
          />
          <SettingsSubTitle text={t("Mobile number")} />
          <SettingsDescription
            className="settings-description-header"
            text={t("Secure your account")}
          />
          <form onSubmit={changeMobileNumberForm.handleSubmit} noValidate>
            <FormSettingsWrapper>
              <div style={{ width: "100%" }}>
                <InputLabel
                  style={{ marginBottom: "8px" }}
                  text={t("Mobile number")}
                />
                <FormControl style={{ width: isMobileView ? "100%" : "400px" }}>
                  <MobileNumberInput
                    errorMessage={errorMessage}
                    formik={changeMobileNumberForm}
                    codeFieldProps={{
                      value: changeMobileNumberForm.values.code,
                      onChange: changeMobileNumberForm.handleChange,
                      name: "code"
                    }}
                    phoneFieldProps={{
                      value: formatPhoneNumber(
                        changeMobileNumberForm.values.phoneNumber
                      ),
                      onChange: changeMobileNumberForm.handleChange,
                      name: "phoneNumber",
                      isValid:
                        changeMobileNumberForm.isValid &&
                        changeMobileNumberForm.dirty
                    }}
                    setSendToPhone={setSendToPhone}
                    setSendToCode={setSendToCode}
                    account={true}
                  />
                </FormControl>
              </div>
              {errorMessageControl && (
                <AlertWarning marginbottom="24px" text={serverErrorMessage} />
              )}

              <PrimaryButton
                style={{
                  width: isMobileView ? "100%" : "initial",
                  marginTop: "48px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalMedium"
                      : "MontserratMedium"
                }}
                text={t("Save changes")}
              />
            </FormSettingsWrapper>
          </form>
        </Grid>
      )}
    </>
  );
};

export default MobileNumberSettings;
