import React from "react";
import { useTranslation } from "react-i18next";
import ColorButton from "Assets/ColorButton/ColorButton";

const PrimaryButton = ({ text, style = {}, ...props }) => {
  const { t, i18n } = useTranslation();

  return (
    <ColorButton
      type="submit"
      variant="contained"
      className="button-submit-pn"
      disableRipple
      style={{
        marginTop: "24px",
        fontFamily:
          i18n.language === "arabic" ? "TajawalMedium" : "MontserratMedium",
        ...style
      }}
      {...props}
    >
      {t(text)}
    </ColorButton>
  );
};

export default PrimaryButton;
