import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as Arrow } from "Assets/images/right-arrow.svg";
import CustomLink from "../components/CustomLink";
import { ABOUT_PAGES_MENU_ITEMS } from "./constants";
import { useIsLanguageArabic } from "hooks";

const MenuItem = ({ onClick, isSelected, label, navigateTo }) => {
  const isLanguageArabic = useIsLanguageArabic();
  const { t, i18n } = useTranslation();
  return (
    <li
      className="menu-item-text"
      data-lang={i18n.language}
      style={isSelected ? { color: "#5A5F64" } : {}}
    >
      {isSelected ? (
        <Arrow
          width={20}
          height={20}
          stroke="#5A5F64"
          style={
            isLanguageArabic
              ? { marginLeft: "16px", transform: "rotate(180deg)" }
              : { marginRight: "16px" }
          }
        />
      ) : null}
      <CustomLink
        onClick={onClick}
        style={{
          color: isSelected ? "#5A5F64" : "initial"
        }}
        to={navigateTo}
      >
        {t(label)}
      </CustomLink>
    </li>
  );
};

export const AboutAmberMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(
    () =>
      ABOUT_PAGES_MENU_ITEMS.find(({ to }) => to === location.pathname)?.value
  );

  return (
    <ul>
      {ABOUT_PAGES_MENU_ITEMS.map(({ label, to, value }, index) => (
        <MenuItem
          key={to}
          label={t(label)}
          isSelected={selectedItem === value}
          navigateTo={to}
          onClick={() => {
            setSelectedItem(value);
          }}
        />
      ))}
    </ul>
  );
};

export const AboutAmberMenuMobile = () => {
  const { t, i18n } = useTranslation();

  return (
    <Accordion style={{ width: "100%", boxShadow: "none" }}>
      <AccordionSummary style={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
        <Typography className="menu-title" data-lang={i18n.language}>
          {t("Menu")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AboutAmberMenu />
      </AccordionDetails>
    </Accordion>
  );
};
