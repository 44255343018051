export const ABOUT_PAGES_MENU_ITEMS = [
  {
    label: "About Us",
    to: "/about/us",
    value: "about-us"
  },
  {
    label: "Corporate Governance",
    to: "/about/corporate-governance",
    value: "corporate-governance"
  },
  {
    label: "Corporate Governance Reports",
    to: "/about/corporate-governance-reports",
    value: "corporate-governance-reports"
  }
];
