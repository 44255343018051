export const MENU_ITEMS = [
  {
    label: "Personal details",
    to: "/account/personal-details",
    value: "personal-details"
  },
  {
    label: "Mobile number",
    to: "/account/mobile-number",
    value: "mobile-number"
  },
  {
    label: "Email address",
    to: "/account/email",
    value: "email-address"
  },

  {
    label: "Notifications",
    to: "/account/notifications",
    value: "notifications"
  },
  { label: "Language", to: "/account/language", value: "language" }
];

export const SECURE_CODE_LENGTH = 6;
export const MAIL_OTP_SECURE_CODE_DURATION_IN_SECONDS = 179;
export const SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS = 59;
