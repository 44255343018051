import React, { useMemo } from "react";
import Layout from "../Layout";
import LightBackground from "Components/LightBackground";
import { useTranslation } from "react-i18next";
import { useGetPrivacyAndPolicy } from "services/privacyAndPolicy/privacyAndPolicies.query";
import PurchaseContainer from "../PurchaseHistory/PurchaseContainer";
import HistoryButton from "Components/HistoryButton";
import { useLocation, useSearchParams } from "react-router-dom";

const PrivacyPolicy = () => {
  const referalCode = useLocation()?.state?.referalCode;
  const [searchParams] = useSearchParams();
  const isExternal = searchParams?.get("external");
  const { data, isSuccess } = useGetPrivacyAndPolicy();
  const privacyAndPolicyData =
    data?.items[0]?.fields?.description?.Content[0]?.Content[0] || "";
  const privacyAndPolicyName = data?.items[0]?.fields?.title || "";
  const { i18n } = useTranslation();

  const alignContent = useMemo(() => {
    return i18n.language === "arabic" ? "right" : "left";
  }, [i18n.language]);

  return (
    <Layout className="brands">
      <PurchaseContainer>
        {isSuccess && (
          <>
            <LightBackground
              text={privacyAndPolicyName}
              xsMinHeight="200px"
              textalign={alignContent}
              paddingTp="0px"
              minheight="320px"
            />
            {isExternal && (
              <HistoryButton
                referalCode={referalCode}
                image="return"
                Left={i18n.language === "arabic" ? "auto" : 25}
                Top={25}
                Right={i18n.language === "arabic" ? 25 : "auto"}
                path={`/sign-up/personal-details`}
              />
            )}
            <div
              className={"privacyAndPolicy"}
              data-lang={i18n.language}
              dangerouslySetInnerHTML={{ __html: privacyAndPolicyData?.value }}
            ></div>
          </>
        )}
      </PurchaseContainer>
    </Layout>
  );
};

export default PrivacyPolicy;
