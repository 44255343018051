import React, { useMemo } from "react";
import CaousalBrands from "Components/AmberApp/CaousalBrands";

//The transform function takes an item and an index and transforms the data into a new object with specific properties,
// which is typically used for data mapping and processing.

const transform = (item, index) => ({
  id: item.id || index,
  text: item.brand,
  maintext: item.title,
  badge: item.badge,
  subText: item.categories || item.category,
  image: item.image
});

export const AmberCaroselSublistContainer = ({ widget = {} }) => {
  const {
    color_theme,
    max_items,
    maxItems,
    label,
    animation_speed,
    auto_slide,
    ids,
    cta_button = {},
    items = []
  } = widget;

  //The mappedItems variable is generated using the useMemo hook, which processes a list of items, assigns IDs,
  // and applies a transformation function to create a new list of transformed items,
  // optimizing performance by recalculating only when the items array changes.

  const mappedItems = useMemo(() => {
    const idArray = ids?.split(",");
    if ((max_items || maxItems) > 3) {
      return items
        .slice(0, max_items || maxItems)
        .map((item, index) => ({ ...item, id: idArray?.[index] }))
        .map(transform);
    } else {
      return items
        .map((item, index) => ({ ...item, id: idArray?.[index] }))
        .map(transform);
    }
  }, [items]);
  const { link_type } = cta_button || {};
  return (
    <CaousalBrands
      items={mappedItems}
      size={mappedItems?.length < 5 ? mappedItems?.length : 5}
      head={label}
      AmberApp={true}
      color_theme={color_theme}
      animation_speed={animation_speed}
      auto_slide={auto_slide}
      itemDetailParentPath={
        link_type === "CONTENT" ? "/" + cta_button.link : cta_button.link
      }
      pathto={link_type === "CONTENT" ? "/" + cta_button.link : cta_button.link}
    />
  );
};
