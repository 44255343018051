import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import WpRightLogin from "Components/WpRightLogin";
import { useSelectAccountCustomers } from "hooks/useSelectAccountCustomers";
import { usePostSelectAccountOtp } from "services/account/account.query";
import { useSelector, useDispatch } from "react-redux";
import { setRouteCntrl, clearRouteCntrl } from "store/authanticateSlice";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";

const styleCheck = {
  backgroundColor: "white",
  display: "flex",
  width: "20px",
  height: "20px",
  margin: "0",
  cursor: "pointer",
  borderRadius: " 50%",
  border: "1px solid #90969E",
  justifyContent: "center",
  alignItems: "center"
};
const styleCheckActive = {
  backgroundColor: "black",
  border: "1px solid black",
  display: "flex",
  width: "20px",
  height: "20px",
  cursor: "pointer",
  borderRadius: " 50%",
  justifyContent: "center",
  alignItems: "center"
};
const imageStyle = {
  opacity: "0",
  transition: "all 0.3s ease",
  width: "10px",
  height: "10px"
};
const imageStyleActive = {
  opacity: "1",
  transition: "all 0.3s ease",
  width: "10px",
  height: "10px"
};

const modifiedEmails = ({ email }) => {
  if (!email) {
    return "";
  }
  const firstChar = email.charAt(0);
  const atIndex = email.indexOf("@");
  const lastChar = email.slice(atIndex - 1, atIndex);

  const modifiedEmail =
    firstChar + "*".repeat(atIndex - 1) + lastChar + email.slice(atIndex);
  return modifiedEmail;
};
const MultipleEmail = ({ setSelectedLoginAccount, selectedLoginAccount }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [showAlert, setShowalert] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const { customers = [] } = useSelectAccountCustomers();
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};
  const lang = i18n.language === "arabic" ? "ar" : "en";
  const personalTrue = routeCntrl.some(
    (item) => item.otp === true || item.multiple === true
  );
  const {
    mutate: selectAccountOtp,
    error,
    isLoading
  } = usePostSelectAccountOtp({
    onSuccess: ({ validation_key } = {}) => {
      navigate(`/sign-in/email-validation`, { state: { validation_key } });
      const newRoute = { multipleOTP: true, multiple: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));
    },
    onError: () => {}
  });

  //  navigateProtect:
  // It's a memoized function created using useCallback.
  // It navigates to the /sign-in route if personalTrue is falsy (i.e., the user is not considered "personal").
  // If personalTrue is truthy, it sets showComponent to true and defines a new route object named newRoute with the property multiple set to true.
  // It clears the route control state and sets it to the newRoute using dispatch.

  // accounts (memoized function):
  // This function uses the useMemo hook to memoize the accounts data.
  // It maps the customers array to an array of objects containing email and customerId properties.
  // The mapping is conditioned to prevent errors if customers is falsy.
  // This function depends on [customers].

  // onChangeAccount:
  // It takes an account as a parameter.
  // It sets showAlert to false (presumably to clear any alert messages).
  // It sets the selectedLoginAccount to the provided account.

  // handleSubmit:
  // This function is called when a form is submitted.
  // It checks if selectedLoginAccount is falsy and sets showAlert to true if it is.
  // If selectedLoginAccount is defined, it sets showAlert to false and calls the selectAccountOtp function with the necessary parameters.

  const navigateProtect = useCallback(() => {
    if (!personalTrue) {
      navigate(`/sign-in`);
    } else {
      setShowComponent(true);
      const newRoute = { multiple: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));
    }
  }, [dispatch, navigate, personalTrue]);

  useEffect(() => {
    setSelectedLoginAccount();
    navigateProtect();
  }, [navigateProtect, setSelectedLoginAccount]);

  const accounts = useMemo(() => {
    return (
      customers?.map?.((c) => ({
        email: c.PersonalInformation.EmailAddress,
        customerId: c.CustomerID
      })) || []
    );
  }, [customers]);

  const onChangeAccount = (account) => {
    setShowalert(false);
    setSelectedLoginAccount(account);
  };

  const handleSubmit = () => {
    if (!selectedLoginAccount) {
      setShowalert(true);
      return;
    }
    setShowalert(false);
    selectAccountOtp({
      customer_id: selectedLoginAccount.customerId,
      email: selectedLoginAccount.email,
      lang: lang
    });
  };

  return (
    <>
      {showComponent ? (
        <div className="WPlogin" style={{ padding: "0px", height: "100vh" }}>
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: background || "#FFFFFF"
            }}
          >
            <div className="left-page-form">
              <div>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "login-head-pn-arabic"
                      : "login-head-pn"
                  }
                >
                  <span>{t("Multiple email addresses found")}</span>
                </div>
                <Box
                  className={
                    i18n.language === "arabic"
                      ? "subtext-pn-arabic"
                      : "subtext-pn"
                  }
                  style={{
                    fontSize: i18n.language === "arabic" ? "16px" : "14px",
                    padding: 0
                  }}
                  sx={{
                    margin: {
                      xs: "16px 12px 24px 0px !important",
                      md: "20px 12px 16px 0px !important"
                    }
                  }}
                >
                  <div>{t("Your mobile phone number")}</div>
                </Box>
                <div>
                  <div
                    className={
                      i18n.language === "arabic"
                        ? "custom-radios-arabic"
                        : "custom-radios"
                    }
                    style={{ marginTop: "8px" }}
                  >
                    {accounts?.length
                      ? accounts.map((account) => (
                          <div
                            style={{ paddingRight: "0px " }}
                            key={account.email}
                          >
                            <div
                              className={
                                selectedLoginAccount?.email === account.email
                                  ? "custom-radio-row-active"
                                  : "custom-radio-row"
                              }
                              onClick={() => onChangeAccount(account)}
                            >
                              <div className="SC-radio-box">
                                <div
                                  className="SC-box-1"
                                  style={{ width: "100%" }}
                                >
                                  <div className="SC-text">
                                    {modifiedEmails(account)}
                                  </div>
                                </div>
                                <div
                                  className="SC-box-2"
                                  style={{
                                    position: "relative",
                                    right:
                                      i18n.language === "arabic"
                                        ? "0px"
                                        : "10px"
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id={account.customerId}
                                    name={account.customerId}
                                    value={account.email}
                                  />
                                  <label htmlFor="color-1">
                                    <span
                                      style={
                                        selectedLoginAccount?.email ===
                                        account.email
                                          ? styleCheckActive
                                          : styleCheck
                                      }
                                    >
                                      <img
                                        style={
                                          selectedLoginAccount?.email ===
                                          account.email
                                            ? imageStyleActive
                                            : imageStyle
                                        }
                                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                                        alt="Checked Icon"
                                      />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  {showAlert ? (
                    <AlertWarning text={t("Please select your email.")} />
                  ) : null}
                  {error ? (
                    <AlertWarning text={error?.response?.data?.message} />
                  ) : null}
                </div>
              </div>
              <div className="btnbox-multiple-email">
                {isLoading ? (
                  <ColorLoadingButton
                    size="small"
                    loading={true}
                    variant="outlined"
                    disabled
                    type="submit"
                    className="button-submit-loading"
                    style={{
                      fontFamily:
                        i18n.language === "arabic"
                          ? "TajawalMedium"
                          : "MontserratMedium",
                      maxWidth: "100%",
                      marginTop: "0px"
                    }}
                  />
                ) : (
                  <ColorButton
                    variant="contained"
                    onClick={handleSubmit}
                    className="button-submit-pn"
                    style={{
                      fontFamily:
                        i18n.language === "arabic"
                          ? "TajawalMedium"
                          : "MontserratMedium",
                      marginTop: "0px"
                    }}
                  >
                    {t("Continue")}
                  </ColorButton>
                )}
              </div>
            </div>
          </div>
          <WpRightLogin />
        </div>
      ) : null}
    </>
  );
};

export default MultipleEmail;
