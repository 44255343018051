import React from "react";
import ColorButton from "Assets/ColorButton/ColorButton";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const KeepItWorks = ({ id, widget }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const ctaButton = widget?.widgets?.[0];
  const { color_theme } = widget;
  const {
    background_color,
    description,
    cta_button_text,
    header_text,
    cta_button_border,
    cta_button_fill
  } = color_theme?.color_definition || {};

  return (
    <Box
      id={id}
      className="keep"
      style={{ backgroundColor: background_color }}
      sx={{
        paddingLeft: { xs: "20px", md: "11.11%" },
        paddingRight: { xs: "20px", md: "11.11%" },
        direction: i18n.language === "arabic" ? "rtl" : "ltr"
      }}
    >
      <Box>
        <div
          style={{ color: header_text || "" }}
          className={i18n.language === "arabic" ? "keep-up-arabic" : "keep-up"}
        >
          {widget?.title}
        </div>
        <div
          style={{
            color: description || "",
            marginBottom: "24px"
          }}
          className={
            i18n.language === "arabic" ? "section-text-arabic" : "section-text"
          }
        >
          {widget?.description}
        </div>
        <ColorButton
          onClick={() => navigate(ctaButton?.link)}
          style={{
            border: `1px solid ${cta_button_border}`,
            color: cta_button_text,
            backgroundColor: cta_button_fill
          }}
          className={
            i18n.language === "arabic"
              ? "button-submit-arabic"
              : "button-submit"
          }
        >
          {ctaButton?.title}
        </ColorButton>
      </Box>
    </Box>
  );
};

export default KeepItWorks;
