import React, { useMemo } from "react";
import Layout from "../Layout";
import LightBackground from "Components/LightBackground";
import { useTranslation } from "react-i18next";
import { useGetTermsAndConditions } from "services/termsAndConditions/termsAndConditions.query";
import PurchaseContainer from "../PurchaseHistory/PurchaseContainer";
import HistoryButton from "Components/HistoryButton";
import { useLocation, useSearchParams } from "react-router-dom";

const TermsAndConditions = () => {
  const referalCode = useLocation()?.state?.referalCode;
  const [searchParams] = useSearchParams();
  const isExternal = searchParams?.get("external");
  const { data, isSuccess } = useGetTermsAndConditions();
  const termsAndConditionsName = data?.items[0]?.fields?.title || "";
  const termsAndConditionsData =
    data?.items[0]?.fields?.description?.Content[0]?.Content[0] || "";
  const { i18n } = useTranslation();

  const alignContent = useMemo(() => {
    return i18n.language === "arabic" ? "right" : "left";
  }, [i18n.language]);

  const userAgent = window?.navigator?.userAgent;
  const termsWrapperStyleApp = {
    paddingBottom: "50px"
  };
  const termsWrapperStyle = {
    paddingBottom: "0"
  };
  return (
    <Layout className="brands">
      <PurchaseContainer>
        {isSuccess && (
          <>
            <LightBackground
              text={termsAndConditionsName}
              xsMinHeight="200px"
              textalign={alignContent}
              paddingTp="0px"
              minheight="320px"
            />
            {isExternal && (
              <HistoryButton
                referalCode={referalCode}
                image="return"
                Left={i18n.language === "arabic" ? "auto" : 25}
                Top={25}
                Right={i18n.language === "arabic" ? 25 : "auto"}
                path={`/sign-up/personal-details`}
              />
            )}
            <div
              className={"termsAndConditions"}
              data-lang={i18n.language}
              style={
                userAgent?.includes("amber")
                  ? termsWrapperStyleApp
                  : termsWrapperStyle
              }
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsData?.value
              }}
            ></div>
          </>
        )}
      </PurchaseContainer>
    </Layout>
  );
};

export default TermsAndConditions;
