import React from "react";
import BrowserNav from "Components/BrowseUpdate/BrowserNav";
const BrowserUpdate = () => {
  return (
    <div>
      <BrowserNav />
    </div>
  );
};

export default BrowserUpdate;
