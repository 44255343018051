import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";

import icon from "Assets/images/Warning.svg";

const AlertWarning = ({ text, linktext, margintop, marginbottom }) => {
  const { t, i18n } = useTranslation();

  const CustomIcon = styled("img")({
    width: "16px",
    height: "16px",
    color: "#2E1111"
  });
  const styleEnglish = (
    <Alert
      severity="error"
      icon={<CustomIcon src={icon} alt="icon" />}
      sx={{
        backgroundColor: "#FFD7D7",
        alignItems: "center",
        borderRadius: "8px",
        transition: "all 1s ease-out",
        color: "#2E1111",
        "& .MuiAlert-icon": {
          color: "#2E1111"
        },
        "& .MuiAlert-message": {
          fontFamily: "MontserratRegular",
          fontWeight: "400",
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "130%",
          color: "#2E1111",
          padding: "8px"
        }
      }}
    >
      {linktext || t(text)}
    </Alert>
  );
  const styleArabic = (
    <Alert
      severity="error"
      icon={<CustomIcon src={icon} alt="icon" />}
      sx={{
        backgroundColor: "#FFD7D7",
        alignItems: "center",
        borderRadius: "8px",
        color: "#2E1111",
        "& .MuiAlert-icon": {
          color: "#2E1111"
        },
        "& .MuiAlert-message": {
          fontFamily: "TajawalRegular !important",
          fontWeight: "400",
          fontStyle: "normal",
          fontSize: "16px",
          lineHeight: "130%",
          color: "#2E1111",
          padding: "8px !important"
        }
      }}
    >
      {linktext || t(text)}
    </Alert>
  );

  return (
    <Collapse in={true} collapsedSize={40}>
      <Stack
        sx={{
          width: "100%",
          marginTop: margintop || "24px",
          marginBottom: marginbottom || "20px"
        }}
        spacing={2}
      >
        {i18n.language === "arabic" ? styleArabic : styleEnglish}
      </Stack>
    </Collapse>
  );
};

export default AlertWarning;
