import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scrollInf: "",
  isMenuOpen: false,
  alliance_id: "00"
};

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    scrollInfFunc: (state, action) => {
      state.scrollInf = action.payload;
    },
    isMenuOpenReducer: (state, action) => {
      state.isMenuOpen = action.payload;
    },
    alliance_id_reducer: (state, action) => {
      state.alliance_id = action.payload;
    }
  }
});

export const { scrollInfFunc, isMenuOpenReducer, alliance_id_reducer } =
  publicSlice.actions;
export default publicSlice.reducer;
