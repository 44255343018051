export const selectText = {
  fontFamily: "MontserratMedium",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "130%",
  color: "#000000",
  padding: " 6px 12px",
  gap: "10px",
  border: "1px solid #000000",
  borderRadius: "50px",
  height: "30px",
  minWidth: "200px",
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    display: "flex",
    alignItems: "center",
    paddingRight: "32px !important",
    paddingLeft: "12px !important"
  },
  "& .MuiSelect-icon": {
    top: "12px !important",
    position: "absolute",
    right: "15px",
    left: "auto"
  }
};
export const selectTextArabic = {
  fontFamily: "TajawalMedium",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "17px",
  lineHeight: "130%",
  color: "#000000",
  padding: " 6px 12px",
  gap: "10px",
  border: "1px solid #000000",
  borderRadius: "50px",
  height: "30px",
  minWidth: "200px",
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    display: "flex",
    alignItems: "center",
    paddingRight: "12px !important",
    paddingLeft: "32px !important"
  },
  "& .MuiSelect-icon": {
    top: "12px !important",
    position: "absolute",
    left: "15px",
    right: "auto"
  }
};
export const menuTextStyle = {
  fontFamily: "MontserratMedium",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "130%",
  textAlign: "left"
};
export const menuTextStylearabic = {
  fontFamily: "TajawalMedium",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "17px",
  lineHeight: "130%",
  direction: "rtl"
};
