import React, { useMemo } from "react";
import { useMediaQuery } from "@mui/material";

import ButtonGroup from "Components/ButtonGroup";

export const ButtonGroupContainer = ({
  widget,
  activeButton,
  setActiveButton,
  months,
  monthsRecord,
  IdsAndCountsData,
  page,
  currentMonthId,
  setCurrentMonthId
}) => {
  const { color_theme, data = [] } = widget;
  const tabletMax = useMediaQuery("(max-width:900px)");
  const { color_definition } = color_theme || {};

  //The monthRecordCount variable, calculated using the useMemo hook, retrieves the RecordCount property of an item in the
  //IdsAndCountsData array where the CategoryID is "all," and it is optimized to recalculate only when the IdsAndCountsData array changes.

  const monthRecordCount = useMemo(() => {
    return IdsAndCountsData?.find((item) => item?.CategoryID === "all")
      ?.RecordCount;
  }, [IdsAndCountsData]);

  //The IdsAndCountsDataMapped variable, computed using the useMemo hook, maps and transforms the items in the IdsAndCountsData
  // array into new objects with specific properties, and this transformation is optimized to recalculate only when the IdsAndCountsData array changes.

  const IdsAndCountsDataMapped = useMemo(() => {
    return IdsAndCountsData?.map((item) => {
      return {
        id: item?.CategoryID,
        RecordCount: item?.RecordCount
      };
    });
  }, [IdsAndCountsData]);
  return (
    <ButtonGroup
      activeButton={activeButton}
      setActiveButton={setActiveButton}
      currentMonthId={currentMonthId}
      setCurrentMonthId={setCurrentMonthId}
      color_definition={color_definition}
      buttonFilter={months ? monthsRecord : IdsAndCountsDataMapped}
      data={data}
      marginbottom={tabletMax ? "24px" : "32px"}
      margintp="48px"
      months={months}
      monthRecordCount={monthRecordCount}
      page={page}
    />
  );
};
