import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader,Marker } from "@react-google-maps/api";
import StylesLight from "./MapStyle";

const containerStyle = {
  width: "100%",
  height: "500px",
  borderRadius: "4px"
};

const center = {
  lat: 22.745,
  lng: 54.523
};

 

const MapComponent = ({ locations }) => {
  const mappedLocations = locations?.map((item) => {
    return {
      lat: Number(item?.lat),
      lng: Number(item?.long)
    };
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCDutRwaHnP8Jqm6idFld0hX1GdPROT_2U",
    language: "en"
  });
  const [map, setMap] = useState(null);

  const onLoad = useCallback((map) => {
    map.setZoom(6);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ mapTypeControl: false, styles: StylesLight }}
      >
        {mappedLocations?.map((location, index) => {
          const svgString = `
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
         
            <circle cx="17" cy="17" r="17" fill="black"/>
            <text x="50%" y="54%"  dominant-baseline="middle" text-anchor="middle" fill="white" font-size="14"  font-family="sans-serif" >${index+1}</text>
          </svg>
          `;
           const svgUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
          const markerOptions = {
            position: location,
            icon: {
              url: svgUrl,
              fillColor: "black",
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 1,
            },
          };
          return (
            <Marker key={index} position={location} options={markerOptions} />
          );
        })}
      </GoogleMap>
    )
  );
};

export default React.memo(MapComponent);
