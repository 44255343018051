import { useState, useEffect, useRef } from "react";
import { Grid, CircularProgress, Box } from "@mui/material";
import Item from "./item";
import { useTranslation } from "react-i18next";
import { useGetEditorials } from "services/editorials/editorials.query";
import { categoryListFilteredHeightControl } from "GlobalProcess/GlobalFunction";
const CategoryListFilteredManual = ({
  CategoryType,
  ButtonText,
  activeButton,
  marginTpBt,
  padSet,
  size,
  spacing,
  color_theme,
  web_message_title,
  web_message,
  paginationData
}) => {
  const PAGINATION_LIMIT = 12;
  const { t, i18n } = useTranslation();
  const skipIndex = useRef(0);
  const [firstTouch, setFirstTouch] = useState(true);
  const { color_definition } = color_theme || {};
  const [buttonApperance, setButtonApperance] = useState(false);
  const totalRecords = useRef(0);
  const [activeButtonFilter, setActiveButtonFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const postList = useRef([]);
  const [laodingFlag, setLaodingFlag] = useState(true);
  const { refetch } = useGetEditorials();
  //The filterCategory function is responsible for filtering items based on the activeButton, which typically represents a category or filter applied to a list of items. Here's a concise summary of this function:
  //The function sets the activeButton as the active filter. If the activeButton is 'all,' it includes all items. Otherwise, it filters the items to include only those with the primary_category_id matching the activeButton. The filtered items are then updated in the postList.current array, and the totalRecords.current is updated to reflect the total number of items after filtering.
  //In summary, this function filters and updates the list of items based on the selected category or filter (the activeButton parameter).

  const filterCategory = (activeButton) => {
    setActiveButtonFilter(activeButton);
    if (activeButton === "all") {
      postList.current = paginationData.items;
      totalRecords.current = paginationData.total_items;
    } else {
     
      const items = paginationData.items.filter(
        (item) => item.primary_category_id === activeButton
      );

      postList.current = items;
      totalRecords.current = items.length;
    }
    setTimeout(() => {
      setLaodingFlag(false);
    }, 1000);
  };

  useEffect(() => {
    if (paginationData) {
      if (firstTouch) {
        setLaodingFlag(false);
        setFirstTouch(false);
       
        postList.current = paginationData.items;
        totalRecords.current = paginationData.total_items;
        skipIndex.current = skipIndex.current + PAGINATION_LIMIT;

        if (totalRecords.current > PAGINATION_LIMIT) {
          setButtonApperance(true);
        } else {
          setButtonApperance(false);
        }
      }
    }
  }, [firstTouch, paginationData, postList]);

  useEffect(() => {
    if (activeButtonFilter !== activeButton) {
      setButtonApperance(false);
      postList.current = [];
      skipIndex.current = 0;
      setLaodingFlag(true);
      filterCategory(activeButton);
    }
  }, [activeButton]);

  //   The handleChange function manages the loading of additional data for a paginated list. Here's a concise summary of its functionality:
  // It sets the Loading state to `true to indicate that data loading is in progress.
  // It increments the skipIndex by PAGINATION_LIMIT, which is used to skip a certain number of items when fetching data in a paginated manner.
  // It creates a temporary copy of the current list of items stored in postList.current.
  // It performs a data refetch operation, which may involve fetching more items from a data source.
  // It updates the isLoading state to reflect the loading status.
  // If the data fetch is successful (isSuccess), it introduces a brief delay (e.g., 600 milliseconds) to simulate a loading process.
  // After the delay, it sets the Loading state to `false to indicate that data loading is complete.
  // It retrieves the new data, typically a widget with items, from the fetched data and assigns it to updateList.
  // It updates the postList.current array by merging the old items from tempList with the newly fetched items in updateList.
  // Finally, it updates the isLoading state again to reflect the loading status.
  // In summary, the function handles the process of loading additional data for a paginated list by fetching more items and updating the list accordingly.

  const handleChange = async () => {
    setLoading(true);

    skipIndex.current = skipIndex.current + PAGINATION_LIMIT;
    const tempList = [...postList.current];
    const { isSuccess, data, isLoading } = await refetch();
 
    setLaodingFlag(true);

    if (isSuccess) {
      setTimeout(() => {
        setLaodingFlag(!isLoading);
        const widget = data?.widgets.find(
          (w) => w.component_type === "mini-navigation"
        );
        const updateList = [...widget?.items];

        postList.current = [...tempList, ...updateList];
      }, 1000);

      setLaodingFlag(!isLoading);
    }
  };
  return (
    <>
      <Grid
        container
        spacing={(laodingFlag || postList?.current?.length>=0) && (spacing || "42.67px")}
        style={{
          paddingBottom: marginTpBt || "32px",
          marginTop: "0px",
          minHeight: "100%",
          alignContent: "center",
          direction: i18n.language === "arabic" ? "rtl" : "",
          paddingTop: marginTpBt || "32px",
          backgroundColor: color_definition?.background_color || "#FFFFFF"
        }}
        sx={{
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" },
          height: "100%"
        }}
      >
        <>
          {postList.current.length !== 0 && !laodingFlag ? (
            postList.current?.map((category) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={size || 3}
                key={category.link}
                sx={{
                  paddingTop: "8px !important"
                }}
              >
                <Item
                  card={category}
                  padSet={padSet}
                  color_definition={color_definition}
                  data_source={CategoryType}
                />
              </Grid>
            ))
          ) : laodingFlag ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width:"100%",
                height: categoryListFilteredHeightControl(
                  postList.current.length,
                  laodingFlag
                )
              }}
            >
              <CircularProgress
                size={"25px"}
                style={{
                  color: "black"
                }}
              />
            </Box>
          ) : (
            <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "24px"
            }}
          >
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium "
                    : "MontserratSemiBold",
                direction: i18n.language === "arabic" ? "rtl" : "ltr",
                fontSize:
                  i18n.language === "arabic"
                    ? { xs: "19px", lg: "23px" }
                    : { xs: "16px", md: "18px", lg: "20px" }
              }}
              style={{
                textAlign:"center",
                fontWeight:"600",
                lineHeight:"26px",
                fontFamily: "MontserratSemiBold"
              }}
            >
              {web_message_title}
            </Box>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular "
                    : "MontserratRegular",
                direction: i18n.language === "arabic" ? "rtl" : "ltr",
                fontSize:
                  i18n.language === "arabic"
                    ? { xs: "19px", lg: "23px" }
                    : { xs: "16px", md: "18px", lg: "20px" }
              }}
              style={{
                textAlign:"center",
                fontSize:"16px",
                fontWeight:"400",
                lineHeight:"20.8px",
                color:"#5A5F64"
              }}
            >
              {web_message}
            </Box>
          </Box>
          )}
        </>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {skipIndex.current < totalRecords.current &&
            totalRecords.current >= PAGINATION_LIMIT &&
            buttonApperance && (
              <button
                style={{
                  marginTop: "10px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalMedium"
                      : "MontserratMedium",
                  fontSize: i18n.language === "arabic" ? "15px" : "12px",
                  display: "flex",
                  minWidth: "160px",
                  padding: "10px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "34px",
                  cursor: "pointer",
                  color: color_definition?.cta_button_text
                    ? color_definition?.cta_button_text
                    : "var(--primary-black, #000)",
                  backgroundColor: color_definition?.cta_button_fill
                    ? color_definition?.cta_button_fill
                    : "white",
                  border: color_definition?.cta_button_border
                    ? `1px solid ${color_definition?.cta_button_border}`
                    : " 1px solid var(--greys-grey-01, #5A5F64)",
                  position: "relative"
                }}
                onClick={handleChange}
              >
                {loading && (
                  <CircularProgress
                    size={"16px"}
                    style={{
                      color: "black",
                      position: "absolute",
                      left: i18n.language === "arabic" ? "auto" : "10px",
                      right: i18n.language === "arabic" ? "10px" : "auto"
                    }}
                  />
                )}
                {ButtonText !== undefined ? ButtonText : t("Load more")}
              </button>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryListFilteredManual;
