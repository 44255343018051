import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUserStore } from "./useUser";
import {
  getInfoFromToken,
  getToken,
  onRemovePrivateToken,
  onSetPrivateToken
} from "utils/authStorage";
import { useRefreshToken } from "services/auth/auth.query";

// It checks the user's authentication status by looking for a valid token.
// If a token exists, it decodes the token to retrieve its expiration time.
// It calculates whether the user's session has expired based on the token's expiration time and the current time.
// If the session is expired, it attempts to refresh the user's token by making an asynchronous request to a token refresh endpoint.
// If the token refresh is successful, the new token is securely stored.
// If there's an error during token refresh, the user is logged out by removing their user data.
// The component is triggered when it's mounted and also when the URL location changes, allowing it to continually monitor and maintain user authentication.

const VerifyAuth = () => {
  const location = useLocation();
  const { removeUser } = useUserStore();
  const { mutateAsync: refreshToken } = useRefreshToken({
    onSuccess: () => {},
    onError: () => {}
  });

  useEffect(() => {
    const existingToken = getToken();

    if (existingToken) {
      const decodedJwt = getInfoFromToken(existingToken);
      const isSessionExpired = decodedJwt.exp * 1000 < Date.now();

      const callRefreshToken = async () => {
        try {
          onRemovePrivateToken();
          const { token: newToken } = await refreshToken({
            token: existingToken
          });
          onSetPrivateToken(newToken);
        } catch (error) {
          removeUser();
        }
      };

      if (isSessionExpired) {
        callRefreshToken();
      }
    }
  }, [location]);

  return null;
};

export default VerifyAuth;
