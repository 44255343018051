import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phoneNumber: "",
  phoneCode: "971",
  otp: "",
  routeCntrl: []
};

const allianceSlice = createSlice({
  name: "alliance",
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setPhoneCode: (state, action) => {
      state.phoneCode = action.payload;
    },
    setOtpCode: (state, action) => {
      state.otp = action.payload;
    },
    setRouteCntrl: (state, action) => {
      state.routeCntrl = [...state.routeCntrl, action.payload];
    },
    clearRouteCntrl: (state) => {
      state.routeCntrl = [];
    }
  }
});

export const {
  setPhoneNumber,
  setPhoneCode,
  setOtpCode,
  setRouteCntrl,
  clearRouteCntrl
} = allianceSlice.actions;
export default allianceSlice.reducer;
