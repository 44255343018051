import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import dayjs from "dayjs";
import { useTranslation, withTranslation } from "react-i18next";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { usePatchPreferredLanguage } from "services/updatePreferredLanguage/updatePreferredLanguage.query";
import SimpleAccordion from "Assets/CustomComponents/AccordionMenu";
import PositionedMenu from "Assets/CustomComponents/PositionedMenu";
import { selectLanguage } from "store/languageSlice";
import { setSelectedLanguageCodeToStorage } from "utils/setSelectedLanguageCodeToStorage";
import LazyImage from "Components/LazyLoad/LazyImage";
import { useUserIsLoggedIn } from "hooks/useUser";
require("dayjs/locale/ar");
require("dayjs/locale/en");

const Footer = ({
  userData,
  logo,
  filterContents = {},
  languageSelector = {},
  socialMedias = {},
  sublinksNavigation = {},
  footerLinks = {},
  color_theme,
  setUpdateLanguage
}) => {
  const { mutateAsync: setPreferred } = usePatchPreferredLanguage({
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {}
  });
  const isLoggedIn = useUserIsLoggedIn();
  const languageMapped = {
    en: "en",
    arabic: "ar"
  };
  const { i18n } = useTranslation();
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const { background_color, normal_link, hover_link, main_nav_hover } =
    color_theme?.color_definition || {};

  const headText = {
    fontFamily: "ArchivoBlack",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.2em",
    textTransform: "uppercase",
    color: normal_link || "#90969e",
    marginBottom: "24px",
    textAlign: "left",
    height: "100%",
    textDecoration: "none"
  };
  const text = {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "130%",
    color: normal_link || "#FFFFFF",
    marginBottom: "12px",
    textAlign: "left",
    height: "100%",
    textDecoration: "none"
  };
  const text2 = {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "130%",
    color: normal_link || "#FFFFFF",
    textAlign: "left",
    height: "100%",
    textDecoration: "none"
  };
  const headTextArabic = {
    fontFamily: "TajawalBold",
    fontSize: "14px",
    lineHeight: "110%",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    marginBottom: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    textAlign: "right",
    color: normal_link || "#90969E",
    height: "100%",
    textDecoration: "none"
  };
  const textArabic = {
    fontFamily: "TajawalRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "17px",
    lineHeight: "130%",
    color: normal_link || "#FFFFFF",
    marginBottom: "12px",
    textAlign: "right",
    height: "100%",
    textDecoration: "none"
  };
  const textArabic2 = {
    fontFamily: "TajawalRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "17px",
    lineHeight: "130%",
    color: normal_link || "#FFFFFF",
    textAlign: "right",
    height: "100%",
    textDecoration: "none"
  };

  //the function forces a page reload using window.location.reload() to apply the language changes instantly.
  //In summary, this code allows users to switch the language of the application's user interface. Upon selecting a new language,
  // it updates the UI, saves the language choice in the application's state and storage for future sessions, and immediately
  //reloads the page to reflect the language change.

  const onLanguageChanged = (event) => {
    setUpdateLanguage(true);
    const language = event.currentTarget.value;
    dispatch(selectLanguage(language));
    i18n.changeLanguage(language);
    setSelectedLanguageCodeToStorage(language);
    if (isLoggedIn) {
      setPreferred({ preferred_language: languageMapped[language] });
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  useEffect(() => {
    dayjs.locale(i18n.language === "arabic" ? "ar" : "en");
    return () => {};
  }, [i18n.language]);

  return (
    <Box
      className="footer-main"
      style={{ backgroundColor: background_color || "" }}
      sx={{
        height: !expand
          ? { xs: "1020px", lg: "440px" }
          : { xs: "1300px", lg: "440px" }
      }}
    >
      <Box
        sx={{
          width: {
            xs: "calc(100% - 40px )",
            lg: "calc(100% - 22.22% )"
          },
          paddingLeft: { xs: "20px", lg: "11.11%" },
          paddingRight: { xs: "20px", lg: "11.11%" },
          maxWidth: { xs: "1120px", xl: "100%" },
          height: 207,
          marginTop: "80px",
          display: { xs: "none", lg: "flex" },
          justifyContent: "space-between",
          flexDirection: i18n.language === "arabic" ? "row-reverse" : "row"
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: i18n.language === "arabic" ? 130 : 150,
            marginRight: "0px",
            justifyContent:
              i18n.language === "arabic" ? "flex-end" : "flex-start",
            alignItems: "flex-start",
            display: "flex"
          }}
        >
          <LazyImage
            placeholderSrc={
              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
            src={logo}
            alt="UnionWhite"
            lazyclass="lazy"
          />
        </Box>
        {footerLinks?.links?.map((footerLink) => {
          return (
            <Box
              key={footerLink.id}
              sx={{ width: "100%", maxWidth: 300, marginRight: "20px" }}
            >
              <List sx={{ paddingTop: "0px" }}>
                <ListItem
                  disablePadding
                  sx={{
                    justifyContent:
                      i18n.language === "arabic" ? "flex-end" : "flex-start"
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      style:
                        i18n.language === "arabic" ? headTextArabic : headText
                    }}
                    primary={footerLink.title || ""}
                    sx={{
                      paddingLeft: "2px",
                      paddingRight: "2px",
                      marginTop: "0px"
                    }}
                  />
                </ListItem>
                {footerLink.items?.map((footerLinkItem, index) => (
                  <ListItem
                    disablePadding
                    key={index}
                    sx={{
                      justifyContent:
                        i18n.language === "arabic" ? "flex-end" : "flex-start"
                    }}
                  >
                    <HashLink
                      style={{ textDecoration: "none" }}
                      smooth
                      to={footerLinkItem.link}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: i18n.language === "arabic" ? textArabic : text
                        }}
                        primary={footerLinkItem.title}
                        sx={{ paddingLeft: "2px", paddingRight: "2px" }}
                      />
                    </HashLink>
                  </ListItem>
                ))}
              </List>
            </Box>
          );
        })}
        <Box sx={{ width: "100%", maxWidth: 300, marginRight: "0px" }}>
          <PositionedMenu
            languageSelector={languageSelector}
            onLanguageChanged={onLanguageChanged}
            userData={userData}
            socialMedias={socialMedias}
            filterContents={filterContents}
            hover_link={hover_link}
            main_nav_hover={main_nav_hover}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: "calc(100% - 40px )",
            lg: "calc(100% - 22.22% )"
          },
          maxWidth: { xs: "1120px", xl: "100%" },
          paddingLeft: { xs: "20px", lg: "11.11%" },
          paddingRight: { xs: "20px", lg: "11.11%" },
          height: 100,
          display: { xs: "none", lg: "flex" },
          flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "428px",
            display: "flex",
            flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: "30px"
          }}
        >
          {sublinksNavigation?.items?.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  direction: i18n.language === "arabic" ? "RTL" : "LTR"
                }}
              >
                {item.link ? (
                  <Link
                    style={i18n.language === "arabic" ? textArabic : text}
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                ) : (
                  <Box style={i18n.language === "arabic" ? textArabic2 : text2}>
                    {item.title}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "100%",
            textAlign: i18n.language === "arabic" ? "left" : "right",
            direction: i18n.language === "arabic" ? "RTL" : "LTR"
          }}
        >
          <LazyImage
            placeholderSrc={
              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
            src={sublinksNavigation?.logo}
            width={148}
            height={26}
            alt="ATG_LOGO"
            lazyclass="lazy"
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: "calc(100% - 40px )",
            md: "calc(100% - 22.22% )",
            lg: "calc(100% - 320px )",
            xl: "calc(100% - 22.22% )"
          },
          marginLeft: { xs: "20px", md: "11.11%", lg: "160px", xl: "11.11%" },
          marginRight: { xs: "20px", md: "11.11%", lg: "160px", xl: "11.11%" },
          height: 207,
          marginTop: "32px",
          display: { xs: "flex", lg: "none" },
          flexDirection: "column",
          justifyContent: "flex-start",
          direction: i18n.language === "arabic" ? "RTL" : "LTR"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
            marginTop: "0px",
            direction: i18n.language === "arabic" ? "RTL" : "LTR"
          }}
        >
          <LazyImage
            placeholderSrc={
              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
            src={logo}
            alt="UnionWhite"
            lazyclass="lazy"
          />
        </Box>

        <SimpleAccordion
          footerLinks={footerLinks}
          expand={expand}
          setExpand={setExpand}
          background_color={background_color}
          normal_link={normal_link}
        />
        <PositionedMenu
          socialMedias={socialMedias}
          languageSelector={languageSelector}
          onLanguageChanged={onLanguageChanged}
          userData={userData}
          filterContents={filterContents}
          hover_link={hover_link}
          main_nav_hover={main_nav_hover}
        />
        <Box sx={{ width: "100%", textAlign: "center", marginTop: "25px" }}>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {sublinksNavigation?.items?.slice(0, 1).map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: "contents" }}>
                {item.link ? (
                  <Link
                    style={i18n.language === "arabic" ? textArabic : text}
                    to={item.link}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: i18n.language === "arabic" ? textArabic : text
                      }}
                      primary={item.title}
                      sx={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    />
                  </Link>
                ) : (
                  <Box style={i18n.language === "arabic" ? textArabic : text}>
                    <ListItemText
                      primaryTypographyProps={{
                        style: i18n.language === "arabic" ? textArabic : text
                      }}
                      primary={item.title}
                      sx={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    />
                  </Box>
                )}
              </ListItem>
            ))}
          </List>
          <List
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {sublinksNavigation?.items?.slice(1).map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: "contents" }}>
                {item.link ? (
                  <Link
                    style={i18n.language === "arabic" ? textArabic : text}
                    to={item.link}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: i18n.language === "arabic" ? textArabic : text
                      }}
                      primary={item.title}
                      sx={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    />
                  </Link>
                ) : (
                  <Box style={i18n.language === "arabic" ? textArabic : text}>
                    <ListItemText
                      primaryTypographyProps={{
                        style: i18n.language === "arabic" ? textArabic : text
                      }}
                      primary={item.title}
                      sx={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    />
                  </Box>
                )}
              </ListItem>
            ))}
          </List>
          <Box sx={{ marginTop: "16px" }}>
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              src={sublinksNavigation?.logo}
              width={148}
              height={26}
              alt="ATG_LOGO"
              lazyclass="lazy"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default withTranslation()(Footer);
