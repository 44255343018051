import { useState, useEffect, useCallback, useMemo } from "react";
import Layout from "../Layout";
import CategoryListFiltered from "Components/CategoryListFiltered";
import CategoryListFilteredManual from "Components/CategoryListFilteredManual";
import { useGetEditorials } from "services/editorials/editorials.query";
import LightBackground from "Components/LightBackground";
import { LightBackgroundControl } from "GlobalProcess/GlobalFunction";
import { EDITORIALS_COMPONENT_MAP_CMS_COMPONENT_MAP } from "CmsComponents";
import { DataSourceEnums, ContentTypesEnums } from "utils/enums";
import { useGetRecordIdsAndTypes } from "services/brands/brands.query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Editorial = ({ scrollCntrl, setScrollCntrl }) => {
  const { data: EditorialsContent = {} } = useGetEditorials();
  const [index, setIndex] = useState(12);
  const [activeButton, setActiveButton] = useState("all");
  const [hidden, setHidden] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { widgets = [],seo_data } = EditorialsContent;
  const componentDetails = EditorialsContent?.widgets?.find(
    (item) => item?.component_type === "mini-navigation"
  );
  const EditorialsLoadContentColorTheme = componentDetails?.color_theme || {};
  debugger
  const data_source = componentDetails?.data_source;
  const {
    data: IdsAndCountsData,
    isLoading,
    isSuccess
  } = useGetRecordIdsAndTypes({
    //data_source: DataSourceEnums?.[data_source] || DataSourceEnums.EDITORIAL - AMBR-3694
    data_source: DataSourceEnums.EDITORIAL
  });

  useEffect(() => {
    if (activeButton !== "all") {
      setHidden(true);
    } else setHidden(false);
  }, [activeButton, t]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollCntrl(false);
  }, [scrollCntrl, setScrollCntrl]);

  // getButtonFilter (using useCallback):
  // It calculates uniqueCategories based on the IdsAndCountsData array.
  // It checks for each item in IdsAndCountsData if the CategoryID property exists and the RecordCount property is greater than 0.
  // The result is an array of boolean values, where each element corresponds to an item in IdsAndCountsData, indicating whether it meets the conditions.

  // emptyDataMessaging (using useMemo):
  // It finds an item in the widgets array where the component_type is "empty-data-messaging."
  // The result is the found item or undefined.

  // carouselCount (using useMemo):
  // It calculates carouselCount based on the widgets array.
  // It checks if there is at least one item in the items array of a CAROUSEL component.
  // The result is a boolean value, indicating whether there are items in the carousel.

  const getButtonFilter = useCallback(() => {
    const uniqueCategories = IdsAndCountsData?.map(
      (item) => item?.CategoryID && item?.RecordCount > 0
    );
    return uniqueCategories;
  }, [IdsAndCountsData]);

  const emptyDataMessaging = useMemo(() => {
    return widgets?.find(
      (item) => item?.component_type === "empty-data-messaging"
    );
  }, [widgets]);

  const carouselCount = useMemo(() => {
    return (
      widgets?.find(
        (item) => item?.component_type === ContentTypesEnums.CAROUSEL
      )?.items?.length > 0
    );
  }, [widgets]);

  useEffect(() => {
    if (isSuccess) {
      getButtonFilter();
    }
  }, [getButtonFilter, isSuccess]);

  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  return (
    <>
      <Layout seo_data={seo_data}>
        {!LightBackgroundControl(widgets) && (
          <LightBackground
            text={EditorialsContent?.title}
            paddingTp="0px"
            xsMinHeight="200px"
            minheight="320px"
            containerStyle={{
              justifyContent: "flex-start"
            }}
          />
        )}

        {widgets?.map((widget) => {
          const CmsComponent =
            EDITORIALS_COMPONENT_MAP_CMS_COMPONENT_MAP[
              widget["component_type"]
            ];

          if (CmsComponent) {
            return (
              <CmsComponent
                key={widget?.component_type}
                widget={widget}
                activeButton={activeButton}
                setActiveButton={setActiveButton}
                index={index}
                setIndex={setIndex}
                buttonFilter={getButtonFilter()}
                hidden={hidden}
                CategoryType={DataSourceEnums?.[data_source]}
                IdsAndCountsData={IdsAndCountsData}
                page="editorial"
              />
            );
          }

          return null;
        })}

        {data_source === DataSourceEnums.MANUAL ? (
          <CategoryListFilteredManual
            CategoryType={DataSourceEnums.MANUAL}
            activeButton={activeButton}
            ButtonText={componentDetails?.cta_button.title}
            color_theme={EditorialsLoadContentColorTheme}
            isLoading={isLoading}
            size={3}
            web_message={!carouselCount && emptyDataMessaging?.web_message}
            web_message_title={!carouselCount && emptyDataMessaging?.title}
            paginationData={{
              items: componentDetails?.items,
              total_items: componentDetails?.items?.length
            }}
          />
        ) : (
          <CategoryListFiltered
            CategoryType={
              DataSourceEnums?.[data_source] || DataSourceEnums.EDITORIAL
            }
            activeButton={activeButton}
            ButtonText={`${componentDetails?.cta_button.title}`}
            color_theme={EditorialsLoadContentColorTheme}
            isLoading={isLoading}
            size={3}
            web_message={!carouselCount && emptyDataMessaging?.web_message}
            web_message_title={!carouselCount && emptyDataMessaging?.title}
          />
        )}
      </Layout>
    </>
  );
};

export default Editorial;
