import React from "react";
import BrandItemCarousel from "Components/EditorialDetail/BrandItemCarousel";

export const EditorialBrandItemCarouselContainer = ({
  widget,
  ui_properties,
  editorial_brand_carousel
}) => {
  return (
    <BrandItemCarousel
      widget={widget}
      ui_properties={ui_properties}
      editorial_brand_carousel={editorial_brand_carousel}
    />
  );
};
