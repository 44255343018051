import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import WprightPage from "Components/Authanticate/WprightPage";
import HistoryButton from "Components/HistoryButton";
import MobileNumberForm from "Components/MobileNumberForm/MobileNumberForm";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  clearRouteCntrl,
  resetState,
  setIsoCode,
  setPhoneCode,
  setPhoneNumber,
  setRouteCntrl
} from "store/authanticateSlice";
import { usePostPhoneEntry } from "services/auth/auth.query";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";
import { useDataLayer } from "hooks/useDataLayer";

const PhoneNumber = () => {
  const referalCode = useLocation()?.state?.referalCode;
  const { setSignUpEvent } = useDataLayer();

  let navigate = useNavigate();
  const { i18n } = useTranslation();
  const path = useSelector((state) => state.path);
  const { isExternal, externalAppType, externalDeviceType } = useSelector(
    (state) => state.authanticate
  );
  console.log(isExternal);
  const dispatch = useDispatch();
  const mobileSize = useMediaQuery("(max-width:900px)");
  const {
    mutateAsync: postPhoneEntry,
    isLoading,
    error
  } = usePostPhoneEntry({
    onSuccess: () => {},
    onError: (error) => {
      console.log(error.response);
    }
  });

  // handleSubmit:It takes an object with three properties as its argument: code, phone, and iso_code.
  // It constructs a parsedPhone variable by concatenating code and phone, then removing any dashes or spaces from the resulting string. This prepares the phone number for sending as a parameter in an API request.
  // It makes an API request using the postPhoneEntry function. The request includes the iso_code and the parsed phone number. The action is set to "register."
  // If the API request is successful (the .then block is executed), it performs the following actions:
  // Dispatches actions to set the user's phone number (phone), phone code (code), and ISO code (iso_code) into the application's state.
  // Defines a new route for the next step, setting phone to true.
  // Sets the signUpEvent to 'enter_phone' (likely for tracking or logging purposes).
  // Navigates to the '/sign-up/phone-verification' route and passes some data in the route's state, including the validation key from the API response and possibly a referalCode.

  const handleSubmit = ({ code, phone, iso_code }) => {
    const parsedPhone = (code + phone).replace(/-/g, "").replace(/ /g, "");

    postPhoneEntry({ iso_code, phone: parsedPhone, action: "register" }).then(
      (response) => {
        dispatch(setPhoneNumber(phone));
        dispatch(setPhoneCode(code));
        dispatch(setIsoCode(iso_code));
        const newRoute = { phone: true };
        dispatch(setRouteCntrl(newRoute));
        setSignUpEvent("enter_phone");
        navigate(`/sign-up/phone-verification`, {
          state: { validation_key: response.validation_key, referalCode }
        });
      }
    );
  };

  useEffect(() => {
    dispatch(clearRouteCntrl());
    dispatch(resetState(isExternal));
    localStorage.removeItem("alliance-control");
  }, [dispatch]);

  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};

  return (
    <div className="WPmain">
      <div
        className="WPleftpage"
        style={{
          direction: i18n.language === "arabic" ? "rtl" : "ltr",
          backgroundColor: background || "#FFFFFF"
        }}
      >
        <HistoryButton
          referalCode={referalCode}
          image="return"
          Left={i18n.language === "arabic" ? "auto" : 25}
          Top={25}
          Right={i18n.language === "arabic" ? 25 : "auto"}
          path={
            !isExternal ? `/sign-up` : `/sign-up/external/${externalAppType}`
          }
          search={`?deviceType=${externalDeviceType || "ios"}`}
        />
        {!isExternal && (
          <HistoryButton
            referalCode={referalCode}
            Right={i18n.language === "arabic" ? "auto" : 25}
            Top={25}
            Left={i18n.language === "arabic" ? 25 : "auto"}
            path={path}
          />
        )}
        <div className="left-page-form">
          <MobileNumberForm
            showLoginLink={!isExternal}
            errorCode={error?.response?.data?.error_code}
            errorResponse={error?.response?.data?.message}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            infoText={
              "We’ll send a code to connect your number to your Amber account."
            }
            style={{ maxWidth: mobileSize ? "100%" : "288px" }}
          />
        </div>
      </div>
      <WprightPage />
    </div>
  );
};

export default PhoneNumber;
