import { CarousalSliderSwiperContainer } from "./CarousalSliderSwiperContainer";
import DynamicAdsContainer from "./DynamicAdsContainer";
import { CmsInfoList } from "./Infolist";
import { VewAllBrandsContainer } from "./ViewAllBrands";
import { CarousalSublistContainer } from "./CarousalSublistContainer";
import AmberDynamic from "./AmberDynamic";
import AmberMessageCta from "./AmberMessageCta";
import AmberFaq from "./AmberFaq";
import { AmberStatusContainer } from "./AmberStatusContainer";
import { AmberTierMatrixContainer } from "./AmberTierMatrixContainer";
import AmberHeadContainer from "./AmberHeadContainer";
import { AmberCaroselSublistContainer } from "./AmberCaroselSublistContainer";
import FeaturedItemContainer from "./FeaturedItemContainer";
import { ButtonGroupContainer } from "./ButtonGroupContainer";
import { NavBarContainer } from "Pages/NavBarContainer";
import { ContentTypesEnums } from "utils/enums";
import { EditorialMediaBlockContainer } from "./EditorialMediaBlockContainer";
import { EditorialBrandItemCarouselContainer } from "./EditorialBrandItemCarouselContainer";
import { EditorialContentDescriptionContainer } from "./EditorialContentDescriptionContainer";

//An object has been created according to the component_type of the widget coming from contentful. Which component is wanted to return from any service
// must be defined in the created object. Then, the page on which the service is running should be added as cms enum. Examples can be viewed on any page in the pages folder.

export const COMPONENT_KEY_MAP = Object.freeze({
  "web-homepage-slider": ContentTypesEnums.WEB_HOMEPAGE_SLIDER,
  "item-carousel": ContentTypesEnums.ITEM_CAROUSEL,
  "web-homepage-bullets": "web-homepage-bullets",
  "mini-navigation": "mini-navigation",
  "web-dynamic-ads": "web-dynamic-ads",
  "web-homepage-footer-navigation": "web-homepage-footer-navigation",
  "web-homepage-main-navigation": "web-homepage-main-navigation",
  "amber-dynamic-ads-var2": "amber-dynamic-ads-var2",
  "amber-message-cta": "amber-message-cta",
  "amber-faq": "amber-faq",
  "amber-status-estimator": "amber-status-estimator",
  "amber-tier-matrix": "amber-tier-matrix",
  "content-description": ContentTypesEnums.CONTENT_DESCRIPTION,
  "brand-item-carousel": "brand-item-carousel",
  "global-configuration": ContentTypesEnums.GLOBAL_CONFIGURATION,
  "featured-item": ContentTypesEnums.FEATURED_ITEM,
  carousel: ContentTypesEnums.CAROUSEL
});

export const CMS_COMPONENT_MAP = Object.freeze({
  [COMPONENT_KEY_MAP[ContentTypesEnums.WEB_HOMEPAGE_SLIDER]]:
    CarousalSliderSwiperContainer,
  [COMPONENT_KEY_MAP["web-homepage-bullets"]]: CmsInfoList,
  [COMPONENT_KEY_MAP["mini-navigation"]]: VewAllBrandsContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.ITEM_CAROUSEL]]:
    CarousalSublistContainer,
  [COMPONENT_KEY_MAP["web-dynamic-ads"]]: DynamicAdsContainer,
  [COMPONENT_KEY_MAP["amber-dynamic-ads-var2"]]: AmberDynamic,
  [COMPONENT_KEY_MAP["amber-message-cta"]]: AmberMessageCta,
  [COMPONENT_KEY_MAP["amber-faq"]]: AmberFaq,
  [COMPONENT_KEY_MAP["amber-status-estimator"]]: AmberStatusContainer,
  [COMPONENT_KEY_MAP["amber-tier-matrix"]]: AmberTierMatrixContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.CONTENT_DESCRIPTION]]:
    AmberHeadContainer,
  [COMPONENT_KEY_MAP["brand-item-carousel"]]: AmberCaroselSublistContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.GLOBAL_CONFIGURATION]]:
    ButtonGroupContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.FEATURED_ITEM]]: FeaturedItemContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.CAROUSEL]]: CarousalSublistContainer,
  [COMPONENT_KEY_MAP["web-homepage-main-navigation"]]: NavBarContainer
});
export const EVENTS_OFFERS_CMS_COMPONENT_MAP = Object.freeze({
  [COMPONENT_KEY_MAP[ContentTypesEnums.WEB_HOMEPAGE_SLIDER]]:
    CarousalSliderSwiperContainer,
  [COMPONENT_KEY_MAP["web-homepage-bullets"]]: CmsInfoList,
  [COMPONENT_KEY_MAP["mini-navigation"]]: VewAllBrandsContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.ITEM_CAROUSEL]]:
    CarousalSublistContainer,
  [COMPONENT_KEY_MAP["web-dynamic-ads"]]: DynamicAdsContainer,
  [COMPONENT_KEY_MAP["amber-dynamic-ads-var2"]]: AmberDynamic,
  [COMPONENT_KEY_MAP["amber-message-cta"]]: AmberMessageCta,
  [COMPONENT_KEY_MAP["amber-faq"]]: AmberFaq,
  [COMPONENT_KEY_MAP["amber-status-estimator"]]: AmberStatusContainer,
  [COMPONENT_KEY_MAP["amber-tier-matrix"]]: AmberTierMatrixContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.CONTENT_DESCRIPTION]]:
    AmberHeadContainer,
  [COMPONENT_KEY_MAP["brand-item-carousel"]]: AmberCaroselSublistContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.GLOBAL_CONFIGURATION]]:
    ButtonGroupContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.FEATURED_ITEM]]: FeaturedItemContainer,
  [COMPONENT_KEY_MAP[ContentTypesEnums.CAROUSEL]]: CarousalSublistContainer
});
export const ORIENTATION = Object.freeze({
  left: "LEFT",
  right: "RIGHT"
});

export const FOOTER_SECTION_MAP = Object.freeze({
  "footer-filter-contents": "footer-filter-contents",
  "footer-language-selector": "footer-language-selector",
  "footer-social-media-handles": "footer-social-media-handles",
  "footer-sublinks-navigation": "footer-sublinks-navigation",
  "main-footer-links": "main-footer-links"
});

export const BRAND_COMPONENT_MAP = Object.freeze({
  carousel: ContentTypesEnums.CAROUSEL,
  "item-carousel": ContentTypesEnums.ITEM_CAROUSEL,
  "global-configuration": ContentTypesEnums.GLOBAL_CONFIGURATION,
  "web-homepage-slider": ContentTypesEnums.WEB_HOMEPAGE_SLIDER,
  "web-homepage-main-navigation": "web-homepage-main-navigation"
});

export const BRAND_CMS_COMPONENT_MAP = Object.freeze({
  [BRAND_COMPONENT_MAP[ContentTypesEnums.ITEM_CAROUSEL]]:
    CarousalSublistContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.GLOBAL_CONFIGURATION]]:
    ButtonGroupContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.CAROUSEL]]: CarousalSublistContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.WEB_HOMEPAGE_SLIDER]]:
    CarousalSliderSwiperContainer,
  [COMPONENT_KEY_MAP["web-homepage-main-navigation"]]: NavBarContainer
});

export const EDITORIALS_COMPONENT_MAP = Object.freeze({
  carousel: ContentTypesEnums.CAROUSEL,
  "global-configuration": ContentTypesEnums.GLOBAL_CONFIGURATION,
  "web-homepage-slider": ContentTypesEnums.WEB_HOMEPAGE_SLIDER,
  "web-homepage-main-navigation": "web-homepage-main-navigation",
  "media-block": ContentTypesEnums.MEDIA_BLOCK,
  "brand-item-carousel": ContentTypesEnums.BRAND_ITEM_CAROUSEL
});

export const EDITORIALS_COMPONENT_MAP_CMS_COMPONENT_MAP = Object.freeze({
  [BRAND_COMPONENT_MAP[ContentTypesEnums.ITEM_CAROUSEL]]:
    CarousalSublistContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.GLOBAL_CONFIGURATION]]:
    ButtonGroupContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.CAROUSEL]]: CarousalSublistContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.WEB_HOMEPAGE_SLIDER]]:
    CarousalSliderSwiperContainer,
  [COMPONENT_KEY_MAP["web-homepage-main-navigation"]]: NavBarContainer
});

export const BRAND_DETAIL_COMPONENT_MAP = Object.freeze({
  "brand-item-carousel": "brand-item-carousel",
  "content-description": ContentTypesEnums.CONTENT_DESCRIPTION,
  "store-locations": ContentTypesEnums.STORE_LOCATIONS,
  "related-brand-articles": "related-brand-articles"
});

export const EVENT_DETAIL_COMPONENT_MAP = Object.freeze({
  "social-media-handles": "social-media-handles",
  "action-button": "action-button",
  schedule: ContentTypesEnums.SCHEDULE,
  "event-location": ContentTypesEnums.EVENT_LOCATION,
  "location-map": ContentTypesEnums.LOCATION_MAP,
  "related-brand-articles": "related-brand-articles",
  "content-description": ContentTypesEnums.CONTENT_DESCRIPTION,
  "amber-image": ContentTypesEnums.AMBER_IMAGE,
  "offers-content-page": "offers-content-page",
  "content-main-banner": ContentTypesEnums.CONTENT_MAIN_BANNER,
  "web-homepage-main-navigation": ContentTypesEnums.WEB_HOMEPAGE_MAIN_NAVIGATION
});

export const OFFER_DETAIL_COMPONENT_MAP = Object.freeze({
  "offers-content-page": "offers-content-page",
  "content-main-banner": ContentTypesEnums.CONTENT_MAIN_BANNER,
  "content-description": ContentTypesEnums.CONTENT_DESCRIPTION,
  carousel: ContentTypesEnums.CAROUSEL,
  "event-location": ContentTypesEnums.EVENT_LOCATION,
  "action-button": "action-button"
});

export const EVENTS_RESULTS_CMS_COMPONENT_MAP = Object.freeze({
  [COMPONENT_KEY_MAP[ContentTypesEnums.WEB_HOMEPAGE_SLIDER]]:
    CarousalSliderSwiperContainer,
  [COMPONENT_KEY_MAP["web-homepage-main-navigation"]]: NavBarContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.GLOBAL_CONFIGURATION]]:
    ButtonGroupContainer
});
export const OFFERS_RESULTS_CMS_COMPONENT_MAP = Object.freeze({
  [COMPONENT_KEY_MAP[ContentTypesEnums.WEB_HOMEPAGE_SLIDER]]:
    CarousalSliderSwiperContainer,
  [COMPONENT_KEY_MAP["web-homepage-main-navigation"]]: NavBarContainer,
  [BRAND_COMPONENT_MAP[ContentTypesEnums.GLOBAL_CONFIGURATION]]:
    ButtonGroupContainer
});
export const CMS_LANGUAGE_MAP = Object.freeze({
  EN: "en",
  AR: "arabic"
});

export const EDITORIAL_DETAILS_COMPONENT_MAP = Object.freeze({
  "media-block": ContentTypesEnums.MEDIA_BLOCK,
  "content-description": ContentTypesEnums.CONTENT_DESCRIPTION,
  carousel: ContentTypesEnums.CAROUSEL,
  "brand-item-carousel": ContentTypesEnums.BRAND_ITEM_CAROUSEL,
  "featured-item": ContentTypesEnums.FEATURED_ITEM,
  "related-brand-articles": "related-brand-articles"
});
export const EDITORIALS_DETAILS_COMPONENT_MAP_CMS_COMPONENT_MAP = Object.freeze(
  {
    [EDITORIAL_DETAILS_COMPONENT_MAP[ContentTypesEnums.CAROUSEL]]:
      CarousalSublistContainer,
    [EDITORIAL_DETAILS_COMPONENT_MAP[ContentTypesEnums.MEDIA_BLOCK]]:
      EditorialMediaBlockContainer,
    [EDITORIAL_DETAILS_COMPONENT_MAP[ContentTypesEnums.CONTENT_DESCRIPTION]]:
      EditorialContentDescriptionContainer,
    [EDITORIAL_DETAILS_COMPONENT_MAP[ContentTypesEnums.BRAND_ITEM_CAROUSEL]]:
      EditorialBrandItemCarouselContainer,
    [EDITORIAL_DETAILS_COMPONENT_MAP[ContentTypesEnums.FEATURED_ITEM]]:
      FeaturedItemContainer,
    [EDITORIAL_DETAILS_COMPONENT_MAP[ContentTypesEnums.RELATED_BRAND_ARTICLES]]:
      CarousalSublistContainer
  }
);
