import React, { useMemo } from "react";
import Layout from "../Layout";
import LightBackground from "Components/LightBackground";
import { useTranslation } from "react-i18next";
import { useGetFaq } from "services/faq/faq.query";
import PurchaseContainer from "../PurchaseHistory/PurchaseContainer";
import ContactUs from "Components/ContactUs";
import FaqQuestions from "Components/FaqQuestions";
const Faq = () => {
  const { data, isSuccess } = useGetFaq();
  const { i18n } = useTranslation();

  const alignContent = useMemo(() => {
    return i18n.language === "arabic" ? "right" : "left";
  }, [i18n.language]);
  return (
    <Layout className="brands">
      <PurchaseContainer>
        {isSuccess &&
          data?.items?.map((item) => (
            <>
              {item?.fields?.title && (
                <LightBackground
                  text={item?.fields?.title || ""}
                  xsMinHeight="200px"
                  textalign={alignContent}
                  paddingTp="0px"
                  minheight="320px"
                />
              )}
              <div
                 data-lang={i18n.language}
                id="faq"
              >
                <FaqQuestions widget={data?.widgets?.[0]} color_theme={data?.widgets?.[0]?.color_theme} />
              </div>
            </>
          ))}
        <ContactUs />
      </PurchaseContainer>
    </Layout>
  );
};

export default Faq;
