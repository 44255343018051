import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ style, ...props }) => {
  return (
    <Link
      style={{
        color: "initial",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        ...style
      }}
      {...props}
    />
  );
};

export default CustomLink;
