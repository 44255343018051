import { apiClient } from "../apiClient";
import { privateApiClient } from "../privateApiClient";

export const getHomeContent = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-home-content",
    customConfig: {
      params: {
        segment
      }
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("private-token")}`
    }
  }).then((res) => res.data);
};

export const getHowItWorksContent = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-how-it-works-content",
    customConfig: {
      params: {
        segment
      }
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("private-token")}`
    }
  }).then((res) => res.data);
};

export const getAmberAppcontent = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-amber-app-content",
    customConfig: {
      params: {
        segment
      }
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("private-token")}`
    }
  }).then((res) => res.data);
};

export const getOnBoardingContent = async () => {
  return apiClient({
    url: "/cms/fetch-on-boarding-content",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("private-token")}`
    }
  }).then((res) => res.data);
};
export const getOnAppConfigContent = async () => {
  return apiClient({
    url: "/cms/fetch-app-config-content",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("private-token")}`
    }
  }).then((res) => res.data);
};

export const getTierCards = async () => {
  return privateApiClient({
    url: "/cms/fetch-tier-cards-content",
    method: "GET"
  }).then((res) => res.data);
};
