import { Box, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LazyImage from "Components/LazyLoad/LazyImage";

export default function Item({ item, editorialText, color_theme }) {
  const { t, i18n } = useTranslation();
  const { description, title } = color_theme?.color_definition || {};

  // This code contains two functions:

  // alignMain: This function determines the alignment of the main content. If editorialText is true, it sets the alignment to "center." Otherwise, it checks the language (i18n.language) and sets the alignment to "flex-end" (for Arabic) or "flex-start" (for other languages).

  // imageCntrl: This function returns true if the language is Arabic and editorialText is true; otherwise, it returns false.

  const alignMain = () => {
    if (editorialText) {
      return "center";
    } else {
      return i18n.language === "arabic" ? "flex-end" : "flex-start";
    }
  };

  const imageCntrl = () => {
    return i18n.language === "arabic" && editorialText;
  };

  return (
    <Paper
      sx={{
        boxShadow: "none",
        textAlign: "left",
        width: "100%",
        position: "relative"
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {imageCntrl() ? (
          <a className="list-none" href={item.link}>
            <img
              src={item.arabicimage}
              alt={item.title}
              className="carousel-image"
            />
          </a>
        ) : (
          <a className="list-none" href={item.link}>
            {" "}
            <img src={item.image} alt={item.title} className="carousel-image" />
          </a>
        )}
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {imageCntrl() ? (
          <a className="list-none" href={item.link}>
            {" "}
            <img
              src={item.arabicmobileimage}
              alt={item.title}
              className="carousel-image carousel-image-mobile"
            />
          </a>
        ) : (
          <a className="list-none" href={item.link}>
            <img
              src={item.mobileimage}
              alt={item.title}
              className="carousel-image carousel-image-mobile"
            />
          </a>
        )}
      </Box>
      <Box
        className="carousel-item"
        sx={{
          display: "flex",
          flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
          height: 172,
          position: "absolute",
          alignItems: "center",
          justifyContent: editorialText && "center",
          width: {
            xs: "calc(100% - 48px )",
            sm: "calc(100% - 22.22% )"
          },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <a className="list-none" href={item.link}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: i18n.language === "arabic" ? "right" : "left",
              alignItems: alignMain
            }}
          >
            {item.headText && (
              <Box
                className="carousel-head"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "NotoNaskhArabicRegular "
                      : "PlayfairDisplay",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",

                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "60px", lg: "82px" }
                      : { xs: "44px", md: "57px", lg: "72px" },
                  maxWidth:
                    i18n.language === "arabic"
                      ? "100%"
                      : {
                          xs: "440px",
                          md: "570px",
                          lg:
                            window.location.pathname === "/HowItworks"
                              ? "685px"
                              : "655px"
                        }
                }}
                style={{ color: title || "#ffffff" }}
              >
                {item.headText}
              </Box>
            )}
            {editorialText && (
              <Box
                className="carousel-head"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "NotoNaskhArabicRegular "
                      : "PlayfairDisplay",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "50px", lg: "60px" }
                      : { xs: "44px", md: "54px" },
                  textAlign: "center",
                  maxWidth: { xs: "350px", sm: "720px" },
                  width: "100%"
                }}
              >
                {t("Billie Eilish unveils her exclusive new scent")}
              </Box>
            )}
            {item.subText && (
              <Box
                className="carousel-subtext"
                sx={{
                  maxWidth:
                    i18n.language === "arabic"
                      ? "100% !important"
                      : "547px !important",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "19px", lg: "23px" }
                      : { xs: "16px", md: "18px", lg: "20px" }
                }}
                style={{
                  color: description || "rgba(255, 255, 255, 0.7)"
                }}
              >
                {item.subText}
              </Box>
            )}
            {item.tinyText && (
              <Box
                className="carousel-subtext-tiny"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  fontSize:
                    i18n.language === "arabic" ? { xs: "15px" } : { xs: "12px" }
                }}
              >
                {t(item.tinyText)}
              </Box>
            )}

            <a
              className="list-none"
              href={item.subImageLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.subImage && (
                <Box
                  sx={{
                    display: { xs: "none", md: "block" }
                  }}
                >
                  <LazyImage
                    placeholderSrc={
                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    }
                    className="carousel-qr"
                    style={{
                      width: "140px",
                      height: "140px",
                      backgroundColor: "transparent"
                    }}
                    src={item.subImage}
                    alt="qr"
                    lazyclass="lazy"
                  />
                </Box>
              )}
              {item.subImageMobile && (
                <Box
                  className="carousel-qr"
                  sx={{
                    display: { xs: "flex", md: "none !important" },
                    borderRadius: "8px",
                    marginTop: "32px !important",
                    backgroundColor: "transparent"
                  }}
                >
                  <LazyImage
                    placeholderSrc={
                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    }
                    style={{
                      width: "140px",
                      height: "140px",
                      backgroundColor: "transparent"
                    }}
                    src={item.subImageMobile}
                    alt="qr-mobile"
                    lazyclass="lazy"
                  />
                </Box>
              )}
            </a>
          </Box>
        </a>
      </Box>
    </Paper>
  );
}
