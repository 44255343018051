import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(({ theme, isLoading }) => ({
  textTransform: "none",
  borderRadius: "34px",
  color: theme.palette.getContrastText(grey[900]),
  backgroundColor: isLoading ? grey[600] : grey[900],
  boxShadow: "none",
  "&:hover": {
    backgroundColor: grey[900],
    boxShadow: "none"
  },
  "&:active": {
    boxShadow: "none"
  }
}));

export default ColorButton;
