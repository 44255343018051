import React from "react";
import YourQuestions from "Components/HowItWorks/YourQuestions";

const AmberFaq = ({ widget }) => {
  const { color_theme } = widget;

  return (
    <YourQuestions
      id="your-questions"
      widget={widget}
      color_theme={color_theme}
    />
  );
};

export default AmberFaq;
