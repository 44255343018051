import React from "react";
import CreateContent from "Components/Homecomponents/CreateContent";

const DynamicAdsContainer = ({ page, widget }) => {
  const {
    color_theme,
    label,
    title,
    description,
    orientation,
    image_web,
    image_mobile,
    sub_image_mobile,
    sub_image,
    sub_image_link,
    widgets = []
  } = widget;
  return (
    <CreateContent
      home={page === "home"}
      items={widgets}
      label={label}
      title={title}
      description={description}
      orientation={orientation}
      imageWeb={image_web}
      imageMobile={image_mobile}
      subImageMobile={sub_image_mobile}
      sub_image={sub_image}
      subImageLink={sub_image_link}
      color_theme={color_theme}
    />
  );
};

export default DynamicAdsContainer;
