import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Divider } from "@mui/material";
import Box from "@mui/material/Box";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import useMediaQuery from "@mui/material/useMediaQuery";
import whiteticksvg from "Assets/images/whitetick.svg";
import { useTranslation } from "react-i18next";
import { AnchorLinkEnums } from "utils/enums";
import LazyImage from "Components/LazyLoad/LazyImage";

export default function BasicTable({ id, widget }) {
  const { i18n } = useTranslation();
  let firstWidget = widget?.widgets?.[0]?.items;
  let secondWidget = widget?.widgets?.[1]?.items;
  const { color_definition } = widget?.color_theme || {};
  const { header_text, background_color, title } = color_definition || {};

  const Title = widget?.title;
  const Assets = widget?.widgets?.[1]?.assets;
  const tabletMax = useMediaQuery("(min-width:600px)");
  const tablet = useMediaQuery("(min-width:400px) and (max-width:599px) ");
  const mobile = useMediaQuery("(max-width:399px)");
  const [value, setValue] = useState(0);
  const tickImage = () => {
    return (
      <LazyImage
        placeholderSrc={
          "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        }
        lazyclass="lazy"
        src={Assets?.check_mark}
        alt="tick"
      />
    );
  };
  const colorCode = ["#E7F0DF", "#274A2E", "#A03214", "#140953", "#4E0B39"];
  const classColor = [
    {
      class: "bg-green-head",
      subtextClass:
        i18n.language === "arabic"
          ? "table-head-subtext-arabic"
          : "table-head-subtext"
    },
    {
      class: "bg-dark-green-head",
      subtextClass:
        i18n.language === "arabic"
          ? "table-head-subtext-light-arabic"
          : "table-head-subtext-light"
    },
    {
      class: "bg-dark-orange-head",
      subtextClass:
        i18n.language === "arabic"
          ? "table-head-subtext-light-arabic"
          : "table-head-subtext-light"
    },
    {
      class: "bg-dark-purple-head",
      subtextClass:
        i18n.language === "arabic"
          ? "table-head-subtext-light-arabic"
          : "table-head-subtext-light"
    },
    {
      class: "bg-dark-brown-head",
      subtextClass:
        i18n.language === "arabic"
          ? "table-head-subtext-light-arabic"
          : "table-head-subtext-light"
    }
  ];
  const tableClassColorLight = [
    "bg-green-opacity",
    "bg-dark-green-opacity",
    "bg-dark-orange-opacity",
    "bg-dark-purple-opacity",
    "bg-dark-brown-opacity"
  ];
  const tableClassColorDark = [
    "bg-green",
    "bg-dark-green",
    "bg-dark-orange",
    "bg-dark-purple",
    "bg-dark-brown"
  ];

  // This function calculates count of data in the targeted columns
  const getColumnDataCount = (targetColumnIndex) => {
    return secondWidget.reduce((count, row) => {
      const checkersArray = JSON.parse(row.checkers);
      return checkersArray[targetColumnIndex] === 1 ? count + 1 : count;
    }, 0);
  };
  //This function returns the first element of the widgets and adapts it to the component.
  const mappedButtons = firstWidget?.map((item, index) => {
    return {
      id: index + 1,
      name: item?.title,
      subname: item?.description,
      class: classColor[index].class,
      subtextClass: classColor[index].subtextClass,
      subtitles: secondWidget.slice(0, getColumnDataCount(index)),
      colorCode: colorCode[index]
    };
  });
  //This function returns the second element of the widgets and adapts it to the component.

  const mappedRows = secondWidget?.map((item, index) => {
    return {
      id: index,
      title: item.label,
      checkers: item.checkers,
      tool_tip: item.tool_tip
    };
  });
  // dont delete "e"
  //This function updates state(setValue) that clicked target value

  const handleClick = (e, key) => {
    setValue(key);
  };
  return (
    <div
      className={AnchorLinkEnums.HOW_IT_WORKS}
      id={AnchorLinkEnums.TIERS_AND_BENEFITS}
      style={{ backgroundColor: background_color || "" }}
    >
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0 }}
        sx={{
          display: { xs: "none", lg: "block" },
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" },
          paddingTop: { xs: "40px", md: "80px" },
          paddingBottom: { xs: "40px", md: "80px" }
        }}
        style={{
          minHeight: "100%",
          alignContent: "center",
          justifyContent: "center",
          width: "100%",
          marginLeft: "0px"
        }}
        className={AnchorLinkEnums.HOW_IT_WORKS}
      >
        <Box
          className={
            i18n.language === "arabic"
              ? "left-section-head-arabic"
              : "left-section-head"
          }
          style={{
            color: header_text || "",
            height: "100% !important",
            marginBottom: "38px"
          }}
        >
          {Title}
        </Box>
        <Grid item xs={12} style={{ paddingLeft: "0px" }}>
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                border: "1px solid white"
              },
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: "#fff",
              boxShadow: "none"
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalMedium"
                        : "MontserratMedium",
                    fontSize: "14px",
                    lineHeight: "130%",
                    fontWeight: "500",
                    paddingLeft: "0px",
                    textAlign: i18n.language === "arabic" ? "right" : "left",
                    display: "table-cell",
                    padding: "16px 0px",
                    borderBottom: "none !important",
                    backgroundColor: background_color || "",
                    color: title || "#000000"
                  }}
                >
                  <div>{widget?.widgets?.[1]?.title}</div>
                </TableCell>
                {mappedButtons.map((item, index) => (
                  <TableCell
                    key={item.id}
                    align="center"
                    className={item.class}
                    sx={{
                      padding: "16px 8.5px",
                      width: "160px !important",
                      boxSizing: "border-box"
                    }}
                  >
                    <div
                      className={
                        i18n.language === "arabic"
                          ? "table-head-text-arabic"
                          : "table-head-text"
                      }
                    >
                      {item.name}
                    </div>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <div className={item.subtextClass}>{item.subname}</div>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ borderBottom: "1px solid white" }}>
              {mappedRows.map((row, rowIndex) => (
                <TableRow key={row.id}>
                  <TableCell
                    style={{ backgroundColor: background_color || "" }}
                    component="th"
                    scope="row"
                    className="table-first-head test"
                    sx={{
                      fontFamily:
                        i18n.language === "arabic"
                          ? "TajawalMedium"
                          : "MontserratMedium",
                      fontSize: "14px",
                      lineHeight: "130%",
                      fontWeight: "500",
                      paddingLeft: "0px",
                      height: "40px",
                      padding:
                        i18n.language === "arabic"
                          ? "16px 0px 16px 16px"
                          : "16px 16px 16px 0px",
                      direction: i18n.language === "arabic" ? "rtl" : "ltr",
                      borderBottom: "none !important",
                      color: title || "#000000",
                      gap: "10px"
                    }}
                  >
                    <div
                      style={{
                        textAlign: i18n.language === "arabic" ? "right" : "left"
                      }}
                    >
                      {row.title}
                    </div>
                    <div class="container">
                      <div className="question-mark">
                        <LazyImage
                          placeholderSrc={
                            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                          }
                          lazyclass="lazy"
                          src={Assets?.tool_tip}
                          alt="question mark"
                        />
                        {row.tool_tip ? (
                          <div
                            class="tooltip"
                            style={{
                              textAlign:
                                i18n.language === "arabic" ? "right" : "left"
                            }}
                          >
                            {row.tool_tip}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </TableCell>
                  {JSON.parse(row?.checkers)?.map((item, itemIndex) => (
                    <TableCell
                      key={`${rowIndex}-${itemIndex}`}
                      align="center"
                      className={
                        item === 1
                          ? rowIndex % 2 === 1
                            ? tableClassColorLight[itemIndex]
                            : tableClassColorDark[itemIndex]
                          : "bg-light"
                      }
                      style={{
                        backgroundColor: item === 1 ? "" : background_color
                      }}
                    >
                      {item === 1 && tickImage()}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0 }}
        sx={{
          display: { xs: "block", lg: "none" },
          paddingLeft: { xs: "0px", md: "11.11%" },
          paddingRight: { xs: "0px", md: "11.11%" },
          paddingBottom: "24px",
          paddingTop: "24px"
        }}
        style={{
          minHeight: "100%",
          alignContent: "center",
          justifyContent: "center"
        }}
        className={AnchorLinkEnums.HOW_IT_WORKS}
      >
        <Grid
          item
          xs={12}
          style={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
        >
          <Box
            sx={{
              height: "100% !important",
              marginBottom: "16px",
              marginTop: "9px !important",
              paddingLeft: "20px",
              paddingRight: "20px",
              color: header_text || ""
            }}
            className={
              i18n.language === "arabic"
                ? "left-section-head-arabic"
                : "left-section-head"
            }
          >
            {Title}
          </Box>
          <Swiper
            direction="horizontal"
            slidesPerView={tabletMax ? 3 : tablet ? 2 : mobile ? 1.5 : 1}
            slidesPerGroup={1}
            spaceBetween={0}
            loop={false}
            loopFillGroupWithBlank={true}
            scrollbar={{ draggable: true }}
            autoplay={false}
            autoplayDisableOnInteraction={false}
            navigation={false}
            pagination={false}
            modules={[Pagination, Navigation]}
            className="mySwiper swiperMin"
            slideMargin={0}
            style={{ background: "#F9F9FD" }}
          >
            <div className="brand-store-btngroup">
              {mappedButtons.map((button, key) => (
                <>
                  <SwiperSlide
                    className=""
                    key={button.id}
                    onClick={(event) => handleClick(event, key)}
                  >
                    <button
                      style={{
                        background:
                          value === key ? `${button.colorCode}` : "#F9F9FD",
                        color: value === key && value !== 0 ? `white` : "black",
                        border: "none",
                        borderBottom: `4px solid ${button.colorCode}`,
                        height: "64px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingLeft: "20px",
                        width: "100%",
                        cursor: "pointer"
                      }}
                    >
                      <div
                        className={
                          i18n.language === "arabic"
                            ? "hw-tab-head-arabic"
                            : "hw-tab-head"
                        }
                      >
                        {button.name}
                      </div>
                      <div
                        className={
                          i18n.language === "arabic"
                            ? "hw-tab-text-arabic"
                            : "hw-tab-text"
                        }
                      >
                        {" "}
                        {button.subname}
                      </div>
                    </button>
                  </SwiperSlide>
                </>
              ))}
            </div>
            {mappedButtons
              .filter((fltr) => fltr.id - 1 === value)
              .map((button) =>
                button.subtitles.map((subt) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        minWidth: "270px",
                        padding: "15px",
                        background: "#FFFFFF"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          flexDirection: "row",
                          width: "100%",
                          fontFamily:
                            i18n.language === "arabic"
                              ? "TajawalRegular"
                              : "MontserratRegular",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "130%",
                          padding: "3px"
                        }}
                      >
                        <div className="img-tick">
                          <LazyImage
                            placeholderSrc={
                              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            }
                            lazyclass="lazy"
                            src={whiteticksvg}
                            alt="Checked Icon"
                          />
                        </div>
                        <div
                          style={{ marginLeft: "20px", marginRight: "20px" }}
                        >
                          {subt.label}{" "}
                        </div>
                      </Box>

                      {/* this should be visible when there is sub-content  */}
                      {/* <img src={Vector} alt="Checked Icon" /> */}
                    </Box>
                    <Divider variant="middle" />
                  </>
                ))
              )}
          </Swiper>
        </Grid>
      </Grid>
    </div>
  );
}
