import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FormSettingsWrapper = ({ children, sx }) => {
  return (
    <Grid className="form-settings-wrapper" sx={sx}>
      {children}
    </Grid>
  );
};

export const SettingsSubTitle = ({ text, ...props }) => {
  const { i18n } = useTranslation();
  return (
    <h3 className="settings-sub-title" data-lang={i18n.language} {...props}>
      {text}
    </h3>
  );
};

export const SettingsDescription = ({ text, ...props }) => {
  const { i18n } = useTranslation();
  return (
    <p className="settings-description" data-lang={i18n.language} {...props}>
      {text}
    </p>
  );
};

export const InputLabel = ({ text, ...props }) => {
  const { i18n } = useTranslation();

  return (
    <p className={"input-label"} data-lang={i18n.language} {...props}>
      {text}
    </p>
  );
};

export const Spacer = () => <div className="form-settings-wrapper-spacer" />;
