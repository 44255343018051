import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import AlertWarning from "Assets/AlertWarning/AlertWarning";
import { useIsMobileView } from "hooks";
import { SECURE_CODE_LENGTH } from "../constants";
import CustomTextField from "./CustomTextField";
import CustomizedDialog, {
  defaultModalTitleArabicStyle,
  defaultModalTitleStyle
} from "./Modal";
import Timer from "./timer/Timer";
import {
  usePostUpdateMobileNumber,
  usePostUpdateEmail
} from "services/account/account.query";

const validationSchema = (t) =>
  yup.object({
    code: yup
      .string()
      .length(
        SECURE_CODE_LENGTH,
        (params) => `Your code must be ${params.length}-digits`
      )
      .required(t("The code is required"))
  });

const StyleTextArabic = {
  fontFamily: "TajawalRegular",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "20px",
  margin: 0,
  padding: 0
};

const StyleTextEnglish = {
  fontFamily: "MontserratRegular",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  margin: 0,
  padding: 0
};

const OTPConfirmation = ({
  sendTo,
  onPhoneChangeSuccess,
  time,
  isChangePhoneModalOpen,
  setIsChangePhoneModalOpen,
  validation_key,
  SetValidationKey,
  Query,
  phoneNumber,
  iso_code,
  parent,
  email,
  SetResponse,
  SetSuccessQuery,
  successQuery,
  isFetching,
  accountIsLoading,
  SetFetchingAccount
   
}) => {
  const { t, i18n } = useTranslation();
  const isMobileView = useIsMobileView();
  const [errorMessageControl, SetErrorMessageControl] = useState(false);
  const [btnClick, SetbtnClick] = useState(false);
  const [serverErrorMessage, SetserverErrorMessage] = useState("");
  const { mutate: mutateUpdateOTP, isLoading } = Query({
    onSuccess: () => {
      SetErrorMessageControl(false);
      handlePhoneChangeSucess();
      SetSuccessQuery(!successQuery);
      SetFetchingAccount(true)

    },
    onError: (error) => {
      const { error_code, status } = error?.response?.data || {};

      if (error_code === 6900) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("expiredOTP"));
      } else if (status === 400) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("otpIncorrect"));
      } else if (error_code === 4001 && status === 429) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("multitimesotp"));
        setTimeout(() => {
          setIsChangePhoneModalOpen(false);
          SetErrorMessageControl(false);
        }, 1500);
      }
    }
  });
 
  const { mutate: mutateUpdateMobileNumber } = usePostUpdateMobileNumber({
    onSuccess: (response) => {
      SetValidationKey(response);
      SetSuccessQuery(!successQuery);

    },
    onError: (error) => {
      const { error_code, message, status } = error?.response?.data || {};
      if (error_code === 5000 && message === "Number already exist") {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("alreadyNumberPhone"));
      } else if (error_code === 4002 && status === 429) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("multitimesotp"));
      } else if (error_code === 4001 && status === 429) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("15minotp"));
      } else if (message === "Invalid phone number" && status === 400) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(
          t("Your phone number is not valid. Please try again")
        );
      }
    }
  });
  const { mutate: mutateUpdateEmail } = usePostUpdateEmail({
    onSuccess: (response) => {
      SetResponse(response);
      setIsChangePhoneModalOpen(true);
      SetErrorMessageControl(false);
      SetSuccessQuery(!successQuery);

    },
    onError: (error) => {
      const { error_code, status } = error?.response?.data || {};
      if (error_code === 5000 && status === 400) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("emailAlreadyRegistered"));
      } else if (error_code === 4002 && status === 429) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("multitimesEmail"));
      }
    }
  });
  const secureCodeForm = useFormik({
    initialValues: {
      validation_key: validation_key?.validation_key,
      code: "",
      validateOnBlur: false
    },
    validationSchema: validationSchema(t),
    onSubmit: async () => {
      await mutateUpdateOTP({
        validation_key: validation_key?.validation_key,
        code: secureCodeForm?.values.code
      });
    },
    validateOnBlur: false
  });
  useEffect(() => {
    if (secureCodeForm.errors.code && btnClick) {
      SetErrorMessageControl(true);
      SetserverErrorMessage(codeErrorMessage);
    } else {
      SetErrorMessageControl(false);
      SetserverErrorMessage("");
    }
  }, [secureCodeForm.errors, btnClick]);

  // resendCode(): This function attempts to resend a verification code. It checks the parent variable to determine whether to update a mobile number or an email. If parent is "mobile," it calls the mutateUpdateMobileNumber function with the phoneNumber and iso_code as parameters. If parent is "mail," it calls the mutateUpdateEmail function with the email as a parameter. If the update is successful, it returns true; otherwise, it returns false.

  // handlePhoneChangeSucess(): This function is used to handle a successful change of phone number. It closes a modal by setting isChangePhoneModalOpen to false and then calls the onPhoneChangeSuccess function with the sendTo parameter if it exists.

  const resendCode = async () => {
    try {
      if (parent === "mobile") {
        await mutateUpdateMobileNumber({
          phoneNumber: phoneNumber,
          iso_code: iso_code
        });
      }

      if (parent === "mail") {
        await mutateUpdateEmail({
          email: email
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  };
  

  const handlePhoneChangeSucess = () => {
     setIsChangePhoneModalOpen(false);
    onPhoneChangeSuccess?.(sendTo);}
  

  const codeErrorMessage = secureCodeForm.errors.code;
  const StyleArabic = {
    direction: "rtl",
    padding: isMobileView ? "20px" : "48px !important",
    marginTop: isMobileView ? "120px" : "initial"
  };
  const StyleEnglish = {
    direction: "ltr",
    padding: isMobileView ? "20px" : "48px !important",
    marginTop: isMobileView ? "120px" : "initial"
  };

  return (
    <>
      <CustomizedDialog
        onCancel={() => {
          setIsChangePhoneModalOpen(false);
        }}
        isLoading={isFetching || isLoading || accountIsLoading}
        open={isChangePhoneModalOpen}
        title={t("Please verify your identity")}
        titleStyle={
          i18n.language === "arabic"
            ? defaultModalTitleArabicStyle
            : defaultModalTitleStyle
        }
        okButtonLabel={t("Submit")}
        dialogStyle={i18n.language === "arabic" ? StyleArabic : StyleEnglish}
        okButtonProps={{
          onClick: () => {
            SetbtnClick(true);
            secureCodeForm.submitForm();
          }
        }}
      >
        <form onSubmit={secureCodeForm.handleSubmit}>
          <p
            style={
              i18n.language === "arabic" ? StyleTextArabic : StyleTextEnglish
            }
          >
            {t("To protect your account") + " : "}
            <div>
              <bdi>{sendTo}</bdi>
            </div>
          </p>
          {errorMessageControl && <AlertWarning text={t(serverErrorMessage)} />}

          <p
            style={{
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalMedium"
                  : "MontserratMedium",
              fontSize: i18n.language === "arabic" ? "17px" : "14px",
              lineHeight: "20px",
              margin: 0,
              marginTop: "28px",
              marginBottom: "8px",
              padding: 0
            }}
          >
            {t("6 digit code")}
          </p>
          <CustomTextField
            placeholder={t("Enter the code")}
            name={"code"}
            onChange={(e) => {
              const mycode = e.target.value.replace(/[^\d]/g, "");
              e.target.value = mycode;
              secureCodeForm.handleChange(e);
            }}
            value={secureCodeForm.values.code}
            autoComplete="off"
            sx={{
              width: "100% !important",
              marginBottom: "12px"
            }}
            inputProps={{
              maxLength: 6,
              inputMode: "numeric"
            }}
          />
          <p
            style={{
              fontFamily: "MontserratMedium",
              fontSize: "14px",
              lineHeight: "20px",
              margin: "0px",
              padding: "0px"
            }}
          >
            <Timer
              onReset={async () => {
                return await resendCode();
              }}
              start={time}
            />
          </p>
        </form>
      </CustomizedDialog>
    </>
  );
};

export default OTPConfirmation;
