import { FormControl, Grid, CircularProgress, Box } from "@mui/material";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useIsMobileView } from "hooks";
import { InputLabel, SettingsDescription, SettingsSubTitle } from "../common";
import CustomTextField from "../components/CustomTextField";
import PrimaryButton from "../components/PrimaryButton";
import OTPConfirmation from "../components/OTPConfirmation";
import NotificationSnack from "../components/NotificationSnack";
import EmailCntrl from "../components/EmailCntrl";
import {
  useGetAccount,
  usePostUpdateEmail,
  usePostEmailOTP
} from "services/account/account.query";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import { usePostExistEmail } from "services/auth/auth.query";
import { MAIL_OTP_SECURE_CODE_DURATION_IN_SECONDS } from "../constants";
import { EVENT_TYPE, useDataLayer } from "hooks/useDataLayer";

const realCode = "123456";

const validationSchema = (t) =>
  yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required(t("Email cannot be blank"))
  });

const EmailSettings = () => {
  const { t, i18n } = useTranslation();
  const [response, SetResponse] = useState();
  const [showSnack, setShowSnack] = useState(false);
  const { setSettingsEvent } = useDataLayer();
  const [isChangePhoneModalOpen, setIsChangePhoneModalOpen] = useState(false);
  const [errorMessageControl, SetErrorMessageControl] = useState(false);
  const [serverErrorMessage, SetserverErrorMessage] = useState("");
  const isMobileView = useIsMobileView();
  const {
    data: userData,
    isLoading: accountIsLoading,
    refetch,
    isFetching
  } = useGetAccount();
  const emailAddress =
    userData?.Customer?.[0]?.PersonalInformation?.EmailAddress;
  const [currentEmail, setCurrentEmail] = useState(emailAddress);
  const [sendToEmail, setSendToEmail] = useState(emailAddress);
  const [successQuery, SetSuccessQuery] = useState(false);
  const [fetchingAccount, SetFetchingAccount] = useState(false);

  const { mutate: mutateUpdateEmail, isLoading } = usePostUpdateEmail({
    onSuccess: (response) => {
      SetResponse(response);
      setIsChangePhoneModalOpen(true);
      SetErrorMessageControl(false);
    },
    onError: (error) => {
      const { error_code, status } = error?.response?.data || {};
      if (error_code === 5000 && status === 400) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("emailAlreadyRegistered"));
      } else if (error_code === 4001 && status === 429) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("15minotp"));
      } else if (error_code === 4002 && status === 429) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("multitimesEmail"));
      }
    }
  });

  const {
    mutateAsync: emailExist,
    error: existEmailError,
    isLoading: isExistEmailLoading,
    reset: resetExistEmailError
  } = usePostExistEmail({
    onSuccess: (response) => {
      const { email_exist } = response || {};
      if (email_exist) {
        SetErrorMessageControl(true);
        SetserverErrorMessage(t("emailAlreadyRegistered"));
      } else {
        SetErrorMessageControl(false);
        mutateUpdateEmail({
          email: changeEmailForm.values.email
        });
      }
    },
    onError: () => {}
  });
  const [phoneNumber, setPhoneNumber] = useState("2123");
  /* when page refreshed get account service return undefinded.ask to ilker */
  const paddingStyle =
    i18n.language === "arabic" ? "10px 12px 10px 40px" : "10px 40px 10px 12px";
  const changeEmailForm = useFormik({
    initialValues: {
      email: emailAddress || ""
    },
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: async ({ email }) => {
      if (emailAddress !== email) {
        setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, { step: "enter_email" });
      }

      await emailExist({
        email: changeEmailForm.values.email
      });
    },
    validateOnBlur: false
  });
  useEffect(() => {
    setCurrentEmail(emailAddress);
  }, [userData]);

  useEffect(() => {
    refetch();
  }, [successQuery]);
  useEffect(() => {
    if (fetchingAccount) {
      refetch();
    }
  }, [successQuery, fetchingAccount]);

  useEffect(() => {
    if (fetchingAccount && !isFetching) {
      SetFetchingAccount(false);
    }
  }, [isFetching]);

  useEffect(() => {
    SetErrorMessageControl(false);
  }, [isChangePhoneModalOpen]);

  const handleEmailChange = (e) => {
    const newValue = e.target.value;
    changeEmailForm.handleChange(e); // update to formik value
    setSendToEmail(newValue); //set state
  };
  if (isLoading || accountIsLoading || isExistEmailLoading || fetchingAccount)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );

  return (
    <>
      {!isLoading && !accountIsLoading && !fetchingAccount && (
        <Grid>
          {isChangePhoneModalOpen && (
            <OTPConfirmation
              sendTo={sendToEmail}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              realCode={realCode}
              onPhoneChangeSuccess={(newEmail) => {
                setCurrentEmail(newEmail);
                changeEmailForm.setValues({ email: newEmail });
                setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, {
                  step: "enter_email_success"
                });
                setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, {
                  step: "complete"
                });
                setShowSnack(true);
              }}
              isChangePhoneModalOpen={isChangePhoneModalOpen}
              setIsChangePhoneModalOpen={setIsChangePhoneModalOpen}
              validation_key={response}
              Query={usePostEmailOTP}
              SetserverErrorMessage={SetserverErrorMessage}
              SetErrorMessageControl={SetErrorMessageControl}
              errorMessageControl={errorMessageControl}
              serverErrorMessage={serverErrorMessage}
              time={MAIL_OTP_SECURE_CODE_DURATION_IN_SECONDS}
              parent={"mail"}
              email={changeEmailForm.values.email}
              SetResponse={SetResponse}
              SetSuccessQuery={SetSuccessQuery}
              successQuery={successQuery}
              isFetching={isFetching}
              accountIsLoading={accountIsLoading}
              SetFetchingAccount={SetFetchingAccount}
            />
          )}
          <NotificationSnack
            message={t("Your email has been updated")}
            open={showSnack}
            handleClose={() => {
              setShowSnack(false);
            }}
          />
          <SettingsSubTitle text={t("Email address")} />
          <SettingsDescription
            className="settings-description-header"
            text={
              emailAddress
                ? `${t("Your current email address is")} ${currentEmail}`
                : t("emailNotFound")
            }
          />
          <form onSubmit={changeEmailForm.handleSubmit}>
            <Grid container>
              <Grid item sm={12} md={6} style={{ width: "100%" }}>
                <InputLabel
                  style={{ marginBottom: "8px" }}
                  text={t("Email address")}
                />
                <FormControl className="settings-form-control">
                  <CustomTextField
                    name="email"
                    placeholder="Your email address"
                    value={changeEmailForm.values.email}
                    errorMessage={t(
                      changeEmailForm.submitCount
                        ? changeEmailForm.errors.email
                        : ""
                    )}
                    onChange={handleEmailChange}
                    inputProps={{ width: "100%" }}
                    paddingStyle={paddingStyle}
                  />

                  {!changeEmailForm.errors.email &&
                  changeEmailForm.values.email ? (
                    <EmailCntrl />
                  ) : null}
                </FormControl>
              </Grid>
              {errorMessageControl && (
                <AlertWarning marginbottom="24px" text={serverErrorMessage} />
              )}
            </Grid>
            <PrimaryButton
              text={t("Save changes")}
              style={{
                width: isMobileView ? "100%" : "initial",
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium"
              }}
            />
          </form>
        </Grid>
      )}
    </>
  );
};

export default EmailSettings;
