import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const SwiperLabelStyle = {
  fontFamily: "MontserratRegular",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "130%",
  color: "#000000",
  padding: "10px 0 0 0"
};

const AUTO_PLAY_OPTIONS = {
  delay: 0,
  disableOnInteraction: false
};

export default function SwiperCarousal({
  paddingAdd,
  items = [],
  background_color_carousel,
  animation_speed,
  auto_slide,
  label,
  editorial_brand_carousel
}) {
  const swiperRef = useRef(null);
  const { i18n } = useTranslation();
  useEffect(() => {
    if (auto_slide) {
      const swiperTimeout = setTimeout(() => {
        swiperRef.current?.autoplay?.start();
        swiperRef.current?.autoplay?.run();
      }, 2000);

      return () => {
        clearTimeout(swiperTimeout);
      };
    }
  }, [swiperRef, auto_slide]);

  return (
    <div
      className="brands-detail"
      style={{
        direction: i18n.language === "arabic" ? "rtl" : "ltr"
        // margin: "48px 0 0 0"
      }}
    >
      <Swiper
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        className={`mySwiper ${paddingAdd ? "pagination" : ""} ${
          editorial_brand_carousel ? " pagination-editorial" : ""
        }`}
        autoplay={auto_slide ? AUTO_PLAY_OPTIONS : false}
        style={{ marginTop: "0", backgroundColor: background_color_carousel }}
        speed={
          auto_slide &&
          (animation_speed ? (101 - animation_speed) * 1000 : 3000)
        }
        data-lang={i18n.language}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{
                justifyContent:
                  paddingAdd && index === 0 ? "center" : "initial",
                backgroundColor: background_color_carousel || "transparent"
              }}
            >
              <img
                className="brand-carousel-image"
                style={{ width: "100%", height: "100%" }}
                src={item.image}
                alt="photo1"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <p style={SwiperLabelStyle}>{label}</p>
    </div>
  );
}
