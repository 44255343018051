import { useEffect, useState } from "react";
import { CMS_COMPONENT_MAP } from "CmsComponents";
import { useGetHomeContent } from "services/cms/cms.query";
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmailCntrl from "../Account/components/EmailCntrl";
import {useGetFetchAmberReferralProgram} from "services/account/account.query";

const AlertStyle = {
  display: "flex",
  width: "350px",
  padding: "16px",
  alignItems: "center",
  gap: "8px",
  position: "absolute",
  left: "50%",
  top: "24px",
  borderRadius: "4px",
  background: "var(--primary-white, #FFF)",
  boxShadow: "0px 0px 14px 0px rgba(34, 38, 49, 0.20)",
  zIndex: 1000,
  margin: "0 0 0 -175px"
};

const AlertStyleArabic = {
  display: "flex",
  width: "350px",
  padding: "16px",
  alignItems: "center",
  gap: "8px",
  position: "absolute",
  left: "50%",
  top: "24px",
  borderRadius: "4px",
  background: "var(--primary-white, #FFF)",
  boxShadow: "0px 0px 14px 0px rgba(34, 38, 49, 0.20)",
  zIndex: 1000,
  margin: "0 0 0 -175px",
  flexDirection: "row-reverse",
  textAlign: "right"
};

const AlertTitleStyleEn = {
  alignSelf: "stretch",
  fontWeight: 600,
  color: "#000000",
  fontFamily: "MontserratRegular",
  margin: "0"
};

const AlertTitleStyleAr = {
  alignSelf: "stretch",
  fontWeight: 600,
  color: "#000000",
  fontFamily: "TajawalRegular",
  margin: "0"
};

const AlertContentStyle = {
  alignSelf: "stretch",
  color: "var(--greys-grey-02, #90969E)",
  fontFamily: "MontserratRegular",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1rem",
  margin: "0",
  padding: "0 20px 0 0"
};

const AlertContentStyleArabic = {
  alignSelf: "stretch",
  color: "var(--greys-grey-02, #90969E)",
  fontFamily: "TajawalRegular",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1rem",
  margin: "0",
  padding: "0 0 0 20px"
};

const ValidImgStyleEn = {
  right: "14.65px",
  left: "auto",
  position: "absolute",
  top: "50%",
  transform: "translate(0%, -50%)"
};
const ValidImgStyleAr = {
  right: "auto",
  left: "14.65px",
  position: "absolute",
  top: "50%",
  transform: "translate(0%, -50%)"
};

const Home = () => {
  const referalCode = localStorage.getItem("referralCode");
  const [hasReferalCode, sethasReferalCode] = useState(false);

  const { data: homeContent = {} } = useGetHomeContent();

  const { widgets = [],seo_data } = homeContent;
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { data: fetchamber } = useGetFetchAmberReferralProgram();
  const signup_success_message =
    fetchamber?.items?.[0]?.fields?.configuration?.configuration?.invitee_info
      ?.signup_success_message;
  const signup_success_header =
    fetchamber?.items?.[0]?.fields?.configuration?.configuration?.invitee_info
      ?.signup_success_header;
  const alliance_control = localStorage.getItem("alliance-control");

  useEffect(() => {
    if (alliance_control) {
      localStorage.removeItem("alliance-control");
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  useEffect(() => {
    if (referalCode) {
      sethasReferalCode(true);
      setTimeout(() => {
        localStorage.removeItem("referralCode");
        sethasReferalCode(false);
      }, 5000);
    }
  }, [referalCode]);

  return (
    <Layout seo_data={seo_data}>
      <div className="hover-background">
        {widgets?.map((widget, index) => {
          const CmsComponent = CMS_COMPONENT_MAP[widget["component_type"]];
          if (CmsComponent) {
            return <CmsComponent key={index} widget={widget} page={"home"} />;
          }
          return null;
        })}
      </div>
      {hasReferalCode && (
        <Alert
          className="referalValidAlert"
          style={i18n.language == "arabic" ? AlertStyleArabic : AlertStyle}
        >
          <AlertTitle
            className="titleReferalValidAlert"
            style={
              i18n.language == "arabic" ? AlertTitleStyleAr : AlertTitleStyleEn
            }
          >
            {i18n.language === "arabic"
              ? signup_success_header?.ar
              : signup_success_header?.en}
          </AlertTitle>
          <p
            style={
              i18n.language == "arabic"
                ? AlertContentStyleArabic
                : AlertContentStyle
            }
          >
            {i18n.language === "arabic"
              ? signup_success_message?.ar
              : signup_success_message?.en}
          </p>
          <InputAdornment position="end">
            <EmailCntrl
              referalCode={referalCode}
              validStyle={
                i18n.language == "arabic" ? ValidImgStyleAr : ValidImgStyleEn
              }
            />
          </InputAdornment>
        </Alert>
      )}
    </Layout>
  );
};

export default Home;
