import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Email from "Pages/SignUp/Email";
import OTP from "Pages/SignUp/OTP";
import PersonelDetails from "Pages/SignUp/PersonelDetails";
import PhoneNumber from "Pages/SignUp/PhoneNumber";
import Selectcountry from "Pages/SignUp/Selectcountry";
import WelcomePage from "Pages/SignUp/WelcomePage";
import EmailOTP from "Pages/SignUp/EmailOTP";

export const SignUpRoutes = (props) => {
  const [phoneNumber, SetPhoneNumber] = useState("");
  const [OTPcode, SetOTPCode] = useState("");
  const [Mail, SetMail] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");

  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/welcomePage" element={<WelcomePage />} />
      <Route path="/external/:appType" element={<WelcomePage />} />
      <Route path="/:referalCode" element={<WelcomePage />} />
      <Route
        path="phone-validation"
        element={
          <PhoneNumber
            SetPhoneNumber={SetPhoneNumber}
            phoneNumber={phoneNumber}
            SetPhoneCode={SetPhoneCode}
            SetOTPCode={SetOTPCode}
            SetMail={SetMail}
          />
        }
      />
      <Route
        path="phone-verification"
        element={
          <OTP
            phoneNumber={phoneNumber}
            SetPhoneNumber={SetPhoneNumber}
            SetOTPCode={SetOTPCode}
            phoneCode={phoneCode}
          />
        }
      />
      <Route
        path="email-registration"
        element={
          <Email
            phoneNumber={phoneNumber}
            OTPcode={OTPcode}
            SetOTPCode={SetOTPCode}
            Mail={Mail}
            SetMail={SetMail}
          />
        }
      />
      <Route path="email-registration-otp" element={<EmailOTP />} />
      <Route
        path="personal-details"
        element={<PersonelDetails Mail={Mail} />}
      />
      <Route
        path="country-selection"
        element={<Selectcountry Mail={Mail} SetMail={SetMail} />}
      />
      <Route path="*" element={<Navigate to="/sign-up/" replace />} />
    </Routes>
  );
};
