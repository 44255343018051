import { Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import WprightPage from "Components/Authanticate/WprightPage";
import { clearRouteCntrl, setRouteCntrl } from "store/authanticateSlice";
import {
  useCountryTopList,
  useRegisterLoginAccount
} from "hooks/onBoardingHooks";
import { useUserStore } from "hooks/useUser";
import { usePostSetCountry } from "services/account/account.query";
import { getToken } from "utils/authStorage";

const styleCheck = {
  backgroundColor: "white",
  display: "flex",
  width: "20px",
  height: "20px",
  margin: "0px",
  cursor: "pointer",
  borderRadius: " 50%",
  border: "1px solid #90969E",
  justifyContent: "center",
  alignItems: "center"
};
const styleCheckActive = {
  backgroundColor: "black",
  border: "1px solid black",
  display: "flex",
  width: "20px",
  height: "20px",
  cursor: "pointer",
  borderRadius: " 50%",
  justifyContent: "center",
  alignItems: "center"
};
const dividerArabic = {
  marginLeft: "0px",
  marginRight: "34px",
  borderColor: "#DFE1EA"
};
const dividerEnglish = {
  marginLeft: "34px",
  marginRight: "5px",
  borderColor: "#DFE1EA"
};

const Selectcountry = () => {
  const countries = useCountryTopList();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);
  const personalTrue = routeCntrl.some(
    (item) =>
      item.otp === true || item.multiple === true || item.selectCountry === true
  );
  const [checkedRadio, setCheckedRadio] = useState(false);
  const [showAlert, SetShowAlert] = useState(false);
  const [showalertError, setShowalertError] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);
  const mobile = useMediaQuery("(max-width:699px)");
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};

  const { setUser } = useUserStore();
  const {
    mutateAsync: setCountry,
    error,
    isLoading
  } = usePostSetCountry({ onSuccess: () => {}, onError: () => {} });
   // handleChange:
  // When called, it sets the checkedRadio state to true.
  // It is memoized using useCallback to avoid re-creation on each render. The dependency array [setCheckedRadio] ensures that the function does not change unless setCheckedRadio changes.

  // handleIndex:
  // Takes an index as a parameter.
  // Sets the checkedRadio state to true.
  // Sets the activeIndex state with the provided index.
  // Memoized with useCallback to prevent re-creation on re-renders. It depends on [setCheckedRadio, setActiveIndex].

  // handleFirstsubmit:
  // Clears alert messages by setting showalertError and ShowAlert to false.
  // Calls a function (presumably setCountry) that sets the country state with activeIndex and returns a promise.
  // After the promise resolves, it sets the user state with the result of getToken.
  // Creates a new route object newRoute with the property selectCountry set to true.
  // Dispatches actions to clear the route control state and set it to newRoute.
  // Navigates to a specific route (/).
  // Memoized with useCallback. It depends on several dependencies: [setShowalertError, SetShowAlert, setCountry, setUser, dispatch, navigate].

  // handleRoute:
  // Checks if checkedRadio is true.
  // If checkedRadio is true, it calls the handleFirstsubmit function.
  // Otherwise, it sets ShowAlert to true.
  // Memoized with useCallback. It depends on [checkedRadio, handleFirstsubmit, SetShowAlert].

  const handleChange = useCallback(() => {
    setCheckedRadio(true);
  }, [setCheckedRadio]);

  const handleIndex = useCallback(
    (index) => {
      setCheckedRadio(true);
      setActiveIndex(index);
    },
    [setCheckedRadio, setActiveIndex]
  );

  const handleFirstsubmit = useCallback(() => {
    setShowalertError(false);
    SetShowAlert(false);

    setCountry({
      country: activeIndex?.toString()
    }).then((response) => {
      setUser(getToken());
      const newRoute = { selectCountry: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));
      navigate(`/`);
    });
  }, [
    setShowalertError,
    SetShowAlert,
    setCountry,
    setUser,
    dispatch,
    navigate,
    activeIndex
  ]);

  const handleRoute = useCallback(() => {
    if (checkedRadio) {
      handleFirstsubmit();
    } else SetShowAlert(true);
  }, [checkedRadio, handleFirstsubmit, SetShowAlert]);

  useEffect(() => {
    navigateProtect();
  }, []);

  const navigateProtect = () => {
    if (!personalTrue) {
      navigate(`/sign-in`);
    } else setShowComponent(true);
  };

  return (
    <>
      {showComponent ? (
        <div className="WPmain">
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: background ? background : "#FFFFFF"
            }}
          >
            {" "}
            <div className="left-page-form-pd">
              <>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "login-head-pd-arabic "
                      : "login-head-pd"
                  }
                >
                  <span>{t("Last step!")}</span>
                </div>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "selecttext-arabic"
                      : "selecttext"
                  }
                >
                  {t("Set your country to see offers")}
                </div>

                <div
                  className={
                    i18n.language === "arabic"
                      ? "custom-radios-arabic"
                      : "custom-radios"
                  }
                >
                  {countries.map((country, index) => (
                    <div
                      style={{ cursor: "pointer" }}
                      key={country.maxxing_iso_code}
                      onClick={() => handleIndex(country.maxxing_iso_code)}
                    >
                      <div className="SC-radio-box">
                        <div className="SC-box-1">
                          <div>
                            <img
                              loading="lazy"
                              width={mobile ? "16" : "20"}
                              height={mobile ? "16" : "20"}
                              style={{ borderRadius: "50%" }}
                              src={country.flag}
                              alt=""
                            />
                          </div>
                          <div
                            className="SC-text"
                            style={{
                              fontFamily:
                                i18n.language === "arabic"
                                  ? "TajawalMedium"
                                  : "MontserratMedium"
                            }}
                          >
                            {country?.name}
                          </div>
                        </div>
                        <div className="SC-box-2">
                          <input
                            type="radio"
                            id={index}
                            name="country"
                            value={index}
                            onChange={handleChange}
                          />
                          <label htmlFor={index}>
                            <span
                              style={
                                activeIndex === country.maxxing_iso_code
                                  ? styleCheckActive
                                  : styleCheck
                              }
                            >
                              <img
                                style={{
                                  opacity:
                                    activeIndex === country.maxxing_iso_code
                                      ? "1"
                                      : "0",
                                  width: "10px",
                                  height: "10px"
                                }}
                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                                alt="Checked Icon"
                              />
                            </span>
                          </label>
                        </div>
                      </div>
                      <Divider
                        style={
                          i18n.language === "arabic"
                            ? dividerArabic
                            : dividerEnglish
                        }
                      />{" "}
                    </div>
                  ))}
                </div>
                {showAlert ? (
                  <AlertWarning text={t("You need to select country")} />
                ) : null}
                {showalertError && (
                  <AlertWarning
                    marginbottom="24px"
                    margintop="-16px"
                    text={t(error)}
                  />
                )}
                <div className="btnbox-pn">
                  {isLoading ? (
                    <ColorLoadingButton
                      size="small"
                      loading={true}
                      variant="outlined"
                      disabled
                      type="submit"
                      className="button-submit-loading"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        maxWidth: "100%"
                      }}
                    />
                  ) : (
                    <ColorButton
                      variant="contained"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium"
                      }}
                      onClick={handleRoute}
                      className="button-submit-pn"
                    >
                      {t("Ok, let’s go!")}
                    </ColorButton>
                  )}
                </div>
              </>
            </div>
          </div>
          <WprightPage />
        </div>
      ) : null}
    </>
  );
};

export default Selectcountry;
