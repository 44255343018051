import React, { useEffect, useState, useCallback } from "react";
import logoUnion from "Assets/images/logo-dark.svg";
import { useNavigate, Link } from "react-router-dom";
import WprightPage from "Components/Authanticate/WprightPage";
import { Box, FormControl } from "@mui/material";
import CustomTextField from "../Account/components/CustomTextField";
import { InputLabel } from "../Account/common";
import { useDispatch } from "react-redux";
import { selectLanguage } from "store/languageSlice";
import { withTranslation, useTranslation } from "react-i18next";
import { parthners } from "Assets/helper/helper";
import { alliance_id_reducer } from "store/publicSlice";
import { setRouteCntrl, clearRouteCntrl } from "store/authanticateSlice";

const AllianceWelcomePage = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [parthner, SetParthner] = useState("");
  const [alert, SetShowAlert] = useState("");
  const [show, SetShow] = useState("");

  //   handleChange function:
  // It updates the "Parthner" state when a change event occurs in the input field.
  // It uses the SetParthner function to update the state.
  // The function is wrapped in a useCallback to optimize performance by memoizing the function reference.

  // handleClick function:
  // It handles a click event for a specific key.
  // It sets a new route configuration with a welcome key set to true and updates the "alliance_id" state with the provided key.
  // It navigates to the /alliance-phone-number route.
  // This function is also wrapped in a useCallback.

  // onLanguageChanged function:
  // It handles a language change event.
  // It updates the selected language, changes the application's language using the i18n object, and stores the selected language in local storage.
  // The function is wrapped in a useCallback to optimize performance.

  // filteredData function:
  // It filters a dataset based on a search query.
  // It checks if the search query contains at least three characters and if the "name" or "name_ar" properties of the data items include the search query (case-insensitive).
  // It returns the filtered data.

  // WarningControl function:
  // It checks if the filtered data for "partners" (parthners) is empty when the search query (parthner) contains at least three characters.
  // If no results are found and the search query has at least three characters, it sets a timeout to hide a component ("SetShow" is set to false) and show an alert component ("SetShowAlert" is set to true) after a delay.
  // Otherwise, it sets "SetShow" to true and hides the alert component ("SetShowAlert" is set to `false").

  const handleChange = useCallback(
    (e) => {
      SetParthner(e.target.value);
    },
    [SetParthner]
  );

  const handleClick = useCallback(
    (key) => {
      const newRoute = { welcome: true };
      dispatch(setRouteCntrl(newRoute));
      dispatch(alliance_id_reducer(key));
      navigate(`/alliance-phone-number`);
    },
    [dispatch, navigate, alliance_id_reducer]
  );

  useEffect(() => {
    dispatch(clearRouteCntrl());
  }, []);

  const onLanguageChanged = useCallback(
    (event) => {
      let language = event.currentTarget.value;
      dispatch(selectLanguage(language));
      props.i18n.changeLanguage(language);
      localStorage.setItem("language", language);
    },
    [dispatch, props.i18n, localStorage]
  );

  const filteredData = (data, search) => {
    return data.filter(
      (dt) =>
        (search.length >= 3 &&
          dt.name
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(search.toLowerCase().replace(/\s/g, ""))) ||
        (search.length >= 3 &&
          dt.name_ar
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(search.toLowerCase().replace(/\s/g, "")))
    );
  };
  const WarningControl = () => {
    if (
      filteredData(parthners, parthner).length === 0 &&
      parthner.length >= 3
    ) {
      setTimeout(() => {
        SetShow(false);
        SetShowAlert(true);
      }, 500);
    } else {
      SetShowAlert(false);
      SetShow(true);
    }
  };

  useEffect(() => {
    WarningControl();
  }, [parthner]);
  useEffect(() => {
    SetParthner("");
  }, [i18n.language]);
  return (
    <Box className="alliance" data-lang={i18n.language}>
      <div
        className="WPleftpage"
        data-lang={i18n.language}
        style={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
      >
        <Box className="Language-select" data-lang={i18n.language}>
          <button
            className={
              i18n.language === "arabic" ? "lng-not-selected" : "lng-selected"
            }
            value="en"
            onClick={(value) => onLanguageChanged(value)}
          >
            English
          </button>
          <span className="lng-not-selected">|</span>
          <button
            className={
              i18n.language === "arabic" ? "lng-selected" : "lng-not-selected"
            }
            value="arabic"
            onClick={(value) => onLanguageChanged(value)}
            style={{
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalRegular"
                  : "MontserratRegular"
            }}
          >
            عَرَبِيّ
          </button>
        </Box>
        <div className="left-page-form">
          <div className="left-page-logo">
            <Link to="/">
              <img src={logoUnion} alt="logo" width={"74.89px"} />
            </Link>
          </div>
          <div
            className={
              i18n.language === "arabic" ? "login-head-arabic" : "login-head"
            }
          >
            <span>{t("Alliance")}</span>
          </div>
          <FormControl
            className="settings-form-control"
            sx={{
              width: "100%",
              maxWidth: "460px",
              marginTop: "32px"
            }}
          >
            <InputLabel text={t("Enter Partner’s Name")} />
            <CustomTextField
              name="lastName"
              placeholder={t("Search a Partner")}
              value={parthner}
              onChange={handleChange}
            />
          </FormControl>

          {!alert && (
            <Box
              className={
                i18n.language === "arabic" ? "sub-text-arabic" : "sub-text"
              }
            >
              {t("Please enter 3 or more characters")}
            </Box>
          )}
          {show && filteredData(parthners, parthner).length > 0 && (
            <Box className="search-area">
              {filteredData(parthners, parthner).map((item) => (
                <Box
                  sx={{
                    textAlign: "center",
                    padding: "20px",
                    cursor: "pointer"
                  }}
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                >
                  <img width="100%" src={item.image} alt={item.name} />

                  <Box
                    className={
                      i18n.language === "arabic"
                        ? "parthner-name-arabic"
                        : "parthner-name"
                    }
                  >
                    {i18n.language === "arabic" ? item.name_ar : item.name}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          <>
            {alert && (
              <p
                className={
                  i18n.language === "arabic"
                    ? "amber-alliance-alert-text-arabic"
                    : "amber-alliance-alert-text"
                }
              >
                {" "}
                {t("No results found")}
              </p>
            )}
          </>
        </div>
      </div>

      <WprightPage
        style={{
          left: i18n.language === "arabic" ? "0" : "auto",
          right: i18n.language === "arabic" ? "auto" : "0"
        }}
      />
    </Box>
  );
};
export default withTranslation()(AllianceWelcomePage);
