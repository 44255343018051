import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const SwitchbuttonReverse = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    left: "auto",
    right: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(-16px)",
      color: "#fff",
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "black"
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    }),
    backgroundColor: "#90969E"
  }
}));

export default SwitchbuttonReverse;
