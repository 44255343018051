import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const ContactInfoItem = ({ label, value }) => {
  const { i18n } = useTranslation();

  return (
    <Box marginBottom={4}>
      {label && (
        <Box
          className={
            i18n.language === "arabic"
              ? "brand-details-head-arabic"
              : "brand-details-head"
          }
        >
          {label}
        </Box>
      )}
      {value && (
        <p
          className={
            i18n.language === "arabic"
              ? "brand-details-text-arabic"
              : "brand-details-text"
          }
        >
          {value}
        </p>
      )}{" "}
    </Box>
  );
};

export const LocationContactInfo = ({
  address,
  contactNumber,
  operatingHours = [],
  email,
  clickAndCollect
}) => {
  const { t, i18n } = useTranslation();

  const filteredItems = (data) => {
    if (!data) {
      return [];
    }

    return data.filter((item) => item?.hours || item?.label);
  };
  return (
    <Box
      sx={{
        paddingRight: { xs: "0px" },
        paddingLeft: { xs: "0px", lg: "0px" },
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "row" },
        marginTop: { xs: "24px", xl: "32px" },
        width: "100%",
        paddingTop: "0px"
      }}
    >
      <Box sx={{ width: "100%", paddingRight: "10px" }}>
        {address && <ContactInfoItem label={t("Location")} value={address} />}
        {email && <ContactInfoItem label={t("Email")} value={email} />}
        {contactNumber && (
          <ContactInfoItem
            label={t("Phone")}
            value={<bdi>{contactNumber}</bdi>}
          />
        )}
      </Box>

      <Box
        sx={{
          width: "100%"
        }}
      >
        <Box>
          <Box
            className={
              i18n.language === "arabic"
                ? "brand-details-head-arabic"
                : "brand-details-head"
            }
            sx={{
              marginTop: {
                xs: "40px !important",
                md: "0 !important"
              }
            }}
          >
            {filteredItems(operatingHours)?.length > 0 && t("Opening Hours")}
          </Box>
          {operatingHours.map((operatingHour) => (
            <Box
              key={operatingHour.label}
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div
                className={
                  i18n.language === "arabic"
                    ? "brand-details-date-arabic"
                    : "brand-details-date"
                }
              >
                {operatingHour.label}
              </div>
              <div
                className={
                  i18n.language === "arabic"
                    ? "brand-details-text-arabic"
                    : "brand-details-text"
                }
              >
                {operatingHour.hours.toLowerCase().replace("to", " - ")}
              </div>
            </Box>
          ))}

          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-end"
              },
              marginTop: { xs: "8px", md: "12px" }
            }}
          >
            <div
              className={
                i18n.language === "arabic"
                  ? "brand-details-text-arabic"
                  : "brand-details-text"
              }
            >
              {clickAndCollect ? t("The store offers Click & Collect") : null}
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
