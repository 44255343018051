import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

export default function SimpleAccordion({
  footerLinks,
  expand,
  setExpand,
  background_color,
  normal_link
}) {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand(isExpanded ? panel : false);
  };
  const { i18n } = useTranslation();
  const text = {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "130%",
    color: normal_link || "#FFFFFF",
    marginBottom: "20px"
  };
  const textArabic = {
    fontFamily: "TajawalRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "17px",
    lineHeight: "130%",
    color: normal_link || "#FFFFFF",
    marginBottom: "20px",
    textAlign: "right"
  };
  const headText = {
    textAlign: "left",
    height: "100%",
    textDecoration: "none",
    fontFamily: "MontserratMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: " 130%",
    color: normal_link || ""
  };
  const headTextArabic = {
    fontFamily: "TajawalRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "19px",
    lineHeight: "130%",
    textAlign: "right",
    color: normal_link || ""
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      {footerLinks.links?.map((footerLink, index) => (
        <Accordion
          expanded={expand === footerLink.title}
          onChange={handleChange(footerLink.title)}
          sx={{
            backgroundColor: "transparent",
            color: "#90969e",
            fontFamily: "MontserratRegular",
            boxShadow: "none",
            direction: "ltr"
          }}
          key={index}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#90969e" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              padding: " 0px",
              marginTop: "21px",
              display: "flex",
              flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
              "& .MuiAccordionSummary-content": {
                display: "flex",
                flexDirection:
                  i18n.language === "arabic" ? "row-reverse" : "row"
              }
            }}
          >
            <Box
              style={i18n.language === "arabic" ? headTextArabic : headText}
              sx={{
                display: "flex",
                flexDirection:
                  i18n.language === "arabic" ? "row-reverse" : "row",
                paddingRight: "0px",
                color: expand === footerLink.title ? "#FFF" : "#90969e"
              }}
            >
              {footerLink.title}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Typography>
              <List>
                {footerLink.items?.map((footerLinkItem, index) => (
                  <ListItem
                    disablePadding
                    key={index}
                    style={{
                      justifyContent:
                        i18n.language === "arabic" ? "flex-end" : "flex-start"
                    }}
                  >
                    <HashLink
                      style={{ textDecoration: "none" }}
                      smooth
                      to={footerLinkItem.link}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: i18n.language === "arabic" ? textArabic : text
                        }}
                        primary={footerLinkItem.title}
                        sx={{ paddingLeft: "2px", paddingRight: "2px" }}
                      />
                    </HashLink>
                  </ListItem>
                ))}
              </List>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
