import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";

const WpRightLogin = () => {
  const { i18n } = useTranslation();
  const styleEnglish = {
    fontFamily: "PlayfairDisplay",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "72px",
    lineHeight: "120%",
    maxWidth: "540px",
    color: "#FFFFFF",
    textAlign: "left"
  };
  const styleArabic = {
    fontFamily: "NotoNaskhArabicRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "72px",
    lineHeight: "120%",
    maxWidth: "540px",
    color: "#FFFFFF",
    textAlign: "right"
  };
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { properties } = RegisterLoginAccount || "";
  const { image_web, title, title_ar } = properties || "";
  return (
    <Box
      className="WPrightpage"
      style={{
        backgroundImage: `url(${image_web})`,
        display: { xs: "none", lg: "flex" },
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box style={i18n.language === "arabic" ? styleArabic : styleEnglish}>
        {i18n.language === "arabic" ? title_ar : title}
      </Box>
    </Box>
  );
};

export default WpRightLogin;
