import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LengthControl, MapCategories } from "GlobalProcess/GlobalFunction";
import calculateBadgeText from "Assets/helper/badgeHelper";
import LinkType from "Components/LinkType";
import { useGetOnAppConfigContent } from "services/cms/cms.query";
import { DataSourceEnums, LinkTypeEnums } from "utils/enums";
import { useDataLayer, ITEMTYPE_MAP } from "hooks/useDataLayer";

export default function ActionAreaCard({
  card,
  padSet,
  color_definition,
  data_source
}) {
  const { i18n } = useTranslation();
  const { setClickEvent } = useDataLayer();
  const { data: appConfigContent = {} } = useGetOnAppConfigContent();
  const endLabels =
    appConfigContent?.items?.[0]?.fields?.configuration?.Configurations?.find(
      (item) => item?.configuration_name === "amber_badge_behaviors"
    )?.items;
  card.modifiedBadgeTemplate = calculateBadgeText(
    card.link_type,
    card.badge_behaviour,
    card.badge,
    card.badge_template,
    card.start_date,
    card.end_date,
    card.reading_time,
    endLabels,
    card.brand
  );

  // The onDataLayerClickEvent function processes click events and constructs tracking data for analytics purposes.
  //It extracts information from the card object, such as the link, link_type, brand_name, primary_category, and title.
  //The function then creates a data object to organize this information for tracking. The itemtype property of the data object is determined by mapping the link_type to its corresponding value in the ITEMTYPE_MAP. If the link_type is 'BRAND,' the brand property is included in the data object. Finally, the function sets the clickEvent state to the data object, making the structured data available for tracking user interactions with items or content.

  const onDataLayerClickEvent = () => {
    const { link, link_type, brand_name, primary_category, title } = card;
    let data = {
      page: link,
      itemtype: ITEMTYPE_MAP[link_type],
      brand: brand_name,
      category: primary_category,
      title: title
    };

    if (link_type === "BRAND") {
      data = {
        page: link,
        brand: card.brand,
        itemtype: ITEMTYPE_MAP[link_type]
      };
    }

    setClickEvent(data);
  };

  return (
    <Card
      onClick={onDataLayerClickEvent}
      sx={{
        maxWidth: "100%",
        boxShadow: "none",
        width: "100%",
        textAlign: "left",
        borderRadius: 0,
        backgroundColor: "transparent"
      }}
    >
      <LinkType link_type={card?.link_type} link={card?.link}>
        <Box
          sx={{
            position: "relative",
            textAlign: i18n.language === "arabic" ? "right" : "left"
          }}
        >
          <CardMedia
            component="img"
            height="100%"
            image={card.image}
            alt={card.text}
          />
          {(card.modifiedBadgeTemplate || card.badge) && (
            <Box
              className={
                i18n.language === "arabic"
                  ? "swiper-badge-arabic"
                  : "swiper-badge"
              }
              style={{
                backgroundColor: color_definition?.badge_fill
                  ? color_definition?.badge_fill
                  : "#FFFFFF",
                color: color_definition
                  ? color_definition?.badge_text
                  : "#000000",
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicMedium "
                    : "MontserratMedium",
                right: i18n.language === "arabic" ? "" : "8px",
                left: i18n.language === "arabic" ? "8px" : ""
              }}
            >
              {LengthControl(
                card.modifiedBadgeTemplate
                  ? card.modifiedBadgeTemplate
                  : card.badge,
                20
              )}
            </Box>
          )}

          <CardContent sx={{ padding: padSet || "16px 0px 16px 0px" }}>
            {data_source !== DataSourceEnums.EDITORIAL && card.brand && (
              <Box
                className={
                  i18n.language === "arabic"
                    ? "category-card-text-arabic"
                    : "category-card-text"
                }
                style={{
                  textAlign: i18n.language === "arabic" ? "right" : "left",
                  color: color_definition ? color_definition?.brand : "#000000",
                  background: "transparent"
                }}
              >
                {card.brand}
              </Box>
            )}
            {card?.title &&
            !(
              data_source === DataSourceEnums.BRAND ||
              card.link_type === LinkTypeEnums.BRAND
            ) ? (
              <Box
                className="home-info-card-brand-text mt-1"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  fontSize: i18n.language === "arabic" ? "17px" : "14px",
                  letterSpacing: 0,
                  padding: "8px 0px 0px 0px",
                  lineHeight: "130%",
                  textTransform: "none"
                }}
              >
                {LengthControl(card.title, 40)}
              </Box>
            ) : null}
            <Box
              style={{
                textAlign: i18n.language === "arabic" ? "right" : "left",
                color: color_definition
                  ? color_definition?.category
                  : "#5A5F64",
                background: "transparent"
              }}
              className={
                i18n.language === "arabic"
                  ? "category-card-subtext-arabic mt-1"
                  : "category-card-subtext mt-1"
              }
            >
              {(card?.categories || card?.primary_category) &&
                MapCategories(card?.categories || card?.primary_category)}
            </Box>
          </CardContent>
        </Box>
      </LinkType>
    </Card>
  );
}
