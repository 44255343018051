const en_messages = {
  "auth-phone-entry-post": "Your phone number is not valid. Please try again",
  "5002-auth-phone-entry-post": "Your account is inactivated. Please contact Amber Assist",
  "4000-auth-phone-entry-post": "OTP code has expired. Please try again",
  "4001-auth-phone-entry-post":
    "We've sent you the maximum number of OTPs. Please wait for 15 minutes and try again.",
  "4002-auth-phone-entry-post":
    "We've sent you the maximum number of OTPs. Please wait for 1 minute and try again.",
  "5000-auth-phone-entry-post":
    "This number is already registered with another account.",
  "5001-auth-phone-entry-post":
    "The mobile number you entered is not connected to an account",
  "auth-phone-validate-post":
    "The OTP you entered is incorrect. Please try again",
  "4001-auth-phone-validate-post":
    "You've entered the OTP code incorrectly multiple times. Please ensure you are entering the correct code and try again",
  "4002-auth-phone-validate-post":
    "The OTP you entered is incorrect. Please try again",
  "account-select-account-validate-post": "Unauthorized",
  "4000-account-select-account-otp-post":
    "OTP code has expired. Please try again",
  "4001-account-select-account-otp-post":
    "We've sent you the maximum number of OTPs. Please wait for 15 minutes and try again.",
  "4002-account-select-account-otp-post":
    "We've sent you the maximum number of OTPs. Please wait for 1 minute and try again.",
  "4001-auth-exist-email-validate-post":
    "You've entered the OTP code incorrectly multiple times. Please ensure you are entering the correct code and try again",
  "4002-auth-exist-email-validate-post":
    "The OTP you entered is incorrect. Please try again",
  "4000-auth-exist-email-otp-post": "OTP code has expired. Please try again",
  "4001-auth-exist-email-otp-post":
    "We've sent you the maximum number of OTPs. Please wait for 15 minutes and try again.",
  "4002-auth-exist-email-otp-post":
    "We've sent you the maximum number of OTPs. Please wait for 1 minute and try again.",
  "4001-account-select-account-validate-post":
    "You've entered the OTP code incorrectly multiple times. Please ensure you are entering the correct code and try again",
  "4002-account-select-account-validate-post":
    "The OTP you entered is incorrect. Please try again",
  "6900-auth-phone-validate-post": "OTP code has expired. Please try again"
};

const ar_messages = {
  "auth-phone-entry-post": "رقم هاتفك غير صحيح، يرجى المحاولة مرة أخرى.",
  "5002-auth-phone-entry-post": "تم تعطيل حسابك. يرجى الاتصال بخدمة عملاء أمبر",
  "4000-auth-phone-entry-post": "انتهت صلاحية رمزOTP. يرجى المحاولة مرة اخرى",
  "4001-auth-phone-entry-post":
    "لقد أرسلنا لك أقصى عدد من كلمات المرور لمرة واحدة. الرجاء الانتظار لمدة 15 دقيقة وحاول مرة أخرى.",
  "4002-auth-phone-entry-post":
    "لقد أرسلنا لك أقصى عدد من كلمات المرور لمرة واحدة. الرجاء الانتظار لمدة 1 دقيقة وحاول مرة أخرى.",
  "5000-auth-phone-entry-post": "هذا الرقم مُسجل مُسبقاً مع حساب آخر.",
  "5001-auth-phone-entry-post":
    "رقم الهاتف المحمول الذي أدخلته غيرمرتبط بأي حساب.",
  "auth-phone-validate-post":
    "كلمة المرور التي أدخلتها غير صحيحة. حاول مرة اخرى",
  "4001-auth-phone-validate-post":
    "لقد أدخلت رمز OTP بشكل غير صحيح عدة مرات. يُرجى التّأكد من إدخال الرمز بشكل صحيح.",
  "4002-auth-phone-validate-post":
    "كلمة المرور التي أدخلتها غير صحيحة. حاول مرة اخرى",
  "account-select-account-validate-post": "غير مصرح",
  "4001-account-select-account-validate-post":
    "لقد أدخلت رمز OTP بشكل غير صحيح عدة مرات. يُرجى التّأكد من إدخال الرمز بشكل صحيح.",
  "4002-account-select-account-validate-post":
    "كلمة المرور التي أدخلتها غير صحيحة. حاول مرة اخرى",
  "4000-account-select-account-otp-post":
    "انتهت صلاحية رمزOTP. يرجى المحاولة مرة اخرى",
  "4001-account-select-account-otp-post":
    "لقد أرسلنا لك أقصى عدد من كلمات المرور لمرة واحدة. الرجاء الانتظار لمدة 15 دقيقة وحاول مرة أخرى.",
  "4002-account-select-account-otp-post":
    "لقد أرسلنا لك أقصى عدد من كلمات المرور لمرة واحدة. الرجاء الانتظار لمدة 1 دقيقة وحاول مرة أخرى.",
  "4001-auth-exist-email-validate-post":
    "لقد أدخلت رمز OTP بشكل غير صحيح عدة مرات. يُرجى التّأكد من إدخال الرمز بشكل صحيح.",
  "4002-auth-exist-email-validate-post":
    "كلمة المرور التي أدخلتها غير صحيحة. حاول مرة اخرى",
  "4000-auth-exist-email-otp-post":
    "انتهت صلاحية رمزOTP. يرجى المحاولة مرة اخرى",
  "4001-auth-exist-email-otp-post":
    "لقد أرسلنا لك أقصى عدد من كلمات المرور لمرة واحدة. الرجاء الانتظار لمدة 15 دقيقة وحاول مرة أخرى.",
  "4002-auth-exist-email-otp-post":
    "لقد أرسلنا لك أقصى عدد من كلمات المرور لمرة واحدة. الرجاء الانتظار لمدة 1 دقيقة وحاول مرة أخرى.",
  "6900-auth-phone-validate-post":
    "انتهت صلاحية رمزOTP. يرجى المحاولة مرة اخرى."
};

const lang = localStorage.getItem("language");

const serviceMessages = lang === "arabic" ? ar_messages : en_messages;

export { serviceMessages };
