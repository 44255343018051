import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectPathPrevious } from "store/pathPreviousSlice";
import Promotions from "Pages/common/Promotions/Promotions";
import RewardList from "Pages/RewardsSystem/RewardList";
import { useGetPurchaseHistory } from "services/account/account.query";
import { NavBarContainer } from "Pages/NavBarContainer";
import { useGetBrands } from "services/brands/brands.query";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const userAgent = window?.navigator?.userAgent;

  useEffect(() => {
    dispatch(selectPathPrevious("/dashboard"));
  }, [dispatch]);

  const navigate = useNavigate();

  const {
    data: purchaseHistoryData,
    isSuccess,
    isLoading,
    isError
  } = useGetPurchaseHistory();
  const { data: brands = [] } = useGetBrands();
  const { widgets } = brands || {};

  const navbar = widgets?.find(
    (widget) => widget?.component_type === "web-homepage-main-navigation"
  );

  // It checks if the tableData array is not empty (tableData?.length !== 0).
  // If the tableData array is not empty, it filters the transactions using the filter method. The filter criteria is that each transaction must have a non-empty TransactionDate property (transaction.TransactionDate && transaction.TransactionDate.trim() !== "").
  // After filtering, it takes the first three transactions (assuming they're the most recent) using .slice(0, 3).
  // The resulting array of filtered transactions is stored in the filteredData variable.

  const filteredData = useMemo(() => {
    if (tableData?.length !== 0) {
      const filteredTransactions = tableData
        ?.filter((transaction) => {
          return (
            transaction.TransactionDate &&
            transaction.TransactionDate.trim() !== ""
          );
        })
        .slice(0, 3);

      return filteredTransactions;
    }
    return tableData;
  }, [tableData]);

  useEffect(() => {
    if (
      isSuccess &&
      purchaseHistoryData?.Customer?.[0]?.LoyaltyTransactions &&
      !isLoading
    ) {
      setTableData(
        purchaseHistoryData?.Customer?.[0]?.LoyaltyTransactions?.Transactions
      );
    }
  }, [isLoading, isSuccess, purchaseHistoryData]);

  return (
    <>
      {!userAgent?.includes("amber") && <NavBarContainer widget={navbar} />}
      <Promotions
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        tableData={filteredData}
        onClickViewAllButton={() => navigate("/purchase-history")}
        RewardList={<RewardList />}
      />
    </>
  );
};

export default Dashboard;
