import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Snackbar, Tooltip } from "@mui/material";
import copy from "copy-to-clipboard";

import PrimaryButton from "Pages/Account/components/PrimaryButton";
import { useIsMobileView } from "hooks";
import { DisplayOptionFunc } from "GlobalProcess/GlobalFunction";

const PromotionCodeButton = ({
  item,
  ButtonSelectColor,
  description,
  buttonStyle
}) => {
  const { i18n, t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { pathname } = useLocation();
  const [showPromotionCodeButton, setShowPromotionCodeButton] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      {showPromotionCodeButton ? (
        <Tooltip
          title={t("CopyToClipboard")}
          placement="top-start"
          disableFocusListener
          disableTouchListener
          arrow
        >
          <PrimaryButton
            style={{
              minWidth: isMobileView ? "auto" : "160px",
              gap: "10px",
              fontSize: i18n.language === "arabic" ? "15px" : "12px",
              ...buttonStyle,
              backgroundColor: "white",
              border: "2px dashed black",
              color: "black",
              marginTop: "0px",
              marginBottom: "12px",
              display: DisplayOptionFunc(
                item?.display_option,
                pathname?.includes("offer")
              )
                ? "block"
                : "none"
            }}
            text={description || ""}
            onClick={() => {
              copy(description || "");
              setOpen(true);
            }}
          ></PrimaryButton>
        </Tooltip>
      ) : (
        <PrimaryButton
          style={{
            maxWidth: isMobileView ? "auto" : "160px",
            gap: "10px",
            fontSize: i18n.language === "arabic" ? "15px" : "12px",
            ...buttonStyle,
            backgroundColor: ButtonSelectColor(item)
              ? ButtonSelectColor(item)?.cta_button_fill
              : "transparent",
            color: ButtonSelectColor(item)?.cta_button_text
              ? ButtonSelectColor(item)?.cta_button_text
              : "",
            border: `1px solid ${
              ButtonSelectColor(item)?.cta_button_border
                ? ButtonSelectColor(item)?.cta_button_border
                : ""
            }`,
            marginTop: "0px",
            marginBottom: "12px",
            display: DisplayOptionFunc(
              item?.display_option,
              pathname?.includes("offer")
            )
              ? "block"
              : "none"
          }}
          text={t("Tap to reveal code")}
          onClick={() => {
            setShowPromotionCodeButton(true);
          }}
        ></PrimaryButton>
      )}
       <Snackbar
          open={open}
          sx={{
            direction: i18n.language === "arabic" ? "rtl" : "ltr"
          }}
          autoHideDuration={2000}
          onClose={handleClose}
          message={
            <div
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium"
              }}
            >
              {t("Copied to Clipboard")}
            </div>
          }
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
    </>
  );
};

export default PromotionCodeButton;
