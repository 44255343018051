import Brightness1Icon from "@mui/icons-material/Brightness1";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { Checkbox } from "formik-mui";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  clearRouteCntrl,
  setPersonelInfo,
  setRouteCntrl
} from "store/authanticateSlice";
import { useAuthRegister, useAuthSetNumber } from "services/auth/auth.query";
import { saveToken } from "utils/authStorage";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import MaterialUIPickers from "Assets/Shared/Datepicker/MaterialUIPickers";
import { setBearerToken } from "services/apiClient";
import { useGetFetchAmberReferralProgram } from "services/account/account.query";
import { useDataLayer } from "hooks/useDataLayer";

const styleArabic = {
  fontSize: 18,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular",
  textAlign: "right"
};
const styleEnglish = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  textAlign: "left"
};

const styleMenuArabic = {
  display: "flex",
  justifyContent: "flex-end",
  fontFamily: "TajawalRegular",
  fontSize: 18
};
const styleMenu = {
  display: "flex",
  justifyContent: "flex-start",
  fontFamily: "MontserratRegular",
  fontSize: 16
};

const muihelper = {
  color: "#f66464 !important",
  marginLeft: " 0px !important",
  fontFamily: "MontserratRegular",
  fontSize: "14px !important",
  lineHeight: "130% !important",
  marginTop: "8px !important",
  textAlign: "left"
};
const muihelperarabic = {
  color: "#f66464 !important",
  marginRight: " 0px !important",
  fontFamily: "TajawalRegular",
  fontSize: "14px !important",
  lineHeight: "130% !important",
  marginTop: "8px !important",
  textAlign: "right"
};

const BasicSelect = ({
  setDynamic,
  initialPersonalDetails,
  email,
  referalCode,
  isExternal = false
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { setSignUpEvent } = useDataLayer();

  const {
    mutateAsync: register,
    isLoading: isAuthRegisterLoading,
    error: authRegisterError
  } = useAuthRegister({ onSuccess: () => {}, onError: () => {} });
  const {
    mutateAsync: setNumber,
    isLoading: isSetNumberLoading,
    error: setNumberRegisterError
  } = useAuthSetNumber({ onSuccess: () => {}, onError: () => {} });
  const { data: fetchamber } = useGetFetchAmberReferralProgram();
  const promo_code =
    fetchamber?.items?.[0]?.fields?.configuration?.configuration
      ?.core_refer_configuration?.promo_code;
  const isNewUser = !initialPersonalDetails;
  const initialValues = initialPersonalDetails || {
    firstName: "",
    lastName: "",
    gender: t("Please select"),
    date: "",
    advertisement: false,
    terms: !isNewUser,
    data_sharing: true
  };
  const validationSchema = yup.object({
    firstName: yup.string().required(t("First Name cannot be blank")),
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    lastName: yup.string().required(t("Last Name cannot be blank")),
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    date: yup
      .string()
      .nullable()
      .required(t("Date of birth should be selected"))
      .test(
        "minimumAge",
        t("You must be at least 15 years old to enroll in Amber."),
        (value) => {
          if (!value) return false; // Return false if the value is empty
          const currentDate = new Date();
          const selectedDate = new Date(value);
          const age = currentDate.getFullYear() - selectedDate.getFullYear();
          const monthDiff = currentDate.getMonth() - selectedDate.getMonth();
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && currentDate.getDate() < selectedDate.getDate())
          ) {
            // Decrease age by 1 if the current date is earlier than the selected date in the same birth month
            return age - 1 >= 15;
          }
          return age >= 15;
        }
      ),

    gender: yup
      .string()
      .oneOf(["0", "1", "99"], t("Gender cannot be blank"))
      .required("Gender cannot be blank"),
    terms: yup
      .bool()
      .oneOf([isNewUser], t("Please confirm you accept our Terms & Conditions"))
  });
  const genderCategory = [
    {
      value: "0",
      label: t("Male")
    },
    {
      value: "1",
      label: t("Female")
    },
    {
      value: "99",
      label: t("Not specified")
    }
  ];

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFirstsubmit(values);
    }
  });
  //The handleMainFunction function sanitizes and formats the firstName and lastName values in a Formik form by removing leading white spaces,
  // replacing consecutive white spaces with a single space, and filtering out non-alphabetical characters, including both Latin and Arabic letters.
  const handleMainFunction = () => {
    formik.values.firstName = formik.values.firstName
      .replace(/^\s+/, "")
      .replace(/\s{2,}/g, " ")
      .replace(/[^a-zA-Z\u0600-\u06FF\s]/g, "");
    formik.values.lastName = formik.values.lastName
      .replace(/^\s+/, "")
      .replace(/\s{2,}/g, " ")
      .replace(/[^a-zA-Z\u0600-\u06FF\s]/g, "");
  };

  //The formatDate function takes a date as input and returns it in the "YYYY-MM-DD" format after converting and formatting its components.

  const formatDate = (date) => {
    const dateObject = new Date(date.date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  //The handleFirstsubmit function handles the submission of a user's registration information, including registration or
  // update based on whether the user is new or existing, dispatching actions, and setting up user interaction events before navigating to the next step.

  const handleFirstsubmit = (values) => {
    const onSuccess = (response) => {
      saveToken(response.token);
      setBearerToken(response.token);
      const newRoute = { personal: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));
      dispatch(setPersonelInfo(values));

      setSignUpEvent("enter_first_name");
      setSignUpEvent("enter_last_name");
      setSignUpEvent("enter_gender");
      setSignUpEvent("enter_dob");

      navigate("/sign-up/country-selection", { state: { referalCode } });
    };
    if (isNewUser) {
      register({
        email,
        first_name: values.firstName,
        last_name: values.lastName,
        gender: Number(values.gender),
        birthday: formatDate(values),
        advertisement: values.advertisement,
        terms: values.terms,
        data_sharing: true,
        referalCode,
        promo_code
      }).then(onSuccess);
    } else {
      setNumber({
        first_name: values.firstName,
        last_name: values.lastName,
        gender: Number(values.gender),
        birthday: formatDate(values),
        referalCode,
        promo_code
      }).then(onSuccess);
    }
  };

  useEffect(() => {
    handleMainFunction();
  }, [formik.values.firstName, formik.values.lastName]);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setDynamic(true);
    } else setDynamic(false);
  }, [formik.errors]);

  // The inputCntrl function determines and returns a CSS style class based on the selected language.
  // If the language is "Arabic," it returns the styleArabic class; otherwise, it returns the styleEnglish class.
  //This function is used to apply different styles based on the language setting.
  const inputCntrl = () => {
    if (i18n.language === "arabic") {
      return styleArabic;
    } else {
      return styleEnglish;
    }
  };

  const serverErrorMessage = (setNumberRegisterError || authRegisterError)
    ?.response?.data?.message;
  const isLoading = isSetNumberLoading || isAuthRegisterLoading;

  return (
    <FormikProvider value={formik} style={{ marginBottom: "20px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={0}
          className="textfont"
          sx={{
            "& .MuiFormHelperText-root": {
              textAlign:
                i18n.language === "arabic" ? muihelperarabic : muihelper
            }
          }}
        >
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("First name")}
            </div>

            <TextField
              id="firstName"
              name="firstName"
              placeholder={t("First name")}
              autoComplete="off"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              helperText={
                formik.touched.firstName ? formik.errors.firstName : ""
              }
              variant="outlined"
              fullWidth
              inputProps={{
                style: inputCntrl(),
                maxLength: 20
              }}
              sx={{
                input: {
                  "&::placeholder": {
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
            />
          </Grid>{" "}
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Last name")}
            </div>

            <TextField
              id="lastName"
              name="lastName"
              autoComplete="off"
              placeholder={t("Last name")}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              helperText={formik.touched.lastName ? formik.errors.lastName : ""}
              variant="outlined"
              fullWidth
              inputProps={{
                style: inputCntrl(),
                maxLength: 20
              }}
              sx={{
                input: {
                  "&::placeholder": {
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
            />
          </Grid>{" "}
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Gender")}{" "}
            </div>

            <TextField
              select
              id="Gender"
              placeholder="Please Select"
              value={formik.values.gender}
              onChange={formik.handleChange("gender")}
              helperText={formik.touched.gender ? formik.errors.gender : ""}
              margin="dense"
              variant="outlined"
              fullWidth
              sx={{
                marginTop: "0px",
                ".MuiInputBase-input": {
                  padding: "12px 10px !important",
                  fontSize: "14px",
                  marginTop: "0px"
                },
                ".MuiSelect-select": {
                  overflow: "auto",
                  color:
                    formik.values.gender === t("Please select")
                      ? "#90969E"
                      : "#000000",
                  fontSize: "16px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular"
                      : "MontserratRegular"
                },
                ".MuiSvgIcon-root": {
                  left: i18n.language === "arabic" ? "7px !important" : "auto",
                  right: i18n.language === "arabic" ? "auto" : "7px !important",
                  "&.MuiSvgIcon-root path": {
                    d: 'path("M 7.41 8.59 L 12 13.17 l 4.59 -4.58 L 18 10 l -6 6 l -6 -6 l 1.41 -1.41 Z")'
                  },
                  "& input::placeholder": {
                    fontSize: "16px",
                    color: "#90969E",
                    opacity: "1"
                  }
                }
              }}
              PaperProps={{
                style: {
                  left: "50%",
                  transform: "translateX(-77%) translateY(32%)"
                }
              }}
            >
              <MenuItem
                style={i18n.language === "arabic" ? styleMenuArabic : styleMenu}
                value={t("Please select")}
                disabled
              >
                {t("Please select")}
              </MenuItem>
              {genderCategory.map((option) => (
                <MenuItem
                  style={
                    i18n.language === "arabic" ? styleMenuArabic : styleMenu
                  }
                  key={option.value}
                  value={option.value}
                >
                  {t(option.label)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "12px" }}>
            <div
              className={
                i18n.language === "arabic"
                  ? "phone-text-pd-arabic"
                  : "phone-text-pd"
              }
            >
              {t("Date of birth")}{" "}
            </div>

            <MaterialUIPickers
              value={formik.values.date}
              onChange={(date) => formik.setFieldValue("date", date)}
              helperText={formik.touched.date ? formik.errors.date : ""}
            />
          </Grid>
        </Grid>
        {isNewUser && (
          <>
            {" "}
            <div
              className={
                i18n.language === "arabic" ? "datetext-arabic" : "datetext"
              }
            >
              {" "}
              {t(
                "We use this to send you a birthday voucher once a year."
              )}{" "}
            </div>
            <div className="textdisplay">
              <div
                className={
                  i18n.language === "arabic"
                    ? "datetext-sub-arabic"
                    : "datetext-sub"
                }
              >
                <Field
                  name="advertisement"
                  type="checkbox"
                  component={Checkbox}
                  style={{ borderRadius: "50%" }}
                  icon={
                    <Brightness1Icon
                      style={{
                        color: "#DFE1EA",
                        backgroundColor: "#DFE1EA",
                        borderRadius: "50%",
                        fontSize: "1rem",
                        padding: "3px"
                      }}
                    />
                  }
                  checkedIcon={
                    <DoneIcon
                      style={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        fontSize: "1rem",
                        padding: "3px"
                      }}
                    />
                  }
                />

                <div>
                  {t(
                    "Yes, keep me updated on news, events, offers and promotions."
                  )}
                </div>
              </div>

              <div
                className={
                  i18n.language === "arabic"
                    ? "datetext-sub-arabic"
                    : "datetext-sub"
                }
              >
                <Field
                  name="terms"
                  type="checkbox"
                  component={Checkbox}
                  style={{ borderRadius: "50%" }}
                  icon={
                    <Brightness1Icon
                      style={{
                        color: "#DFE1EA",
                        backgroundColor: "#DFE1EA",
                        borderRadius: "50%",
                        fontSize: "1rem",
                        padding: "3px"
                      }}
                    />
                  }
                  checkedIcon={
                    <DoneIcon
                      style={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        fontSize: "1rem",
                        padding: "3px"
                      }}
                    />
                  }
                />

                <div>
                  {t("By signing up you agree to our")}{" "}
                  <Link
                    style={{ color: "#5A5F64" }}
                    to={`/legal/terms-and-conditions?external=${isExternal}`}
                    target={isExternal ? "_self" : "_blank"}
                  >
                    {t("Terms & Conditions")}
                  </Link>
                  {t(". Please visit our")}{" "}
                  <Link
                    style={{ color: "#5A5F64" }}
                    to={`/legal/privacy-policy?external=${isExternal}`}
                    target={isExternal ? "_self" : "_blank"}
                  >
                    {t("Privacy Policy")}
                  </Link>{" "}
                  {t("to understand how we handle your personal data.")}
                </div>
              </div>
              {formik.errors.terms && formik.touched.terms && (
                <div
                  className={
                    i18n.language === "arabic"
                      ? "datetext-sub-arabic"
                      : "datetext-sub"
                  }
                  style={{
                    paddingLeft: "34px",
                    color: "#F66464",
                    fontSize: "14px",
                    marginBottom: "8px",
                    marginTop: "8px"
                  }}
                >
                  {formik.errors.terms}
                </div>
              )}
            </div>
          </>
        )}

        {serverErrorMessage && (
          <AlertWarning marginbottom="24px" text={serverErrorMessage} />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" }
          }}
        >
          {isLoading ? (
            <ColorLoadingButton
              size="small"
              loading={true}
              variant="outlined"
              disabled
              type="submit"
              className="button-submit-loading-create-account"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                maxWidth: "100%"
              }}
            />
          ) : (
            <ColorButton
              type="submit"
              variant="contained"
              className="button-submit-pd"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium"
              }}
            >
              {t("Create account")}
            </ColorButton>
          )}
        </Box>
      </form>
    </FormikProvider>
  );
};

export default BasicSelect;
