import { Box, MenuItem, Select } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Vector from "Assets/images/Vector.svg";
import { ContactInfoContainer } from "./ContactInfoContainer";
import { WithAccordion } from "./WithAccordion";
import {
  menuTextStyle,
  menuTextStylearabic,
  selectText,
  selectTextArabic
} from "./styles";
import { getSelectedCountryFromStorage } from "utils/getSelectedCountryFromStorage";
import { useSelector } from "react-redux";
const CustomSelectIcon = (props) => {
  return <img src={Vector} alt="Custom Icon" {...props} />;
};
const country = getSelectedCountryFromStorage();

const LocationComponent = ({
  title,
  storeLocations,
  selectedValue,
  setSelectedValue
}) => {
  const { t, i18n } = useTranslation();
  const [isSelectOpen, setSelectOpen] = useState(false);
  const scrollRef = useRef(0);
  const { scrollInf } = useSelector((state) => state.public);

  // The provided code is a React component or function that contains several functions related to handling menus and filtering items. Here's a summary of what each function does:
  // handleMenuClose: This function is used to close a menu. It sets the selectOpen state to false, effectively closing the menu.
  // handleMenuOpen: This function is used to open a menu. It sets the selectOpen state to true, indicating that the menu is open.
  // flattenedStoresMall: This variable is assigned an array created by flattening the storeLocations object. It maps through the stores array within the storeLocations object, and for each store, it maps through the items array within the first country object. It returns an array of objects with additional properties, including store_iso_code.
  // filteredItems: This function takes an array of data as an argument and filters the array to remove any items that have empty or falsy values for label, address, contact_number, lat, or long. The function returns an array of filtered items with non-empty values for these properties.
  // filteredItemsArray:It filters an array of items retrieved by the filteredItems function. The filter criteria include checking if the store_iso_code property of each item matches the selectedValue variable.
  // store_country_mapped:It creates a new array called store_country_mapped by mapping over an array of storeLocations.stores. For each store in the array, it attempts to access the store.country[0].store_iso_code property.
  // handleChangeSelect:The code defines a function called handleChangeSelect, typically used as an event handler for a dropdown (select) element. When a change event occurs in the dropdown, the function retrieves the selected value from the event and updates the selectedValue state or variable. This function allows for handling and reacting to user selections in a dropdown menu.
  // These functions are used to control menus, manipulate store location data, and filter items based on certain criteria.

  const handleMenuClose = () => {
    setSelectOpen(false);
  };
  const handleMenuOpen = () => {
    scrollRef.current = scrollInf;
    setSelectOpen(true);
  };
  const flattenedStoresMall = storeLocations?.stores?.flatMap((store) => {
    return store?.country?.[0]?.items?.map((item) => {
      return {
        ...item,
        store_iso_code: store?.country?.[0]?.store_iso_code
      };
    });
  });

  const filteredItems = (data) => {
    if (!data) {
      return [];
    }

    return data.filter(
      (item) =>
        item?.label ||
        item?.address ||
        item?.contact_number ||
        item?.lat ||
        item?.long
    );
  };

  const filteredItemsArray = filteredItems(flattenedStoresMall).filter(
    (item) => item.store_iso_code === selectedValue
  );
  const store_country_mapped = storeLocations?.stores?.map(
    (store) => store?.country?.[0]?.store_iso_code
  );
  const malls = filteredItemsArray[0] || [];

  const handleChangeSelect = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    setSelectedValue(country?.iso_code);
  }, [setSelectedValue]);
  
  useEffect(() => {
    if (isSelectOpen && scrollRef.current !== scrollInf) {
      setSelectOpen(false);
    }
  }, [scrollInf, isSelectOpen, setSelectOpen, scrollRef]);
  return (
    <Box
      sx={{
        position: "relative",
        top: { xs: "48px", lg: "64px" },
        marginBottom: { xs: "48px", lg: "64px" },
        width: "100%"
      }}
    >
      <Box
        className="brands-deatil-head"
        sx={{
          marginBottom: {
            xs: "20px !important",
            md: "17px !important",
            alignItems: "center"
          }
        }}
      >
        {malls ? (
          <>
            <div
              className={
                i18n.language === "arabic"
                  ? "location-text-arabic"
                  : "location-text"
              }
            >
              {filteredItemsArray.length > 1 ? t("Locations") : null}
            </div>
            <Select
              onClose={handleMenuClose}
              onOpen={handleMenuOpen}
              open={isSelectOpen}
              displayEmpty
              sx={i18n.language === "arabic" ? selectTextArabic : selectText}
              IconComponent={CustomSelectIcon}
              value={selectedValue || null}
              onChange={handleChangeSelect}
            >
              {store_country_mapped?.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={
                    i18n.language === "arabic"
                      ? menuTextStylearabic
                      : menuTextStyle
                  }
                >
                  {t("Stores-text") + t(item)}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : null}
      </Box>
      {filteredItemsArray.length > 1 && (
        <WithAccordion
          selectedValue={selectedValue}
          malls={filteredItemsArray}
          renderContent={(mallItem) => (
            <ContactInfoContainer title={title} mall={mallItem} />
          )}
        />
      )}
      {filteredItemsArray.length <= 1 && malls && (
        <ContactInfoContainer title={title} mall={malls} />
      )}
    </Box>
  );
};

export default LocationComponent;
