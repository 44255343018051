import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import LineControlComp from "Components/LineControlComp";
import { AnchorLinkEnums } from "utils/enums";
import { useLocation } from "react-router-dom";

const AmberHead = ({
  description,
  color_theme,
  title,
  truncated,
  content_description_style
}) => {
  const { i18n } = useTranslation();
  const { color_definition } = color_theme || {};
  const colorDescription = color_definition?.description;
  const colorTitle = color_definition?.title;
  const BackgroundColor = color_definition?.background_color;
  const { pathname } = useLocation();

  const amberHeadClass = (currentLang) => {
    if (currentLang == "arabic") {
      if (pathname.includes("events-and-offers")) {
        return "as-head-arabic amberHeadTitle";
      } else {
        return "as-head-arabic";
      }
    } else {
      if (pathname.includes("events-and-offers")) {
        return "as-head amberHeadTitle";
      } else {
        return "as-head";
      }
    }
  };

  return (
    <Box
      className={pathname.includes("events-and-offers") ? "amber-app amberHeadContainer" : "amber-app"}
      id={
        title === "Offers" || title === "العروض"
          ? AnchorLinkEnums.OFFERS
          : AnchorLinkEnums.EVENTS
      }
      sx={{
        padding: {
          xs: "32px 20px 20px 24px",
          md: "80px 11.11% 56px 11.11%"
        },
        ...content_description_style,
        direction: i18n.language === "arabic" ? "rtl" : "ltr",
        backgroundColor: BackgroundColor || ""
      }}
    >
      <Box>
        <div
          className={amberHeadClass(i18n.language)}
          style={{ color: colorTitle || "" }}
        >
          {title || ""}
        </div>
        <div
          className={i18n.language === "arabic" ? "as-text-arabic" : "as-text"}
          style={{ color: colorDescription || "" }}
        >
          {truncated ? (
            <LineControlComp originalText={description} />
          ) : (
            description
          )}
        </div>
      </Box>
    </Box>
  );
};

export default AmberHead;
