import { useParams } from "react-router-dom";
import Layout from "../Layout";
import ErrorContainer from "../ErrorPages/ErrorContainer";
import ErrorComponent from "Components/ErrorComponent";
import { t } from "i18next";
import { Box, CircularProgress } from "@mui/material";
import { useGetEmailVerification } from "services/auth/auth.query";
import { useEffect, useState } from "react";

const VerifyEmail = () => {
  const { validationKey, secondKey } = useParams();
  const [tryFlag, setTryFlag] = useState(true);
  const [verification, setVerification] = useState(null);
  const { mutateAsync: getEmailVerification, isLoading } =
    useGetEmailVerification({
      onSuccess: (response) => {
        if (response?.success) {
          setVerification(true);
          setTryFlag(false);
        }
      },
      onError: (error) => {
        setVerification(false);
        setTryFlag(false);
      }
    });

  useEffect(() => {
    if (tryFlag) {
      getEmailVerification({ validationKey, secondKey });
    }
  }, [getEmailVerification, secondKey, tryFlag, validationKey]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isLoading ? "100vh" : "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  }

  return (
    <Layout navbar={"white"} className="brands">
      <ErrorContainer>
        <ErrorComponent
          Text={verification ? t("Email Verified") : t("Email Not Verified")}
          SmallText={
            verification
              ? t("Your Email address successfully verified")
              : t("Your Email address not verified")
          }
          margintp={24}
        />
      </ErrorContainer>
    </Layout>
  );
};

export default VerifyEmail;
