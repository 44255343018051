import { Box, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { MapCategories, LengthControl } from "GlobalProcess/GlobalFunction";
import LinkType from "Components/LinkType";
import calculateBadgeText from "Assets/helper/badgeHelper";
import { useGetOnAppConfigContent } from "services/cms/cms.query";
import LazyImage from "Components/LazyLoad/LazyImage";

const FeaturedItem = ({
  title,
  asset,
  asset_type,
  color_theme,
  header_text,
  category,
  brand,
  show_brand_title,
  badge,
  link_type,
  link,
  badge_behaviour,
  badge_template,
  start_date,
  end_date,
  reading_time,
  ui_properties
}) => {
  const { i18n } = useTranslation();
  const { data: appConfigContent = {} } = useGetOnAppConfigContent();

  const endLabels =
    appConfigContent?.items?.[0]?.fields?.configuration?.Configurations?.find(
      (item) => item?.configuration_name === "amber_badge_behaviors"
    )?.items;

  const mobile = useMediaQuery("(min-width:900px)");
  const { background_color, badge_fill, badge_text } =
    color_theme?.color_definition || {};
  const titleColor = color_theme?.color_definition?.title;
  const categoriesColor = color_theme?.color_definition?.category;
  const headerTextColor = color_theme?.color_definition?.header_text;
  const brandColor = color_theme?.color_definition?.brand;
  const { background } = ui_properties || {};

  const modifiedBadgeTemplate = calculateBadgeText(
    link_type,
    badge_behaviour,
    badge,
    badge_template,
    start_date,
    end_date,
    reading_time,
    endLabels
  );

  return (
    <>
      <Grid
        className="create-content-featured-Item"
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0 }}
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "100%",
          alignContent: "center",
          justifyContent: "space-between",
          backgroundColor: background || background_color || ""
        }}
        sx={{
          paddingTop: { xs: "32px", md: "48px" },
          paddingBottom: { xs: "32px", md: "40px" },
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" },
          display: "flex",
          flexDirection: i18n.language === "arabic" ? "row-reverse" : "row"
        }}
      >
        <Box
          className="column-rev"
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: {
              md:
                i18n.language === "arabic "
                  ? "row-reverse !important"
                  : "initial !important",
              xs: "column !important"
            },
            justifyContent: { md: "space-between" },
            alignItems: { md: "center" },
            columnGap: { md: "80px" },
            direction: i18n.language === "arabic" ? "rtl" : "ltr"
          }}
        >
          {header_text && !mobile && show_brand_title && (
            <Box
              className={
                i18n.language === "arabic"
                  ? "dynamic-ads-arabic"
                  : "dynamic-ads"
              }
              sx={{
                textAlign: i18n.language === "arabic" ? "right" : "left",
                fontFamily:
                  i18n.language === "arabic" ? "Tajawalbold " : "ArchivoBlack",
                letterSpacing:
                  i18n.language === "arabic" ? "0.02em !important" : "0.2em",
                marginBottom: "16px",
                fontSize:
                  i18n.language === "arabic" ? { xs: "18px" } : { xs: "14px" },
                lineHeight: i18n.language === "arabic" ? "110%" : { xs: "18px" }
              }}
              style={{ color: headerTextColor || "" }}
            >
              {LengthControl(header_text, 55)}
            </Box>
          )}
          <Box
            className="create-content-right-section"
            sx={{
              height: "100% !important",
              width: "100%"
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                width: "100%",
                position: "relative"
              }}
            >
              {(modifiedBadgeTemplate || badge) && (
                <Box
                  className={
                    i18n.language === "arabic"
                      ? "swiper-badge-arabic"
                      : "swiper-badge"
                  }
                  style={{
                    backgroundColor: badge_fill || "#FFFFFF",
                    color: badge_text || "#000000",
                    fontFamily:
                      i18n.language === "arabic"
                        ? "NotoNaskhArabicMedium "
                        : "MontserratMedium",

                    right: i18n.language === "arabic" ? "auto" : "8px",
                    left: i18n.language === "arabic" ? "8px" : "auto",
                    direction: i18n.language === "arabic" ? "rtl" : "ltr"
                  }}
                >
                  {LengthControl(modifiedBadgeTemplate || badge, 20)}
                </Box>
              )}
              {asset_type === "VIDEO" ? (
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={asset}
                    width="100%"
                    height="100%"
                    muted={true}
                    controls={false}
                    playing={true}
                    loop={true}
                  />
                </div>
              ) : (
                <LinkType link_type={link_type} link={link}>
                  <LazyImage
                    placeholderSrc={
                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    }
                    src={asset}
                    alt={"dynamic-ads"}
                    width={"100%"}
                    lazyclass="lazy"
                  />
                </LinkType>
              )}
            </Box>
          </Box>
          <Box
            className={
              i18n.language === "arabic"
                ? "create-content-left-section-arabic"
                : "create-content-left-section"
            }
            sx={{
              marginBottom: {
                xs: "24px !important",
                sm: "48px !important",
                md: "0px !important",
                height: "100% !important"
              },
              maxWidth: { xs: "100% !important", md: "520px !important" },
              width: "100%"
            }}
          >
            {" "}
            <LinkType link_type={link_type} link={link}>
              <Box
                className={
                  i18n.language === "arabic" ? "cc-div-arabic" : "cc-div"
                }
                style={{
                  maxWidth: { xs: "100% !important", md: "520px !important" },
                  justifyContent: "flex-start"
                }}
              >
                {header_text && mobile && (
                  <Box
                    className={
                      i18n.language === "arabic"
                        ? "dynamic-ads-arabic"
                        : "dynamic-ads"
                    }
                    sx={{
                      textAlign: i18n.language === "arabic" ? "right" : "left",
                      fontFamily:
                        i18n.language === "arabic"
                          ? "Tajawalbold "
                          : "ArchivoBlack",
                      letterSpacing:
                        i18n.language === "arabic"
                          ? "0.02em !important"
                          : "0.2em",

                      fontSize:
                        i18n.language === "arabic"
                          ? { xs: "18px" }
                          : { xs: "14px" },
                      lineHeight:
                        i18n.language === "arabic" ? "110%" : { xs: "18px" }
                    }}
                    style={{ color: headerTextColor || "" }}
                  >
                    {LengthControl(header_text, 55)}
                  </Box>
                )}
                {title && (
                  <>
                    {" "}
                    {i18n.language === "arabic" ? (
                      <Box
                        className="left-section-head-featured-items-arabic"
                        sx={{ textAlign: "right" }}
                        style={{
                          color: titleColor || ""
                        }}
                      >
                        {LengthControl(title, 30)}
                      </Box>
                    ) : (
                      <Box
                        className="left-section-head-featured-items"
                        sx={{ textAlign: "left" }}
                      >
                        <div
                          style={{
                            color: titleColor || ""
                          }}
                        >
                          {LengthControl(title, 50)}
                        </div>
                      </Box>
                    )}
                  </>
                )}
                {show_brand_title && brand && (
                  <Box
                    className={
                      i18n.language === "arabic"
                        ? "dynamic-ads-featured-item-arabic"
                        : "dynamic-ads-featured-item"
                    }
                    sx={{
                      textAlign: i18n.language === "arabic" ? "right" : "left",
                      fontFamily:
                        i18n.language === "arabic"
                          ? "Tajawalbold "
                          : "ArchivoBlack",
                      letterSpacing:
                        i18n.language === "arabic"
                          ? "0.02em !important"
                          : "0.2em",

                      fontSize:
                        i18n.language === "arabic"
                          ? { xs: "18px" }
                          : { xs: "14px" },
                      lineHeight:
                        i18n.language === "arabic" ? "110%" : { xs: "18px" }
                    }}
                    style={{ color: brandColor ? brandColor : "" }}
                  >
                    {LengthControl(brand, 30)}
                  </Box>
                )}
                <Box
                  className={
                    i18n.language === "arabic"
                      ? "left-section-text-featured-items-arabic"
                      : "left-section-text-featured-items"
                  }
                  sx={{
                    textAlign: i18n.language === "arabic" ? "right" : "left",
                    marginRight:
                      i18n.language === "arabic" ? "0px" : { xs: "0px" },
                    marginLeft: i18n.language === "arabic" ? "20px" : ""
                  }}
                  style={{ color: categoriesColor ? categoriesColor : "" }}
                >
                  {MapCategories(category)}
                </Box>
              </Box>
            </LinkType>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default FeaturedItem;
