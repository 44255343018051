import React, { useState, useEffect, useMemo, useCallback } from "react";
import logoUnion from "Assets/images/logo-dark.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import WprightPage from "Components/Authanticate/WprightPage";
import { Box, Grid } from "@mui/material";
import BasicSelectAmberAlliance from "Components/BasicSelectAmberAlliance";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BackButton from "Components/BackButton/index";

const AllianceOTP = () => {
  const [showAlert, setShowalert] = useState(false);
  const [showAlertCode, setShowalertCode] = useState(false);
  const [dynamic, setDynamic] = useState(false);
  const { initialPersonalDetails } = useLocation().state || {};

  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const { routeCntrl } = useSelector((state) => state.authanticate);

  const hasPhoneTrue = useMemo(() => {
    return routeCntrl.some((item) => item.otp === true);
  }, [routeCntrl]);

  const [showComponent, setShowComponent] = useState(hasPhoneTrue);
  const { alliance_id } = useSelector((state) => state.public);

  useEffect(() => {
    if (showAlert) {
      setShowalertCode(false);
    }
  }, [showAlert]);

  useEffect(() => {
    if (showAlertCode) {
      setShowalert(false);
    }
  }, [showAlertCode]);

  useEffect(() => {
    navigateProtect();
  }, []);

  //   If hasPhoneTrue is false, it uses the navigate function to redirect the user to the /amber-alliance/welcome route. This is typically done to guide users to a welcome or registration page when they haven't provided their phone information.
  // If hasPhoneTrue is true, it sets the showComponent state to true. This action likely controls the visibility or rendering of a specific component or section within the application.

  const navigateProtect = useCallback(() => {
    if (!hasPhoneTrue) {
      navigate(`/amber-alliance/welcome`);
    } else setShowComponent(true);
  }, [hasPhoneTrue, navigate, setShowComponent]);

  return (
    <>
      {showComponent && (
        <Box className="alliance" data-lang={i18n.language}>
          <div
            data-lang={i18n.language}
            className="WPleftpage"
            style={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
          >
            {" "}
            <BackButton path={"/alliance-otp"} />
            <div className="left-page-form">
              <div className="left-page-logo">
                <Link to="/">
                  <img src={logoUnion} alt="logo" width={"74.89px"} />
                </Link>
              </div>
              <div
                className={
                  i18n.language === "arabic"
                    ? "login-head-arabic"
                    : "login-head"
                }
              >
                <span>{t("Alliance")}</span>
              </div>

              <Grid
                container
                spacing={0}
                sx={{
                  width: "100%",
                  maxWidth: "460px",
                  marginTop: { xs: "16px", md: "24px" }
                }}
              >
                <Grid item xs={12}>
                  <Box
                    className={
                      i18n.language === "arabic"
                        ? "verify-text-arabic"
                        : "verify-text"
                    }
                  >
                    {t("Create Account")}
                  </Box>
                </Grid>
                <Grid xs={12} sx={{ marginTop: "16px" }}>
                  {" "}
                  <BasicSelectAmberAlliance
                    setDynamic={setDynamic}
                    navigate={navigate}
                    initialPersonalDetails={initialPersonalDetails}
                    alliance_id={alliance_id}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <WprightPage
            style={{
              left: i18n.language === "arabic" ? "0" : "auto",
              right: i18n.language === "arabic" ? "auto" : "0"
            }}
          />{" "}
        </Box>
      )}
    </>
  );
};

export default AllianceOTP;
