import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Vector from "Assets/images/Vector.svg";

const CustomSelectIcon = (props) => {
  return <img src={Vector} alt="Custom Icon" {...props} />;
};

export const WithAccordion = ({ malls = [], renderContent, selectedValue }) => {
  const { i18n } = useTranslation();
  const [expand, setExpand] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand(isExpanded ? panel : false);
  };
  useEffect(() => {
    setExpand(false);
  }, [selectedValue]);
  return malls.map((mall, index) => (
    <Accordion
      key={index}
      expanded={expand === index}
      onChange={handleChange(index)}
      sx={{
        backgroundColor: "transparent",
        color: "#90969e",
        fontFamily: "MontserratRegular",
        boxShadow: "none",
        "&.MuiAccordion-root:before": {
          height: "0px"
        },

        "&.MuiAccordion-root": {
          direction: i18n.language === "arabic" ? "rtr" : "ltl"
        }
      }}
    >
      <AccordionSummary
        expandIcon={<CustomSelectIcon sx={{ color: "#FFFFFF" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          padding: " 0px",
          display: "flex",
          borderBottom: expand === index ? "0px" : "1px solid #CED0D7",
          minHeight: "46px !important",
          "& .Mui-expanded ": {
            margin: "0px !important"
          },
          alignItems: "center"
        }}
      >
        <Box
          className={
            i18n.language === "arabic" ? "tick-text-arabic" : "tick-text"
          }
          sx={{
            display: "flex",
            paddingRight: "0px",
            alignItems: "center"
          }}
        >
          <span
            style={{
              width: 16,
              height: 16,
              backgroundColor: "black",
              color: "white",
              borderRadius: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "MontserratRegular",
              fontSize: 10,
              fontWeight: 500,
              lineHeight: 13,
              marginRight: i18n.language === "arabic" ? "auto" : 14,
              marginLeft: i18n.language === "arabic" ? 14 : "auto"
            }}
          >
            {index + 1}
          </span>
          <span style={{ lineHeight: "normal" }}>{mall.label}</span>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingLeft: "0px",
          paddingRight: "0px",
          padding: "4px 0px"
        }}
      >
        {renderContent(mall)}
      </AccordionDetails>
    </Accordion>
  ));
};
