import { apiClient } from "../apiClient";

export const getContract = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-contact-us",
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};
