import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InputValidationMessage } from "./InputValidationMessage";
import EmailCntrl from "./EmailCntrl";
import { useIsLanguageArabic } from "hooks";
import { useCountryList } from "hooks/onBoardingHooks";
import PhoneInput from "./PhoneInput";
const MobileNumberInput = ({
  formik,
  codeFieldProps,
  phoneFieldProps = {},
  errorMessage,
  setSendToPhone,
  setSendToCode,
  account
}) => {
  const { t, i18n } = useTranslation();
  const isLanguageArabic = useIsLanguageArabic();
  const countryList = useCountryList();

  //countryLengthControl(country_code): This function shortens a country_code string to a maximum length of 5 characters and appends ellipsis if the input string is longer. If the string is already 5 characters or shorter, it returns the original string.

  // handleChange(_, newValue): This function is likely an event handler for a form field. It uses the formik library to update the value of a form field. It also sets the value of sendToCode based on the country_code property of the newValue object if an account exists.

  const countryLengthControl = (country_code) => {
    if (country_code?.length > 5) {
      return country_code.slice(0, 5) + ".".repeat(country_code.length - 6);
    } else return country_code;
  };
  const handleChange = (_, newValue) => {
    formik.setFieldValue(codeFieldProps.name, newValue || {});
    account && setSendToCode(newValue?.country_code);
  };
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: "row" }}>
        <Autocomplete
          noOptionsText={
            <div
              style={{
                textAlign: "center",
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular"
              }}
            >
              {t("No Options")}
            </div>
          }
          loadingText={
            <div
              style={{
                textAlign: "center",
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {t("Loading")}
            </div>
          }
          disableClearable
          ListboxProps={{ className: "myCustomList" }}
          componentsProps={{
            paper: {
              sx: {
                minWidth: "100%"
              }
            }
          }}
          sx={{
            maxWidth: "90px",
            width: "100%",
            "& .MuiAutocomplete-endAdornment": {
              position: i18n.language === "arabic" ? "relative" : "",
              right: "0px !important",
              height: "100%"
            },
            "& .MuiAutocomplete-input": {
              padding: 0,
              fontSize: "16px"
            },
            input: {
              "&::placeholder": {
                color: "#90969E",
                opacity: "1"
              }
            }
          }}
          onChange={handleChange}
          popupIcon={<KeyboardArrowDownIcon />}
          options={countryList}
          autoHighlight
          getOptionLabel={({ country_code = "" } = {}) =>
            countryLengthControl(country_code)
          }
          isOptionEqualToValue={(option, value) => {
            return option.iso_code === value.iso_code;
          }}
          value={codeFieldProps.value}
          renderOption={(props, option) => (
            <li key={option.iso_code}>
              <Box
                component="li"
                key={option.iso_code}
                sx={{
                  "& > img": { mr: 0, flexShrink: 0 },
                  backgroundColor: "white !important",
                  paddingLeft:
                    i18n.language === "arabic"
                      ? "1px !important"
                      : "4px !important",
                  paddingRight:
                    i18n.language === "arabic"
                      ? "1px !important"
                      : "4px !important"
                }}
                {...props}
              >
                {i18n.language === "arabic" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      width: "100%"
                    }}
                  >
                    <span>{countryLengthControl(option.country_code)}</span>
                    <img
                      loading="lazy"
                      width="20"
                      src={option.flag}
                      style={{
                        position: "relative",
                        paddingRight: "4px"
                      }}
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      width: "100%"
                    }}
                  >
                    <img loading="lazy" width="20" src={option.flag} alt="" />
                    <span>{countryLengthControl(option.country_code)}</span>
                  </div>
                )}
              </Box>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name={codeFieldProps.name}
              style={{ fontSize: 14, paddingRight: 1 }}
              placeholder={t("Code")}
              autoComplete="off"
              InputProps={{
                style: {
                  padding: 2.5,
                  fontFamily: "MontserratRegular",
                  height: "41px",
                  fontSize: isLanguageArabic ? "19px" : "16px"
                },
                ...params.InputProps
              }}
              inputProps={{
                ...params.inputProps,
                style: {
                  direction: "ltr",
                  padding: "0 4px 0 6px",
                  ...params.inputProps.style
                }
              }}
            />
          )}
        />
        <PhoneInput
          textFieldProps={phoneFieldProps}
          name="phoneNumber"
          phone={phoneFieldProps.value}
          tick={phoneFieldProps.isValid ? <EmailCntrl /> : null}
          setSendToPhone={setSendToPhone}
          account={account}
        />
      </Grid>
      {errorMessage && <InputValidationMessage text={errorMessage} />}
    </>
  );
};

export default MobileNumberInput;
