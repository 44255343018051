import { create } from "zustand";

// customers: This state is used to store customer information and is initially set to null.
// setCustomers: This function is used to set customer information. You can use this function to update the customers state with customer data.
// removeCustomers: This function is used to remove or clear the customer information. It sets the customers state to null.

export const useSelectAccountCustomers = create((set) => ({
  customers: null,
  setCustomers: (customers) =>
    set(() => {
      return { customers };
    }),
  removeCustomers: () =>
    set(() => {
      return { customers: null };
    })
}));
