import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetContact } from "services/contractUs/contactUs.query";
const ContactUs = () => {
  const { i18n } = useTranslation();
  const { data: getContract } = useGetContact();
  const get_web_info = getContract?.items?.[0]?.fields?.configuration?.web;
  const { title, sub_title, support_information, items, background_color } =
    get_web_info || "";
  const commonStyle = {
    marginTop: "0px",
    fontFamily:
      i18n.language === "arabic" ? "TajawalMedium" : "MontserratMedium",
    fontSize: "14px",
    lineHeight: " 130%",
    padding: "12px 20px",
    width: "min-content",
    minWidth: i18n.language === "arabic" ? "115px" : "109px",
    borderRadius: "34px",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  return (
    <Box
      className="contact-us"
      data-lang={i18n.language}
      id="contact-us"
      sx={{
        padding: { xs: "40px 20px", md: "64px 11.11%" },
        backgroundColor: background_color || ""
      }}
    >
      <Box className="contact-us-container ">
        <div className="box-1">
          {title && (
            <div className="head" data-lang={i18n.language}>
              {title}
            </div>
          )}
          {sub_title && (
            <div className="text" data-lang={i18n.language}>
              {sub_title}
            </div>
          )}
        </div>
        <div className="box-2">
          {support_information?.title && (
            <div className="head" data-lang={i18n.language}>
              {support_information?.title}
            </div>
          )}
          {support_information?.days?.length && (
            <div className="text-space-container">
              {support_information?.days?.map((item) => (
                <div className="text-space">
                  {item?.day && (
                    <span className="text" data-lang={i18n.language}>
                      {item?.day}
                    </span>
                  )}
                  {item?.hour && (
                    <span className="text-hour" data-lang={i18n.language}>
                      {item?.hour}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Box>
      {items?.length > 0 && (
        <Box className="contact-us-container mobile-convert">
          {items?.map((item) => (
            <>
              {item?.enable && (
                <div className="box-3">
                  <div className="box-3-child">
                    {item?.title && (
                      <div className="head" data-lang={i18n.language}>
                        {item?.title}
                      </div>
                    )}
                    {item?.message && (
                      <div
                        className="text start-position"
                        data-lang={i18n.language}
                      >
                        {item?.id === "chat" && (item?.onlineStatus ? <div className="online"></div>: <div className="offline"></div>)}
                        {item?.message}
                      </div>
                    )}
                  </div>
                  {item?.cta?.text && (
                    <a
                      href={item?.cta?.link}
                      style={{
                        color: item?.cta?.text_color || "",
                        backgroundColor: item?.cta?.background_color || "",
                        ...commonStyle
                      }}
                    >
                      {item?.cta?.text}
                    </a>
                  )}
                </div>
              )}
            </>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ContactUs;
