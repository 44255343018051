import React from "react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import RewardCard from "./RewardCard";
import { dateDiffrence } from "dateUtil";

const RewardCarousel = ({ onClickItem = () => false, rewards = [] }) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      navigation={true}
      modules={[Navigation]}
      breakpoints={{
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
          slidesPerGroup: 2
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 35,
          slidesPerGroup: 3
        }
      }}
    >
      {rewards?.length > 0 &&
        rewards.map(
          (category, index) =>
            (!category?.image?.offerValidity ||
              dateDiffrence(category?.image?.offerValidity) >= 0) && (
              <SwiperSlide>
                <RewardCard
                  id={category.id}
                  couponBarcode={category?.image?.couponBarcode}
                  discount={category?.image?.discount}
                  discountType={category?.image?.discountType}
                  cardTitleDescription={category?.image?.cardTitleDescription}
                  offerStatus={category?.image?.offerStatus}
                  offerValidity={category?.image?.offerValidity}
                  cardBackgroundImage={category?.image?.cardBackgroundImage}
                  BrandLogo={category?.image?.BrandLogo}
                  brandName={category?.image?.brandName}
                  ctaText={category?.image?.ctaText}
                  cardDetailDescription={category?.image?.cardDetailDescription}
                  cardTheme={category?.image?.cardTheme}
                  expiredBrandLogo={category?.image?.expiredBrandLogo}
                  expiredCardBackgroundImage={
                    category?.image?.expiredCardBackgroundImage
                  }
                  expiredCardTheme={category?.image?.expiredCardTheme}
                  rewardType={category?.image?.rewardType}
                />
              </SwiperSlide>
            )
        )}
    </Swiper>
  );
};

export default RewardCarousel;
