import { useEffect, useMemo } from "react";
import Layout from "../Layout";
import InfCard from "Components/EventOffers/InfCard";
import { useGetEventsById, useGetEvents } from "services/events/events.query";
import { EVENT_DETAIL_COMPONENT_MAP } from "CmsComponents";
import { CarousalSublistContainer } from "CmsComponents/CarousalSublistContainer";
import DetailsText from "Components/DetailsText";
import { useNavigate, useParams } from "react-router-dom";
import { NavBarContainer } from "../NavBarContainer";
import { EventsOffersAsset } from "GlobalProcess/GlobalFunction";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDataLayer } from "hooks/useDataLayer";

const EventsDetail = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const { setImpressionEvent } = useDataLayer();
  const userAgent = window?.navigator?.userAgent;

  const {
    data: {
      brand,
      properties,
      ui_properties,
      brand_logo,
      primary_category,
      secondary_category,
      widgets = [],
      title,
      slug_url,
      seo_data
    } = {}
  } = useGetEventsById({ id });
  const { data: events = [] } = useGetEvents();
  const eventsswidgets = events?.widgets;

  // This code utilizes the useMemo hook to create a memoized object called eventDetailMapData. It iterates through an array called widgets, mapping each item to its component_type. The resulting eventDetailMapData object provides efficient lookups for component types and their associated widget objects. This memoization ensures that the object is recomputed only when the widgets array changes, improving performance.

  const eventDetailMapData = useMemo(() => {
    const componentMap = {};
    widgets?.forEach((item) => {
      componentMap[item.component_type] = item;
    });
    return componentMap;
  }, [widgets]);

  const navigate = useNavigate();

  const socialMediaHandles =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["social-media-handles"]];
  const schedule = eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["schedule"]];
  const eventlocation =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["event-location"]];
  const locationMap =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["location-map"]];
  const carousel =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["related-brand-articles"]];
  const contentDescription =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["content-description"]];
  const actionButtonsMapped = widgets?.filter(
    (widget) => widget?.component_type === "action-button"
  );
  const amberImages = widgets?.filter(
    (widget) => widget?.component_type === "amber-image"
  );
  const navbar = eventsswidgets?.find(
    (widget) => widget?.component_type === "web-homepage-main-navigation"
  );
  const content_main_banner =
    eventDetailMapData[EVENT_DETAIL_COMPONENT_MAP["content-main-banner"]];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (slug_url) {
      setImpressionEvent({
        page: slug_url,
        itemtype: "event",
        brand: brand,
        category: primary_category,
        title: title
      });
    }
  }, [primary_category, title, brand, slug_url, setImpressionEvent]);

  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);
  const { background } = ui_properties || {};

  return (
    <Layout seo_data={seo_data} className="brands">
      <>
        {!userAgent?.includes("amber") && <NavBarContainer widget={navbar} />}

        <InfCard
          eventFromTitle={"Event from"}
          brand={brand}
          brand_logo={brand_logo}
          title={title}
          dateText={schedule?.description}
          location={locationMap?.label}
          eventlocation={eventlocation}
          onClickButton={() => false}
          socialMediaHandles={socialMediaHandles}
          primary_category={primary_category}
          ui_properties={ui_properties}
          properties={properties}
          actionButtonsMapped={actionButtonsMapped}
          secondary_category={secondary_category}
        />
        <CarousalSublistContainer widget={carousel} />
        {content_main_banner?.asset && (
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: { xs: "0px !important" },
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              padding: { xs: "24px 20px ", md: " 48px 11.11%" },
              backgroundColor: background || ""
            }}
          >
            {EventsOffersAsset(content_main_banner)}
          </Grid>
        )}
        <DetailsText
          contentDescription={contentDescription}
          amberImages={amberImages}
          ui_properties={ui_properties}
        />
      </>
    </Layout>
  );
};

export default EventsDetail;
