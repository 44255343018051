import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BasicTable from "Assets/ResponsiveTable/Responsive";
import Layout from "../../Layout";
import BrowseButton from "./BrowseButton";
import PromotionHeader from "./PromotionHeaderDesktop";
import { useNavigate } from "react-router-dom";

export const EmptyPurchase = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onBrowseOffers = () => {
    navigate("/events-and-offers");
  };
  const onBrowseBrands = () => {
    navigate("/brands-and-stores");
  };

  return (
    <Grid className="purchase">
      <p className="no-purchase-title" data-lang={i18n.language}>
        {t("You haven’t made any purchases yet")}
      </p>
      <p className="no-purchase-text" data-lang={i18n.language}>
        {t("Your transaction history")}
      </p>
      <div className="actions-wrapper">
        <BrowseButton
          style={{ marginRight: 10 }}
          onClick={() => {
            onBrowseOffers();
          }}
        >
          {t("Browse offers")}
        </BrowseButton>
        <BrowseButton
          onClick={() => {
            onBrowseBrands();
          }}
        >
          {t("Browse brands")}
        </BrowseButton>
      </div>
    </Grid>
  );
};

const Promotions = ({
  tableData = [],
  onClickViewAllButton,
  RewardList = null,
  isSuccess,
  isLoading,
  isError
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout navbar={"logged"}>
      <PromotionHeader isTableDataEmpty={!tableData?.length} />
      {RewardList}
      {!isError && (
        <BasicTable
          isSuccess={isSuccess}
          isLoading={isLoading}
          data={tableData.reverse()}
          EmptyComponent={<EmptyPurchase />}
          onClickViewAllButton={onClickViewAllButton}
        />
      )}
    </Layout>
  );
};

export default Promotions;
