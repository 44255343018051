import { apiClient } from "../apiClient";

export const getBrands = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-brands-and-stores-content",
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};

export const getBrandsLoad = async ({
  segment = "Default",
  data_source,
  limit,
  skip,
  category
} = {}) => {
  return apiClient({
    url: `/cms/fetch-all-contents?data_source=${data_source}&limit=${limit}&category=${category}&skip=${skip}`,
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};

export const getBrandById = async ({ segment = "Default", id }) => {
  return apiClient({
    url: `/cms/fetch-brands-content/${id}`,
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};

export const getRecordIdsAndTypes = async ({
  segment = "Default",
  data_source
} = {}) => {
  return apiClient({
    url: `/cms/fetch-brands-item-count`,
    customConfig: {
      params: {
        segment,
        data_source
      }
    }
  }).then((res) => res.data);
};
