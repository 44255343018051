import { apiClient } from "../apiClient";

export const getEditorials = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-editorials-content",
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};

export const getEditorialsDetail = async ({ segment = "Default", id }) => {
  return apiClient({
    url: `/cms/fetch-editorials-detail-content/${id}`,
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};
