import { SELECTED_LANGUAGE } from "constants";
import { LangMap } from "i18n";
import { setSelectedLanguageCodeToStorage } from "./setSelectedLanguageCodeToStorage";

// getSelectedLanguageCodeFromStorage: This function attempts to retrieve the selected language code from local storage using the key SELECTED_LANGUAGE. It does so by calling localStorage.getItem(SELECTED_LANGUAGE).

// If the value is found and it's not null, the function returns the retrieved language code.

// If the value is not found (i.e., selectedLanguageCode is falsy), it sets the selected language code to a default language code (in this case, LangMap.en represents English) using setSelectedLanguageCodeToStorage(LangMap.en) and then returns the default language code.

export const getSelectedLanguageCodeFromStorage = () => {
  const selectedLanguageCode = localStorage.getItem(SELECTED_LANGUAGE);
  if (!selectedLanguageCode) {
    setSelectedLanguageCodeToStorage(LangMap.en);
    return LangMap.en;
  }
  return selectedLanguageCode;
};
