import { Grid, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InputValidationMessage } from "./InputValidationMessage";

const CustomTextField = ({
  placeholder,
  textFieldStyle = {},
  inputProps: { style = {}, ...otherInputProps } = {},
  errorMessage = "",
  InputProps = {},
  paddingStyle,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Grid style={{ width: "100%" }}>
      <TextField
        autoComplete="off"
        sx={{
          width: "100% !important",
          "& .MuiFormHelperText-root": {
            color: " #f66464",
            marginLeft: "0px ",
            fontFamily:
              i18n.language === "arabic"
                ? "TajawalRegular"
                : "MontserratRegular",
            fontSize: "14px",
            lineHeight: "130%",
            marginTop: "8px"
          },
          input: {
            "&::placeholder": {
              color: "#90969E",
              opacity: "1",
              fontSize: "16px",
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalRegular"
                  : "MontserratRegular"
            }
          },
          ...textFieldStyle
        }}
        placeholder={t(placeholder)}
        size="small"
        InputProps={{
          style: {
            height: "41px"
          },
          ...InputProps
        }}
        inputProps={{
          style: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "130%",
            fontStyle: "normal",
            fontFamily:
              i18n.language === "arabic"
                ? "TajawalRegular"
                : "MontserratRegular",
            padding: paddingStyle || "10px 12px",
            ...style
          },
          ...otherInputProps
        }}
        {...props}
      />
      {errorMessage && <InputValidationMessage text={t(errorMessage)} />}
    </Grid>
  );
};

export default CustomTextField;
