import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phoneNumber: "",
  phoneCode: "971",
  isoCode: "",
  otp: "123456",
  email: "",
  selectcountry: "",
  personalInfo: {},
  isExternal: false,
  externalAppType: "",
  externalDeviceType: "",
  routeCntrl: []
};

const authanticateSlice = createSlice({
  name: "authanticate",
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setPhoneCode: (state, action) => {
      state.phoneCode = action.payload;
    },
    setOtpCode: (state, action) => {
      state.otp = action.payload;
    },
    setIsoCode: (state, action) => {
      state.isoCode = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPersonelInfo: (state, action) => {
      state.personalInfo = action.payload;
    },
    setSelectCountry: (state, action) => {
      state.selectcountry = action.payload;
    },
    setIsExternal: (state, action) => {
      state.isExternal = action.payload;
    },
    setExternalAppType: (state, action) => {
      state.externalAppType = action.payload;
    },
    setExternalDeviceType: (state, action) => {
      state.externalDeviceType = action.payload;
    },
    setRouteCntrl: (state, action) => {
      state.routeCntrl = [...state.routeCntrl, action.payload];
    },
    clearRouteCntrl: (state) => {
      state.routeCntrl = [];
    },
    resetState: (state, isExternal = false) => {
      state = { ...initialState, isExternal };
    }
  }
});
export const {
  setPhoneNumber,
  setPhoneCode,
  setOtpCode,
  setIsoCode,
  setEmail,
  setPersonelInfo,
  setSelectCountry,
  setIsExternal,
  setExternalAppType,
  setExternalDeviceType,
  setRouteCntrl,
  clearRouteCntrl,
  resetState
} = authanticateSlice.actions;
export default authanticateSlice.reducer;
