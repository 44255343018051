import { useQuery } from "@tanstack/react-query";
import * as Requests from "./cms.requests";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetHomeContent = () => {
  const tierValue = useTierValue();
  return useQuery(["fetch-home-content", tierValue], () => {
    return Requests.getHomeContent({ segment: tierValue });
  });
};
export const useGetHowItWorksContent = () => {
  const tierValue = useTierValue();
  return useQuery(["fetch-how-it-works-content", tierValue], () => {
    return Requests.getHowItWorksContent({ segment: tierValue });
  });
};
export const useGetAmberAppContent = () => {
  const tierValue = useTierValue();
  return useQuery(["fetch-amber-app-content", tierValue], () => {
    return Requests.getAmberAppcontent({ segment: tierValue });
  });
};
export const useGetOnBoardingContent = () => {
  return useQuery(
    ["on-boarding-content"],
    () => {
      return Requests.getOnBoardingContent();
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};
export const useGetOnAppConfigContent = () => {
  return useQuery(
    ["fetch-app-config-content"],
    () => {
      return Requests.getOnAppConfigContent();
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

export const useGetTierCards = () => {
  return useQuery(
    ["fetch-tier-cards-content"],
    () => {
      return Requests.getTierCards();
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};
