import moment from "moment";
import { getSelectedLanguageCodeFromStorage } from "utils/getSelectedLanguageCodeFromStorage";
import { find } from "lodash";

function calculateBadgeText(
  linkType,
  behaviour,
  badge,
  template,
  startDate,
  endDate,
  readingTime,
  endLabels
) {
  if (typeof badge != "undefined") {
    return badge;
  }

  if (typeof behaviour === "undefined" || behaviour === "NONE") {
    return false;
  }

  if (
    behaviour !== "NONE" &&
    (typeof template === "undefined" || template === "" || template === null)
  ) {
    return false;
  }

  const currentDate = moment();
  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);

  //This function, setDateDiff, constructs a human-readable text description of a date difference based on the provided parameters,
  // handling special cases for Arabic and English language settings.

  const setDateDiff = function (template, behaviour, value) {
    if (typeof template != "undefined" && template != null) {
      let dateText = value;
      if (behaviour === "WEEK") {
        if (value <= 1) {
          dateText =
            getSelectedLanguageCodeFromStorage() === "arabic"
              ? "1 أسبوع"
              : "1 Week";
        } else {
          dateText += " Weeks";
        }
      } else {
        if (value <= 1) {
          dateText =
            getSelectedLanguageCodeFromStorage() === "arabic"
              ? "1 يوم"
              : "1 Day";
        } else {
          dateText += " Days";
        }
      }

      if (getSelectedLanguageCodeFromStorage() === "arabic") {
        dateText = dateText.replace("Weeks", "أسابيع");
        dateText = dateText.replace("Days", "أيام");
      }

      return template.replace("{{VALUE}}", dateText);
    }
    return template;
  };

  //This function, setDateRange, generates a date range string with replaced month names for Arabic language and inserts it into the provided template.
  const setDateRange = function (template, start, end) {
    if (getSelectedLanguageCodeFromStorage() === "arabic") {
      start.month = start.month.replace("Jan", "يناير");
      start.month = start.month.replace("Feb", "فبراير");
      start.month = start.month.replace("Mar", "مارس");
      start.month = start.month.replace("Apr", "أبريل");
      start.month = start.month.replace("May", "مايو");
      start.month = start.month.replace("Jun", "يونيو");
      start.month = start.month.replace("Jul", "يوليو");
      start.month = start.month.replace("Aug", "أغسطس");
      start.month = start.month.replace("Sep", "سبتمبر");
      start.month = start.month.replace("Oct", "أكتوبر");
      start.month = start.month.replace("Nov", "نوفمبر");
      start.month = start.month.replace("Dec", "ديسمبر");

      end.month = end.month.replace("Jan", "يناير");
      end.month = end.month.replace("Feb", "فبراير");
      end.month = end.month.replace("Mar", "مارس");
      end.month = end.month.replace("Apr", "أبريل");
      end.month = end.month.replace("May", "مايو");
      end.month = end.month.replace("Jun", "يونيو");
      end.month = end.month.replace("Jul", "يوليو");
      end.month = end.month.replace("Aug", "أغسطس");
      end.month = end.month.replace("Sep", "سبتمبر");
      end.month = end.month.replace("Oct", "أكتوبر");
      end.month = end.month.replace("Nov", "نوفمبر");
      end.month = end.month.replace("Dec", "ديسمبر");
    }
    let range = `${start.day} ${start.month} - ${end.day} ${end.month}`;

    /*if(getSelectedLanguageCodeFromStorage()==="arabic") {
            // startText = `${start.month} ${start.day}`;
            // endText = `${start.month} ${start.day}`;

            range = `${start.month} ${start.day} - ${end.month} ${end.day}`;
        }*/

    return template.replace("{{VALUE}}", range);
  };

  //This function, setEnded, is used to determine the label or text that indicates the end of a specific entity,
  // taking into account different conditions, including language settings and predefined labels.
  const setEnded = function (linkType, xxx) {
    if (typeof endLabels !== "undefined") {
      let searchKey;
      if (linkType === "EVENTS") {
        searchKey = "EVENT_END";
      } else if (linkType === "OFFERS") {
        searchKey = "OFFER_END";
      }

      let labelResult = find(endLabels, ["event_id", searchKey]);

      if (
        typeof labelResult !== "undefined" &&
        typeof labelResult.default_label !== "undefined"
      ) {
        return labelResult.default_label;
      }
    }

    if (getSelectedLanguageCodeFromStorage() === "arabic") {
      if (linkType === "EVENTS") {
        return "الحدث انتهى";
      } else if (linkType === "OFFERS") {
        return "العرض انتهى";
      }
    } else {
      if (linkType === "EVENTS" || linkType === "OFFERS") {
        return (
          linkType
            .toLowerCase()
            .replace(linkType[0].toLowerCase(), linkType[0].toUpperCase())
            .slice(0, linkType.length - 1) + " Ended"
        );
      }
    }
    return template;
  };

  //This function, setMinRead, is used to replace the {{MIN_READ}} placeholder in a given template with a specified value. It checks if the template is defined, not null, and contains the {{MIN_READ}} placeholder. If these conditions are met and value is defined, it replaces the placeholder with the provided value.
  //If the {{MIN_READ}} placeholder exists in the template but the value is undefined, it simply removes the placeholder.
  //If none of the above conditions apply, it returns the original template without any modifications. This function is useful for dynamic text replacements in templates.

  const setMinRead = function (template, value) {
    if (
      typeof template !== "undefined" &&
      template !== null &&
      typeof value !== "undefined" &&
      template?.indexOf("{{MIN_READ}}") !== -1
    ) {
      return template.replace("{{MIN_READ}}", value);
    } else if (
      template?.indexOf("{{MIN_READ}}") !== -1 &&
      typeof value === "undefined"
    ) {
      return template.replace("{{MIN_READ}}", "");
    }
    return template;
  };

  template = setMinRead(template, readingTime);
  if (template === false) {
    return false;
  }

  //This code handles different scenarios based on the behaviour variable, calculating date differences and returning corresponding text or false depending on the specific conditions.
  if (typeof behaviour !== "undefined") {
    const eventEndDiff = mEndDate.diff(currentDate, "minutes");

    if (eventEndDiff <= 0) {
      return setEnded(linkType, eventEndDiff);
    }

    if (behaviour === "WEEK" || behaviour === "DAYS") {
      let dateDiffType = "weeks";
      if (behaviour === "WEEK") {
        dateDiffType = "weeks";
      } else if (behaviour === "DAYS") {
        dateDiffType = "days";
      }

      const startDateCurrentDateDiff = mStartDate.diff(
        currentDate,
        dateDiffType,
        true
      );
      const endDateCurrentDateDiff = mEndDate.diff(
        currentDate,
        dateDiffType,
        true
      );

      if (startDateCurrentDateDiff <= 0) {
        let roundedDiff = Math.ceil(endDateCurrentDateDiff);
        return setDateDiff(template, behaviour, roundedDiff);
      } else {
        let roundedDiff = Math.ceil(startDateCurrentDateDiff);
        return setDateDiff(template, behaviour, roundedDiff);
      }
    } else if (behaviour === "DATE_RANGE") {
      return setDateRange(
        template,
        {
          day: mStartDate.format("DD"),
          month: mStartDate.format("MMM")
        },
        {
          day: mEndDate.format("DD"),
          month: mEndDate.format("MMM")
        }
      );
    } else if (
      behaviour === "MANUAL" &&
      typeof template !== "undefined" &&
      template !== ""
    ) {
      return template;
    }

    return false;
  }
}

export default calculateBadgeText;
