import React from "react";
import { useTranslation } from "react-i18next";

import emailvalidate from "Assets/images/emailvalidate.png";

const EmailCntrl = ({ referalCode, validStyle }) => {
  const { i18n } = useTranslation();

  return (
    <>
      {referalCode ? (
        <img style={validStyle} src={emailvalidate} alt="emailvalidate" />
      ) : (
        <img
          style={{
            position: "absolute",
            right: i18n.language === "arabic" ? "auto" : "14.65px",
            left: i18n.language === "arabic" ? "14.65px" : "auto",
            top: "50%",
            transform: "translate(0%, -50%)"
          }}
          src={emailvalidate}
          alt="emailvalidate"
        />
      )}
    </>
  );
};

export default EmailCntrl;
