import React from "react";
import { Grid } from "@mui/material";
import ItemInfo from "./item";
import { useTranslation } from "react-i18next";
import InfoButtonBox from "./InfoButtonBox";

const Infolist = ({
  helperArray,
  marginHome,
  color_theme,
  small_bullet_image
}) => {
  const { i18n } = useTranslation();
  const { background_color } = color_theme?.color_definition || {};
  return (
    <Grid
      container
      rowSpacing={0}
      columnSpacing={{ xs: 0, md: "50px" }}
      sx={{
        paddingBottom: marginHome
          ? { xs: "32px", md: "80px" }
          : { xs: "0%", xl: "0%" },
        paddingLeft: { xs: "20px", md: "11.11%" },
        paddingRight: { xs: "20px", md: "11.11%" },
        paddingTop: { xs: "0px", md: "30px" },
        backgroundColor: background_color || "var(--primary-white, #FFF)",
        direction: i18n.language === "arabic" ? "rtl" : "ltr",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      {helperArray.slice(0, 3).map((info) => (
        <Grid
          key={info.id}
          item
          xs={12}
          md={4}
          sx={{
            textAlign: i18n.language === "arabic" ? "right" : "left",
            height: "100%",
            direction: i18n.language === "arabic" ? "rtl" : "ltr",
            flex: 1,
            display: "flex",
            flexDirection: "column"
          }}
        >
          <ItemInfo
            item={info}
            color_theme={color_theme}
            small_bullet_image={small_bullet_image}
          />
        </Grid>
      ))}
      {helperArray.slice(0, 3).map((info) => (
        <Grid
          key={info.id}
          item
          xs={12}
          md={4}
          sx={{
            textAlign: i18n.language === "arabic" ? "right" : "left",
            height: "100%",
            direction: i18n.language === "arabic" ? "rtl" : "ltr",
            flex: 1,
            flexDirection: "row",
            display: { xs: "none", md: "flex" },
            marginTop: "20px"
          }}
        >
          <InfoButtonBox item={info} color_theme={color_theme} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Infolist;
