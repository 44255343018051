import CardMedia from "@mui/material/CardMedia";
import * as React from "react";
export default function ActionAreaCard({ card }) {
  return (
    <CardMedia
      component="img"
      height="100%"
      image={card.image}
      alt={card.text}
    />
  );
}
