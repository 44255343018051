const NUMBER_REGEX = /^\d+$/;

//Regular Expression: It defines a regular expression NUMBER_REGEX using the RegExp constructor. This regular expression checks for values that consist only of one or more digits (0-9). The ^\d+$ pattern means the string should start and end with one or more digits.

// Function Input: The function numberOrEmpty takes one parameter value, which is the value to be checked.

// Return Value: The function uses the test method of the NUMBER_REGEX regular expression to check if the value matches the pattern. The test method returns true if the value is a number (consisting of digits) or empty, and it returns false otherwise.

// Outcome: The function returns true if the value is a number or empty, and it returns false if the value contains non-digit characters or is not empty.

export const numberOrEmpty = (value) => {
  return !value || NUMBER_REGEX.test(value);
};
