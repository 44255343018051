import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import EmailSettings from "Pages/Account/EmailSettings/EmailSettings";
import LanguageSettings from "Pages/Account/LanguageSettings/LanguageSettings";
import MobileNumberSettings from "Pages/Account/MobileNumberSettings/MobileNumberSettings";
import NotificationSettings from "Pages/Account/NotificationSettings/NotificationSettings";
import PersonalDetailsSettings from "Pages/Account/PersonalDetailsSettings/PersonalDetailsSettings";
import AccountSettings from "Pages/Account/AccountSettings";

export const AccountRoutes = (props) => {
  return (
    <Routes>
      <Route path="/*" element={<AccountSettings />}>
        <Route path="personal-details" element={<PersonalDetailsSettings />} />
        <Route path="mobile-number" element={<MobileNumberSettings />} />
        <Route path="email" element={<EmailSettings />} />
        <Route path="notifications" element={<NotificationSettings />} />
        <Route path="language" element={<LanguageSettings />} />
        <Route path="*" element={<Navigate to="personal-details" replace />} />
      </Route>
    </Routes>
  );
};
