import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { CategoriesRule } from "GlobalProcess/GlobalFunction";
import LazyImage from "Components/LazyLoad/LazyImage";

const BrandsLightBackground = ({
  brand,
  primaryCategory,
  logo,
  ui_properties,
  secondary_category
}) => {
  const { i18n } = useTranslation();

  return (
    <Grid
      className="reward-system"
      container
      rowSpacing={2}
      columnSpacing={{ xs: 0 }}
      sx={{
        marginTop: { xs: "22px", md: "30px" },
        height: "100%",
        minHeight: "216px",
        background: "#FFFFFF",
        display: "flex",
        justifyContent: { xs: "flex-start" },
        alignItems: { xs: "flex-start", md: "center" },
        direction: i18n.language === "arabic" ? "rtl" : "ltr",
        backgroundColor: "transparent !important"
      }}
    >
      <Box
        sx={{
          maxWidth: "190px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <LazyImage
          placeholderSrc={
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          }
          lazyclass="lazy"
          src={logo}
          width={"128px"}
          alt="BrandsIcon"
        />
      </Box>
      <Box
        sx={{
          maxWidth: "690px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: { xs: "100%", md: "100%" },
          alignItems: "center",
          minHeight: "90px",
          marginLeft:
            i18n.language === "arabic"
              ? { xs: "auto" }
              : { xs: "0px", lg: "80px" },
          marginRight:
            i18n.language === "arabic"
              ? { xs: "0px", lg: "80px" }
              : { xs: "auto" },
          marginBottom: { xs: "24px", lg: "0px" }
        }}
      >
        <div
          className={
            i18n.language === "arabic"
              ? "barnd-head-details-arabic"
              : "barnd-head-details"
          }
          style={{
            color: ui_properties?.title || "#000000"
          }}
        >
          {brand}
        </div>
        <div
          className={
            i18n.language === "arabic"
              ? "brand-head-tiny-text-arabic"
              : "brand-head-tiny-text"
          }
          style={{ textAlign: i18n.language === "arabic" ? "right" : "left" }}
        >
          {CategoriesRule(primaryCategory, secondary_category)}
        </div>
      </Box>
    </Grid>
  );
};

export default BrandsLightBackground;
