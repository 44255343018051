import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import LinkType from "Components/LinkType";
import ViewAllButton from "Assets/ViewAllButton";
import { LengthControl } from "GlobalProcess/GlobalFunction";
import LazyImage from "Components/LazyLoad/LazyImage";

const ItemInfo = ({ item, color_theme, small_bullet_image }) => {
  const { i18n } = useTranslation();
  const tabletMax = useMediaQuery("(max-width:899px)");

  const {
    description,
    title,
    cta_button_fill,
    cta_button_text,
    cta_button_border
  } = color_theme?.color_definition || {};
  const { image, link, link_type, bullets_cta_button } = item || {};

  const englishText = {
    width: { xs: "123px", md: "119px" },
    fontFamily: "MontserratMedium",
    fontSize: "12px",
    lineHeight: "130%",
    backgroundColor: cta_button_fill || "#F3F3FA",
    color: cta_button_text || "#000000",
    border: `1px solid ${cta_button_border || "#000000"}`
  };
  const arabicText = {
    width: { xs: "123px", md: "119px" },
    fontFamily: "TajawalMedium",
    fontStyle: "normal",
    fontweight: "500",
    fontSize: "15px",
    lineHeight: "130%",
    backgroundColor: cta_button_fill || "#F3F3FA",
    color: cta_button_text || "#000000",
    border: `1px solid ${cta_button_border || "#000000"}`
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: !small_bullet_image && "flex-start",
        minHeight: !small_bullet_image && {
          sm: "300px",
          md: "350px",
          lg: "400px",
          xl: "450px"
        },
        gap: !small_bullet_image && { xs: "20px", md: "0px" }
      }}
    >
      {" "}
      <LinkType link_type={link_type} link={link}>
        <Box
          sx={{
            width: small_bullet_image ? 56 : "100%",
            height: small_bullet_image ? 56 : "100%",
            backgroundColor: image ? "transparent" : "#D9D9D9",
            marginBottom: "18px",
            marginTop: "32px"
          }}
        >
          {small_bullet_image && (
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={image}
              height="56px"
              width="56px"
            />
          )}
          {!small_bullet_image && (
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={image}
              height="100%"
              width="100%"
            />
          )}
        </Box>
        <Box
          className="home-info-card-head"
          style={{
            position: "relative",
            top: "0px",
            marginTop: "2px",
            color: title || "#000000"
          }}
          sx={{
            fontFamily:
              i18n.language === "arabic" ? "TajawalBold " : "ArchivoBlack",
            fontSize:
              i18n.language === "arabic" ? { xs: "18px" } : { xs: "14px" },
            letterSpacing: i18n.language === "arabic" ? "0.02em" : "0.2em"
          }}
        >
          {" "}
          {item.text}
        </Box>
        <Box
          className={
            i18n.language === "arabic"
              ? "home-info-card-text-arabic"
              : "home-info-card-text"
          }
          sx={{
            height: "100%",
            fontFamily:
              i18n.language === "arabic"
                ? "TajawalRegular "
                : "MontserratRegular",

            fontSize:
              i18n.language === "arabic" ? { xs: "17px" } : { xs: "14px" }
          }}
          style={{ color: description || "#5a5f64" }}
        >
          {LengthControl(item?.subtext, 250)}
        </Box>
      </LinkType>
      <Box
        sx={{
          width: "100%",
          direction: i18n.language === "arabic" ? "rtl" : "ltr"
        }}
      >
        {tabletMax && bullets_cta_button?.title && (
          <LinkType
            link_type={bullets_cta_button?.link_type}
            link={bullets_cta_button?.link}
          >
            <ViewAllButton
              style={i18n.language === "arabic" ? arabicText : englishText}
            >
              {bullets_cta_button?.title}
            </ViewAllButton>
          </LinkType>
        )}
      </Box>
    </Box>
  );
};

export default ItemInfo;
