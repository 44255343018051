import React from "react";
import Return from "Assets/images/Return.svg";
import ReturnArabic from "Assets/images/ReturnArabic.svg";
import close from "Assets/images/close.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyImage from "Components/LazyLoad/LazyImage";

const HistoryButton = ({
  path,
  Left,
  Right,
  Top,
  image,
  width,
  height,
  referalCode,
  search
}) => {
  const { i18n } = useTranslation();
  const imageStyle = {
    position: "absolute",
    left: Left || 20,
    right: Right || 20,
    top: Top || 20,
    width: width,
    height: height
  };
  const imageSelect = () => {
    if (i18n.language === "arabic") {
      return (
        <LazyImage
          placeholderSrc={
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          }
          lazyclass="lazy"
          src={image === "return" ? ReturnArabic : close}
          style={imageStyle}
          alt="ReturnArabic"
        />
      );
    } else
      return (
        <LazyImage
          placeholderSrc={
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          }
          lazyclass="lazy"
          src={image === "return" ? Return : close}
          style={imageStyle}
          alt="Return"
        />
      );
  };
  return (
    <>
      <Link
        to={{
          pathname: path || "/",
          state: {
            state: { referalCode }
          },
          search: search
        }}
      >
        {imageSelect()}
      </Link>
    </>
  );
};

export default HistoryButton;
