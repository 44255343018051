import React from "react";
import ContentDescription from "Components/EditorialDetail/ContentDescription";

export const EditorialContentDescriptionContainer = ({
  widget,
  ui_properties
}) => {
  return (
    <ContentDescription
      widget={widget}
      color_theme={widget.color_theme}
      ui_properties={ui_properties}
    />
  );
};
