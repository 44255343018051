import React, { useState, useRef, useEffect } from "react";

const TruncatedText = ({ text }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const contentRef = useRef(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const containerHeight = contentElement.parentElement.clientHeight;
      const contentHeight = contentElement.clientHeight;

      setIsTruncated(contentHeight > containerHeight);
    }
  }, [text]);

  return (
    <div className="truncated-text-container">
      <div
        ref={contentRef}
        className={`truncated-text-content ${isTruncated ? "truncate" : ""}`}
      >
        {text}
      </div>
    </div>
  );
};

const LineControlComp = ({ originalText }) => {
  return (
    <>
      <TruncatedText text={originalText} />
    </>
  );
};

export default LineControlComp;
