import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import MobileNumberInput from "Pages/Account/components/MobileNumberInput";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import { Link } from "react-router-dom";

const ErrorCode = {
  "Number already exist": 5000,
  "Number not exist": 5001
};
const AllianceNumberForm = ({
  errorResponse,
  errorCode,
  handleSubmit,
  infoText,
  isLoading,
  style,
  label,
  alliance_id
}) => {
  const [errorResponseMessage, setErrorResponseMessage] = useState();
  const { t, i18n } = useTranslation();

  const initialFormValues = process.env.REACT_APP_MOCK_LOGIN_FORM
    ? {
        code: {
          country_code: "+90",
          iso_code: "TR"
        },
        phoneNumber: process.env.REACT_APP_MOCK_LOGIN_PHONE_NUMBER,
        alliance_id
      }
    : {
        code: {
          country_code: "+971",
          iso_code: "ARE"
        },
        phoneNumber: "",
        alliance_id
      };

  const validationSchema = yup.object({
    code: yup
      .object({
        country_code: yup.string().required(),
        iso_code: yup.string().required("Phone code cannot be blank")
      })
      .required(),
    phoneNumber: yup
      .string()
      .required(t("Phone number cannot be blank"))
      .test(
        "len",
        t("Phone number should be 7 digits minimum"),
        (val) => val?.length > 7
      )
  });

  const formikForm = useFormik({
    initialValues: initialFormValues,

    validationSchema,
    onSubmit: ({ code, phoneNumber, alliance_id }) => {
      handleSubmit({
        code: code?.country_code,
        phone: phoneNumber,
        iso_code: code?.iso_code,
        alliance_id: alliance_id
      });
    }
  });

  useEffect(() => {
    if (formikForm.errors.code || formikForm.errors.phoneNumber) {
      setErrorResponseMessage();
    }
    return () => {};
  }, [formikForm.errors]);
  useEffect(() => {
    setErrorResponseMessage(errorResponse);
  }, [errorResponse]);

  const formikErrorMessage =
    (formikForm.touched.code ? t(formikForm.errors.code?.iso_code) : "") ||
    (formikForm.touched.phoneNumber ? t(formikForm.errors.phoneNumber) : "");

  //The function checks if errorResponseMessage and errorCode match a specific error code, which corresponds to "Number already exist." If there's a match, it returns the errorResponseMessage inside a React fragment.
  // If the conditions in the first if statement are not met, it checks another condition where errorResponseMessage is checked against an error code for "Number not exist." If there's a match, it returns a React fragment containing the errorResponseMessage and a link to the "/sign-up/phone-validation" route with a message asking if the user wants to "create an account." The text and link text are generated using the t function, which appears to be for internationalization.
  // If none of the conditions are met, it returns formikErrorMessage or errorResponseMessage. The exact behavior depends on the values of these variables.

  const errorMessage = () => {
    if (
      errorResponseMessage &&
      errorCode === ErrorCode["Number already exist"]
    ) {
      return <>{errorResponseMessage}</>;
    } else if (
      errorResponseMessage &&
      errorCode === ErrorCode["Number not exist"]
    ) {
      return (
        <>
          {errorResponseMessage}
          <br></br>
          {t("Would you like to ")}{" "}
          <Link to="/sign-up/phone-validation">{t("create an account")}</Link>
          {i18n.language === "arabic" ? "؟" : "?"}
        </>
      );
    } else return formikErrorMessage || errorResponseMessage;
  };
  return (
    <form
      style={{ height: "100%" }}
      onSubmit={formikForm.handleSubmit}
      noValidate
    >
      <div className="left-page-form-subdiv">
        <div>
          <div
            className={
              i18n.language === "arabic" ? "subtext-pn-arabic" : "subtext-pn"
            }
            style={{ paddingLeft: "0px" }}
          >
            {t(infoText)}
          </div>
          <div>
            {errorMessage() && (
              <AlertWarning
                marginbottom="24px"
                margintop="0px"
                linktext={errorMessage()}
              />
            )}
            <div
              style={{ marginBottom: "8px" }}
              className={
                i18n.language === "arabic" ? "phone-text-arabic" : "phone-text"
              }
            >
              {t(label)}
            </div>
            <Grid container spacing={0}>
              <Grid xs={12}>
                <MobileNumberInput
                  formik={formikForm}
                  codeFieldProps={{
                    value: formikForm.values.code,
                    onChange: formikForm.handleChange,
                    name: "code"
                  }}
                  phoneFieldProps={{
                    value: formikForm.values.phoneNumber,
                    onChange: formikForm.handleChange,
                    name: "phoneNumber",
                    isValid: formikForm.isValid && formikForm.dirty
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="btnbox-pn" style={style}>
          {isLoading ? (
            <ColorLoadingButton
              size="small"
              loading={true}
              variant="outlined"
              disabled
              type="submit"
              className="button-submit-loading"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                maxWidth: "100%",
                width: "100%",
                marginTop: "0px"
              }}
            />
          ) : (
            <ColorButton
              variant="contained"
              type="submit"
              className="button-submit-pn"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                maxWidth: "100%",
                width: "100%"
              }}
            >
              {t("Continue")}
            </ColorButton>
          )}
        </div>
      </div>
    </form>
  );
};

export default AllianceNumberForm;
