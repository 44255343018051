import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  LengthControl,
  isColor,
  MapCategories
} from "GlobalProcess/GlobalFunction";
import LinkType from "Components/LinkType";
import calculateBadgeText from "Assets/helper/badgeHelper";
import { useGetOnAppConfigContent } from "services/cms/cms.query";
import { DataSourceEnums, LinkTypeEnums } from "utils/enums";
export default function ActionAreaCard({
  card,
  size,
  order_cross,
  backGround,
  home,
  color_theme,
  show_brand_title,
  data_source,
  show_category,
  component_type
}) {
  const { i18n } = useTranslation();
  const { data: appConfigContent = {} } = useGetOnAppConfigContent();
  const endLabels =
    appConfigContent?.items?.[0]?.fields?.configuration?.Configurations?.find(
      (item) => item?.configuration_name === "amber_badge_behaviors"
    )?.items;
  const { color_definition } = color_theme || {};
  card.modifiedBadgeTemplate = calculateBadgeText(
    card.link_type,
    card.badge_behaviour,
    card.badge,
    card.badge_template,
    card.start_date,
    card.end_date,
    card.reading_time,
    endLabels
  );

  return (
    <LinkType link_type={card?.link_type} link={card?.link} withLink="100%">
      <Card
        sx={{
          maxWidth: "100%",
          boxShadow: "none",
          width: "100%",
          textAlign: "left",
          borderRadius: "0px",
          cursor: "pointer",
          backgroundColor: backGround || "#fff"
        }}
      >
        <Box>
          {typeof card.image === "string" ? (
            <CardMedia
              component="img"
              loading="lazy"
              height="100%"
              image={card.image}
              alt={card.text}
            />
          ) : (
            card.image
          )}
          <CardContent
            sx={{
              padding: " 16px 0px 16px 0px",
              textAlign: i18n.language === "arabic" ? "right" : "left",
              backgroundColor: backGround || "#fff"
            }}
          >
            {(card.modifiedBadgeTemplate || card.badge) && (
              <Box
                className={
                  i18n.language === "arabic"
                    ? "swiper-badge-arabic"
                    : "swiper-badge"
                }
                style={{
                  backgroundColor: color_definition
                    ? color_definition?.badge_fill
                    : "#FFFFFF",
                  color: color_definition
                    ? color_definition?.badge_text
                    : "#000000",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "NotoNaskhArabicMedium "
                      : "MontserratMedium",
                  right: i18n.language === "arabic" ? "auto" : "8px",
                  left: i18n.language === "arabic" ? "8px" : "auto"
                }}
              >
                {LengthControl(
                  card.modifiedBadgeTemplate
                    ? card.modifiedBadgeTemplate
                    : card.badge,
                  20
                )}
              </Box>
            )}
            {card?.text && show_brand_title && (
              <Box
                className={
                  i18n.language === "arabic"
                    ? "category-card-text-arabic"
                    : "category-card-text"
                }
                sx={{
                  textAlign: i18n.language === "arabic" ? "right " : "left"
                }}
                style={{
                  color: isColor(color_definition?.brand)
                    ? color_definition?.brand
                    : "#000000"
                }}
              >
                {data_source !== DataSourceEnums.EDITORIAL &&
                  LengthControl(card.text, 30)}
              </Box>
            )}

            {card?.maintext &&
              !(
                data_source === DataSourceEnums.BRAND ||
                card?.link_type === LinkTypeEnums.BRAND
              ) && (
                <Box
                  sx={{
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalRegular "
                        : "MontserratRegular",
                    fontSize: i18n.language === "arabic" ? "17px" : "14px"
                  }}
                  className={
                    order_cross
                      ? (size === 4 ? "home-info-card-maintext-helper " : "") +
                        (size === 3
                          ? "swiper-text-brands mt-1"
                          : "swiper-text-brands ")
                      : (size === 4 ? "home-info-card-maintext-helper " : "") +
                        (size === 3
                          ? home
                            ? " home-info-card-maintext-marginless mt-1"
                            : " home-info-card-maintext mt-1"
                          : " home-info-card-maintext-size3 mt-1")
                  }
                  style={{
                    color: isColor(color_definition?.title)
                      ? color_definition?.title
                      : "#000000"
                  }}
                >
                  {LengthControl(card.maintext, 55)}
                </Box>
              )}
            {
              <Box
                className="category-card-subtext mt-1"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  fontSize: i18n.language === "arabic" ? "15px" : "12px"
                }}
                style={{
                  color: isColor(color_definition?.category)
                    ? color_definition?.category
                    : "#5A5F64"
                }}
              >
                {card?.category && MapCategories(card?.category)}
              </Box>
            }
          </CardContent>
        </Box>
      </Card>
    </LinkType>
  );
}
