import { SELECTED_COUNTRY_CODE } from "constants";

// Input: The function takes a single parameter mappedUserCountryCode, which is the country code to be stored.

// Local Storage Item Storage: It uses the localStorage.setItem method to store the mappedUserCountryCode in local storage. The item is stored with the key SELECTED_COUNTRY_CODE, which is defined in the constants module. The mappedUserCountryCode is first converted to a JSON string using JSON.stringify before storage.

// Outcome: After executing this function, the user's selected country code is stored in the browser's local storage under the key SELECTED_COUNTRY_CODE.

export const setSelectedCountryCodeToStorage = (mappedUserCountryCode) => {
  if (mappedUserCountryCode) {
    localStorage.setItem(
      SELECTED_COUNTRY_CODE,
      JSON.stringify(mappedUserCountryCode)
    );
  }
};
