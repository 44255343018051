import React from "react";
import { Navigate } from "react-router-dom";

import { useUserIsLoggedIn } from "hooks/useUser";

const AuthRoute = ({ children }) => {
  const alliance_control = localStorage.getItem("alliance-control");
  const isLoggedIn = useUserIsLoggedIn();
  const pathname=window.location.pathname?.split("/")?.[2];
  const loginkeyLength = (pathname?.includes("phone") || pathname?.includes("email")) ? 0 : pathname?.length;
 

   const isloggedControl = alliance_control ? false : isLoggedIn;
  if (isloggedControl && (loginkeyLength<=1 || loginkeyLength===undefined )) {
    return <Navigate to={"/"} replace />;
  }
  return <>{children}</>;
};

export { AuthRoute };


