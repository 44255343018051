// src/hooks/useTierValue.ts (or utils, depending on your structure)

import { useUserStore } from "./useUser"; // Adjust the path based on your file structure
import { TIER_MAP } from "constants";
import { useMemo } from "react";

export const useTierValue = () => {
  const tier = useUserStore((state) => state.tier);
  return useMemo(() => TIER_MAP[tier] || "Default", [tier]);
};
