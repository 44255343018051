import React from "react";
import Layout from "../Layout";
import ErrorComponent from "Components/ErrorComponent";
import ErrorContainer from "./ErrorContainer";

const Error404 = () => {
  let ButtonText = [
    { text: "Return home", path: "/" },
    { text: "Contact us", path: "/faq" }
  ];
  return (
    <Layout navbar={"white"} className="brands">
      <ErrorContainer>
        <ErrorComponent
          Warnig="404"
          Text="Sorry, the page you were looking for was not found"
          ButtonText={ButtonText}
          margintp={40}
        />
      </ErrorContainer>
    </Layout>
  );
};

export default Error404;
