import React, { useState, useEffect, useMemo } from "react";
import logoUnion from "Assets/images/logo-dark.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import WprightPage from "Components/Authanticate/WprightPage";
import { Box, Grid, TextField, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "Components/BackButton/index";
import { SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS } from "../Account/constants";
import {
  setRouteCntrl,
  setOtpCode,
  clearRouteCntrl
} from "store/authanticateSlice";
import { setBearerToken } from "services/apiClient";
import {
  usePostPhoneEntry,
  usePostPhoneValidate
} from "services/auth/auth.query";
import { saveToken } from "utils/authStorage";
import { numberOrEmpty } from "utils/stringUtil";
import Timer from "../Account/components/timer/Timer";
import ColorButton from "Assets/ColorButton/ColorButton";
import * as yup from "yup";
import { useFormik } from "formik";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";

const styleArabic = {
  fontSize: 21,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular"
};
const styleEnglish = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  textAlign: "right"
};
const styleEnglishLength = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr"
};
const AllianceOTP = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { phoneNumber, phoneCode, isoCode } = useSelector(
    (state) => state.authanticate
  );
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);

  const [showComponent, setShowComponent] = useState(true);
  const [responseErrorMessage, setResponseErrorMessage] = useState();

  const hasPhoneTrue = useMemo(() => {
    return routeCntrl.some((item) => item.phone === true);
  }, [routeCntrl]);

  const locationState = useLocation().state;
  const [validationKey, setValidationKey] = useState();
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};
  const { alliance_id } = useSelector((state) => state.public);
  const {
    mutateAsync: postPhoneEntry,
    isLoading: isLoadingPhoneEntry,
    error: isPhoneEntryError
  } = usePostPhoneEntry({
    onSuccess: () => {},
    onError: () => {}
  });

  const {
    mutateAsync: phoneValidate,
    isLoading: isLoadingPhoneValidate,
    error: isPhoneValidateError,
    reset: resetPhoneValidateError
  } = usePostPhoneValidate({
    onSuccess: () => {},
    onError: (error) => {
      if (error?.response?.status === 429) {
        setTimeout(() => {
          navigate("/alliance-phone-number");
        }, 1500);
      }
    }
  });
  useEffect(() => {
    navigateProtect();
  }, []);

  useEffect(() => {
    let error = isPhoneValidateError;
    if (isPhoneEntryError) {
      error = isPhoneEntryError;
      resetPhoneValidateError();
    }
    setResponseErrorMessage(error?.response?.data?.message);
  }, [isPhoneEntryError, isPhoneValidateError]);

  useEffect(() => {
    if (locationState?.validation_key) {
      setValidationKey(locationState.validation_key);
    }
  }, [locationState]);

  const validationSchema = yup.object({
    code: yup
      .string()
      .required(t("Your verification code should be 6 digits"))
      .test("len", t("Your verification code should be 6 digits"), (val) => {
        return val?.length === 6;
      })
  });

  const formikForm = useFormik({
    initialValues: {
      code: "",
      alliance_id
    },
    validationSchema,
    onSubmit: ({ code }) => {
      handleSubmit();
    }
  });

  const code = formikForm.values.code;

  //   resendCode function:
  // It constructs a parsedPhone by concatenating phoneCode and phoneNumber, removing dashes and spaces.
  // It attempts to make a POST request to the server using postPhoneEntry with various parameters, including isoCode, parsedPhone, action, and alliance_id.
  // If the request is successful, it sets the validationKey and clears any response error message. It returns true.
  // If there's an error during the request, it returns false.

  // navigateProtect function:
  // It checks the condition of hasPhoneTrue.
  // If hasPhoneTrue is false, it navigates to the route /amber-alliance/welcome.
  // If hasPhoneTrue is true, it sets the showComponent to true.

  // getStyle function:
  // It checks the language specified by i18n.language.
  // If the language is "arabic" and code has a length greater than 0, it returns styleEnglish.
  // If the language is "arabic" and code has a length of 0, it returns styleArabic.
  // For other languages, it returns styleEnglishLength.

  // handleSubmit function:
  // It calls phoneValidate with validationKey, code, and alliance_id as parameters.
  // Upon a successful response, it dispatches various actions, including setting the OTP code, saving a token, and setting a new route in the application.
  // Finally, it navigates to the route /alliance-personal.

  const resendCode = async () => {
    const parsedPhone = (phoneCode + phoneNumber)
      .replace(/-/g, "")
      .replace(/ /g, "");

    try {
      const response = await postPhoneEntry({
        iso_code: isoCode,
        phone: parsedPhone,
        action: "register",
        alliance_id: alliance_id
      });
      setValidationKey(response.validation_key);
      setResponseErrorMessage("");

      return true;
    } catch (error) {
      return false;
    }
  };

  const navigateProtect = () => {
    if (!hasPhoneTrue) {
      navigate(`/amber-alliance/welcome`);
    } else setShowComponent(true);
  };

  const getStyle = () => {
    if (i18n.language === "arabic") {
      if (code.length > 0) {
        return styleEnglish;
      } else return styleArabic;
    } else {
      return styleEnglishLength;
    }
  };

  const handleSubmit = () => {
    phoneValidate({
      validation_key: validationKey,
      validation_code: code,
      allianceID: alliance_id
    }).then((data) => {
      dispatch(setOtpCode(code));
      const token = data.token;
      saveToken(token);
      setBearerToken(token);
      const newRoute = { otp: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));
      navigate(`/alliance-personal`);
    });
  };

  const formikErrorMessage = formikForm.touched.code && formikForm.errors.code;
  const errorMessage = formikErrorMessage || responseErrorMessage;
  const isLoading = isLoadingPhoneEntry || isLoadingPhoneValidate;

  return (
    <>
      {showComponent && (
        <Box className="alliance" data-lang={i18n.language}>
          <div
            data-lang={i18n.language}
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: background || "#FFFFFF"
            }}
          >
            {" "}
            <BackButton path={"/alliance-phone-number"} />
            <div className="left-page-form">
              <form
                style={{ height: "100%" }}
                onSubmit={formikForm.handleSubmit}
                noValidate
              >
                <div className="left-page-logo">
                  <Link to="/">
                    <img src={logoUnion} alt="logo" width={"74.89px"} />
                  </Link>
                </div>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "login-head-arabic"
                      : "login-head"
                  }
                >
                  <span>{t("Alliance")}</span>
                </div>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    width: "100%",
                    maxWidth: "460px",
                    marginTop: { xs: "16px", md: "24px" }
                  }}
                >
                  <Grid item xs={12}>
                    <Box
                      className={
                        i18n.language === "arabic"
                          ? "verify-text-arabic"
                          : "verify-text"
                      }
                    >
                      {t("Verify your phone number")}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {i18n.language === "arabic" ? (
                      <Box
                        className={
                          errorMessage
                            ? "subtext-box-arabic marginbox"
                            : "subtext-box-arabic"
                        }
                        sx={{
                          fontSize: "14px",
                          direction: i18n.language === "arabic" ? "ltr" : "rtl",
                          textAlign:
                            i18n.language === "arabic" ? "right" : "left",
                          justifyContent: "flex-end"
                        }}
                      >
                        {phoneCode + " " + phoneNumber} &nbsp;
                        {t("We sent a code to")}
                        <br></br>
                        <Link
                          style={{ color: "black" }}
                          to="/alliance-phone-number"
                        >
                          {t("Change phone number")}
                        </Link>
                      </Box>
                    ) : (
                      <div
                        className={
                          errorMessage ? "subtext-box marginbox" : "subtext-box"
                        }
                        style={{ fontSize: "14px" }}
                      >
                        {t("We sent a code to")} {phoneCode}{" "}
                        {phoneNumber.replace(/-/g, " ")}
                        .&nbsp;<br></br>
                        <Link
                          style={{ color: "black" }}
                          to="/alliance-phone-number"
                        >
                          {t("Change phone number")}
                        </Link>
                      </div>
                    )}
                    <div>
                      {errorMessage && (
                        <AlertWarning
                          margintop="0px"
                          marginbottom={{ xs: "32px", md: "40px" }}
                          text={t(errorMessage)}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    className="textfont"
                    marginBottom={{ md: "24px", xs: "20px" }}
                  >
                    <Grid item xs={12}>
                      <InputLabel
                        sx={{
                          fontFamily:
                            i18n.language === "arabic"
                              ? "TajawalMedium"
                              : "MontserratMedium",
                          fontSize: "14px !important",
                          lineHeight: "130%",
                          color: "#000000",
                          marginBottom: "8px"
                        }}
                      >
                        {t("Verification code")}{" "}
                      </InputLabel>
                      <TextField
                        name="code"
                        onChange={(e) => {
                          if (numberOrEmpty(e.target.value)) {
                            formikForm.handleChange(e);
                            responseErrorMessage && setResponseErrorMessage("");
                          }
                        }}
                        autoComplete="off"
                        value={formikForm.values.code}
                        sx={{
                          width: "100% !important",
                          input: {
                            "&::placeholder": {
                              color: "#90969E",
                              opacity: "1"
                            }
                          }
                        }}
                        placeholder={t("Enter your code")}
                        inputProps={{
                          style: getStyle(),
                          maxLength: 6,
                          inputMode: "numeric",
                          pattern: "[0-9]*"
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Timer
                        onReset={async () => {
                          return await resendCode();
                        }}
                        start={SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS}
                      />{" "}
                    </Grid>
                  </Grid>
                  {isLoading ? (
                    <ColorLoadingButton
                      size="small"
                      loading={true}
                      variant="outlined"
                      disabled
                      type="submit"
                      className="button-submit-loading"
                      style={{
                        width: "calc(100%)",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginTop: "24px",
                        maxWidth: "460px",
                        fontSize: 14,
                        height: 41
                      }}
                    />
                  ) : (
                    <ColorButton
                      type="submit"
                      variant="contained"
                      className="button-submit-pn"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        width: "calc(100%)",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginTop: "24px",
                        maxWidth: "460px",
                        fontSize: 14,
                        height: 41
                      }}
                    >
                      {t("Continue")}
                    </ColorButton>
                  )}
                </Grid>
              </form>
            </div>
          </div>
          <WprightPage
            style={{
              left: i18n.language === "arabic" ? "0" : "auto",
              right: i18n.language === "arabic" ? "auto" : "0"
            }}
          />{" "}
        </Box>
      )}
    </>
  );
};

export default AllianceOTP;
