import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Layout";
import {
  Grid,
  Box,
  Typography,
  useMediaQuery,
  CircularProgress
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetEditorialsDetail,
  useGetEditorials
} from "services/editorials/editorials.query";
import { ContentTypesEnums } from "../../utils/enums";
import { NavBarContainer } from "../NavBarContainer";
import { EDITORIALS_DETAILS_COMPONENT_MAP_CMS_COMPONENT_MAP } from "CmsComponents";
import { useDataLayer } from "hooks/useDataLayer";
import LazyImage from "Components/LazyLoad/LazyImage";

const EditorialDetail = ({ scrollCntrl, setScrollCntrl }) => {
  const { data: EditorialsContent = {} } = useGetEditorials();
  const { t, i18n } = useTranslation();
  const widthScreen = useMediaQuery("(max-width:900px)");
  const { id } = useParams();
  const navigate = useNavigate();
  const { setImpressionEvent } = useDataLayer();
  const [tagList, setTagList] = useState([]);
  const [showPublishDate, setShowPublishDate] = useState(false);
  const userAgent = window?.navigator?.userAgent;

  const {
    data: {
      publish_date,
      reading_time,
      main_image_web,
      main_image_app,
      widgets = [],
      tags,
      primary_category,
      show_brand_title,
      title,
      ui_properties,
      brand,
      slug_url,
      seo_data
    } = {},
    isSuccess,
    isLoading
  } = useGetEditorialsDetail({ id });
  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  const contentDescriptionList = widgets?.filter(
    (widget) => widget?.component_type === ContentTypesEnums.CONTENT_DESCRIPTION
  );
  const contentDescriptionListFiltered = widgets?.findIndex(
    (widget) => widget?.component_type === ContentTypesEnums.CONTENT_DESCRIPTION
  );
  const modifiedWidgets = widgets?.filter(
    (widget, index) => index !== contentDescriptionListFiltered
  );
  const navbar = EditorialsContent?.widgets?.find(
    (widget) => widget?.component_type === "web-homepage-main-navigation"
  );
  const brandSponsor = widgets?.filter(
    (widget) => widget?.component_type === ContentTypesEnums.BRAND_SPONSOR
  );

  const {
    background,
    description_color,
    header_text_color,
    title: ui_properties_title
  } = ui_properties || {};
  const {
    background_color,
    description,
    header_text: header_text_component,
    title: title_components
  } = contentDescriptionList?.[0]?.color_theme?.color_definition || {};

  // It receives a date parameter, which is assumed to be a date in a specific format.
  // It creates a new Date object from the provided date.
  // It extracts the month name, year, and day from the Date object.
  // The toLocaleString method is used to get the month name in the current locale, and it's specified to return the full month name.
  // The year and day are extracted using getFullYear and getDate methods, respectively.
  // It returns a formatted string that combines the day, localized month name, and year, resulting in a human-readable date format.

  const convertDate = useCallback(
    (date) => {
      const dateObj = new Date(date);
      const monthName = dateObj.toLocaleString("default", {
        month: "long"
      });
      const year = dateObj.getFullYear();
      const day = dateObj.getDate();
      return `${day} ${t(`${monthName}`)} ${year}`;
    },
    [t]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollCntrl(false);
  }, [scrollCntrl, setScrollCntrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (slug_url) {
      setImpressionEvent({
        page: slug_url,
        itemtype: "editorial",
        brand: brand,
        category: primary_category,
        title: title
      });
    }
  }, [primary_category, title, brand, slug_url, setImpressionEvent]);

  useEffect(() => {
    if (isSuccess) {
      if (typeof tags === "string") {
        const tempList = tags.split(",");
        setTagList(tempList);
      }
    }
  }, [isSuccess, tags]);

  useEffect(() => {
    if (isSuccess && publish_date) {
      setShowPublishDate(convertDate(publish_date));
    }
  }, [convertDate, isSuccess, publish_date]);

  if (isLoading && !isSuccess) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isLoading ? "100vh" : "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  }

  return (
    <Layout seo_data={seo_data}>
      {!userAgent?.includes("amber") && <NavBarContainer widget={navbar} />}
      {main_image_web && [
        <Box style={{ backgroundColor: background || background_color || "" }}>
          {" "}
          {widthScreen ? (
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={main_image_app}
              alt={`Frame-${main_image_app}`}
              width="100%"
            />
          ) : (
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={main_image_web}
              alt={`Frame-${main_image_web}`}
              width="100%"
            />
          )}
        </Box>
      ]}
      <Grid
        container
        sx={{
          paddingLeft: { xs: "20px", lg: "11.11%" },
          paddingRight: { xs: "20px", lg: "11.11%" },
          position: "relative",
          top: { xs: "0px" },
          display: "flex",
          justifyContent: "center",
          direction: i18n.language === "arabic" ? "rtl" : "ltr",
          backgroundColor: background || background_color || ""
        }}
        className="editorial-detail"
      >
        {main_image_web && (
          <Grid
            item
            xs={12}
            sx={{
              marginTop: {
                xs: "-250px",
                sm: "-200px",
                md: "-210px",
                lg: "-250px"
              }
            }}
          >
            <Box
              style={{ color: ui_properties_title || title_components || "" }}
              className={
                i18n.language === "arabic"
                  ? "metaDataTitleArabicStyle"
                  : "metaDataTitleStyle"
              }
            >
              {show_brand_title && title}
              {brandSponsor[0]?.image && (
                <Typography
                  className={
                    i18n.language === "arabic"
                      ? "sponsorTitleArabic"
                      : "sponsorTitle"
                  }
                >
                  {`${brandSponsor[0]?.label}`}{" "}
                  <LazyImage
                    placeholderSrc={
                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    }
                    lazyclass="lazy"
                    src={brandSponsor[0].image}
                    alt={`Frame-${brandSponsor[0].image}`}
                    width={60}
                    height={35}
                    style={{ marginBottom: "-10px" }}
                  />
                </Typography>
              )}
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container sx={{ marginTop: { xs: "24px", md: "80px" } }}>
            <Grid item xs={12} md={6}>
             {showPublishDate && <Box sx={{ marginTop: { xs: "24px", md: "0px" } }}>
                <p
                  className={
                    i18n.language === "arabic" ? "tajawalStyle" : "shneStyle"
                  }
                  style={{
                    color: header_text_color || header_text_component || ""
                  }}
                >
                  {showPublishDate}
                </p>
              </Box>}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  maxWidth: "360px",
                  flexWrap: "wrap"
                }}
              >
                {tagList.length > 0 &&
                  tagList.map((tag) => (
                    <Box
                      key={tag}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        padding: "4px 8px",
                        gap: "4px",
                        height: "14px",
                        background: "#F3F3FA",
                        borderRadius: "50px",
                        margin:
                          i18n.language === "arabic"
                            ? "0px 0px 6px 8px "
                            : "6px 8px 0px 0px"
                      }}
                    >
                      <p
                        className={
                          i18n.language === "arabic"
                            ? "buttonarabicStyle"
                            : "buttonStyle"
                        }
                      >
                        {tag}
                      </p>
                    </Box>
                  ))}
              </Box>
              {primary_category && reading_time && (
                <Box
                  className={
                    i18n.language === "arabic" ? "celebrityArabic" : "celebrity"
                  }
                >
                  <span
                    style={{
                      color: description_color || description || ""
                    }}
                  >
                    {primary_category || null}
                  </span>
                  <span className="dot"></span>
                  <span
                    style={{
                      color: description_color || description || ""
                    }}
                  >
                    {reading_time}
                  </span>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  color: header_text_color || header_text_component || ""
                }}
                className={
                  i18n.language === "arabic" ? "tajawalStyle" : "shneStyle"
                }
              >
                {contentDescriptionList?.[0]?.header_description || ""}
              </Box>
              <Box
                style={{
                  color: description_color || description || ""
                }}
                className={i18n.language === "arabic" ? "textArabic" : "text"}
              >
                {contentDescriptionList?.[0]?.description || ""}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {modifiedWidgets?.map((widget) => {
        const CmsComponent =
          EDITORIALS_DETAILS_COMPONENT_MAP_CMS_COMPONENT_MAP[
            widget["component_type"]
          ];
        if (CmsComponent) {
          return (
            <CmsComponent
              className="editorial-detail"
              key={widget?.component_type}
              widget={widget}
              style={{ backgroundColor: background || "" }}
              ui_properties={ui_properties}
              editorial_brand_carousel={true}
            />
          );
        }
        return null;
      })}
    </Layout>
  );
};

export default EditorialDetail;
