import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Switchbutton from "Assets/SwitchButton/SwitchButton";
import SwitchbuttonReverse from "Assets/SwitchButton/SwitchButtonReverse";

const NotificationSettingsItem = ({
  text,
  name,
  value,
  description,
  onChange,
  
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Grid style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="notification-text" data-lang={i18n.language}>
          {t(text)}
        </p>
        {i18n.language === "arabic" ? (
          <SwitchbuttonReverse
            name={name}
            checked={value}
            onChange={(e) => {
              onChange?.(e);
            }}
          />
        ) : (
          <Switchbutton
            name={name}
            checked={value}
            onChange={(e) => {
              onChange?.(e);
            }}
          />
        )}
      </Grid>
      <p className="notification-description" data-lang={i18n.language}>
        {t(description)}
      </p>
    </div>
  );
};

export default NotificationSettingsItem;
