import { useMutation } from "@tanstack/react-query";
import * as Requests from "./auth.requests";

export const usePostPhoneEntry = ({ onSuccess, onError }) => {
  return useMutation(
    ["phone-entry"],
    ({ iso_code, phone, action, alliance_id, loginKey }) => {
      return Requests.postPhoneEntry({ iso_code, phone, action, alliance_id, loginKey });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const usePostPhoneValidate = ({ onSuccess, onError }) => {
  return useMutation(
    ["phone-validate"],
    ({ validation_key, validation_code, loginKey, allianceID }) => {
      return Requests.postPhoneValidate({
        validation_key,
        validation_code,
        loginKey,
        allianceID
      });
    },
    { onSuccess, onError }
  );
};

export const usePostExistEmail = ({ onSuccess, onError }) => {
  return useMutation(
    ["email-exist"],
    ({ email, alliance_id }) => {
      return Requests.postEmailExist({ email, alliance_id });
    },
    { onSuccess, onError }
  );
};

export const usePostExistEmailValidate = ({ onSuccess, onError }) => {
  return useMutation(
    ["exist-email-validate"],
    ({ validation_key, validation_code, alliance_id }) => {
      return Requests.postExistEmailValidate({
        validation_key,
        validation_code,
        alliance_id
      });
    },
    { onSuccess, onError }
  );
};

export const usePostExistEmailOtp = ({ onSuccess, onError }) => {
  return useMutation(
    ["exist-email-otp"],
    ({ email, alliance_id }) => {
      return Requests.postExistEmailOtp({ email, alliance_id });
    },
    { onSuccess, onError }
  );
};

export const useAuthRegister = ({ onSuccess, onError }) => {
  return useMutation(
    ["auth-register"],
    ({
      email,
      first_name,
      last_name,
      gender,
      birthday,
      advertisement,
      terms,
      data_sharing,
      region,
      alliance_id,
      promo_code,
      referalCode = ""
    }) => {
      return Requests.postRegister({
        email,
        first_name,
        last_name,
        gender,
        birthday,
        advertisement,
        terms,
        data_sharing,
        region,
        alliance_id,
        referalCode,
        promo_code
      });
    },
    { onSuccess, onError }
  );
};

export const useAuthSetNumber = ({ onSuccess, onError }) => {
  return useMutation(
    ["auth-set-number"],
    ({
      first_name,
      last_name,
      gender,
      birthday,
      referalCode,
      alliance_id = ""
    }) => {
      return Requests.postSetNumber({
        first_name,
        last_name,
        gender,
        birthday,
        referalCode,
        alliance_id
      });
    },
    { onSuccess, onError }
  );
};

export const useRefreshToken = ({ onSuccess, onError }) => {
  return useMutation(
    ["refresh-token"],
    ({ token }) => {
      return Requests.refreshToken({ token });
    },
    { onSuccess, onError }
  );
};

export const useGetEmailVerification = ({ onSuccess, onError }) => {
  return useMutation(
    ["email-verification"],
    ({ validationKey, secondKey }) => {
      return Requests.getEmailVerification({ validationKey, secondKey });
    },
    { onSuccess, onError }
  );
};
