import React from "react";
import CategoryListFiltered from "Components/CategoryListFiltered";
import CategoryListFilterdeMonths from "Components/CategoryListFilterdeMonths";

export const CategoryListFilteredContainer = ({
  activeButton,
  ButtonText,
  color_theme,
  CategoryType,
  web_message,
  web_message_title,
  months,
  monthRecordCount,
  SetMonthFilterCount,
  currentMonthId,
  setCurrentMonthId,
 }) => {
  return (
    <>
      {months ? (
        <CategoryListFilterdeMonths
          CategoryType={CategoryType}
          activeButton={activeButton}
          currentMonthId={currentMonthId}
          setCurrentMonthId={setCurrentMonthId}
          ButtonText={ButtonText}
          color_theme={color_theme}
          web_message={web_message}
          web_message_title={web_message_title}
          months={months}
          monthRecordCount={monthRecordCount}
          SetMonthFilterCount={SetMonthFilterCount}
         />
      ) : (
        <CategoryListFiltered
          CategoryType={CategoryType}
          activeButton={activeButton}
          ButtonText={ButtonText}
          color_theme={color_theme}
          web_message={web_message}
          web_message_title={web_message_title}
         />
      )}
    </>
  );
};
