import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useUserIsLoggedIn } from "hooks/useUser";
import { PRIVATE_TOKEN_PERSIST_KEY, LOCAL_STORAGE_EVENT_NAME } from "constants";

const ProtectedRoute = ({ children, path }) => {
  const isLoggedIn = useUserIsLoggedIn();

  // Handling logout event across different tabs
  useEffect(() => {
    // handleStorageChange: It listens for the storage event by specifying a callback function, in this case, the handleStorageChange function.
    // When a change occurs in the local storage (e.g., when a value associated with a key is updated), the storage event is triggered.
    // The function checks if the key property of the event object (e.key) is equal to PRIVATE_TOKEN_PERSIST_KEY. PRIVATE_TOKEN_PERSIST_KEY is presumably a constant or variable that holds the key for a specific item in the local storage.
    // If the key matches PRIVATE_TOKEN_PERSIST_KEY, and the newValue property of the event object (e.newValue) is null, it indicates that the item associated with this key has been removed from local storage.
    // If the conditions are met (the key matches, and the value is null), the function performs a page reload using window.location.reload(). This effectively refreshes the page or the current browser tab.

    const handleStorageChange = (e) => {
      if (e.key === PRIVATE_TOKEN_PERSIST_KEY && e.newValue === null) {
        window.location.reload();
      }
    };

    window.addEventListener(LOCAL_STORAGE_EVENT_NAME, handleStorageChange);

    return () => {
      window.removeEventListener(LOCAL_STORAGE_EVENT_NAME, handleStorageChange);
    };
  }, []);

  if (!isLoggedIn) {
    return <Navigate to={path || "/sign-in"} replace />;
  }

  return <>{children}</>;
};

export { ProtectedRoute };
