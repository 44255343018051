import { useQuery } from "@tanstack/react-query";
import * as Requests from "./editorials.requests";
import { EDITORIALS, EDITORIALS_DETAIL } from "../serviceKeys";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetEditorials = () => {
  const tierValue = useTierValue();
  return useQuery([EDITORIALS, tierValue], () => {
    return Requests.getEditorials({ segment: tierValue });
  });
};

export const useGetEditorialsDetail = ({ id }) => {
  const tierValue = useTierValue();
  return useQuery(
    [EDITORIALS_DETAIL, id, tierValue],
    () => {
      return Requests.getEditorialsDetail({ segment: tierValue, id });
    },
    {
      enabled: !!id
    }
  );
};
