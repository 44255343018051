import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Vector from "Assets/images/Vector.svg";
import { InputValidationMessage } from "./InputValidationMessage";

const styleEnglish = {
  display: "flex",
  justifyContent: "flex-start",
  fontFamily: "MontserratRegular",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "130%",
  alignItems: "center",
  color: "#000000"
};

const styleArabic = {
  display: "flex",
  justifyContent: "flex-start",
  fontFamily: "TajawalRegular",
  direction: "rtl",
  gap: "8px",
  height: "30px",
  fontSize: "18px",
  lineHeight: "130%"
};

const CustomSelectIcon = (props) => {
  return <img src={Vector} alt="Custom Icon" {...props} />;
};

const CustomSelect = ({
  name,
  value,
  errorMessage,
  handleChange,
  disabledItems,
  items = [],
  sx = {},
  outlinedInputStyle = {},
  ...otherProps
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Select
        name={name}
        value={value || ""}
        onChange={handleChange}
        IconComponent={CustomSelectIcon}
        sx={{
          fontFamily: "MontserratRegular",
          gap: "8px",
          height: "41px",
          width: "100%",
          fontSize: "16px",
          maxWidth: "350px",
          "& .MuiButtonBase-root-MuiMenuItem-root": {
            padding: "10px, 16px, 10px, 16px",
            backgroundColor: "transparent !important"
          },
          "& .MuiSelect-icon": {
            left: i18n.language === "arabic" ? "15.75px" : "auto",
            right: i18n.language === "arabic" ? "auto" : "15.75px",
            top: "40%"
          },
          ".MuiSelect-select": {
            overflow: "auto",
            color: value === t("Please select") ? "#90969E" : "#000000"
          },
          // ".MuiSelect-select-outlined": {
          //   paddingRight: "12px !important"
          // },
          "& .MuiOutlinedInput-input": {
            fontFamily:
              i18n.language === "arabic"
                ? "TajawalRegular !important"
                : "MontserratRegular !important",
            borderRadius: "27px",
            gap: "8px",
            height: "30px",
            fontSize:
              i18n.language === "arabic"
                ? "18px !important"
                : "16px !important",
            display: "flex",
            alignItems: "center",
            paddingLeft: "14px !important",
            paddingRight: "14px !important",
            ...outlinedInputStyle
          },

          "& .MuiSelect-selected": {
            padding: "10px, 16px, 10px, 16px",
            backgroundColor: "transparent !important"
          },
          ...sx
        }}
        {...otherProps}
      >
        {disabledItems && (
          <MenuItem
            value={disabledItems}
            disabled
            style={i18n.language === "arabic" ? styleArabic : styleEnglish}
          >
            {t(disabledItems)}
          </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem
            style={i18n.language === "arabic" ? styleArabic : styleEnglish}
            value={item.value}
            disableRipple
          >
            {t(item.label)}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <InputValidationMessage text={errorMessage} />}
    </>
  );
};

export default CustomSelect;
