import { apiClient } from "../apiClient";
import { privateApiClient } from "../privateApiClient";
import { getPrivateToken, getSecondToken } from "utils/authStorage";

export const getAccount = async () => {
  return privateApiClient({
    url: "/account/"
  }).then((res) => res.data);
};

export const getNotificationSettings = async () => {
  return privateApiClient({
    url: "/account/fetch-sfmc-rowset"
  }).then((res) => res.data);
};

export const postSetCountry = async ({ country }) => {
  return apiClient({
    url: "/account/set-country",
    method: "PATCH",
    data: {
      country
    },
    headers: {
      Authorization: `Bearer ${getSecondToken() || getPrivateToken()}`
    }
  }).then((res) => res.data);
};

export const postSelectAccountOtp = async ({ customer_id, email, lang }) => {
  return apiClient({
    url: "/account/select-account-otp",
    method: "POST",

    data: {
      customer_id,
      email
    },
    headers: {
      Language: lang,
      Authorization: `Bearer ${getSecondToken()}`
    }
  }).then((res) => res.data);
};

export const postSelectAccountValidate = async ({
  validation_key,
  validation_code
}) => {
  return apiClient({
    url: "/account/select-account-validate",
    method: "POST",
    data: {
      validation_key,
      validation_code
    }
  }).then((res) => res.data);
};

export const postUpdatedAccount = async ({
  firstName,
  lastName,
  gender,
  birthDate
}) => {
  return privateApiClient({
    url: "/account/update-customer-detail",
    method: "PATCH",
    data: {
      first_name: firstName,
      last_name: lastName,
      gender,
      birthday: birthDate
    }
  }).then((res) => res.data);
};

export const postNotificationSettings = async ({
  contact_by_email,
  contact_by_sms,
  personalized_data,
  most_out_of_amber
}) => {
  return privateApiClient({
    url: "/account/update-customer-notifications",
    method: "PATCH",
    data: {
      contact_by_email,
      contact_by_sms,
      personalized_data,
      most_out_of_amber
    },
    headers: {
      Authorization: `Bearer ${getPrivateToken()}`
    }
  }).then((res) => res.data);
};

export const postUpdateEmail = async ({ email }) => {
  return privateApiClient({
    url: "/account/update-email",
    method: "PATCH",
    data: {
      email
    }
  }).then((res) => res.data);
};

export const postUpdateMobileNumber = async ({ phoneNumber, iso_code }) => {
  return privateApiClient({
    url: "/account/update-phone",
    method: "PATCH",
    data: {
      iso_code,
      phone: phoneNumber
    }
  }).then((res) => res.data);
};

export const postUpdateOTP = async ({ validation_key, code }) => {
  return privateApiClient({
    url: "/account/update-phone-validate",
    method: "POST",
    data: {
      validation_key,
      validation_code: code
    },
    headers: {
      Authorization: `Bearer ${getPrivateToken()}`
    }
  }).then((res) => res.data);
};
export const postUpdateEmaiOTP = async ({ validation_key, code }) => {
  return privateApiClient({
    url: "/account/update-email-validate",
    method: "POST",
    data: {
      validation_key,
      validation_code: code
    },
    headers: {
      Authorization: `Bearer ${getPrivateToken()}`
    }
  }).then((res) => res.data);
};

export const getPurchaseHistory = async () => {
  return privateApiClient({
    url: "/account/purchase-history?limit=5",
    method: "GET"
  }).then((res) => res.data);
};

export const getPromotions = async () => {
  return privateApiClient({
    url: "/account/get-promotions",
    method: "GET"
  }).then((res) => res.data);
};

export const getArchivedCoupons = async () => {
  return privateApiClient({
    url: "/account/get-coupons",
    method: "GET"
  }).then((res) => res.data);
};

export const getRewards = async () => {
  return privateApiClient({
    url: "/cms/fetch-amber-rewards-cards",
    method: "GET"
  }).then((res) => res.data);
};

export const removeCoupon = async ({ couponBarcode, action }) => {
  return privateApiClient({
    url: "/account/manage-coupons",
    method: "POST",
    data: {
      barcode: `${couponBarcode}`,
      action
    }
  }).then((res) => res.data);
};

export const getFetchAmberReferralProgram = async () => {
  return privateApiClient({
    url: "/cms/fetch-amber-referral-program",
    method: "GET",
    headers: {
      Authorization: `Bearer ${getPrivateToken()}`
    }
  }).then((res) => res.data);
};
