import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import ColorButton from "Assets/ColorButton/ColorButton";
import { ORIENTATION } from "CmsComponents";
import AppStore from "Components/AppStore";
import { isColor } from "GlobalProcess/GlobalFunction";
import LazyImage from "Components/LazyLoad/LazyImage";

const Actions = ({ items = [] }) => {
  const { i18n } = useTranslation();
  return items.map(({ label, link, index, color_theme }) => {
    const { cta_button_border, cta_button_fill, cta_button_text } =
      color_theme?.color_definition || {};
    return (
      <ColorButton
        key={index}
        href={link}
        variant="contained"
        className={
          i18n.language === "arabic" ? "button-submit-arabic" : "button-submit"
        }
        style={{
          width: "160px",
          backgroundColor: cta_button_fill || "",
          color: isColor(cta_button_text) ? cta_button_text : "",
          border: `1px solid ${
            isColor(cta_button_border) ? cta_button_border : ""
          }`
        }}
      >
        {label}
      </ColorButton>
    );
  });
};

const CreateContent = ({
  label,
  title,
  description,
  orientation,
  imageWeb,
  imageMobile,
  subImageMobile,
  subImageLink,
  color_theme,
  sub_image,
  items = []
}) => {
  const { i18n } = useTranslation();
  const mobile = useMediaQuery("(max-width:600px)");
  const { background_color } = color_theme?.color_definition || {};
  const titleColor = color_theme?.color_definition?.title;
  const descriptionColor = color_theme?.color_definition?.description;
  const labelColor = color_theme?.color_definition?.header_text;
  return (
    <>
      <Grid
        className="create-content"
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0 }}
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "100%",
          alignContent: "center",
          justifyContent: "space-between",
          backgroundColor: background_color || ""
        }}
        sx={{
          paddingTop: { xs: "32px", md: "48px" },
          paddingBottom: { xs: "32px", md: "40px" },
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" },
          display: "flex",

          flexDirection: i18n.language === "arabic" ? "row-reverse" : "row"
        }}
      >
        <Box
          className="column-rev"
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: {
              sm:
                orientation === ORIENTATION.right
                  ? "row-reverse !important"
                  : "initial !important",
              xs:
                orientation === ORIENTATION.right
                  ? "column-reverse !important"
                  : "column !important"
            },
            justifyContent: { sm: "space-between" },
            alignItems: { sm: "center" },
            columnGap: { sm: "80px" }
          }}
        >
          <Box
            className={
              i18n.language === "arabic"
                ? "create-content-left-section-arabic"
                : "create-content-left-section"
            }
            sx={{
              marginBottom: {
                xs: "24px !important",
                sm: "48px !important",
                md: "0px !important",
                height: "100% !important"
              },
              maxWidth: { xs: "100% !important", md: "520px !important" },
              width: "100%"
            }}
          >
            <Box
              className={
                i18n.language === "arabic" ? "cc-div-arabic" : "cc-div"
              }
              sx={{
                maxWidth: { xs: "100% !important", md: "520px !important" }
              }}
            >
              <Box
                className={
                  i18n.language === "arabic"
                    ? "dynamic-ads-arabic"
                    : "dynamic-ads"
                }
                sx={{
                  textAlign: i18n.language === "arabic" ? "right" : "left",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "Tajawalbold "
                      : "ArchivoBlack",
                  letterSpacing:
                    i18n.language === "arabic" ? "0.02em !important" : "0.2em",

                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "18px" }
                      : { xs: "14px" },
                  lineHeight:
                    i18n.language === "arabic" ? "110%" : { xs: "18px" }
                }}
                style={{ color: labelColor || "" }}
              >
                {label}
              </Box>
              {i18n.language === "arabic" ? (
                <Box
                  className="left-section-head-arabic"
                  sx={{ textAlign: "right" }}
                  style={{ color: titleColor || "" }}
                >
                  {title}
                </Box>
              ) : (
                <Box className="left-section-head" sx={{ textAlign: "left" }}>
                  <div style={{ color: titleColor || "" }}>{title}</div>
                </Box>
              )}

              <Box
                className={
                  i18n.language === "arabic"
                    ? "left-section-text-arabic"
                    : "left-section-text"
                }
                sx={{
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  textAlign: i18n.language === "arabic" ? "right" : "left",
                  marginRight:
                    i18n.language === "arabic" ? "0px" : { xs: "0px" },
                  marginLeft: i18n.language === "arabic" ? "20px" : ""
                }}
                style={{ color: descriptionColor || "" }}
              >
                {description}
              </Box>
              <Box
                className={
                  i18n.language === "arabic"
                    ? "button-menu-arabic"
                    : "button-menu"
                }
                sx={{
                  marginTop: { xs: "24px", md: "32px" },
                  justifyContent: "space-between !important",
                  alignItems: "center !important",
                  maxWidth: " 336px !important",
                  width: "100% !important"
                }}
              >
                <Actions items={items} />
              </Box>
            </Box>
            <Box
              className="carousel-qr"
              sx={{
                display: {
                  xs: "none",
                  md: sub_image ? "flex" : "none"
                },
                width: "fit-content",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginTop: "9.05px"
              }}
            >
              {sub_image && (
                <a
                  href={subImageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppStore image={sub_image} data_description="sub_image" />
                </a>
              )}
            </Box>
            <Box
              className="carousel-qr"
              sx={{
                display: {
                  xs: subImageMobile ? "flex" : "none",
                  md: "none !important"
                },
                width: "fit-content",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginTop: "9.05px"
              }}
            >
              <a href={subImageLink} target="_blank" rel="noopener noreferrer">
                <AppStore
                  image={subImageMobile}
                  data_description="subImageMobile"
                />
              </a>
            </Box>
          </Box>
          <Box
            className="create-content-right-section"
            sx={{
              height: "100% !important",
              width: "100%"
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "520px", md: "100%" },
                width: "100%"
              }}
            >
              <LazyImage
                placeholderSrc={
                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                }
                src={mobile ? imageMobile : imageWeb}
                alt={"dynamic-ads"}
                width={"100%"}
                lazyclass="lazy"
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default CreateContent;
