import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AllianceCode from "Pages/AmberAlliance/AllianceCode";
import AllianceOTP from "Pages/AmberAlliance/AllianceOTP";
import AlliancePersonal from "Pages/AmberAlliance/AlliancePersonal";
import AlliancePhoneNumber from "Pages/AmberAlliance/AlliancePhoneNumber";
import AllianceWelcomePage from "Pages/AmberAlliance/AllianceWelcomePage";
import AmberApp from "Pages/AmberApp/AmberApp";
import BrandStoreDetails from "Pages/BrandsStores/BrandStoreDetails";
import BrandsStore from "Pages/BrandsStores/BrandsStore";
import Dashboard from "Pages/Dashboard/Dashboard";
import Editorial from "Pages/Editorial/Editorial";
import EditorialDetail from "Pages/Editorial/EditorialDetail";
import BrowserUpdate from "Pages/ErrorPages/BrowserUpdate";
import Error401 from "Pages/ErrorPages/Error401";
import Error404 from "Pages/ErrorPages/Error404";
import Error500 from "Pages/ErrorPages/Error500";
import EventsOffers from "Pages/EventsOffers/EventsOffers";
import ResultEvents from "Pages/EventsOffers/ResultEvents";
import ResultOffers from "Pages/EventsOffers/ResultOffers";
import Home from "Pages/Home/Home";
import HowItWorks from "Pages/HowItWorks/HowItWorks";
import DataPolicy from "Pages/Policy/DataPolicy";
import PrivacyPolicy from "Pages/Policy/PrivacyPolicy";
import TermsConditions from "Pages/Policy/TermsConditions";
import PurchaseDetail from "Pages/PurchaseHistory/PurchaseDetail";
import PurchaseHistory from "Pages/PurchaseHistory/PurchaseHistory";
import YourRewards from "Pages/RewardsSystem/YourRewards";
import AboutPage from "Pages/SupportPages/AboutPage/AboutPage";
import AboutUs from "Pages/SupportPages/AboutPage/AboutUs";
import CorporateGovernance from "Pages/SupportPages/AboutPage/CorporateGovernance";
import CorporateGovernanceReports from "Pages/SupportPages/AboutPage/CorporateGovernanceReports";
import Faq from "Pages/Policy/Faq";
import SupportPages from "Pages/SupportPages/SupportPages";
import Promotion from "Pages/EventsOffers/Promotion";
import EventsDetail from "Pages/EventsOffers/EventsDetail";
import VerifyEmail from "Pages/Login/VerifyEmail";
import { AccountRoutes } from "./AccountRoutes";
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthRoute } from "./AuthRoutes/AuthRoute";
import { LoginRoutes } from "./AuthRoutes/LoginRoutes";
import { SignUpRoutes } from "./AuthRoutes/SignUpRoutes";
import { LogWithAmberRoutes } from "./AuthRoutes/LogWithAmberRoutes";
import Brands from "Pages/Brands/Brands";
export const AppRoutes = (props) => {
  const [scrollCntrl, setScrollCntrl] = useState(false);
  useEffect(() => {
    window.onpopstate = () => {
      setScrollCntrl(true);
    };
  });

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <>
        <Route
          path="/sign-in/*"
          element={
            <AuthRoute>
              <LoginRoutes />
            </AuthRoute>
          }
        />
        <Route
          path="/sign-up/*"
          element={
            <AuthRoute>
              <SignUpRoutes />
            </AuthRoute>
          }
        />
        {/* <Route
          path="/logwithamber/*"
          element={
            <AuthRoute>
              <LogWithAmberRoutes />
            </AuthRoute>
          }
        /> */}
      </>

      <Route path="how-it-works">
        <Route exact path="/how-it-works" element={<HowItWorks />} />
      </Route>
      <Route path="amber-app">
        <Route exact path="/amber-app" element={<AmberApp />} />
      </Route>

      <Route path="brands-and-stores">
        <Route
          exact
          path="/brands-and-stores"
          element={
            <BrandsStore
              scrollCntrl={scrollCntrl}
              setScrollCntrl={setScrollCntrl}
            />
          }
        />
      </Route>
      <Route path="Editorial">
        <Route
          exact
          path="/Editorial"
          element={
            <Editorial
              scrollCntrl={scrollCntrl}
              setScrollCntrl={setScrollCntrl}
            />
          }
        />
      </Route>
      <Route
        exact
        path="/Editorial/:id"
        element={
          <EditorialDetail
            scrollCntrl={scrollCntrl}
            setScrollCntrl={setScrollCntrl}
            Singlelocate={true}
          />
        }
      />
      <Route
        exact
        path="/brands-and-stores/name-of-the-brand/:id"
        element={
          <BrandStoreDetails
            scrollCntrl={scrollCntrl}
            setScrollCntrl={setScrollCntrl}
          />
        }
      />
      <Route
        exact
        path="/brands-and-stores/:id"
        element={
          <BrandStoreDetails
            scrollCntrl={scrollCntrl}
            setScrollCntrl={setScrollCntrl}
          />
        }
      />
      <Route
        exact
        path="/events-and-offers"
        element={
          <EventsOffers
            scrollCntrl={scrollCntrl}
            setScrollCntrl={setScrollCntrl}
          />
        }
      />
      <Route exact path="/offers/:id" element={<Promotion />} />
      <Route exact path="/events/:id" element={<EventsDetail />} />

      <Route
        exact
        path="/events-and-offers/offers"
        element={<ResultOffers />}
      />
      <Route
        exact
        path="/events-and-offers/events"
        element={<ResultEvents />}
      />
      <Route
        exact
        path="/legal/terms-and-conditions"
        element={<TermsConditions />}
      />
      <Route exact path="/legal/data-policy" element={<DataPolicy />} />
      <Route exact path="/legal/privacy-policy" element={<PrivacyPolicy />} />
      <>
        <Route
          exact
          path="/Dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/purchase-history"
          element={
            <ProtectedRoute>
              <PurchaseHistory />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/purchase-details/:id"
          element={
            <ProtectedRoute>
              <PurchaseDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account/*"
          element={
            <ProtectedRoute>
              <AccountRoutes />
            </ProtectedRoute>
          }
        />
        <Route path="rewards/profile">
          <Route
            exact
            path="/rewards/profile"
            element={
              <ProtectedRoute path={"/"}>
                <YourRewards />
              </ProtectedRoute>
            }
          />
        </Route>
      </>
      <Route
        exact
        path="/verify-email/:validationKey/:secondKey"
        element={<VerifyEmail />}
      />

      <Route exact path="/resources" element={<SupportPages />} />
      <Route path="/about" element={<AboutPage />}>
        <Route path="/about/us" element={<AboutUs />} />
        <Route
          path="/about/corporate-governance"
          element={<CorporateGovernance />}
        />
        <Route
          path="/about/corporate-governance-reports"
          element={<CorporateGovernanceReports />}
        />
      </Route>
      <Route exact path="/faq" element={<Faq />} />
      <Route
        exact
        path="/amber-alliance/welcome"
        element={<AllianceWelcomePage />}
      />
      <Route
        exact
        path="/alliance-phone-number"
        element={<AlliancePhoneNumber />}
      />
      <Route exact path="/alliance-otp" element={<AllianceOTP />} />
      <Route exact path="/alliance-personal" element={<AlliancePersonal />} />
      <Route exact path="/alliance-code" element={<AllianceCode />} />
      <Route path="/error401" element={<Error401 />} />
      <Route path="/error404" element={<Error404 />} />
      <Route path="/error500" element={<Error500 />} />
      <Route path="/browserupdate" element={<BrowserUpdate />} />
      <Route path="/enrollment" replace element={<Navigate to="/sign-up" />} />
      <Route
        path="/brand"
        replace
        element={<Navigate to="/brands-and-stores" />}
      />
      <Route path="/brand/:id" replace element={<Brands />} />
      <Route
        path="/alliance/home/:id"
        replace
        element={<Navigate to="/amber-alliance/welcome" />}
      />

      <Route path="*" replace element={<Navigate to={"/error404"} replace />} />
    </Routes>
  );
};
