import { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, RadioGroup } from "@mui/material";

import ColorButton from "Assets/ColorButton/ColorButton";
import RadioButton from "Pages/Account/components/RadioButton";
import SelectLabels from "./Select";
import InputAdornments from "./TextFieldIcon";
import { PointCalculatorEnums, PointCalculatorEnumsArabic } from "utils/enums";

const RadioButtonItem = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <Box
      data-lang={i18n.language}
      className={"as-custom-radio"}
      sx={{
        flex: 1,
        marginRight:
          i18n.language === "arabic" ? "0px" : { xs: "16px", md: "0px" },
        marginLeft:
          i18n.language === "arabic" ? { xs: "16px", md: "0px" } : "0px",
        color: props?.title || "",
        backgroundColor: props?.button_tab_color + " !important" || ""
      }}
    >
      {props.children}
      <span>{t(props.label)}</span>
    </Box>
  );
};

const AmberStatus = ({
  id,
  widget,
  color_theme,
  widgets,
  amberTierMapping
}) => {
  const earn_points = widgets?.find(
    (item) => item?.component_type === "earn-points"
  );
  const redeem_points = widgets?.find(
    (item) => item?.component_type === "redeem-points"
  );
  const cta_button_widget = widgets?.find(
    (item) => item?.component_type === "cta_button"
  );
  const cta_button = cta_button_widget?.title;
  const getIsoCode = JSON.parse(
    localStorage.getItem("selected-country")
  )?.iso_code;

  const firstWidget = useMemo(
    () => [
      {
        title: earn_points?.items?.find(
          (item) => item?.component_type === "earn-points-labels"
        )?.title,
        label: earn_points?.items?.find(
          (item) => item?.component_type === "earn-points-labels"
        )?.label,
        information_label: earn_points?.items?.find(
          (item) => item?.component_type === "earn-points-labels"
        )?.information_label
      },
      {
        title: redeem_points?.items?.find(
          (item) => item?.component_type === "redeem-points-labels"
        )?.title,
        label: redeem_points?.items?.find(
          (item) => item?.component_type === "redeem-points-labels"
        )?.label,
        information_label: redeem_points?.items?.find(
          (item) => item?.component_type === "redeem-points-labels"
        )?.information_label
      }
    ],
    [earn_points, redeem_points]
  );

  const selectTitle = earn_points?.items?.find(
    (item) => item?.component_type === "text_dropdown"
  )?.title;
  const selectPlaceHolder = earn_points?.items?.find(
    (item) => item?.component_type === "text_dropdown"
  )?.label;
  const text_field_earn = earn_points?.items?.find(
    (item) => item?.component_type === "text_field"
  )?.title;
  const text_field_redeem = redeem_points?.items?.find(
    (item) => item?.component_type === "text_field"
  )?.title;
  const label_redeem = redeem_points?.items?.find(
    (item) => item?.component_type === "text_field"
  )?.label;
  const { color_definition } = color_theme || {};
  const {
    background_color,
    title,
    description,
    cta_button_fill,
    cta_button_text,
    cta_button_border,
    header_text,
    button_tab_color
  } = color_definition || {};
  const { t, i18n } = useTranslation();
  const [val, setVal] = useState(0);
  const [calculate, setCalculate] = useState(0);
  const [inputvalue, setInputvalue] = useState(0);
  const [selected, setSelected] = useState(firstWidget?.[0]?.title);
  const [error, setError] = useState(false);
  const [errorSelect, setErrorSelect] = useState(false);
  const [calculateValueData, setCalculateValueData] = useState(null);
  const [rate, setRate] = useState(1);
  const currencyMappingConfigFilter = amberTierMapping?.tiers[
    val > 0 ? val - 1 : 0
  ]?.items.find((item) => item?.site_id === getIsoCode);
  const selectedTitle =
    selected === firstWidget?.[0]?.title
      ? firstWidget?.[0]?.label
      : firstWidget?.[1]?.label;
  function fixArabicPunctuation(text, charCount) {
    if (i18n.language === "arabic") {
      return text;
    }

    return text;
  }
  const [multiplier, setMuptiplier] = useState(
    currencyMappingConfigFilter?.multiplier
  );

  useEffect(() => {
    setCalculate(0);
    setInputvalue(0);
    setVal(0);
  }, [selected]);

  useEffect(() => {
    setErrorSelect(false);
  }, [val]);

  useEffect(() => {
    if (inputvalue?.amount?.length > 0) {
      setError(false);
    }
  }, [inputvalue]);
  useEffect(() => {
    if (
      selected ===
      (i18n.language === "arabic"
        ? PointCalculatorEnumsArabic.EARN_POINTS
        : PointCalculatorEnums.EARN_POINTS)
    ) {
      if (val > 0) {
        setCalculateValueData(amberTierMapping?.tiers[val - 1]);
        setRate(amberTierMapping?.tiers[val - 1]?.rate * 1);
        setMuptiplier(
          amberTierMapping?.tiers[val - 1]?.items.find(
            (item) => item?.site_id === getIsoCode
          )?.multiplier * 1
        );
      }
    }
    if (
      selected ===
      (i18n.language === "arabic"
        ? PointCalculatorEnumsArabic.REDEEM
        : PointCalculatorEnums.REDEEM)
    ) {
      setRate(1);
      setMuptiplier(currencyMappingConfigFilter?.multiplier);
    }
  }, [
    amberTierMapping?.rate,
    amberTierMapping?.tiers,
    getIsoCode,
    selected,
    val
  ]);

  const replacedString = (myString) => {
    if (myString?.includes("{{CURRENCY}}")) {
      return myString
        ?.replace("{{VALUE}}", `<b>${calculate}</b>`)
        .replace("{{CURRENCY}}", currencyMappingConfigFilter?.currency_code);
    } else return myString?.replace("{{VALUE}}", `<b>${calculate}</b>`);
  };
  const calculateFunc = useCallback(() => {
    setError(false);
    setErrorSelect(false);
    let total;
    if (
      selected ===
      (i18n.language === "arabic"
        ? PointCalculatorEnumsArabic.EARN_POINTS
        : PointCalculatorEnums.EARN_POINTS)
    ) {
      total = Number(
        inputvalue?.amount * 1 * rate * (multiplier / 100)
      ).toFixed(2);
    }
    if (
      selected ===
      (i18n.language === "arabic"
        ? PointCalculatorEnumsArabic.REDEEM
        : PointCalculatorEnums.REDEEM)
    ) {
      total = Number((inputvalue?.amount * 1) / multiplier).toFixed(2);
    }
    setCalculate(total);
  }, [inputvalue?.amount, multiplier, rate, selected]);

  const conditionCalculate = () => {
    return selected === firstWidget?.[0]?.title
      ? inputvalue?.amount?.length > 0 && val !== 0
      : inputvalue?.amount?.length > 0;
  };

  const handleClick = () => {
    if (conditionCalculate()) {
      calculateFunc();
    } else if (!inputvalue?.amount && val === 0) {
      setError(true);
      setErrorSelect(true);
    } else if (!inputvalue?.amount) {
      setError(true);
    } else if (val === 0) {
      setErrorSelect(true);
    }
  };
  const onChangePointType = (e) => {
    setSelected(e.target.value);
    setError(false);
    setErrorSelect(false);
  };

  return (
    <Box
      id={id}
      className="as-div"
      sx={{
        height: "100% !important",
        padding: {
          xs: "32px 20px 32px 20px !important",
          md: "80px 20px 80px 20px !important"
        },
        marginTop: { xs: "0px", md: "0px" },
        backgroundColor: background_color || ""
      }}
    >
      <div
        className={i18n.language === "arabic" ? "as-head-arabic" : "as-head"}
        style={{ color: header_text || "" }}
      >
        {fixArabicPunctuation(widget?.title, 10)}
      </div>
      <div
        className={i18n.language === "arabic" ? "as-text-arabic" : "as-text"}
        style={{ height: "100%", color: description || "" }}
      >
        {fixArabicPunctuation(widget?.description, 10)}
      </div>
      <div className="bottom-box">
        <Box className="as-custom-main">
          <RadioGroup
            value={selected}
            onChange={onChangePointType}
            className="status-radio-group"
            data-lang={i18n.language}
          >
            {firstWidget?.map((widget) => (
              <RadioButtonItem
                label={widget.title}
                key={widget.title}
                title={title}
                button_tab_color={button_tab_color}
              >
                <RadioButton
                  value={widget.title}
                  className="status-radio-button"
                  data-lang={i18n.language}
                />
              </RadioButtonItem>
            ))}
          </RadioGroup>
        </Box>
        <div
          className={
            i18n.language === "arabic" ? "as-subtext-arabic" : "as-subtext"
          }
          style={{ color: description || "" }}
        >
          {fixArabicPunctuation(selectedTitle, 10)}
        </div>
        {selected === firstWidget?.[0]?.title && (
          <SelectLabels
            val={val}
            setVal={setVal}
            widget={widget}
            title={title}
            amberTierMapping={amberTierMapping}
            selectTitle={selectTitle}
            selectPlaceHolder={selectPlaceHolder}
            errorMessage={t("SelectMessage")}
            errorSelect={errorSelect}
          />
        )}
        <InputAdornments
          error={error}
          setInputvalue={setInputvalue}
          text={
            selected === firstWidget?.[0]?.title
              ? text_field_earn
              : text_field_redeem
          }
          label_redeem={label_redeem}
          widget={widget}
          title={title}
          errorMessage={
            selected === firstWidget?.[0]?.title
              ? t("VATMessage")
              : t("redeemMessage")
          }
          selected={selected}
          currencyMappingConfigFilter={currencyMappingConfigFilter}
          button_tab_color={button_tab_color}
          firstWidget={firstWidget}
          val={val}
          amberTierMapping={amberTierMapping}
          getIsoCode={getIsoCode}
        />
        <div
          className={
            i18n.language === "arabic" ? "total-text-arabic" : "total-text"
          }
          style={{ color: description || "" }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: replacedString(
                selected === firstWidget?.[0]?.title
                  ? firstWidget?.[0]?.information_label
                  : firstWidget?.[1]?.information_label
              )
            }}
          ></span>
        </div>
        <div className="button-box">
          <ColorButton
            onClick={handleClick}
            className={
              i18n.language === "arabic"
                ? "button-submit-arabic"
                : "button-submit"
            }
            style={{
              backgroundColor: cta_button_fill || "",
              color: cta_button_text || "",
              border: `1px solid` + cta_button_border || ""
            }}
          >
            {cta_button}
          </ColorButton>
        </div>
      </div>
    </Box>
  );
};

export default AmberStatus;
