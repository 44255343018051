import { SELECTED_COUNTRY_CODE } from "constants";

// getSelectedCountryCodeFromStorage: This function retrieves the selected country code from local storage. It first attempts to retrieve the value from local storage using the key SELECTED_COUNTRY_CODE. If the value is not found (i.e., it's null), it initializes the local storage with a default value ("101") using localStorage.setItem. Then, it returns the selected country code as a string.

// If a value is found in local storage, it parses the value as JSON (assuming it's stored as a JSON string) and returns it. This function helps manage the selected country code state across different parts of your application by storing it in local storage and providing a way to retrieve or set it.

export const getSelectedCountryCodeFromStorage = () => {
  const selectedCountryCodeAsString = localStorage.getItem(
    SELECTED_COUNTRY_CODE
  );
  if (selectedCountryCodeAsString == null) {
    localStorage.setItem(SELECTED_COUNTRY_CODE, '"101"');
    return "101";
  }
  if (selectedCountryCodeAsString) {
    return JSON.parse(selectedCountryCodeAsString);
  }

  return null;
};
