import React from "react";
import AmberHead from "Components/AmberApp/AmberHead";

const AmberHeadContainer = ({ content_description_style, widget }) => {
  const { truncated, color_theme, title, description } = widget;

  return (
    <AmberHead
      id="amber-head"
      description={description}
      title={title}
      color_theme={color_theme}
      truncated={truncated || false}
      content_description_style={content_description_style}
    />
  );
};

export default AmberHeadContainer;
