import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { InputAdornment, Box } from "@mui/material";

import birth from "Assets/images/birth.svg";
import { useIsLanguageArabic } from "hooks";

export default function MaterialUIPickers({
  value,
  onChange,
  helperText,
  dateIcon,
  backgroundStyle,
  disabled,
  inputFormat = "MM/DD/YYYY",
  placeHolderControl
}) {
  const date = new Date();
  const { t, i18n } = useTranslation();
  const isLanguageArabic = useIsLanguageArabic();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={1}>
        <MobileDatePicker
          inputFormat={inputFormat}
          value={value}
          onChange={onChange}
          maxDate={date}
          showToolbar={false}
          disabled={disabled || false}
          renderInput={(params) => (
            <TextField
              defaultValue={value}
              sx={{
                input: {
                  "&::placeholder": {
                    color: "#90969E",
                    opacity: "1"
                  }
                },
                ".MuiInputBase-input": {
                  padding: "9px",
                  paddingRight: "12px",
                  fontSize: "16px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular !important"
                      : "MontserratRegular !important"
                },
                ".MuiInputBase-root > fieldset": {
                  borderColor: "#CED0D7  !important",
                  fontSize: "16px"
                },
                ".MuiInputBase-root ": {
                  background: backgroundStyle
                },
                ".MuiOutlinedInput-root": {
                  paddingRight: "0 !important"
                },
                "& .MuiFormHelperText-root": {
                  color: "#f66464 !important",
                  marginLeft: "0px ",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular"
                      : "MontserratRegular",
                  fontSize: "14px",
                  lineHeight: "130%",
                  marginTop: "8px",
                  marginRight: 0,
                  textAlign: isLanguageArabic ? "right" : "left"
                }
              }}
              placeholder={placeHolderControl ? "":t("Please select")}
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dateIcon ? (
                      <img
                        src={dateIcon}
                        alt="dateIcon"
                        style={{ paddingRight: "10px" }}
                      />
                    ) : (
                      <img
                        src={birth}
                        alt="birthday"
                        style={{ paddingRight: "10px" }}
                      />
                    )}
                  </InputAdornment>
                )
              }}
            />
          )}
        />
        <Box className="MuiFormHelperText-root"> {helperText}</Box>
      </Stack>
    </LocalizationProvider>
  );
}
