import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import ViewAllButton from "Assets/ViewAllButton";
import { useIsLanguageArabic } from "hooks";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseWhite } from "Assets/images/close-white.svg";
import { formatDate } from "dateUtil";
import { RewardTypeEnums } from "utils/enums";
import { useRemoveCoupon } from "services/account/account.query";
import { useQueryClient } from "@tanstack/react-query";
import {
  ACCOUNT_DETAILS,
  ACCOUNT_PROMOTIONS,
  REWARDS
} from "services/serviceKeys";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import { ITEMTYPE_MAP, useDataLayer } from "hooks/useDataLayer";
import { useLocation } from "react-router-dom";
import LazyImage from "Components/LazyLoad/LazyImage";

function CloseButton(props) {
  const { onClose } = props;
  const isLanguageArabic = useIsLanguageArabic();
  return (
    <IconButton
      disableRipple
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        top: "-40px",
        left: "calc(50% - 14px)",
        justifyContent: isLanguageArabic ? "flex-start" : "flex-end",
        color: "white"
      }}
    >
      <CloseWhite width={14} height={14} fill="red" />
    </IconButton>
  );
}

const RewardCarouselWithDetail = ({ children, rewards, cardTheme, item }) => {
  const client = useQueryClient();
  const { i18n, t } = useTranslation();
  const [selectedRewardItemId, setSelectedRewardItemId] = useState();
  const [showComponent, SetShowComponent] = useState(true);
  const [error, SetError] = useState(false);
  const [errorMesage, setErrorMessage] = useState("");
  const mobile = useMediaQuery("(max-width:499px)");
  const { setImpressionEvent, setClickEvent } = useDataLayer();
  const { pathname } = useLocation();

  const {
    text_color,
    back_short_description_color,
    barcode_code_color,
    bottom_bg_color,
    cta_button_background_color,
    cta_button_text_color
  } = cardTheme || {};
  const SelectedRewardCard = item?.image;

  const { mutateAsync: mutateRemoveCoupon, isLoading } = useRemoveCoupon({
    onSuccess: () => {
      client.invalidateQueries([
        REWARDS,
        ACCOUNT_PROMOTIONS,
        ACCOUNT_DETAILS,
        "get_archived-coupons"
      ]);
      setSelectedRewardItemId(false);
      window.location.reload();
      SetShowComponent(true);
    },
    onError: (error) => {
      const { message } = error.response.data;
      if (message === "coupon already exists") {
        SetShowComponent(true);
        setErrorMessage(t("CouponAlready"));
        SetError(true);
      }
    }
  });

  useEffect(() => {
    if (!!selectedRewardItemId) {
      const { brandName, cardTitleDescription } = SelectedRewardCard;

      setImpressionEvent({
        page: pathname,
        itemtype: ITEMTYPE_MAP.REWARD,
        brand: brandName,
        title: cardTitleDescription
      });
    }
  }, [selectedRewardItemId, SelectedRewardCard, pathname, setImpressionEvent]);

  if (isLoading && showComponent) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  }
  return (
    <>
      {children((id) => {
        setSelectedRewardItemId(id);

        const { brandName, cardTitleDescription } = SelectedRewardCard;

        setClickEvent({
          page: pathname,
          itemtype: ITEMTYPE_MAP.REWARD,
          brand: brandName,
          title: cardTitleDescription
        });
      })}
      {selectedRewardItemId && showComponent ? (
        <Dialog
          open={true}
          onClose={() => {
            setSelectedRewardItemId();
          }}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "16px",
              width: "480px",
              overflow: "initial",
              boxShadow: "0px 0px 28px rgba(34, 38, 49, 0.35)"
            }
          }}
        >
          <CloseButton
            onClose={() => {
              setSelectedRewardItemId();
            }}
          />
          <div
            className="reward-card"
            data-lang={i18n.language}
            style={{
              backgroundImage: !SelectedRewardCard?.expired
                ? `url(${SelectedRewardCard?.cardBackgroundImageHalf})`
                : `url(${SelectedRewardCard?.expiredCardBackgroundImage})`,
              height: "200px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}
          >
            <div className="top-container">
              <span
                className={
                  i18n.language === "arabic"
                    ? "brand-top-left-arabic"
                    : "brand-top-left"
                }
                style={{ color: text_color || "" }}
              >
                {SelectedRewardCard?.brandName &&
                  SelectedRewardCard?.brandName.toUpperCase()}
              </span>
            </div>
            <div className="info-container">
              <div className="brandmark-icon">
                {SelectedRewardCard?.BrandLogo && (
                  <LazyImage
                    placeholderSrc={
                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    }
                    lazyclass="lazy"
                    width="100%"
                    src={SelectedRewardCard?.BrandLogo}
                    alt="brandmark-icon"
                  />
                )}
              </div>
              <span
                style={{
                  marginTop: "20px",
                  color: text_color || ""
                }}
                className="descriptiption"
              >
                {SelectedRewardCard?.cardTitleDescription &&
                  SelectedRewardCard?.cardTitleDescription.toUpperCase()}
              </span>
            </div>
            <div className="expire-date-container" data-lang={i18n.language}>
              {SelectedRewardCard?.offerValidity && (
                <>
                  <span
                    data-lang={i18n.language}
                    className="expires-text"
                    style={{ color: text_color || "" }}
                  >
                    {t("Expires")}
                  </span>
                  <span
                    data-lang={i18n.language}
                    className="date"
                    style={{ color: text_color || "" }}
                  >
                    {formatDate({
                      dateText: SelectedRewardCard?.offerValidity,
                      fromFormat: "DD-MM-YYYY",
                      toFormat: "DD MMM YYYY",
                      language: i18n.language
                    })}
                  </span>
                </>
              )}
            </div>
          </div>
          <Box
            sx={{
              padding: {
                xs: "32px 28px !important",
                md: "32px 28px !important"
              },
              backgroundColor: bottom_bg_color || "",
              borderRadius: "0px 0px 16px 16px"
            }}
          >
            <DialogContent
              sx={{
                padding: "0",
                alignItems: "center",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box>
                <Barcode
                  value={SelectedRewardCard?.couponBarcode}
                  marginTop="8px"
                  marginBottom="0px"
                  width={mobile ? 1.6 : 2.4}
                  height={40}
                  font="SF Mono"
                  fontSize="14px"
                  format="CODE128"
                  lineColor={barcode_code_color || ""}
                  background={bottom_bg_color || ""}
                />
              </Box>
              <DialogContentText style={{ marginTop: "24px" }}>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    textAlign: "center",
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalRegular"
                        : "MontserratRegular",
                    fontSize: i18n.language === "arabic" ? "17px" : "14px",
                    color: back_short_description_color || ""
                  }}
                >
                  {SelectedRewardCard?.cardDetailDescription}
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                padding: 0,
                marginTop: "24px"
              }}
            >
              {SelectedRewardCard.rewardType === RewardTypeEnums.CUPONS &&
                !SelectedRewardCard?.expired && (
                  <ViewAllButton
                    disableRipple
                    style={{
                      fontFamily:
                        i18n.language === "arabic"
                          ? "TajawalMedium"
                          : "MontserratMedium",
                      fontSize: i18n.language === "arabic" ? "15px" : "12px",
                      backgroundColor: cta_button_background_color,
                      color: cta_button_text_color
                    }}
                    onClick={() => {
                      SetShowComponent(false);
                      SetError(false);
                      mutateRemoveCoupon({
                        couponBarcode: item.image?.couponBarcode,
                        action: "add"
                      });
                    }}
                  >
                    {SelectedRewardCard?.ctaText}
                  </ViewAllButton>
                )}
              {error && <AlertWarning marginbottom="24px" text={errorMesage} />}
            </DialogActions>
          </Box>
        </Dialog>
      ) : null}
    </>
  );
};

export default RewardCarouselWithDetail;
