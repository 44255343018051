import React from "react";
import Layout from "../Layout";
import LightBackground from "Components/LightBackground";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";

const DataPolicy = () => {
  const mobile = useMediaQuery("(max-width:499px)");
  const { i18n, t } = useTranslation();
  const alignControl = () => {
    if (mobile) {
      return i18n.language === "arabic" ? "right" : "left";
    } else return "center";
  };
  return (
    <Layout className="events">
      <LightBackground
        text={t("Data Sharing Terms & Conditions")}
        xsMinHeight="200px"
        paddingTp={"50px"}
        textalign={alignControl()}
        minheight="320px"
        paddingleft="22.22%"
        paddingright="22.22%"
      />
      {i18n.language === "arabic" ? (
        <Grid
          container
          sx={{
            marginTop: { xs: "40px", md: "48px" },
            paddingLeft: { xs: "20px", md: "22%" },
            paddingRight: { xs: "20px", md: "22%" }
          }}
          style={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalBold"
                    : "MontserratSemiBold",
                fontStyle: "normal",
                fontWeight: { xs: "500", md: "600" },
                fontSize: { xs: "16px", md: "20px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              تعود ملكية هذا الموقع شكرا ("الموقع") والتي تديرها شركة لاندمارك
              المحدودة للبيع بالتجزئة ('LMG'، 'نحن "أو" لنا ") وجميع المواد،
              والمعلومات، الوثائق والبيانات الواردة في هذا الموقع من قبل LMG
              حصرا للاستخدام الفردي وليس التجاري. استخدام هذا الموقع، ومحتويات
              أي شيء فيه خاضع للشروط المذكورة أدناه والظروف. LMG تحتفظ لنفسها
              بحق تغيير أو تعديل الشروط والظروف، والإشعارات في أي وقت
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              <span
                style={{
                  marginRight: "8px",
                  width: "24px",
                  textAlign: "right"
                }}
              >
                1.
              </span>
              قبول
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              تقدم خدمات الموقع ("الخدمات") من قبل LMG من خلال صفحات الويب. هذه
              شروط استخدام الموقع وهي تتضمن التزامات قانونية و التي ستكون ملزمة
              لك عند استخدام الموقع. يشترط استخدامك لهذا الموقع على موافقتك على
              هذه الشروط ، و استخدام أي جزء من الموقع الإلكتروني يعني موافقتك
              على الالتزام بشروط الاستخدام. إذا كنت لا تتفق مع شروط استخدام
              الموقع ، يرجى عدم الدخول أو استخدام أي جزء من هذا الموقع
            </Box>
          </Grid>
          {/* 2************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              {" "}
              <span
                style={{
                  marginRight: "8px",
                  width: "24px",
                  textAlign: "right"
                }}
              >
                2.
              </span>
              استخدام المواد على هذا الموقع
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              لقد تم إنشاء هذا الموقع وتطويره من قبل LMG لتوفير معلومات عن
              شركتها، والمفاهيم المختلفة، والمنتجات وعرضت برنامج ولاء لعملائها.
              يجوز لك نسخ، تنزيل أو طباعة المواد على هذا الموقع لغير الاستعمال
              التجاري أو الخاص أو المحلي فقط، شريطة اعلان حقوقنا الملكية، بما في
              ذلك يتم الحفاظ على حقوق الملكية الفكرية في جميع الأوقات. ما لم ينص
              على خلاف ذلك، أن كل ما تراه أو تقرأه على الموقع بما في ذلك أي
              أفراد الممثلة في النص والملفات والصور والأمثلة وغيرها من المواد
              ("امواد خاصة بLMG ") محمية بموجب القوانين.
            </Box>
          </Grid>
          {/* 3************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              3. العلامات التجارية
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              هذا الموقع يحتوي على العلامات التجارية و الخدمة والأجهزة وغيرها من
              القيم التجارية أو علامات الخدمة التي تملكها LMG أو مرخصة لLMG
              للتمييز لخدماتها ومنتجاتها. وحماية هذه العلامات التجارية والخدمات
              والملكية الفكرية ذات الصلة من التقليد والمحاكاة في إطار القوانين
              الوطنية والدولية، وينبغي أن لا تكون مستنسخة أو نسخها من دون الحصول
              على موافقة خطية مسبقة من LMG.
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              4. حقوق الطبع والنشر
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              المعلومات والنصوص والرسوم والصور والأصوات، والوصلات وجميع
              المعلومات وبرامج أخرى منشورة أو غير ذلك الواردة في هذا الموقع
              ("المعلومات") وإما مملوكة بشكل خاص من قبل LMG أو مرخصة من قبل LMG
              وباستثناء ما هو منصوص عليه تحديدا في هذه الظروف لا يمكن نسخها
              وتوزيعها وعرضها، أو استنساخها أو تحويلها إلى أي شكل أو بأي وسيلة
              سواء الكترونية، التصوير الميكانيكي، تسجيل، أو غير ذلك، دون الحصول
              على موافقة خطية مسبقة من LMG. لا يمكن تسويق أي معلومات أو منتجات
              أو خدمات تم الحصول عليها من أي جزء من الموقع بدون موافقة خطية
              مسبقة من LMG. المعلومات التي تم شراؤها من طرف ثالث تكون موضوع
              الملكية الفكرية التي يملكها هذا الطرف الثالث.
            </Box>
          </Grid>
          {/* 5********* */}
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              5. القواعد والسلوك
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ marginBottom: "8px" }}>
                إنك توافق على عدم استخدام هذا الموقع في:
              </Box>
              <Box sx={{ marginBottom: "8px" }}>
                <ol
                  className="list"
                  style={{
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalRegular"
                        : "MontserratRegular"
                  }}
                >
                  <li>
                    {" "}
                    <Box sx={{ marginBottom: "8px", display: "flex" }}>
                      <div>
                        نشر أو نقل أو توصيل، أو توزيع أي من المعلومات التي تشكل
                        أو تشجع السلوك الذي من شأنه أن يشكل جريمة أو ينطوي على
                        مسؤولية مدنية؛
                      </div>
                    </Box>
                  </li>
                  <li>
                    {" "}
                    <Box sx={{ marginBottom: "8px", display: "flex" }}>
                      <div>
                        استخدام هذا الموقع بطريقة غير مخالفة للقانون، أو من
                        شأنها أن تؤدي إلى تقييد، أو يكون لها تأثير سلبي أو ضررا
                        على LMG أو أي مستخدم آخر؛
                      </div>
                    </Box>
                  </li>
                  <li>
                    {" "}
                    <Box sx={{ marginBottom: "8px", display: "flex" }}>
                      <div>
                        استخدام أي وسيلة أو برنامج أو روتين للتدخل أو محاولة
                        التدخل في سير عمل هذا الموقع الإلكتروني؛
                      </div>
                    </Box>
                  </li>
                  <li>
                    <Box sx={{ marginBottom: "8px", display: "flex" }}>
                      <div>
                        تكييف أو ترجمة أو تعديل، تفكيك، وإزالة، أو عكس هندسة أي
                        برنامج، التطبيقات والبرامج والأجهزة المستخدمة في اتصال
                        مع الموقع الإلكتروني؛
                      </div>
                    </Box>
                  </li>
                  <li>
                    <Box sx={{ marginBottom: "8px", display: "flex" }}>
                      <div>
                        استخدام أو استغلال هذا الموقع إلا لأية أغراض بخلاف ما هو
                        منصوص عليه صراحة بموجب هذه الشروط والأحكام .
                      </div>
                    </Box>
                  </li>
                </ol>
              </Box>
            </Box>
          </Grid>

          {/* 6************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              6. حساب الأعضاء، كلمة السر والامن
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              فقط العضو المتخطي الثامنة عشر سنة عند تقديم طلب العضوية سيكون هو
              المخول لعضوية البرنامج . سيتم حظر أستعادة قيمة النقاط للطلبات
              المقدمة من الاعضاء اقل من 18 عام حتى يتخطوا الثمانية عشر عاماً.
              إذا كان أي من الخدمات يتطلب منك فتح حساب، يجب عليك إتمام عملية
              التسجيل عن طريق تزويدنا بالمعلومات الحالية والكاملة والدقيقة
              كاستمارة التسجيل المعمول بها. وعليك أيضا اختيار كلمة مرور واسم
              مستخدم. أنت مسؤول تماما عن الحفاظ على سرية كلمة السر الخاصة بك
              وحسابك. وعلاوة على ذلك، تكون مسؤولة مسؤولية كاملة عن أية أنشطة وكل
              ما يحدث تحت حسابك. أنت توافق على إبلاغ LMG فورا عن أي استخدام غير
              مصرح به لحسابك أو أي خرق للأمن. وسوف لن تكون مسؤوليين عن أي خسارة
              قد تتكبدها نتيجة لاستخدام شخص آخر لكلمة المرور الخاصة بك أو حسابك،
              إما مع أو من دون علمك.لا يجوز لك استخدام حساب أي شخص آخر في أي
              وقت، دون الحصول على إذن من صاحب الحساب.
            </Box>
          </Grid>
          {/* 7************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              7. المعلومات
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              انت تقبل أن تكون جميع المعلومات المقدمة على الموقع أو أي مواد أخرى
              يمكن الوصول إليها من خلال الموقع الالكتروني بما في ذلك عن طريق أي
              موقع مرتبط هي معلومات عامة. أيضا يتم توفير كافة المعلومات على أساس
              "كما هي" وبقدر ما يسمح به القانون، يتم توفيرها من دون أي ضمان أو
              تمثيل أو شرط من أي نوع سواء كانت صريحة أو ضمنية، قانونية أو غير
              ذلك. أنت تتحمل كامل المسؤولية والمخاطر لاستخدامك أو الاعتماد على
              المعلومات الواردة في هذا الموقع أو أي مواد أخرى يمكن الوصول إليها
              من خلال الموقع الالكتروني بما في ذلك عن طريق أي موقع مرتبط سيعتبر
              تعليقات أو اقتراحات أو أفكار أو المواد المرسلة أو التي تنتقل إلى
              LMG من قبلك أو نيابة عنك، من المعلومات غير السرية ، وسوف تصبح ملكا
              ل LMG. يجب عليك ان تجعل الاستفسارات الخاصة بك والبحث عن مهنة شيء
              مستقل قبل العمل أو الاعتماد على أي معلومات أو مواد تم الحصول عليها
              من خلال هذا الموقع بما في ذلك أي موقع مرتبط. LMGان لا تعطي أي
              تمثيل أو ضمان (صريح أو ضمني) من أي نوع وعلى أي مسألة تتعلق بالموقع
              أوأي موقع مرتبط، بما في ذلك ترويج والتعدي على الغير من حقوق
              الملكية الفكرية. ان LMG لا تضمن ما يلي: (أ) معلومات أو أي مواد
              أخرى يمكن الوصول إليها من خلال الموقع بما في ذلك عن طريق أي موقع
              مرتبط والدقة الكاملة بما في ذلك، دون حصر، المعلومات المتعلقة
              بالأسعار، أو (ب) الوصول إلى المعلومات أو أي مادة أخرى يمكن الوصول
              إليها من خلال الموقع بما في ذلك عن طريق أي موقع مرتبط دون انقطاع،
              في الوقت المناسب أو آمن. يمكن ل LMG تحديث أو تعديل أو إضافة أي
              معلومات من وقت لآخر ، بما في ذلك هذه الشروط وتحتفظ بمطلق الحق في
              اتخاذ مثل هذه التغييرات من دون أي التزام لإعلام المستخدمين في
              الماضي والحاضر أو المحتملين للموقع. ما لم ينص على خلاف ذلك جميع
              المعلومات الجديدة و تكون خاضعة لهذه الشروط. ان LMG لا تضمن أن هذا
              الموقع سوف يعمل بشكل مستمر من دون انقطاع أو يكون خالي من الأخطاء.
              وبالإضافة إلى ذلك، قد تعلق أي جانب من جوانب الموقع في أي وقت دون
              أن تكون مسؤولة عن أي خسارة مباشرة أو غير مباشرة نتيجة لمثل هذا
              العمل
            </Box>
          </Grid>
          {/* 8************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              8. الحذر
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              يجب التأكد من أن وصولك لهذا الموقع أو واستخدامك له لا يعتبر غير
              قانوني أو محظور بموجب القوانين التي تنطبق عليك. يجب اتخاذ
              الاحتياطات الخاصة بك عند استخدامك للموقع كي لا يعرضك لخطر
              الفيروسات، اوالرموزخبيثة ، او برامج الكمبيوتر الروتينية أو غيرها
              من أشكال التدخل الذي قد يضر بنظام الكمبيوتر الخاص بك. على سبيل
              المثال وليس الحصر، فأنك المسؤول عن التأكد من أن نظام الكمبيوتر
              الخاص بك يفي جميع المواصفات التقنية الضرورية لاستخدام الموقع
              ومتوافق معه. ولإزالة الشك، LMG لا تتحمل المسؤولية عن أي من الأضرار
              التي لحقت بنظام الكمبيوتر الخاص بك عند استخدامك لهذا الموقع أو أي
              موقع مرتبط به. LMG لا يضمن أو تضمن أن أي مادة متاحة للتحميل من هذا
              الموقع أو أي موقع مرتبط ستكون خالية من أي فيروس أو مواد أخرى قد
              تلوث الكمبيوتر . أنت المسؤول عن اتخاذ الاحتياطات الكافية لتلبية
              المتطلبات الخاصة بك لدقة البيانات.
            </Box>
          </Grid>
          {/* 9************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              9. المواقع المرتبطة
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              قد يحتوي الموقع على روابط إلى مواقع أخرى. يتم توفير هذه الروابط
              لسهولة الوصول فقط، وربما تلغى أويتم الإبقاء عليها. أنت تقر وتوافق
              على أن LMG لا تملك أي سيطرة على محتوى أو توافر المواقع المرتبطة
              على شبكة الإنترنت م ولا تقبل أي مسؤولية عن محتواها، وسياسات
              الخصوصية أو أي جانب آخر من المواقع المرتبطة على شبكة الإنترنت.
            </Box>
          </Grid>
          {/* 10************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000",
                display: "flex"
              }}
            >
              <div
                style={{
                  marginRight: "8px",
                  width: "24px",
                  textAlign: "right"
                }}
              >
                10.
              </div>{" "}
              <div>الحد من المسؤولية</div>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ marginBottom: "8px" }}>
                انك تفهم وتوافق صراحة على أنه لا يجوز بأية حال من الأحوال أن LMG
                أو الشركات التابعة لها الشركة غيرمسؤولة عن أي أضرار من أي نوع،
                ولكن لا تقتصر على أي أضرار مباشرة أو غير مباشرة أو عرضية أو
                تبعية أو خاصة أو تحذيرية، أو أي أضرار عن خسارة الأرباح أو خسائر
                تجارية، وخسارة برامج أو بيانات أخرى، أو فقدان الاستخدام،
                والادخار أو خسائر معنوية أخرى، بغض النظر عما إذا كان قد تم إبلاغ
                LMG من الممكن أن يتنبأ باحتمال حدوث مثل هذه الأضرار، الناشئة عن
                أو فيما يتعلق بما يلي: (أ) الاستخدام، عدم القدرة على استخدام أو
                أداء أي من أقسام الموقع، أو (ب) أية أمور أخرى تتعلق بالموقع أو
                محتواه، بغض النظر أو ما إذا كان تحديد أي مما سبق ذكره يشكل
                مخالفة جوهرية أو فشل غرض ضروري . قد تكون بعض الولايات القضائية
                لا تسمح باستثناء أو تحديد الضمانات أو أضرار في أنواع معينة من
                الاتفاقات والاستثناءات والقيود المذكورة آنفا قد لا تنطبق عليها.
              </Box>
            </Box>
          </Grid>
          {/* 11************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              11. دفع عن الأضرار و إذن بالنشر
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ marginBottom: "8px", display: "flex" }}>
                أنت توافق على الدفاع، و تعويض LMG، الشركة الأم والشركات التابعة،
                والفروع،و الطرف الثالث من مقدمي المعلومات ، ولكل منهما، موظفيهما
                والمديرين، وكلاء الموظفين والمتعاقدين (إجمالا،) غير ضارة من أي
                مطالبات، وجميع والتكاليف والنفقات، بما في ذلك أتعاب المحاماة
                المعقولة، المتصلة أو في اتصال مع: أي انتهاك لشروط الموقع ؛
              </Box>
              <ol
                className="list"
                style={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular"
                      : "MontserratRegular"
                }}
              >
                <li>
                  <Box sx={{ marginBottom: "8px", display: "flex" }}>
                    <div>استخدام للموقع، ومحتوى الموقع؛</div>
                  </Box>
                </li>
                <li>
                  <Box sx={{ marginBottom: "8px", display: "flex" }}>
                    <div>
                      الخدمة على LMG، على أمر من المحكمة أو الحكومية لوضع شخص أو
                      سجل في اتصال مع إجراءات قانونية أو منصفة أو المسألة التي
                      LMG ليست طرفا فيها.
                    </div>
                  </Box>
                </li>
                <li>
                  <Box sx={{ marginBottom: "8px", display: "flex" }}>
                    <div>
                      أنت تقر بموجب هذه دفع التعويضات لاي خطأ أو إغفال أو انقطاع
                      أو حذف أو عيب، أو تأخير في العمليات أو نقل من هذا الموقع،
                      والتقنية، وشبكة الهاتف والاتصالات، وأجهزة الحاسوب أو أعطال
                      البرمجيات أو أخطاء من أي نوع.
                    </div>
                  </Box>
                </li>
              </ol>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              12. الاستخدام الدولي
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ marginBottom: "8px", display: "flex" }}>
                أنت توافق على الامتثال لجميع القوانين المحلية والقواعد بما في
                ذلك، دون حصر، القوانين التي تحكم الإنترنت، والبيانات والبريد
                الإلكتروني والملكية الفكرية والخصوصية. أنت توافق على الامتثال
                لجميع القوانين المعمول بها في ما يتعلق بنقل البيانات الفنية
                المصدرة من البلد الذي تقيم فيه و / أو تعمل فيه.
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "NotoNaskhArabicRegular"
                    : "PlayfairDisplay",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              13. القانون المحلي
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular"
                    : "MontserratRegular",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ marginBottom: "8px", display: "flex" }}>
                تخضع هذه البنود والشروط من قبل القوانين في دبي، الإمارات العربية
                المتحدة.
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            marginTop: { xs: "40px", md: "48px" },
            paddingLeft: { xs: "20px", md: "22%" },
            paddingRight: { xs: "20px", md: "22%" }
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                fontFamily: {
                  xs: "MontserratMedium",
                  md: "MontserratSemiBold"
                },
                fontStyle: "normal",
                fontWeight: { xs: "500", md: "600" },
                fontSize: { xs: "16px", md: "20px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              PLEASE READ THESE TERMS AND CONDITIONS. BY PARTICIPATING IN THIS
              PROGRAMME, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL TERMS
              INCORPORATED BY REFERENCE.
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              <span style={{ paddingRight: "8px" }}>1.</span>Binding Definitions
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              In these Terms: i) 'Al Tayer Group' 'we', 'our' or 'us' means Al
              Tayer Group LLC for itself and its affiliated companies; ii)
              'Amber' means the loyalty programme run by Al Tayer Group LLC, the
              entity with ultimate responsibility for the Programme, or any
              other company within the same group of companies; iii) 'Amber
              Assist' means the Amber programme customer service contact centre,
              details of which are available on the Website and other Programme
              enrolment documentation; iv) 'Application' means any Amber
              physical or electronic enrolment form whether online, digital, at
              point of sale or otherwise; v) 'Automatic Termination' has the
              meaning set out in Condition 3(g); vi) 'Card' has the meaning set
              out in Condition 3(e); vii) 'Earning Partners' means vendors
              authorized by Al Tayer Group from time to time to issue Amber
              Points when you purchase their qualifying goods and services;
              viii) 'Employee' has the meaning set out in Condition 3(b); ix)
              'Family' means the principle family member, his or her spouse and
              their children over the age of eighteen (18) years; x) 'Mobile
              App' means the Amber mobile application intended for use on a
              mobile device; xi) 'Points' means the non-monetary Amber credit
              provided to you resulting from a purchase of qualifying goods or
              items on the terms stated below and not capable of exchange for
              any monetary currency or credit; xii) 'Redemption Partners' means
              vendors authorized by Al Tayer Group from time to time whom goods
              and services can be obtained in exchange for redemption of Points;
              xiii) 'Tier' has the meaning set out in Condition 4(a); xiv)
              'Website' means www.myamber.com, or any successor website from
              time to time; xv) ‘Campaign Points’ means Points and/or bonus
              Points made available to you in respect of certain offers,
              schemes, deals or campaigns, including but not limited to birthday
              and/or anniversary schemes; xvi) ‘Campaign Period’ means the
              period for which Campaign Points are available and/or redeemable
              being +/- 1 week from the actual day of event that such Campaign
              Points relate to; and xvii) 'you', 'your' or 'principle member'
              means the individual named on the Application.
            </Box>
          </Grid>
          {/* 2************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              2. Binding Effect{" "}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              Subject to Al Tayer Group accepting you into Amber, by signing in
              writing, or electronically accepting these Terms, by applying to
              join and/or joining the programme you agree that you have read and
              understood and agree to be bound by these Terms, including any
              Terms posted on the Website as the same may be varied by Al Tayer
              Group from time to time. Updated Terms supersede any terms agreed
              to by you and Al Tayer Group. It is your responsibility to remain
              aware of any such updates. We will publish the latest Terms on our
              Website.
            </Box>
          </Grid>
          {/* 3************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              3. Membership{" "}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>1.</span>These Terms are
                void if entered into by an individual younger than eighteen (18)
                years of age.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>2.</span>This programme is
                available to individuals for their personal use only and is
                limited to one account per individual. The programme must not be
                used for any business or commercial purposes and we may refuse
                to create an account for any reason.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>3.</span> Officers and
                employees of Al Tayer Group (an 'Employee') are eligible to join
                Amber. However Employees may not accrue Points on transactions
                on which the Inner Circle discount is applied. Direct family
                members of Employees may become members, but neither they nor
                Employees are eligible to enter contests or win prizes.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>4.</span> Al Tayer Group
                reserves the right to make the final decision to enter you into
                the Amber programme and reserves the right to terminate your
                membership and/or revoke your Points at any time. Al Tayer Group
                will not charge you for membership but enrolment for membership
                may be subject to minimum purchase requirements specified by us,
                from time to time.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>5.</span> Al Tayer Group
                may, in its sole and absolute discretion, grant a Family
                membership. The rules relating to Family membership will be
                communicated to you upon entry into such membership.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>6.</span> Al Tayer Group
                will issue you with an Amber membership number and/or Amber
                membership card in physical or digital form (the 'Card'). The
                Card will remain our property and membership may be withdrawn or
                cancelled by Al Tayer Group, for any reason. You are responsible
                for the security of your Card. It is a condition of the
                Programme that you provide Al Tayer Group with a valid email
                address for redemption of Points.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>7.</span> It is a
                condition of the Programme that you provide Al Tayer Group with
                a valid email address for redemption of Points.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>8.</span> Membership will
                automatically terminate in the event that Al Tayer Group
                determines that a member has acted fraudulently or has misused
                the Card or membership or in the event of a member’s death or
                bankruptcy ("Automatic Termination"). Please note that in the
                event of Automatic Termination, all accrued points will be lost.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>9.</span> If you do not
                earn or redeem Points within a 24 month period, your membership
                will be deactivated. Deactivation means Amber programme benefits
                may be withdrawn and you may not receive Amber programme
                communications. To avoid deactivation, please ensure that you
                transact at least once within the 24 month period. Your account
                will be re-activated as soon as you make your next transaction.
                For the avoidance of doubt, deactivation is in addition to any
                Points loss which may arise due to non-redemption as further
                described in Condition 6 below and such Points will not be
                reinstated upon reactivation.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>10.</span> You may cancel
                your membership at any time by emailing assist@myamber.com. Al
                Tayer Group reserves the right to request appropriate
                identification from you in support of such membership
                cancellation prior to processing. To assist us in improving our
                programme, we would be grateful if you could provide the reason
                for cancelling your membership. Once cancelled, all benefits
                will immediately cease. Al Tayer Group will not be liable to you
                in any way for Points loss due to cancellation through Automatic
                Termination or otherwise.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                1<span style={{ paddingRight: "8px" }}>1.</span>You may cancel
                your membership at any time by emailing assist@myamber.com. Al
                Tayer Group reserves the right to request appropriate
                identification from you in support of such membership
                cancellation prior to processing. To assist us in improving our
                programme, we would be grateful if you could provide the reason
                for cancelling your membership. Once cancelled, all benefits
                will immediately cease. Al Tayer Group will not be liable to you
                in any way for Points loss due to cancellation through Automatic
                Termination or otherwise.
              </Box>
            </Box>
          </Grid>
          {/* 4********* */}
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              4. Tier Membership
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>1.</span>Amber membership
                is divided into tiers based on Points earned by a Member in the
                preceding 12 month period (a "Tier"). The default entry Tier to
                the programme is Classic.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>2.</span>A member's Tier
                will automatically be upgraded at the time the Member meets a
                higher Tier’s qualifying Points threshold. From 2 April 2019 or
                for Members joining after 2 April 2019 from their date of
                upgrade, Members will also be automatically downgraded if in
                subsequent 12 months the Member fails to meet the Points
                threshold for that Tier.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>3.</span> Tier upgrades
                and maintenance are dependent on a Member earning sufficient
                Points to qualify to stay in their existing Tier or move to a
                higher Tier.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>4.</span> Tier benefits
                and the thresholds required for qualifying for Tier upgrades are
                displayed on the Website and are subject to change from time to
                time in Al Tayer Group’s sole discretion. Tier upgrade will be
                automatic once the Points threshold applicable to another Tier
                has been reached.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>5.</span> Certain benefits
                are based on the Tier level achieved by you.
              </Box>
            </Box>
          </Grid>
          {/* 5********* */}
          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              5. Breach of Terms and Conditions
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ marginBottom: "8px" }}>
                Al Tayer Group reserves the right to cancel your membership of
                Amber, use of the Mobile App and Website and/or withdraw any
                Points earned if you breach any of these Terms, fail to pay for
                any purchase or provide false or misleading information on the
                Application.
              </Box>
            </Box>
          </Grid>

          {/* 6************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              6. Points{" "}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>1.</span>Al Tayer Group
                and our Earning Partners and Redemption Partners will determine
                from time to time which goods or services purchased by you will
                earn Points including the number of Points awarded. Al Tayer
                Group may from time to time at its sole discretion amend or
                impose additional conditions to redemption, such as varying
                minimum redemption thresholds. You may only earn Points when
                making purchases from Earning Partners and only redeem Points
                with Redemption Partners, or as otherwise permitted by us from
                time to time in writing. Redemption conditions, Earning Partners
                and Redemption Partners will be available on the Website and/or
                on Amber programme documentation.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>2.</span>Unless
                specifically stated, no Points will be earned during a sale or
                promotion, on discretionary discounts, on gift card redemptions,
                on returned items for which a cash (or a gift card) refund is
                given or when a special offer is in effect. Points will not be
                awarded on the portion of a transaction which you fund through
                Points redemption.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>3.</span> To collect
                Points, you must show your Card or quote your membership number
                at the time of purchase. Points are awarded only for qualifying
                goods or services actually supplied and paid for.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>4.</span> Your Amber
                programme Points statement will be made available to you on a
                monthly basis. Missing Points, supported by appropriate proof of
                purchase and identity, can be claimed by contacting Amber Assist
                on 800 26237 (UAE), +971 800 26237 (BAH, KSA, KWT, QTR) or
                through an Amber desk up to 90 days after the transaction to
                which they relate. Al Tayer Group reserves the right to correct
                your Points record as we consider appropriate at any time. Al
                Tayer Group's decision is final and binding.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>5.</span> Unless redeemed,
                Points will expire on the earlier of: (i) twenty-four (24)
                months from the date on which they were earned provided that
                such expiry will only be effected on the immediately following
                membership anniversary day; or (ii) termination of your Amber
                membership.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>6.</span> Al Tayer Group
                may, at its discretion, from time to time, provide you with the
                opportunity to accrue Campaign Points on selected purchases
                (excluding gift cards, as set out in Condition 6(g)), made on
                specific dates subject to the relevant terms and conditions
                relating to such Campaign Points which shall be set out on the
                Website, the Mobile App and any applicable communications with
                you in respect of such Campaign Points, which may be amended by
                Al Tayer Group from time to time.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>7.</span> Gift card
                purchases shall accrue Points on a standard basis and shall not,
                under any circumstances, accrue Campaign Points.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>8.</span> The number of
                Points awarded to or earned by you shall be based on the VAT
                exclusive price of Products (by way of example, and for
                illustration purposes only, if a Product’s VAT exclusive
                purchase price is 50AED, with 2.5AED VAT, you will be
                awarded/earn 50 Points in respect of that Product).
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>9.</span> Campaign Points
                will be awarded pursuant to the Member’s Tier. Such Campaign
                Points can be accrued and/or redeemed within the Campaign Period
                and are rewarded on a minimum spend of AED 2500 on a single
                transaction, valid for a maximum of 3 transactions during the
                relevant Campaign Period.
              </Box>
            </Box>
          </Grid>
          {/* 7************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              7. Redemptions
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>1.</span>You may, at our
                discretion and subject to the Terms herein and on the Mobile
                App, purchase products or services and/or be eligible for offers
                and/or discounts from Redemption Partners entirely or partially
                through the redemption of Points.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>2.</span>Al Tayer Group
                shall not be held liable for any failure on the part of a
                Redemption Partner to accept any redemption request. Any Points
                redemption is subject to availability, advance booking
                requirements (if necessary), cancellation restrictions or any
                other terms and conditions as stipulated by the Redemption
                Partner and/or on the Mobile App.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>3.</span> Al Tayer Group
                will not be liable for any goods, services, offers and/or
                discounts purchased and/or accessed with the Card, Mobile App or
                through your membership number.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>4.</span> You may redeem
                Points by requesting redemption of Points from any Redemption
                Partner (subject to its and/or the Mobile App’s terms and
                conditions) against purchases of goods and/or services and/or
                eligible offers and/or discounts by presenting your Card at
                participating stores and/or through the Mobile App. The relevant
                number of Points will be deducted from your points balance. We,
                and our Redemption Partners, reserve the right to: (i) require
                suitable identification before allowing any redemption of
                Points; and (ii) decline a Points redemption request for
                legitimate reasons, including but not limited to, expired points
                or non-qualifying redemption items.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>5.</span> Any and all
                benefits made available to you through the Amber programme
                (including but not limited to any Campaign Points, gifts, offers
                and/or attendance at events), as notified by us to you, are
                available whilst supplies and/or availability lasts and
                substitutions may be made in our sole discretion.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>6.</span> Redeemed Points
                cannot be used again.
              </Box>
            </Box>
          </Grid>
          {/* 8************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              8. Mobile App and Website
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>1.</span>Al Tayer Group
                reserves the right to provide you with access to the Mobile App
                and/or the Website by means of a password and login code through
                which we may send or make available to you Points statements and
                other Programme information from time to time. We reserve the
                right to withdraw your use of the Mobile App or access via the
                Website at any stage without prior notice or providing reasons.
                Al Tayer Group is not responsible for the security of your
                password or login code to the Mobile App and/or Website.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>2.</span>Al Tayer Group
                will not be held responsible for the availability and/or
                accuracy of any information sent through the Mobile App or made
                available via the Website. Any information sent is intended for
                your personal information and is intended only to be
                communicated to you. We are not obliged to provide, sell, offer,
                discount or provide any items under sale to you through us or
                our Earning Partners and Redemption Partners, for the price
                communicated to you. Any information or rewards communicated to
                you using the Mobile App or available on the Website is not an
                irrevocable offer to contract and shall not be considered
                binding upon us in any manner whatsoever.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>3.</span> The Website and
                the Mobile App may contain hyperlinks to websites operated by Al
                Tayer Group or by third parties. These hyperlinks are provided
                for your reference only and your use of these sites may be
                subject to terms and conditions posted on them. Our inclusion of
                hyperlinks to third party websites does not imply any
                endorsement of the material on such websites and we accept no
                liability for their operation or content. Any liability we have
                for other Al Tayer Group websites will be as per the terms and
                conditions of those websites. You agree that when using the
                Website or the Mobile App you will not:
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>4.</span> upload any files
                that contain software or other material protected by third party
                intellectual property rights unless you have obtained all
                necessary consents;
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>5.</span> upload any files
                that contain viruses, corrupted files, or any other similar
                software or programs that may damage the operation of another's
                computer; or delete any author attributions, legal notices, or
                proprietary designations or labels in any file that is uploaded.
              </Box>
            </Box>
          </Grid>
          {/* 9************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              9. Your Personal Information
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>1.</span>You acknowledge
                and agree that we collect your personal information, in
                accordance with the terms of our Privacy and Cookies Policy,
                accessible and that you will be bound by the most recent update
                of the policy appearing on our Website. Where it is practicable,
                we may notify you by email of any significant changes. However,
                it is therefore your responsibility to continue to check our
                Privacy and Cookie Policy for updates.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>2.</span>You acknowledge
                and agree that all information you provide to Al Tayer Group is
                true and accurate, and that you will notify us if there is any
                change in your name, email address or other personal details.
              </Box>
            </Box>
          </Grid>
          {/* 10************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              <span style={{ paddingRight: "8px" }}>10.</span> Intellectual
              Property Rights
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ marginBottom: "8px" }}>
                All copyright, trademarks, design rights, patents and other
                intellectual property rights (registered and unregistered) in
                and on the Website, Mobile App and the Amber programme generally
                belong to Al Tayer Group, its group companies, licensors and/or
                third parties. Al Tayer Group reserves all of its rights in
                relation to Amber, the Website and the Mobile App. Nothing in
                these Terms grants you a right or licence to use any trade mark,
                design right or copyright owned or controlled by Al Tayer Group
                or any other third party.{" "}
              </Box>
            </Box>
          </Grid>
          {/* 11************ */}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                fontFamily: { xs: "PlayfairDisplay" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "20px", md: "28px" },
                lineHeight: " 120%",
                color: "#000000"
              }}
            >
              11. General{" "}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <Box
              sx={{
                fontFamily: { xs: "MontserratRegular" },
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: { xs: "14px", md: "16px" },
                lineHeight: " 130%",
                color: "#000000"
              }}
            >
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>1.</span>Al Tayer Group
                reserves the right at any time and without prior notice to
                change or add to these Terms, the qualifying goods and services,
                and our Earning Partners and Redemption Partners, and to
                suspend, amend or terminate the Amber programme, notwithstanding
                that such change may result in reducing or extinguishing the
                number, value or period of expiry of any existing or future
                Points.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>2.</span>You must
                immediately notify Amber Assist of any loss of your Card or if
                you think your password or login has been compromised. We will
                not be held liable for any loss, theft, damage, or unauthorized
                use of your Card, membership number or Points. Any replacement
                Card is provided at our discretion and a charge may apply for
                the issuance of replacement Cards.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>3.</span> All conditions
                and warranties, whether expressed or implied and whether arising
                under legislation or otherwise, as to condition, suitability,
                quality, fitness or safety of any Points awarded or products or
                services purchased are expressly excluded to the fullest extent
                permitted by law. Any liability that cannot be fully excluded is
                limited to replacing, repairing, or crediting the value of
                Points redemption at our sole discretion.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>4.</span> Neither Al Tayer
                Group, the Earning Partners nor the Redemption Partners will be
                responsible or liable whatsoever for any direct, indirect or
                consequential loss, damage, cost or liability arising from the
                use of the Card, the Website or the Mobile App, from your
                participation in the Amber programme, or any other action or
                omission arising as a result of the these Terms or arising out
                of the Amber programme. Should Al Tayer Group, its group
                companies, any Earning Partners, or any Redemption Partner be
                found responsible or liable to you, such responsibility or
                liability shall be limited to the value of any Points standing
                to your credit at that time.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>5.</span> To the extent
                there is an inconsistency between the terms of these Terms and
                the terms set out on the Website and/or the App, the terms of
                the Terms shall take precedence.
              </Box>
              <Box sx={{ paddingLeft: "10px", marginBottom: "8px" }}>
                <span style={{ paddingRight: "8px" }}>6.</span> Earning Partners
                and Redemption Partners do not have any authority, express, or
                implied, to make any representation or warranty on our behalf.
              </Box>
              <Box sx={{ marginTop: "16px", marginBottom: "48px" }}>
                Earning Partners and Redemption Partners do not have any
                authority, express, or implied, to make any representation or
                warranty on our behalf.
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default DataPolicy;
