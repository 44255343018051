import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useMemo } from "react";
import LightBackground from "Components/LightBackground";
import { useGetEvents } from "services/events/events.query";
import Layout from "../Layout";
import { EVENTS_OFFERS_CMS_COMPONENT_MAP } from "CmsComponents";
import { LightBackgroundControl } from "GlobalProcess/GlobalFunction";
import { useLocation, useNavigate } from "react-router-dom";
import { AnchorLinkEnums } from "utils/enums";
import { useTranslation } from "react-i18next";
import { NavBarContainer } from "../NavBarContainer";
const EventsOffers = ({ scrollCntrl, setScrollCntrl }) => {
  const tag = useLocation().hash.replace("#", "");
  const { data: events = [] } = useGetEvents();
  const { widgets = [],seo_data } = events;
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width:900px)");
  const { i18n } = useTranslation();
  const configuration_type = widgets?.find(
    (widget) => widget?.component_type === "events-and-offers-configuration"
  );
  const configuration_events = configuration_type?.configuration_type?.find(
    (widget) => widget?.component_type === "events"
  );
  const configuration_offers = configuration_type?.configuration_type?.find(
    (widget) => widget?.component_type === "offers"
  );
  const userAgent = window?.navigator?.userAgent;

  const miniNavigationCountElement =
    widgets?.find((widget) => widget?.component_type === "mini-navigation")
      ?.items?.length >= 3;
  const carouselCountElement =
    widgets?.find((widget) => widget?.component_type === "carousel")?.items
      ?.length >= 3;
  const emptyDataMessaging = useMemo(() => {
    return widgets?.find(
      (item) => item?.component_type === "empty-data-messaging"
    );
  }, [widgets]);

  const navbar = widgets?.find(
    (item) => item?.component_type === "web-homepage-main-navigation"
  );

  const web_message = emptyDataMessaging?.web_message;
  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollCntrl(false);
  }, [scrollCntrl, setScrollCntrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content_description_style = {
    padding: mobile
      ? "32px 20px 0px 20px  !important"
      : "32px 11.11% 0px 11.11%  !important"
  };

  const carosel_description_style = {
    paddingTop: { xs: "0px !important" }
  };
  useEffect(() => {
    if (tag === AnchorLinkEnums.OFFERS) {
      setTimeout(() => {
        const element = document.getElementById(tag);
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 1500);
    }
    if (tag === AnchorLinkEnums.EVENTS) {
      setTimeout(() => {
        const element = document.getElementById(tag);
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 1500);
    }
  }, [tag]);

  return (
    <Layout className="events" seo_data={seo_data}>
      {!userAgent?.includes("amber") && <NavBarContainer widget={navbar} />}
      {!LightBackgroundControl(widgets) && (
        <LightBackground
          text={events?.title}
          xsMinHeight="200px"
          paddingTp="0px"
          minheight="320px"
          containerStyle={{
            justifyContent: "initial"
          }}
        />
      )}
      {!miniNavigationCountElement && !carouselCountElement ? (
        <Box
          sx={{
            fontFamily:
              i18n.language === "arabic"
                ? "TajawalRegular "
                : "MontserratRegular",
            direction: i18n.language === "arabic" ? "rtl" : "ltr",
            padding: "20px",
            fontSize:
              i18n.language === "arabic"
                ? { xs: "19px", lg: "23px" }
                : { xs: "16px", md: "18px", lg: "20px" }
          }}
        >
          {web_message || ""}
        </Box>
      ) : (
        <Box sx={{ minHeight: "100vh" }}>
          {widgets?.map((widget, index) => {
            const CmsComponent =
              EVENTS_OFFERS_CMS_COMPONENT_MAP[widget["component_type"]];
            if (CmsComponent) {
              return (
                <CmsComponent
                  key={index}
                  widget={widget}
                  page={"home"}
                  configuration_events={configuration_events}
                  configuration_offers={configuration_offers}
                  content_description_style={content_description_style}
                  carosel_description_style={carosel_description_style}
                 />
              );
            }
            return null;
          })}
        </Box>
      )}
    </Layout>
  );
};

export default EventsOffers;
