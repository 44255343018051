import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../components/CustomTable";

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box style={{ padding: 0 }}>
      <div>
        <p className="content-text" data-lang={i18n.language}>
          {t("about-us-abstraction")}
        </p>
      </div>
      <div>
        <h2 className="title" data-lang={i18n.language}>
          {t("statistics")}
        </h2>
        <p className="content-text" data-lang={i18n.language}>
          {t("statistics-part-1")}
        </p>
        <ul className="content-list">
          <li className="content-list-item" data-lang={i18n.language}>
            {t("List Item 1")}
          </li>
          <li className="content-list-item" data-lang={i18n.language}>
            {t("List Item 2")}
          </li>
          <li className="content-list-item" data-lang={i18n.language}>
            {t("List Item 3")}
          </li>
        </ul>
        <p className="content-text" data-lang={i18n.language}>
          {t("statistics-part-2")}
        </p>
        <CustomTable
          columns={["Table TH", "Table TH", "Table TH", "Table TH"]}
          data={[
            {
              id: "1",
              items: ["Table TD", "Table TD", "Table TD", "Table TD"]
            },
            {
              id: "2",
              items: ["Table TD", "Table TD", "Table TD", "Table TD"]
            }
          ]}
        />
      </div>
    </Box>
  );
};

export default AboutUs;
