import axios from "axios";
import { langMap } from "dateUtil";
import { getSelectedCountryFromStorage } from "utils/getSelectedCountryFromStorage";
import { mapResponseMessage } from "./_serviceMessages/transformResponseMessage";

export const API_URL = process.env.REACT_APP_API_URL;

export const getAxiosInstance = () =>
  axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });

export const getApiClient =
  (axiosInstance) =>
  ({
    url,
    method = "GET",
    data = undefined,
    headers: customHeaders = {},
    customConfig = {}
  }) => {
    const language = langMap[localStorage.getItem("language") || "en"];

    if (url.startsWith("/cms/")) {
      const selectedCountry = getSelectedCountryFromStorage();

      axiosInstance.defaults.params = {
        locale: language + "-" + (selectedCountry?.iso_code || "AE")
      };
    }
    axiosInstance.defaults.method = method;
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...customHeaders,
      language
    };

    return new Promise((resolve, reject) => {
      axiosInstance({
        method,
        url,
        data,
        ...customConfig
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          mapResponseMessage({ error, url, method });
          reject(error);
        });
    });
  };

export const getBearerTokenFunc = (axiosInstance) => (token) => {
  if (token) {
    axiosInstance.defaults.headers.Authorization = "Bearer " + token;
  }
};

export const getRemoveBearerTokenFunc = (axiosInstance) => () => {
  delete axiosInstance.defaults.headers.Authorization;
};
