import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import FooterContainer from "CmsComponents/FooterContainer";
import {
  useGetHomeContent,
  useGetHowItWorksContent,
  useGetAmberAppContent,
  useGetOnBoardingContent
} from "services/cms/cms.query";
import { useGetBrands } from "services/brands/brands.query";
import { useGetEvents } from "services/events/events.query";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
const Layout = ({ children, seo_data = {} }) => {
  const [updateLanguage, setUpdateLanguage] = useState(false);

  const { isLoading: isLoadingHomeContent } = useGetHomeContent();
  const { isLoading: isLoadingHowItWorksContent } = useGetHowItWorksContent();
  const { isLoading: isLoadingAmberAppContent } = useGetAmberAppContent();
  const { isLoading: isLoadingOnBoardingContent } = useGetOnBoardingContent();
  const { isLoading: isLoadingGetBrands } = useGetBrands();
  const { isLoading: isLoadingGetEvents } = useGetEvents();
  const userAgent = window?.navigator?.userAgent;
  const { pathname } = useLocation();
  const [LoadingControl, SetLoadingControl] = useState(false);
  useEffect(() => {
    if (!pathname?.includes("account")) {
      SetLoadingControl(true);
      setTimeout(() => {
        SetLoadingControl(false);
      }, 2000);
    }
  }, [pathname]);
  return (
    <Box style={{ position: "relative", height: "100%" }}>
      {isLoadingHomeContent ||
      isLoadingHowItWorksContent ||
      isLoadingAmberAppContent ||
      isLoadingOnBoardingContent ||
      isLoadingGetBrands ||
      isLoadingGetEvents ||
      updateLanguage ||
      LoadingControl ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height:
              isLoadingHomeContent ||
              isLoadingHowItWorksContent ||
              isLoadingAmberAppContent ||
              isLoadingOnBoardingContent ||
              isLoadingGetBrands ||
              isLoadingGetEvents ||
              updateLanguage ||
              LoadingControl
                ? "100vh"
                : "100%"
          }}
        >
          <CircularProgress
            size={"25px"}
            style={{
              color: "black"
            }}
          />
        </Box>
      ) : (
        <HelmetProvider>
          {seo_data && (
            <Helmet>
              {seo_data.meta_title ? (
                <title>{seo_data.meta_title}</title>
              ) : (
                <title>Amber An Al Tayer Group Loyalty Programme</title>
              )}
              {seo_data.meta_description && (
                <meta name="description" content={seo_data.meta_description} />
              )}
              {seo_data.meta_keywords && (
                <meta name="keywords" content={seo_data.meta_keywords} />
              )}
              {seo_data.canonical && (
                <link rel="canonical" href={seo_data.canonical_url} />
              )}
              {seo_data.robots_meta && (
                <meta name="robots" content={seo_data.robots_meta} />
              )}
            </Helmet>
          )}
          <main>{children}</main>
          {!userAgent?.includes("amber") ? (
            <FooterContainer setUpdateLanguage={setUpdateLanguage} />
          ) : (
            <div style={{ display: "none" }}>
              <FooterContainer setUpdateLanguage={setUpdateLanguage} />
            </div>
          )}
        </HelmetProvider>
      )}
    </Box>
  );
};
export default Layout;
