import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "locales/en.json";
import arabic from "locales/arabic.json";
import { setSelectedLanguageCodeToStorage } from "utils/setSelectedLanguageCodeToStorage";
import { COMPONENT_KEY_MAP } from "CmsComponents";
import * as Requests from "services/cms/cms.requests";
import { setSelectedCountryToStorage } from "utils/setSelectedCountryToStorage";
import { countries } from "Assets/helper/helper";
import { setSelectedCountryCodeToStorage } from "utils/setSelectedCountryCodeToStorage";

// Language Map (LangMap): This object (LangMap) is used to map language codes ("ar" for Arabic, "en" for English).
// replaceQueryParam Function: This function fetches data (presumably related to countries or regions) from a CMS via API requests. It is then used to manipulate and replace query parameters in the URL. In particular, it checks for a query parameter identified by the variable queryParamKey, and if found, it processes and replaces it in the URL.
// replaceLang Function: This function deals with the "lang" query parameter in the URL. It attempts to parse the "lang" parameter and sets the selected language and country based on the parsed parameter. If the language is successfully set, it also replaces the "lang" query parameter in the URL.
// i18n Initialization: The default language ("en") is set if no language is found in local storage.
// i18n is initialized using the i18n.use(initReactI18next).init({...}) method.
// It provides the app with resources for Arabic and English translations.
// The fallback language is set to "en" in case a translation is missing.
// The language ("lng") is set based on what's stored in local storage.
// Additional configurations for i18next are specified, such as key separators and interpolation settings.
// It is configured to work with React and set to wait for translations to load before rendering.
// Export Default: The i18n object, which is now fully configured and initialized, is exported as the default export.

export const LangMap = {
  ar: "arabic",
  en: "en"
};

const replaceQueryParam = (queryParamKey) => {
  Requests.getHomeContent().then((data) => {
    const web_footer =
      data?.widgets?.find(
        (item) =>
          item.component_type ===
          COMPONENT_KEY_MAP["web-homepage-footer-navigation"]
      ) || {};
    const section_name =
      web_footer?.sections?.find(
        (item) => item.section_name === "footer-filter-contents"
      ) || {};

    const countryMapdata =
      section_name.items !== undefined
        ? section_name?.items.map((item) => {
            if (item.id.toLowerCase() === queryParamKey.toLowerCase()) {
              let selectedCountry = {
                ...item,
                iso_code: item?.id.split("-")[1].toUpperCase(),
                name: item.title
              };
              setSelectedCountryToStorage(selectedCountry);
            }
            return {
              ...item,
              iso_code: item?.id.split("-")[1].toUpperCase(),
              name: item.title
            };
          })
        : [];
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.delete(queryParamKey);
  const newUrlParamsAsString = urlParams.toString();

  window.history.replaceState(
    null,
    "",
    window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      (newUrlParamsAsString ? "?" + newUrlParamsAsString : "")
  );
};

const replaceLang = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lang = urlParams.get("lang");
  if (lang?.includes("-")) {
    let langCode = lang?.split("-")?.[0]?.toLowerCase();
    let code = lang?.split("-")?.[1]?.toLowerCase();

    const filterRegion = () => {
      return countries?.filter(
        (country) =>
          country?.code?.toLocaleLowerCase() === code?.toLocaleLowerCase()
      );
    };

    if (lang) {
      if (LangMap[langCode]) {
        setSelectedLanguageCodeToStorage(LangMap[langCode]);
        setSelectedCountryCodeToStorage(filterRegion()?.countryCode);
        replaceQueryParam(lang);
      }
    }
  }
};

replaceLang();

let language = localStorage.getItem("language");
if (language === undefined) {
  language = "en";
}

i18n.use(initReactI18next).init({
  resources: {
    arabic: { translations: arabic },
    en: { translations: en }
  },
  fallbackLng: "en",
  lng: localStorage.getItem("language"),
  // debug only when not in production
  //   debug: process.env.NODE_ENV !== 'production',
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});

export default i18n;
