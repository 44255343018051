import { HttpStatusCode } from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorPages, PRIVATE_TOKEN_PERSIST_KEY } from "constants";
import { privateApiAxiosInstance } from "services/privateApiClient";
import { useRefreshToken } from "services/auth/auth.query";
import { useUserStore } from "./useUser";
import {
  getToken,
  onRemovePrivateToken,
  onSetPrivateToken
} from "utils/authStorage";

const errorExcludePaths = ["/account/purchase-history"];

const AxiosInterceptor = () => {
  const { removeUser } = useUserStore();
  const { mutateAsync: refreshToken } = useRefreshToken({
    onSuccess: () => {},
    onError: () => {}
  });
  const navigate = useNavigate();

  // removeUser(): This function removes user-related data or tokens, effectively logging the user out.
  // navigate(ErrorPages[401], { replace: true }): It redirects the user to an error page associated with an HTTP status code 401 (typically indicating unauthorized access). The { replace: true } option replaces the current page's URL with the new URL, preventing the user from navigating back to the previous page using the browser's back button.

  const onAuthFailed = () => {
    removeUser();
    navigate(ErrorPages[401], { replace: true });
  };

  useEffect(() => {
    const resInterceptor = (response) => response;

    const errInterceptor = async (error) => {
      const originalConfig = error.config;
      const url = originalConfig.url;
      // remove the search params from the url
      const cleanUrl = url.split("?")[0];

      if (error.response.status === HttpStatusCode.Unauthorized) {
        const existingToken = getToken(PRIVATE_TOKEN_PERSIST_KEY);
        if (existingToken && !originalConfig._retry) {
          try {
            originalConfig._retry = true;
            const { token } = await refreshToken({ token: existingToken });
            onSetPrivateToken(token);
            originalConfig.headers.Authorization = "Bearer " + token;
            return privateApiAxiosInstance(originalConfig);
          } catch (error) {
            onRemovePrivateToken();
            onAuthFailed();
          }
        } else {
          onAuthFailed();
        }
      } else if (
        error.response.status >= HttpStatusCode.InternalServerError &&
        !errorExcludePaths.includes(cleanUrl)
      ) {
        navigate(ErrorPages[500], { replace: true });
      }

      return Promise.reject(error);
    };

    const interceptor = privateApiAxiosInstance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () =>
      privateApiAxiosInstance.interceptors.response.eject(interceptor);
  }, []);

  return null;
};

export default AxiosInterceptor;
