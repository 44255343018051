import "./_serviceMessages/serviceMessages";
import {
  getApiClient,
  getAxiosInstance,
  getBearerTokenFunc,
  getRemoveBearerTokenFunc
} from "./commonApiClient";

export const axiosInstance = getAxiosInstance();
export const apiClient = getApiClient(axiosInstance);
export const setBearerToken = getBearerTokenFunc(axiosInstance);
export const removeBearerToken = getRemoveBearerTokenFunc(axiosInstance);
