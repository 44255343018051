import { Box, CircularProgress, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackNavigator from "Components/BackNavigator";
import { useIsLanguageArabic } from "hooks";
import Layout from "../Layout";
import RewardCarouselWithDetail from "./RewardCarouselWithDetail";
import {
  useGetAccount,
  useGetArchivedCoupons,
  useGetPromotions,
  useGetRewardCards
} from "services/account/account.query";
import RewardCard from "./RewardCard";
import { RewardTypeEnums } from "utils/enums";
import { dateDiffrence } from "dateUtil";
import PurchaseContainer from "../PurchaseHistory/PurchaseContainer";
const YourRewards = () => {
  const { t, i18n } = useTranslation();
  const isLanguageArabic = useIsLanguageArabic();
  const {
    data: rewardCardsData,
    isLoading: rewardsCardLoading,
    isSuccess: rewardsCardSuccess
  } = useGetRewardCards();
  const {
    data: promotionsData,
    isLoading: promotionLoading,
    isSuccess: promotionSuccess
  } = useGetPromotions();
  const {
    data: userInfoData,
    isLoading: userInfoLoading,
    isSuccess: userInfoSuccess
  } = useGetAccount();
  const {
    data: archivedCoupons,
    isLoading: archivedLoading,
    isSuccess: archivedSuccess
  } = useGetArchivedCoupons();

  const tabletMax = useMediaQuery("(min-width:1390px)");

  //   getDefaultRewardCard(data, type):
  // This function retrieves the default reward card's front or back image based on the provided type.
  // It takes two parameters:
  // data: The data containing reward card configurations.
  // type: A string, which should be "front" or "back" to specify which image to retrieve.
  // It finds the default reward card configuration based on the ID "default" and returns the corresponding image URL for the specified type.

  // getDefaultRewardCardBrandLogo(data):
  // This function retrieves the brand logo for the default reward card.
  // It takes one parameter, data, which contains reward card configurations.
  // It finds the default reward card configuration based on the ID "default" and returns the brand logo URL.

  // getDefaultRewardCardBrandName(data):
  // This function retrieves the brand name associated with the default reward card.
  // It takes one parameter, data, which contains reward card configurations.
  // It finds the default reward card configuration based on the ID "default" and returns the brand name.

  // getDefaultRewardCardBrandDescription(data):
  // This function retrieves the description or information associated with the back side of the default reward card.
  // It takes one parameter, data, which contains reward card configurations.
  // It finds the default reward card configuration based on the ID "default" and returns the card back description.

  // getDefaultRewardCardTheme(data):
  // This function retrieves the color theme associated with the default reward card for web display.
  // It takes one parameter, data, which contains reward card configurations.
  // It finds the default reward card configuration based on the ID "default" and returns the color theme for web display.

  const getDefaultRewardCard = (data, type) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    if (type == "front") {
      return defaultRewardCard?.web?.assets?.front;
    } else {
      return defaultRewardCard?.web?.assets?.back;
    }
  };
  const getDefaultRewardCardBrandLogo = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.brand_logo;
  };
  const getDefaultRewardCardBrandName = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.brand_name;
  };
  const getDefaultRewardCardBrandDescription = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.card_back_description;
  };
  const getDefaultRewardCardTheme = (data) => {
    const defaultRewardCard =
      data?.items[0]?.fields?.configuration?.reward_card_types?.find(
        (item) => item?.id === "default"
      );
    return defaultRewardCard?.web?.color_theme;
  };

  const [rewardCardsList, setRewardCardsList] = useState([]);
  const [expiredRewardCards, setExpiredRewardCards] = useState([]);
  const [expiredRewardCardTheme, setExpiredRewardCardsTheme] = useState();
  useEffect(() => {
    if (
      promotionSuccess &&
      rewardsCardSuccess &&
      userInfoSuccess &&
      archivedSuccess
    ) {
      let tempRewards = [];
      let expiredTempRewards = [];
      if (
        rewardCardsData?.items?.length > 0 &&
        promotionsData?.customer_promotions?.length > 0
      ) {
        const { fields } = rewardCardsData?.items[0];
        if (fields?.configuration?.reward_card_types?.length > 0) {
          promotionsData?.customer_promotions?.map(
            (promotion, indexPromotion) => {
              const rewardCard = fields?.configuration?.reward_card_types?.find(
                (rewardCard) => rewardCard?.id === promotion?.applicable_stores
              );

              const expiredRewardCard = fields?.configuration?.expired;
              setExpiredRewardCardsTheme(expiredRewardCard);
              if (rewardCard?.id === promotion?.applicable_stores) {
                if (
                  (!promotion?.offer_validity ||
                    dateDiffrence(promotion?.offer_validity) >= 0) &&
                  promotion?.offer_status === "active"
                ) {
                  tempRewards.push({
                    id: indexPromotion + 1,
                    image: {
                      couponBarcode:
                        userInfoData?.Customer[0]?.LoyaltyInformaton?.Card
                          ?.CardID || "NA",
                      discount: promotion?.discount,
                      discountType: promotion?.discount_type,
                      cardTitleDescription: promotion?.offer_name,
                      offerStatus: promotion?.offer_status || "",
                      offerValidity: promotion?.offer_validity,
                      cardBackgroundImage:
                        rewardCard?.web?.assets?.front ||
                        getDefaultRewardCard(rewardCardsData, "front"),
                      cardBackgroundImageHalf:
                        rewardCard?.web?.assets?.back ||
                        getDefaultRewardCard(rewardCardsData, "back"),
                      BrandLogo:
                        rewardCard?.brand_logo ||
                        getDefaultRewardCardBrandLogo(rewardCardsData),
                      brandName:
                        rewardCard?.brand_name ||
                        getDefaultRewardCardBrandName(rewardCardsData),
                      ctaText: rewardCard?.cta_text,
                      cardDetailDescription:
                        rewardCard?.card_back_description ||
                        getDefaultRewardCardBrandDescription(rewardCardsData),
                      cardTheme:
                        rewardCard?.web?.color_theme ||
                        getDefaultRewardCardTheme(rewardCardsData),
                      expiredBrandLogo: rewardCard?.expired_brand_logo,
                      expiredCardBackgroundImage:
                        fields?.configuration?.expired?.bg_asset,
                      expiredCardTheme:
                        fields?.configuration?.expired?.text_color,
                      rewardType: RewardTypeEnums.PROMOTIONS,
                      expired: false
                    }
                  });
                } else {
                  expiredTempRewards.push({
                    id: indexPromotion + 1,
                    image: {
                      couponBarcode:
                        userInfoData?.Customer[0]?.LoyaltyInformaton?.Card
                          ?.CardID || "NA",
                      discount: promotion?.discount,
                      discountType: promotion?.discount_type,
                      cardTitleDescription: promotion?.offer_name,
                      offerStatus: promotion?.promotion_status?.split(" ")[0]
                        ? promotion?.offer_status?.split(" ")[0].toUpperCase()
                        : "",
                      offerValidity: promotion?.offer_validity,
                      cardBackgroundImage:
                        fields?.configuration?.reward_card_types[0]?.web?.assets
                          ?.front ||
                        getDefaultRewardCard(rewardCardsData, "front"),
                      cardBackgroundImageHalf:
                        fields?.configuration?.reward_card_types[0]?.web?.assets
                          ?.back ||
                        getDefaultRewardCard(rewardCardsData, "back"),
                      BrandLogo:
                        fields?.configuration?.reward_card_types[0]?.brand_logo,
                      brandName:
                        fields?.configuration?.reward_card_types[0]?.brand_name,
                      ctaText:
                        fields?.configuration?.reward_card_types[0]?.cta_text,
                      cardDetailDescription:
                        fields?.configuration?.reward_card_types[0]
                          ?.card_back_description,
                      cardTheme:
                        fields?.configuration?.reward_card_types[0]?.web
                          ?.color_theme ||
                        getDefaultRewardCardTheme(rewardCardsData),
                      expiredBrandLogo:
                        fields?.configuration?.reward_card_types[0]
                          ?.expired_brand_logo,
                      expiredCardBackgroundImage:
                        fields?.configuration?.expired?.bg_asset,
                      expiredCardTheme:
                        fields?.configuration?.expired?.text_color,
                      rewardType: RewardTypeEnums.PROMOTIONS,
                      expired: true
                    }
                  });
                }
              } else {
                if (
                  !promotion?.offer_validity ||
                  dateDiffrence(promotion?.offer_validity) >= 0
                ) {
                  tempRewards.push({
                    id: indexPromotion + 1,
                    image: {
                      couponBarcode:
                        userInfoData?.Customer[0]?.LoyaltyInformaton?.Card
                          ?.CardID || "NA",
                      discount: promotion?.discount,
                      discountType: promotion?.discount_type,
                      cardTitleDescription: promotion?.offer_name,
                      offerStatus: promotion?.offer_status || "",
                      offerValidity: promotion?.offer_validity,
                      cardBackgroundImage:
                        rewardCard?.web?.assets?.front ||
                        getDefaultRewardCard(rewardCardsData, "front"),
                      cardBackgroundImageHalf:
                        rewardCard?.web?.assets?.back ||
                        getDefaultRewardCard(rewardCardsData, "back"),
                      BrandLogo:
                        rewardCard?.brand_logo ||
                        getDefaultRewardCardBrandLogo(rewardCardsData),
                      brandName:
                        rewardCard?.brand_name ||
                        getDefaultRewardCardBrandName(rewardCardsData),
                      ctaText: rewardCard?.cta_text,
                      cardDetailDescription:
                        rewardCard?.card_back_description ||
                        getDefaultRewardCardBrandDescription(rewardCardsData),
                      cardTheme:
                        rewardCard?.web?.color_theme ||
                        getDefaultRewardCardTheme(rewardCardsData),
                      expiredBrandLogo: rewardCard?.expired_brand_logo,
                      expiredCardBackgroundImage:
                        fields?.configuration?.expired?.bg_asset,
                      expiredCardTheme:
                        fields?.configuration?.expired?.text_color,
                      rewardType: RewardTypeEnums.PROMOTIONS,
                      expired: false
                    }
                  });
                } else {
                  expiredTempRewards.push({
                    id: indexPromotion + 1,
                    image: {
                      couponBarcode:
                        userInfoData?.Customer[0]?.LoyaltyInformaton?.Card
                          ?.CardID || "NA",
                      discount: promotion?.discount,
                      discountType: promotion?.discount_type,
                      cardTitleDescription: promotion?.offer_name,
                      offerStatus: promotion?.promotion_status?.split(" ")[0]
                        ? promotion?.offer_status?.split(" ")[0].toUpperCase()
                        : "",
                      offerValidity: promotion?.offer_validity,
                      cardBackgroundImage:
                        fields?.configuration?.reward_card_types[0]?.web?.assets
                          ?.front ||
                        getDefaultRewardCard(rewardCardsData, "front"),
                      cardBackgroundImageHalf:
                        fields?.configuration?.reward_card_types[0]?.web?.assets
                          ?.back ||
                        getDefaultRewardCard(rewardCardsData, "back"),
                      BrandLogo:
                        fields?.configuration?.reward_card_types[0]?.brand_logo,
                      brandName:
                        fields?.configuration?.reward_card_types[0]?.brand_name,
                      ctaText:
                        fields?.configuration?.reward_card_types[0]?.cta_text,
                      cardDetailDescription:
                        fields?.configuration?.reward_card_types[0]
                          ?.card_back_description,
                      cardTheme:
                        fields?.configuration?.reward_card_types[0]?.web
                          ?.color_theme ||
                        getDefaultRewardCardTheme(rewardCardsData),
                      expiredBrandLogo:
                        fields?.configuration?.reward_card_types[0]
                          ?.expired_brand_logo,
                      expiredCardBackgroundImage:
                        fields?.configuration?.expired?.bg_asset,
                      expiredCardTheme:
                        fields?.configuration?.expired?.text_color,
                      rewardType: RewardTypeEnums.PROMOTIONS,
                      expired: true
                    }
                  });
                }
              }
            }
          );
        }
      }
      if (
        rewardCardsData?.items?.length > 0 &&
        promotionsData?.customer_coupons?.length > 0
      ) {
        const { fields } = rewardCardsData?.items[0];
        promotionsData?.customer_coupons?.map((coupon, indexCoupon) => {
          const rewardCard = fields?.configuration?.reward_card_types?.find(
            (rewardCard) => rewardCard?.id === coupon?.applicable_stores
          );
          const check = archivedCoupons.find(
            (item) => item?.Barcode === coupon?.coupon_barcode
          );
          if (check) {
            expiredTempRewards.push({
              id: indexCoupon + 1,
              image: {
                couponBarcode: coupon?.coupon_barcode,
                discount: coupon?.discount,
                discountType: coupon?.discount_type,
                cardTitleDescription: coupon?.coupon_name,
                offerStatus: coupon?.coupon_status?.split(" ")[0]
                  ? coupon?.coupon_status?.split(" ")[0].toUpperCase()
                  : "",
                offerValidity: coupon?.coupon_validity,
                cardBackgroundImage:
                  fields?.configuration?.reward_card_types[0]?.web?.assets
                    ?.front || getDefaultRewardCard(rewardCardsData, "front"),
                cardBackgroundImageHalf:
                  fields?.configuration?.reward_card_types[0]?.web?.assets
                    ?.back || getDefaultRewardCard(rewardCardsData, "back"),
                BrandLogo:
                  fields?.configuration?.reward_card_types[0]?.brand_logo,
                brandName:
                  fields?.configuration?.reward_card_types[0]?.brand_name || "",
                ctaText: fields?.configuration?.reward_card_types[0]?.cta_text,
                cardDetailDescription:
                  fields?.configuration?.reward_card_types[0]
                    ?.card_back_description,
                cardTheme:
                  fields?.configuration?.reward_card_types[0]?.web
                    ?.color_theme || getDefaultRewardCardTheme(rewardCardsData),
                expiredBrandLogo:
                  fields?.configuration?.reward_card_types[0]
                    ?.expired_brand_logo,
                expiredCardBackgroundImage:
                  fields?.configuration?.expired?.bg_asset,
                expiredCardTheme: fields?.configuration?.expired?.text_color,
                rewardType: RewardTypeEnums.CUPONS,
                expired: true
              }
            });
          } else {
            if (rewardCard?.id === coupon?.applicable_stores) {
              if (dateDiffrence(coupon?.coupon_validity) >= 0) {
                tempRewards.push({
                  id: indexCoupon + 1,
                  image: {
                    couponBarcode: coupon?.coupon_barcode,
                    discount: coupon?.discount,
                    discountType: coupon?.discount_type,
                    cardTitleDescription: coupon?.coupon_name,
                    offerStatus: coupon?.coupon_status?.split(" ")[0]
                      ? coupon?.coupon_status?.split(" ")[0].toUpperCase()
                      : "",
                    offerValidity: coupon?.coupon_validity,
                    cardBackgroundImage:
                      rewardCard?.web?.assets?.front ||
                      getDefaultRewardCard(rewardCardsData, "front"),
                    cardBackgroundImageHalf:
                      rewardCard?.web?.assets?.back ||
                      getDefaultRewardCard(rewardCardsData, "back"),
                    BrandLogo:
                      rewardCard?.brand_logo ||
                      getDefaultRewardCardBrandLogo(rewardCardsData),
                    brandName:
                      rewardCard?.brand_name ||
                      getDefaultRewardCardBrandName(rewardCardsData) ||
                      "",
                    ctaText: rewardCard?.cta_text,
                    cardDetailDescription:
                      rewardCard?.card_back_description ||
                      getDefaultRewardCardBrandDescription(rewardCardsData),
                    cardTheme:
                      rewardCard?.web?.color_theme ||
                      getDefaultRewardCardTheme(rewardCardsData),
                    expiredBrandLogo: rewardCard?.expired_brand_logo,
                    expiredCardBackgroundImage:
                      fields?.configuration?.expired?.bg_asset,
                    expiredCardTheme:
                      fields?.configuration?.expired?.text_color,
                    rewardType: RewardTypeEnums.CUPONS,
                    expired: false
                  }
                });
              }
              if (dateDiffrence(coupon?.coupon_validity) < 0) {
                expiredTempRewards.push({
                  id: indexCoupon + 1,
                  image: {
                    couponBarcode: coupon?.coupon_barcode,
                    discount: coupon?.discount,
                    discountType: coupon?.discount_type,
                    cardTitleDescription: coupon?.coupon_name,
                    offerStatus: coupon?.coupon_status?.split(" ")[0]
                      ? coupon?.coupon_status?.split(" ")[0].toUpperCase()
                      : "",
                    offerValidity: coupon?.coupon_validity,
                    cardBackgroundImage:
                      rewardCard?.web?.assets?.front ||
                      getDefaultRewardCard(rewardCardsData, "front"),
                    cardBackgroundImageHalf:
                      rewardCard?.web?.assets?.back ||
                      getDefaultRewardCard(rewardCardsData, "back"),
                    BrandLogo:
                      rewardCard?.brand_logo ||
                      getDefaultRewardCardBrandLogo(rewardCardsData),
                    brandName:
                      rewardCard?.brand_name ||
                      getDefaultRewardCardBrandName(rewardCardsData) ||
                      "",
                    ctaText: rewardCard?.cta_text,
                    cardDetailDescription:
                      rewardCard?.card_back_description ||
                      getDefaultRewardCardBrandDescription(rewardCardsData),
                    cardTheme:
                      rewardCard?.web?.color_theme ||
                      getDefaultRewardCardTheme(rewardCardsData),
                    expiredBrandLogo: rewardCard?.expired_brand_logo,
                    expiredCardBackgroundImage:
                      fields?.configuration?.expired?.bg_asset,
                    expiredCardTheme:
                      fields?.configuration?.expired?.text_color,
                    rewardType: RewardTypeEnums.CUPONS,
                    expired: true
                  }
                });
              }
            } else {
              if (dateDiffrence(coupon?.coupon_validity) >= 0) {
                tempRewards.push({
                  id: indexCoupon + 1,
                  image: {
                    couponBarcode: coupon?.coupon_barcode,
                    discount: coupon?.discount,
                    discountType: coupon?.discount_type,
                    cardTitleDescription: coupon?.coupon_name,
                    offerStatus: coupon?.coupon_status?.split(" ")[0]
                      ? coupon?.coupon_status?.split(" ")[0].toUpperCase()
                      : "",
                    offerValidity: coupon?.coupon_validity,
                    cardBackgroundImage:
                      fields?.configuration?.reward_card_types[0]?.web?.assets
                        ?.front ||
                      getDefaultRewardCard(rewardCardsData, "front"),
                    cardBackgroundImageHalf:
                      fields?.configuration?.reward_card_types[0]?.web?.assets
                        ?.back || getDefaultRewardCard(rewardCardsData, "back"),
                    BrandLogo:
                      fields?.configuration?.reward_card_types[0]?.brand_logo,
                    brandName:
                      fields?.configuration?.reward_card_types[0]?.brand_name ||
                      "",
                    ctaText:
                      fields?.configuration?.reward_card_types[0]?.cta_text,
                    cardDetailDescription:
                      fields?.configuration?.reward_card_types[0]
                        ?.card_back_description,
                    cardTheme:
                      fields?.configuration?.reward_card_types[0]?.web
                        ?.color_theme ||
                      getDefaultRewardCardTheme(rewardCardsData),
                    expiredBrandLogo:
                      fields?.configuration?.reward_card_types[0]
                        ?.expired_brand_logo,
                    expiredCardBackgroundImage:
                      fields?.configuration?.expired?.bg_asset,
                    expiredCardTheme:
                      fields?.configuration?.expired?.text_color,
                    rewardType: RewardTypeEnums.CUPONS,
                    expired: false
                  }
                });
              }
              if (dateDiffrence(coupon?.coupon_validity) < 0) {
                expiredTempRewards.push({
                  id: indexCoupon + 1,
                  image: {
                    couponBarcode: coupon?.coupon_barcode,
                    discount: coupon?.discount,
                    discountType: coupon?.discount_type,
                    cardTitleDescription: coupon?.coupon_name,
                    offerStatus: coupon?.coupon_status?.split(" ")[0]
                      ? coupon?.coupon_status?.split(" ")[0].toUpperCase()
                      : "",
                    offerValidity: coupon?.coupon_validity,
                    cardBackgroundImage:
                      fields?.configuration?.reward_card_types[0]?.web?.assets
                        ?.front ||
                      getDefaultRewardCard(rewardCardsData, "front"),
                    cardBackgroundImageHalf:
                      fields?.configuration?.reward_card_types[0]?.web?.assets
                        ?.back || getDefaultRewardCard(rewardCardsData, "back"),
                    BrandLogo:
                      fields?.configuration?.reward_card_types[0]?.brand_logo,
                    brandName:
                      fields?.configuration?.reward_card_types[0]?.brand_name ||
                      "",
                    ctaText:
                      fields?.configuration?.reward_card_types[0]?.cta_text,
                    cardDetailDescription:
                      fields?.configuration?.reward_card_types[0]
                        ?.card_back_description,
                    cardTheme:
                      fields?.configuration?.reward_card_types[0]?.web
                        ?.color_theme ||
                      getDefaultRewardCardTheme(rewardCardsData),
                    expiredBrandLogo:
                      fields?.configuration?.reward_card_types[0]
                        ?.expired_brand_logo,
                    expiredCardBackgroundImage:
                      fields?.configuration?.expired?.bg_asset,
                    expiredCardTheme:
                      fields?.configuration?.expired?.text_color,
                    rewardType: RewardTypeEnums.CUPONS,
                    expired: true
                  }
                });
              }
            }
          }
        });
      }

      setRewardCardsList(tempRewards);
      setExpiredRewardCards(expiredTempRewards);
    }
  }, [
    archivedCoupons,
    archivedSuccess,
    promotionSuccess,
    promotionsData?.customer_coupons,
    promotionsData?.customer_promotions,
    rewardCardsData?.items,
    rewardsCardSuccess,
    userInfoData?.Customer,
    userInfoSuccess,
    dateDiffrence
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (
    rewardsCardLoading ||
    promotionLoading ||
    userInfoLoading ||
    archivedLoading
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height:
            rewardsCardLoading ||
            promotionLoading ||
            userInfoLoading ||
            archivedLoading
              ? "100vh"
              : "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  }
  return (
    <Layout navbar={"brands"}>
      <PurchaseContainer>
        <Grid
          className="reward-system your-rewards"
          container
          rowSpacing={0}
          columnSpacing={{ xs: 0 }}
          sx={{
            paddingLeft: { xs: "20px", md: "11.11%" },
            paddingRight: { xs: "20px", md: "11.11%" },
            height: "100%",
            paddingTop: { xs: "96px", md: "144px" },
            paddingBottom: !isLanguageArabic ? { xs: "40px", md: "72px" } : {},
            background: "#F3F3FA",
            display: "flex",
            justifyContent: { xs: "flex-start" },
            alignItems: "center",
            direction: isLanguageArabic ? "rtl" : "ltr"
          }}
        >
          <Grid xs={12}>
            <BackNavigator title={t("Back to Dashboard")} path={"/dashboard"} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { xs: "100%" },
                alignItems: "center",
                direction: i18n.language === "arabic" ? "rtl" : "initial"
              }}
            >
              <div
                className={
                  i18n.language === "arabic" ? "user-name-arabic" : "user-name"
                }
              >
                {t("Your Rewards")}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid
          className="reward-system"
          container
          spacing="35px"
          sx={{
            paddingLeft: { xs: "20px", md: "11.11%" },
            paddingRight: { xs: "20px", md: "11.11%" },
            marginTop: { xs: "32px", md: "48px" },
            justifyContent: { xs: "flex-start" },
            direction: isLanguageArabic ? "rtl" : "ltr",
            background: "white",
            rowGap: { xs: "16px", md: "32px" }
          }}
        >
          {rewardCardsList?.length > 0 &&
            rewardCardsList.map((item, index) => (
              <Grid key={index} item xs={12} md={6} lg={tabletMax ? 4 : 6}>
                <RewardCarouselWithDetail
                  item={item}
                  rewards={rewardCardsList}
                  cardTheme={
                    item?.image?.cardTheme ||
                    getDefaultRewardCardTheme(rewardCardsData)
                  }
                >
                  {(onClickItem) => (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickItem(item.id)}
                    >
                      <RewardCard
                        key={index}
                        cardTitleDescription={item?.image?.cardTitleDescription}
                        cardBackgroundImage={
                          item?.image?.cardBackgroundImage ||
                          getDefaultRewardCard(rewardCardsData, "front")
                        }
                        cardBackgroundImageHalf={
                          item?.image?.cardBackgroundImageHalf ||
                          getDefaultRewardCard(rewardCardsData, "back")
                        }
                        BrandLogo={item?.image?.BrandLogo}
                        brandName={item?.image?.brandName}
                        ctaText={item?.image?.ctaText}
                        cardDetailDescription={
                          item?.image?.cardDetailDescription
                        }
                        cardTheme={
                          item?.image?.cardTheme ||
                          getDefaultRewardCardTheme(rewardCardsData)
                        }
                        expiredBrandLogo={item?.image?.expiredBrandLogo}
                        expiredCardBackgroundImage={
                          item?.image?.expiredCardBackgroundImage
                        }
                        expiredCardTheme={item?.image?.expiredCardTheme}
                        offerStatus={item?.image?.offerStatus}
                        offerValidity={item?.image?.offerValidity}
                        couponBarcode={item?.image?.couponBarcode}
                        discount={item?.image?.discount}
                        discountType={item?.image?.discountType}
                        rewardType={item?.image?.rewardType}
                        expired={false}
                        item={item}
                      />
                    </div>
                  )}
                </RewardCarouselWithDetail>
              </Grid>
            ))}
        </Grid>

        <Grid
          className="reward-system"
          container
          rowSpacing={4}
          columnSpacing={{ xs: 0 }}
          sx={{
            paddingLeft: { xs: "20px", md: "11.11%" },
            paddingRight: { xs: "20px", md: "11.11%" },
            marginTop: { xs: "16px", md: "48px" },
            background: "white",
            direction: isLanguageArabic ? "rtl" : "ltr",
            paddingBottom: "64px",
            justifyContent: { xs: "flex-start" }
          }}
        >
          <Grid item xs={12}>
            {expiredRewardCards?.length > 0 && (
              <Box
                sx={{
                  marginTop: "16px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "Tajawalbold !important"
                      : "ArchivoBlack",
                  lineHeight: "18px",
                  letterSpacing: i18n.language === "arabic" ? "0px" : "2.8px",
                  fontSize: i18n.language === "arabic" ? "17px" : "14px"
                }}
              >
                {t("EXPIRED OR USED REWARDS")}
              </Box>
            )}
          </Grid>
          {expiredRewardCards?.length > 0 &&
            expiredRewardCards.map((item, index) => (
              <Grid key={index} item xs={12} md={6} lg={tabletMax ? 4 : 6}>
                <div style={{ cursor: "pointer" }}>
                  <RewardCard
                    key={index}
                    cardTitleDescription={item?.image?.cardTitleDescription}
                    cardBackgroundImage={
                      item?.image?.cardBackgroundImage ||
                      getDefaultRewardCard(rewardCardsData, "front")
                    }
                    cardBackgroundImageHalf={
                      item?.image?.cardBackgroundImageHalf ||
                      getDefaultRewardCard(rewardCardsData, "back")
                    }
                    BrandLogo={item?.image?.BrandLogo}
                    brandName={item?.image?.brandName}
                    ctaText={item?.image?.ctaText}
                    cardDetailDescription={item?.image?.cardDetailDescription}
                    cardTheme={
                      item?.image?.cardTheme ||
                      getDefaultRewardCardTheme(rewardCardsData)
                    }
                    expiredBrandLogo={item?.image?.expiredBrandLogo}
                    expiredCardBackgroundImage={
                      item?.image?.expiredCardBackgroundImage
                    }
                    expiredCardTheme={item?.image?.expiredCardTheme}
                    offerStatus={item?.image?.offerStatus}
                    offerValidity={item?.image?.offerValidity}
                    couponBarcode={item?.image?.couponBarcode}
                    discount={item?.image?.discount}
                    discountType={item?.image?.discountType}
                    rewardType={item?.image?.rewardType}
                    expiredTextColor={expiredRewardCardTheme}
                    expired={true}
                    item={item}
                  />
                </div>
              </Grid>
            ))}
        </Grid>
      </PurchaseContainer>
    </Layout>
  );
};

export default YourRewards;
