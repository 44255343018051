import { Grid, CircularProgress, Box } from "@mui/material";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormSettingsWrapper,
  SettingsDescription,
  SettingsSubTitle
} from "../common";
import PrimaryButton from "../components/PrimaryButton";
import NotificationSettingsItem from "./NotificationSettingsItem";
import { useIsMobileView } from "hooks";
import {
  usePostNotificationSettings,
  useGetNotificationSettings
} from "services/account/account.query";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import { useQueryClient } from "@tanstack/react-query";
import { ACCOUNT_NOTIFICATION_SETTINGS_DETAIL } from "services/serviceKeys";
import NotificationSnack from "../components/NotificationSnack";

const NotificationSettings = () => {
  const {
    data: userData,
    isFetching: getAccountFetching,
    isLoading: getAccountLoading
  } = useGetNotificationSettings();
  const { t } = useTranslation();

  const contact_by_email =
    userData?.[0]?.values?.emailpreference === "True" || false;
  const contact_by_sms =
    userData?.[0]?.values?.smspreference === "True" || false;
  const personalized_data =
    userData?.[0]?.values?.personalizeddata === "True" || false;
  const most_out_of_amber =
    userData?.[0]?.values?.mostoutofamber === "True" || false;
  const [loadingControl, SetLoadingControl] = useState(false);
  const client = useQueryClient();
  const isMobileView = useIsMobileView();
  const [showSnack, setShowSnack] = useState(false);

  const { mutate: mutateUpdateNotificationSettings } =
    usePostNotificationSettings({
      onSuccess: async () => {
        client.invalidateQueries([ACCOUNT_NOTIFICATION_SETTINGS_DETAIL]);
        setShowSnack(true);
      },
      onError: () => {}
    });

  useEffect(() => {
    if (loadingControl) {
      setTimeout(() => {
        SetLoadingControl(false);
      }, 0);
    }
  }, [getAccountFetching]);

  const { i18n } = useTranslation();
  const notificationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact_by_email: contact_by_email == 1,
      contact_by_sms: contact_by_sms == 1,
      personalized_data: personalized_data == 1,
      most_out_of_amber: most_out_of_amber == 1
    },

    onSubmit: async ({
      contact_by_email,
      contact_by_sms,
      personalized_data,
      most_out_of_amber
    }) => {
      SetLoadingControl(true);
      await mutateUpdateNotificationSettings({
        contact_by_email: contact_by_email ? "1" : "0",
        contact_by_sms: contact_by_sms ? "1" : "0",
        personalized_data: personalized_data ? "1" : "0",
        most_out_of_amber: most_out_of_amber ? "1" : "0"
      });
    }
  });

  if (getAccountLoading && getAccountFetching)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: getAccountLoading || getAccountFetching ? "30vh" : "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );

  return (
    <>
      {
        <Grid>
          <SettingsSubTitle text={t("Notifications")} />
          <SettingsDescription
            className="settings-description-header"
            text={t("notificationMessage")}
          />
          <form onSubmit={notificationForm.handleSubmit}>
            <FormSettingsWrapper
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "41px"
              }}
            >
              <NotificationSettingsItem
                key="contact_by_email"
                name="contact_by_email"
                value={notificationForm.values.contact_by_email}
                onChange={notificationForm.handleChange}
                text={t("Emails")}
                description={t("emailsMessage")}
              />
              <NotificationSettingsItem
                key="contact_by_sms"
                name="contact_by_sms"
                value={notificationForm.values.contact_by_sms}
                onChange={notificationForm.handleChange}
                text={t("SMS")}
                description={t("smsMessage")}
              />
              <NotificationSettingsItem
                key="personalised-data"
                name="personalized_data"
                value={notificationForm.values.personalized_data}
                onChange={notificationForm.handleChange}
                text={t("Personalised data")}
                description={t("personalisedDataMessage")}
              />
              <NotificationSettingsItem
                key="out-of-amber"
                name="most_out_of_amber"
                value={notificationForm.values.most_out_of_amber}
                onChange={notificationForm.handleChange}
                text={t("Get the most out of Amber")}
                description={t("getTheMostOutOfAmberMessage")}
              />
              <div>
                {loadingControl ? (
                  <ColorLoadingButton
                    size="small"
                    loading={true}
                    variant="outlined"
                    disabled
                    type="submit"
                    className="button-submit-loading"
                    sx={{
                      fontFamily:
                        i18n.language === "arabic"
                          ? "TajawalMedium"
                          : "MontserratMedium",
                      maxWidth: "100%",
                      minWidth:
                        i18n.language === "arabic"
                          ? { xs: "100%", md: "119px" }
                          : { xs: "100%", md: "129px" },
                      marginTop: "24px",
                      height: "37px"
                    }}
                  />
                ) : (
                  <PrimaryButton
                    fullWidth={isMobileView}
                    text={t("Save changes")}
                  />
                )}
              </div>
            </FormSettingsWrapper>
          </form>

          <NotificationSnack
            message={t("Your notification settings have been updated")}
            open={showSnack}
            handleClose={() => {
              setShowSnack(false);
            }}
          />
        </Grid>
      }
    </>
  );
};

export default NotificationSettings;
