import React, { useMemo, useCallback } from "react";
import CategoryList from "Components/Homecomponents/CategoryList";
import { useLocation } from "react-router-dom";

//The transform function takes an item and an index and transforms the data into a new object with specific properties,
// which is typically used for data mapping and processing.

const transform = (item, index) => ({
  id: item.id || index,
  title: item.title,
  primary_category: item.primary_category,
  secondary_category: item.secondary_category,
  image: item.image,
  link: item.link,
  link_type: item.link_type,
  badge: item.badge,
  badge_behaviour: item.badge_behaviour,
  badge_template: item.badge_template,
  start_date: item.start_date,
  end_date: item.end_date,
  reading_time: item.reading_time,
  brand_name: item.brand_name,
  category: item.categories || item.category
});
export const VewAllBrandsContainer = ({
  widget,
  loadMore,
  activeButton,
  setActiveButton,
  configuration_offers,
  configuration_events
}) => {
  const {
    color_theme,
    data_source,
    title,
    show_brand_title,
    ids,
    cta_button,
    items = []
  } = widget;
  const { pathname } = useLocation();
  const idArray = ids;
  //The mappedItems variable is generated using the useMemo hook, which processes a list of items, assigns IDs,
  // and applies a transformation function to create a new list of transformed items,
  // optimizing performance by recalculating only when the items array changes.

  const mappedItems = useMemo(
    () =>
      items
        .map((item, index) => ({ ...item, id: idArray?.[index] }))
        .map(transform),
    [idArray, items]
  );
  const { link_type } = cta_button || {};
   //The ViewAllResultLink function, implemented with the useCallback hook, determines a link based on the current pathname and the cta_button.link value,
  // ensuring it's recalculated only when these values change.

  const ViewAllResultLink = useCallback(() => {
    return cta_button.link;
  }, [pathname, cta_button.link]);

  //The ViewAllResultLinkType function is implemented using the useCallback hook. It determines the link type based on the current pathname,
  //link_type, and cta_button.link values. If the pathname includes "events-and-offers" and the link_type is "MODULE," it checks the cta_button.
   //If the pathname does not include "events-and-offers" or the link_type is not "MODULE," it returns the link_type. This function is designed to be efficient and
  //is only recalculated when the pathname, link_type, or cta_button.link changes.
   const ViewAllResultLinkType = useCallback(() => {
 
    return link_type;
  }, [pathname, link_type, cta_button.link]);
  return (
    <>
      <CategoryList
        home={true}
        mt={true}
        items={mappedItems}
        title={title}
        ButtonText={cta_button.title}
        pdngtop="0px !important"
        pathto={ViewAllResultLink()}
        color_theme={color_theme}
        link_type={ViewAllResultLinkType()}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        configuration_offers={configuration_offers}
        configuration_events={configuration_events}
        data_source={data_source}
        show_brand_title={show_brand_title}
      />
    </>
  );
};
