import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import HistoryButton from "Components/HistoryButton";
import WpRightLogin from "Components/WpRightLogin";
import { clearRouteCntrl, setRouteCntrl } from "store/authanticateSlice";
import { useSelectAccountCustomers } from "hooks/useSelectAccountCustomers";
import { useUserStore } from "hooks/useUser";
import { RETURN_URL } from "../../routes/RouteListener";
import {
  usePostSelectAccountOtp,
  usePostSelectAccountValidate
} from "services/account/account.query";
import { saveToken } from "utils/authStorage";
import { numberOrEmpty } from "utils/stringUtil";
import Timer from "../Account/components/timer/Timer";
import { setBearerToken } from "services/apiClient";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";

// maskEmail(email): This function takes an email address, masks it by replacing characters before the "@" symbol with asterisks, and returns the masked email. It also reverses the order of masking.
//maskEmailReverse Function: Similar to the maskEmail function, this function is used to mask an email address. However, in this case, it places asterisks () in the positions of the characters preceding the "@" symbol. For example, the email address "example@example.com" would be reversed masked as "e******@example.com."
//findIndexByCustomerID Function: This function is used to locate a specific customer within a JSON data array based on their customer ID. If the customer is found, the function returns the index of that customer within the array. If the customer is not found, it returns -1.

const maskEmail = (email) => {
  if (!email) {
    return "";
  }
  const atIndex = email.indexOf("@");
  const firstChar = email.charAt(0);
  const maskedPart = "*".repeat(atIndex - 1);
  const domain = email.slice(atIndex);
  const Email = firstChar + maskedPart + domain;
  return Email.split("").join("");
};

const maskEmailReverse = (email) => {
  if (!email) {
    return "";
  }
  const atIndex = email.indexOf("@");
  const firstChar = email.charAt(0);
  const maskedPart = "*".repeat(atIndex - 1);
  const domain = email.slice(atIndex);
  const reversedEmail = firstChar + maskedPart + domain;
  return reversedEmail.split("").join("");
};

function findIndexByCustomerID(customerID, json_data) {
  const index = json_data?.findIndex(
    (customer) => customer.CustomerID === customerID
  );
  return index;
}

const LoginEmail = ({ selectedLoginAccount, setSelectedLoginAccount }) => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const path = useSelector((state) => state.path);
  const [validationKey, setValidationKey] = useState();
  const [showComponent, setShowComponent] = useState(false);
  const locationState = useLocation().state;
  const { setUser } = useUserStore();
  const { customers = [] } = useSelectAccountCustomers();
  const customerIDToSearch = selectedLoginAccount?.customerId;
  const index = findIndexByCustomerID(customerIDToSearch, customers);
  const country = customers?.[index]?.PersonalInformation?.Country;
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);
  const dispatch = useDispatch();
  const personalTrue = routeCntrl.some((item) => item.multipleOTP === true);
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};

  const {
    mutate: selectAccountValidate,
    error,
    isError,
    isLoading: isLoadingSelectAccountValidate,
    reset: resetErrorAccountValidate
  } = usePostSelectAccountValidate({
    onSuccess: ({ token }) => {
      if (country.length) {
        setUser(token);
        navigate(localStorage.getItem(RETURN_URL) || "/");
        dispatch(clearRouteCntrl());
      } else {
        saveToken(token);
        setBearerToken(token);
        setSelectedLoginAccount();
        navigate("/sign-in/country-selection");
        const newRoute = { selectCountry: true };
        dispatch(clearRouteCntrl());
        dispatch(setRouteCntrl(newRoute));
      }
    },
    onError: (error) => {
      if (error?.response?.status === 429) {
        setTimeout(() => {
          navigate("/sign-in/email-selection");
        }, 1500);
      }
    }
  });

  useEffect(() => {
    if (locationState?.validation_key) {
      setValidationKey(locationState.validation_key);
    }
  }, [locationState]);

  const {
    mutateAsync: selectAccountOtp,
    error: selectAccountOtpError,
    reset: resetSelectAccountOtpError,
    isLoading: isLoadingSelectAccountOTP
  } = usePostSelectAccountOtp({
    onSuccess: ({ validation_key } = {}) => {},
    onError: () => {}
  });

  useEffect(() => {
    if (selectAccountOtpError) {
      resetErrorAccountValidate();
    }
    return () => {};
  }, [selectAccountOtpError]);

  const validationSchema = yup.object({
    code: yup
      .string()
      .required(t("Your verification code should be 6 digits"))
      .test("len", t("Your verification code should be 6 digits"), (val) => {
        return val?.length === 6;
      })
  });

  const formikForm = useFormik({
    initialValues: {
      code: process.env.REACT_APP_MOCK_LOGIN_FORM ? "123456" : ""
    },
    validationSchema,
    onSubmit: ({ code }) => {
      selectAccountValidate({
        validation_key: validationKey,
        validation_code: code
      });
    }
  });

  useEffect(() => {
    navigateProtect();
  }, []);

  const navigateProtect = () => {
    if (!personalTrue) {
      navigate(`/sign-in/email-selection`);
    } else setShowComponent(true);
  };

  // resendCode:  It is an asynchronous function indicated by the async keyword, which means it can perform asynchronous operations.
  // Inside the function, it sends a request, possibly to a server, by calling selectAccountOtp with specific parameters:
  // customer_id: This parameter is set to the customerId of the selectedLoginAccount.
  // email: This parameter is set to the email of the selectedLoginAccount.
  // If the request is successful and returns a response, it sets the validationKey with the response.validation_key. This key is used for validating the OTP.
  // It also calls the resetErrorAccountValidate() function, which appears to be resetting or clearing any previous errors related to account validation.
  // Finally, the function returns true if the request was successful and false if there was an error during the request.

  const resendCode = async () => {
    try {
      const response = await selectAccountOtp({
        customer_id: selectedLoginAccount.customerId,
        email: selectedLoginAccount.email
      });

      setValidationKey(response.validation_key);
      resetErrorAccountValidate();
      return true;
    } catch (error) {
      return false;
    }
  };

  const formikErrorMessage = formikForm.touched.code && formikForm.errors.code;
  const errorMessage =
    formikErrorMessage ||
    (error || selectAccountOtpError)?.response?.data?.message;
    const error_code =
    formikErrorMessage ||
    (error || selectAccountOtpError)?.response?.data?.error_code;
  const isLoading = isLoadingSelectAccountOTP || isLoadingSelectAccountValidate;

   return (
    <>
      {showComponent ? (
        <div className="WPlogin" style={{ padding: "0px", height: "100vh" }}>
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              justifyContent: "flex-start",
              backgroundColor: background || "#FFFFFF"
            }}
          >
            <HistoryButton
              image="return"
              Left={i18n.language === "arabic" ? "auto" : 25}
              Top={25}
              Right={i18n.language === "arabic" ? 25 : "auto"}
              path={`/sign-in/email-selection`}
            />
            <HistoryButton
              Right={i18n.language === "arabic" ? "auto" : 25}
              Top={25}
              Left={i18n.language === "arabic" ? 25 : "auto"}
              path={path}
            />
            <div className="left-page-form">
              <form
                style={{ height: "100%" }}
                onSubmit={formikForm.handleSubmit}
                noValidate
              >
                <div>
                  <div
                    className={
                      i18n.language === "arabic"
                        ? "login-head-pn-arabic"
                        : "login-head-pn"
                    }
                  >
                    <span>{t("Verify you own this email")}</span>
                  </div>
                  <div
                    className={
                      i18n.language === "arabic"
                        ? "subtext-multiple-arabic"
                        : "subtext-multiple"
                    }
                    style={{ lineHeight: "25px" }}
                  >
                    <div>
                      {t(
                        "An account with this email exists. For security purposes a code has been sent to "
                      )}
                      {(i18n.language === "arabic"
                        ? maskEmailReverse
                        : maskEmail)(selectedLoginAccount?.email)}
                    </div>
                  </div>

                  <div>
                    {errorMessage && <AlertWarning text={error_code===6900 ? t("expiredOTP"): t(errorMessage)} />}

                    <div
                      style={{ marginBottom: "8px" }}
                      className={
                        i18n.language === "arabic"
                          ? "phone-text-arabic"
                          : "phone-text"
                      }
                    >
                      {t("Verification code")}
                    </div>
                    <Grid
                      container
                      spacing={0}
                      className="textfont"
                      marginBottom={{ md: "24px", xs: "20px" }}
                    >
                      <Grid item xs={12}>
                        <TextField
                          name="code"
                          onChange={(e) => {
                            if (numberOrEmpty(e.target.value)) {
                              formikForm.handleChange(e);
                              if (isError || selectAccountOtpError) {
                                resetErrorAccountValidate();
                                resetSelectAccountOtpError();
                              }
                            }
                          }}
                          autoComplete="off"
                          value={formikForm.values.code}
                          sx={{ width: "100% !important" }}
                          placeholder={t("Enter your code")}
                          inputProps={{
                            style: {
                              fontSize: 16,
                              padding: "10px 12px",
                              lineHeight: "130%",
                              height: "21px",
                              fontFamily:
                                i18n.language === "arabic"
                                  ? "TajawalRegular"
                                  : "MontserratRegular"
                            },
                            maxLength: 6,
                            autoCapitalize: "none"
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div
                          className={
                            i18n.language === "arabic"
                              ? "otp-time-text-arabic"
                              : "otp-time-text"
                          }
                        >
                          {t(
                            "Didn’t get the email? Check your junk folder or resend it."
                          )}
                          <Timer
                            onReset={async () => {
                              return await resendCode();
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="btnbox-login-email">
                  {isLoading ? (
                    <ColorLoadingButton
                      size="small"
                      loading={true}
                      variant="outlined"
                      disabled
                      type="submit"
                      className="button-submit-loading"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        maxWidth: "100%",
                        marginTop: "24px"
                      }}
                    />
                  ) : (
                    <ColorButton
                      variant="contained"
                      type="submit"
                      className="button-submit-pn"
                      style={{
                        marginTop: "24px",
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium"
                      }}
                    >
                      {t("Continue")}
                    </ColorButton>
                  )}
                </div>
              </form>
            </div>
          </div>
          <WpRightLogin />
        </div>
      ) : null}
    </>
  );
};

export default LoginEmail;
