import { useState, useEffect, memo } from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { ReactComponent as ClockIcon } from "Assets/images/clock.svg";
import { ReactComponent as LocationIcon } from "Assets/images/location.svg";
import PrimaryButton from "Pages/Account/components/PrimaryButton";
import BrowseButton from "Pages/common/Promotions/BrowseButton";
import { useIsMobileView } from "hooks";
import {
  CategoriesRule,
  DisplayOptionFunc,
  LengthControl
} from "GlobalProcess/GlobalFunction";
import LinkType from "Components/LinkType";
import { LinkTypeEnums } from "utils/enums";
import PromotionCodeButton from "./PromotionCodeButton";
import ScoopedCard from "./ScoopedCard";
import LazyImage from "Components/LazyLoad/LazyImage";

const InfCard = memo(({
  eventFromTitle,
  brand,
  header_text,
  dateText,
  eventlocation,
  image,
  imageStyle = {},
  buttonStyle = {},
  onClickButton,
  hasFooter = true,
  socialMediaHandles,
  brand_logo,
  primary_category,
  secondary_category,
  ui_properties,
  actionButtonsMapped,
  title,
  open,
  setOpen,
  contentDescriptionMappedTermCondition
}) => {
  const { t, i18n } = useTranslation();
  const isMobileView = useIsMobileView();
  const { pathname } = useLocation();
  const { terms_conditions_content, cta } =
    contentDescriptionMappedTermCondition || {};
  const contentDescription =
    contentDescriptionMappedTermCondition?.description || {};
  const [truntacated, SetTruntacated] = useState(true);

  //ButtonSelectColor(item): This function extracts the color_definition property from the color_theme object of the given item and returns its value.
  //handleTruntcated(): It sets the state variable setTruncated to false, indicating that the content is no longer truncated.

  const ButtonSelectColor = (item) => {
    const { color_definition } = item?.color_theme;
    return color_definition;
  };

  const handleTruntcated = () => {
    SetTruntacated(false);
  };
  const {
    description_color,
    header_text_color,
    web_ticket_background,
    background
  } = ui_properties || {};

  const { link, link_type, description } = actionButtonsMapped?.[0] || {};
  const [backgroundColor, setBackgroundColor] = useState("transparent");

  useEffect(() => {
    if (web_ticket_background) {
      setBackgroundColor(web_ticket_background);
    } else
      setTimeout(() => {
        setBackgroundColor("#E7F0DF");
      }, 500);
  }, [web_ticket_background]);

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 0, sm: 0, md: 0 }}
      sx={{
        paddingLeft: { xs: "20px", md: "11.11%" },
        paddingRight: { xs: "20px", md: "11.11%" },
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexDirection: "row",
        paddingTop: { xs: "83px", md: "128px" },
        paddingBottom: { xs: "20px", md: "35px", lg: "48px" },
        direction: i18n.language === "arabic" ? "rtl" : "ltr",
        backgroundColor: background || ""
      }}
    >
      <Grid>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {brand_logo && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor:background || ""
              }}
            >
              <LazyImage
                placeholderSrc={
                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                }
                lazyclass="lazy"
                src={brand_logo}
                style={{
                  maxWidth: "157px",
                  height: "auto",
                  backgroundColor:background || ""

                }}
                alt="BrandsIcon"
              />
            </Box>
          )}
          {brand && (
            <Box
              sx={{
                maxWidth: "690px",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                width: { xs: "100%", md: "100%" },
                alignItems: "center",
                height: { xs: "93px", sm: "115px" },
                marginTop: "7px"
              }}
            >
              <div
                className={
                  i18n.language === "arabic"
                    ? "barnd-head-details-arabic"
                    : "barnd-head-details"
                }
              >
                {brand}
              </div>
              <div
                className={
                  i18n.language === "arabic"
                    ? "brand-head-tiny-text-arabic"
                    : "brand-head-tiny-text"
                }
                style={{
                  textAlign: i18n.language === "arabic" ? "right" : "left"
                }}
              >
                {CategoriesRule(primary_category, secondary_category)}
              </div>
            </Box>
          )}
        </Box>
      </Grid>
      <ScoopedCard background={background}>
        {backgroundColor && (
          <Box
            className="scooped-card"
            data-lang={i18n.language}
            style={{
              backgroundColor: backgroundColor
            }}
          >
            <div className="content-container">
              <Grid maxWidth={image ? "416px" : "100%"}>
                {brand && eventFromTitle && (
                  <p className="from-text">{t(eventFromTitle)}</p>
                )}
                {brand && (
                  <p className="from-value" data-lang={i18n.language}>
                    {brand}
                  </p>
                )}
                {title && (
                  <div
                    style={{
                      marginBottom: "24px"
                    }}
                  >
                    <p
                      className="title"
                      style={{ color: ui_properties?.title || "" }}
                    >
                      {title}
                    </p>
                  </div>
                )}
                {header_text && (
                  <div
                    style={{
                      marginBottom: "32px"
                    }}
                  >
                    <p
                      className="title"
                      style={{ color: header_text_color || "" }}
                    >
                      {header_text}
                    </p>
                  </div>
                )}
                {
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: { xs: "column", md: "row" }
                    }}
                  >
                    {" "}
                    {actionButtonsMapped?.map((item, index) =>
                      LinkTypeEnums.MODULE === link_type &&
                      link === "REVEAL_OFFER_CODE" ? (
                        <PromotionCodeButton
                          ButtonSelectColor={ButtonSelectColor}
                          description={description}
                          item={item}
                          open={open}
                          setOpen={setOpen}
                          buttonStyle={buttonStyle}
                        />
                      ) : (
                        <LinkType
                          key={index}
                          link_type={link_type}
                          link={link}
                          description={description}
                        >
                          <PrimaryButton
                            onClick={onClickButton}
                            style={{
                              maxWidth: isMobileView ? "auto" : "160px",
                              gap: "10px",
                              fontSize:
                                i18n.language === "arabic" ? "15px" : "12px",
                              ...buttonStyle,
                              backgroundColor: ButtonSelectColor(item)
                                ? ButtonSelectColor(item)?.cta_button_fill
                                : "transparent",
                              color: ButtonSelectColor(item)?.cta_button_text
                                ? ButtonSelectColor(item)?.cta_button_text
                                : "",
                              border: `1px solid ${
                                ButtonSelectColor(item)?.cta_button_border
                                  ? ButtonSelectColor(item)?.cta_button_border
                                  : ""
                              }`,
                              marginTop: "0px",
                              marginBottom: "8px",
                              display: DisplayOptionFunc(
                                item?.display_option,
                                pathname?.includes("offer")
                              )
                                ? "block"
                                : "none"
                            }}
                            text={item?.label}
                          ></PrimaryButton>
                        </LinkType>
                      )
                    )}
                  </Box>
                }
                <Box sx={{ marginTop: "12px" }}>
                  {" "}
                  {dateText && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                        gap: "8px"
                      }}
                    >
                      <div className="box-16">
                        <ClockIcon width={16} height={16} stroke="black" />
                      </div>
                      <p
                        className="date"
                        style={{ color: description_color || "" }}
                      >
                        {dateText}
                      </p>
                    </div>
                  )}
                  {eventlocation?.label && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "16px",
                        gap: "8px"
                      }}
                    >
                      <div className="box-16">
                        <LocationIcon width={16} height={16} stroke="black" />
                      </div>
                      <p
                        className="location"
                        style={{ color: description_color || "" }}
                      >
                        {eventlocation?.label}
                      </p>
                    </div>
                  )}
                  {terms_conditions_content && (
                    <>
                      <p className="terms-conditions-text">
                        {truntacated
                          ? LengthControl(contentDescription, 100)
                          : contentDescription}
                      </p>

                      {truntacated && contentDescription?.length > 100 && (
                        <div
                          onClick={handleTruntcated}
                          className="terms-conditions-text terms-pointer"
                        >
                          {cta}
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
              {image && (
                <Grid display={"flex"} alignItems={"center"}>
                  <LazyImage
                    placeholderSrc={
                      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    }
                    lazyclass="lazy"
                    src={image}
                    alt="event-logo"
                    style={{ width: "200px", height: "auto", ...imageStyle }}
                  />
                </Grid>
              )}
            </div>
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"flex-start"}
              width={"100%"}
              sx={{
                gap: "10px",
                flexWrap: "wrap",
                marginTop: "16px"
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                {actionButtonsMapped?.map((item, index) => (
                  <LinkType key={index} link_type={link_type} link={link}>
                    <BrowseButton
                      onClick={onClickButton}
                      style={{
                        minWidth: isMobileView ? "auto" : "160px",
                        ...buttonStyle,
                        backgroundColor: ButtonSelectColor(item)
                          ? ButtonSelectColor(item)?.cta_button_fill
                          : "transparent",
                        color: ButtonSelectColor(item)?.cta_button_text
                          ? ButtonSelectColor(item)?.cta_button_text
                          : "",
                        border: `1px solid ${
                          ButtonSelectColor(item)?.cta_button_border
                            ? ButtonSelectColor(item)?.cta_button_border
                            : ""
                        }`,
                        display: DisplayOptionFunc(
                          item?.display_option,
                          pathname?.includes("event")
                        )
                          ? "block"
                          : "none"
                      }}
                    >
                      {item?.label}
                    </BrowseButton>
                  </LinkType>
                ))}
              </Box>
              <Box>
                {hasFooter && (
                  <Box
                    sx={{
                      display: "flex",
                      marginLeft: isMobileView ? "0" : "10px",
                      marginTop: { md: "0px" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "16px"
                    }}
                  >
                    {socialMediaHandles?.items?.map(
                      (item, index) =>
                        item?.link && (
                          <a key={index} href={item?.link}>
                            <LazyImage
                              placeholderSrc={
                                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                              }
                              lazyclass="lazy"
                              src={item?.icon}
                              alt=""
                              style={{
                                background: "transparent",
                                alignContent:
                                  i18n.language === "arabic" ? "start" : "end"
                              }}
                              width="32px"
                              height="32px"
                            />
                          </a>
                        )
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </ScoopedCard>
    </Grid>
  );
});

export default InfCard;
