import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const registrationBases = [
  "login",
  "logwithAmber",
  "signup",
  "error401",
  "error404",
  "error500"
];

export const RETURN_URL = "return-url";

const RouteListener = () => {
  const location = useLocation();

  useEffect(() => {
    if (!registrationBases.some((item) => location.pathname.includes(item))) {
      localStorage.setItem(RETURN_URL, location.pathname);
    }
  }, [location]);

  return null;
};

export default RouteListener;
