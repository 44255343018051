import { configureStore } from "@reduxjs/toolkit";
import lanuageSlice from "./languageSlice";
import pathSlice from "./pathSlice";
import allianceSlice from "./allianceSlice";
import authanticateSlice from "./authanticateSlice";
import publicSlice from "./publicSlice";
import pathPreviousSlice from "./pathPreviousSlice";
import linkSlice from "./linkSlice";
export const store = configureStore({
  reducer: {
    language: lanuageSlice,
    path: pathSlice,
    alliance: allianceSlice,
    authanticate: authanticateSlice,
    public: publicSlice,
    pathPrevious: pathPreviousSlice,
    linkName: linkSlice
  }
});
