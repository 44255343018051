import { Box, Grid, TextField, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import HistoryButton from "Components/HistoryButton";
import { clearRouteCntrl, setRouteCntrl } from "store/authanticateSlice";
import { useSelectAccountCustomers } from "hooks/useSelectAccountCustomers";
import { useUserStore } from "hooks/useUser";
import { RETURN_URL } from "../../routes/RouteListener";
import { setBearerToken } from "services/apiClient";
import {
  usePostPhoneEntry,
  usePostPhoneValidate
} from "services/auth/auth.query";
import { saveToken } from "utils/authStorage";
import { numberOrEmpty } from "utils/stringUtil";
import Timer from "../Account/components/timer/Timer";
import { SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS } from "../Account/constants";
import { setSelectedCountryCodeToStorage } from "utils/setSelectedCountryCodeToStorage";
import { removeSelectedCountryFromStorage } from "utils/removeSelectedCountryFromStorage";
import { useDataLayer } from "hooks/useDataLayer";
import Group from "Assets/images/Group.png";
import logoUnion from "Assets/images/Unionlwa.png";
import logoUnionBig from "Assets/images/UnionBig.png";
import ReturnImage from "Assets/images/Return.svg";
import ReturnImageArabic from "Assets/images/ReturnArabic.svg";
const styleArabic = {
  fontSize: 21,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular"
};
const styleEnglish = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  textAlign: "right"
};
const styleEnglishLength = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr"
};

const LoginOTP = ({ SetOTPCode }) => {
  const {
    state: { loginKey }
  } = useLocation();

  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const path = useSelector((state) => state.path);
  const mobileSize = useMediaQuery("(max-width:900px)");
  const formStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent:(!loginKey && mobileSize )? "space-between" : "flex-start",
    height: "100%"
  };
  const phonenumber = useSelector((state) => state.authanticate.phoneNumber);
  const phonecode = useSelector((state) => state.authanticate.phoneCode);
  const iso_code = useSelector((state) => state.authanticate.isoCode);
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);
  const personalTrue = routeCntrl.some((item) => item.phone === true);
  const [showComponent, setShowComponent] = useState(false);
  const locationState = useLocation().state;
  const { setUser, setTier } = useUserStore();
  const { setCustomers } = useSelectAccountCustomers();
  const [validationKey, setValidationKey] = useState();
  const { setSignInEvent } = useDataLayer();
  const {
    mutate: validatePhone,
    error: validatePhoneError,
    isError: isValidatePhoneError,
    reset: resetValidatePhoneError,
    isLoading: isLoadingValidatePhone
  } = usePostPhoneValidate({
    onSuccess: (data, { validation_code }) => {
      setSignInEvent("enter_phone_success");
      if (loginKey && data?.token && data?.callback_url) {
        if (data) {
          const hasCountry =
            data?.customers?.[0]?.PersonalInformation?.Country?.length;
          const hasOneCustomer = data?.customers?.length === 1;
          //setting tier after successful login
          const tier = data?.customers?.[0]?.LoyaltyInformaton?.Card?.Tier;
          if (tier) {
            setTier(tier); 
          }
          setCustomers(data.customers);
          saveToken(data.token);
          if (hasOneCustomer && hasCountry) {
            setSelectedCountryCodeToStorage(
              data?.customers?.[0]?.PersonalInformation?.Country
            );
            removeSelectedCountryFromStorage();
          }
          setBearerToken(data.token);
        }
        window.location.replace(`${data?.callback_url}${data?.token}`);
      }
      if (!loginKey && data?.token && !data?.callback_url) {
        if (data) {
          const hasCountry =
            data?.customers?.[0]?.PersonalInformation?.Country?.length;
          const hasOneCustomer = data?.customers?.length === 1;
          //setting tier after successful login
          const tier = data?.customers?.[0]?.LoyaltyInformaton?.Card?.Tier;
          if (tier) {
            setTier(tier); 
          }
          setCustomers(data.customers);
          saveToken(data.token);
          if (hasOneCustomer && hasCountry) {
            setSelectedCountryCodeToStorage(
              data?.customers?.[0]?.PersonalInformation?.Country
            );
            removeSelectedCountryFromStorage();
          }
          setBearerToken(data.token);
          SetOTPCode(validation_code);
          if (hasOneCustomer) {
            if (!hasCountry) {
              saveToken(data.token);
              navigate("/sign-in/country-selection");
              const newRoute = { otp: true };
              dispatch(clearRouteCntrl());
              dispatch(setRouteCntrl(newRoute));
            } else {
              setUser(data.token);
              navigate(localStorage.getItem(RETURN_URL) || `/`);
              dispatch(clearRouteCntrl());
            }
          } else {
            navigate("/sign-in/email-selection");
            const newRoute = { otp: true };
            dispatch(clearRouteCntrl());
            dispatch(setRouteCntrl(newRoute));
          }
        }
      }

      setSignInEvent("complete");
    },
    onError: (error) => {
      if (error?.response?.status === 429) {
        setTimeout(() => {
          navigate("/sign-in");
        }, 1500);
      }
      if (error?.response?.status === 500) {
        setTimeout(() => {
          navigate("/sign-in");
        }, 1500);
      }
    }
  });

  const {
    mutateAsync: phoneEntry,
    isLoading: isLoadingPhoneEntry,
    error: isPhoneEntryError,
    reset: resetPhoneEntryError
  } = usePostPhoneEntry({
    onSuccess: () => {},
    onError: () => {}
  });
  
  // Determine if the validation conditions are for 'ounass'
  const isOunassKey = loginKey && loginKey.includes('ounass');
  // Set the expected length and required message based on the condition
  const expectedLength = isOunassKey ? 5 : 6;
  const requiredMessage = t(`Your verification code should be ${expectedLength} digits`);

  const validationSchema = yup.object({
    code: yup
      .string()
      .required(requiredMessage)
      .test(
        'len', 
        requiredMessage,
        val => val && val.length === expectedLength
      )
  });

  const formikForm = useFormik({
    initialValues: {
      code: process.env.REACT_APP_MOCK_LOGIN_FORM ? "123456" : ""
    },
    validationSchema,
    onSubmit: ({ code }) => {
      validatePhone({
        validation_key: validationKey,
        validation_code: code,
        loginKey
      });
    }
  });

  useEffect(() => {
    navigateProtect();
  }, []);

  useEffect(() => {
    resetValidatePhoneError();
  }, [isPhoneEntryError]);

  const code = formikForm.values.code;

  useEffect(() => {
    if (locationState?.validation_key) {
      setValidationKey(locationState.validation_key);
    }
  }, [locationState]);

  // navigateProtect is a function that is used to handle navigation based on the value of personalTrue. If personalTrue is falsy (e.g., false), it navigates the user to the sign-in page (assuming there is a navigation system in place). Otherwise, it sets the showComponent state to true.
  // getStyle is a function that determines the style based on the i18n language and the values of code. If the language is "arabic" and code has a length greater than 0, it returns styleEnglish. Otherwise, it returns styleArabic. If the language is not "arabic," it returns styleEnglishLength.
  // resendCode is an asynchronous function that sends a request, possibly to a server, with specific parameters:
  // It constructs the parsedPhone by removing dashes and spaces from the concatenation of phonecode and phonenumber.
  // It then makes an asynchronous request to the phoneEntry function with parameters phone (the parsed phone number) and iso_code.

  const navigateProtect = () => {
    if (!personalTrue) {
      navigate(`/sign-in`);
    } else setShowComponent(true);
  };

  const getStyle = () => {
    if (i18n.language === "arabic") {
      if (code.length > 0) {
        return styleEnglish;
      } else return styleArabic;
    } else {
      return styleEnglishLength;
    }
  };

  const resendCode = async () => {
    const parsedPhone = (phonecode + phonenumber)
      .replace(/-/g, "")
      .replace(/ /g, "");
    try {
      const response = await phoneEntry({ phone: parsedPhone, iso_code });

      setValidationKey(response.validation_key);
      isValidatePhoneError && resetValidatePhoneError();

      return true;
    } catch (error) {
      return false;
    }
  };

  const formikErrorMessage = formikForm.touched.code && formikForm.errors.code;
  const errorMessage =
    formikErrorMessage ||
    (validatePhoneError || isPhoneEntryError)?.response?.data?.message;

  const isLoading = isLoadingValidatePhone || isLoadingPhoneEntry;
  const hasServerError = isPhoneEntryError || isValidatePhoneError;

  return (
    <>
      {showComponent ? (
        <div className={loginKey ? "WPlogin log-with-amber" : "WPlogin"}>
          <Box
            className={
              loginKey
                ? "WPleftpage login-page-main log-with-amber-main"
                : "WPleftpage login-page-main"
            }
            data-path={"loginPhone"}
            sx={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
          >
            {loginKey ? (
              <>
                <IconButton
                  className=""
                  onClick={() => navigate(`/`)}
                  sx={{
                    width: 40,
                    height: 40,
                    right:
                      i18n.language === "arabic"
                        ? "auto"
                        : { xs: "20px", md: "30px" },
                    left:
                      i18n.language === "arabic"
                        ? { xs: "20px", md: "30px" }
                        : "auto",
                    position: "absolute !important",
                    top: { xs: "20px", md: "30px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "14px",
                        md: "28px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }
                    }}
                  >
                    {" "}
                    <img src={Group} alt="Group" style={{ width: "100%" }} />
                  </Box>
                </IconButton>
                <IconButton
                  className=""
                  onClick={() => navigate(`/sign-in/`+loginKey)}
                  sx={{
                    height: "auto",
                    left:
                      i18n.language === "arabic"
                        ? "auto"
                        : { xs: "20px", md: "30px" },
                    right:
                      i18n.language === "arabic"
                        ? { xs: "20px", md: "30px" }
                        : "auto",
                    position: "absolute !important",
                    top: { xs: "20px", md: "30px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "16px",
                        md: "28px"
                      },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {i18n.language === "arabic" ? (
                      <img
                        src={ReturnImageArabic}
                        alt="Group"
                        style={{ width: "100%", height: "auto" }}
                      />
                    ) : (
                      <img
                        src={ReturnImage}
                        alt="Group"
                        style={{ width: "100%", height: "auto" }}
                      />
                    )}
                  </Box>
                </IconButton>
              </>
            ) : (
              <>
                <HistoryButton
                  image="return"
                  Left={i18n.language === "arabic" ? "auto" : 25}
                  Top={25}
                  Right={i18n.language === "arabic" ? 25 : "auto"}
                  path={`/sign-in`}
                />
                <HistoryButton
                  Right={i18n.language === "arabic" ? "auto" : 25}
                  Top={25}
                  Left={i18n.language === "arabic" ? 25 : "auto"}
                  path={path}
                />
              </>
            )}
            {loginKey && (
              <>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <div
                    className="left-page-logo"
                    style={{ top: "3.7%", marginTop: "80px" }}
                  >
                    <img
                      src={logoUnionBig}
                      alt="logo"
                      style={{ marginBottom: "45px" }}
                    />
                  </div>
                </Box>
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  <div
                    className="left-page-logo"
                    style={{ top: "3.7%", marginTop: "80px" }}
                  >
                    <img
                      src={logoUnion}
                      alt="logo"
                      style={{ marginBottom: "45px" }}
                    />
                  </div>
                </Box>
              </>
            )}
            <div
              className={
                loginKey
                  ? " left-page-form-pn loginLP log-with-amber-loginlp2"
                  : "left-page-form-pn loginLP"
              }
            >
              <form
                style={formStyle}
                onSubmit={formikForm.handleSubmit}
                noValidate
              >
                <div>
                  <div
                    className={
                      i18n.language === "arabic"
                        ? "login-head-pn-arabic"
                        : "login-head-pn"
                    }
                  >
                    <span>
                      {loginKey
                        ? t("Confirm your phone number")
                        : t("Enter the code")}
                    </span>
                  </div>
                  {i18n.language === "arabic" ? (
                    <div
                      className={
                        i18n.language === "arabic"
                          ? "subtext-arabic"
                          : "subtext-lg-otp"
                      }
                      style={{ fontSize: "14px" }}
                    >
                      {t("We sent a code to")}
                      <div style={{ display: "inline-block",direction:"ltr" }}>
                        {phonecode + " " + phonenumber}
                      </div>{" "}
                      &nbsp;
                      <>
                        {loginKey ? (
                          <>
                            <br />
                            <Link
                              className="phone-text"
                              to={`/sign-in/`+loginKey}
                              style={{
                                fontSize: "14px",
                                fontFamily:
                                  i18n.language === "arabic"
                                    ? "TajawalMedium"
                                    : "MontserratMedium"
                              }}
                            >
                              {t("Change phone number")}
                            </Link>
                          </>
                        ) : (
                          <Link to="/sign-in">{t("Change")}</Link>
                        )}{" "}
                      </>
                    </div>
                  ) : (
                    <div
                      className={
                        i18n.language === "arabic"
                          ? "subtext-arabic"
                          : "subtext-lg-otp"
                      }
                      style={{ fontSize: "14px" }}
                    >
                      {t("We sent a code to")} {phonecode}{" "}
                     <span style={{direction:"ltr"}}> {phonenumber.replace(/-/g, " ")}</span>
                      .&nbsp;
                      <>
                        {loginKey ? (
                          <>
                            <br />
                            <Link
                              className="phone-text"
                              to={`/sign-in/`+loginKey}
                              style={{
                                fontSize: "14px",
                                fontFamily:
                                  i18n.language === "arabic"
                                    ? "TajawalMedium"
                                    : "MontserratMedium"
                              }}
                            >
                              {t("Change phone number")}
                            </Link>
                          </>
                        ) : (
                          <Link to="/sign-in"> {t("Change")}</Link>
                        )}
                      </>
                    </div>
                  )}

                  <div>
                    {errorMessage ? (
                      <AlertWarning
                        marginbottom="24px"
                        margintop="-16px"
                        text={t(errorMessage)}
                      />
                    ) : null}
                    {!errorMessage && (
                      <div
                        className={
                          i18n.language === "arabic"
                            ? "phone-text-arabic"
                            : "phone-text"
                        }
                      >
                        {t("Verification code")}
                      </div>
                    )}
                    <Grid
                      container
                      className="textfont"
                      marginBottom={{ md: "24px", xs: "20px" }}
                    >
                      <Grid item xs={12}>
                        <TextField
                          name="code"
                          onChange={(e) => {
                            if (numberOrEmpty(e.target.value)) {
                              formikForm.handleChange(e);
                              if (hasServerError) {
                                resetValidatePhoneError();
                                resetPhoneEntryError();
                              }
                            }
                          }}
                          value={formikForm.values.code}
                          autoComplete="off"
                          sx={{
                            width: "100% !important",
                            marginTop: "8px",
                            input: {
                              "&::placeholder": {
                                color: "#90969E",
                                opacity: "1"
                              }
                            }
                          }}
                          placeholder={t("Enter your code")}
                          inputProps={{
                            maxLength: 6,
                            style: getStyle(),
                            inputMode: "numeric",
                            pattern: "[a-z]{1,15}"
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Timer
                          onReset={async () => {
                            return await resendCode();
                          }}
                          start={SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Box
                  className={loginKey? "button-box-lg-amber" : "button-box-lg"}
                  sx={{
                    marginTop: errorMessage ? "2px !important" : "20px"
                  }}
                >
                  {isLoading ? (
                    <ColorLoadingButton
                      size="small"
                      loading={true}
                      variant="outlined"
                      disabled
                      type="submit"
                      className="button-submit-loading"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        maxWidth: "100%",
                        width: "100%",
                        marginTop:loginKey ?"0px": "34px"
                      }}
                    />
                  ) : (
                    <ColorButton
                      variant="contained"
                      type="submit"
                      disabled={isLoading}
                      className={
                        loginKey
                          ? "button-submit-login-amber"
                          : "button-submit-login"
                      }
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                            marginTop:loginKey ?"0px": "34px",
                            display: "inline-block"
                      }}
                    >
                      {loginKey ? t("Continue") : t("Sign in")}
                    </ColorButton>
                  )}
                  {!loginKey && (
                    <p
                      className={
                        i18n.language === "arabic"
                          ? "LGmessage-arabic"
                          : "LGmessage"
                      }
                    >
                      {t("Message and data rates may apply.")}
                    </p>
                  )}
                </Box>
              </form>
            </div>
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default LoginOTP;
