import { useQuery } from "@tanstack/react-query";
import { CONTACT } from "../serviceKeys";
import * as Requests from "./contactUs.request";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetContact = () => {
  const tierValue = useTierValue();
  return useQuery([CONTACT, tierValue], () => {
    return Requests.getContract({ segment: tierValue });
  });
};
