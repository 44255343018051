import React from "react";
import { useTranslation } from "react-i18next";
import { MAIL_OTP_SECURE_CODE_DURATION_IN_SECONDS } from "../../constants";
import useTimer from "./useTimer";

const StyleTextArabic = {
  fontFamily: "TajawalRegular",
  fontSize: "16px",
  lineHeight: "18px",
  margin: 0,
  padding: 0
};
const StyleTextEnglish = {
  fontFamily: "MontserratRegular",
  fontSize: "14px",
  lineHeight: "18px",
  margin: 0,
  padding: 0
};

const Timer = ({
  onStartTimer,
  onTimeExpired,
  onReset,
  start = MAIL_OTP_SECURE_CODE_DURATION_IN_SECONDS
}) => {
  // There's a handleClickReset function that is called when a reset action is triggered. It first checks whether a callback function onReset is provided, and if it is, it calls this function. The onReset function is expected to return a boolean value to determine whether the timer should be reset or not. If the return value is true, the timer is reset using the resetTimer function.

  const { timer, resetTimer, isExpired } = useTimer({
    start,
    onStartTimer: () => {
      onStartTimer?.();
    },
    onTimeExpired: () => {
      onTimeExpired?.();
    }
  });

  const handleClickReset = async () => {
    let shouldReset = true;
    if (onReset) {
      shouldReset = await onReset();
    }
    shouldReset && resetTimer();
  };
  const { t, i18n } = useTranslation();

  return (
    <div>
      <p
        style={i18n.language === "arabic" ? StyleTextArabic : StyleTextEnglish}
      >
        {
          <span
            onClick={() => {
              isExpired && handleClickReset();
            }}
            style={{
              textDecoration: isExpired ? "underline" : "initial",
              cursor: isExpired ? "pointer" : "initial"
            }}
          >
            {t("Request another code")}
          </span>
        }
        {timer !== "0:00" ? t(" in ") + timer : null}
      </p>
    </div>
  );
};

export default Timer;
