import { Box, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import WprightPage from "Components/Authanticate/WprightPage";
import HistoryButton from "Components/HistoryButton";
import {
  clearRouteCntrl,
  setOtpCode,
  setRouteCntrl
} from "store/authanticateSlice";
import { setBearerToken } from "services/apiClient";
import {
  usePostPhoneEntry,
  usePostPhoneValidate
} from "services/auth/auth.query";
import { saveToken } from "utils/authStorage";
import { numberOrEmpty } from "utils/stringUtil";
import Timer from "Pages/Account/components/timer/Timer";
import { SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS } from "../Account/constants";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";
import { useDataLayer } from "hooks/useDataLayer";

const styleArabic = {
  fontSize: 21,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular"
};
const styleEnglish = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  textAlign: "right"
};
const styleEnglishLength = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr"
};

const OTP = () => {
  const referalCode = useLocation()?.state?.referalCode;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSignUpEvent } = useDataLayer();

  const path = useSelector((state) => state.path);
  const isExternal = useSelector((state) => state.authanticate.isExternal);
  const { phoneNumber, phoneCode, isoCode } = useSelector(
    (state) => state.authanticate
  );
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);

  const [showComponent, setShowComponent] = useState(true);
  const [responseErrorMessage, setResponseErrorMessage] = useState();

  const hasPhoneTrue = useMemo(() => {
    return routeCntrl.some((item) => item.phone === true);
  }, [routeCntrl]);

  const locationState = useLocation().state;
  const [validationKey, setValidationKey] = useState();
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};
  const {
    mutateAsync: postPhoneEntry,
    isLoading: isLoadingPhoneEntry,
    error: isPhoneEntryError
  } = usePostPhoneEntry({
    onSuccess: () => {},
    onError: () => {}
  });

  const {
    mutateAsync: phoneValidate,
    isLoading: isLoadingPhoneValidate,
    error: isPhoneValidateError,
    reset: resetPhoneValidateError
  } = usePostPhoneValidate({
    onSuccess: () => {},
    onError: (error) => {
      if (error?.response?.status === 429) {
        setTimeout(() => {
          navigate(`/sign-up/phone-validation`, { state: { referalCode } });
        }, 1500);
      }
    }
  });

  useEffect(() => {
    navigateProtect();
  }, []);

  useEffect(() => {
    let error = isPhoneValidateError;
    if (isPhoneEntryError) {
      error = isPhoneEntryError;
      resetPhoneValidateError();
    }
    setResponseErrorMessage(error?.response?.data?.message);
  }, [isPhoneEntryError, isPhoneValidateError]);

  useEffect(() => {
    if (locationState?.validation_key) {
      setValidationKey(locationState.validation_key);
    }
  }, [locationState]);

  const validationSchema = yup.object({
    code: yup
      .string()
      .required(t("Your verification code should be 6 digits"))
      .test("len", t("Your verification code should be 6 digits"), (val) => {
        return val?.length === 6;
      })
  });

  const formikForm = useFormik({
    initialValues: {
      code: ""
    },
    validationSchema,
    onSubmit: ({ code }) => {
      handleSubmit();
    }
  });

  const code = formikForm.values.code;

  // resendCode():
  // This function is responsible for resending a validation code to the user's phone number.
  // It concatenates the phoneCode and phoneNumber, removes any dashes or spaces from the resulting string, and sends an API request using the postPhoneEntry function.
  // If the request is successful, it sets the validationKey and clears any response error message.
  // If there's an error during the request, it returns false to indicate that the code resend was unsuccessful.

  // navigateProtect():
  // This function determines whether the user should navigate to the phone validation page or proceed with the sign-up process.
  // If hasPhoneTrue is false, it navigates to the phone validation page (/sign-up/phone-validation) and includes the referalCode in the route's state.
  // If hasPhoneTrue is true, it sets showComponent to true, which might indicate that the user can proceed with the sign-up process.

  // getStyle():
  // This function determines the style to be applied based on the language (i18n.language) and whether a code exists.
  // If the language is "arabic" and the code has a length greater than 0, it returns styleEnglish.
  // Otherwise, it returns styleArabic if the language is "arabic" or styleEnglishLength for other languages.

  // handleSubmit():
  // This function is used after the user has validated their phone number using the validation code.
  // It calls the phoneValidate function with the validationKey and code.
  // If the validation is successful and returns data, it sets the OTP code in the state using dispatch, saves the token, and sets the bearer token.
  // It defines a new route for the next step (OTP verification) and navigates to the email registration page (/sign-up/email-registration), potentially passing the referalCode as part of the route's state.

  const resendCode = async () => {
    const parsedPhone = (phoneCode + phoneNumber)
      .replace(/-/g, "")
      .replace(/ /g, "");

    try {
      const response = await postPhoneEntry({
        iso_code: isoCode,
        phone: parsedPhone,
        action: "register"
      });
      setValidationKey(response.validation_key);
      setResponseErrorMessage("");

      return true;
    } catch (error) {
      return false;
    }
  };

  const navigateProtect = () => {
    if (!hasPhoneTrue) {
      navigate(`/sign-up/phone-validation`, { state: { referalCode } });
    } else setShowComponent(true);
  };

  const getStyle = () => {
    if (i18n.language === "arabic") {
      if (code.length > 0) {
        return styleEnglish;
      } else return styleArabic;
    } else {
      return styleEnglishLength;
    }
  };

  const handleSubmit = () => {
    phoneValidate({
      validation_key: validationKey,
      validation_code: code
    }).then((data) => {
      dispatch(setOtpCode(code));
      const token = data.token;
      saveToken(token);
      setBearerToken(token);
      const newRoute = { otp: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));

      setSignUpEvent("enter_phone_success");
      navigate(`/sign-up/email-registration`, { state: { referalCode } });
    });
  };

  const formikErrorMessage = formikForm.touched.code && formikForm.errors.code;
  const errorMessage = formikErrorMessage || responseErrorMessage;
  const isLoading = isLoadingPhoneEntry || isLoadingPhoneValidate;

  return (
    <>
      {showComponent ? (
        <div className="WPmain">
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: background ? background : "#FFFFFF"
            }}
          >
            <HistoryButton
              image="return"
              Left={i18n.language === "arabic" ? "auto" : 25}
              Top={25}
              Right={i18n.language === "arabic" ? 25 : "auto"}
              path={`/sign-up/phone-validation`}
              referalCode={referalCode}
            />
            {!isExternal && (
              <HistoryButton
                Right={i18n.language === "arabic" ? "auto" : 25}
                Top={25}
                Left={i18n.language === "arabic" ? 25 : "auto"}
                path={path}
                referalCode={referalCode}
              />
            )}
            <div className="left-page-form">
              <form
                style={{ height: "100%" }}
                onSubmit={formikForm.handleSubmit}
                noValidate
              >
                <div className="left-page-form-subdiv">
                  <div>
                    <div
                      className={
                        i18n.language === "arabic"
                          ? "login-head-pn-arabic"
                          : "login-head-pn"
                      }
                    >
                      <span>{t("Enter the code")}</span>
                    </div>

                    {i18n.language === "arabic" ? (
                      <Box
                        className={
                          i18n.language === "arabic"
                            ? "subtext-pn-arabic"
                            : "subtext-pn"
                        }
                        sx={{
                          fontSize: "14px",
                          direction: i18n.language === "arabic" ? "ltr" : "rtl",
                          textAlign:
                            i18n.language === "arabic" ? "right" : "left"
                        }}
                      >
                        {phoneCode + " " + phoneNumber} &nbsp;
                        {t("We sent a code to")}
                        <br></br>
                        <Link
                          to={{
                            pathname: "/sign-up/phone-validation",
                            state: {
                              state: { referalCode }
                            }
                          }}
                        >
                          {t("Change phone number")}
                        </Link>
                      </Box>
                    ) : (
                      <div
                        className={
                          i18n.language === "arabic"
                            ? "subtext-pn-arabic"
                            : "subtext-pn"
                        }
                        style={{ fontSize: "14px" }}
                      >
                        {t("We sent a code to")} {phoneCode}{" "}
                        {phoneNumber.replace(/-/g, " ")}
                        .&nbsp;<br></br>
                        <Link
                          to={{
                            pathname: "/sign-up/phone-validation",
                            state: {
                              state: { referalCode }
                            }
                          }}
                        >
                          {t("Change phone number")}
                        </Link>
                      </div>
                    )}
                    <div>
                      {errorMessage ? (
                        <AlertWarning
                          marginbottom="24px"
                          margintop="-16px"
                          text={t(errorMessage)}
                        />
                      ) : null}
                      <div
                        className={
                          i18n.language === "arabic"
                            ? "phone-text-arabic"
                            : "phone-text"
                        }
                        style={{ marginBottom: "8px" }}
                      >
                        {t("Verification code")}
                      </div>
                      <Grid
                        container
                        spacing={0}
                        className="textfont"
                        marginBottom={{ md: "24px", xs: "20px" }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            name="code"
                            onChange={(e) => {
                              if (numberOrEmpty(e.target.value)) {
                                formikForm.handleChange(e);
                                responseErrorMessage &&
                                  setResponseErrorMessage("");
                              }
                            }}
                            autoComplete="off"
                            value={formikForm.values.code}
                            sx={{
                              width: "100% !important",
                              input: {
                                "&::placeholder": {
                                  color: "#90969E",
                                  opacity: "1"
                                }
                              }
                            }}
                            placeholder={t("Enter your code")}
                            inputProps={{
                              style: getStyle(),
                              maxLength: 6,
                              inputMode: "numeric",
                              pattern: "[0-9]*"
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Timer
                            onReset={async () => {
                              return await resendCode();
                            }}
                            start={SMS_OTP_SECURE_CODE_DURATION_IN_SECONDS}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <Box
                    className="btnbox-pn"
                    sx={{ marginTop: errorMessage === true ? "2px" : "36px" }}
                  >
                    {isLoading ? (
                      <ColorLoadingButton
                        size="small"
                        loading={true}
                        variant="outlined"
                        disabled
                        type="submit"
                        className="button-submit-loading"
                        style={{
                          maxWidth: "100%",
                          marginTop: "0px"
                        }}
                      />
                    ) : (
                      <ColorButton
                        isLoading={isLoading}
                        type="submit"
                        variant="contained"
                        className="button-submit-pn"
                        style={{
                          marginTop: "0px",
                          fontFamily:
                            i18n.language === "arabic"
                              ? "TajawalMedium"
                              : "MontserratMedium"
                        }}
                      >
                        {t("Continue")}
                      </ColorButton>
                    )}
                  </Box>
                </div>
              </form>
            </div>
          </div>
          <WprightPage />
        </div>
      ) : null}
    </>
  );
};

export default OTP;
