import React, { useState, useEffect, useMemo } from "react";
import logoUnion from "Assets/images/logo-dark.svg";
import { Box, Grid, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import BarcodeGenerator from "Components/BarcodeGenerator";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useGetAccount } from "services/account/account.query";

const AllianceCode = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { routeCntrl } = useSelector((state) => state.authanticate);

  const hasPhoneTrue = useMemo(() => {
    return routeCntrl.some((item) => item.personal === true);
  }, [routeCntrl]);

  const [showComponent, setShowComponent] = useState(true);
  const { data: userData, isLoading } = useGetAccount();
  const CardID = userData?.Customer?.[0]?.LoyaltyInformaton?.Card?.CardID;

  useEffect(() => {
    navigateProtect();
  }, []);

  // navigateProtect:It checks a condition, likely a boolean variable, hasPhoneTrue.
  // If hasPhoneTrue is not true (evaluates to false), it navigates to a specific route using the navigate function. The destination route is /amber-alliance/welcome.
  // If hasPhoneTrue is true (evaluates to true), it sets a component's visibility by calling setShowComponent to true.

  const navigateProtect = () => {
    if (!hasPhoneTrue) {
      navigate(`/amber-alliance/welcome`);
    } else setShowComponent(true);
  };

  useEffect(() => {
    if (CardID) {
      setTimeout(() => {
        localStorage.removeItem("private-token");
        localStorage.setItem("alliance-control", true);
      }, 1000);
    }
  }, [CardID]);

  return (
    <>
      {" "}
      {showComponent &&
        (isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: isLoading ? "100vh" : "100%"
            }}
          >
            <CircularProgress
              size={"25px"}
              style={{
                color: "black"
              }}
            />
          </Box>
        ) : (
          CardID && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              className="alliance"
            >
              <Grid
                item
                xs={12}
                sx={{ width: "100%", maxWidth: "1440px !important" }}
              >
                <Box
                  sx={{
                    padding: { xs: "0px 20px", xl: "0px 400px" },
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Box
                    sx={{
                      padding: { xs: "48px 0px", lg: "142px 90px  0px 90px" },
                      height: { xs: "100%", lg: "calc(100% - 142px)" },
                      maxWidth: "460px"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <Link to="/">
                        <img src={logoUnion} alt="logo" width={"74.89px"} />
                      </Link>
                      <Box
                        sx={{ textAlign: "center" }}
                        className={
                          i18n.language === "arabic"
                            ? "login-head-arabic"
                            : "login-head"
                        }
                      >
                        {t("Your Alliance Account Created!")}
                      </Box>
                      <BarcodeGenerator generateValue={CardID} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )
        ))}
    </>
  );
};

export default AllianceCode;
