import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU_ITEMS } from "./constants";
import CustomSelect from "./components/CustomSelect";
import { useIsMobileView } from "hooks";

const MenuItem = ({ onClick, isSelected, label, lang, props }) => {
  return (
    <div
      {...props}
      className="menu-item-text"
      data-lang={lang}
      data-is-selected={isSelected}
      style={isSelected ? { color: "black" } : {}}
    >
      <a onClick={onClick}>{label}</a>
    </div>
  );
};

export const AccountSettingsMenuDesktop = ({
  onSelectedItem,
  selectedItem
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return MENU_ITEMS.map(({ label, to, value }, index) => (
    <MenuItem
      key={index}
      label={t(label)}
      lang={i18n.language}
      isSelected={selectedItem === value}
      onClick={() => {
        navigate(to);
        onSelectedItem(value);
      }}
    />
  ));
};

export const AccountSettingsMenuMobile = ({ onSelectedItem, selectedItem }) => {
  const navigate = useNavigate();
  const handleChange = (e) => {
    onSelectedItem(e.target.value);
    const to = MENU_ITEMS.find((item) => item.value === e.target.value)?.to;
    navigate(to);
  };
  return (
    <Grid container sx={{ width: "100%", marginBottom: "36px" }}>
      <CustomSelect
        items={MENU_ITEMS}
        sx={{ maxWidth: "100%" }}
        value={selectedItem}
        handleChange={handleChange}
      />
    </Grid>
  );
};

export const AccuntSettingMenu = () => {
  const isMobileView = useIsMobileView();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    !selectedItem &&
      setSelectedItem(
        MENU_ITEMS.find(({ to }) => to === location.pathname)?.value
      );
    return () => {};
  }, [location]);

  return isMobileView ? (
    <AccountSettingsMenuMobile
      selectedItem={selectedItem}
      onSelectedItem={(item) => setSelectedItem(item)}
    />
  ) : (
    <AccountSettingsMenuDesktop
      selectedItem={selectedItem}
      onSelectedItem={(item) => setSelectedItem(item)}
    />
  );
};
