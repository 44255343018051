import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LengthControl, CategoriesRule } from "GlobalProcess/GlobalFunction";
import LinkType from "Components/LinkType";
import calculateBadgeText from "Assets/helper/badgeHelper";
import { useGetOnAppConfigContent } from "services/cms/cms.query";
import { DataSourceEnums, LinkTypeEnums } from "utils/enums";
import { useDataLayer, ITEMTYPE_MAP } from "hooks/useDataLayer";

export default function ActionAreaCard({
  card,
  color_definition,
  data_source
}) {
  const { i18n } = useTranslation();
  const { setClickEvent } = useDataLayer();
  const { data: appConfigContent = {} } = useGetOnAppConfigContent();
  const endLabels =
    appConfigContent?.items?.[0]?.fields?.configuration?.Configurations?.find(
      (item) => item?.configuration_name === "amber_badge_behaviors"
    )?.items;
  card.modifiedBadgeTemplate = calculateBadgeText(
    card.link_type,
    card.badge_behaviour,
    card.badge,
    card.badge_template,
    card.start_date,
    card.end_date,
    card.reading_time,
    endLabels
  );

  // The code snippet defines an "onDataLayerClickEvent" function. This function is used to construct and set a "clickEvent" object based on properties from a "card" object. The "clickEvent" object includes fields such as "page," "itemtype," "brand," "category," and "title," which are populated using corresponding properties from the "card" object. The purpose of this function is likely to capture and track user interactions or events when a user clicks on an element represented by the "card" object.

  const onDataLayerClickEvent = () => {
    const { link, link_type, brand_name, primary_category, title } = card;

    setClickEvent({
      page: link,
      itemtype: ITEMTYPE_MAP[link_type],
      brand: brand_name,
      category: primary_category,
      title: title
    });
  };

  return (
    <Card
      onClick={onDataLayerClickEvent}
      sx={{
        maxWidth: "100%",
        boxShadow: "none",
        textAlign: "left",
        borderRadius: 0,
        cursor: "pointer",
        width: "100%",
        backgroundColor: "transparent"
      }}
    >
      <LinkType link_type={card?.link_type} link={card?.link}>
        <Box
          sx={{
            position: "relative",
            textAlign: i18n.language === "arabic" ? "right" : "left"
          }}
        >
          <CardMedia
            component="img"
            loading="lazy"
            image={card.image}
            alt={card.text}
            height="100%"
          />
          <CardContent
            sx={{
              padding: " 16px 0px 16px 0px",
              textAlign: i18n.language === "arabic" ? "right" : "left",
              paddingBottom: "0px !important"
            }}
          >
            {(card.modifiedBadgeTemplate || card.badge) && (
              <Box
                className={
                  i18n.language === "arabic"
                    ? "swiper-badge-arabic"
                    : "swiper-badge"
                }
                style={{
                  backgroundColor: color_definition?.badge_fill
                    ? color_definition?.badge_fill
                    : "#FFFFFF",
                  color: color_definition
                    ? color_definition?.badge_text
                    : "#000000",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "NotoNaskhArabicMedium "
                      : "MontserratMedium",
                  right: i18n.language === "arabic" ? "" : "8px",
                  left: i18n.language === "arabic" ? "8px" : ""
                }}
              >
                {LengthControl(
                  card.modifiedBadgeTemplate
                    ? card.modifiedBadgeTemplate
                    : card.badge,
                  20
                )}
              </Box>
            )}
            {card?.brand_name && (
              <div
                className={
                  i18n.language === "arabic"
                    ? "category-card-text-arabic"
                    : "category-card-text"
                }
                style={{
                  color: color_definition ? color_definition?.brand : "#000000"
                }}
              >
                {LengthControl(card.brand_name, 30)}
              </div>
            )}
            {card?.title &&
            !(
              data_source === DataSourceEnums.BRAND ||
              card?.link_type === LinkTypeEnums.BRAND
            ) ? (
              <Box
                className="home-info-card-brand-text mt-1"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  fontSize: i18n.language === "arabic" ? "17px" : "14px",
                  letterSpacing: 0,
                  padding: "8px 0px 0px 0px",
                  lineHeight: "130%",
                  textTransform: "none"
                }}
              >
                {LengthControl(card.title, 40)}
              </Box>
            ) : null}
            {card?.primary_category && (
              <Box
                className="category-card-subtext mt-1"
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  fontSize: i18n.language === "arabic" ? "15px" : "12px"
                }}
                style={{
                  color: color_definition
                    ? color_definition?.category
                    : "#5A5F64"
                }}
              >
                {LengthControl(
                  CategoriesRule(
                    card?.primary_category,
                    card?.secondary_category
                  ),
                  40
                )}
              </Box>
            )}
          </CardContent>
        </Box>{" "}
      </LinkType>
    </Card>
  );
}
