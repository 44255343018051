import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";

const ContentDescription = ({ widget, color_theme = [], ui_properties }) => {
  const contentDescriptionList = widget;
  const { i18n } = useTranslation();
  const { color_definition } = color_theme || {};
  const backgroundColor = color_definition?.background_color;
  const headerTextColor = color_definition?.headerText;
  const descriptionColor = color_definition?.description;
  const { background, description_color, header_text_color } =
    ui_properties || {};
  return (
    <Grid
      container
      style={{
        backgroundColor: background || backgroundColor || ""
      }}
      sx={{
        paddingLeft: { xs: "20px", lg: "11.11%" },
        paddingRight: { xs: "20px", lg: "11.11%" },
        position: "relative",
        top: { xs: "0px" },
        display: "flex",
        justifyContent: "center",
        direction: i18n.language === "arabic" ? "rtl" : "ltr"
      }}
      className="editorial-detail"
    >
      <Grid item xs={12} sx={{ maxWidth: { xs: "100%" } }}>
        <Grid container sx={{ marginTop: { xs: "28px", md: "44px" } }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start"
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                style={{
                  color: header_text_color || headerTextColor || ""
                }}
                className={
                  i18n.language === "arabic" ? "tajawalStyle" : "shneStyle"
                }
              >
                {contentDescriptionList?.header_description || ""}
              </Box>
              <Box
                style={{
                  color: description_color || descriptionColor || ""
                }}
                className={i18n.language === "arabic" ? "textArabic" : "text"}
                sx={{ maxWidth: { xs: "100%" }, marginTop: "8px" }}
              >
                {contentDescriptionList?.description || ""}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ContentDescription;
