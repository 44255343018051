import { useMutation } from "@tanstack/react-query";
import { PREFERRED_LANGUAGE } from "services/serviceKeys";
import * as Requests from "./updatePreferredLanguage.requests";

export const usePatchPreferredLanguage = ({ onSuccess, onError }) => {
  return useMutation(
    [PREFERRED_LANGUAGE],
    ({ preferred_language }) => {
      return Requests.patchPreferredLanguage({
        preferred_language
      });
    },
    {
      onSuccess,
      onError
    }
  );
};
