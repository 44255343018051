import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BasicSelect from "Assets/Shared/Select/BasicSelect";
import WprightPage from "Components/Authanticate/WprightPage";
import HistoryButton from "Components/HistoryButton";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";

const PersonelDetails = () => {
  const referalCode = useLocation()?.state?.referalCode;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const path = useSelector((state) => state.path);
  const isExternal = useSelector((state) => state.authanticate.isExternal);
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);
  const personalTrue = routeCntrl.some((item) => item.email === true);
  const [showComponent, setShowComponent] = useState(true);

  const { initialPersonalDetails, email } = useLocation().state || {};
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};
  useEffect(() => {
    navigateProtect();
  }, []);

  const navigateProtect = () => {
    if (!personalTrue) {
      navigate(`/sign-up/email-registration`);
    } else setShowComponent(true);
  };
  const medium = useMediaQuery("(max-width:900px)");
  const unvisible = useMediaQuery("(max-width:1280px)");
  const [dynamic, setDynamic] = useState(false);
  return (
    <>
      {showComponent ? (
        <div className="WPmain">
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              height: "100%",
              backgroundColor: background || "#FFFFFF"
            }}
          >
            <HistoryButton
              referalCode={referalCode}
              image="return"
              Left={i18n.language === "arabic" ? "auto" : 25}
              Top={25}
              Right={i18n.language === "arabic" ? 25 : "auto"}
              path={`/sign-up/email-registration`}
            />
            {!isExternal && (
              <HistoryButton
                referalCode={referalCode}
                Right={i18n.language === "arabic" ? "auto" : 25}
                Top={25}
                Left={i18n.language === "arabic" ? 25 : "auto"}
                path={path}
              />
            )}
            <Box
              className="left-page-form-pd"
              style={{
                top: medium ? "124px" : "215px",
                position: "relative"
              }}
            >
              <>
                <Box
                  className={
                    i18n.language === "arabic"
                      ? "login-head-pd-arabic "
                      : "login-head-pd"
                  }
                  sx={{
                    marginBottom:
                      dynamic && medium && unvisible
                        ? "1.7% !important"
                        : "40px !important"
                  }}
                >
                  <span>{t("Your details")}</span>
                </Box>
                <BasicSelect
                  setDynamic={setDynamic}
                  navigate={navigate}
                  initialPersonalDetails={initialPersonalDetails}
                  email={email}
                  referalCode={referalCode}
                  isExternal={isExternal}
                />
              </>
            </Box>
          </div>
          <WprightPage />
        </div>
      ) : null}
    </>
  );
};

export default PersonelDetails;
