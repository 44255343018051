import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useIsLanguageArabic } from "hooks";
import Arrow from "Assets/images/tableArrow.svg";
import LazyImage from "Components/LazyLoad/LazyImage";

const BackNavigator = ({
  title,
  path,
  containerStyle = {},
  linkStyle = {},
  titleStyle = {},
  imgStyle = {}
}) => {
  const isLanguageArabic = useIsLanguageArabic();
  return (
    <Grid
      sx={{
        width: "100%",
        marginBottom: "16px",
        alignItems: "center",
        display: "flex",
        ...containerStyle
      }}
    >
      <Link
        to={path}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          ...linkStyle
        }}
      >
        <LazyImage
          placeholderSrc={
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          }
          lazyclass="lazy"
          style={{
            transform: isLanguageArabic ? "initial" : "rotate(180deg)",
            marginRight: isLanguageArabic ? "0px" : "16px",
            marginLeft: isLanguageArabic ? "16px" : "0px",
            ...imgStyle
          }}
          width={20}
          src={Arrow}
          alt="Arrow"
        />
      </Link>

      <h3
        style={{
          margin: 0,
          padding: 0,
          justifyItems: "flex-start",
          display: "inline-block",
          fontFamily: isLanguageArabic ? "TajawalRegular" : "MontserratRegular",
          fontSize: isLanguageArabic ? "17px" : "16px",
          lineHeight: isLanguageArabic ? "19px" : "20.8px",
          marginTop: isLanguageArabic ? "-3px" : 0,
          fontWeight: "400",
          ...titleStyle
        }}
      >
        {title}
      </h3>
    </Grid>
  );
};

export default BackNavigator;
