import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import DoneIcon from "@mui/icons-material/Done";

export default function IconCheckboxes({ values }) {
  return (
    <>
      <Checkbox
        checked={values}
        style={{ padding: "0px" }}
        icon={
          <Brightness1Icon
            style={{
              color: "#DFE1EA",
              backgroundColor: "#DFE1EA",
              borderRadius: "50%",
              fontSize: "1rem",
              padding: "3px"
            }}
          />
        }
        checkedIcon={
          <DoneIcon
            style={{
              color: "white",
              backgroundColor: "black",
              borderRadius: "50%",
              fontSize: "1rem",
              padding: "3px"
            }}
          />
        }
      />
    </>
  );
}
