import React from "react";
import FeaturedItem from "Components/Homecomponents/FeaturedItem/FeaturedItem";

const FeaturedItemContainer = ({ page, widget, ui_properties }) => {
  const {
    color_theme,
    asset_type,
    asset,
    show_brand_title,
    title,
    badge,
    link_type,
    header_text,
    categories,
    link,
    brand,
    widgets = [],
    badge_behaviour,
    badge_template,
    start_date,
    end_date,
    reading_time
  } = widget;
  return (
    <FeaturedItem
      home={page === "home"}
      items={widgets}
      asset={asset}
      asset_type={asset_type}
      title={title}
      header_text={header_text}
      category={categories}
      brand={brand}
      show_brand_title={show_brand_title}
      badge={badge}
      color_theme={color_theme}
      link_type={link_type}
      link={link}
      badge_behaviour={badge_behaviour}
      badge_template={badge_template}
      start_date={start_date}
      end_date={end_date}
      reading_time={reading_time}
      ui_properties={ui_properties}
    />
  );
};

export default FeaturedItemContainer;
