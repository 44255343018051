export const AUTH_TOKEN_PERSIST_KEY = "secondToken";
export const PRIVATE_TOKEN_PERSIST_KEY = "private-token";
export const LOCAL_STORAGE_EVENT_NAME = "storage";
export const SELECTED_COUNTRY = "selected-country";
export const SELECTED_COUNTRY_CODE = "selected-country-code";
export const SELECTED_LANGUAGE = "language";
export const MOCK_LOGIN_FORM = process.env.REACT_APP_MOCK_LOGIN_FORM;
export const TIER_LOCAL_STORAGE_KEY = "userTier";
export const ErrorPages = {
  401: "error401",
  500: "error500",
  404: "error404"
};

export const TIER_MAP = Object.freeze({
  "001": "Arise",
  "002": "Aspiration",
  "008": "Ambition",
  "009": "Ascension",
  "010": "Azure",
  "019": "Azure"
});

export const TIER_CODE = Object.freeze({
  "001": "arise",
  "002": "aspiration",
  "008": "ambition",
  "009": "ascension",
  "010": "azure",
  "019": "azure"
});
