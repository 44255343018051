import React from "react";
import { useTranslation } from "react-i18next";
import { dateDiffrence, formatDate } from "dateUtil";
import { LengthControl } from "GlobalProcess/GlobalFunction";
import { Box, useMediaQuery } from "@mui/material";
import LazyImage from "Components/LazyLoad/LazyImage";

const RewardCard = ({
  cardTitleDescription,
  offerStatus,
  offerValidity,
  cardBackgroundImage,
  BrandLogo,
  brandName,
  cardTheme,
  expiredBrandLogo,
  expiredCardBackgroundImage,
  expiredCardTheme,
  expiredTextColor,
  expired
}) => {
  const { t, i18n } = useTranslation();
  const { badge_background_color, badge_text_color, text_color } = expired
    ? expiredTextColor
    : cardTheme || {};
  const mobileMax = useMediaQuery("(max-width:420px)");
  return (!offerValidity || dateDiffrence(offerValidity) >= 0) && !expired ? (
    <Box
      className="reward-card"
      data-lang={i18n.language}
      style={{
        backgroundImage: `url(${cardBackgroundImage})`,
        height: "200px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        width: mobileMax ? "100%" : "350px"
      }}
    >
      <div
        className={
          i18n.language === "arabic" ? "swiper-badge-arabic" : "swiper-badge"
        }
        style={{
          backgroundColor: offerStatus ? badge_background_color : "transparent",
          color: badge_text_color || "#000000",
          fontFamily:
            i18n.language === "arabic"
              ? "NotoNaskhArabicMedium "
              : "MontserratMedium",
          right: i18n.language === "arabic" ? "auto" : "20px",
          left: i18n.language === "arabic" ? "20px" : "auto",
          top: "20px"
        }}
      >
        {offerStatus && LengthControl(offerStatus, 20)}
      </div>
      <div className="top-container">
        <span
          className={
            i18n.language === "arabic"
              ? "brand-top-left-arabic"
              : "brand-top-left"
          }
          style={{ color: text_color || "" }}
        >
          {brandName && brandName.toUpperCase()}
        </span>
      </div>
      <div className="info-container">
        <div className="brandmark-icon">
          {BrandLogo && (
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              width="100%"
              src={BrandLogo}
              alt="brandmark-icon"
            />
          )}
        </div>
        <span
          style={{ marginTop: "8px", color: text_color || "" }}
          className="descriptiption"
        >
          {cardTitleDescription}
        </span>
      </div>
      <div className="expire-date-container" data-lang={i18n.language}>
        {offerValidity && (
          <>
            <span
              data-lang={i18n.language}
              className="expires-text"
              style={{ color: text_color || "" }}
            >
              {t("Expires")}
            </span>
            <span
              data-lang={i18n.language}
              className="date"
              style={{ color: text_color || "" }}
            >
              {formatDate({
                dateText: offerValidity,
                fromFormat: "DD-MM-YYYY",
                toFormat: "DD MMM YYYY",
                language: i18n.language
              })}
            </span>
          </>
        )}
      </div>
    </Box>
  ) : (
    <Box
      className="reward-card"
      data-lang={i18n.language}
      style={{
        backgroundImage: `url(${expiredCardBackgroundImage})`,
        width: "350px",
        height: "200px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: expiredCardTheme,
        width: mobileMax ? "100%" : "350px"
      }}
    >
      <div className="top-container">
        <span
          className={
            i18n.language === "arabic"
              ? "brand-top-left-arabic"
              : "brand-top-left"
          }
          style={{ color: text_color || "" }}
        >
          {brandName && brandName.toUpperCase()}
        </span>
      </div>
      <div className="info-container">
        <div className="brandmark-icon">
          {expiredBrandLogo && (
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              width="100%"
              src={expiredBrandLogo}
              alt="brandmark-icon"
            />
          )}
        </div>
        <span
          style={{ marginTop: "8px", color: text_color || "" }}
          className="descriptiption"
        >
          {cardTitleDescription}
        </span>
      </div>

      <div className="expire-date-container" data-lang={i18n.language}>
        {offerValidity && (
          <>
            <span data-lang={i18n.language} className="expires-text">
              {t("Expires")}
            </span>
            <span
              data-lang={i18n.language}
              className="date"
              style={{ color: text_color || "" }}
            >
              {formatDate({
                dateText: offerValidity,
                fromFormat: "DD-MM-YYYY",
                toFormat: "DD MMM YYYY",
                language: i18n.language
              })}
            </span>
          </>
        )}
      </div>
    </Box>
  );
};

export default RewardCard;
