import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";

const DetailsText = ({ contentDescription, amberImages, ui_properties }) => {
  const { i18n } = useTranslation();
  const { background, header_text_color, description_color } =
    ui_properties || {};
  return (
    <>
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0 }}
        sx={{
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" },
          minHeight: "100%",
          alignContent: "center",
          justifyContent: "flex-start",
          backgroundColor: background || ""
        }}
      >
        {(contentDescription?.title || contentDescription?.description) && (
          <Grid
            container
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              gap: "48px",
              justifyContent: "space-between"
            }}
          >
            {contentDescription?.header_description && (
              <>
                {" "}
                <Grid item xs={12} lg={3.84}>
                  <Box
                    style={{ color: header_text_color || "" }}
                    className={
                      i18n.language === "arabic"
                        ? "brands-left-text-arabic"
                        : "brands-left-text"
                    }
                  >
                    {contentDescription?.header_description}
                  </Box>
                </Grid>
              </>
            )}
            {contentDescription?.description && (
              <Grid item xs={12} lg={6.96}>
                <Box
                  style={{ color: description_color || "" }}
                  className={
                    i18n.language === "arabic"
                      ? "brands-right-text-arabic"
                      : "brands-right-text"
                  }
                >
                  {contentDescription?.description}
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          container
          sx={{
            marginTop: { xs: "36px", md: "34px" },
            marginBottom: { xs: "20px", md: "116px" }
          }}
        >
          {amberImages.map((amberImage) => (
            <Grid
              key={amberImage?.name}
              item
              xs={12}
              md={6}
              sx={{
                marginTop: { xs: "12px", md: "30px" }
              }}
            >
              <Box
                component="img"
                src={amberImage?.image}
                alt="ImagePlaceholder4"
                sx={{
                  width: "-webkit-fill-available",
                  paddingRight: { xs: "0px ", md: "12px" }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsText;
