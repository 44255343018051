import { useMutation, useQuery } from "@tanstack/react-query";
import * as Requests from "./account.requests";
import {
  ACCOUNT_DETAILS,
  ACCOUNT_NOTIFICATION_SETTINGS,
  ACCOUNT_PURCHASE_HISTORY,
  ACCOUNT_SELECT_OTP,
  ACCOUNT_SET_COUNTRY,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_EMAIL,
  ACCOUNT_UPDATE_MOBILE_NUMBER,
  ACCOUNT_VALIDATE,
  ACCOUNT_UPDATE_OTP,
  ACCOUNT_UPDATE_EMAIL_OTP,
  REWARDS,
  ACCOUNT_PROMOTIONS,
  ACCOUNT_NOTIFICATION_SETTINGS_DETAIL
} from "../serviceKeys";
import { getToken } from "utils/authStorage";
import { PRIVATE_TOKEN_PERSIST_KEY } from "constants";
import { useUserStore } from "hooks/useUser";

export const useGetAccount = () => {
  const { setTier, removeUser } = useUserStore();
  return useQuery(
    [ACCOUNT_DETAILS],
    () => {
      return Requests.getAccount()
        .then((data) => {
          const tier = data?.Customer?.[0]?.LoyaltyInformaton?.Card?.Tier;
          if (tier) {
            setTier(tier);
          }
          return data;
        })
        .catch((error) => {
          console.log("error", error);
          if (
            error?.response?.status === 400 &&
            error?.response?.data?.message ===
              "Your account is inactivated. Please contact Amber Assist."
          ) {
            removeUser();
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          }
        });
    },
    {
      enabled: !!getToken(PRIVATE_TOKEN_PERSIST_KEY),
      cacheTime: 300000
    }
  );
};

export const usePostSelectAccountOtp = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_SELECT_OTP],
    ({ customer_id, email, lang }) => {
      return Requests.postSelectAccountOtp({ customer_id, email, lang });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const usePostSelectAccountValidate = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_VALIDATE],
    ({ validation_key, validation_code }) => {
      return Requests.postSelectAccountValidate({
        validation_key,
        validation_code
      });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const usePostSetCountry = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_SET_COUNTRY],
    ({ country }) => {
      return Requests.postSetCountry({
        country
      });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const usePostUpdatedAccount = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_UPDATE],
    ({ firstName, lastName, gender, birthDate }) => {
      return Requests.postUpdatedAccount({
        firstName,
        lastName,
        gender,
        birthDate
      });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useGetNotificationSettings = () => {
  return useQuery([ACCOUNT_NOTIFICATION_SETTINGS_DETAIL], () => {
    return Requests.getNotificationSettings();
  });
};

export const usePostNotificationSettings = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_NOTIFICATION_SETTINGS],
    ({
      contact_by_email,
      contact_by_sms,
      personalized_data,
      most_out_of_amber
    }) => {
      return Requests.postNotificationSettings({
        contact_by_email,
        contact_by_sms,
        personalized_data,
        most_out_of_amber
      });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const usePostUpdateEmail = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_UPDATE_EMAIL],
    ({ email }) => {
      return Requests.postUpdateEmail({
        email
      });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const usePostUpdateMobileNumber = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_UPDATE_MOBILE_NUMBER],
    ({ phoneNumber, iso_code }) => {
      return Requests.postUpdateMobileNumber({
        phoneNumber,
        iso_code
      });
    },
    {
      onSuccess,
      onError
    }
  );
};
export const usePostUpdateOTP = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_UPDATE_OTP],
    ({ validation_key, code }) => {
      return Requests.postUpdateOTP({
        validation_key,
        code
      });
    },
    {
      onSuccess,
      onError
    }
  );
};
export const usePostEmailOTP = ({ onSuccess, onError }) => {
  return useMutation(
    [ACCOUNT_UPDATE_EMAIL_OTP],
    ({ validation_key, code }) => {
      return Requests.postUpdateEmaiOTP({
        validation_key,
        code
      });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useGetPurchaseHistory = () => {
  return useQuery(
    [ACCOUNT_PURCHASE_HISTORY],
    () => {
      return Requests.getPurchaseHistory();
    },
    {
      staleTime: Infinity
    }
  );
};

export const useGetRewardCards = () => {
  return useQuery(
    [REWARDS],
    () => {
      return Requests.getRewards();
    },
    {
      staleTime: Infinity
    }
  );
};

export const useGetPromotions = () => {
  return useQuery(
    [ACCOUNT_PROMOTIONS],
    () => {
      return Requests.getPromotions();
    },
    {
      staleTime: Infinity
    }
  );
};
export const useGetArchivedCoupons = () => {
  return useQuery(["get_archived-coupons"], () => {
    return Requests.getArchivedCoupons();
  });
};
export const useRemoveCoupon = ({ onSuccess, onError }) => {
  return useMutation(
    ["add-coupon-archive"],
    ({ couponBarcode, action }) => {
      return Requests.removeCoupon({ couponBarcode, action });
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useGetFetchAmberReferralProgram = () => {
  return useQuery(["fetch-amber-referral-program"], () => {
    return Requests.getFetchAmberReferralProgram();
  });
};
