import { useEffect } from "react";
import Layout from "../Layout";
import { useGetAmberAppContent } from "services/cms/cms.query";
import { CMS_COMPONENT_MAP } from "CmsComponents";
import { useNavigate } from "react-router-dom";
const Amberapp = () => {
  const { data: amberAppContent = {} } = useGetAmberAppContent();
  const { widgets = [] ,seo_data} = amberAppContent;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  return (
    <Layout seo_data={seo_data}>
      {widgets?.map((widget) => {
        const CmsComponent = CMS_COMPONENT_MAP[widget["component_type"]];
        if (CmsComponent) {
          return <CmsComponent widget={widget} page={"amberapp"} />;
        }
        return null;
      })}
    </Layout>
  );
};

export default Amberapp;
