import React from "react";
import BrowseButton from "../../common/Promotions/BrowseButton";

const LinkButton = ({ style = {}, ...props }) => {
  return (
    <BrowseButton
      style={{
        border: "none",
        backgroundColor: "transparent",
        margin: 0,
        padding: 0,
        ...style
      }}
      {...props}
    />
  );
};

export default LinkButton;
