import { useTranslation } from "react-i18next";

export const InputValidationMessage = ({ text }) => {
  const { i18n } = useTranslation();
  return (
    <p className="input-error-message" data-lang={i18n.language}>
      {text}
    </p>
  );
};
