import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Box } from "@mui/material";
import ActionAreaCard from "./item";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const AUTO_PLAY_OPTIONS = {
  delay: 5000,
  disableOnInteraction: false
};

const CaousalBrands = ({
  size,
  items,
  head,
  color_theme,
  animation_speed,
  auto_slide
}) => {
  const swiperRef = useRef(null);
  const twice = useMediaQuery("(min-width:1400px)");
  const single2 = useMediaQuery("(min-width:1000px) and (max-width:1399px) ");
  const single = useMediaQuery("(min-width:600px) and (max-width:999px) ");
  const unvisible = useMediaQuery("(max-width:599px)");
  const { i18n } = useTranslation();
  const { color_definition } = color_theme || {};
  const { title, background_color } = color_definition || {};

  //The autoplayOption variable is an object that configures autoplay options for a slideshow or carousel. It specifies a delay of 5000 milliseconds (5 seconds)
  // between slides and ensures that autoplay does not get disabled when the user interacts with the slideshow.
  //The slidesPerView variable calculates the number of slides to be displayed in the view based on certain conditions.
  // It appears to be used for determining how many slides are visible at once in a slideshow or carousel. The specific value is determined by conditions involving twice,
  // size, single2, and single, and it can be 4 or 2.

  let slidesPerView = twice ? size : single2 ? 4 : single ? 4 : 2;

  useEffect(() => {
    const swiperTimeout = setTimeout(() => {
      swiperRef.current?.autoplay?.start();
      swiperRef.current?.autoplay?.run();
    }, 250);

    return () => {
      clearTimeout(swiperTimeout);
    };
  }, [swiperRef, auto_slide]);

  return (
    <>
      {" "}
      <div className="amber-app">
        <Box
          className="swiper"
          sx={{
            minHeight: "100%",

            direction: i18n.language === "arabic" ? "rtl" : "ltr",
            backgroundColor: background_color ? background_color : "",
            paddingTop: { xs: "32px", md: "120px" },
            paddingBottom: { xs: "32px", md: "120px" },
            paddingLeft: { xs: "20px", md: "11.11%" },
            paddingRight: { xs: "20px", md: "11.11%" }
          }}
        >
          {head && (
            <div
              className={
                i18n.language === "arabic"
                  ? "section-text-head-arabic"
                  : "section-text-head"
              }
              style={{ color: title ? title : "" }}
            >
              {head}
            </div>
          )}
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            autoplay={auto_slide ? AUTO_PLAY_OPTIONS : false}
            slidesPerView={parseFloat(slidesPerView)}
            slidesPerGroup={parseInt(slidesPerView)}
            spaceBetween={80}
            loop={false}
            speed={animation_speed ? (101 - animation_speed) * 1000 : 3000}
            loopFillGroupWithBlank={true}
            navigation={false}
            pagination={!unvisible ? { clickable: true } : false}
            className="mySwiper"
            data-lang={i18n.language}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {items.map((category) => (
              <SwiperSlide
                key={category.id}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  direction: "ltr",
                  flexDirection: "column"
                }}
              >
                <ActionAreaCard card={category} size={size} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </div>{" "}
    </>
  );
};
export default CaousalBrands;
