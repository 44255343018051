import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Grid, CircularProgress } from "@mui/material";

import { useIsLanguageArabic, useIsMobileView } from "hooks";
import ViewAllButton from "Assets/ViewAllButton";
import { formatDate } from "dateUtil";
import Arrow from "Assets/images/tableArrow.svg";
import LazyImage from "Components/LazyLoad/LazyImage";

const DefaultEmptyComponent = () => {
  return <div>No Data</div>;
};

export const ARROWS_COLORS = {
  active: "black",
  inActive: "#CED0D7"
};

//The SORT_CONFIG object defines sorting configurations for different columns in a data table, including keys,
// default sorting types, available sorting options, and sorting functions.
const SORT_CONFIG = {
  defaultSortColumn: "Date",
  Date: {
    key: "TransactionDate",
    defaultType: "oldest",
    options: ["newest", "oldest"],
    sortFunction: (a, b, type) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return type === "newest" ? dateB - dateA : dateA - dateB;
    }
  },
  Merchant: {
    key: "BrandName",
    defaultType: "AtoZ",
    options: ["AtoZ", "ZtoA"],
    sortFunction: (a, b, type) => {
      return type === "AtoZ" ? a.localeCompare(b) : b.localeCompare(a);
    }
  },
  Amount: {
    key: "PurchaseValue",
    defaultType: "highestFirst",
    options: ["highestFirst", "lowestFirst"],
    sortFunction: (a, b, type) => {
      const valueA = Number(a.split(" ")[0]);
      const valueB = Number(b.split(" ")[0]);
      return type === "highestFirst" ? valueB - valueA : valueA - valueB;
    }
  },
  Receipt: {
    key: "BrandOrStore",
    defaultType: "AtoZ",
    options: ["AtoZ", "ZtoA"],
    sortFunction: (a, b, type) => {
      return type === "AtoZ" ? a.localeCompare(b) : b.localeCompare(a);
    }
  },
  Status: {
    key: "TransactionType",
    defaultType: "AtoZ",
    options: ["AtoZ", "ZtoA"],
    sortFunction: (a, b, type) => {
      return type === "AtoZ" ? a.localeCompare(b) : b.localeCompare(a);
    }
  },
  Points: {
    key: "Points",
    defaultType: "highestFirst",
    options: ["highestFirst", "lowestFirst"],
    sortFunction: (a, b, type) => {
      const pointsA = parseInt(a, 10) || 0;
      const pointsB = parseInt(b, 10) || 0;
      return type === "highestFirst" ? pointsB - pointsA : pointsA - pointsB;
    }
  }
};

export default function BasicTable({
  data,
  columns = ["Date", "Merchant", "Amount", "Status", "Points", "Details"],
  EmptyComponent = <DefaultEmptyComponent />,
  CustomViewAllButton,
  showMoreText = "View all purchases",
  showMoreTextStyle = {},
  onClickViewAllButton = () => false,
  showMoreButton = true,
  loadMoreJustifyContent,
  isLoading,
  isSuccess
}) {
  const { t, i18n } = useTranslation();
  const isMobileView = useIsMobileView();
  const isArabic = useIsLanguageArabic();
  const responsiveTableHeadClassName = isArabic
    ? "responsive-table-head-arabic"
    : "responsive-table-head";
  const responsiveTableBodyClassName = isArabic
    ? "responsive-table-body-arabic"
    : "responsive-table-body";
  const boxTextClassName = isArabic ? "box-text-arabic" : "box-text";
  const boxTextLabelClassName = isArabic
    ? "box-text-label-arabic"
    : "box-text-label";
  const isDataEmpty = !data || !data.length;
  // State for sorting
  const [sortColumn, setSortColumn] = useState(SORT_CONFIG.defaultSortColumn);
  const [sortDirection, setSortDirection] = useState(
    SORT_CONFIG[SORT_CONFIG.defaultSortColumn].defaultType
  );

  //The handleSort function manages the sorting behavior of a data table, allowing users to toggle the sorting direction for a specified column based on the provided configurations.
  const handleSort = (column) => {
    const config = SORT_CONFIG[column];
    if (!config) return;
    let newDirection = config.defaultType;
    if (sortColumn === column) {
      const currentIndex = config.options.indexOf(sortDirection);
      newDirection = config.options[(currentIndex + 1) % config.options.length];
    }
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  //This code creates a sorted copy of a data array (sortedData) using the JavaScript sort method.
  // It uses the specified sortColumn and the associated sortFunction from the SORT_CONFIG object to sort the data in the desired direction (sortDirection).
  const sortedData = [...data].sort((a, b) => {
    if (!sortColumn || !SORT_CONFIG[sortColumn]) return 0;
    const { key, sortFunction } = SORT_CONFIG[sortColumn];
    return sortFunction(a[key], b[key], sortDirection);
  });

  useEffect(() => {
    handleSort(sortColumn);
  }, []);

  return (
    <Grid
      className="responsive-table"
      container
      rowSpacing={4}
      columnSpacing={{ xs: 0 }}
      sx={{
        paddingLeft: { xs: "20px", md: "11.11%" },
        paddingRight: { xs: "20px", md: "11.11%" },
        paddingTop: "40px",
        height: "100%",
        justifyContent: { xs: "center" },
        alignItems: "center",
        textAlign: "left",
        marginBottom: { xs: "52px", md: "108px" }
      }}
    >
      {isLoading && !isSuccess ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: isLoading ? "100vh" : "100%"
          }}
        >
          <CircularProgress
            size={"25px"}
            style={{
              color: "black"
            }}
          />
        </Box>
      ) : (
        <>
          {" "}
          {isDataEmpty ? (
            <Grid
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {EmptyComponent}
            </Grid>
          ) : (
            <>
              <Grid
                xs={12}
                sx={{
                  display: { xs: "none", md: "flex" }
                }}
              >
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    direction: isArabic ? "rtl" : "initial",
                    textAlign: isArabic ? "right" : "left"
                  }}
                >
                  <thead style={{ borderBottom: "1px solid #000000" }}>
                    <tr>
                      {columns.map((item) => (
                        <th
                          data-key={item}
                          key={item}
                          className={responsiveTableHeadClassName}
                          scope="col"
                          onClick={() => handleSort(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingRight: "16px"
                            }}
                          >
                            {t(item)}
                            {SORT_CONFIG[item] ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  height: "14px"
                                }}
                              >
                                <svg
                                  width="11"
                                  height="5"
                                  viewBox="0 0 11 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.33333 0L0.333328 5H10.3333L5.33333 0Z"
                                    fill={
                                      sortColumn === item &&
                                      sortDirection ===
                                        SORT_CONFIG[item].options[1]
                                        ? ARROWS_COLORS.active
                                        : ARROWS_COLORS.inActive
                                    }
                                  />
                                </svg>
                                <svg
                                  width="11"
                                  height="5"
                                  viewBox="0 0 11 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.33333 5L10.3333 8.74228e-07L0.333328 0L5.33333 5Z"
                                    fill={
                                      sortColumn === item &&
                                      sortDirection !==
                                        SORT_CONFIG[item].options[1]
                                        ? ARROWS_COLORS.active
                                        : ARROWS_COLORS.inActive
                                    }
                                  />
                                </svg>
                              </div>
                            ) : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map(
                      (row, index) =>
                        row.TransactionDate !== "" && (
                          <tr
                            key={row.id}
                            style={{
                              borderBottom: "1px solid rgb(224, 224, 224)"
                            }}
                          >
                            <td
                              className={responsiveTableBodyClassName}
                              style={{ width: 230 }}
                            >
                              {formatDate({
                                dateText: row.TransactionDate,
                                language: i18n.language
                              })}
                            </td>
                            <td
                              className={responsiveTableBodyClassName}
                              style={{ width: 230 }}
                            >
                              {row.BrandName?.split("-")[0]}
                            </td>
                            <td
                              className={responsiveTableBodyClassName}
                              style={{ width: 230 }}
                            >{`${row.PurchaseValue.split(" ")[1]} ${
                              row.PurchaseValue.split(" ")[0]
                            }`}</td>
                            <td
                              className={responsiveTableBodyClassName}
                              style={{ width: 230 }}
                            >
                              <span
                                className={
                                  isArabic
                                    ? "responsive-table-points-arabic"
                                    : "responsive-table-points"
                                }
                              >
                                {t(row.TransactionType)}
                              </span>
                            </td>
                            <td
                              className={
                                row.Points > 0
                                  ? responsiveTableBodyClassName
                                  : `${responsiveTableBodyClassName} responsive-table-body-change-color`
                              }
                              style={{
                                color: row.Points <= 0 ? "#F66464" : "initial",
                                width: 230
                              }}
                            >
                              <span>
                                {(row.Points * 1 === 0
                                  ? " "
                                  : row.Points * 1 > 0
                                  ? "+ "
                                  : "- ") +
                                  Math.abs(row.Points) +
                                  (isArabic ? " نقطة" : " pts")}
                              </span>
                            </td>
                            <td
                              className="responsive-table-body"
                              style={{ width: 230 }}
                            >
                              {
                                // TODO: Refactor this. Following link is ducktaped solution, because previous solution was not based on stable id to pick proper details
                                <Link
                                  style={{ cursor: "pointer" }}
                                  to={
                                    "/purchase-details/" +
                                    (row.stableIndex !== undefined
                                      ? row.stableIndex
                                      : index)
                                  }
                                >
                                  {i18n.language === "arabic" ? (
                                    <LazyImage
                                      placeholderSrc={
                                        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                      }
                                      lazyclass="lazy"
                                      src={Arrow}
                                      width={17}
                                      height={12}
                                      alt="ArrowBack"
                                      style={{ transform: "rotate(180deg)" }}
                                    />
                                  ) : (
                                    <LazyImage
                                      placeholderSrc={
                                        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                      }
                                      lazyclass="lazy"
                                      src={Arrow}
                                      width={17}
                                      height={12}
                                      alt="ArrowForward"
                                    />
                                  )}
                                </Link>
                              }
                            </td>
                          </tr>
                        )
                    )}
                    <tr></tr>
                  </tbody>
                </table>
              </Grid>
              {!isDataEmpty && showMoreButton && !isMobileView ? (
                <Grid
                  sx={{
                    marginTop: "24px",
                    display: {
                      xs: "none",
                      md: "flex",
                      width: "100%",
                      direction: isArabic ? "rtl" : "initial"
                    }
                  }}
                >
                  {CustomViewAllButton || (
                    <ViewAllButton
                      style={showMoreTextStyle}
                      isArabic={isArabic}
                      onClick={onClickViewAllButton}
                    >
                      {t(showMoreText)}
                    </ViewAllButton>
                  )}
                </Grid>
              ) : null}
            </>
          )}
          {data.map(
            (row, index) =>
              row.TransactionDate !== "" && (
                <Grid
                  key={index}
                  xs={12}
                  sx={{
                    display: { xs: isDataEmpty ? "none" : "flex", md: "none" },
                    direction: isArabic ? "rtl" : "initial"
                  }}
                >
                  <Link
                    style={{
                      color: "initial",
                      textDecoration: "none",
                      width: "100%",
                      height: "100%"
                    }}
                    // TODO: Refactor this. Following link is ducktaped solution, because previous solution was not based on stable id to pick proper details
                    to={
                      "/purchase-details/" +
                      (row.stableIndex !== undefined ? row.stableIndex : index)
                    }
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "214px",
                        border: "1px solid #DFE1EA",
                        borderRadius: "8px",
                        maxWidth: "100%",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-around",
                        textAlign: isArabic ? "right" : "left",
                        flexDirection: "column",
                        padding: "20px 0px"
                      }}
                    >
                      <div className="box-main">
                        <div className={boxTextLabelClassName}>{t("Date")}</div>
                        <div className={boxTextClassName}>
                          {" "}
                          {formatDate({
                            dateText: row.TransactionDate,
                            language: i18n.language
                          })}
                        </div>
                      </div>
                      <div className="box-main">
                        <div className={boxTextLabelClassName}>
                          {t("Merchant")}
                        </div>
                        <div className={boxTextClassName}>
                          {row.BrandName?.split("-")[0]}
                        </div>
                      </div>
                      <div className="box-main">
                        <div className={boxTextLabelClassName}>
                          {t("Amount")}
                        </div>
                        <div className={boxTextClassName}>
                          {`${row.PurchaseValue.split(" ")[1]} ${
                              row.PurchaseValue.split(" ")[0]
                          }`}
                        </div>
                      </div>
                      <div className="box-main">
                        <div className={boxTextLabelClassName}>
                          {t("Status")}
                        </div>
                        <div className={boxTextClassName}>
                          <Box
                            sx={{
                              gap: "4px",
                              width: "64px",
                              height: "20px",
                              background: "#F3F3FA",
                              borderRadius: "50px",
                              fontFamily: isArabic
                                ? "TajawalMedium"
                                : "MontserratMedium",
                              fontWeight: isArabic ? 500 : 400,
                              fontSize: isArabic ? "11px" : "10px",
                              lineHeight: "14px",
                              alignItems: "center",
                              color: "#000000",
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            {t(row.TransactionType)}
                          </Box>
                        </div>
                      </div>
                      <div className="box-main">
                        <div
                          className={`${boxTextClassName} ${boxTextLabelClassName}`}
                        >
                          {t("Points")}
                        </div>
                        <div
                          className={boxTextClassName}
                          style={{
                            color: row.Points <= 0 ? "#F66464" : "initial"
                          }}
                        >
                          <span>
                            {(row.Points * 1 === 0
                              ? ""
                              : row.Points * 1 > 0
                              ? "+ "
                              : "- ") +
                              Math.abs(row.Points) +
                              (isArabic ? " نقطة" : " pts")}
                          </span>
                        </div>
                      </div>
                    </Box>
                  </Link>
                </Grid>
              )
          )}
          {!isDataEmpty && showMoreButton && isMobileView && (
            <Grid
              xs={12}
              sx={{
                display: "flex",
                justifyContent: loadMoreJustifyContent
                  ? "center"
                  : isArabic
                  ? "right"
                  : "left"
              }}
            >
              {CustomViewAllButton || (
                <ViewAllButton
                  isArabic={isArabic}
                  onClick={onClickViewAllButton}
                  sx={{ marginTop: "24px", display: "flex" }}
                >
                  {t(showMoreText)}
                </ViewAllButton>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
