import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import VectorCross from "Assets/images/VectorCross.png";
import Vector from "Assets/images/Vectordown.svg";
import CloseImage from "Assets/images/close.png";
import LinkType from "Components/LinkType";
import LazyImage from "Components/LazyLoad/LazyImage";

const MobileNavMenu = ({
  navItems = [],
  logo,
  token,
  userData,
  setMobileOpen,
  color_definition
}) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { cta_text_hover,hover_link, cta_fill_hover } = color_definition || {};
   const linkButton = (item, index) => {
    if (item) {
      return (
        <Box
          key={index}
          onClick={() => setMobileOpen(false)}
          className="nav-list-a"
          style={{
            color: hover_link
          }}
        >
          <LinkType link={item.link} link_type={item.link_type}>
            <ListItem
              key={item.title}
              disablePadding
              secondaryAction={
                i18n.language === "arabic" ? null : (
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    sx={{ marginRight: "6px" }}
                  >
                    <LazyImage
                      placeholderSrc={
                        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      }
                      lazyclass="lazy"
                      src={Vector}
                      alt="Vector"
                    />
                  </IconButton>
                )
              }
            >
              <ListItemButton
                sx={{
                  textAlign: i18n.language === "arabic" ? "right" : "left",
                  paddingLeft: "20px",
                 
                }}
              >
                {i18n.language === "arabic" ? (
                  <ListItemIcon>
                    <LazyImage
                      placeholderSrc={
                        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      }
                      lazyclass="lazy"
                      src={VectorCross}
                      alt="Vector"
                    />
                  </ListItemIcon>
                ) : null}
                <ListItemText
                  primary={item.title}
                  sx={{ marginRight: "14px" }}
                  primaryTypographyProps={{
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalRegular "
                        : "MontserratRegular",
                    color:hover_link,
                    fontSize: i18n.language === "arabic" ? "17px" : "16px"
                  }}
                />
              </ListItemButton>
            </ListItem>
          </LinkType>
        </Box>
      );
    }
  };
  return (
    <Box
      className="Nav"
      style={{
        textAlign: "left",
        marginTop: "24px"
      }}
    >
      <Typography
        variant="h6"
        sx={{
          my: 0,
          pl: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: i18n.language === "arabic" ? "row-reverse" : "row"
        }}
      >
        <Box
          style={{
            maxWidth: "88.1px",
            maxHeight: "40px",
            marginRight: "24px",
            marginLeft: "20px"
          }}
        >
          <Link to="/">
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={logo}
              alt="logo"
              width={"88.1px"}
              height={"40px"}
            />
          </Link>
        </Box>

        <IconButton
          className="close-button"
          onClick={() => setMobileOpen(false)}
          sx={{
            width: 40,
            height: 40,
            left: "none",
            marginRight: "12px",
            marginLeft: "4px"
          }}
        >
          <LazyImage
            placeholderSrc={
              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
            lazyclass="lazy"
            src={CloseImage}
            alt="CloseImage"
          />
        </IconButton>
      </Typography>

      <List
      >{navItems.map((item, index) => linkButton(item, index))}</List>
      {token && userData ? (
        <></>
      ) : (
        <Grid container spacing={0} className="create-grid">
          <Button
            variant="contained"
            onClick={() => navigate("/sign-up")}
            className="button-crate-account"
            style={{
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalMedium"
                  : "MontserratMedium",
              boxShadow: "none",
              fontSize: i18n.language === "arabic" ? "17px" : "14px",
              backgroundColor: cta_fill_hover,
              color: cta_text_hover
            }}
          >
            {t("Create account")}
          </Button>
          <Button
            component={Link}
            to="/sign-in"
            variant="contained"
            className="button-signin"
            style={{
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalRegular "
                  : "MontserratRegular",

              fontSize: i18n.language === "arabic" ? "17px" : "14px",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "none"
              },
              backgroundColor: "inherit",
              color: hover_link
            }}
          >
            {t("Sign in")}
          </Button>
        </Grid>
      )}
    </Box>
  );
};

export default MobileNavMenu;
