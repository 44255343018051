import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SwitchButton from "Assets/SwitchButton/SwitchButton";
import SwitchbuttonReverse from "Assets/SwitchButton/SwitchButtonReverse";
import CustomizedDialog from "../Account/components/Modal";
import PrimaryButton from "../Account/components/PrimaryButton";

const YourPrivacy = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { t, i18n } = useTranslation();

  const secureCodeForm = useFormik({
    initialValues: {
      code: ""
    },
    onSubmit: ({ code }) => {},
    validateOnBlur: false
  });

  return (
    <CustomizedDialog
      onCancel={() => {
        setIsModalOpen(false);
      }}
      open={isModalOpen}
      title={t("Your Privacy")}
      data-lang={i18n.language}
      titleClassName={i18n.language === "arabic" ? "title-arabic" : "title"}
      dialogContentClassName={"dialog-content-container"}
      okButtonLabel={t("Save Settings")}
      dialogClasses={{
        root: "your-privacy-modal-root"
      }}
      okButtonProps={{
        onClick: () => {
          secureCodeForm.submitForm();
        },
        className: "",
        style: {
          width: "100%",
          marginTop: "32px"
        }
      }}
    >
      <div>
        <p className="your-privacy-description" data-lang={i18n.language}>
          {t("yourPrivacyContent")}
        </p>
        <div className="more-information-link-container">
          <span className="more-information-link" data-lang={i18n.language}>
            {t("More Information")}
          </span>
        </div>
        <div className="allow-all-button-container">
          <PrimaryButton
            text={t("Allow All")}
            onClick={() => false}
            style={{
              marginTop: "0px",
              width: "160px",
              fontSize: "12px",
              lineHeight: "130%",
              padding: "10px 16px"
            }}
          />
        </div>
      </div>
      <div className="manage-consent">
        <h3 className="title" data-lang={i18n.language}>
          {t("Manage Consent Preferences")}
        </h3>
        <div className="sub-content">
          <div className="sub-title-container">
            <h4 className="sub-title" data-lang={i18n.language}>
              {t("Necessary tracking technologies")}
            </h4>
            <span className="always-active" data-lang={i18n.language}>
              {t("Always Active")}
            </span>
          </div>

          <p className="sub-content-text" data-lang={i18n.language}>
            {t("necessaryTrackingTechnologiesMessage")}
          </p>
        </div>

        <div className="sub-content-container">
          <div className="sub-title-container">
            <h4 className="sub-title" data-lang={i18n.language}>
              {t("Performance tracking technologies")}
            </h4>
            {i18n.language === "arabic" ? (
              <SwitchbuttonReverse />
            ) : (
              <SwitchButton />
            )}
          </div>

          <p className="sub-content-text" data-lang={i18n.language}>
            {t("performanceTrackingTechnologiesMessage")}
          </p>
        </div>
      </div>
    </CustomizedDialog>
  );
};

export default YourPrivacy;
