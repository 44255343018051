import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import SwiperCarousal from "Assets/CustomComponents/SwiperCarousal";

const BrandItemCarousel = ({
  widget = [],
  ui_properties,
  editorial_brand_carousel
}) => {
  const { i18n } = useTranslation();
  const { background } = ui_properties || {};

  return (
    <Grid
      container
      sx={{
        paddingLeft: { xs: "20px", lg: "11.11%" },
        paddingRight: { xs: "20px", lg: "11.11%" },
        position: "relative",
        top: { xs: "0px" },
        display: "flex",
        justifyContent: "center",
        direction: i18n.language === "arabic" ? "rtl" : "ltr",
        backgroundColor: background || ""
      }}
      className="editorial-detail"
    >
      <Grid item xs={12} sx={{ maxWidth: { xs: "100%" } }}>
        {widget?.items && (
          <SwiperCarousal
            items={widget?.items || []}
            marginless={"nomargin"}
            background_color_carousel={
              widget?.color_theme?.color_definition?.background_color
            }
            animation_speed={widget?.animation_speed || 90}
            auto_slide={widget?.auto_slide || false}
            label={widget?.label || ""}
            paddingAdd={true}
            editorial_brand_carousel={editorial_brand_carousel}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default BrandItemCarousel;
