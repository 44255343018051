import dayjs from "dayjs";

export const langMap = {
  arabic: "ar",
  en: "en"
};

// formatDate Function: This function takes an input date string and converts it into a desired output format while considering the specified language. It uses the following parameters:
// dateText: The input date in string format.
// language: The language for formatting the date (e.g., "arabic" or "en").
// fromFormat: The format of the input date string (default is "YYYY-MM-DD HH:mm:ss").
// toFormat: The desired format for the output date string (default is "DD MMM YYYY").
// The function uses "dayjs" to parse the dateText from the fromFormat and then formats it using the specified toFormat. The locale is set based on the provided language or defaults to "en" if the language is not found in the langMap. The result is the formatted date string.
// dateDiffrence Function: This function calculates the difference in days between a given rewardDate and the current date. It takes rewardDate in the "DD-MM-YYYY" format, and using "dayjs," it calculates the difference in days between rewardDate and the current date.

export const formatDate = ({
  dateText,
  language,
  fromFormat = "YYYY-MM-DD HH:mm:ss",
  toFormat = "DD MMM YYYY"
}) => {
  return dayjs(dateText, fromFormat)
    .locale(langMap[language] || "en")
    .format(toFormat);
};

export const dateDiffrence = (rewardDate) => {
  return dayjs(rewardDate, "DD-MM-YYYY").diff(dayjs(), "day");
};
