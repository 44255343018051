import { apiClient } from "../apiClient";

export const getOffers = async ({ segment = "Default" } = {}) => {
  return apiClient({
    url: "/cms/fetch-events-and-offers-content",
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};
export const getOfferCategories = async () => {
  return apiClient({
    url: "/offer-categories"
  }).then((res) => res.data);
};

export const getOfferDetailsByID = async ({ id, segment = "Default" }) => {
  return apiClient({
    url: `/cms/fetch-offers-content/${id}`,
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};

export const getOffersLanding = async ({
  segment = "Default",
  content_type
}) => {
  return apiClient({
    url: `/cms/fetch-offers-content?content_type=${content_type}`,
    customConfig: {
      params: {
        segment
      }
    }
  }).then((res) => res.data);
};
