import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import SwitchButton from "Assets/SwitchButton/SwitchButton";
import SwitchbuttonReverse from "Assets/SwitchButton/SwitchButtonReverse";
import MobileNumberInput from "Pages/Account/components/MobileNumberInput";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import { Link } from "react-router-dom";

const ErrorCode = {
  "Number already exist": 5000,
  "Number not exist": 5001,
  "Account is disabled": 5002
};
const storage = localStorage.getItem("switchActive");
const MobileNumberForm = ({
  errorResponse,
  errorCode,
  handleSubmit,
  infoText,
  isLoading,
  style,
  label = "Mobile Phone",
  loginKey,
  showLoginLink = true
}) => {
  const [errorResponseMessage, setErrorResponseMessage] = useState();
  const { t, i18n } = useTranslation();
  const [active, SetActive] = useState(null);
  useEffect(() => {
    if (storage === "true") {
      SetActive(true);
    } else SetActive(false);
  }, []);
  const [rememberPhone, SetRememberPhone] = useState(
    JSON.parse(localStorage.getItem("rememberSıgnIn")) || null
  );
  const initialFormValues = process.env.REACT_APP_MOCK_LOGIN_FORM
    ? {
        code: {
          country_code: rememberPhone?.code ? rememberPhone?.code : "+90",
          iso_code: rememberPhone?.iso_code ? rememberPhone?.iso_code : "TR"
        },
        phoneNumber: process.env.REACT_APP_MOCK_LOGIN_PHONE_NUMBER
      }
    : {
        code: {
          country_code: rememberPhone?.code ? rememberPhone?.code : "+971",
          iso_code: rememberPhone?.iso_code ? rememberPhone?.iso_code : "ARE"
        },
        phoneNumber: rememberPhone?.phone ? rememberPhone?.phone : ""
      };

  const validationSchema = yup.object({
    code: yup
      .object({
        country_code: yup.string().required(),
        iso_code: yup.string().required("Phone code cannot be blank")
      })
      .required(),
    phoneNumber: yup
      .string()
      .required(t("Phone number cannot be blank"))
      .test(
        "len",
        t("Phone number should be 7 digits minimum"),
        (val) => val?.length > 7
      )
  });

  const formikForm = useFormik({
    initialValues: initialFormValues,

    validationSchema,
    onSubmit: ({ code, phoneNumber }) => {
      handleSubmit({
        code: code?.country_code,
        phone: phoneNumber,
        iso_code: code?.iso_code
      });
      if (active) {
        const rememberSıgnIn = {
          code: code?.country_code,
          phone: phoneNumber,
          iso_code: code?.iso_code
          // iso_code: "TR",
        };
        localStorage.setItem("switchActive", true);

        localStorage.setItem("rememberSıgnIn", JSON.stringify(rememberSıgnIn));
      } else {
        localStorage.setItem("switchActive", false);
        localStorage.removeItem("rememberSıgnIn");

        SetActive(false);
      }
    }
  });

  useEffect(() => {
    if (formikForm.errors.code || formikForm.errors.phoneNumber) {
      setErrorResponseMessage();
    }
    return () => {};
  }, [formikForm.errors]);
  useEffect(() => {
    setErrorResponseMessage(errorResponse);
  }, [errorResponse]);

  const formikErrorMessage =
    (formikForm.touched.code ? t(formikForm.errors.code?.iso_code) : "") ||
    (formikForm.touched.phoneNumber ? t(formikForm.errors.phoneNumber) : "");

  //The handleChangeSwitchButton function toggles the state of a boolean variable,
  // active, while the errorMessage function is used to generate error messages based on specific conditions.
  // If the error matches the "Number already exists" or "Number doesn't exist" error codes, the error message is
  //constructed with optional modifications based on the current language (English or Arabic).
  //The messages also include links for user actions, such as signing in or creating an account.

  const handleChangeSwitchButton = () => {
    SetActive(!active);
  };

  const errorMessage = () => {
    if (
      errorResponseMessage &&
      errorCode === ErrorCode["Number already exist"]
    ) {
      return (
        <>
          {!loginKey && (
            <>
              {i18n.language === "arabic"
                ? errorResponseMessage.replace("?", "؟")
                : errorResponseMessage}
              {showLoginLink && (
                <>
                  <br></br>
                  {t("Would you like to ")}{" "}
                  <Link to="/sign-in">{t("sign in instead?")}</Link>
                </>
              )}
            </>
          )}
        </>
      );
    } else if (
      errorResponseMessage &&
      errorCode === ErrorCode["Number not exist"]
    ) {
      return (
        <>
          {i18n.language === "arabic"
            ? errorResponseMessage.replace("?", "؟")
            : errorResponseMessage}
          <br></br>
          {!loginKey && (
            <>
              {t("Would you like to ")}{" "}
              <Link to="/sign-up/phone-validation">
                {t("create an account")}
              </Link>
              {i18n.language === "arabic" ? "؟" : "?"}
            </>
          )}
        </>
      );
    } else if (
      errorResponseMessage &&
      errorCode === ErrorCode["Account is disabled"]
    ) {
      return <>{errorResponseMessage}</>;
    } else return formikErrorMessage || errorResponseMessage;
  };

  return (
    <form
      style={{ height: "100%" }}
      onSubmit={formikForm.handleSubmit}
      noValidate
    >
      <div
        className={
          loginKey
            ? "left-page-form-subdiv flex-center"
            : "left-page-form-subdiv"
        }
      >
        {loginKey && (
          <div
            className={loginKey ? "f-26 login-head-WA" : "login-head-WA"}
            style={{
              maxWidth: "100%",
              marginTop: "4px",
              fontFamily:
                i18n.language === "arabic"
                  ? "NotoNaskhArabicRegular"
                  : "PlayfairDisplay"
            }}
          >
            <span>{t("Login with Amber to earn rewards")}</span>
          </div>
        )}
        <div className={loginKey && "flex-center"}>
          {!loginKey && (
            <div
              className={
                i18n.language === "arabic"
                  ? "login-head-pn-arabic"
                  : "login-head-pn"
              }
            >
              <span>{t("Your phone number")}</span>
            </div>
          )}
          <div
            className={
              i18n.language === "arabic" ? "subtext-pn-arabic" : "subtext-pn"
            }
            style={{ paddingLeft: "0px" }}
          >
            {!loginKey && t(infoText)}
          </div>
          <div
            style={{ maxWidth: loginKey && "460px", width: loginKey && "100%" }}
          >
            {errorMessage() && (
              <AlertWarning
                marginbottom="24px"
                margintop="-16px"
                linktext={errorMessage()}
              />
            )}
            <div
              style={{ marginBottom: "8px", maxWidth: "460px" }}
              className={
                i18n.language === "arabic" ? "phone-text-arabic" : "phone-text"
              }
            >
              {t(label)}
            </div>
            <Grid container spacing={0}>
              <Grid xs={12}>
                <MobileNumberInput
                  formik={formikForm}
                  codeFieldProps={{
                    value: formikForm.values.code,
                    onChange: formikForm.handleChange,
                    name: "code"
                  }}
                  phoneFieldProps={{
                    value: formikForm.values.phoneNumber,
                    onChange: formikForm.handleChange,
                    name: "phoneNumber",
                    isValid: formikForm.isValid && formikForm.dirty
                  }}
                />
              </Grid>
            </Grid>
            {!loginKey && (
              <Grid
                container
                spacing={1}
                className={
                  i18n.language === "arabic"
                    ? "remember-signin-arabic"
                    : "remember-signin"
                }
              >
                <Grid item xs={10}>
                  <div>{t("Remember sign in details")}</div>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    direction: i18n.language === "arabic" ? "ltr" : "rtl"
                  }}
                >
                  {i18n.language === "arabic" ? (
                    <SwitchbuttonReverse
                      checked={active}
                      onChange={handleChangeSwitchButton}
                    />
                  ) : (
                    <SwitchButton
                      checked={active}
                      onChange={handleChangeSwitchButton}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        <div
          className={loginKey ? "btnbox-pn-amber" : "btnbox-pn"}
          style={style}
        >
          {isLoading ? (
            <ColorLoadingButton
              size="small"
              loading={true}
              variant="outlined"
              disabled
              type="submit"
              className="button-submit-loading"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                maxWidth: loginKey ? "460px" : "100%",
                width: "100%",
                marginTop: "24px"
              }}
            />
          ) : (
            <ColorButton
              variant="contained"
              type="submit"
              className="button-submit-pn"
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                maxWidth: loginKey ? "460px" : "100%",
                width: "100%",
                marginTop: "24px"
              }}
            >
              {t("Continue")}
            </ColorButton>
          )}
        </div>
      </div>
    </form>
  );
};

export default MobileNumberForm;
