import React, { useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";

import MenuNavIcon from "Assets/images/MenuNavIcon.svg";
import MenuNavIconBlack from "Assets/images/MenuNavIconBlack.svg";
import ArrowDownIcon from "Components/Icon/ArrowDownIcon";
import { useGetAccount } from "services/account/account.query";
import { PRIVATE_TOKEN_PERSIST_KEY, LOCAL_STORAGE_EVENT_NAME } from "constants";

const AuthanticateButtons = (props) => {
  const { isLoading } = useGetAccount();
  const { t, i18n } = useTranslation();

  const {
    propsclass,
    isHover,
    userData,
    token,
    handleClick,
    handleDrawerToggle,
    defaultTool,
    NavScroll,
    navbar,
    color_definition
  } = props;

  const handleStorageChange = (e) => {
    if (e.key === PRIVATE_TOKEN_PERSIST_KEY && e.newValue === null) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (token && userData) {
      window.addEventListener(LOCAL_STORAGE_EVENT_NAME, handleStorageChange);
    }
    return () => {
      window.removeEventListener(LOCAL_STORAGE_EVENT_NAME, handleStorageChange);
    };
  }, [token, userData]);

  const DefaultTool = () => {
    if (defaultTool) {
      return CustomSelectIconLightBg();
    } else
      return !NavScroll && !isHover
        ? CustomSelectIcon()
        : CustomSelectIconLightBg();
  };
  const borderSettings = () => {
    if (defaultTool) {
      return "1px solid #000000";
    } else
      return isHover || NavScroll ? "1px solid #000000" : "1px solid white";
  };
  const {
    normal_link,
    hover_link,
    cta_text_normal,
    cta_text_hover,
    cta_fill_normal,
    cta_fill_hover
  } = color_definition || {};

  let lastname = userData?.Customer?.[0]?.PersonalInformation?.LastName;
  let firstname = userData?.Customer?.[0]?.PersonalInformation?.FirstName;

  const nameControl = () => {
    if (lastname?.length > 10 || firstname?.length > 10) {
      const firstCharLastNme = lastname.charAt(0);
      const firstCharName = firstname.slice(0, 10);
      const modifiedlastname =
        firstCharName + ".".repeat(3) + " " + firstCharLastNme + ".".repeat(3);
      return modifiedlastname;
    } else return firstname + " " + lastname;
  };

  const authArrow = () => {
    if (navbar === "default") {
      return isHover || NavScroll ? "black" : "white";
    } else return "black";
  };

  const authanticeButtons = () => {
    if (token && userData && !isLoading) {
      return (
        <>
          <Button
            onClick={handleClick}
            sx={{
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalRegular"
                  : "MontserratRegular",
              fontSize: i18n.language === "arabic" ? "17px" : "14px",
              display: "flex",
              borderRadius: "27px",
              maxHeight: { xs: "36px", md: "32px" },
              lineHeight: "130%",
              textAlign: "right",
              color: "#000000",
              padding: "6px 20px",
              border: {
                xs: "none",
                lg: borderSettings()
              },
              textTransform: "none",
              width: "auto",
              minWidth: "167px",
              position: "relative"
            }}
            className={propsclass.navButton}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                padding: "0",
                flexDirection:
                  i18n.language === "arabic" ? "row-reverse" : "row"
              }}
            >
              {" "}
              <div>{nameControl()}</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: i18n.language === "arabic" ? "22.09px" : "18.188px",
                  width: i18n.language === "arabic" ? "22.09px" : "18.188px",
                  paddingBottom: 3
                }}
              >
                {" "}
                <ArrowDownIcon color={authArrow()} />
              </div>
            </Box>
          </Button>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { lg: "none" },
              color: !NavScroll && !isHover ? "white" : "black"
            }}
          >
            {DefaultTool()}
          </IconButton>
        </>
      );
    } else if (!token && !userData) {
      return (
        <>
          <Button
            component={Link}
            to="/sign-in"
            sx={{
              color: "#fff",
              paddingLeft: "32px",
              paddingRight: "32px",
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalRegular"
                  : "MontserratRegular",
              fontSize: i18n.language === "arabic" ? "17px" : "14px"
            }}
            className={propsclass.navButton}
            style={{
              color: !NavScroll && !isHover ? normal_link : hover_link
            }}
          >
            {t("Sign in")}
          </Button>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { lg: "none" }
            }}
          >
            {DefaultTool()}
          </IconButton>
          <Button
            component={Link}
            to="/sign-up"
            sx={{
              color: "#fff",
              paddingLeft:
                i18n.language === "arabic"
                  ? { xs: "18px", xl: "32px" }
                  : { xs: "24px", xl: "32px" },
              paddingRight:
                i18n.language === "arabic"
                  ? { xs: "18px", xl: "32px" }
                  : { xs: "24px", xl: "32px" },
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalMedium"
                  : "MontserratMedium",
              fontSize: i18n.language === "arabic" ? "17px" : "14px",
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
              alignItems: "center"
            }}
            className={propsclass.createAccount}
            style={{
              color: !NavScroll && !isHover ? cta_text_normal : cta_text_hover,
              backgroundColor:
                !NavScroll && !isHover ? cta_fill_normal : cta_fill_hover
            }}
          >
            {t("Create account")}
          </Button>
        </>
      );
    } else if (!token && userData) {
      return (
        <>
          <Button
            component={Link}
            to="/sign-in"
            sx={{
              color: "#fff",
              paddingLeft: "32px",
              paddingRight: "32px",
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalRegular"
                  : "MontserratRegular",
              fontSize: i18n.language === "arabic" ? "17px" : "14px"
            }}
            className={propsclass.navButton}
            style={{
              color: !NavScroll && !isHover ? normal_link : hover_link
            }}
          >
            {t("Sign in")}
          </Button>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { lg: "none" }
            }}
          >
            {DefaultTool()}
          </IconButton>
          <Button
            component={Link}
            to="/sign-up"
            sx={{
              color: "#fff",
              paddingLeft:
                i18n.language === "arabic"
                  ? { xs: "18px", xl: "32px" }
                  : { xs: "24px", xl: "32px" },
              paddingRight:
                i18n.language === "arabic"
                  ? { xs: "18px", xl: "32px" }
                  : { xs: "24px", xl: "32px" },
              fontFamily:
                i18n.language === "arabic"
                  ? "TajawalMedium"
                  : "MontserratMedium",
              fontSize: i18n.language === "arabic" ? "17px" : "14px",
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
              alignItems: "center"
            }}
            className={propsclass.createAccount}
            style={{
              color: !NavScroll && !isHover ? cta_text_normal : cta_text_hover,
              backgroundColor:
                !NavScroll && !isHover ? cta_fill_normal : cta_fill_hover
            }}
          >
            {t("Create account")}
          </Button>
        </>
      );
    } else if (isLoading) {
      <Box sx={{ display: "flex" }}>
        <CircularProgress
          size={"25px"}
          style={{
            color: defaultTool || isHover || NavScroll ? "black" : "white"
          }}
        />
      </Box>;
    }
  };
  const CustomSelectIcon = (props) => {
    return <img src={MenuNavIcon} alt="MenuNavIcon" {...props} />;
  };
  const CustomSelectIconLightBg = (props) => {
    return <img src={MenuNavIconBlack} alt="MenuNavIconBlack" {...props} />;
  };
  return isLoading && token ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        size={"25px"}
        style={{
          color: defaultTool || isHover || NavScroll ? "black" : "white"
        }}
      />
    </Box>
  ) : (
    authanticeButtons()
  );
};

export default AuthanticateButtons;
