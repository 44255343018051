import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import WprightPage from "Components/Authanticate/WprightPage";
import HistoryButton from "Components/HistoryButton";
import { clearRouteCntrl, setRouteCntrl } from "store/authanticateSlice";
import { useIsMobileView } from "hooks";
import { useSelectAccountCustomers } from "hooks/useSelectAccountCustomers";
import { setBearerToken } from "services/apiClient";
import {
  usePostExistEmailOtp,
  usePostExistEmailValidate
} from "services/auth/auth.query";
import {
  MAIL_OTP_SECURE_CODE_DURATION_IN_SECONDS,
  SECURE_CODE_LENGTH
} from "../Account/constants";
import { saveToken } from "utils/authStorage";
import CustomTextField from "../Account/components/CustomTextField";
import Timer from "../Account/components/timer/Timer";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";
import { useDataLayer } from "hooks/useDataLayer";

const StyleTextArabic = {
  fontFamily: "TajawalRegular",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "20px",
  margin: 0,
  padding: 0
};

const StyleTextEnglish = {
  fontFamily: "MontserratRegular",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  margin: 0,
  padding: 0
};

const validationSchema = (t) =>
  yup.object({
    code: yup
      .string()
      .length(
        SECURE_CODE_LENGTH,
        (params) => `Your code must be ${params.length}-digits`
      )
      .required(t("The code is required"))
  });

const EmailOTP = () => {
  const referalCode = useLocation()?.state?.referalCode;
  const isExternal = useSelector((state) => state.authanticate.isExternal);
  const [isTimerExpired, setIsTimerExpired] = useState();
  const { t, i18n } = useTranslation();
  const [validationKey, setValidationKey] = useState(false);
  const isMobileView = useIsMobileView();
  const routeCntrl = useSelector((state) => state.authanticate.routeCntrl);
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};
  const personalTrue = routeCntrl.some((item) => item.multipleOTP === true);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setCustomers } = useSelectAccountCustomers();
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const [customerError, setCustomerError] = useState(false);
  const { setSignUpEvent } = useDataLayer();

  // navigateProtect():
  // It determines whether the user should navigate to the email registration page or continue with the sign-up process.
  // If personalTrue is false, it navigates to the email registration page (/sign-up/email-registration).
  // If personalTrue is true, it sets showComponent to true and defines a new route with multiple steps (multiple forms) for the sign-up process.

  // resendCode():
  // This function appears to be responsible for resending an OTP (One-Time Password) code to the user's email for verification.
  // It makes an asynchronous API request to postExistEmailOtp with the email stored in the state.email.
  // If the request is successful, it retrieves the validation_key from the response and sets it in the component's state.
  // If an error occurs during the request, it returns false to indicate that the OTP resend was unsuccessful.

  const {
    mutate: postExistEmailValidate,
    isLoading: isPostExistEmailValidateLoading,
    error: existEmailValidateError,
    reset: resetPostExistEmailValidate
  } = usePostExistEmailValidate({
    onSuccess: ({ customers, token }) => {
      if (customers?.length > 1) {
        setCustomerError(true);
        return;
      }
      setCustomers(customers);
      saveToken(token);
      setBearerToken(token);
      const newRoute = { email: true };
      dispatch(setRouteCntrl(newRoute));

      const {
        FirstName = "",
        LastName = "",
        Gender = "",
        DateOfBirth = ""
      } = customers?.[0]?.PersonalInformation || {};

      setSignUpEvent("enter_email_success");
      navigate(`/sign-up/personal-details`, {
        state: {
          initialPersonalDetails: {
            firstName: FirstName,
            lastName: LastName,
            gender: Gender,
            date: DateOfBirth,
            terms: false,
            data_sharing: false,
            referalCode
          },
          email: state.email
        }
      });
    },
    onError: (error) => {
      if (error?.response?.status === 429) {
        setTimeout(() => {
          navigate("/sign-up/email-registration");
        }, 1500);
      }
    }
  });

  const navigateProtect = () => {
    if (!personalTrue) {
      navigate(`/sign-up/email-registration`);
    } else {
      setShowComponent(true);
      const newRoute = { multiple: true };
      dispatch(clearRouteCntrl());
      dispatch(setRouteCntrl(newRoute));
    }
  };
  useEffect(() => {
    navigateProtect();
  }, []);
  useEffect(() => {
    if (state) {
      setValidationKey(state.validation_key);
    }
    return () => {};
  }, [state]);

  const {
    mutateAsync: postExistEmailOtp,
    error: existEmailOtpError,
    isLoading: isPostExistEmailOtpLoading
  } = usePostExistEmailOtp({
    onSuccess: () => {},
    onError: () => {}
  });

  const secureCodeForm = useFormik({
    initialValues: {
      code: ""
    },
    validationSchema: validationSchema(t),
    onSubmit: ({ code }) => {
      postExistEmailValidate({
        validation_key: validationKey,
        validation_code: code
      });
    },
    validateOnBlur: false
  });

  const resendCode = async () => {
    try {
      const response = await postExistEmailOtp({ email: state.email });
      setValidationKey(response.validation_key);

      return true;
    } catch (error) {
      return false;
    }
  };

  const serverErrorMessage =
    (existEmailOtpError || existEmailValidateError)?.response?.data?.message ||
    (customerError ? t("multipleCustomerError") : "");
  const formErrorMessage =
    secureCodeForm.touched.code && secureCodeForm.errors.code;
  const errorMessage = serverErrorMessage || formErrorMessage;
  const error_code = (existEmailOtpError || existEmailValidateError)?.response
    ?.data?.error_code;
  const isLoading = isPostExistEmailValidateLoading;
  return (
    <>
      {showComponent && (
        <div className="WPmain">
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: background || "#FFFFFF"
            }}
          >
            <HistoryButton
              image="return"
              Left={i18n.language === "arabic" ? "auto" : 25}
              Top={25}
              Right={i18n.language === "arabic" ? 25 : "auto"}
              path={`/sign-up/email-registration`}
              referalCode={referalCode}
            />
            {!isExternal && (
              <HistoryButton
                Right={i18n.language === "arabic" ? "auto" : 25}
                Top={25}
                Left={i18n.language === "arabic" ? 25 : "auto"}
                path={"path"}
                referalCode={referalCode}
              />
            )}
            <div className="left-page-form">
              <form onSubmit={secureCodeForm.handleSubmit}>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "login-head-pn-arabic"
                      : "login-head-pn"
                  }
                  style={{ marginBottom: isMobileView ? "16px" : "24px" }}
                >
                  <span>{t("Verify you own this email")}</span>
                </div>
                <p
                  style={
                    i18n.language === "arabic"
                      ? StyleTextArabic
                      : StyleTextEnglish
                  }
                >
                  {t("To protect your account") + " : "}
                  {<b>{state?.email}</b>}
                  <div></div>
                </p>
                {errorMessage && (
                  <AlertWarning
                    text={
                      error_code === 6900 ? t("expiredOTP") : t(errorMessage)
                    }
                  />
                )}
                <p
                  style={{
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalMedium"
                        : "MontserratMedium",
                    fontSize: i18n.language === "arabic" ? "17px" : "14px",
                    lineHeight: "20px",
                    margin: 0,
                    marginTop: "28px",
                    marginBottom: "8px",
                    padding: 0
                  }}
                >
                  {t("Your verification code should be 6 digits")}
                </p>
                <CustomTextField
                  placeholder={t("Enter your code")}
                  name={"code"}
                  onChange={(e) => {
                    const mycode = e.target.value.replace(/[^\d]/g, "");
                    e.target.value = mycode;
                    secureCodeForm.handleChange(e);
                    existEmailValidateError && resetPostExistEmailValidate();
                  }}
                  value={secureCodeForm.values.code}
                  autoComplete="off"
                  sx={{
                    width: "100% !important",
                    marginBottom: "12px"
                  }}
                  inputProps={{
                    maxLength: 6,
                    inputMode: "numeric"
                  }}
                />
                <p
                  style={{
                    fontFamily: "MontserratMedium",
                    fontSize: "14px",
                    lineHeight: "20px",
                    margin: "0px",
                    padding: "0px"
                  }}
                >
                  <Timer
                    start={MAIL_OTP_SECURE_CODE_DURATION_IN_SECONDS}
                    onReset={async () => {
                      return await resendCode();
                    }}
                    onStartTimer={() => {
                      setIsTimerExpired(false);
                    }}
                    onTimeExpired={() => {
                      setIsTimerExpired(true);
                    }}
                  />
                </p>
                <div>
                  <Box
                    className="btnbox-pn"
                    sx={{ marginTop: errorMessage === true ? "2px" : "20px" }}
                  >
                    {isLoading || isPostExistEmailOtpLoading ? (
                      <ColorLoadingButton
                        size="small"
                        loading={true}
                        variant="outlined"
                        disabled
                        type="submit"
                        className="button-submit-loading"
                        style={{
                          fontFamily:
                            i18n.language === "arabic"
                              ? "TajawalMedium"
                              : "MontserratMedium",
                          maxWidth: "100%"
                        }}
                      />
                    ) : (
                      <ColorButton
                        isLoading={isLoading || isPostExistEmailOtpLoading}
                        // disabled={isTimerExpired}
                        type="submit"
                        variant="contained"
                        className="button-submit-pn"
                      >
                        {t("Continue")}
                      </ColorButton>
                    )}
                  </Box>
                </div>
              </form>
            </div>
          </div>
          <WprightPage />
        </div>
      )}
    </>
  );
};

export default EmailOTP;
