import { useQuery } from "@tanstack/react-query";
import { FAQ } from "../serviceKeys";
import * as Requests from "./faq.requests";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetFaq = () => {
  const tierValue = useTierValue();
  return useQuery([FAQ, tierValue], () => {
    return Requests.getFaq({ segment: tierValue });
  });
};
