import { create } from "zustand";
import { queryClient } from "services/queryClient";
import {
  getInfoFromToken,
  onRemovePrivateToken,
  onSetPrivateToken
} from "utils/authStorage";
import { TIER_LOCAL_STORAGE_KEY } from "constants";

// setUser: Used to set the user's authentication status. It takes a token, extracts user information from it (using getInfoFromToken), and securely stores the token in local storage (using onSetPrivateToken).
// removeUser: Logs the user out by removing the user's authentication information, clearing user data, and removing specific queries from the query client (using queryClient.removeQueries(["account"]). It sets the user state back to null.

export const useUserStore = create((set) => ({
  user: null,
  tier: localStorage.getItem(TIER_LOCAL_STORAGE_KEY) || null,

  setUser: (token) =>
    set(() => {
      onSetPrivateToken(token);
      return { user: getInfoFromToken(token) };
    }),

  // Fetch the account details, update tier in state and localStorage
  setTier: (tier) =>
    set(() => {
      localStorage.setItem(TIER_LOCAL_STORAGE_KEY, tier); // Update localStorage
      return { tier }; // Update store with the tier
    }),

  removeUser: () =>
    set(() => {
      onRemovePrivateToken();
      queryClient.removeQueries(["account"]);
      localStorage.removeItem(TIER_LOCAL_STORAGE_KEY);

      return {
        user: null,
        tier: null
      };
    })
}));

export const useUserIsLoggedIn = () => {
  const user = useUserStore((state) => state.user);

  return !!user;
};

// Hook to get the user's tier from the store or localStorage
export const useUserTier = () => {
  const tier = useUserStore((state) => state.tier);
  return tier;
};
