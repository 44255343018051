import { useEffect, useRef, useState } from "react";

const getRemainingTime = (value) => {
  const seconds = value % 60;
  const minutes = Math.floor(value / 60) % 60;

  return {
    seconds: seconds > 9 ? seconds : "0" + seconds,
    minutes: minutes > 9 || minutes <= 0 ? minutes : "0" + minutes
  };
};

// It takes an options object with the following properties:

// start: The initial timer value in seconds.
// onTimeExpired: An optional callback function to execute when the timer expires.
// onStartTimer: An optional callback function to execute when the timer starts.
// The getRemainingTime function calculates the remaining time in minutes and seconds based on the timer value in seconds. It returns an object with minutes and seconds properties formatted as strings.

// Within the useTimer hook, it initializes various state variables and refs:

// setRefresh is a state updater function that forces a re-render when called.
// timerRef is a ref that stores the current timer value.
// intervalIdRef is a ref that stores the ID of the timer interval.
// isExpired is a state variable that tracks whether the timer has expired.
// The startTimer function is responsible for starting the timer. It clears any existing timer interval if one is active, sets up a new interval that decrements the timer value each second, and checks if the timer has reached zero. If the timer is zero or less, it calls the onTimeExpired callback (if provided), marks the timer as expired, and clears the interval.

// The useEffect hook is used to handle the initial setup of the timer when the component first mounts. It resets the isExpired state, calls the onStartTimer callback (if provided), and starts the timer using the startTimer function. It also returns a cleanup function that clears the interval when the component unmounts.

// The resetTimer function resets the timer to its initial value, clears the "expired" state, and starts the timer again.

// The getRemainingTime function is used to calculate the remaining minutes and seconds based on the current timer value.

// The hook returns an object containing three properties:

// timer: A string representation of the remaining time in "minutes:seconds" format.
// resetTimer: A function to reset the timer.
// isExpired: A boolean indicating whether the timer has expired.

const useTimer = ({ start = 0, onTimeExpired, onStartTimer }) => {
  const [, setRefresh] = useState({});
  const timerRef = useRef(start);
  const intervalIdRef = useRef();
  const [isExpired, setIsExpired] = useState(false);

  const startTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    intervalIdRef.current = setInterval(() => {
      if (timerRef.current > 0) {
        timerRef.current = timerRef.current - 1;
      }
      if (timerRef.current <= 0) {
        onTimeExpired?.();
        setIsExpired(true);
        clearInterval(intervalIdRef.current);
      }
      setRefresh({});
    }, 1000);
    return () => {
      clearInterval(intervalIdRef.current);
    };
  };

  useEffect(() => {
    setIsExpired(false);
    onStartTimer?.();
    const returnFunc = startTimer();
    return () => {
      returnFunc();
    };
  }, []);

  const resetTimer = () => {
    setIsExpired(false);
    timerRef.current = start;
    setRefresh(start);
    onStartTimer?.();
    startTimer();
  };

  const { minutes, seconds } = getRemainingTime(timerRef.current);

  return { timer: minutes + ":" + seconds, resetTimer, isExpired };
};

export default useTimer;
