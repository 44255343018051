import { SELECTED_COUNTRY } from "constants";

// Input: The function doesn't take any parameters.

// Local Storage Item Removal: It uses the localStorage.removeItem method to remove an item from local storage. The item to be removed is identified by the key SELECTED_COUNTRY, which is defined in the constants module.

// Outcome: After executing this function, the item associated with the key SELECTED_COUNTRY is removed from the local storage, effectively clearing or deleting that particular piece of data.

export const removeSelectedCountryFromStorage = () => {
  localStorage.removeItem(SELECTED_COUNTRY);
};
