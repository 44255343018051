export const CategoryListFilterEnums = Object.freeze({
  BRANDSANDSTORES: "BrandsAndStores",
  EVENTS: "Events",
  OFFERS: "Offers",
  ALL: "All"
});

export const DataSourceEnums = Object.freeze({
  BRAND: "BRAND",
  EVENTS: "EVENTS",
  OFFERS: "OFFERS",
  MANUAL: "MANUAL",
  EDITORIAL: "EDITORIAL"
});



 
export const LinkTypeEnums = Object.freeze({
  CONTENT: "CONTENT",
  EXTERNAL: "EXTERNAL",
  STATIC: "STATIC",
  MODULE: "MODULE",
  BRAND: "BRAND",
  EVENTS: "EVENTS",
  OFFERS: "OFFERS",
  EDITORIAL: "EDITORIAL",
  HOWITWORKS:"HOWITWORKS",
  AMBERAPP:"AMBERAPP",
  DAHBOARD:"DAHBOARD",
  PURCHASEHISTORY:"PURCHASEHISTORY"
});

export const RewardTypeEnums = Object.freeze({
  CUPONS: "COUPONS",
  PROMOTIONS: "PROMOTIONS"
});

export const RewardCardStatusEnums = Object.freeze({
  DELETED: "DELETED",
  REDEEM: "REDEEM",
  AVAILABLE: "AVAILABLE",
  ACTIVE: "ACTIVE"
});
export const LinkTypeKeyEnums = Object.keys(LinkTypeEnums);
const modifiedLinkTypeEnums = { ...LinkTypeEnums };
delete modifiedLinkTypeEnums.MODULE;
export const LinkTypeKeyNoModuleEnums = Object.keys(modifiedLinkTypeEnums);

 

export const AnchorLinkEnums = Object.freeze({
  POINTS_CALCULATOR: "points-calculator",
  TIERS_AND_BENEFITS: "tiers-and-benefits",
  HOW_IT_WORKS: "how-it-works",
  OFFERS: "offers",
  EVENTS: "events",
  FAQS: "faqs",
  CONTACT_US: "contact-us",
  CATEGORIES: "categories"
});

export const PointCalculatorEnums = Object.freeze({
  EARN_POINTS: "Earn Points",
  REDEEM: "Redeem"
});
export const PointCalculatorEnumsArabic = Object.freeze({
  EARN_POINTS: "اكسب نقاط أمبر",
  REDEEM: "استبدل نقاط أمبر"
});
export const ContentTypesEnums = Object.freeze({
  CAROUSEL: "carousel",
  ITEM_CAROUSEL: "item-carousel",
  GLOBAL_CONFIGURATION: "global-configuration",
  WEB_HOMEPAGE_SLIDER: "web-homepage-slider",
  WEB_HOMEPAGE_MAIN_NAVIGATION: "web-homepage-main-navigation",
  BRAND_ITEM_CAROUSEL: "brand-item-carousel",
  CONTENT_DESCRIPTION: "content-description",
  STORE_LOCATIONS: "store-locations",
  RELATED_BRAND_ARTICLES: "related-brand-articles",
  CONTENT_MAIN_BANNER: "content-main-banner",
  SOCIAL_MEDIA_HANDLES: "social-media-handles",
  ACTION_BUTTON: "action-button",
  SCHEDULE: "schedule",
  EVENT_LOCATION: "event-location",
  LOCATION_MAP: "location-map",
  AMBER_IMAGE: "amber-image",
  MINI_NAVIGATION: "mini-navigation",
  OFFERS_CONTENT_PAGE: "offers-content-page",
  OFFERS_CONTENT_PAGE_BANNER: "offers-content-page-banner",
  OFFERS_CONTENT_PAGE_DESCRIPTION: "offers-content-page-description",
  FEATURED_ITEM: "featured-item",
  MEDIA_BLOCK: "media-block",
  BRAND_SPONSOR: "brand-sponsor",
  EDITORIAL_CONTENT_PAGE: "editorial-content-page"
});
