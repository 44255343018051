import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Grid, Box } from "@mui/material";
import { Pagination, Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import { useDataLayer, ITEMTYPE_MAP } from "hooks/useDataLayer";

const ButtonGroup = ({
  activeButton,
  setActiveButton,
  buttonFilter = [],
  path,
  marginbottom,
  margintp,
  data,
  color_definition,
  months,
  page,
  currentMonthId,
  setCurrentMonthId
}) => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const { setFilterEvent } = useDataLayer();
  const tabletMax = useMediaQuery("(max-width:1337px)");
  const catgoryGroup = months ? data?.months : data?.categories;
  const categories = [...buttonFilter];
  const RecordCount = categories?.some((category) => category?.RecordCount > 0);

  //The mappedCategories function is responsible for creating an array of category objects based on the catgoryGroup data, while considering the application's selected language.
  // It maps the categories and generates objects with id and name properties. The value of the name property is determined by the selected language,
  // with Arabic names used for "arabic" language and English names for "en" language, resulting in an array of category objects tailored to the chosen language.

  const mappedCategories = catgoryGroup?.map((item) => {
    if (i18n.language === "arabic") {
      return {
        id: item?.id,
        name: item?.name?.ar
      };
    } else {
      return {
        id: item?.id,
        name: item?.name?.en
      };
    }
  });

  // Current Month Selection
  useEffect(() => {
    const hasRecordItems = categories?.filter((item) => item?.RecordCount >= 0);
    const getCurrentMonth = new Date().getMonth() + 1;
    hasRecordItems?.map((item, index) => {
      if (item.id == getCurrentMonth) {
        setCurrentMonthId(item.id); // FirstLoad set Current Month
        setTimeout(() => {
          setActiveButton(item.id);
        }, 100);
      }
    });
  }, []);

  //The handleClick function is responsible for managing button clicks and updating state variables. It sets the active button,
  // determines the page type based on the page name, and extracts the category name from the mappedCategories array to be used for event filtering.

  function handleClick(button) {
    setActiveButton(button);

    setFilterEvent({
      page: pathname,
      type: ITEMTYPE_MAP[page?.toLocaleUpperCase()],
      name: mappedCategories?.find((x) => x.id === button)?.name
    });
  }
  return (
    <>
      {RecordCount && (
        <>
          {" "}
          <Grid
            container
            rowSpacing={0}
            columnSpacing={{ xs: 0 }}
            sx={{
              marginTop: path === "events" ? margintp : "0px",
              backgroundColor: color_definition?.background_color
                ? color_definition?.background_color
                : "#FFFFFF",
              minHeight: "100%",
              alignContent: "center",
              justifyContent: "flex-start",
              position: "relative",
              display: tabletMax ? "flex" : "none",
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              paddingRight: { xs: "20px", sm: "11.11%" },
              paddingLeft: { xs: "20px", sm: "11.11%" }
            }}
          >
            <Swiper
              direction="horizontal"
              slidesPerView="auto"
              spaceBetween={30}
              loop={false}
              loopFillGroupWithBlank={true}
              scrollbar={{ draggable: true }}
              autoplay={false}
              autoplayDisableOnInteraction={false}
              navigation={false}
              pagination={false}
              modules={[Pagination, Navigation]}
              className="mySwiper swiperMin swiperMinTop"
              slideMargin={0}
              dir={i18n.language === "arabic" ? "rtl" : "ltr"}
              style={{
                paddingTop: "24px",
                paddingBottom: "24px",
                top: "0px"
              }}
            >
              <Box
                className="brand-store-btngroup"
                sx={{
                  flexDirection:
                    i18n.language === "arabic" ? "row-reverse" : "row"
                }}
              >
                {categories?.map((item, index) => (
                  <SwiperSlide className="button-group-slide" key={item.id}>
                    <button
                      disabled={
                        item.RecordCount === 0 && item.id !== currentMonthId
                          ? true
                          : false
                      }
                      className={
                        (item.id === activeButton
                          ? "button-group-btn button-group-btn-active"
                          : "button-group-btn") +
                        (mappedCategories?.find(
                          (value) =>
                            value.id === item.id &&
                            item.RecordCount === 0 &&
                            value.id !== currentMonthId
                        )
                          ? " button-group-btn button-group-btn-disable"
                          : "")
                      }
                      onClick={() => handleClick(item.id)}
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalRegular"
                            : "MontserratRegular",
                        fontSize: i18n.language === "arabic" ? "17px" : "14px"
                      }}
                    >
                      {mappedCategories?.find((x) => x.id === item.id)?.name}
                    </button>
                  </SwiperSlide>
                ))}
              </Box>
            </Swiper>
          </Grid>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={{ xs: 0 }}
            style={{
              paddingTop: margintp || "48px",
              minHeight: "100%",
              justifyContent: "space-between",
              position: "relative",
              alignContent: "center",
              height: "100%",
              paddingBottom: marginbottom || "32px",
              backgroundColor: color_definition?.background_color
                ? color_definition?.background_color
                : "#FFFFFF"
            }}
            sx={{
              display: tabletMax ? "none" : "flex",
              paddingRight: { xs: "20px", sm: "11.11%" },
              paddingLeft: { xs: "20px", sm: "11.11%" },
              flexDirection: i18n.language === "arabic" ? "row-reverse" : "row"
            }}
          >
            <Box
              className="brand-store-btngroup"
              sx={{
                direction: i18n.language === "arabic" ? "rtl" : "ltr"
              }}
            >
              {categories?.map((item, index) => (
                <button
                  disabled={
                    item.RecordCount === 0 && item.id !== currentMonthId
                      ? true
                      : false
                  }
                  key={item.id}
                  className={
                    i18n.language === "arabic"
                      ? (item.id === activeButton
                          ? "button-group-btn-arabic button-group-btn-active"
                          : "button-group-btn-arabic" + " ") +
                        (mappedCategories?.find(
                          (value) =>
                            value.id === item.id &&
                            item.RecordCount === 0 &&
                            value.id !== currentMonthId
                        )
                          ? "button-group-btn-arabic button-group-btn-disable"
                          : "")
                      : (item.id === activeButton
                          ? "button-group-btn button-group-btn-active"
                          : "button-group-btn") +
                        (mappedCategories?.find(
                          (value) =>
                            value.id === item.id &&
                            item.RecordCount === 0 &&
                            value.id !== currentMonthId
                        )
                          ? " button-group-btn button-group-btn-disable"
                          : "")
                  }
                  onClick={() => handleClick(item.id)}
                >
                  {mappedCategories?.find((x) => x.id === item.id)?.name}
                </button>
              ))}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default ButtonGroup;
