import { createSlice } from "@reduxjs/toolkit";

const initialState = "";
export const pathPreviousSlice = createSlice({
  name: "pathPrevious",
  initialState,
  reducers: {
    selectPathPrevious: (state, action) => {
      state = action.payload;
      return state;
    }
  }
});

export const { selectPathPrevious } = pathPreviousSlice.actions;
export default pathPreviousSlice.reducer;
