import { useQuery } from "@tanstack/react-query";
import * as Requests from "./events.requests";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetEvents = () => {
  const tierValue = useTierValue();
  return useQuery(
    ["fetch-events-and-offers-content",tierValue],
    () => {
      return Requests.getEvents({ segment: tierValue });
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

export const useGetEventsById = ({ id }) => {
  return useQuery(
    ["fetch-events-content", id],
    () => {
      return Requests.getEventsById({ id });
    },
    {
      enabled: !!id
    }
  );
};

export const useGetEventsLanding = ({ content_type }) => {
  return useQuery(["fetch-events-content"], () => {
    return Requests.getEventsLanding({ content_type });
  });
};
