import { Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AlertWarning from "Assets/AlertWarning/AlertWarning";
import ColorButton from "Assets/ColorButton/ColorButton";
import ColorLoadingButton from "Assets/ColorButton/ColorLoadingButton";
import ScrollDialog from "Assets/CustomComponents/DialogFull";
import WprightPage from "Components/Authanticate/WprightPage";
import { clearRouteCntrl } from "store/authanticateSlice";
import {
  useCountryTopList,
  useRegisterLoginAccount
} from "hooks/onBoardingHooks";
import { useUserStore } from "hooks/useUser";
import { usePostSetCountry } from "services/account/account.query";
import { getToken } from "utils/authStorage";
import { setSelectedCountryCodeToStorage } from "utils/setSelectedCountryCodeToStorage";
import { setSelectedCountryToStorage } from "utils/setSelectedCountryToStorage";
import { useDataLayer } from "hooks/useDataLayer";
import LazyImage from "Components/LazyLoad/LazyImage";

const styleCheck = {
  backgroundColor: "white",
  display: "flex",
  width: "20px",
  height: "20px",
  margin: "0px",
  cursor: "pointer",
  borderRadius: " 50%",
  border: "1px solid #90969E",
  justifyContent: "center",
  alignItems: "center"
};
const styleCheckActive = {
  backgroundColor: "black",
  border: "1px solid black",
  display: "flex",
  width: "20px",
  height: "20px",
  cursor: "pointer",
  borderRadius: " 50%",
  justifyContent: "center",
  alignItems: "center"
};
const dividerArabic = {
  marginLeft: "0px",
  marginRight: "34px",
  borderColor: "#DFE1EA"
};
const dividerEnglish = {
  marginLeft: "34px",
  marginRight: "5px",
  borderColor: "#DFE1EA"
};

const Selectcountry = () => {
  const referalCode = useLocation()?.state?.referalCode;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const countries = useCountryTopList();
  const { setSignUpEvent } = useDataLayer();
  const {
    routeCntrl,
    phoneNumber,
    phoneCode,
    email,
    isExternal,
    externalDeviceType
  } = useSelector((state) => state.authanticate);
  const personalTrue = routeCntrl.some((item) => item.personal === true);
  const { setUser } = useUserStore();
  const [checkedRadio, setCheckedRadio] = useState(false);
  const [showAlert, setShowalert] = useState(false);
  const [showalertError, setShowalertError] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);
  const mobile = useMediaQuery("(max-width:699px)");
  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};
  const {
    mutateAsync: setCountry,
    error,
    isLoading
  } = usePostSetCountry({ onSuccess: () => {}, onError: () => {} });

  // IdCreater(iso_code): This function creates an ID by combining the provided iso_code with a language code (langCode) based on the current language selected (i18n.language). The result is something like "isoCode-langCode."
  // filterRegion(code): This function searches for a country in a list of countries based on a maxxing_iso_code. It returns information about the matching country, including its flag, iso_code, and name.
  // handleChange(): A function that sets the checkedRadio state to true. It might be used for handling a radio button or input element change.
  // handleIndex(index): This function sets the checkedRadio state to true, updates the activeIndex with the provided index, and performs additional actions like storing selected country information to the storage. It looks up the country details by calling the filterRegion function and constructs an object to be stored.
  // handleFirstsubmit(): This function is called when the user submits their selection. It hides error messages (if any) and calls the setCountry function. After successfully setting the country, it performs several actions, including setting the signUpEvent to different values, retrieving the user's token, clearing route control data, and possibly storing a referral code. Finally, it navigates the user to a specific route, typically the main application route.
  // handleRoute(): This function is called when the user proceeds to the next step. If checkedRadio is true, it calls handleFirstsubmit(); otherwise, it displays an alert by setting showalert to true.
  // navigateProtect(): This function checks whether personalTrue is true (indicating that the user has completed a previous step of registration) or not. If not, it navigates the user to the /sign-up/phone-validation route. If personalTrue is true, it sets the showComponent to true, which might indicate that the user is allowed to proceed to the location selection step.

  const IdCreater = (iso_code) => {
    const langCode = i18n.language === "arabic" ? "ar" : "en";
    const text = iso_code.toLowerCase() + "-" + langCode;
    return text;
  };
  const filterRegion = (code) => {
    return countries?.find((country) => country?.maxxing_iso_code === code);
  };
  let handleChange = () => {
    setCheckedRadio(true);
  };
  let handleIndex = (index) => {
    setCheckedRadio(true);
    setActiveIndex(index);
    setSelectedCountryCodeToStorage(index);
    const { flag, iso_code, name } = filterRegion(index) || {};
    setSelectedCountryToStorage({
      action: "FILTER_CONTENT",
      flag: flag,
      id: IdCreater(iso_code),
      iso_code: iso_code,
      name: name,
      title: name
    });
  };
  const handleFirstsubmit = () => {
    setShowalertError(false);
    setShowalert(false);

    setCountry({
      country: activeIndex
    }).then((response) => {
      setSignUpEvent("choose_location");
      setSignUpEvent("complete");

      const token = getToken();
      setUser(token);
      dispatch(clearRouteCntrl());
      referalCode && localStorage.setItem("referralCode", referalCode);
      if (!isExternal) {
        navigate(`/`);
      } else {
        try {
          const valueToSend = {
            action: "signup_complete",
            data: {
              phone: phoneNumber,
              code: phoneCode,
              email: email,
              customerID: response.CustomerID
            }
          };
          if (externalDeviceType === "android") {
            window.CallJavaAdapter &&
              window.CallJavaAdapter.signupAmber(valueToSend);
          }
          if (externalDeviceType === "flutter_apps") {
            window.flutter_inappwebview &&
              window.flutter_inappwebview.callHandler(
                "signupAmber",
                valueToSend
              );
          } else {
            window.webkit.messageHandlers.signupAmber.postMessage(valueToSend);
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  let handleRoute = () => {
    if (checkedRadio) {
      handleFirstsubmit();
    } else {
      setShowalert(true);
    }
  };
  useEffect(() => {
    navigateProtect();
  }, []);
  const navigateProtect = () => {
    if (!personalTrue) {
      navigate(`/sign-up/phone-validation`);
    } else setShowComponent(true);
  };
  return (
    <>
      {showComponent ? (
        <div
          className="WPmain"
          style={{ backgroundColor: background || "#FFFFFF" }}
        >
          <div
            className="WPleftpage"
            style={{
              direction: i18n.language === "arabic" ? "rtl" : "ltr",
              backgroundColor: background || "#FFFFFF"
            }}
          >
            {" "}
            <div className="left-page-form-pd">
              <>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "login-head-pd-arabic "
                      : "login-head-pd"
                  }
                >
                  <span>{t("Last step!")}</span>
                </div>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "selecttext-arabic"
                      : "selecttext"
                  }
                >
                  {t("Set your country to see offers")}
                </div>

                <div
                  className={
                    i18n.language === "arabic"
                      ? "custom-radios-arabic"
                      : "custom-radios"
                  }
                >
                  {countries.map((country) => (
                    <div
                      style={{ cursor: "pointer" }}
                      key={country.maxxing_iso_code}
                      onClick={() => handleIndex(country.maxxing_iso_code)}
                    >
                      <div className="SC-radio-box">
                        <div className="SC-box-1">
                          <div>
                            <img
                              loading="lazy"
                              width={mobile ? "16" : "20"}
                              height={mobile ? "16" : "20"}
                              style={{ borderRadius: "50%" }}
                              src={country.flag}
                              alt=""
                            />
                          </div>
                          <div
                            className="SC-text"
                            style={{
                              fontFamily:
                                i18n.language === "arabic"
                                  ? "TajawalMedium"
                                  : "MontserratMedium"
                            }}
                          >
                            {country?.name}
                          </div>
                        </div>
                        <div className="SC-box-2">
                          <input
                            type="radio"
                            id={country.color}
                            name="color"
                            value={country.color}
                            onChange={handleChange}
                          />
                          <label htmlFor={country.color}>
                            <span
                              style={
                                activeIndex === country.maxxing_iso_code
                                  ? styleCheckActive
                                  : styleCheck
                              }
                            >
                              <LazyImage
                                placeholderSrc={
                                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                }
                                lazyclass="lazy"
                                style={{
                                  opacity:
                                    activeIndex === country.maxxing_iso_code
                                      ? "1"
                                      : "0",
                                  width: "10px",
                                  height: "10px"
                                }}
                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                                alt="Checked Icon"
                              />
                            </span>
                          </label>
                        </div>
                      </div>
                      <Divider
                        style={
                          i18n.language === "arabic"
                            ? dividerArabic
                            : dividerEnglish
                        }
                      />{" "}
                    </div>
                  ))}
                </div>
                {showAlert && (
                  <AlertWarning text={t("You need to select country")} />
                )}
                {showalertError && (
                  <AlertWarning
                    marginbottom="24px"
                    margintop="-16px"
                    text={t(error)}
                  />
                )}
                <ScrollDialog />
                <div className="btnbox-pn" style={{ top: "0px" }}>
                  {" "}
                  {isLoading ? (
                    <ColorLoadingButton
                      size="small"
                      loading={true}
                      variant="outlined"
                      disabled
                      type="submit"
                      className="button-submit-loading"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium",
                        maxWidth: "100%",
                        marginTop: "47px"
                      }}
                    />
                  ) : (
                    <ColorButton
                      variant="contained"
                      style={{
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalMedium"
                            : "MontserratMedium"
                      }}
                      onClick={handleRoute}
                      className="button-submit-pn"
                    >
                      {t("Ok, let’s go!")}
                    </ColorButton>
                  )}
                </div>
              </>
            </div>
          </div>
          <WprightPage />
        </div>
      ) : null}
    </>
  );
};

export default Selectcountry;
