import LoadingButton from "@mui/lab/LoadingButton";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const ColorLoadingButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "34px",
  color: theme.palette.getContrastText(grey[500]),
  backgroundColor: grey[500],
  boxShadow: "none",
  "&:hover": {
    backgroundColor: grey[500],
    boxShadow: "none"
  },
  "&:active": {
    boxShadow: "none"
  }
}));

export default ColorLoadingButton;
