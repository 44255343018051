import { useQuery } from "@tanstack/react-query";
import * as Requests from "./brands.requests";
import {
  ALL_CONTENTS,
  BRANDS_AND_STORES,
  BRANDS_AND_STORES_DETAIL,
  RECORDS_AND_TYPES
} from "../serviceKeys";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetBrands = () => {
  const tierValue = useTierValue();
  return useQuery([BRANDS_AND_STORES, tierValue], () => {
    return Requests.getBrands({ segment: tierValue });
  });
};

export const useGetBrandsLoad = ({ skip, data_source, limit, category }) => {
  const tierValue = useTierValue();
  return useQuery(
    [ALL_CONTENTS, tierValue],
    () => {
      return Requests.getBrandsLoad({ segment: tierValue, skip, data_source, limit, category });
    },
    {
      staleTime: Infinity
    }
  );
};
export const useGetBrandById = ({ id }) => {
  const tierValue = useTierValue();
  return useQuery(
    [BRANDS_AND_STORES_DETAIL, id, tierValue],
    () => {
      return Requests.getBrandById({ id, segment: tierValue });
    },
    {
      enabled: !!id
    }
  );
};

export const useGetRecordIdsAndTypes = ({ data_source }) => {
  const tierValue = useTierValue();
  return useQuery([RECORDS_AND_TYPES, tierValue], () => {
    return Requests.getRecordIdsAndTypes({ segment: tierValue, data_source });
  });
};
