import { SELECTED_COUNTRY } from "constants";

// Input: The function takes a single parameter mappedUserCountry, which is an object containing country-related information to be stored.

// Local Storage Item Storage: It uses the localStorage.setItem method to store the mappedUserCountry object in local storage. The item is stored with the key SELECTED_COUNTRY, which is defined in the constants module. The mappedUserCountry object is first converted to a JSON string using JSON.stringify before storage.

// Outcome: After executing this function, the user's selected country information is stored in the browser's local storage under the key SELECTED_COUNTRY. This information can include properties like the country's flag, ISO code, name, and other details.

export const setSelectedCountryToStorage = (mappedUserCountry) => {
  if (mappedUserCountry) {
    localStorage.setItem(SELECTED_COUNTRY, JSON.stringify(mappedUserCountry));
  }
};
