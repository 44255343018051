import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: localStorage.getItem("language")
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    selectLanguage: (state, action) => {
      state.language = action.payload;
    }
  }
});

export const { selectLanguage } = languageSlice.actions;
export default languageSlice.reducer;
