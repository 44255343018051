import { SELECTED_COUNTRY } from "constants";

// getSelectedCountryFromStorage: This function attempts to retrieve the selected country information from local storage using the key SELECTED_COUNTRY. It does so by calling localStorage.getItem(SELECTED_COUNTRY).

// If the value is found and it's not null, the function attempts to parse it as a JSON object using JSON.parse(selectedCountryAsString) and returns the parsed value. This allows you to store complex data structures, such as country information, in local storage.

// If there's an error during the parsing process (e.g., if the stored value is not a valid JSON string), the function catches the error and returns null.

export const getSelectedCountryFromStorage = () => {
  try {
    const selectedCountryAsString = localStorage.getItem(SELECTED_COUNTRY);
    if (selectedCountryAsString) {
      return JSON.parse(selectedCountryAsString);
    }
  } catch (error) {
    return null;
  }
};
