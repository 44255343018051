import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocationContactInfo } from "./LocationContactInfo";

export const ContactInfoContainer = ({ title, mall = {} }) => {
  const { i18n } = useTranslation();

  return (
    <Box
      className={i18n.language === "arabic" ? "tick-text-arabic" : "tick-text"}
      sx={{
        paddingLeft: "0px",
        paddingRight: "0px",
        textAlign: i18n.language === "arabic" ? "right" : "left"
      }}
    >
      <LocationContactInfo
        title={title}
        address={mall?.address}
        email={mall?.email}
        contactNumber={mall?.contact_number}
        operatingHours={mall.operating_hours}
        clickAndCollect={mall?.click_and_collect}
      />
    </Box>
  );
};
