import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import CustomSelectIcon from "Components/CustomSelectIcon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomFormHelperTextWithTranslation from "../CustomerHelperText";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          marginRight: "10px !important"
        }
      }
    }
  }
});

const theme2 = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: "15px !important"
        }
      }
    }
  }
});

export default function SelectLabels({
  setVal,
  val,
  title,
  selectTitle,
  selectPlaceHolder,
  amberTierMapping,
  errorMessage,
  errorSelect
}) {
  const [isSelectOpen, setSelectOpen] = useState(false);
  const scrollRef = useRef(0);
  const { scrollInf } = useSelector((state) => state.public);

  const handleMenuClose = () => {
    setSelectOpen(false); // Açılır menüyü kapat
  };
  const handleMenuOpen = () => {
    scrollRef.current = scrollInf;
    setSelectOpen(true); // Açılır menüyü aç
  };
  const handleChange = (event) => {
    setVal(event.target.value);
  };

  const { i18n } = useTranslation();

  const menuDisable = {
    textAlign: "left",
    fontFamily: "MontserratRegular",
    fontstyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "130%",
    color: "#90969E",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: { xs: "100%", lg: "480px" },
    height: "41px",
    flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
    backgroundColor: "#FFFFFF"
  };
  const menuDisableArabic = {
    fontFamily: "TajawalRegular",
    fontstyle: "normal",
    fontWeight: "400",
    fontSize: "19px",
    lineHeight: "130%",
    color: "#90969E",
    display: "flex",
    alignItems: "center",
    width: { xs: "100%", lg: "480px" },
    height: "41px",
    flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
    textAlign: i18n.language === "arabic" ? "right" : "left",
    backgroundColor: "#FFFFFF"
  };
  const menuActive = {
    textAlign: "left",
    fontFamily: "MontserratRegular",
    fontstyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "130%",
    color: "#000",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: { xs: "100%", lg: "480px" },
    height: "41px",
    flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
    backgroundColor: "#FFFFFF"
  };
  const menuActiveArabic = {
    fontFamily: "TajawalRegular",
    fontstyle: "normal",
    fontWeight: "400",
    fontSize: "19px",
    lineHeight: "130%",
    color: "#000",
    display: "flex",
    alignItems: "center",
    width: { xs: "100%", lg: "480px" },
    height: "41px",
    flexDirection: i18n.language === "arabic" ? "row-reverse" : "row",
    textAlign: i18n.language === "arabic" ? "right" : "left",
    backgroundColor: "#FFFFFF"
  };
  const stylefunction = () => {
    if (i18n.language === "arabic") {
      return val === 0 ? menuDisableArabic : menuActiveArabic;
    } else return val === 0 ? menuDisable : menuActive;
  };
  useEffect(() => {
    if (isSelectOpen && scrollRef.current !== scrollInf) {
      setSelectOpen(false);
    }
  }, [scrollInf, isSelectOpen, setSelectOpen, scrollRef]);
  return (
    <div>
      <FormControl
        sx={{ mb: 2, minWidth: 120, width: "100%" }}
        className="select-how"
      >
        <div
          className={
            i18n.language === "arabic" ? "select-label-arabic" : "select-label"
          }
          style={{ color: title || "" }}
        >
          {selectTitle}
        </div>
        <ThemeProvider theme={i18n.language === "arabic" ? theme : theme2}>
          <Select
            value={val}
            onChange={handleChange}
            onClose={handleMenuClose}
            onOpen={handleMenuOpen}
            open={isSelectOpen}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={CustomSelectIcon}
            style={stylefunction()}
            className={
              i18n.language === "arabic"
                ? "select-label-arabic-padding-arabic"
                : "select-label-arabic-padding-others"
            }
            sx={{
              "& .MuiSelect-icon": {
                left: i18n.language === "arabic" ? "15px" : "auto",
                right: i18n.language === "arabic" ? "auto" : "15px",
                top: "17px"
              },
              "& .MuiSelect-select": {
                paddingRight: i18n.language === "arabic" ? "-30px" : "12px",
                padding: "0px",
                transitionDuration: "0ms !important",
                transitionDelay: "0ms"
              }
            }}
          >
            <MenuItem
              style={
                i18n.language === "arabic" ? menuDisableArabic : menuDisable
              }
              disabled
              value={0}
            >
              {selectPlaceHolder}
            </MenuItem>
            {amberTierMapping?.tiers?.map((item, index) => (
              <MenuItem
                key={index + 1}
                style={
                  i18n.language === "arabic" ? menuDisableArabic : menuDisable
                }
                value={index + 1}
              >
                {item?.tier_name}
              </MenuItem>
            ))}
          </Select>
          {errorSelect && (
            <CustomFormHelperTextWithTranslation
              style={{
                textAlign: "initial",
                direction: i18n.language === "arabic" ? "rtl" : "lrt"
              }}
            >
              {errorMessage}
            </CustomFormHelperTextWithTranslation>
          )}
        </ThemeProvider>
      </FormControl>
    </div>
  );
}
