import React, { useEffect, useState } from "react";
import logoUnion from "Assets/images/logo-dark.svg";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import ColorButton from "Assets/ColorButton/ColorButton";
import WprightPage from "Components/Authanticate/WprightPage";
import { useTranslation } from "react-i18next";
import { useRegisterLoginAccount } from "hooks/onBoardingHooks";
import { useGetFetchAmberReferralProgram } from "services/account/account.query";
import { useDataLayer } from "hooks/useDataLayer";
import LazyImage from "Components/LazyLoad/LazyImage";
import { useDispatch } from "react-redux";
import {
  setExternalAppType,
  setExternalDeviceType,
  setIsExternal
} from "store/authanticateSlice";
import { selectLanguage } from "store/languageSlice";
import { setSelectedLanguageCodeToStorage } from "utils/setSelectedLanguageCodeToStorage";
import { CMS_LANGUAGE_MAP } from "CmsComponents";

const WelcomePage = () => {
  const referalCode = useParams()?.referalCode;
  const appType = useParams()?.appType;
  const [searchParams] = useSearchParams();
  const externalDeviceType = searchParams.get("deviceType");
  const private_token = localStorage.getItem("private-token");
  const locale = searchParams.get("locale");

  const { data: fetchamber, isLoading } = useGetFetchAmberReferralProgram();
  const landing_page_header =
    fetchamber?.items?.[0]?.fields?.configuration?.configuration?.invitee_info
      ?.landing_page_header;
  const landing_page_text =
    fetchamber?.items?.[0]?.fields?.configuration?.configuration?.invitee_info
      ?.landing_page_text;
  const { label, background_color } =
    fetchamber?.items?.[0]?.fields?.configuration?.configuration?.invitee_info
      ?.color_theme || {};

  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isSetEvent, setIsSetEvent] = useState(false);
  const { setSignUpEvent } = useDataLayer();
  const dispatch = useDispatch();

  // referralNavigateVontrol:It first checks if both referalCode and private_token are present. If both are present, it navigates the user to the root route / with the referalCode included in the state. This might be the case where the user has both a referral code and a private token, and they should be directed to the main application with the referral code included for some specific actions or tracking.
  // If there is a referalCode but no private_token, it navigates the user to a specific route based on the referral code. The route is constructed as /sign-up/${referalCode}, and the referalCode is included in the state. This scenario may be used when the user is registering with a referral code, and the route depends on that code.
  // If there is no referalCode, it navigates the user to a default route /sign-up. This might be the standard registration process route.

  const referralNavigateVontrol = () => {
    if (referalCode && private_token) {
      navigate("/", { state: { referalCode } });
    } else if (referalCode && !private_token) {
      navigate(`/sign-up/${referalCode}`, { state: { referalCode } });
    } else if (!appType) navigate(`/sign-up`, { state: { referalCode } });
  };

  useEffect(() => {
    if (!isSetEvent) {
      setSignUpEvent("start_sign_up");
      setIsSetEvent(true);
    }
  }, [isSetEvent, setSignUpEvent]);

  useEffect(() => {
    if (appType) {
      dispatch(setIsExternal(true));
      dispatch(setExternalAppType(appType));
      dispatch(setExternalDeviceType(externalDeviceType || "ios")); // android or ios
    } else {
      dispatch(setIsExternal(false));
    }
  }, [appType]);

  useEffect(() => {
    referralNavigateVontrol();
  }, []);

  useEffect(() => {
    if (locale) {
      const language = locale;
      const languageCode = CMS_LANGUAGE_MAP[language.toUpperCase()];
      const selectedLanguageCode = localStorage.getItem("language");
      if (languageCode && selectedLanguageCode !== languageCode) {
        dispatch(selectLanguage(languageCode));
        i18n.changeLanguage(languageCode);
        setSelectedLanguageCodeToStorage(languageCode);
        window.location.reload();
      }
    }
  }, [locale]);

  const RegisterLoginAccount = useRegisterLoginAccount();
  const { background } = RegisterLoginAccount?.properties || {};

  return (
    <div className="WPmain">
      <div
        className="WPleftpage inviteFriendStyle"
        style={{
          direction: i18n.language === "arabic" ? "rtl" : "ltr",
          backgroundColor: background || "#FFFFFF"
        }}
      >
        <div className="left-page-logo">
          <Link to={appType ? "#" : "/"}>
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={logoUnion}
              alt="logo"
              width={"74.89px"}
            />
          </Link>
        </div>
        <div className="left-page-form left-page-customize">
          <div
            className={
              i18n.language === "arabic" ? "login-head-arabic" : "login-head"
            }
          >
            <span>{t("Create your account")}</span>
          </div>
          {referalCode && (
            <div
              className="invite-friend"
              style={{
                background:
                  !isLoading &&
                  (background_color || "var(--primary-sage, #E7F0DF)")
              }}
            >
              <div
                className="invite-friend-title"
                style={{
                  letterSpacing: i18n.language === "arabic" ? "unset" : "2.4px",
                  fontFamily:
                    i18n.language === "arabic" ? "TajawalBold" : "ArchivoBlack",
                  fontWeight: "500",
                  color: label || ""
                }}
              >
                {i18n.language === "arabic"
                  ? landing_page_header?.ar
                  : landing_page_header?.en}
              </div>
              <div
                className="invite-friend-text"
                style={{
                  letterSpacing: i18n.language === "arabic" ? "unset" : "2.4px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular"
                      : "MontserratRegular"
                }}
              >
                {i18n.language === "arabic"
                  ? landing_page_text?.ar
                  : landing_page_text?.en}{" "}
              </div>
            </div>
          )}
          <div className="btnbox">
            {" "}
            <ColorButton
              className="button-submit"
              onClick={() =>
                navigate(`/sign-up/phone-validation`, {
                  state: { referalCode }
                })
              }
              style={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium"
              }}
            >
              {t("Get started")}
            </ColorButton>
          </div>
          <div className="textall">
            <div
              className={
                i18n.language === "arabic" ? "subtext-arabic" : "subtext"
              }
            >
              {t("By creating your account")}
            </div>
            {!appType && (
              <div
                className={
                  i18n.language === "arabic" ? "sublink-arabic" : "sublink"
                }
              >
                {t("Already have an account?")}{" "}
                <Link to="/sign-in">{t("Sign in here")}</Link>{" "}
              </div>
            )}
          </div>
        </div>

        <div className="left-page-footer">
          <Link to="/">
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={logoUnion}
              alt="logo"
              width={"74.89px"}
            />
          </Link>
        </div>
      </div>
      <WprightPage />
    </div>
  );
};

export default WelcomePage;
