import React from "react";
import { AppRoutes } from "routes/AppRoutes";

import Bootstrap from "./Bootstrap";
import "Styles/Main.scss";

const App = () => {
  return (
    <Bootstrap>
      <AppRoutes />
    </Bootstrap>
  );
};

export default App;
