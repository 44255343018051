import { useState, useEffect, useCallback, useMemo } from "react";
import Layout from "../Layout";
import LightBackground from "Components/LightBackground";
import {
  useGetBrands,
  useGetRecordIdsAndTypes
} from "services/brands/brands.query";
import { BRAND_CMS_COMPONENT_MAP } from "CmsComponents";
import CategoryListFiltered from "Components/CategoryListFiltered";
import { useTranslation } from "react-i18next";
import { ContentTypesEnums, DataSourceEnums } from "utils/enums";
import { LightBackgroundControl } from "GlobalProcess/GlobalFunction";
import CategoryListFilteredManual from "Components/CategoryListFilteredManual";

const BrandsStore = ({ scrollCntrl, setScrollCntrl }) => {
  const [index, setIndex] = useState(12);
  const [hidden, setHidden] = useState(false);
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState("all");

  const { data: BrandsContent = {} } = useGetBrands();
  const { widgets = [], seo_data } = BrandsContent;
  const componentDetails = widgets?.find(
    (item) => item?.component_type === "mini-navigation"
  );

  const data_source = componentDetails?.data_source;
  const {
    data: IdsAndCountsData,
    isLoading,
    isSuccess
  } = useGetRecordIdsAndTypes({
    data_source: DataSourceEnums?.[data_source] || DataSourceEnums.BRAND
  });
  const BrandsLoadContentColorTheme = componentDetails?.color_theme || {};
  useEffect(() => {
    if (activeButton !== "all") {
      setHidden(true);
    } else setHidden(false);
  }, [activeButton, t]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollCntrl(false);
  }, [scrollCntrl, setScrollCntrl]);

  // getButtonFilter function (wrapped in useCallback):
  // It computes unique categories based on the IdsAndCountsData array.
  // The function maps through the IdsAndCountsData and checks if each item has a non-zero RecordCount and a valid CategoryID.
  // It returns an array of boolean values representing unique categories.
  // The useCallback ensures that this function's reference remains consistent across renders.

  // emptyDataMessaging (defined using useMemo):
  // It calculates a value for emptyDataMessaging by finding an item in the widgets array with a "component_type" of "empty-data-messaging".
  // The useMemo hook ensures that this calculation is only done when the widgets array changes.

  // carouselCount (defined using useMemo):
  // It calculates a value for carouselCount by finding an item in the widgets array with a "component_type" of "carousel" and checking if it has more than zero items.
  // The useMemo hook ensures that this calculation is only done when the widgets array changes.

  const getButtonFilter = useCallback(() => {
    const uniqueCategories = IdsAndCountsData?.map(
      (item) => item?.CategoryID && item?.RecordCount > 0
    );
    return uniqueCategories;
  }, [IdsAndCountsData]);

  useEffect(() => {
    if (isSuccess) {
      getButtonFilter();
    }
  }, [getButtonFilter, isSuccess]);

  const emptyDataMessaging = useMemo(() => {
    return widgets?.find(
      (item) => item?.component_type === "empty-data-messaging"
    );
  }, [widgets]);

  const carouselCount = useMemo(() => {
    return (
      widgets?.find(
        (item) => item?.component_type === ContentTypesEnums.CAROUSEL
      )?.items?.length > 0
    );
  }, [widgets]);

  return (
    <>
      <Layout seo_data={seo_data} className="brands">
          
          {!LightBackgroundControl(widgets) && (
            <LightBackground
              text={BrandsContent?.title}
              paddingTp="0px"
              xsMinHeight="200px"
              minheight="320px"
              containerStyle={{
                justifyContent: "flex-start"
              }}
            />
          )}
          {widgets?.map((widget) => {
            const CmsComponent =
              BRAND_CMS_COMPONENT_MAP[widget["component_type"]];
            if (CmsComponent) {
              return (
                <CmsComponent
                  key={widget?.component_type}
                  widget={widget}
                  activeButton={activeButton}
                  setActiveButton={setActiveButton}
                  index={index}
                  setIndex={setIndex}
                  buttonFilter={getButtonFilter()}
                  hidden={hidden}
                  CategoryType={DataSourceEnums.BRAND}
                  IdsAndCountsData={IdsAndCountsData}
                  page="brand"
                />
              );
            }
            return null;
          })}
          {data_source === DataSourceEnums.MANUAL ? (
            <CategoryListFilteredManual
              CategoryType={DataSourceEnums.MANUAL}
              activeButton={activeButton}
              ButtonText={`${componentDetails?.cta_button.title}`}
              color_theme={BrandsLoadContentColorTheme}
              isLoading={isLoading}
              size={3}
              web_message={!carouselCount && emptyDataMessaging?.web_message}
              web_message_title={!carouselCount && emptyDataMessaging?.title}
              paginationData={{
                items: componentDetails?.items,
                total_items: componentDetails?.items?.length
              }}
            />
          ) : (
            <CategoryListFiltered
              CategoryType={
                DataSourceEnums?.[data_source] || DataSourceEnums.BRAND
              }
              activeButton={activeButton}
              ButtonText={`${componentDetails?.cta_button.title}`}
              color_theme={BrandsLoadContentColorTheme}
              isLoading={isLoading}
            />
          )}
       </Layout>
    </>
  );
};

export default BrandsStore;
