import { useState, useEffect } from "react";
import { Grid, CircularProgress, Box,useMediaQuery } from "@mui/material";
import Item from "./item";
import { useTranslation } from "react-i18next";
import { useGetBrandsLoad } from "services/brands/brands.query";
import dayjs from "dayjs";
import { categoryListFilteredHeightControl } from "GlobalProcess/GlobalFunction";
 const CategoryListFilterdeMonths = ({
  CategoryType,
  ButtonText,
  activeButton,
  marginTpBt,
  padSet,
  size,
  spacing,
  color_theme,
  web_message_title,
  web_message,
  monthRecordCount,
  SetMonthFilterCount,
  currentMonthId
}) => {
  const { t, i18n } = useTranslation();
  const { color_definition } = color_theme || {};
  const [loading, setLoading] = useState(true);
  const [monthList, SetMonthList] = useState(12);
  const [DataMonthsListMapped, SetDataMonthsListMapped] = useState();
  const mobile = useMediaQuery("(max-width:599px)");

  const { data: paginationDataMonths } = useGetBrandsLoad({
    limit: monthRecordCount || 100,
    skip: 0,
    data_source: CategoryType,
    category: "all",
    monthRecordCount
  });

  const paginationDataMonthsList = paginationDataMonths?.widgets?.[0]?.items;
  const paginationDataMonthsListMapped = paginationDataMonthsList?.map(
    (item) => {
      return {
        month: dayjs(item?.start_date).format("M"),
        ...item
      };
    }
  );
  const hasDataCurrentMonth =
    paginationDataMonthsListMapped?.filter(
      (item) => item.month === currentMonthId
    ).length > 0
      ? true
      : false;

  useEffect(() => {
    SetMonthFilterCount(paginationDataMonthsListMapped);
  }, [paginationDataMonths]);

  const handleChangeMonths = () => {
    setLoading(true);
    setTimeout(() => {
      SetMonthList(monthList + 12);
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    setLoading(true);
    SetDataMonthsListMapped([]);
    const timeoutId = setTimeout(() => {
      setLoading(false);
      const process = paginationDataMonthsListMapped
        ?.filter((item) => item.month === activeButton)
        .slice(0, monthList);
      SetDataMonthsListMapped(process);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeButton, paginationDataMonths]);

  const [isNoResultVisible, setIsNoResultVisible] = useState(false);

  useEffect(() => {
    if (!hasDataCurrentMonth &&
      activeButton === currentMonthId &&
      !loading ) {
        setIsNoResultVisible(true);
    } else {
      setIsNoResultVisible(false);
    }

    // Cleanup effect if necessary
    return () => {
      setIsNoResultVisible(false); // Reset visibility if component unmounts
    };
  }, [activeButton, currentMonthId, hasDataCurrentMonth, loading]); 

  return (
    <>
      <Grid
        container
        spacing={DataMonthsListMapped?.length > 0 && !mobile ? (spacing || "42.67px") : 0}
        style={{
          paddingBottom: marginTpBt || "32px",
          marginTop: "0px",
          minHeight: "100%",
          alignContent: "center",
          direction: i18n.language === "arabic" ? "rtl" : "",
          paddingTop: marginTpBt || "32px",
          backgroundColor: color_definition?.background_color
            ? color_definition?.background_color
            : "#FFFFFF"
        }}
        sx={{
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" },
          ...(DataMonthsListMapped?.length <= 0 && { margin: { xs: "0", md: "unset" } }),
          maxWidth: { xs: "100%", md: "100%" },
          height: "100%"
        }}
      >
        {!loading ? (
          <>
            {DataMonthsListMapped?.length > 0 && (
              <>
                {DataMonthsListMapped.map((category) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={size || 3}
                    key={category.id}
                    sx={{
                      paddingTop: "8px !important"
                    }}
                  >
                    <Item
                      card={category}
                      padSet={padSet}
                      color_definition={color_definition}
                      data_source={CategoryType}
                    />
                  </Grid>
                ))}
              </>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: categoryListFilteredHeightControl(
                DataMonthsListMapped?.length,
                loading
              ),
              width: "100%"
            }}
          >
            <CircularProgress
              size={"25px"}
              style={{
                color: "black"
              }}
            />
          </Box>
        )}
        {isNoResultVisible && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "24px"
              }}
            >
              <Box
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalMedium "
                      : "MontserratSemiBold",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "19px", lg: "23px" }
                      : { xs: "16px", md: "18px", lg: "20px" }
                }}
                style={{
                  textAlign:"center",
                  fontWeight:"600",
                  lineHeight:"26px",
                  fontFamily: "MontserratSemiBold"
                }}
              >
                {web_message_title}
              </Box>
              <Box
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "19px", lg: "23px" }
                      : { xs: "16px", md: "18px", lg: "20px" }
                }}
                style={{
                  textAlign:"center",
                  fontSize:"16px",
                  fontWeight:"400",
                  lineHeight:"20.8px",
                  color:"#5A5F64"
                }}
              >
                {web_message}
              </Box>
            </Box>
          )}
        {/* {!paginationDataMonthsListMapped?.length > 0 && !loading && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "24px"
            }}
          >
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium "
                    : "MontserratMedium",
                direction: i18n.language === "arabic" ? "rtl" : "ltr",
                fontSize:
                  i18n.language === "arabic"
                    ? { xs: "19px", lg: "23px" }
                    : { xs: "16px", md: "18px", lg: "20px" }
              }}
            >
              {web_message_title}
            </Box>
            <Box
              sx={{
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalRegular "
                    : "MontserratRegular",
                direction: i18n.language === "arabic" ? "rtl" : "ltr",
                fontSize:
                  i18n.language === "arabic"
                    ? { xs: "19px", lg: "23px" }
                    : { xs: "16px", md: "18px", lg: "20px" }
              }}
            >
              {web_message}
            </Box>
          </Box>
        )} */}
        {monthList?.length < paginationDataMonthsListMapped?.length && (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                marginTop: "10px",
                fontFamily:
                  i18n.language === "arabic"
                    ? "TajawalMedium"
                    : "MontserratMedium",
                fontSize: i18n.language === "arabic" ? "15px" : "12px",
                display: "flex",
                minWidth: "160px",
                padding: "10px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
                borderRadius: "34px",
                cursor: "pointer",
                color: color_definition?.cta_button_text
                  ? color_definition?.cta_button_text
                  : "var(--primary-black, #000)",
                backgroundColor: color_definition?.cta_button_fill
                  ? color_definition?.cta_button_fill
                  : "white",
                border: color_definition?.cta_button_border
                  ? `1px solid ${color_definition?.cta_button_border}`
                  : " 1px solid var(--greys-grey-01, #5A5F64)",
                position: "relative"
              }}
              onClick={handleChangeMonths}
            >
              {loading && (
                <CircularProgress
                  size={"16px"}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: i18n.language === "arabic" ? "auto" : "10px",
                    right: i18n.language === "arabic" ? "10px" : "auto"
                  }}
                />
              )}{" "}
              {ButtonText !== undefined ? ButtonText : t("Load more")}
            </button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CategoryListFilterdeMonths;
