import React from "react";
import { createRoot } from "react-dom/client";

import "./i18n";
import AppRoot from "./AppRoot";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<AppRoot />);
