import { useEffect, useMemo, useRef } from "react";
import Layout from "../Layout";
import { useGetHowItWorksContent } from "services/cms/cms.query";
import { CMS_COMPONENT_MAP, CMS_LANGUAGE_MAP } from "CmsComponents";
import { useLocation, useNavigate } from "react-router";
import { AnchorLinkEnums } from "utils/enums";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectLanguage } from "store/languageSlice";
import { setSelectedLanguageCodeToStorage } from "utils/setSelectedLanguageCodeToStorage";

const HowItWorks = () => {
  const tag = useLocation().hash.replace("#", "");
  const { data: howItWorksContent = {} } = useGetHowItWorksContent();
  const [searchParams] = useSearchParams();
  const appType = searchParams?.get("appType");
  const { widgets = [], seo_data } = howItWorksContent;
  const { hash } = useLocation();
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const locale = searchParams.get("locale");

  useEffect(() => {
    const el = hash && document.getElementById(hash.substr(1));

    if (el) {
      targetRef.current = el;
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!widgets) {
      navigate("/error404");
    }
  }, [navigate, widgets]);

  useEffect(() => {
    if (tag === AnchorLinkEnums.POINTS_CALCULATOR) {
      setTimeout(() => {
        const element = document.getElementById(tag);
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 1500);
    }
    if (tag === AnchorLinkEnums.TIERS_AND_BENEFITS) {
      setTimeout(() => {
        const element = document.getElementById(tag);
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 1500);
    }
  }, [tag]);

  useEffect(() => {
    if (locale) {
      const language = locale;
      const languageCode = CMS_LANGUAGE_MAP[language.toUpperCase()];
      const selectedLanguageCode = localStorage.getItem("language");
      if (languageCode && selectedLanguageCode !== languageCode) {
        dispatch(selectLanguage(languageCode));
        i18n.changeLanguage(languageCode);
        setSelectedLanguageCodeToStorage(languageCode);
        window.location.reload();
      }
    }
  }, [locale]);

  const filteredWidgets = useMemo(() => {
    const excludeComponents = appType
      ? [
          "web-homepage-main-navigation",
          "web-homepage-footer-navigation",
          "amber-message-cta"
        ]
      : [];
    return widgets.filter(
      (widget) => !excludeComponents.includes(widget["component_type"])
    );
  }, [widgets, appType]);

  return (
    <Layout seo_data={seo_data}>
      {filteredWidgets?.map((widget, index) => {
        const CmsComponent = CMS_COMPONENT_MAP[widget["component_type"]];
        if (CmsComponent) {
          return (
            <CmsComponent key={index} widget={widget} page={"howitworks"} />
          );
        }
        return null;
      })}
    </Layout>
  );
};

export default HowItWorks;
