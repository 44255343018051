import React from "react";
import { Link } from "react-router-dom";
import {
  AnchorLinkEnums,
  LinkTypeEnums,
  LinkTypeKeyNoModuleEnums
} from "utils/enums";
import { useGetOnAppConfigContent } from "services/cms/cms.query";
// The provided code is a React component named LinkType used for rendering links with different types of behaviors based on the link_type prop. It handles rendering links for various scenarios, such as external links, internal links, links to events or offers, and more.
// Here's a summary of how the component works:
// It receives several props, including link_type, link, children (the content of the link), stylesLink, withLink, and description.
// It defines a commonStyle object to specify common CSS styles for the rendered links, including text decoration, color, and width.
// It uses conditional logic to determine the type of link based on the link_type prop. The different cases include:
// External Links (LinkTypeEnums.EXTERNAL): Renders an anchor (<a>) tag with the link opening in a new tab (target="_blank").
// Static Links (LinkTypeEnums.STATIC): Renders either an anchor tag or a React Router Link component depending on whether the application is running locally (localhost) or in production.
// Links with No Module (LinkTypeKeyNoModuleEnums): Renders links for specific cases without a module. It constructs the link based on the link_type.
// Module Links (LinkTypeEnums.MODULE): Renders links to various modules or performs specific actions based on the link_type. It can also include links to call phone numbers.
// Depending on the link_type, the component returns either an anchor tag (<a>) or a React Router Link component, including the specified commonStyle and children (the link content).
// The component handles different scenarios for rendering links based on the provided link_type and other conditions.
// Overall, LinkType is a versatile component for handling various types of links and providing the appropriate rendering behavior for each type.

const LinkType = ({
  link_type,
  link,
  children,
  stylesLink,
  withLink,
  description
}) => {
  const { data: appConfigContent = {} } = useGetOnAppConfigContent();
    const amberModuleUrlMappingItems =
    appConfigContent?.items?.[0]?.fields?.configuration?.Configurations?.[1]
      ?.items ?? [];

  const PrefixLinkEnums = Object.fromEntries(
    amberModuleUrlMappingItems?.map((item) => [item.id, item.web_route_mod])
  );
    const commonStyle = {
    textDecoration: "none",
    color: stylesLink,
    width: withLink || ""
  };

  if (LinkTypeEnums.EXTERNAL === link_type) {
    return (
      <a href={link} style={commonStyle} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  if (
    LinkTypeEnums.STATIC === link_type &&
    window.location.hostname !== "localhost"
  ) {
    return (
      <a
        href={"https://" + window.location.hostname + "/" + link}
        style={commonStyle}
      >
        {children}
      </a>
    );
  }
  if (
    LinkTypeEnums.STATIC === link_type &&
    window.location.hostname === "localhost"
  ) {
    return (
      <Link style={commonStyle} to={"/" + link}>
        {children}
      </Link>
    );
  }
   

  

  if (LinkTypeKeyNoModuleEnums.includes(link_type)) {
    let eventsAndOffersTo;
    if (link_type === LinkTypeEnums.OFFERS) {
      eventsAndOffersTo = "/" + AnchorLinkEnums.OFFERS + "/" + link;
     } else if (link_type === LinkTypeEnums.EVENTS) {
      eventsAndOffersTo = "/" + AnchorLinkEnums.EVENTS + "/" + link;
    } else {
      eventsAndOffersTo = PrefixLinkEnums[link_type]
        ? PrefixLinkEnums[link_type] + "/" + link
        : link;
    }
    return (
      <Link style={commonStyle} to={eventsAndOffersTo}>
        {children}
      </Link>
    );
  }

  if (
    LinkTypeEnums.MODULE === link_type &&
    link !== "CALL_NUMBER_ACTION" &&
    link !== "REVEAL_OFFER_CODE"
  ) {
 
    const moduleTo = PrefixLinkEnums[link];
     return (
      <Link style={commonStyle} to={moduleTo}>
        {children}
      </Link>
    );
  }
  if (LinkTypeEnums.MODULE === link_type && link === "CALL_NUMBER_ACTION") {
    return (
      <a href={"tel:" + description} style={commonStyle}>
        {children}
      </a>
    );
  } else return <> {children}</>;
};

export default LinkType;
