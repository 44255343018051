import React from "react";
import { useTranslation } from "react-i18next";
import { formatPhoneNumber } from "Components/FormatPhoneNumber/FormatPhoneNumber";
import CustomTextField from "./CustomTextField";
import { InputAdornment } from "@mui/material";

const styleArabic = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "TajawalRegular"
};
const styleEnglish = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr",
  textAlign: "right"
};
const styleEnglishLength = {
  fontSize: 16,
  padding: "10px 12px",
  lineHeight: "130%",
  height: "21px",
  fontFamily: "MontserratRegular",
  direction: "ltr"
};

const PhoneInput = ({
  phone,
  handleKeyDown,
  name,
  textFieldProps: { onChange, ...otherTextFieldProps } = {},
  title = "Mobile Phone",
  tick,
  account,
  setSendToPhone
}) => {
  const { t, i18n } = useTranslation();

  // handleInput(e): This function is an event handler typically used for input fields. It formats the phone number entered in the input field using the formatPhoneNumber function and assigns the formatted number back to the input field. It also sets the sendToPhone state variable (if account is truthy) with the formatted number. Finally, it calls the onChange function, passing the event object.

  // inputCntrl(): This function returns a CSS style class based on the current language (i18n.language) and the length of the phone variable. If the language is "arabic" and the phone variable has a length greater than 0, it returns the styleEnglish class; otherwise, it returns the styleArabic class. If the language is not "arabic," it returns the styleEnglishLength class.

  const handleInput = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    e.target.value = formattedNumber;
    account && setSendToPhone(formattedNumber);
    onChange(e);
  };
  const inputCntrl = () => {
    if (i18n.language === "arabic") {
      if (phone?.length > 0) {
        return styleEnglish;
      } else return styleArabic;
    } else {
      return styleEnglishLength;
    }
  };
  return (
    <>
      <CustomTextField
        name={name}
        value={phone}
        onChange={handleInput}
        sx={{ width: "100% !important" }}
        placeholder={t(title)}
        onKeyUp={handleKeyDown}
        inputProps={{
          style: inputCntrl(),
          textAlign: i18n.language === "arabic" ? "right" : "left",
          inputMode: "numeric",
          pattern: "[0-9]*"
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{tick}</InputAdornment>
        }}
        {...otherTextFieldProps}
      />
    </>
  );
};

export default PhoneInput;
