import { useState, useEffect, useRef, useMemo } from "react";
import { Grid, CircularProgress, Box } from "@mui/material";
import Item from "./item";
import { useTranslation } from "react-i18next";
import { useGetBrandsLoad } from "services/brands/brands.query";
import { categoryListFilteredHeightControl } from "GlobalProcess/GlobalFunction";
const CategoryListFiltered = ({
  CategoryType,
  ButtonText,
  activeButton,
  marginTpBt,
  padSet,
  size,
  color_theme,
  web_message_title,
  web_message,
  spacing
}) => {
  const { t, i18n } = useTranslation();
  const skipIndex = useRef(0);
  const [firstTouch, setFirstTouch] = useState(true);
  const { color_definition } = color_theme || {};
  const [buttonApperance, setButtonApperance] = useState(false);
  const totalRecords = useRef(0);
  const [activeButtonFilter, setActiveButtonFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const postList = useRef([]);
  const [laodingFlag, setLaodingFlag] = useState(true);
  const [activeButtonChange, SetActiveButtonChange] = useState(true);

  const {
    data: paginationData,
    isSuccess,
    refetch
  } = useGetBrandsLoad({
    limit: 12,
    skip: skipIndex.current,
    data_source: CategoryType,
    category: activeButton
  });

  //The fetchData function is a callback function used to fetch and handle data. It does the following:

  // It accepts an activeButton parameter.
  // It calls an asynchronous function named refetch to retrieve data.
  // It updates the loadingFlag state variable to reflect the loading status based on the isLoading result.
  // After a 1-second timeout, it checks if the data fetching was successful (isSuccess). If successful, it performs the following actions:
  // Updates the loadingFlag state variable.
  // Stores data in the postList and totalRecords ref variables.
  // Sets the activeButtonFilter state variable.
  // If the total number of records in the data exceeds 12, it sets the buttonApperance state variable to true and updates the skipIndex ref variable.
  // If the total number of records is not greater than 12, the buttonApperance state variable is set to false.
  //In summary, this function is responsible for managing data fetching and the appearance of buttons based on the fetched data. It relies on state and ref variables for tracking and managing data and loading indicators.

  const fetchData = useMemo(
    () => async (activeButton) => {
      const { isSuccess, data, isLoading } = await refetch();
      setLaodingFlag(!isLoading);
      SetActiveButtonChange(!isLoading);
      setTimeout(() => {
        if (isSuccess) {
          postList.current = data?.widgets[0]?.items || [];
          totalRecords.current = data.widgets[0].total_items;
          setActiveButtonFilter(activeButton);
          if (totalRecords.current > 12) {
            setButtonApperance(true);
            skipIndex.current = skipIndex.current + 12;
          } else {
            setButtonApperance(false);
          }
        }
        setLaodingFlag(false);
        SetActiveButtonChange(false);
      }, 2000);
    },
    [refetch]
  );

  useEffect(() => {
    if (activeButtonFilter !== activeButton) {
      setButtonApperance(false);
      postList.current = [];
      skipIndex.current = 0;
      setLaodingFlag(true);
      SetActiveButtonChange(true);

      setTimeout(() => {
        fetchData(activeButton);
      }, 200);
    }
  }, [
    activeButton,
    postList,
    refetch,
    totalRecords,
    skipIndex,
    setButtonApperance,
    fetchData,
    activeButtonFilter
  ]);

  useEffect(() => {
    if (isSuccess && paginationData?.widgets[0]) {
      if (firstTouch) {
        setLaodingFlag(false);
        SetActiveButtonChange(false);

        setFirstTouch(false);
        postList.current = paginationData?.widgets[0]?.items || [];
        totalRecords.current = paginationData.widgets[0].total_items;
        skipIndex.current = skipIndex.current + 12;
        if (totalRecords.current > 12) {
          setButtonApperance(true);
        } else {
          setButtonApperance(false);
        }
      }
    }
  }, [firstTouch, isSuccess, paginationData, postList]);

  // This function is an asynchronous operation used to handle data fetching and pagination changes. Here's a summary of what it does:
  // Sets the loading state to true to indicate that data is being loaded.
  // Increases the skipIndex variable by 12, likely to fetch the next page of data.
  // Stores a copy of the current postList data in the tempList variable.
  // Calls an asynchronous function called refetch to fetch more data.
  // Updates the loadingFlag state to reflect the loading status, which is set based on the isLoading result.
  // If the data retrieval is successful (isSuccess), it proceeds with the following actions:
  // After a 600-millisecond delay, it sets the loading state to false to indicate that the data loading process is complete.
  // Creates an array named updateList containing the newly fetched data.
  // Updates the postList data by concatenating the tempList and updateList, adding the new data to the existing list.
  // Updates the loadingFlag state once more to reflect the loading status.
  // In summary, this function manages data fetching and pagination. It updates state variables and reference variables to handle the loading process and data list updates.

  const handleChange = async () => {
    setLoading(true);
    skipIndex.current = skipIndex.current + 12;
    const tempList = [...postList.current];
    const { isSuccess, data, isLoading } = await refetch();
    setLaodingFlag(true);
    SetActiveButtonChange(false);

    if (isSuccess) {
      setTimeout(() => {
        setLoading(false);
        const updateList = [...data?.widgets[0]?.items];
        postList.current = [...tempList, ...updateList];
        setLaodingFlag(false);
      }, 1000);
    }
  };
   return (
    <>
      <Grid
        container
        spacing={postList?.current?.length > 0 && (spacing || "42.67px")}
        style={{
          paddingBottom: marginTpBt || "32px",
          marginTop: "0px",
          minHeight: "100%",
          alignContent: "center",
          direction: i18n.language === "arabic" ? "rtl" : "",
          paddingTop: marginTpBt || "32px",
          backgroundColor: color_definition?.background_color
            ? color_definition?.background_color
            : "#FFFFFF"
        }}
        sx={{
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" },
          height: "100%"
        }}
      >
        <>
          {postList?.current?.length !== 0 && !activeButtonChange
            ? postList?.current?.map((category) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={size || 3}
                  key={category.id}
                  sx={{
                    paddingTop: "8px !important"
                  }}
                >
                  <Item
                    card={category}
                    padSet={padSet}
                    color_definition={color_definition}
                    data_source={CategoryType}
                  />
                </Grid>
              ))
            : laodingFlag && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: categoryListFilteredHeightControl(
                      postList?.current?.length,
                      laodingFlag
                    ),
                    width: "100%"
                  }}
                >
                  <CircularProgress
                    size={"25px"}
                    style={{
                      color: "black"
                    }}
                  />
                </Box>
              )}
          {postList?.current?.length === 0 && !laodingFlag && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "24px"
              }}
            >
              <Box
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalMedium "
                      : "MontserratSemiBold",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "19px", lg: "23px" }
                      : { xs: "16px", md: "18px", lg: "20px" }
                }}
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  lineHeight: "26px",
                  fontFamily: "MontserratSemiBold"
                }}
              >
                {web_message_title}
              </Box>
              <Box
                sx={{
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalRegular "
                      : "MontserratRegular",
                  direction: i18n.language === "arabic" ? "rtl" : "ltr",
                  fontSize:
                    i18n.language === "arabic"
                      ? { xs: "19px", lg: "23px" }
                      : { xs: "16px", md: "18px", lg: "20px" }
                }}
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "20.8px",
                  color: "#5A5F64"
                }}
              >
                {web_message}
              </Box>
            </Box>
          )}
        </>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {" "}
          {skipIndex.current < totalRecords.current &&
            totalRecords.current >= 12 &&
            buttonApperance && (
              <button
                style={{
                  marginTop: "10px",
                  fontFamily:
                    i18n.language === "arabic"
                      ? "TajawalMedium"
                      : "MontserratMedium",
                  fontSize: i18n.language === "arabic" ? "15px" : "12px",
                  display: "flex",
                  minWidth: "160px",
                  padding: "10px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "34px",
                  cursor: "pointer",
                  color: color_definition?.cta_button_text
                    ? color_definition?.cta_button_text
                    : "var(--primary-black, #000)",
                  backgroundColor: color_definition?.cta_button_fill
                    ? color_definition?.cta_button_fill
                    : "white",
                  border: color_definition?.cta_button_border
                    ? `1px solid ${color_definition?.cta_button_border}`
                    : " 1px solid var(--greys-grey-01, #5A5F64)",
                  position: "relative"
                }}
                onClick={handleChange}
              >
                {loading && (
                  <CircularProgress
                    size={"16px"}
                    style={{
                      color: "black",
                      position: "absolute",
                      left: i18n.language === "arabic" ? "auto" : "10px",
                      right: i18n.language === "arabic" ? "10px" : "auto"
                    }}
                  />
                )}{" "}
                {ButtonText !== undefined ? ButtonText : t("Load more")}
              </button>
            )}
        </Grid>{" "}
      </Grid>
    </>
  );
};

export default CategoryListFiltered;
