import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import { AccuntSettingMenu } from "./AccountSettingsMenu";
import LightBackground from "Components/LightBackground";
import Layout from "../Layout";
import PurchaseContainer from "Pages/PurchaseHistory/PurchaseContainer";
const AccountSettings = () => {
  const { t, i18n } = useTranslation();

  return (
    <Layout className="events">
      <PurchaseContainer>
        <LightBackground
          text={t("Account Settings")}
          xsMinHeight="200px"
          minheight="320px"
        />
        <Grid
          className="account-settings"
          container
          sx={{
            paddingLeft: { xs: "20px", md: "11.11%" },
            paddingRight: { xs: "20px", md: "11.11%" },
            paddingTop: { xs: "24px", md: "58px" },
            paddingBottom: "80px",
            height: "100%",
            maxHeight: { xs: "100%" },
            background: "white",
            display: "flex",
            alignItems: "flex-start",
            direction: i18n.language === "arabic" ? "rtl" : "ltr"
          }}
        >
          <Box className="account-settings-menu">
            <AccuntSettingMenu />
          </Box>
          <Grid className="outlet-container">
            <Outlet />
          </Grid>
        </Grid>
      </PurchaseContainer>
    </Layout>
  );
};

export default AccountSettings;
