import React, { useState, useEffect, useMemo } from "react";
import logoUnion from "Assets/images/logo-dark.svg";
import { useNavigate, Link } from "react-router-dom";
import WprightPage from "Components/Authanticate/WprightPage";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { countries_radius } from "Assets/helper/helper";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "Components/BackButton/index";
import AllianceNumberForm from "Components/MobileNumberForm/AllianceNumberForm";
import {
  setIsoCode,
  setPhoneCode,
  setPhoneNumber,
  setRouteCntrl
} from "store/authanticateSlice";
import { usePostPhoneEntry } from "services/auth/auth.query";

const AlliancePhoneNumber = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const { routeCntrl } = useSelector((state) => state.authanticate);

  const hasPhoneTrue = useMemo(() => {
    return routeCntrl.some((item) => item.welcome === true);
  }, [routeCntrl]);

  const [showComponent, setShowComponent] = useState(hasPhoneTrue);
  const { alliance_id } = useSelector((state) => state.public);

  useEffect(() => {
    navigateProtect();
  }, []);
  const navigateProtect = () => {
    if (!hasPhoneTrue) {
      navigate(`/amber-alliance/welcome`);
    } else setShowComponent(true);
  };
  const {
    mutateAsync: postPhoneEntry,
    isLoading,
    error
  } = usePostPhoneEntry({
    onSuccess: () => {},
    onError: () => {}
  });

  // The handleSubmit function takes an object with properties code, phone, iso_code, and alliance_id as its argument. It performs the following actions:
  // It concatenates the code and phone, removing any hyphens or spaces, and assigns the result to the parsedPhone variable.
  // It calls the postPhoneEntry function with the following parameters:
  // iso_code: The ISO country code.
  // phone: The parsed and formatted phone number.
  // action: The string "register."
  // alliance_id: An identifier associated with an alliance.
  // It handles the response from the postPhoneEntry function. When the response is received, the function does the following:
  // Dispatches actions to set the phone number, phone code, and ISO code in the application's state.
  // Creates a new route configuration by setting a phone key to true in the route control state.
  // Navigates to the /alliance-otp route, passing additional state data containing the validation_key received in the response.

  const handleSubmit = ({ code, phone, iso_code, alliance_id }) => {
    const parsedPhone = (code + phone).replace(/-/g, "").replace(/ /g, "");
    postPhoneEntry({
      iso_code,
      phone: parsedPhone,
      action: "register",
      alliance_id
    }).then((response) => {
      dispatch(setPhoneNumber(phone));
      dispatch(setPhoneCode(code));
      dispatch(setIsoCode(iso_code));
      const newRoute = { phone: true };
      dispatch(setRouteCntrl(newRoute));
      navigate(`/alliance-otp`, {
        state: { validation_key: response.validation_key }
      });
    });
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (active) {
      setOptions([...countries_radius]);
    }

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Box className="alliance" data-lang={i18n.language}>
      {showComponent && (
        <>
          {" "}
          <div
            className="WPleftpage"
            data-lang={i18n.language}
            style={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
          >
            <BackButton path={"/amber-alliance/welcome"} />
            <div className="left-page-form">
              <div className="left-page-logo">
                <Link to="/">
                  <img src={logoUnion} alt="logo" width={"74.89px"} />
                </Link>
              </div>
              <div
                className={
                  i18n.language === "arabic"
                    ? "login-head-arabic"
                    : "login-head"
                }
              >
                <span>{t("Alliance")}</span>
              </div>

              <Grid
                container
                spacing={0}
                sx={{ width: "100%", maxWidth: "460px", marginTop: "40px" }}
              >
                <div
                  className={
                    i18n.language === "arabic"
                      ? "amber-alliance-form-text-arabic"
                      : "amber-alliance-form-text"
                  }
                >
                  {t("Enter your country code and phone number")}
                </div>

                <Grid item xs={12}>
                  <AllianceNumberForm
                    errorCode={error?.response?.data?.error_code}
                    errorResponse={t(error?.response?.data?.message)}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    alliance_id={alliance_id}
                    style={{ maxWidth: "100%", marginTop: "24px" }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <WprightPage
            style={{
              left: i18n.language === "arabic" ? "0" : "auto",
              right: i18n.language === "arabic" ? "auto" : "0"
            }}
          />
        </>
      )}
    </Box>
  );
};
export default AlliancePhoneNumber;
