import React from "react";
import CookiesPrivacy from "./CookiesPrivacy";
import YourPrivacy from "./YourPrivacy";

const SupportPages = () => {
  return (
    <div className="support-pages">
      <div className="cookie">
        <CookiesPrivacy />
      </div>
      <YourPrivacy />
    </div>
  );
};

export default SupportPages;
