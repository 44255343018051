// Privacy and Policy
export const PRIVACY_POLICY = "fetch-privacy-and-policy";
// Terms and Conditions
export const TERMS_AND_CONDITIONS = "fetch-terms-and-conditions";
// FAQ
export const FAQ = "fetch-faq";

// Editorials
export const EDITORIALS = "fetch-editorials-content";
export const EDITORIALS_DETAIL = "fetch-editorials-detail-content";

// Brands&Stores
export const BRANDS_AND_STORES = "fetch-brands-and-stores-content";
export const BRANDS_AND_STORES_DETAIL = "fetch-brands-content";

// CategoryBasedContents
export const ALL_CONTENTS = "fetch-all-contents";
export const RECORDS_AND_TYPES = "fetch-record-ids-and-types";

// Account Settings
export const ACCOUNT_DETAILS = "fetch-account-details";
export const ACCOUNT_SELECT_OTP = "account-select-account-otp";
export const ACCOUNT_VALIDATE = "account-select-account-validate";
export const ACCOUNT_SET_COUNTRY = "set-country";
export const ACCOUNT_UPDATE = "update-account-details";
export const ACCOUNT_NOTIFICATION_SETTINGS = "update-notification-settings";
export const ACCOUNT_NOTIFICATION_SETTINGS_DETAIL =
  "fetch-notification-settings-detail";
export const ACCOUNT_UPDATE_EMAIL = "update-email";
export const ACCOUNT_UPDATE_MOBILE_NUMBER = "update-mobile-number";
export const ACCOUNT_PURCHASE_HISTORY = "fetch-purchase-history";
export const ACCOUNT_UPDATE_OTP = "update-phone-validate";
export const ACCOUNT_UPDATE_EMAIL_OTP = "update-email-validate";
export const ACCOUNT_PROMOTIONS = "fetch-promotions";
export const CONTACT = "fetch-contact-us";
export const PREFERRED_LANGUAGE = "update-preferred-language";

// Rewards
export const REWARDS = "fetch-rewards";
