import { useMemo } from "react";
import { useGetOnBoardingContent } from "services/cms/cms.query";

// This code exports several custom hooks for managing and retrieving onboarding content for a web application. Here's a summary of each hook:
// useCountryList: This hook fetches a list of countries from onboarding content. It retrieves data from the "top_country_list" and "country_list" widgets and maps the countries with localized names (based on the selected language) and flags.
// useCountryTopList: Similar to the previous hook, this one specifically retrieves the top country list from the onboarding content and applies the same mapping to localize names and provide flags.
// useCountryNotAvailable: This hook fetches a list of countries that are not available. It retrieves data from the "country_not_available" widget.
// useRegisterLoginAccount: This hook is used to retrieve the content related to the registration and login account screen. It specifically searches for a widget with the screen type "register-login-account" in the onboarding content.
// These hooks make use of the useGetOnBoardingContent custom hook, which is assumed to be responsible for fetching onboarding content data for the application. The data is used to populate lists of countries and related content, such as registration and login information.
// The localization of country names is based on the selected language, which is determined by checking the "language" stored in the local storage (with a default of "en" if no language is found). The mapped country data includes the country name and a reference to the flag icon.

const getCountryListWidget = (widgets = []) => {
  return widgets?.find((widget) => widget?.hasOwnProperty("top_country_list"));
};
const getWidgetByScreentype = (widgets, screen_type) => {
  return widgets?.find((widget) => widget.screen_type === screen_type);
};

let language = localStorage.getItem("language");
if (language === undefined) {
  language = "en";
}

const mapCountries = (countries) =>
  countries.map((country) => ({
    ...country,
    name:
      language === "arabic"
        ? country["name_ar"] || country["name"]
        : country["name"],
    flag: "/icons" + country.flag
  }));

const useCountryList = () => {
  const { data: onBoardingContent = {} } = useGetOnBoardingContent();

  const { top_country_list = [], country_list = [] } =
    getCountryListWidget(onBoardingContent?.widgets) || {};

  const countries = useMemo(
    () => mapCountries([...top_country_list, ...country_list]),
    [onBoardingContent?.widgets]
  );

  return countries;
};
const useCountryTopList = () => {
  const { data: onBoardingContent = {} } = useGetOnBoardingContent();

  const { top_country_list = [] } =
    getCountryListWidget(onBoardingContent?.widgets) || {};

  const countries = useMemo(
    () => mapCountries(top_country_list),
    [top_country_list]
  );

  return countries;
};

const useCountryNotAvailable = () => {
  const { data: onBoardingContent = [] } = useGetOnBoardingContent();

  const { country_not_available = [] } =
    getCountryListWidget(onBoardingContent?.widgets) || {};

  return country_not_available;
};
const useRegisterLoginAccount = () => {
  const { data: onBoardingContent = [] } = useGetOnBoardingContent();

  return (
    getWidgetByScreentype(
      onBoardingContent?.widgets,
      "register-login-account"
    ) || {}
  );
};
export {
  useCountryList,
  useCountryTopList,
  useCountryNotAvailable,
  useRegisterLoginAccount
};
