import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HistoryButton from "Components/HistoryButton";
import MobileNumberForm from "Components/MobileNumberForm/MobileNumberForm";
import { usePostPhoneEntry } from "services/auth/auth.query";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  clearRouteCntrl,
  resetState,
  setIsoCode,
  setPhoneCode,
  setPhoneNumber,
  setRouteCntrl
} from "store/authanticateSlice";
import { useDataLayer } from "hooks/useDataLayer";
import Group from "Assets/images/Group.png";
import logoUnion from "Assets/images/Unionlwa.png";
import logoUnionBig from "Assets/images/UnionBig.png";
const LoginPhoneNumber = ({ SetPhoneNumber, SetPhoneCode, SetOTPCode }) => {
  const { loginKey } = useParams();
  const { pathname } = useLocation(); 
  let navigate = useNavigate();
  const { i18n,t } = useTranslation();
  const dispatch = useDispatch();
  const mobileSize = useMediaQuery("(max-width:900px)");
  const { setSignInEvent } = useDataLayer();
  const [isSetEvent, setIsSetEvent] = useState(false);
 
  const path = useSelector((state) => state.path);
  const {
    mutate: login,
    isLoading,
    error
  } = usePostPhoneEntry({
    onSuccess: ({ validation_key } = {}) => {
      setSignInEvent("enter_phone");
      navigate(`/sign-in/phone-verification`, {
        state: { validation_key, loginKey}
      });
    }
  });

  useEffect(() => {
    if (!isSetEvent && pathname === "/sign-in" && !loginKey) {
      setSignInEvent("start_sign_in");
      setIsSetEvent(true);
    }
  }, [isSetEvent, pathname, loginKey, setSignInEvent]);

  useEffect(() => {
    SetPhoneNumber("");
    SetOTPCode("");
    dispatch(clearRouteCntrl());
    dispatch(resetState());
    localStorage.removeItem("alliance-control");
  }, [SetOTPCode, SetPhoneNumber, dispatch]);

  // The handleSubmit function takes an object with three parameters: phone, code, and iso_code.
  // It constructs the phoneNumber by combining the code (which is cleaned up to remove dashes and spaces) and phone, replacing any dashes and spaces with empty strings.
  // It also constructs the phoneCode by removing any plus signs ("+") from the code.
  // It sets the phoneNumber and phoneCode states using SetPhoneNumber and SetPhoneCode functions, respectively.
  // It dispatches actions to set the phone and code states using the dispatch function.
  // It sets the iso_code state using the setIsoCode action.
  // It creates a new route object newRoute with a property phone set to true.
  // It dispatches actions to set the route control state using the setRouteCntrl function with the newRoute.
  // Finally, it calls a login function with the phoneNumber, iso_code, and loginKey as parameters. The exact behavior of the login function is not provided in the code snippet.

  const handleSubmit = ({ phone, code, iso_code }) => {
    const phoneNumber = code + phone.replace(/-/g, " ").replace(/ /g, "");
    const phoneCode = code.replace(/([+])/g, "");
    SetPhoneNumber(phoneNumber);
    SetPhoneCode(phoneCode);
    dispatch(setPhoneNumber(phone));
    dispatch(setPhoneCode(code));
    dispatch(setIsoCode(iso_code));
    const newRoute = { phone: true };
    dispatch(setRouteCntrl(newRoute));
    login({ phone: phoneNumber, iso_code, loginKey });
  };

  return (
    <div className={loginKey ? "WPlogin log-with-amber" : "WPlogin"}>
      <Box
        className={
          loginKey
            ? "WPleftpage login-page-main log-with-amber-main"
            : "WPleftpage login-page-main"
        }
        data-path={"loginPhone"}
        sx={{ direction: i18n.language === "arabic" ? "rtl" : "ltr" }}
      >
        {loginKey ? (
          <IconButton
            className=""
            onClick={() => {
              if (loginKey) {
                // If loginKey is present, go back to the previous page
                window.history.back();
              } else {
                // If loginKey is not present, navigate to the root path
                navigate('/');
              }
            }}
            sx={{
              width: 40,
              height: 40,
              right:
                i18n.language === "arabic"
                  ? "auto"
                  : { xs: "20px", md: "30px" },
              left:
                i18n.language === "arabic"
                  ? { xs: "20px", md: "30px" }
                  : "auto",
              position: "absolute !important",
              top: { xs: "20px", md: "30px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "14px",
                  md: "28px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }
              }}
            >
              {" "}
              <img src={Group} alt="Group" style={{ width: "100%" }} />
            </Box>
          </IconButton>
        ) : (
          <HistoryButton
            Right={i18n.language === "arabic" ? "auto" : 25}
            Top={25}
            Left={i18n.language === "arabic" ? 25 : "auto"}
            path={path}
          />
        )}
        {loginKey && (
          <>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <div
                className="left-page-logo"
                style={{ top: "3.7%", marginTop: "80px" }}
              >
                <img
                  src={logoUnionBig}
                  alt="logo"
                  style={{ marginBottom: "45px" }}
                />
              </div>
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <div
                className="left-page-logo"
                style={{ top: "3.7%", marginTop: "80px" }}
              >
                <img
                  src={logoUnion}
                  alt="logo"
                  style={{ marginBottom: "45px" }}
                />
              </div>
            </Box>
          </>
        )}
        <div className={loginKey? " left-page-form-pn loginLP log-with-amber-loginlp": "left-page-form-pn loginLP"}>
          <MobileNumberForm
            errorCode={error?.response?.data?.error_code}
            errorResponse={error?.response?.data?.message}
            handleSubmit={handleSubmit}
            infoText={"Sign into your Amber account using your mobile number"}
            isLoading={isLoading}
            label={loginKey ? t("Enter your country code and phone number") :"Mobile Phone"}
            style={{ maxWidth:loginKey ? ( mobileSize ? " calc(100% )" : "460px"): ( mobileSize ? " calc(100% - 40px )" : "100%") ,width: (loginKey && "100%") }}
            loginKey={loginKey}
          />
        </div>
      </Box>
    </div>
  );
};

export default LoginPhoneNumber;
