import React from "react";
import logoUnion from "Assets/images/logo-white.svg";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import LazyImage from "Components/LazyLoad/LazyImage";

const BrowserNav = () => {
  return (
    <Box
      sx={{
        backgroundColor: "black",
        padding: { xs: "10px", lg: "23px" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {" "}
      <Box
        sx={{
          display: { xs: "block", lg: "none" },
          maxWidth: "61.67px",
          maxHeight: "28px"
        }}
      >
        <Link to="/">
          <LazyImage
            placeholderSrc={
              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
            lazyclass="lazy"
            width={"100%"}
            height={"100%"}
            src={logoUnion}
            alt="logoUnion"
          />
        </Link>
      </Box>
      <Box
        sx={{
          display: { xs: "none", lg: "block" },

          maxWidth: "74.89px",
          maxHeight: "34px"
        }}
      >
        <Link to="/">
          <LazyImage
            placeholderSrc={
              "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            }
            lazyclass="lazy"
            width={"100%"}
            height={"100%"}
            src={logoUnion}
            alt="logo"
          />
        </Link>
      </Box>
    </Box>
  );
};

export default BrowserNav;
