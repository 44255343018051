import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  CircularProgress,
  Box
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import MaterialUIPickers from "Assets/Shared/Datepicker/MaterialUIPickers";
import { useIsLanguageArabic, useIsMobileView } from "hooks";
import {
  InputLabel,
  SettingsDescription,
  SettingsSubTitle,
  Spacer
} from "../common";
import CustomTextField from "../components/CustomTextField";
import PrimaryButton from "../components/PrimaryButton";
import lock from "Assets/images/lock.svg";
import {
  useGetAccount,
  usePostUpdatedAccount
} from "services/account/account.query";
import { EVENT_TYPE, useDataLayer } from "hooks/useDataLayer";
const validationSchema = (t) =>
  yup.object({
    firstName: yup.string().required(t("First name cannot be blank")),
    lastName: yup.string().required(t("Last name cannot be blank")),
    gender: yup
      .number()
      .oneOf([0, 1, 99], t("Please select a valid gender"))
      .required(t("Gender is required"))
  });

const PersonalDetailsSettings = () => {
  const { t, i18n } = useTranslation();
  const isLanguageArabic = useIsLanguageArabic();
  const isMobileView = useIsMobileView();
  const { setSettingsEvent } = useDataLayer();

  const {
    data: userData,
    isLoading: accountIsLoading,
    refetch,
    isFetching
  } = useGetAccount();
  const gender = userData?.Customer?.[0]?.PersonalInformation?.Gender;
  const firstname = userData?.Customer?.[0]?.PersonalInformation?.FirstName;
  const lastname = userData?.Customer?.[0]?.PersonalInformation?.LastName;
  const birthdate =
    userData?.Customer?.[0]?.PersonalInformation?.DateOfBirth || "";
  const [accountControl, SetAccountControl] = useState(0);
  const [loadingControl, SetLoadingControl] = useState(false);

  const { mutate: mutateUpdateAccount, isLoading } = usePostUpdatedAccount({
    onSuccess: () => {
      SetAccountControl(accountControl + 1);
      setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, { step: "complete" });
      refetch();
    },
    onError: () => {}
  });
  const personalDetailForm = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      gender: gender,
      birthDate: birthdate
    },
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      if (firstname !== values.firstName) {
        setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, {
          step: "enter_first_name"
        });
      }

      if (lastname !== values.lastName) {
        setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, {
          step: "enter_last_name"
        });
      }

      if (Number(gender) !== Number(values.gender)) {
        setSettingsEvent(EVENT_TYPE.CHANGE_SETTING, { step: "enter_gender" });
      }

      await mutateUpdateAccount({
        firstName: values.firstName,
        lastName: values.lastName,
        gender: Number(values.gender)
      });
      SetLoadingControl(true);
    }
  });

  // handleMainFunction:  It accesses the firstName and lastName values in the personalDetailForm.
  // For both firstName and lastName, it performs a series of string operations:
  // Removes any leading white spaces from the beginning of the strings.
  // Replaces consecutive sequences of white spaces with a single space to prevent excessive spacing.
  // Filters out any characters that are not letters or specific Arabic characters (in the range of \u0600-\u06FF), effectively removing any other special characters or symbols.

  const handleMainFunction = () => {
    personalDetailForm.values.firstName = personalDetailForm.values.firstName
      ?.replace(/^\s+/, "")
      ?.replace(/\s{2,}/g, " ")
      ?.replace(/[^a-zA-Z\u0600-\u06FF\s]/g, "");
    personalDetailForm.values.lastName = personalDetailForm.values.lastName
      ?.replace(/^\s+/, "")
      ?.replace(/\s{2,}/g, " ")
      ?.replace(/[^a-zA-Z\u0600-\u06FF\s]/g, "");
  };

  useEffect(() => {
    handleMainFunction();
  }, [personalDetailForm.values.firstName, personalDetailForm.values.lastName]);

  useEffect(() => {
    personalDetailForm.setValues({
      firstName: firstname,
      lastName: lastname,
      gender: Number(gender),
      birthDate: birthdate
    });
  }, [userData]);

  useEffect(() => {
    if (loadingControl && !isFetching) {
      SetLoadingControl(false);
    }
  }, [isFetching]);

  const genderCategory = [
    {
      value: 0,
      label: t("Male")
    },
    {
      value: 1,
      label: t("Female")
    },
    {
      value: 99,
      label: t("Not specified")
    }
  ];

  if (isLoading || accountIsLoading || loadingControl)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height:
            isLoading || accountIsLoading || loadingControl ? "30vh" : "100%"
        }}
      >
        <CircularProgress
          size={"25px"}
          style={{
            color: "black"
          }}
        />
      </Box>
    );
  return (
    <>
      {!isLoading && !accountIsLoading && !loadingControl && (
        <Grid>
          <SettingsSubTitle text={t("Personal details")} />
          <SettingsDescription
            className="settings-description-header"
            text={t("The basics of your profile")}
          />
          <form onSubmit={personalDetailForm.handleSubmit}>
            <Grid container rowSpacing={4} columnSpacing={1}>
              <Grid item sm={12} md={6} style={{ width: "100%" }}>
                <FormControl className="settings-form-control">
                  <InputLabel text={t("First name")} />
                  <CustomTextField
                    name="firstName"
                    value={personalDetailForm.values.firstName}
                    placeholder={t("Your first name")}
                    onChange={personalDetailForm.handleChange}
                    errorMessage={t(
                      personalDetailForm.touched.firstName
                        ? personalDetailForm.errors.firstName
                        : ""
                    )}
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6} style={{ width: "100%" }}>
                <FormControl className="settings-form-control">
                  <InputLabel text={t("Last name")} />
                  <CustomTextField
                    name="lastName"
                    placeholder={t("Your last name")}
                    value={personalDetailForm.values.lastName}
                    onChange={personalDetailForm.handleChange}
                    errorMessage={t(
                      personalDetailForm.touched.lastName
                        ? personalDetailForm.errors.lastName
                        : ""
                    )}
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6} style={{ width: "100%" }}>
                <FormControl className="settings-form-control">
                  <InputLabel text={t("Gender")} />
                  <TextField
                    select
                    placeholder={t("Please Select")}
                    value={personalDetailForm.values.gender}
                    onChange={personalDetailForm.handleChange("gender")}
                    helperText={t(
                      personalDetailForm.touched.gender
                        ? personalDetailForm.errors.gender
                        : ""
                    )}
                    SelectProps={{
                      IconComponent: KeyboardArrowDownIcon
                    }}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    sx={{
                      marginTop: "0px",
                      ".MuiInputBase-input": {
                        padding: "9px",
                        fontSize: "14px",
                        marginTop: "0px"
                      },
                      "& .MuiSelect-icon": {
                        left: isLanguageArabic ? "7px" : "initial",
                        right: isLanguageArabic ? "initial" : "7px"
                      },
                      "& .MuiFormHelperText-root": {
                        color: " #f66464",
                        marginLeft: "0px ",
                        fontFamily: isLanguageArabic
                          ? "TajawalRegular"
                          : "MontserratRegular",
                        fontSize: "14px",
                        lineHeight: "130%",
                        marginTop: "8px",
                        marginRight: 0,
                        textAlign: isLanguageArabic ? "right" : "left"
                      },
                      ".MuiSelect-select": {
                        overflow: "auto",
                        color:
                          personalDetailForm.values.gender ===
                          t("Please select")
                            ? "#90969E"
                            : "#000000",
                        opacity: "1",
                        fontFamily:
                          i18n.language === "arabic"
                            ? "TajawalRegular"
                            : "MontserratRegular",
                        fontSize: i18n.language === "arabic" ? "19px" : "16px",
                        lineHeight:
                          i18n.language === "arabic" ? "24.7px" : "130%"
                      },
                      ".MuiSvgIcon-root": {
                        "& input::placeholder": {
                          fontSize: "16px"
                        }
                      },
                      ".MuiSelect-outlined": {
                        paddingRight: isLanguageArabic
                          ? "12px !important"
                          : "initial"
                      }
                    }}
                  >
                    <MenuItem
                      style={
                        i18n.language === "arabic"
                          ? {
                              direction: "rtl",
                              fontFamily: "TajawalRegular",
                              fontSize: "19px",
                              lineHeight: "24.7px"
                            }
                          : {
                              fontFamily: "MontserratRegular",
                              fontSize: "16px",
                              lineHeight: "130%"
                            }
                      }
                      value={t("Please select")}
                      disabled
                    >
                      {t("Please select")}
                    </MenuItem>
                    {genderCategory.map((option) => (
                      <MenuItem
                        style={
                          i18n.language === "arabic"
                            ? {
                                direction: "rtl",
                                fontFamily: "TajawalRegular",
                                fontSize: "19px",
                                lineHeight: "24.7px"
                              }
                            : {
                                fontFamily: "MontserratRegular",
                                fontSize: "16px",
                                lineHeight: "130%"
                              }
                        }
                        key={option.value}
                        value={option.value}
                      >
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Spacer />
              <Grid item sm={12} md={6} sx={{ width: "100%" }}>
                <InputLabel text={t("Date of birth")} />
                <MaterialUIPickers
                  inputFormat="DD MMM YYYY"
                  disabled={true}
                  dateIcon={lock}
                  value={personalDetailForm.values.birthDate}
                  onChange={(birthDate) =>
                    personalDetailForm.setFieldValue("birthDate", birthDate)
                  }
                  backgroundStyle="#F3F3FA"
                  helperText={""}
                  placeHolderControl={true}
                />
                <div className="date-message-info" data-lang={i18n.language}>
                  {t("dateMessageToUser")}
                </div>
              </Grid>
              <Grid
                sm={12}
                md={12}
                style={{
                  marginTop: isMobileView ? "40px" : "48px",
                  width: "100%"
                }}
              >
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  className="button-submit-pn"
                  style={{
                    width: isMobileView ? "100%" : "initial",
                    fontFamily:
                      i18n.language === "arabic"
                        ? "TajawalMedium"
                        : "MontserratMedium"
                  }}
                  text={t("Save changes")}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </>
  );
};

export default PersonalDetailsSettings;
