import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import ViewAllButton from "Assets/ViewAllButton";

const ErrorComponent = ({ Warnig, Text, ButtonText, SmallText, margintp }) => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <Box className="error">
      <Box className="error-main-component">
        <Box className="error-main-text">{Warnig}</Box>
        <Box
          className={
            i18n.language === "arabic"
              ? "error-sup-text-arabic"
              : "error-sup-text"
          }
        >
          {t(Text)}
        </Box>
        {SmallText && (
          <Box
            className={
              i18n.language === "arabic" ? "small-text-arabic" : "small-text"
            }
          >
            {t(SmallText)}
          </Box>
        )}
        <Box className="button-box-error" style={{ marginTop: margintp }}>
          {ButtonText &&
            ButtonText.map((item, index) => (
              <ViewAllButton
                className={
                  i18n.language === "arabic"
                    ? "error-button-arabic"
                    : "error-button"
                }
                onClick={() => item.path && navigate(item.path)}
                key={index}
              >
                {t(item.text)}
              </ViewAllButton>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorComponent;
