import React from "react";
import Layout from "../Layout";
import ErrorComponent from "Components/ErrorComponent";
import ErrorContainer from "./ErrorContainer";

const Error401 = () => {
  let ButtonText = [
    { text: "Return home", path: "/" },
    { text: "Contact us", path: "/faq" }
  ];
  return (
    <Layout navbar={"white"} className="brands">
      <ErrorContainer>
        <ErrorComponent
          Warnig="401"
          Text="Authorization Required"
          ButtonText={ButtonText}
          margintp={40}
        />
      </ErrorContainer>
    </Layout>
  );
};

export default Error401;
