import React from "react";
import { useIsLanguageArabic } from "hooks";

const BrowseButton = (props) => {
  const isLanguageArabic = useIsLanguageArabic();
  return (
    <button
      className={isLanguageArabic ? "browse-button-arabic" : "browse-button"}
      {...props}
    />
  );
};

export default BrowseButton;
