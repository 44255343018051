import { Grid, Snackbar } from "@mui/material";
import React from "react";
import SuccessIcon from "Assets/images/success.svg";

import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";

const NotificationSnack = ({ handleClose, open, message }) => {
  const { t, i18n } = useTranslation();
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top"
      }}
      TransitionComponent={Slide}
    >
      <Grid
        style={{
          width: "350px",
          padding: "16px",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 0px 14px rgba(34, 38, 49, 0.2)",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Grid>
          <div className="notification-snack-header" data-lang={i18n.language}>
            {t("Done")}
          </div>
          <div className="notification-snack-content" data-lang={i18n.language}>
            {message}
          </div>
        </Grid>
        <img
          style={{
            width: "20px",
            height: "20px"
          }}
          src={SuccessIcon}
          alt="Qatar"
        />
      </Grid>
    </Snackbar>
  );
};

export default NotificationSnack;
