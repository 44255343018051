import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ViewAllButton = styled(Button)(({ theme, isArabic = false }) => ({
  textTransform: "none",
  backgroundColor: "#F3F3FA",
  fontStyle: "normal",
  fontWeight: 500,
  fontFamily: isArabic ? "TajawalMedium" : "MontserratMedium",
  fontSize: isArabic ? "15px" : "12px",
  lineHeight: "130%",
  padding: "10px 16px",
  height: "36px",
  borderRadius: "34px",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#F3F3FA"
  },
  "&:active": {
    backgroundColor: "#F3F3FA",
    boxShadow: "none"
  },
  isArabic
}));

export default ViewAllButton;
