import React from "react";
export default function ArrowDownIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "16"}
      height={size || "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g id="Web/Carousel Arrows">
        <path
          id="Vector"
          d="M3.66663 5.66663L8.33329 10.3333L13 5.66663"
          stroke={color || "black"}
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
}
