import { SELECTED_LANGUAGE } from "constants";

// Input: The function takes a single parameter languageCode, which is a string representing the selected language code to be stored.

// Local Storage Item Storage: It uses the localStorage.setItem method to store the languageCode as a string in local storage. The item is stored with the key SELECTED_LANGUAGE, which is defined in the constants module.

// Outcome: After executing this function, the user's selected language code is stored in the browser's local storage under the key SELECTED_LANGUAGE. This allows the application to remember the user's language preference across sessions.

export const setSelectedLanguageCodeToStorage = (languageCode) => {
  if (languageCode) {
    localStorage.setItem(SELECTED_LANGUAGE, languageCode);
  }
};
