import React from "react";
import { Grid, Box } from "@mui/material";
import Frame from "Assets/images/Frame689.png";
import { useTranslation } from "react-i18next";
import LazyImage from "Components/LazyLoad/LazyImage";

const AmberInf = ({ id, widget }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <Grid
        className="AmberInf"
        container
        rowSpacing={4}
        columnSpacing={{ xs: 0 }}
        id={id}
        style={{}}
        sx={{
          display: "flex",
          marginTop: { xs: "32px", md: "80px" },
          marginBottom: { xs: "32px", md: "76px" },
          direction: i18n.language === "arabic" ? "rtl" : "ltr",
          alignItems: {
            xs: "center",
            md: "none"
          },
          paddingLeft: { xs: "20px", md: "11.11%" },
          paddingRight: { xs: "20px", md: "11.11%" }
        }}
      >
        <Grid xs={12} md={6} className=" ">
          <Box sx={{ maxWidth: { xs: "-webkit-fill-available", xl: "520px" } }}>
            <LazyImage
              placeholderSrc={
                "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              }
              lazyclass="lazy"
              src={widget?.image}
              alt={Frame}
              width={"100%"}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={6}
          className="section"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Box
            sx={{
              marginLeft:
                i18n.language === "arabic"
                  ? "0px"
                  : { xs: "0px", md: "20px", lg: "80px" },
              marginRight:
                i18n.language === "arabic"
                  ? { xs: "0px", md: "20px", lg: "80px" }
                  : "0px"
            }}
          >
            <Box
              sx={{
                marginTop: { xs: "36px !important", md: "0px !important" }
              }}
              className={
                i18n.language === "arabic"
                  ? "section-head-arabic"
                  : "section-head"
              }
            >
              {widget?.title}
            </Box>
            {widget?.items.map((item) => (
              <>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "section-text-head-arabic"
                      : "section-text-head"
                  }
                >
                  {item.title}
                </div>
                <div
                  className={
                    i18n.language === "arabic"
                      ? "section-text-arabic"
                      : "section-text"
                  }
                >
                  {item.description}
                </div>
              </>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AmberInf;
