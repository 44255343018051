import React from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-barcode";
import { Box } from "@mui/material";

import PrimaryButton from "Pages/Account/components/PrimaryButton";

const BarcodeGenerator = ({ generateValue }) => {
  const { t, i18n } = useTranslation();

  //The handleDownloadClick function creates a PNG image from an SVG barcode element and enables the user to download it as "barcode.png" when a download action is triggered.
  // It accomplishes this by creating a canvas, rendering the SVG onto it, and providing a download link to the user.

  const handleDownloadClick = () => {
    const canvas = document.createElement("canvas");
    const barcode = document.querySelector(".barcode-box svg");

    canvas.width = barcode.clientWidth;
    canvas.height = barcode.clientHeight;

    const ctx = canvas.getContext("2d");
    const svgString = new XMLSerializer().serializeToString(barcode);
    const img = new Image();

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const link = document.createElement("a");
      link.download = "barcode.png";
      link.href = canvas.toDataURL();
      link.click();
    };
    img.src = "data:image/svg+xml," + encodeURIComponent(svgString);
  };
  return (
    <Box
      className="alliance"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {" "}
      <Box className="barcode-box">
        <Barcode
          value={generateValue}
          marginTop="8px"
          marginBottom="0px"
          width={2.4}
          height={60}
          font="SF Mono"
          fontSize="12px"
          format="CODE128"
        />
      </Box>
      <Box
        className={
          i18n.language === "arabic" ? "barcode-text-arabic" : "barcode-text"
        }
      >
        {t("We emailed your")}
      </Box>
      <PrimaryButton
        onClick={handleDownloadClick}
        style={{
          width: "calc(100%)",
          paddingLeft: "20px",
          paddingRight: "20px",
          marginTop: "24px",
          maxWidth: "460px",
          fontSize: 14,
          height: 41,
          fontFamily:
            i18n.language === "arabic" ? "TajawalMedium" : "MontserratMedium"
        }}
        text={t("Download as image")}
      />
    </Box>
  );
};

export default BarcodeGenerator;
