import React, { useMemo } from "react";

import Footer from "Components/Footer/Footer";
import { useGetAccount } from "services/account/account.query";
import { useGetHomeContent } from "services/cms/cms.query";
import { COMPONENT_KEY_MAP, FOOTER_SECTION_MAP } from ".";

const FooterContainer = ({setUpdateLanguage}) => {
  const { data: userData } = useGetAccount();
  const { data: homeContentData } = useGetHomeContent();

  //The widget variable, computed using the useMemo hook, retrieves a specific widget from the homeContentData based on its component type,
  //and it is optimized to recalculate only when the homeContentData changes.

  const widget = useMemo(() => {
    return (
      homeContentData?.widgets?.find(
        (item) =>
          item.component_type ===
          COMPONENT_KEY_MAP["web-homepage-footer-navigation"]
      ) || {}
    );
  }, [homeContentData]);

  const { color_theme, logo, sections = [] } = widget;

  //The sectionMap variable, computed using the useMemo hook, creates a mapping of sections based on the sections array,
  // with keys derived from section_name properties and values containing additional properties,
  // including an id. This optimization ensures that sectionMap is recalculated only when the sections array changes.

  const sectionMap = useMemo(() => {
    let sectionMap = {};
    sections.forEach((item) => {
      sectionMap[FOOTER_SECTION_MAP[item.section_name]] = {
        ...item,
        id: item.section_name
      };
    });
    return sectionMap;
  }, [sections]);
  return (
    <Footer
      color_theme={color_theme}
      userData={userData}
      logo={logo}
      filterContents={sectionMap[FOOTER_SECTION_MAP["footer-filter-contents"]]}
      languageSelector={
        sectionMap[FOOTER_SECTION_MAP["footer-language-selector"]]
      }
      socialMedias={
        sectionMap[FOOTER_SECTION_MAP["footer-social-media-handles"]]
      }
      sublinksNavigation={
        sectionMap[FOOTER_SECTION_MAP["footer-sublinks-navigation"]]
      }
      footerLinks={sectionMap[FOOTER_SECTION_MAP["main-footer-links"]]}
      setUpdateLanguage={setUpdateLanguage}  
    />
  );
};

export default FooterContainer;
