import { useQuery } from "@tanstack/react-query";
import { TERMS_AND_CONDITIONS } from "../serviceKeys";
import * as Requests from "./termsAndConditions.requests";
import { useTierValue } from "../../hooks/useTierValue";

export const useGetTermsAndConditions = () => {
  const tierValue = useTierValue();
  return useQuery([TERMS_AND_CONDITIONS, tierValue], () => {
    return Requests.getTermsAndConditions({ segment: tierValue });
  });
};
