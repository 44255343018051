import md5 from "md5";
import moment from "moment";
import _ from "lodash";

import { SELECTED_LANGUAGE, TIER_MAP } from "constants";
import { countries } from "Assets/helper/helper";

// Input Data: The function receives a userData object, which presumably contains information about a user.
// User Property Mapping: It maps and extracts specific properties from the userData object, including the user's ID, Amber loyalty tier, email and phone number (hashed for privacy), country, language, gender, date of birth, and notification preferences.
// Data Processing:
// It calculates the user's age based on the provided date of birth.
// It determines the user's location permission status, checking if geolocation permission is granted.
// It sets the user's notification preferences based on the data provided.

const GENDER_MAP = Object.freeze({
  0: "M",
  1: "F",
  99: ""
});

const getStatus = (status) => {
  return status ? "enabled" : "disabled";
};

const getLocationPermission = async () => {
  const response = await navigator.permissions.query({ name: "geolocation" });

  return response.state === "granted" ? "enabled" : "disabled";
};

export const getUserProperties = async (userData) => {
  if (!userData) {
    return {
      userID: "",
      amber_tier: "",
      hashed_email: "",
      hashed_phone: "",
      country: "",
      language: "",
      user_gender: "",
      user_age: "",
      no_of_kids: "",
      location_permission: "",
      email_opt_in: "",
      sms_opt_in: "",
      push_opt_in: ""
    };
  }

  const user = userData.Customer[0];
  const {
    AccountID,
    LoyaltyInformaton,
    PersonalInformation,
    PersonalSettings
  } = user || {};
  const { EmailAddress, PrimaryPhoneNumber, Country, Gender, DateOfBirth } =
    PersonalInformation;
  const { ContactByEmail, ContactBySMS } = PersonalSettings;
  const selectedLanguageCode = localStorage.getItem(SELECTED_LANGUAGE);

  return {
    userID: AccountID,
    amber_tier: TIER_MAP[LoyaltyInformaton?.Card?.Tier],
    hashed_email: md5(EmailAddress),
    hashed_phone: md5(`+${PrimaryPhoneNumber}`),
    country: _.find(countries, ["countryCode", Country]).code,
    language: selectedLanguageCode === "en" ? "EN" : "AR",
    user_gender: GENDER_MAP[Gender],
    user_age: moment().diff(DateOfBirth, "years").toString(),
    no_of_kids: "0",
    location_permission: await getLocationPermission(),
    email_opt_in: getStatus(ContactByEmail),
    sms_opt_in: getStatus(ContactBySMS),
    push_opt_in: getStatus(false)
  };
};
