import { apiClient } from "../apiClient";

export const postPhoneEntry = async ({
  iso_code,
  phone,
  loginKey,
  action = "signIn",
  alliance_id
}) => {
  return apiClient({
    url: "/auth/phone-entry",
    method: "POST",
    customConfig: {
      params: {
        action,
        allianceID: alliance_id
      }
    },
    data: {
      iso_code,
      phone,
       // Conditionally include amber_partner_key if loginKey is truthy
       ...(loginKey && { amber_partner_key: loginKey }),
    }
  }).then((res) => res.data);
};

export const postPhoneValidate = async ({
  validation_key,
  validation_code,
  loginKey,
  allianceID
}) => {
  return apiClient({
    url: `/auth/phone-validate`,
    method: "POST",
    customConfig: {
      params: {
        allianceID
      }
    },
    data: {
      validation_key,
      validation_code,
      amber_partner_key: loginKey
    }
  }).then((res) => res.data);
};

export const postEmailExist = async ({ email, alliance_id }) => {
  return apiClient({
    url: "/auth/email-exist",
    method: "POST",
    customConfig: {
      params: {
        allianceID: alliance_id
      }
    },
    data: {
      email
    }
  }).then((res) => res.data);
};

export const postExistEmailOtp = async ({ email, alliance_id }) => {
  return apiClient({
    url: "/auth/exist-email-otp",
    method: "POST",
    customConfig: {
      params: {
        allianceID: alliance_id
      }
    },
    data: {
      email
    }
  }).then((res) => res.data);
};

export const postExistEmailValidate = async ({
  validation_key,
  validation_code,
  alliance_id
}) => {
  return apiClient({
    url: "/auth/exist-email-validate",
    method: "POST",
    customConfig: {
      params: {
        allianceID: alliance_id
      }
    },
    data: {
      validation_key,
      validation_code
    }
  }).then((res) => res.data);
};

export const postRegister = async ({
  email,
  first_name,
  last_name,
  gender,
  birthday,
  advertisement,
  terms,
  data_sharing,
  region,
  alliance_id,
  referalCode,
  promo_code
}) => {
  return apiClient({
    url: "/auth/register",
    method: "POST",
    customConfig: {
      params: {
        allianceID: alliance_id
      }
    },
    data: {
      email,
      first_name,
      last_name,
      gender,
      birthday,
      advertisement,
      terms,
      data_sharing,
      region,
      alliance_id,
      referral_code: referalCode,
      referral_point: promo_code
    }
  }).then((res) => res.data);
};

export const postSetNumber = async ({
  first_name,
  last_name,
  gender,
  birthday,
  referalCode,
  alliance_id
}) => {
  return apiClient({
    url: "/auth/set-number",
    method: "POST",
    customConfig: {
      params: {
        allianceID: alliance_id
      }
    },
    data: {
      first_name,
      last_name,
      gender,
      birthday,
      referral_code: referalCode
    }
  }).then((res) => res.data);
};

export const refreshToken = async ({ token }) => {
  return apiClient({
    url: "/auth/refresh-token",
    method: "GET",
    headers: {
      Authorization: "Bearer " + token
    }
  }).then((res) => res.data);
};

export const getEmailVerification = async ({
  validationKey,
  secondKey,
  alliance_id
}) => {
  return apiClient({
    url: "/auth/verify-email",
    method: "POST",
    customConfig: {
      params: {
        allianceID: alliance_id
      }
    },
    data: {
      validation_key: validationKey,
      second_key: secondKey
    }
  }).then((res) => res.data);
};
