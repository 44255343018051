import React from "react";
import Layout from "../Layout";
import ErrorComponent from "Components/ErrorComponent";
import ErrorContainer from "./ErrorContainer";

const Error500 = () => {
  let ButtonText = [{ text: "Contact us", path: "/faq" }];
  return (
    <Layout navbar={"white"} className="brands">
      <ErrorContainer>
        <ErrorComponent
          Warnig="500"
          Text="Server Error"
          ButtonText={ButtonText}
          SmallText="The server encountered"
          margintp={24}
        />
      </ErrorContainer>
    </Layout>
  );
};

export default Error500;
